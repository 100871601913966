/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FontsStyle, GlobalStyle } from 'styles/global-styles';

import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { I18nContext } from 'react-i18next';
import { FrontendLayout } from 'app/Layout/FrontendLayout/Loadable';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { CreateTheme } from 'styles/theme';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAppSettings,
  selectIsAuthenticated,
  selectLoading,
  selectLoadingUser,
} from 'app/slice/selectors';
import { useAppSettingsSlice } from './slice';
// import i18n (needs to be bundled ;))
import '../locales/i18n';
import { AnimalTypesPage } from './pages/AnimalTypesPage/Loadable';

import { DialogAlert } from './components/DialogAlert';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SnackbarManager } from './Layout/FrontendLayout/components/Snackbar/Loadable';
import { InternalIdLoginPage } from './pages/Animals/InternalIdLoginPage/Loadable';
import { AnimalsListPage } from './pages/AnimalsListPage/Loadable';
import { PosLayout } from './Layout/PosLayout';
import { AnimalCaseConsumablesPage } from './pages/Animals/AnimalCaseConsumablesPage/Loadable';
import { LoginLayout } from './Layout/LoginLayout';
import { AnimalsHomePage } from './pages/Animals/AnimalsHomePage/Loadable';
import { ConnectedRouter } from 'connected-react-router';

import { PrivateAnimalRoute } from './pages/Animals/PrivateAnimalRoute';
import Grow from '@material-ui/core/Grow';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { CssBaseline } from '@material-ui/core';
import { CreateNewCasePage } from './pages/Animals/CreateNewCasePage/Loadable';
import { SamplesListPage } from './pages/Samples/SamplesListPage/Loadable';
import { RunDetailsPage } from './pages/Samples/RunDetailsPage/Loadable';
import {
  RequestDetailsPage,
  RequestSamplesPage,
} from './pages/Samples/RequestSamplesPage/Loadable';
import { TrainingSessionsPage } from './pages/TrainingSessionsPage/Loadable';
import { BuildingsPage } from './pages/Buildings/Loadable';
import { History } from './pages/ReservationHistoryPage/Loadable';
import { AddNewReportPage } from './pages/AddNewReportPage/Loadable';
import { Progress } from './components/LoadingIndicator';
import { TrainingWaitingListPage } from './pages/TrainingWaitingListPage/Loadable';
import { history } from 'store/history';
import { EmailNotificationsPage } from './pages/EmailNotificationsPage/Loadable';
import { ExperimentTemplatesPage } from './pages/ExperimentTemplates/Loadable';
import { UsersHistoryPage } from './pages/UsersHistoryPage/Loadable';
import { UserTrainingPage } from './pages/UserTrainingsPage/Loadable';
import { NotAvailable } from './pages/NotAvailablePage/Loadable';
import { RestrictedRoute } from './components/Routers/RestrictedRoute';
import { Users } from './pages/UsersPage/loadable';
import { AnimalExtendedBillingReportPage } from './pages/Animals/AnimalExtendedBillingReportPage/Loadable';
import { UserTrainingHistoryPage } from './pages/UserTrainingHistoryPage/Loadable';
import { OtherServicesPage } from './pages/OtherServicesPage/Loadable';
import { BudgetsPage } from './pages/BudgetsPage/Loadable';
import { ServiceRequestPage } from './pages/ServiceRequestListPage/Loadable';
import { WorkOrdersPage } from './pages/WorkOrdersPage/Loadable';
import { DocumentsPage } from './pages/Documents/Loadable';
import { QuizPage } from './pages/QuizPage/Loadable';
import { QuizDetailsPage } from './pages/QuizDetailsPage/Loadable';
import { UserTrainingDetailsPage } from './pages/UserTrainings/TrainingDetailsPage/Loadable';
import { SampleModificationsPage } from './pages/Samples/SampleModificationsPage/Loadable';
import { UsageHistory } from './pages/UsageHistoryPage/Loadable';
import { RunsListPage } from './pages/Samples/RunsListPage/Loadable';
import { AnimalCasesHistoryPage } from './pages/Animals/AnimalCaseEditPage/AnimalCasesHistory/Loadable';
import { SendEmailToUsersPage } from './pages/SendEmailToUsers/Loadable';
import { Charges } from './pages/ChargesPage/Loadable';
import { MyAssetsPage } from './pages/MyAssetsPage/Loadable';
import { Locations } from './pages/LocationsPage/loadable';
import { BuildingPage } from './pages/LocationsPage/BuildingPage/loadable';
import { CampusesPage } from './pages/LocationsPage/CampusesPage/loadable';
import { AreasPage } from './pages/LocationsPage/AreasPage/loadable';
import { LocationListPage } from './pages/LocationsPage/LocationListPage/loadable';
import { SubLocationListPage } from './pages/LocationsPage/SubLocationListPage/loadable';
import { InvoiceBatches } from './pages/InvoiceBatches/Loadable';
import { CategoriesPage } from './pages/CategoriesPage/Loadable';
import { CategoryGroupsPage } from './pages/CategoryGroupsPage/Loadable';
import { BillingPreviewPage } from './pages/BillingPreviewPage/Loadable';
import { OnlineBudgetsStatusPage } from './pages/OnlineBudgetsStatusPage';
import { InvoiceDetailsPage } from './pages/InvoiceDetails/Loadable';
import { WorkOrderDetailsPage } from './pages/WorkOrders/WorkOrderDetailsPage/Loadable';
import { Billing } from './pages/InvoiceBillingPage/Loadable';
import { BillingActions } from './pages/BillingActions/BillingActionsPage/Loadable';
import { BillingActionsDetailsPage } from './pages/BillingActions/BillingActionsDetailsPage/Loadable';
import { LocationTypesPage } from './pages/LocationsPage/LocationTypesPage/loadable';
import { SubLocationTypesPage } from './pages/LocationsPage/SubLocationTypesPage/loadable';
import { GetBrowserInfo } from 'utils/mobileDetect';
import { SavedViewDetailsPage } from './pages/SavedViewsPage/SavedViewPage/Loadable';
import { SavedViews } from './pages/SavedViewsPage/SavedViewsPage/Loadable';
import { LabelTypesPage } from './pages/LabelTypesPage/Loadable';
import { AssetDetailsPage } from './pages/AssetDetailsPage/Loadable';
import { LabelPrintDialog } from './components/LabelPrint/LabelPrintDialog/Loadable';
import { KioskProjectsPage } from './pages/KioskProjects/Loadable';
import { KioskProjectConsumablesPage } from './pages/KioskProjects/KioskProjectConsumablesPage/Loadable';
import { PrivateKioskProjectsRoute } from './pages/KioskProjects/PrivateKioskProjectsRoute/Loadable';
import { SessionTimeout } from './components/SessionTimeout';
import { SubmissionsPage } from './pages/QuizSubmissionsPage/Loadable';
import { QuizzesPage } from './pages/QuizzesPage/Loadable';
import { AdHocReportListPage } from './pages/AdHocReportListPage/Loadable';
import { Rooms } from './pages/RoomsPage/Loadable';
import { CalendarMessages } from './pages/CalendarMessages/CalendarMessagesPage/Loadable';
import { CoresDirectoryPage } from './pages/CoresDirectoryPage/Loadable';
import { SamplePlatesPage } from './pages/Samples/SamplePlatesPage/Loadable';
import { HeadTitle } from './Layout/FrontendLayout/components/HeadTitle';
import { InstrumentRestrictionsPage } from './pages/InstrumentRestrictionsPage/Loadable';
import { LabelTypeDetailsPage } from './pages/LabelTypeDetailsPage/Loadable';
import { ConfigurableColumnExportImportPage } from './pages/ConfigurableColumnExportImport/Loadable';
import { AppSettings } from 'types/AppSettings';
import { ServerBarcodeRoute } from './components/Routers/ServerBarcodesRoute';
import { useLayoutSlice } from './Layout/FrontendLayout/slice';
import { NewMessagePage } from './pages/NewMessagePage/Loadable';
import { OtherServiceDetailsPage } from './pages/OtherServiceDetails/OtherServiceDetailsPage/Loadable';
import { InvoiceTemplates } from './pages/InvoiceTemplates/InvoiceTemplatesPage/Loadable';
import { Expenses } from './pages/ExpensesPage/Loadable';
import { PricePeriodsPagePage } from './pages/PriceSheetPeriodsPage/Loadable';
import { PriceSheetPeriodDetailsPage } from './pages/PriceSheetPeriodDetails/PriceSheetPeriodDetailsPage/Loadable';
import { ArticlesPagePath, ArticlesPage } from './pages/ArticlesPage/Loadable';
import {
  ArticlesExternalSearchPagePath,
  ArticlesExternalSearchPage,
} from './pages/ArticlesExternalSearchPage/Loadable';
import { AccountLayout } from './Layout/AccountLayout/Loadable';
import { RegisterPage } from './pages/Account/Register/Loadable';
import { anonimusUrls } from './slice/types';
import {
  CalendarPage,
  CalendarPageDefaultRedir,
} from './pages/CalendarPage/Loadable';
import {
  calendarPageDefaultPath,
  calendarPageFullPath,
  CalendarPagePaths,
} from './pages/CalendarPage';
import { ReservationDetailsPage } from './pages/ReservationDetails/ReservationDetailsPage/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import { SystemPage } from './pages/SystemPage/Loadable';
import { DashboardPage } from './pages/DashboardPage/Loadable';
import { CoreSelectionPage } from './pages/CoreSelectionPage/Loadable';
import { CoreHomePage } from './pages/CoreHomePage/Loadable';
import { CoreStatsPage } from './pages/CoreStatsPage/Loadable';
import { ReportingPage } from './pages/ReportingPage/Loadable';
import { BookitLabDashboadPage } from './pages/BookitLabDashboardPage/Loadable';
import { RedirectWithSearch } from './components/RedirectWithSearch';
import { AssetSelectionPage } from './pages/MyAssetsPage/AssetsSelection/Loadable';
import { TermsOfUseAgreementPage } from './pages/TermsOfUseAgreementPage/Loadable';
import { SnackWrapper } from './Layout/FrontendLayout/components/Snackbar/SnackWrapper';
import { ActiveUserGroupPage } from './pages/ActiveUserGroupPage/Loadable';
import { CannedReportsPage } from './pages/CannedReports/Loadable';
import { ServiceRequestDetailsPath } from './pages/Samples/RequestSamplesPage/slice/path';
import { UserAttributes } from './pages/UserAttributes/UserAttributesPage/Loadable';
import { ReportConsumablesPage } from './pages/OtherServiceDetails/ReportConsumables/ReportConsumablesPage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { CalendarMessagesDetailsPage } from './pages/CalendarMessages/CalendarMessagesDetails/Loadable';
import { ServiceRequestsKanbanPage } from './pages/ServiceRequestsKanbanPage/Loadable';
import { OfflineServiceTypesPage } from './pages/OfflineServiceTypesPage/Loadable';
import { RUN_DETAILS_PAGE_PATH } from './pages/Samples/RunDetailsPage';
import { NOTIFICATIONS_LOG_PAGE_PATH } from './pages/EmailNotificationsPage';
import { AnimalCaseEditPage } from './pages/Animals/AnimalCaseEditPage/Loadable';
import { ServiceRequestsKanbanPagePath } from './pages/ServiceRequestsKanbanPage';
import './AllIcons';
import { PublicAssetsLayout } from './Layout/PublicAssetsLayout';
import { PublicAssetsPage } from './pages/PublicAssets/Loadable';
import { NotAllowedPage } from './pages/NotAllowedPage/Loadable';
import { PlayGroundPage } from './pages/PlayGround/Loadable';
import { AssetsInventoryPage } from './pages/AssetsInventory/Loadable';
import { AssetsInventoryLogPage } from './pages/AssetsInventoryLog/Loadable';
import { ServiceRequestAuditTrailHistoryPage } from './pages/ServiceRequestAuditTrailHistoryPage/Loadable';
import { Floors } from './pages/FloorsPage/Loadable';
import { SubscriptionTypesPage } from './pages/SubscriptionTypesPage/Loadable';
import { BudgetExperimentsPage } from './pages/BudgetExperimentsPage/Loadable';
import { toRootedURL } from 'utils/url-utils';
import { RequestStatusesPage } from './pages/RequestStatuses/RequestStatusesPage/Loadable';
import { SubscribersPage } from './pages/SubscribersPage/Loadable.';
import { RegionDetailsPage } from './pages/Regions/RegionsDetailsPage';
import { ProjectMembersPage } from './pages/ProjectMembersPage/Loadable';
import { dateUtils } from 'utils/date-utils';
import { ReservationWaitingListPage } from './pages/ReservationWaitingListPage/Loadable';
import { JoinAdGroupPage } from './pages/JoinAdGroup/Loadable';
import { JoinConfirmPage } from './pages/JoinAdGroup/JoinConfirmPage/Loadable';
import { PlannedWorkOrdersPage } from './pages/PlannedWorkOrdersPage/Loadable';
import { NotificationsPage } from './pages/NotificationsPage/Loadable';
import { useSideBarSlice } from './Layout/FrontendLayout/components/SideBar/slice';
import { SamplePlatesDetailsPage } from './pages/Samples/SamplePlatesDetailsPage/Loadable';
import { AssetsFilePage } from './pages/AssetFile/Loadable';
import { AssetSettingsPage } from './pages/AssetSettings/Loadable';
import { UsageDetailsPage } from './pages/UsageDetails/UsageDetailsPage/Loadable';
import { useEffectOnMount } from './hooks/useEffectOnMount';
import { RegionsPage } from './pages/Regions/RegionsPage/Loadable';
import { SuperGroupsPage } from './pages/SuperGroups/SuperGroupsPage/Loadable';
import { AssemblySnapshotsPage } from './pages/AssemblySnapshots/Loadable';
import { UserDetailsPage } from './pages/UserDetails/UserDetailsPage/Loadable';
import { AssetSearchHistoryPage } from './pages/AssetSearchHistory/Loadable';
import { AllCoresPage } from './pages/AllCoresPage/Loadable';
import { SpecificCoresPage } from './pages/SpecificCoresPage/Loadable';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userLoading = useSelector(selectLoadingUser);
  const isLoading = useSelector(selectLoading);
  const isLoginPage = React.useMemo(() => {
    return (
      window.location.pathname
        .split('/')
        .filter(f => f !== '' && f.toLowerCase() === 'login').length > 0
    );
  }, []);
  const isPublicPage = React.useMemo(() => {
    return anonimusUrls.some(
      f => window.location.pathname.toLowerCase().indexOf(f) > -1,
    );
  }, []);

  const { actions } = useAppSettingsSlice();
  useSideBarSlice();
  // run the layout slice ahead of time so that it will process global actions like openSidePanel, etc.
  useLayoutSlice();
  useEffectOnMount(() => {
    if (!isLoginPage) {
      dispatch(actions.getAuthenticatedUser(isPublicPage));
      window.scrollTo(0, 1);
    } else {
      dispatch(actions.loadLoginSystemSettings());
    }
  });

  React.useEffect(() => {
    if (!isLoginPage) {
      if (!userLoading && isAuthenticated !== undefined) {
        if (isAuthenticated) {
          dispatch(actions.loadAppSettings(true));
          dispatch(actions.loadSiteMapSettings());
          dispatch(actions.loadUserProfileSettings());
          dispatch(actions.loadGlobalServiceGroupFilter());
          dispatch(actions.loadAllServiceGroupsSelectable());
          dispatch(actions.loadSystemSettings());
          setTimeout(() => dispatch(actions.loadEventsCount()), 0);

          GetBrowserInfo();
        } else {
          if (isPublicPage) {
            dispatch(actions.loadAppSettings(false));
          } else {
            window.location.replace(toRootedURL('/login'));
            //dispatch(actions.navigate('/login'));
          }
        }
      }
    }
  }, [
    actions,
    dispatch,
    isAuthenticated,
    isLoginPage,
    isPublicPage,
    userLoading,
  ]);
  // React.useEffect(() => {
  //   setNoValidLocation(window.location.pathname.endsWith('index.html'));
  // }, []);

  const dir = useDir();
  const bookitTheme = CreateTheme(dir, 'bookit');
  const defaultTheme = CreateTheme(dir, 'default');
  const animalTheme = CreateTheme(dir, 'animals');

  document.body.dir = dir;
  const locale = React.useMemo(() => dateUtils.CurrentLocale(), []);
  const appSettings: AppSettings | null = useSelector(selectAppSettings);

  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <GlobalStyle />
          {/* basename is required to properly route addresses when app is served from a non-root location like https://host/application/ */}
          <ConnectedRouter history={history}>
            <IsLoaded isLoading={isLoading}>
              {isAuthenticated === true && (
                <ServerBarcodeRoute appSettings={appSettings} />
              )}
              <HeadTitle />
            </IsLoaded>

            {isLoading === false &&
            //!noValidLocation &&
            (isAuthenticated !== undefined || isLoginPage) ? (
              <React.Fragment>
                {isAuthenticated === true ? (
                  <Switch>
                    <Route
                      key="route-defaultTheme"
                      exact
                      path={[
                        '/dashboards/home/',
                        '/dashboards/core/:serviceGroupId?',
                        '/dashboards/coreStats',
                        '/dashboards/reporting',
                        '/dashboards',
                        '/systemdash',
                        '/allcoresdash',
                        '/speccoresdash',
                        '/coreselection',
                        '/BookitLabSupportDashboard',
                        ...CalendarPagePaths,
                        '/reservations',
                        '/animaltypes',
                        '/animals',
                        '/samples',
                        ServiceRequestDetailsPath,
                        '/requests/:id/samples',
                        '/requests',
                        RUN_DETAILS_PAGE_PATH,
                        '/samples/plates',
                        '/samples/modifications',
                        '/coresdirectory',
                        '/trainingsessions',
                        '/trainingwaitinglist',
                        NOTIFICATIONS_LOG_PAGE_PATH,
                        '/usertraining',
                        '/usertraining/details/',
                        '/usertraining/details/:id',
                        '/CalendarMessages',
                        '/CalendarMessages/details/',
                        '/CalendarMessages/details/:id',
                        '/Maps/Buildings',
                        '/Maps/Rooms',
                        '/Documents',
                        '/AdHocReports',
                        '/myassets',
                        '/assets/details',
                        '/AddNewReportPage',
                        '/History',
                        '/Users',
                        '/Users/Profile',
                        '/Experiments/experimenttemplates',
                        '/UsersHistory',
                        '/quizzes',
                        '/quizzes/create/:id',
                        '/quizzes/details/:id',
                        '/submissions',
                        '/animals/cases/AnimalExtendedBillingReport',
                        '/notavailable',
                        '/notallowed',
                        '/UserTrainingHistory',
                        '/OtherServices',
                        '/OtherServices/details/',
                        '/OtherServices/details/:id',
                        '/reportConsumables',
                        '/Budgets/BudgetType/:id',
                        '/WorkOrders',
                        '/WorkOrders/details/',
                        '/WorkOrders/details/:id',
                        '/UsageHistory',
                        '/Charges',
                        '/serviceRequests',
                        '/serviceRequests/kanban',
                        // ServiceRequestTicketDetailsPagePath,
                        '/samples/runs',
                        '/animals/cases/AnimalCasesHistory/:id',
                        '/SendEmailToUsers',
                        '/Locations',
                        '/Building',
                        '/Campuses',
                        '/Areas',
                        '/LocationList',
                        '/SubLocationList',
                        '/InvoiceBatches',
                        '/Categories',
                        '/CategoryGroups',
                        '/BillingPreview',
                        '/OnlineBudgetsStatus',
                        '/OnlineBudgetsStatus/:endDate',
                        '/Invoices/Details',
                        '/Invoices/Details/:id',
                        '/Billing',
                        '/BillingActions',
                        '/BillingActions/details/',
                        '/BillingActions/details/:id',
                        '/LocationTypes',
                        '/SubLocationTypes',
                        '/mylists/details:id',
                        '/mylists/details',
                        '/mylists',
                        '/labeltypes',
                        '/labeltypes/details/',
                        '/labeltypes/details/:id',
                        '/instrumentRestrictions',
                        '/NewMessage/:id',
                        '/InvoiceTemplates',
                        '/Expenses',
                        '/ConfigurableColumnExportImport',
                        '/reservation.aspx',
                        '/reservations/details:id',
                        '/reservations/details',
                        '/PricePeriods',
                        '/PricePeriods/details:id',
                        '/PricePeriods/details',
                        '/AssetSearch',
                        '/TermsOfUseAgreement',
                        '/ActiveUserGroup/:shrink',
                        '/CannedReports',
                        ArticlesExternalSearchPagePath,
                        ArticlesPagePath,
                        '/UserAttributes',
                        '/OfflineServiceTypes',
                        // '/Assets',
                        '/Assets/Public',
                        '/Assets/Inventory',
                        '/Assets/Inventory/Log',
                        '/PlayGround',
                        '/ServiceRequestAuditTrailHistory',
                        '/Maps/Floors',
                        '/SubscriptionTypes',
                        '/BudgetExperiments',
                        '/RequestStatuses',
                        '/Subscribers',
                        '/ProjectMembers',
                        '/supergroups',
                        '/regions',
                        '/regions/details:id',
                        '/regions/details',
                        '/waitinglist',
                        '/PlannedWorkOrders',
                        '/Join/:shrink',
                        '/JoinConfirm/:shrink',
                        '/notificationSettings',
                        '/samples/plates/details:id',
                        '/samples/plates/details',
                        '/AssetFile',
                        '/assets/settings',
                        '/usage/details',
                        '/usage/details:id',
                        '/AssemblySnapshots',
                        'AssetSearchHistory',
                      ]}
                    >
                      <MuiThemeProvider theme={bookitTheme}>
                        {/* todo: fix the LatoStyle - after initial load, first dialog open results in a glitch: text elements are switched from lato to default and immediately back. this results in elements on the page jumping back and forth  */}
                        {/* <LatoStyle /> */}
                        <FrontendLayout>
                          {/* todo : prepend public url like so to support virtual applications hosted at {process.env.PUBLIC_URL + '/Calendar'} */}
                          <Switch key="switch-bookit-theme">
                            <Route
                              path={'/dashboards/home'}
                              component={HomePage}
                            />
                            <Route
                              path={['/dashboards/core/:serviceGroupId?)']}
                              component={CoreHomePage}
                            />
                            <Route
                              path={'/dashboards/coreStats'}
                              component={CoreStatsPage}
                            />
                            <Route
                              path={'/dashboards/reporting'}
                              component={ReportingPage}
                            />
                            <Route
                              path={'/dashboards'}
                              component={DashboardPage}
                            />
                            <Route
                              path={'/coreselection'}
                              component={CoreSelectionPage}
                            />
                            <Route
                              path={'/systemdash'}
                              component={SystemPage}
                            />
                            <Route
                              path={'/allcoresdash'}
                              component={AllCoresPage}
                            />
                            <Route
                              path={'/speccoresdash'}
                              component={SpecificCoresPage}
                            />
                            <Route
                              path={'/BookitLabSupportDashboard'}
                              component={BookitLabDashboadPage}
                            />
                            <Route
                              exact
                              path={calendarPageFullPath}
                              component={CalendarPage}
                            />
                            <Route
                              exact
                              path={calendarPageDefaultPath}
                              component={CalendarPageDefaultRedir}
                            />
                            <RestrictedRoute
                              exact
                              path={'/animaltypes'}
                              component={AnimalTypesPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/animals'}
                              component={AnimalsListPage}
                            />
                            <Route
                              exact
                              path={'/serviceRequests'}
                              component={ServiceRequestPage}
                            />
                            <Route
                              exact
                              path={ServiceRequestsKanbanPagePath}
                              component={ServiceRequestsKanbanPage}
                            />
                            <Route
                              exact
                              path={'/requests/:id/samples'}
                              component={RequestSamplesPage}
                            />
                            <Route
                              exact
                              path={ServiceRequestDetailsPath}
                              component={RequestSamplesPage}
                            />
                            <Route
                              exact
                              path={'/requests'}
                              component={RequestDetailsPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/samples'}
                              component={SamplesListPage}
                            />
                            <Route
                              exact
                              path={RUN_DETAILS_PAGE_PATH}
                              component={RunDetailsPage}
                            />
                            <RestrictedRoute
                              exact
                              path="/samples/plates"
                              component={SamplePlatesPage}
                            />
                            <Route
                              path="/samples/plates/details"
                              component={SamplePlatesDetailsPage}
                            />
                            <Route
                              path={'/samples/plates/details:id'}
                              component={SamplePlatesDetailsPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/samples/runs'}
                              component={RunsListPage}
                            />
                            <Route
                              exact
                              path="/samples/modifications"
                              component={SampleModificationsPage}
                            />
                            <Route
                              exact
                              path={'/coresdirectory'}
                              component={CoresDirectoryPage}
                            />
                            <Route
                              exact
                              path={'/trainingsessions'}
                              component={TrainingSessionsPage}
                            />
                            <Route
                              exact
                              path={'/trainingwaitinglist'}
                              component={TrainingWaitingListPage}
                            />
                            <Route
                              exact
                              path={NOTIFICATIONS_LOG_PAGE_PATH}
                              component={EmailNotificationsPage}
                            />
                            <Route
                              exact
                              path={'/Experiments/experimenttemplates'}
                              component={ExperimentTemplatesPage}
                            />
                            <Route
                              exact
                              path={'/usertraining'}
                              component={UserTrainingPage}
                            />
                            <Route
                              path={'/usertraining/details/:id'}
                              component={UserTrainingDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/usertraining/details/'}
                              component={UserTrainingDetailsPage}
                            />
                            <Route
                              exact
                              path={'/Maps/Buildings'}
                              component={BuildingsPage}
                            />
                            <Route
                              exact
                              path={'/Documents'}
                              component={DocumentsPage}
                            />
                            <RestrictedRoute
                              path={'/CalendarMessages'}
                              component={CalendarMessages}
                            />
                            <Route
                              path={'/CalendarMessages/details/:id'}
                              component={CalendarMessagesDetailsPage}
                            />
                            <Route
                              path={'/CalendarMessages/details'}
                              component={CalendarMessagesDetailsPage}
                            />
                            <Route
                              exact
                              path={'/adhocreportlist'}
                              component={AdHocReportListPage}
                            />
                            <Route
                              exact
                              path={'/Maps/Rooms'}
                              component={Rooms}
                            />
                            <RestrictedRoute
                              exact
                              path={'/AdHocReports'}
                              component={AdHocReportListPage}
                            />
                            <Route
                              exact
                              path={'/myassets'}
                              component={MyAssetsPage}
                            />
                            <Route
                              exact
                              path="/assets/details"
                              component={AssetDetailsPage}
                            />
                            <Route
                              exact
                              path="/assets/settings"
                              component={AssetSettingsPage}
                            />
                            <Route
                              exact
                              path={'/AddNewReportPage'}
                              component={AddNewReportPage}
                            />
                            <Route
                              exact
                              path={'/reservations'}
                              component={History}
                            />
                            <RestrictedRoute
                              exact
                              path={'/Users'}
                              component={Users}
                            />
                            <RestrictedRoute
                              exact
                              path={'/UsersHistory'}
                              component={UsersHistoryPage}
                            />
                            <Route
                              exact
                              path={'/Users/Profile'}
                              component={UserDetailsPage}
                            />
                            <Route
                              path={'/quizzes/create/:id'}
                              component={QuizPage}
                            />
                            <Route
                              path={'/quizzes/details/:id'}
                              component={QuizDetailsPage}
                            />
                            <Route
                              exact
                              path={'/quizzes'}
                              component={QuizzesPage}
                            />
                            <Route
                              exact
                              path={'/submissions'}
                              component={SubmissionsPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/UserTrainingHistory'}
                              component={UserTrainingHistoryPage}
                            />
                            <Route
                              exact
                              path={'/OtherServices'}
                              component={OtherServicesPage}
                            />
                            <Route
                              path={'/OtherServices/details/:id'}
                              component={OtherServiceDetailsPage}
                            />
                            <Route
                              path={'/OtherServices/details/'}
                              component={OtherServiceDetailsPage}
                            />
                            <Route
                              path={'/reportConsumables'}
                              component={ReportConsumablesPage}
                            />
                            <Route
                              exact
                              path={'/WorkOrders'}
                              component={WorkOrdersPage}
                            />
                            <Route
                              path={'/WorkOrders/details/:id'}
                              component={WorkOrderDetailsPage}
                            />
                            <Route
                              path={'/WorkOrders/details/'}
                              component={WorkOrderDetailsPage}
                            />
                            <Route
                              exact
                              path={'/UsageHistory'}
                              component={UsageHistory}
                            />
                            <Route
                              exact
                              path={'/Charges'}
                              component={Charges}
                            />
                            <Route
                              path="/OnlineBudgetsStatus/:endDate?"
                              component={OnlineBudgetsStatusPage}
                            />
                            <Route
                              component={NotAvailable}
                              path={'/notavailable'}
                            />
                            <Route
                              component={NotAllowedPage}
                              path={'/notallowed'}
                            />
                            <RestrictedRoute
                              exact
                              path={
                                '/animals/cases/AnimalExtendedBillingReport'
                              }
                              component={AnimalExtendedBillingReportPage}
                            />
                            <Route
                              exact
                              path={'/Budgets/BudgetType/:id'}
                              component={BudgetsPage}
                            />
                            <Route
                              exact
                              path={'/animals/cases/AnimalCasesHistory/:id'}
                              component={AnimalCasesHistoryPage}
                            />

                            <RestrictedRoute
                              exact
                              path={'/SendEmailToUsers'}
                              component={SendEmailToUsersPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/Locations'}
                              component={Locations}
                            />
                            <Route
                              path={'/Building'}
                              component={BuildingPage}
                            />
                            <RestrictedRoute
                              path={'/Campuses'}
                              component={CampusesPage}
                            />
                            <Route path={'/Areas'} component={AreasPage} />
                            <Route
                              path={'/SubLocationList'}
                              component={SubLocationListPage}
                            />
                            <RestrictedRoute
                              path={'/LocationList'}
                              component={LocationListPage}
                            />
                            <RestrictedRoute
                              path={'/LocationTypes'}
                              component={LocationTypesPage}
                            />
                            <RestrictedRoute
                              path={'/SubLocationTypes'}
                              component={SubLocationTypesPage}
                            />
                            <RestrictedRoute
                              //exact
                              path={'/InvoiceBatches'}
                              component={InvoiceBatches}
                            />
                            <RestrictedRoute
                              path={'/Categories'}
                              component={CategoriesPage}
                            />
                            <RestrictedRoute
                              path={'/CategoryGroups'}
                              component={CategoryGroupsPage}
                            />
                            <RestrictedRoute
                              path={'/BillingPreview'}
                              component={BillingPreviewPage}
                            />
                            <Route
                              path={'/Invoices/Details/:id'}
                              component={InvoiceDetailsPage}
                            />
                            <Route
                              path={'/Invoices/Details/'}
                              component={InvoiceDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/Billing'}
                              component={Billing}
                            />
                            <RestrictedRoute
                              path={'/BillingActions'}
                              component={BillingActions}
                            />
                            <Route
                              path={'/BillingActions/details/:id'}
                              component={BillingActionsDetailsPage}
                            />
                            <Route
                              path={'/BillingActions/details'}
                              component={BillingActionsDetailsPage}
                            />
                            <Route path={'/mylists'} component={SavedViews} />
                            <Route
                              path={'/mylists/details'}
                              component={SavedViewDetailsPage}
                            />
                            <Route
                              path={'/mylists/details/:id'}
                              component={SavedViewDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/labeltypes'}
                              exact
                              component={LabelTypesPage}
                            />
                            <Route
                              path={'/labeltypes/details/:id'}
                              component={LabelTypeDetailsPage}
                            />
                            <Route
                              path={'/labeltypes/details'}
                              component={LabelTypeDetailsPage}
                            />
                            <Route
                              path={'/instrumentRestrictions'}
                              component={InstrumentRestrictionsPage}
                            />
                            <RestrictedRoute
                              path={'/InvoiceTemplates'}
                              component={InvoiceTemplates}
                            />
                            <RestrictedRoute
                              path={'/Expenses'}
                              component={Expenses}
                            />
                            <Route
                              path={'/ConfigurableColumnExportImport'}
                              component={ConfigurableColumnExportImportPage}
                            />
                            <Route
                              path={'/PlayGround'}
                              component={PlayGroundPage}
                            />
                            {/* 
                              direct navigation/aspx links are redirected on the server side,
                              so reservation details should get the /reservation/details here
                              reservation.aspx link might be added by mistake somewhere in react, so these cases are handled here
                            */}
                            <RedirectWithSearch
                              from="/reservation.aspx"
                              to="/reservations/details"
                            />
                            <Route
                              path={'/reservations/details:id'}
                              component={ReservationDetailsPage}
                            />
                            <Route
                              path={'/reservations/details/'}
                              component={ReservationDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/PricePeriods'}
                              component={PricePeriodsPagePage}
                            />
                            <Route
                              path={'/PricePeriods/details:id'}
                              component={PriceSheetPeriodDetailsPage}
                            />
                            <Route
                              path={'/PricePeriods/details'}
                              component={PriceSheetPeriodDetailsPage}
                            />
                            <Route
                              path={ArticlesExternalSearchPagePath}
                              component={ArticlesExternalSearchPage}
                            />
                            <Route
                              path={'/AssetSearch'}
                              component={AssetSelectionPage}
                            />
                            <Route
                              path={ArticlesPagePath}
                              component={ArticlesPage}
                            />
                            <Route
                              path={'/TermsOfUseAgreement'}
                              component={TermsOfUseAgreementPage}
                            />
                            <Route
                              path={'/ActiveUserGroup/:shrink'}
                              component={ActiveUserGroupPage}
                            />
                            <RestrictedRoute
                              path={'/CannedReports'}
                              component={CannedReportsPage}
                            />
                            <RestrictedRoute
                              path={'/UserAttributes'}
                              component={UserAttributes}
                            />
                            {/* <Route
                              path={'/Assets'}
                              component={PublicAssetsPage}
                            /> */}
                            <Route
                              path={'/Assets/Public'}
                              component={PublicAssetsPage}
                            />
                            <RestrictedRoute
                              path={'/Assets/Inventory'}
                              component={AssetsInventoryPage}
                            />
                            <RestrictedRoute
                              path={'/Assets/Inventory/Log'}
                              component={AssetsInventoryLogPage}
                            />
                            <RestrictedRoute
                              path={'/OfflineServiceTypes'}
                              component={OfflineServiceTypesPage}
                            />
                            <RestrictedRoute
                              path={'/SubscriptionTypes'}
                              component={SubscriptionTypesPage}
                            />
                            <Route
                              path={'/ServiceRequestAuditTrailHistory'}
                              component={ServiceRequestAuditTrailHistoryPage}
                            />
                            <Route path={'/Maps/Floors'} component={Floors} />
                            <Route
                              path={'/SubscriptionTypes'}
                              component={SubscriptionTypesPage}
                            />
                            <Route
                              path={'/BudgetExperiments'}
                              component={BudgetExperimentsPage}
                            />
                            <RestrictedRoute
                              path={'/supergroups'}
                              component={SuperGroupsPage}
                            />
                            <RestrictedRoute
                              path={'/regions'}
                              component={RegionsPage}
                            />
                            <RestrictedRoute
                              path={'/regions/details:id'}
                              component={RegionDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/regions/details'}
                              component={RegionDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/RequestStatuses'}
                              component={RequestStatusesPage}
                            />
                            <Route
                              path={'/ProjectMembers'}
                              component={ProjectMembersPage}
                            />
                            <Route
                              path={'/Join/:shrink'}
                              component={ActiveUserGroupPage}
                            />
                            <Route
                              path={'/JoinConfirm/:shrink'}
                              component={ActiveUserGroupPage}
                            />
                            <Route
                              path={'/Subscribers'}
                              component={SubscribersPage}
                            />
                            <Route
                              exact
                              path={'/waitinglist'}
                              component={ReservationWaitingListPage}
                            />
                            <Route
                              exact
                              path={'/notificationSettings'}
                              component={NotificationsPage}
                            />
                            <Route
                              path={'/PlannedWorkOrders'}
                              component={PlannedWorkOrdersPage}
                            />
                            <RestrictedRoute
                              path="/AssetFile"
                              component={AssetsFilePage}
                            />
                            <Route
                              path={'/usage/details'}
                              component={UsageDetailsPage}
                            />
                            <Route
                              path={'/usage/details:id'}
                              component={UsageDetailsPage}
                            />
                            <RestrictedRoute
                              path={'/AssemblySnapshots'}
                              component={AssemblySnapshotsPage}
                            />
                            <RestrictedRoute
                              path={'/AssetSearchHistory'}
                              component={AssetSearchHistoryPage}
                            />
                            <Route component={NotFoundPage} />
                          </Switch>
                        </FrontendLayout>
                      </MuiThemeProvider>
                    </Route>

                    <Route
                      key="route-postLogin"
                      path={[
                        '/TermsOfUseAgreement/:exp',
                        '/ActiveUserGroup',
                        '/Join',
                        '/JoinConfirm',
                      ]}
                    >
                      <MuiThemeProvider theme={bookitTheme}>
                        <FontsStyle />
                        <PosLayout>
                          <Route
                            path={'/TermsOfUseAgreement/:exp'}
                            component={TermsOfUseAgreementPage}
                          />
                          <Route
                            path={'/ActiveUserGroup'}
                            component={ActiveUserGroupPage}
                          />
                          <Route path={'/Join'} component={JoinAdGroupPage} />
                          <Route
                            path={'/JoinConfirm'}
                            component={JoinConfirmPage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </Route>

                    <Route exact path={'/InternalIdLogin'}>
                      <MuiThemeProvider theme={animalTheme}>
                        <FontsStyle />
                        <LoginLayout>
                          <Route
                            exact
                            path={'/InternalIdLogin'}
                            component={InternalIdLoginPage}
                          />
                        </LoginLayout>
                      </MuiThemeProvider>
                    </Route>

                    <Route exact path={'/KioskInternalIdLogin'}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <FontsStyle />
                        <LoginLayout>
                          <Route
                            exact
                            path={'/KioskInternalIdLogin'}
                            component={InternalIdLoginPage}
                          />
                        </LoginLayout>
                      </MuiThemeProvider>
                    </Route>

                    <PrivateAnimalRoute
                      exact
                      path={[
                        '/animals/home',
                        '/animals/cases/create',
                        '/animals/cases/details/:id',
                        '/animals/cases/consumables/:id',
                      ]}
                    >
                      <MuiThemeProvider theme={animalTheme}>
                        <PosLayout>
                          <Route
                            exact
                            path={'/animals/cases/create'}
                            component={CreateNewCasePage}
                          />
                          <Route
                            exact
                            path={'/animals/home'}
                            component={AnimalsHomePage}
                          />

                          <Route
                            exact
                            path={'/animals/cases/consumables/:id'}
                            component={AnimalCaseConsumablesPage}
                          />
                          <Route
                            exact
                            path={'/animals/cases/details/:id'}
                            component={AnimalCaseEditPage}
                          />
                          <Route
                            path={'/NewMessage/:id'}
                            component={NewMessagePage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </PrivateAnimalRoute>
                    <PrivateKioskProjectsRoute
                      exact
                      path={[
                        '/kiosk/projects',
                        '/kiosk/projects/consumables',
                        '/kiosk/projects/consumables/:id',
                      ]}
                    >
                      <MuiThemeProvider theme={bookitTheme}>
                        <PosLayout>
                          <Route
                            exact
                            path={'/kiosk/projects'}
                            component={KioskProjectsPage}
                          />
                          <Route
                            exact
                            path={'/kiosk/projects/consumables'}
                            component={KioskProjectConsumablesPage}
                          />
                          <Route
                            exact
                            path={'/kiosk/projects/consumables/:id'}
                            component={KioskProjectConsumablesPage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </PrivateKioskProjectsRoute>
                    <Route exact path={['/RegisterAccount', '/Login']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route
                            exact
                            path={'/RegisterAccount'}
                            component={RegisterPage}
                          />
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route>
                    {/* <Route exact path={'/Login'}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route> */}

                    <Route component={NotFoundPage} />
                    <Route component={NotAvailable} path={'/notavailable'} />
                    <Route component={NotAllowedPage} path={'/notallowed'} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path={['/RegisterAccount', '/Login']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route
                            exact
                            path={'/RegisterAccount'}
                            component={RegisterPage}
                          />
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route>
                    <Route exact path={['/Assets/Public', '/Assets/Details']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <PublicAssetsLayout>
                          <Route
                            exact
                            path={'/Assets/Public'}
                            component={PublicAssetsPage}
                          />
                          <Route
                            exact
                            path="/Assets/Details"
                            component={AssetDetailsPage}
                          />
                        </PublicAssetsLayout>
                      </MuiThemeProvider>
                    </Route>
                  </Switch>
                )}
              </React.Fragment>
            ) : (
              <Progress inProgress={isLoading} size={80} />
            )}
            {/* <GlobalStyle /> */}

            <MuiThemeProvider theme={bookitTheme}>
              {isLoading === false &&
                //!noValidLocation &&
                isAuthenticated === true && <SessionTimeout />}

              <ErrorBoundary>
                <DialogAlert />
              </ErrorBoundary>
              <ErrorBoundary>
                <SnackWrapper>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    TransitionComponent={
                      Grow as React.ComponentType<TransitionProps>
                    }
                  >
                    <SnackbarManager />
                  </SnackbarProvider>
                </SnackWrapper>
              </ErrorBoundary>
              {isLoading === false &&
                //!noValidLocation &&
                isAuthenticated === true && (
                  <ErrorBoundary>
                    <LabelPrintDialog />
                  </ErrorBoundary>
                )}
            </MuiThemeProvider>
          </ConnectedRouter>
        </MuiThemeProvider>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  );
}

function IsLoaded({
  isLoading,
  ...props
}: React.PropsWithChildren<{ isLoading: boolean }>) {
  if (isLoading) {
    return <React.Fragment />;
  } else {
    return <React.Fragment {...props} />;
  }
}

const useDir = function () {
  const i18n = React.useContext(I18nContext);
  return i18n?.i18n?.dir() ?? 'ltr';
};
