import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  InputProps,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import * as React from 'react';

import cliTruncate from 'cli-truncate';
import { uploadStyle } from './styles';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FileView } from './FileView';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';

export interface FileInputProps extends InputProps {
  className?: string;
  onFileChange?: (file: FileList | null) => void;
  label?: string;
  multiple?: boolean;
  uploadLabel?: string;
  showPreview?: boolean;
  removeable?: boolean;
  onRemove?: (value: string) => void;
  acceptedFiles?: string;
  isRequired?: string;
  helperText?: string;
  errorMesage?: string;
  info?: string;
  referenceId?: number;
  file?: IFormFileValue;
  uploadText?: string;
  shrinkLabel?: boolean;
}

export function FileInput(props: FileInputProps) {
  const {
    className,
    onFileChange,
    onRemove,
    id,
    multiple,
    uploadLabel,
    showPreview,
    helperText,
    errorMesage,
    onChange,
    label,
    file,
    uploadText,
    required,
    shrinkLabel,
    acceptedFiles,
  } = props;
  const classes = uploadStyle();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  //const [file, setFile] = React.useState<string | undefined>(undefined);
  const isMobile = DetectIsMobile();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    } else {
      const files = event.target.files;
      if (onFileChange) {
        onFileChange(files);
      }
    }
    // if (event.target.files?.length) {
    //   setFileName(event.target.files[event.target.files?.length - 1].name);
    // }
  };
  const removeFile = (fileName: string) => {
    if (onRemove) {
      onRemove(fileName);
    }
    //setFileName(undefined);
  };
  return (
    <FormControl
      required={required}
      error={props.error}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      variant="filled"
    >
      {label && (
        <React.Fragment>
          {shrinkLabel ? (
            <InputLabel
              htmlFor={id || 'standart-uploader'}
              required={required}
              variant={'filled'}
              shrink={file === undefined}
              className={clsx({
                shrinkFile: shrinkLabel && !!label && file !== undefined,
              })}
            >
              {label}
            </InputLabel>
          ) : (
            <FormLabel
              htmlFor={id || 'standart-uploader'}
              className={'file-label'}
              required={required}
              filled={true}
            >
              {label}
            </FormLabel>
          )}
        </React.Fragment>
      )}
      <Button
        component="label"
        disableRipple
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel,
          endIcon: classes.endIcon,
        }}
        className={clsx({
          shrinkLabel: shrinkLabel && !!label && file === undefined,
        })}
        endIcon={
          multiple && file !== undefined ? (
            <IconButton
              onClick={() => removeFile(file.DisplayValue || '')}
              children={<Icon icon="times" />}
            />
          ) : props.info ? (
            <InfoIcon title={props.info} />
          ) : undefined
        }
      >
        <span>{uploadLabel ? uploadLabel : 'Upload File'}</span>
        <Divider
          orientation="vertical"
          variant="fullWidth"
          classes={{ root: classes.dividerRoot }}
        />
        {multiple && file ? (
          <Body size="small">
            {cliTruncate(
              file.DisplayValue || '',
              sidePanelExpanded && !isMobile ? 35 : 22,
              {
                position: 'middle',
              },
            )}
          </Body>
        ) : (
          <Body size="small">
            {uploadText ? uploadText : 'Please select a file...'}
          </Body>
        )}
        <Input
          id={id || 'standart-uploader'}
          className={clsx('file-input-hidden', className)}
          value={props.value}
          name={props.name}
          onChange={handleChange}
          type="file"
          inputProps={{ multiple: multiple, accept: acceptedFiles }}
          hidden={true}
          aria-describedby="upload-input"
        />
      </Button>

      {showPreview === true && !multiple && file !== undefined && (
        <FileView
          file={file}
          onRemove={() => removeFile(file.DisplayValue || ' ')}
          withShrinkLabel={shrinkLabel}
        />
      )}
      {(!!errorMesage || !!helperText) && (
        <FormHelperText
          id="upload-input"
          required={required}
          error={props.error}
          disabled={props.disabled}
        >
          {errorMesage ? (
            <span>{errorMesage}</span>
          ) : helperText ? (
            <span>{helperText}</span>
          ) : undefined}
        </FormHelperText>
      )}
    </FormControl>
  );
}
