import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IReservationWaitingListDto } from '../IReservationWaitingListDto';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { ISignUpModel, ISimpleSignUpModel } from './ISignUpModel';
import { Formik } from 'formik';
import { GetSignUpSchema, GetSimpleSignUpSchema } from './SignUpSchema';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormOnlineServicesSinglePicker } from 'app/components/Forms/FormEquipmentsPicker';
import { FormRadioGroupBase } from 'app/components/Forms/FormRadioGroupBase';
import {
  convertSignUpToModel,
  convertSimpleSignUpToModel,
  getIsRowSimpleMode,
  getNotifyOnEndUsageOptions,
  getNotifyOnResCancelationOptions,
  getNotifyOnResTerminationOptions,
  SimpleModeResults,
} from './utils';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import { UserNotificationsApi as api } from 'api/UserNotificationsApi';
import { AxiosError } from 'axios';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import {
  selectAuthenticatedUser,
  selectUsageEnabled,
} from 'app/slice/selectors';
import { FormWindowHoursField } from 'app/components/Forms/FormWindowHours';
import { Entity } from 'types/common';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Box } from '@material-ui/core';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';

export interface SignUpWaitingListDetailsProps
  extends SidePanelContentProps,
    CoverProps {
  Id?: number;
  Equipment?: Entity<number>;
  SignUpUser?: Entity<string>;
  initialDetails?: IReservationWaitingListDto;
}

export function SignUpWaitingListDetails(props: SignUpWaitingListDetailsProps) {
  const {
    closeCover,
    isCover,
    initialDetails,
    Id,
    Equipment,
    SignUpUser,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const [busy, setBusy] = React.useState(false);
  const isEdit =
    (initialDetails !== undefined && initialDetails.Id > 0) ||
    (Id !== undefined && Id > 0);
  const submitRef = React.useRef<any>();
  const simplesubmitRef = React.useRef<any>();
  const continueLink = useSelector(selectContinueToLink);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const closeApproved = useSelector(selectConfirmApproved);
  const closeRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const usageEnabled = useSelector(selectUsageEnabled);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const { actions: notifyActions } = useAppSettingsSlice();
  const [loadedDetails, setLoadedDetails] = React.useState<
    IReservationWaitingListDto | undefined
  >(undefined);
  const [initialValues, setInitialValues] = React.useState<
    ISignUpModel | undefined
  >(undefined);
  const termOptions = getNotifyOnResTerminationOptions(t);
  const cancelOptions = getNotifyOnResCancelationOptions(t);
  const usageOptions = getNotifyOnEndUsageOptions(t);
  const [simpleMode, setSimpleMode] = React.useState<SimpleModeResults>({
    isSimpleMode: isEdit ? false : true,
    hoursCount: 0,
    notificationsLength: usageEnabled ? 3 : 2,
  });
  const [isSimpleView, setIsSimpleView] = React.useState<boolean | undefined>(
    isEdit ? undefined : true,
  );
  const [isMounted, setIsMounted] = React.useState<boolean | undefined>(
    undefined,
  );
  const isAdminRole = React.useMemo(() => {
    return (
      authenticatedUser?.isAdmin() ||
      authenticatedUser?.isGroupAdmin() ||
      authenticatedUser?.isLabTech()
    );
  }, [authenticatedUser]);
  const isAdminView = React.useMemo(() => {
    const details = initialDetails || loadedDetails;
    return (
      isEdit &&
      details &&
      authenticatedUser?.IsAllGroupOrLabTechAdmin(undefined, [
        {
          Id: details.EquipmentID,
          Name: details.EquipmentName,
          ServiceTypeId: 1,
        } as IServiceTypeFilterDto,
      ])
    );
  }, [authenticatedUser, initialDetails, isEdit, loadedDetails]);
  const userVisible = React.useMemo(() => {
    return !isEdit && isAdminRole;
    // formik.values.Equipment !== null &&
    // authenticatedUser?.IsAllGroupOrLabTechAdmin(undefined, [
    //   {
    //     Id: formik.values.Equipment.Id,
    //     Name: formik.values.Equipment.Name,
    //     ServiceTypeId: 1,
    //   } as IServiceTypeFilterDto,
    // ])
  }, [isAdminRole, isEdit]);
  React.useEffect(() => {
    if (isEdit && (!!initialDetails || !!loadedDetails) && !isMounted) {
      if (!!initialDetails) {
        setSimpleMode(getIsRowSimpleMode(initialDetails, usageEnabled));
      } else if (!!loadedDetails) {
        setSimpleMode(getIsRowSimpleMode(loadedDetails, usageEnabled));
      }
      setIsMounted(true);
    }
  }, [initialDetails, isEdit, isMounted, loadedDetails, usageEnabled]);

  const schema = React.useMemo(() => {
    return GetSignUpSchema(t, isEdit);
  }, [isEdit, t]);
  const simpleschema = React.useMemo(() => {
    return GetSimpleSignUpSchema(t);
  }, [t]);
  const handleClose = React.useCallback(() => {
    if (isCover) {
      closeCover?.();
    } else {
      dispatch(actions.resetSidePanel());
    }
  }, [isCover, closeCover, dispatch, actions]);
  const refreshTable = React.useCallback(() => {
    dispatch(actions.setRefreshTable(true));
  }, [dispatch, actions]);

  React.useEffect(() => {
    if (closeApproved) {
      if (continueToOther.continueOnApprove) {
        dispatch(actions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            actions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(actions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(notifyActions.navigate(continueLink));
        dispatch(actions.resetSidePanel());
      } else {
        dispatch(actions.resetSidePanel());
      }
    }
    if (closeRejected) {
      dispatch(actions.resetConfirmState());
    }
  }, [
    actions,
    closeApproved,
    closeRejected,
    continueLink,
    continueToOther,
    dispatch,
    notifyActions,
  ]);
  React.useEffect(() => {
    if (!isEdit) {
      setInitialValues({
        Id: initialDetails?.Id ?? 0,
        Equipment:
          initialDetails !== undefined
            ? {
                Id: initialDetails.EquipmentID,
                Name: initialDetails.EquipmentName,
              }
            : Equipment ?? null,
        User:
          initialDetails !== undefined
            ? {
                Id: initialDetails.UserName,
                Name: initialDetails.UserDisplayName,
              }
            : SignUpUser ?? {
                Id: authenticatedUser?.Id ?? '',
                Name: authenticatedUser?.DisplayName ?? '',
              },
        BeforeResCancelHour: initialDetails?.BeforeResCancelHour ?? 0,
        //BeforeResStartHour: initialDetails?.BeforeResStartHour ?? 0,
        //BeforeTrainStartHour: initialDetails?.BeforeTrainStartHour ?? 0,
        NextResTermHours: initialDetails?.NextResTermHours ?? 0,
        NextReservationHours: initialDetails?.NextReservationHours ?? 0,
        NotifyOnEndUsage: initialDetails?.NotifyOnEndUsage
          ? (initialDetails?.NextReservationHours ?? 0) > 0
            ? 'true'
            : 'false'
          : '',
        NotifyOnResCancelation: (
          initialDetails?.NotifyOnResCancelation ?? false
        ).toString(),
        NotifyOnResTermination: initialDetails?.NotifyOnResTermination
          ? (initialDetails?.NextResTermHours ?? 0) > 0
            ? 'true'
            : 'false'
          : '',
        // UpcomingReservations: (
        //   initialDetails?.UpcomingReservations ?? false
        // ).toString(),
        // UpcomingTrainingSession: (
        //   initialDetails?.UpcomingTrainingSession ?? false
        // ).toString(),
      });
      return;
    } else {
      console.log('effect operated');
      if (Id === undefined && initialDetails !== undefined) {
        setInitialValues({
          Id: initialDetails.Id,
          Equipment: {
            Id: initialDetails.EquipmentID,
            Name: initialDetails.EquipmentName,
          },
          User: {
            Id: initialDetails.UserName,
            Name: initialDetails.UserDisplayName,
          },
          BeforeResCancelHour: initialDetails.BeforeResCancelHour ?? 0,
          //BeforeResStartHour: initialDetails?.BeforeResStartHour ?? 0,
          //BeforeTrainStartHour: initialDetails?.BeforeTrainStartHour ?? 0,
          NextResTermHours: initialDetails.NextResTermHours ?? 0,
          NextReservationHours: initialDetails.NextReservationHours ?? 0,
          NotifyOnEndUsage: initialDetails.NotifyOnEndUsage
            ? initialDetails.NextReservationHours > 0
              ? 'true'
              : 'false'
            : '',
          NotifyOnResCancelation: (
            initialDetails.NotifyOnResCancelation ?? false
          ).toString(),
          NotifyOnResTermination: initialDetails.NotifyOnResTermination
            ? initialDetails.NextResTermHours > 0
              ? 'true'
              : 'false'
            : '',
          // UpcomingReservations: (
          //   initialDetails?.UpcomingReservations ?? false
          // ).toString(),
          // UpcomingTrainingSession: (
          //   initialDetails?.UpcomingTrainingSession ?? false
          // ).toString(),
        });
      } else {
        setBusy(true);
        let mounted = true;
        (async () => {
          try {
            const res = await api.getSignUp(Id ?? 0);
            let item = res as IReservationWaitingListDto;

            if (mounted) {
              setLoadedDetails(res);
              setInitialValues({
                Id: item.Id,
                Equipment: {
                  Id: item.EquipmentID,
                  Name: item.EquipmentName,
                },
                User: {
                  Id: item.UserName,
                  Name: item.UserDisplayName,
                },
                BeforeResCancelHour: item.BeforeResCancelHour ?? 0,
                //BeforeResStartHour: initialDetails?.BeforeResStartHour ?? 0,
                //BeforeTrainStartHour: initialDetails?.BeforeTrainStartHour ?? 0,
                NextResTermHours: item.NextResTermHours ?? 0,
                NextReservationHours: item.NextReservationHours ?? 0,
                NotifyOnEndUsage: item.NotifyOnEndUsage
                  ? item.NextReservationHours > 0
                    ? 'true'
                    : 'false'
                  : '',
                NotifyOnResCancelation: (
                  item.NotifyOnResCancelation ?? false
                ).toString(),
                NotifyOnResTermination: item.NotifyOnResTermination
                  ? item.NextResTermHours > 0
                    ? 'true'
                    : 'false'
                  : '',
                // UpcomingReservations: (
                //   initialDetails?.UpcomingReservations ?? false
                // ).toString(),
                // UpcomingTrainingSession: (
                //   initialDetails?.UpcomingTrainingSession ?? false
                // ).toString(),
              });

              setBusy(false);
            }
          } catch (error) {
            setBusy(false);
            console.error(error);
          }
        })();
        return () => {
          mounted = false;
        };
      }
    }
  }, [
    Equipment,
    Id,
    SignUpUser,
    authenticatedUser?.DisplayName,
    authenticatedUser?.Id,
    initialDetails,
    isEdit,
  ]);
  const handleSubmit = React.useCallback(
    (model: ISignUpModel) => {
      setBusy(true);
      const insertModel = convertSignUpToModel(
        model,
        initialDetails ?? loadedDetails,
      );
      if (!isEdit) {
        api
          .insertSignUp(insertModel)
          .then(res => {
            setBusy(false);
            if (res.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpError',
                  message: res.ErrorMessages[0],
                  variant: 'error',
                }),
              );
            } else {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpSuccess',
                  message: res.SuccessMessages[0],
                  messageType: SnackBarMessageType.openSidepanelDetails,
                  messageTypeProps: {
                    Id: res.Id ?? undefined,
                    closeMessageOnPanelOpen: true,
                    created: true,
                    itemName: t(
                      translations.UserNotificationsSignUpToWaitingList,
                    ),
                    detailsType: RenderPageType.SignUpWaitingListDetails,
                    detailsTypeProps: {
                      useSidePanel: true,
                      Id: res.Id,
                      // initialDetails: Object.assign({}, insertModel, {
                      //   Id: res.Id,
                      // }),
                    } as SignUpWaitingListDetailsProps,
                  },
                  variant: 'success',
                }),
              );
              handleClose();
              refreshTable();
            }
          })
          .catch((error: unknown) => {
            setBusy(false);
            const message =
              (error as AxiosError)?.response?.data?.error?.innererror
                ?.message ??
              ((error as AxiosError)?.response?.status === 403
                ? t(translations.Forbidden)
                : undefined) ??
              t(translations.errormessage);
            dispatch(
              notifyActions.addNotification({
                key: 'signUpError',
                message: message,
                variant: 'error',
              }),
            );
          });
      } else {
        api
          .updateSignUp(insertModel, insertModel.Id)
          .then(res => {
            setBusy(false);
            if (res.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpUpdateError',
                  message: res.ErrorMessages[0],
                  variant: 'error',
                }),
              );
            } else {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpSuccess',
                  message: res.SuccessMessages[0],
                  messageType: SnackBarMessageType.openSidepanelDetails,
                  messageTypeProps: {
                    Id: res.Id ?? undefined,
                    closeMessageOnPanelOpen: true,
                    created: false,
                    itemName: t(
                      translations.UserNotificationsSignUpToWaitingList,
                    ),
                    detailsType: RenderPageType.SignUpWaitingListDetails,
                    detailsTypeProps: {
                      useSidePanel: true,
                      Id: res.Id,
                      // initialDetails: insertModel,
                    } as SignUpWaitingListDetailsProps,
                  },
                  variant: 'success',
                }),
              );
              handleClose();
              refreshTable();
            }
          })
          .catch((error: unknown) => {
            setBusy(false);
            const message =
              (error as AxiosError)?.response?.data?.error?.innererror
                ?.message ??
              ((error as AxiosError)?.response?.status === 403
                ? t(translations.Forbidden)
                : undefined) ??
              t(translations.errormessage);
            dispatch(
              notifyActions.addNotification({
                key: 'signUpUpdateError',
                message: message,
                variant: 'error',
              }),
            );
          });
      }
    },
    [
      dispatch,
      handleClose,
      initialDetails,
      isEdit,
      loadedDetails,
      notifyActions,
      refreshTable,
      t,
    ],
  );
  const handleSimpleSubmit = React.useCallback(
    (model: ISimpleSignUpModel) => {
      setBusy(true);
      const updatedModel = convertSimpleSignUpToModel(
        model,
        initialDetails ?? loadedDetails,
        usageEnabled,
      );
      if (!isEdit) {
        api
          .insertSignUp(updatedModel)
          .then(res => {
            setBusy(false);
            if (res.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpError',
                  message: res.ErrorMessages[0],
                  variant: 'error',
                }),
              );
            } else {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpSuccess',
                  message: res.SuccessMessages[0],
                  messageType: SnackBarMessageType.openSidepanelDetails,
                  messageTypeProps: {
                    Id: res.Id ?? undefined,
                    created: true,
                    closeMessageOnPanelOpen: true,
                    itemName: t(
                      translations.UserNotificationsSignUpToWaitingList,
                    ),
                    detailsType: RenderPageType.SignUpWaitingListDetails,
                    detailsTypeProps: {
                      useSidePanel: true,
                      initialDetails: Object.assign({}, updatedModel, {
                        Id: res.Id,
                      }),
                    } as SignUpWaitingListDetailsProps,
                  },
                  variant: 'success',
                }),
              );
              handleClose();
              refreshTable();
            }
          })
          .catch((error: unknown) => {
            setBusy(false);
            const message =
              (error as AxiosError)?.response?.data?.error?.innererror
                ?.message ??
              ((error as AxiosError)?.response?.status === 403
                ? t(translations.Forbidden)
                : undefined) ??
              t(translations.errormessage);
            dispatch(
              notifyActions.addNotification({
                key: 'signUpError',
                message: message,
                variant: 'error',
              }),
            );
          });
      } else {
        api
          .updateSignUp(updatedModel, updatedModel.Id)
          .then(res => {
            setBusy(false);
            if (res.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpUpdateError',
                  message: res.ErrorMessages[0],
                  variant: 'error',
                }),
              );
            } else {
              dispatch(
                notifyActions.addNotification({
                  key: 'signUpSuccess',
                  message: res.SuccessMessages[0],
                  messageType: SnackBarMessageType.openSidepanelDetails,
                  messageTypeProps: {
                    Id: res.Id ?? undefined,
                    closeMessageOnPanelOpen: true,
                    created: false,
                    itemName: t(
                      translations.UserNotificationsSignUpToWaitingList,
                    ),
                    detailsType: RenderPageType.SignUpWaitingListDetails,
                    detailsTypeProps: {
                      useSidePanel: true,
                      initialDetails: updatedModel,
                    } as SignUpWaitingListDetailsProps,
                  },
                  variant: 'success',
                }),
              );
              handleClose();
              refreshTable();
            }
          })
          .catch((error: unknown) => {
            setBusy(false);
            const message =
              (error as AxiosError)?.response?.data?.error?.innererror
                ?.message ??
              ((error as AxiosError)?.response?.status === 403
                ? t(translations.Forbidden)
                : undefined) ??
              t(translations.errormessage);
            dispatch(
              notifyActions.addNotification({
                key: 'signUpUpdateError',
                message: message,
                variant: 'error',
              }),
            );
          });
      }
    },
    [
      dispatch,
      handleClose,
      initialDetails,
      isEdit,
      loadedDetails,
      notifyActions,
      refreshTable,
      t,
      usageEnabled,
    ],
  );
  const SignUpForm = React.memo(function SignUpForm() {
    const inputProps = {
      step: 1,
      min: 0,
      max: 1000,
      'aria-label': 'Counter',
      style: {
        minWidth: '30px',
        padding: '6px 6px',
        textAlign: 'center' as 'center',
      },
    };
    return !!initialValues ? (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {formik => {
          submitRef.current = formik.submitForm;
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              <FormLeftSection>
                <FormFieldsSection>
                  <FormRow fullRow>
                    <FormOnlineServicesSinglePicker
                      name="Equipment"
                      fullWidth
                      placeholder={t(translations.Equipment)}
                      label={t(translations.Equipment)}
                      disabled={isEdit || busy}
                    />
                  </FormRow>
                  {userVisible &&
                    formik.values.Equipment !== null &&
                    authenticatedUser?.IsAllGroupOrLabTechAdmin(undefined, [
                      {
                        Id: formik.values.Equipment.Id,
                        Name: formik.values.Equipment.Name,
                        ServiceTypeId: 1,
                      } as IServiceTypeFilterDto,
                    ]) && (
                      <FormRow fullRow>
                        <FormUserPicker
                          name="User"
                          selectArray={[
                            'Id',
                            'Name',
                            'UserGroupId',
                            'UserGroupName',
                          ]}
                          label={t(translations.UserName)}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow>
                    )}
                  {isAdminView && (
                    <FormRow fullRow>
                      <FormLabelText
                        id="userId"
                        name="User"
                        label={t(translations.User) as string}
                        html={
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {formik.values.User !== null && (
                              <UserProfileLink
                                userName={formik.values.User.Id}
                                displayName={formik.values.User.Name}
                                twoRows={false}
                              />
                            )}
                          </Box>
                        }
                      />
                    </FormRow>
                  )}
                  {/* NotifyOnResCancelation */}
                  <FormRow fullRow>
                    <FormRadioGroupBase
                      label={t(translations.ReservationCancel)}
                      name="NotifyOnResCancelation"
                      id="NotifyOnResCancelation_Id"
                      fullWidth
                      options={cancelOptions}
                      shortLabel
                      inputId="ResCancelHourID"
                      inputName="BeforeResCancelHour"
                      inputMode="numeric"
                      inputProps={inputProps}
                      useInputField={true}
                      inputAfterLabel={'hours'}
                      onInputFocus={() =>
                        formik
                          .getFieldHelpers('NotifyOnResCancelation')
                          .setValue('true')
                      }
                    />
                  </FormRow>
                  {/* NotifyOnResTermination */}
                  <FormRow fullRow>
                    <FormRadioGroupBase
                      label={t(translations.ResTerminationNotification)}
                      name="NotifyOnResTermination"
                      id="NotifyOnResTermination_Id"
                      fullWidth
                      options={termOptions}
                      shortLabel
                      inputId="ResTermHoursID"
                      inputName="NextResTermHours"
                      inputMode="numeric"
                      inputProps={inputProps}
                      useInputField={true}
                      inputAfterLabel={'hours'}
                      onInputFocus={() =>
                        formik
                          .getFieldHelpers('NotifyOnResTermination')
                          .setValue('true')
                      }
                    />
                  </FormRow>
                  {/* NotifyOnEndUsage */}
                  {usageEnabled && (
                    <FormRow fullRow>
                      <FormRadioGroupBase
                        label={t(translations.UsageEndNotification)}
                        name="NotifyOnEndUsage"
                        id="NotifyOnEndUsage_Id"
                        fullWidth
                        options={usageOptions}
                        shortLabel
                        inputId="UsageHoursID"
                        inputName="NextReservationHours"
                        inputMode="numeric"
                        inputProps={inputProps}
                        useInputField={true}
                        inputAfterLabel={'hours'}
                        onInputFocus={() =>
                          formik
                            .getFieldHelpers('NotifyOnEndUsage')
                            .setValue('true')
                        }
                      />
                    </FormRow>
                  )}
                  {simpleMode.isSimpleMode && isSimpleView === false && (
                    <FormRow>
                      <Button
                        variant="textGray"
                        size="small"
                        onClick={() => {
                          setIsSimpleView(true);
                          if (
                            !isEdit &&
                            (formik.values.Equipment !== null ||
                              formik.values.User !== null)
                          ) {
                            setInitialValues(
                              Object.assign({}, initialValues, {
                                Equipment: formik.values.Equipment,
                                User: formik.values.User,
                              }),
                            );
                          }
                        }}
                      >
                        {t(translations.UserNotificationsResetToBasic)}
                      </Button>
                    </FormRow>
                  )}
                </FormFieldsSection>
              </FormLeftSection>
            </StyledForm>
          );
        }}
      </Formik>
    ) : null;
  });
  const SimpleSignUpForm = React.memo(function SimpleSignUpForm() {
    const inputProps = {
      step: 1,
      min: 0,
      max: 1000,
      'aria-label': 'Counter',
      style: {
        minWidth: '30px',
        padding: '6px 6px',
        textAlign: 'center' as 'center',
      },
    };
    return (
      <Formik
        validationSchema={simpleschema}
        initialValues={{
          Id: initialValues?.Id ?? 0,
          Equipment: initialValues?.Equipment ?? null,
          User: initialValues?.User ?? null,
          WindowHours: simpleMode.hoursCount ?? 0,
        }}
        onSubmit={handleSimpleSubmit}
      >
        {formik => {
          simplesubmitRef.current = formik.submitForm;
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              {/* By using FormListener, the fields become not editable */}
              {/* <FormListener fields={[]} onFormChange={() => {}} /> */}
              <FormLeftSection>
                <FormFieldsSection>
                  <FormRow fullRow>
                    <FormOnlineServicesSinglePicker
                      name="Equipment"
                      fullWidth
                      placeholder={t(translations.Equipment)}
                      label={t(translations.Equipment)}
                      disabled={isEdit || busy}
                    />
                  </FormRow>
                  {userVisible &&
                    formik.values.Equipment !== null &&
                    authenticatedUser?.IsAllGroupOrLabTechAdmin(undefined, [
                      {
                        Id: formik.values.Equipment.Id,
                        Name: formik.values.Equipment.Name,
                        ServiceTypeId: 1,
                      } as IServiceTypeFilterDto,
                    ]) && (
                      <FormRow fullRow>
                        <FormUserPicker
                          name="User"
                          selectArray={[
                            'Id',
                            'Name',
                            'UserGroupId',
                            'UserGroupName',
                          ]}
                          label={t(translations.UserName)}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow>
                    )}
                  {isAdminView && (
                    <FormRow fullRow>
                      <FormLabelText
                        id="userId"
                        name="User"
                        label={t(translations.User) as string}
                        html={
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {formik.values.User !== null && (
                              <UserProfileLink
                                userName={formik.values.User.Id}
                                displayName={formik.values.User.Name}
                                twoRows={false}
                              />
                            )}
                          </Box>
                        }
                      />
                    </FormRow>
                  )}
                </FormFieldsSection>
                <FormFieldsSection
                  titleSection={t(translations.WaitingListOptions)}
                >
                  <span>{t(translations.UserNotifications_SimpleMode)}</span>
                  <FormRow fullRow>
                    <FormWindowHoursField
                      name="WindowHours"
                      id="WindowHours_Id"
                      inputMode="numeric"
                      inputProps={inputProps}
                      style={{ width: '100px' }}
                    />
                    <span>{'hours'}</span>
                  </FormRow>
                  <FormRow>
                    <Button
                      variant="textGray"
                      size="small"
                      startIcon={<Icon icon="plus" />}
                      onClick={() => {
                        setIsSimpleView(false);
                        if (
                          !isEdit &&
                          (formik.values.Equipment !== null ||
                            formik.values.User !== null)
                        ) {
                          setInitialValues(
                            Object.assign({}, initialValues, {
                              Equipment: formik.values.Equipment,
                              User: formik.values.User,
                            }),
                          );
                        }
                      }}
                    >
                      {t(translations.UserNotificationsSwitchToAdvanced)}
                    </Button>
                  </FormRow>
                </FormFieldsSection>
              </FormLeftSection>
            </StyledForm>
          );
        }}
      </Formik>
    );
  });
  const leftActions = [
    () => (
      <>
        <Button
          onClick={() =>
            simpleMode.isSimpleMode
              ? isSimpleView === undefined || isSimpleView === true
                ? simplesubmitRef.current?.()
                : submitRef.current?.()
              : submitRef.current?.()
          }
          size="small"
          disabled={busy}
        >
          {t(translations.Submit)}
        </Button>
      </>
    ),
  ];
  const rightActions = [
    () => (
      <>
        <Button
          variant="ghost"
          startIcon={<Icon icon="times" />}
          onClick={handleClose}
          size="small"
          disabled={busy}
        >
          {t(translations.Cancel)}
        </Button>
      </>
    ),
  ];
  return (
    <PageWrapper
      id="sign_up_res_notifications"
      topProcessing={busy}
      titlePage={
        isEdit
          ? t(translations.ReservationSignUpWaitingListDetails)
          : t(translations.ReservationNewWaitingListSignUp)
      }
      closable={true}
      useSidePanel={true}
      children={
        initialValues ? (
          <>
            {simpleMode.isSimpleMode ? (
              isSimpleView === undefined || isSimpleView === true ? (
                <SimpleSignUpForm />
              ) : (
                <SignUpForm />
              )
            ) : (
              <SignUpForm />
            )}
          </>
        ) : null
      }
      leftActions={leftActions}
      rightActions={rightActions}
      disableExpandToggle={true}
      closeSidePanel={() => {
        if (hasChanges) {
          dispatch(actions.setConfirmOpen(true));
        } else {
          dispatch(actions.resetSidePanel());
        }
      }}
      isCover={props.isCover}
      closeCover={props.closeCover}
    />
  );
}
