import { IRow } from 'app/components/BasicTable';
import { getDependencyFilters } from 'app/components/BasicTable/BasicFilter';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { getFilterValue } from 'app/components/BasicTable/getFilterValue';
import { initFilterData } from 'app/components/BasicTable/initFilterData';
import { visibleFiltersFilter } from 'app/components/BasicTable/withSavedHistory';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectglobalSettings,
} from 'app/slice/selectors';
import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export interface FilterValue {
  Name: string;
  Value: FilterValueType | null;
}
export class BaseFilterValues {
  GetFilter = (): Array<FilterValue> => {
    const res: Array<FilterValue> = Object.entries(this)
      .filter(v => typeof v[1] !== 'function')
      .map(v => {
        return {
          Name: v[0],
          Value: v[1] as FilterValueType | null,
        };
      });
    return res;
  };
  SetValue = (name: string, value: FilterValueType | null) => {
    this[name] = value;
  };
}
export function useFilters<TRow extends IRow>(
  GetFiltersDefinition: GetPageFilters<TRow>,
  filterBy?: FilterValue[],
  setValue?: (name: string, value: FilterValueType | null) => void,
) {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const showFiltersFn = React.useCallback(visibleFiltersFilter, []);
  const getLocalizedFilters = useMemo(() => {
    const result = GetFiltersDefinition(
      t,
      appSettings,
      authenticatedUser ?? null,
      settings,
    );
    return result;
  }, [GetFiltersDefinition, appSettings, authenticatedUser, settings, t]);

  const allFilters = useMemo(() => {
    var result = getLocalizedFilters('');
    // get the necessary filters
    if (filterBy !== undefined && filterBy.length > 0) {
      result = result.filter(
        f => filterBy.findIndex(v => v.Name === f.id) >= 0,
      );
      // Set saved values
      for (const f of filterBy) {
        let rf = result.find(r => r.id === f.Name);
        if (rf !== undefined) {
          rf.value = f.Value;
        }
      }
    }
    return result;
  }, [filterBy, getLocalizedFilters]);

  const [appliedFilters, setAppliedFilters] = useAsyncExtendedState<
    IFilterSettings<TRow>[] | undefined
  >(undefined);
  const handleFilterChange: (
    items: IFilterSettings<TRow>[],
  ) => void = React.useCallback(
    items => {
      const newState = [...items.filter(showFiltersFn)];

      setAppliedFilters(getDependencyFilters(newState));

      // Save values
      if (setValue !== undefined) {
        for (const f of newState) {
          setValue(
            f.fieldName as string,
            f.value !== undefined ? f.value : null,
          );
        }
      }
    },
    [setAppliedFilters, setValue, showFiltersFn],
  );
  const setFilterValueCallback = React.useCallback(
    (id: string, value: FilterValueType) => {
      if (allFilters === undefined) {
        return;
      }
      const filter = allFilters?.find(f => f.id === id);
      // in cases when applied filter is not defined call to setAppliedFilters will just only mutate appliedFilters array and cause duplicate/unnecessary invocation of hooks that have appliedFilters as a dependency
      if (filter !== undefined) {
        filter.value = value;
        const x = allFilters
          .map(f => (f === filter ? { ...filter, ...{ value: value } } : f))
          .filter(showFiltersFn);
        handleFilterChange(x);
      }
    },
    [allFilters, handleFilterChange, showFiltersFn],
  );
  const getFilterValueCallback = React.useCallback(
    (id: string) => {
      const f = getFilterValue(appliedFilters, id);
      return f;
    },
    [appliedFilters],
  );
  React.useEffect(() => {
    if (authenticatedUser === undefined) {
      return;
    }
    // get the filters
    let visibleFilters = allFilters?.filter(showFiltersFn);
    let depFilters = getDependencyFilters(visibleFilters);
    setAppliedFilters(depFilters);
    setAppliedFilters(initFilterData(depFilters, globalServiceGroupFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalServiceGroupFilter]);
  return {
    allfilters: allFilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue: getFilterValueCallback,
    setFilterValue: setFilterValueCallback,
  };
}
