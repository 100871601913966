import { ToolbarProps as MuiToolbarProps } from '@material-ui/core';
import React from 'react';
import { Body, H1 } from 'app/components/Typography';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';

export interface TableToolbarHeaderProps
  extends Pick<MuiToolbarProps, 'variant'> {
  isMobile: boolean;
  compact?: boolean;
  screenName: React.ReactNode;
  customScreenName?: React.ReactNode;
  /**
   * Controls the size of the table title. Defaults to H1 for list pages.
   */
  screenNameVariant?: 'H1' | 'section';
}
/**
 * Renders responsive page/table header.
 * Nothing fancy, just wraps the header content in different components for desktop/mobile. Using responsive styling might have been more appropriate here
 * @param props
 * @returns
 */
export function TableToolbarHeader({
  compact,
  isMobile,
  screenName,
  customScreenName,
  screenNameVariant,
  variant,
}: React.PropsWithChildren<TableToolbarHeaderProps>) {
  const headerProps = { id: 'table-toolbar-heading' };
  if (compact || isMobile) {
    return (
      <Body bold={true} {...headerProps}>
        {screenName}
      </Body>
    );
  } else if (!!customScreenName) {
    return <React.Fragment>{customScreenName}</React.Fragment>;
  } else if (screenNameVariant === 'section') {
    return <FormSectionTitle title={screenName} />;
  } else {
    return (
      <H1 gutterBottom={variant === 'regular'} {...headerProps}>
        {screenName}
      </H1>
    );
  }
}
