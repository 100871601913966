import styled, { withTheme } from 'styled-components';
import { Theme, AppBar, AppBarProps, MenuProps, Menu } from '@material-ui/core';
import { bookitColors } from 'styles/colors/bookitColors';
import transitions from '@material-ui/core/styles/transitions';

export const StyledTopBar = styled(
  ({
    theme,
    drawerWidth,
    sidePanelWidth,
    children,
    ...other
  }: {
    theme: Theme;
    drawerWidth: number;
    sidePanelWidth: number;
    children: React.ReactNode;
  } & AppBarProps) => <AppBar {...other}>{children}</AppBar>,
)`
  &.appBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    background-color: ${bookitColors.base.white};
    z-index: ${props => props.theme.zIndex.drawer + 1};
    transition: ${props =>
      props.theme.transitions.create(['width', 'margin'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.leavingScreen,
      })};
    border-bottom: 1px solid ${bookitColors.grayscale.grayBorders};
    width: calc(100% - ${60 + 1}px);
    &.appBarShift {
      margin-left: ${props => props.drawerWidth}px;
      width: calc(100% - ${props => props.drawerWidth}px);
      transition: ${props =>
        props.theme.transitions.create(['width', 'margin'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen,
        })};
    }
    &.sidePanelShift {
      margin-right: ${props => props.sidePanelWidth}px;
      width: calc(100% - ${props => 60 + 1 + props.sidePanelWidth}px);
      &.appBarShift {
        width: calc(
          100% - ${props => props.drawerWidth + props.sidePanelWidth}px
        );
      }
    }
  }
`;

export const SubTopBar = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.subBar {
    display: flex;
    height: 56px;
    padding: 4px 16px 4px 32px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    width: 100%;
    gap: ${props => props.theme.spacing(2)}px;
    border-top: 1px solid ${bookitColors.grayscale.grayBorders};
    background: ${bookitColors.primary.background};
    transition: ${transitions.create('height', {
      easing: transitions.easing.easeInOut,
      duration: 150,
    })};
    &.subBarCollapsed {
      height: 0;
      display: none;
    }
    & .frame {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      & .controls {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
      }
    }
  }
`;

export const ProfileSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.userProfile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
`;

export const StyledUserMenu = withTheme(styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & MenuProps) => <Menu {...other}>{children}</Menu>,
)`
  & li {
    & a {
      margin-left: ${props => props.theme.spacing(2)}px;
      color: ${props => props.theme.palette.text.primary};
    }
  }
`);
