import * as React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useTheme } from '@material-ui/core';
import { BasicAlert } from 'app/components/BasicAlert';
import { IndexNode } from '../StyledForm/index';
import clsx from 'clsx';
import { LeftSection } from '../wrappers';

export interface FormLeftSectionProps {
  pageDescription?: string;
  warning?: string;
  info?: string;
  children: React.ReactNode;
  showShortView?: boolean;
  errors?: IndexNode[];
  warningNodes?: IndexNode[];
  infoNodes?: IndexNode[];
  successNodes?: IndexNode[];
  isCover?: boolean;
}
export const FormLeftSection = (props: FormLeftSectionProps) => {
  const {
    pageDescription,
    children,
    warning,
    info,
    errors,
    warningNodes,
    infoNodes,
    successNodes,
    isCover,
  } = props;
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const theme = useTheme();
  const showShortView = React.useMemo(() => {
    return (
      props.showShortView ||
      isMobile ||
      (sidePanelOpen && !sidePanelExpanded) ||
      isCover
    );
  }, [
    isCover,
    isMobile,
    props.showShortView,
    sidePanelExpanded,
    sidePanelOpen,
  ]);

  const [dataErrors, setDataErrors] = React.useState<IndexNode[]>(errors || []);
  const [dataWarnings, setDataWarnings] = React.useState<IndexNode[]>(
    warningNodes || [],
  );
  const [dataSuccess, setDataSuccess] = React.useState<IndexNode[]>(
    successNodes || [],
  );
  const [dataInfos, setDataInfos] = React.useState<IndexNode[]>(
    infoNodes || [],
  );
  const colapsedMinHeight = React.useMemo(() => {
    return showShortView ? 60 : 50;
  }, [showShortView]);
  const handleCloseError = React.useCallback(
    (index: number) => {
      if (dataErrors.length > 0) {
        setDataErrors(() => dataErrors.splice(index, 1));
      }
    },
    [dataErrors],
  );
  const handleCloseWarning = React.useCallback(
    (index: number) => {
      if (dataWarnings.length > 0) {
        setDataWarnings(() => dataWarnings.splice(index, 1));
      }
    },
    [dataWarnings],
  );
  const handleCloseInfo = React.useCallback(
    (index: number) => {
      if (dataInfos.length > 0) {
        setDataInfos(() => dataInfos.splice(index, 1));
      }
    },
    [dataInfos],
  );
  const handleCloseSuccess = React.useCallback(
    (index: number) => {
      if (dataSuccess.length > 0) {
        setDataSuccess(() => dataSuccess.splice(index, 1));
      }
    },
    [dataSuccess],
  );
  React.useEffect(() => {
    if (!!errors && errors.length > 0) {
      setDataErrors(errors);
    } else {
      setDataErrors([]);
    }
    if (!!warningNodes && warningNodes.length > 0) {
      setDataWarnings(warningNodes);
    } else {
      setDataWarnings([]);
    }
    if (!!infoNodes && infoNodes.length > 0) {
      setDataInfos(infoNodes);
    } else {
      setDataInfos([]);
    }
    if (!!successNodes && successNodes.length > 0) {
      setDataSuccess(successNodes);
    } else {
      setDataSuccess([]);
    }
  }, [errors, infoNodes, successNodes, warningNodes]);
  return (
    <LeftSection
      className={clsx('leftsection', { shortView: showShortView })}
      theme={theme}
    >
      {pageDescription && (
        <BasicAlert
          showMore={true}
          collapsedMinHeight={colapsedMinHeight}
          content={{
            type: 'info',
            closable: true,
            message: pageDescription,
          }}
        />
      )}
      {warning && (
        <BasicAlert
          collapsedMinHeight={colapsedMinHeight}
          showMore={true}
          content={{
            type: 'warning',
            closable: true,
            message: warning,
          }}
        />
      )}
      {info && (
        <BasicAlert
          showMore={true}
          collapsedMinHeight={colapsedMinHeight}
          content={{
            type: 'info',
            closable: true,
            message: info,
          }}
        />
      )}
      {dataSuccess.length > 0 && (
        <React.Fragment>
          {dataSuccess.map((succ, index) => (
            <BasicAlert
              key={succ.index}
              collapsedMinHeight={colapsedMinHeight}
              content={{
                type: 'success',
                closable: true,
                children: succ.node,
                variant: succ.variant,
              }}
              variant={succ.variant}
              showMore={succ.showMore === undefined ? true : succ.showMore}
              onClose={ev => handleCloseSuccess(index)}
            />
          ))}
        </React.Fragment>
      )}
      {dataInfos.length > 0 && (
        <React.Fragment>
          {dataInfos.map((info, index) => (
            <BasicAlert
              key={info.index}
              collapsedMinHeight={colapsedMinHeight}
              content={{
                type: 'info',
                closable: true,
                children: info.node,
                variant: info.variant,
              }}
              variant={info.variant}
              showMore={info.showMore === undefined ? true : info.showMore}
              onClose={ev => handleCloseInfo(index)}
            />
          ))}
        </React.Fragment>
      )}
      {dataWarnings.length > 0 && (
        <React.Fragment>
          {dataWarnings.map((warn, index) => (
            <BasicAlert
              key={warn.index}
              collapsedMinHeight={colapsedMinHeight}
              content={{
                type: 'warning',
                closable: true,
                children: warn.node,
                variant: warn.variant,
              }}
              variant={warn.variant}
              showMore={warn.showMore === undefined ? true : warn.showMore}
              onClose={ev => handleCloseWarning(index)}
            />
          ))}
        </React.Fragment>
      )}
      {dataErrors.length > 0 && (
        <React.Fragment>
          {dataErrors.map((err, index) => (
            <BasicAlert
              key={err.index}
              collapsedMinHeight={colapsedMinHeight}
              content={{
                type: 'error',
                closable: true,
                children: err.node,
                variant: err.variant,
              }}
              variant={err.variant}
              showMore={err.showMore === undefined ? true : err.showMore}
              onClose={ev => handleCloseError(index)}
            />
          ))}
        </React.Fragment>
      )}
      {children}
    </LeftSection>
  );
};
