import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import {
  pushAssetAvailabilityFilter,
  pushAssetCatFilter,
  pushAssetCatGroupFilter,
  pushBookedByFilter,
  pushBudgetManagerFilter,
  pushBuildingFilter,
  pushCampusFilter,
  pushRoomFilter,
  pushServicesCalendarFilter,
} from 'app/pages/ReservationHistoryPage/Filter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { getTrainingStatePickerFilter } from 'app/components/BasicTable/Filters/TrainingStatePickerFilter';
import { getReservationOrDowntimePickerFilter } from 'app/components/BasicTable/Filters/ReservationOrDowntimePickerFilter';
import { pushReservationsOnlyFilter } from 'app/components/BasicTable/Filters/WithReservationsOnlyFilter';
import { getBudgetsFilterSettings } from 'app/components/BasicTable/Filters/ProjectFilter/getBudgetsFilterSettings';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { Identifiable } from 'types/common';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';
import { isNullOrUndefined } from 'utils/typeUtils';

export type CombinedSchedulerTRow = ICalendarReservationDto &
  ITrainingSessionDto & {
    ReservationOrDowntime: number | null;
    WithReservationsOnly: boolean | null;
  };

export const getFilters: GetPageFilters<CombinedSchedulerTRow> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParamsCI(search);
    const result: Array<IFilterSettings<CombinedSchedulerTRow>> = [];
    pushServicesCalendarFilter(result, t, params, false, 'Id', 'reserved');
    pushAssetCatFilter(result, t, params);
    pushAssetCatGroupFilter(result, t, params);
    pushRoomFilter(result, t, params, undefined, {
      dependencyProp: ['AssetBuildingId'],
      filterPredicates: (value, fieldName) => {
        if (isNullOrUndefined(value)) {
          return [];
        }
        if (fieldName === 'AssetBuildingId') {
          return [
            `${new Condition<IRoomDto>(
              'BuildingId',
              ODataOperators.Equals,
              (value as Identifiable<number>).Id,
            ).toString()}`,
          ];
        }
        return [];
      },
    });
    pushReservationsOnlyFilter(result, t, params);
    // result.push(
    //   getWithReservationsOnlyFilter<CombinedSchedulerTRow>(
    //     'WithReservationsOnly',
    //     params,
    //     t,
    //   ),
    // );
    pushCampusFilter(appSettings, result, t, params, true);
    pushBuildingFilter(result, t, params, true);
    const budgetsFilter = getBudgetsFilterSettings<CombinedSchedulerTRow>(
      params,
      'BudgetId',
      appSettings,
    );
    if (budgetsFilter !== undefined) {
      result.push({ ...budgetsFilter, notDefault: true });
    }
    pushBudgetManagerFilter(appSettings, result, t, params, true);
    pushAssetAvailabilityFilter(result, t, params, true);
    pushBookedByFilter(result, t, params, true);

    result.push({
      ...getTrainingStatePickerFilter<CombinedSchedulerTRow>(
        'TrainingState',
        params,
        t,
      ),
      notDefault: true,
    });
    result.push({
      ...getReservationOrDowntimePickerFilter<CombinedSchedulerTRow>(
        'ReservationOrDowntime',
        params,
        t,
      ),
      notDefault: true,
    });

    return result;
  };
