/**
 *
 * BasicTable
 *
 */
import * as React from 'react';
import {
  UseSortByState,
  UsePaginationState,
  SortingRule,
  TableState,
  UseGroupByState,
  Column,
  UseRowSelectState,
  UseGlobalFiltersState,
} from 'react-table';
import { httpClient } from 'api/HttpClient';
import { CancelToken } from 'axios';
import { BasicTableProps } from './BasicTableProps';
import { IRow } from './IRow';
import { IFilterSettings } from './BasicFilter/IFilterSettings';
import {
  getExportParameters,
  buildURL,
  openExportLink,
  IPathAndQuery,
  getExportForImportParameters,
} from 'utils/url-utils';
import { Entity } from 'types/common';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useDispatch, useSelector } from 'react-redux';
//import { Progress } from '../LoadingIndicator';
import { buildParams } from 'api/odata/ODataParamsBuilder';
import { useConfigurableColumns } from './useConfigurableColumns';
import { ControlledTable } from './ControlledTable';
import { useMemoFilters } from './useMemoFilters';
import { selectDoRefreshTable } from 'app/Layout/FrontendLayout/slice/selectors';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { TableSkeleton } from './components/TableSkeleton';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { hasDeps } from './types/ColumnWithDeps';
import { notUndefined } from 'utils/typeUtils';
import { Condition, quoteODataValue } from 'api/odata/ODataFilter';
import { TryGetExportColumns } from './ConfigurableColumns';
import { BasicTableRefreshEventHandler } from './ControlledTable/ControlledTableProps';
import { useCustomCompareMemo } from 'use-custom-compare';
import { ServiceGroupsComparer, SortByComparer } from './Comparers';
import { ScreensId } from 'enums/ConfigurableTypes';
import { selectAppTimeZone } from 'app/slice/selectors';

export type { IRow };
export type { BasicTableProps };

type IPartialTableState<TRow extends IRow> = Partial<
  UseSortByState<TRow> &
    UsePaginationState<TRow> &
    UseGroupByState<TRow> &
    UseRowSelectState<TRow> &
    UseGlobalFiltersState<TRow>
>;

const REQUEST_CANCELLED_MESSAGE = 'Request cancelled (race condition)';
/**
 * Interface to the (initial) table state
 */
export interface IBasicTableState<TRow extends IRow>
  extends IPartialTableState<TRow> {}

interface IDataState<TRow> {
  data?: TRow[];
  dataLength?: number;
  controlledPageCount?: number;
  loading?: boolean;
  initComplete: boolean;
}

const defaultRowIdAccessor = <TRow extends IRow>(row: TRow): string =>
  row.Id?.toString();
/**
 * Server side driven Table component that will be reused in most of the pages
 * @param param0
 * @returns
 */
export function BasicTable<TRow extends IRow>({
  id,
  columns,
  initialState,
  api,
  additionalColumns,
  getRowId = defaultRowIdAccessor,
  searchColumns,
  useExport = true,
  rowActions,
  inlineRowActions,
  pageActions,
  screenName,
  selectedRowsActions,
  filterOpen = false,
  screenId,
  serviceGroups = [],
  customParams,
  useImportLink,
  importLink,
  importHandler,
  needRefresh,
  ServiceGroupsOptional,
  topAlertMessage,
  expandedColumns,
  onPageSizeChanged,
  onPageIndexChanged,
  savedListSettings,
  floatingTabSettings,
  useExportForImport,
  customEmptyTableSettings,
  exportForImportFilterKey,
  printing,
  onTableCountChange,
  useCustomSearch,
  ns,
  ...props
}: BasicTableProps<TRow>) {
  const isMobile = DetectIsMobile();

  // data received from the server and displayed on the table
  const [dataState, setDataState, extendDataState] = useAsyncExtendedState<
    IDataState<TRow>
  >({ initComplete: false });
  // reference to the show/hide filters button
  // data length - total number of the records
  //const [dataLength, setDataLength] = React.useState(0);
  // page count derived from total number of the records
  // this is passed to the react-table hook
  //const [controlledPageCount, setControlledPageCount] = React.useState(0);
  const { actions: layoutActions } = useLayoutSlice();
  const dispatch = useDispatch();
  const doRefresh = useSelector(selectDoRefreshTable);
  const tz = useSelector(selectAppTimeZone);
  const [tableState, setTableState] = React.useState<Partial<TableState<TRow>>>(
    {
      pageIndex: initialState?.pageIndex ?? 0,
      pageSize:
        printing !== undefined && printing.printing === true
          ? 10000
          : initialState?.pageSize ?? 10,
      //sortBy, which is set to undefined, is subsequently changed to [], causing re-rendering.
      //Assigning sortBy a value equal to [] is preventing re-rendering.
      sortBy: initialState?.sortBy !== undefined ? initialState?.sortBy : [],
      globalFilter: initialState?.globalFilter,
    },
  );
  const handleChangeState = React.useCallback(state => {
    setTableState(state);
  }, []);
  // custom useMemo Hook with deps comparision, the service groups array is changed even their contant is the same;
  // to do: make a generic comparer.
  const serviceGroupsCompared = useCustomCompareMemo(
    () => {
      return serviceGroups;
    },
    [serviceGroups],
    ServiceGroupsComparer,
  );
  // const additionalColumnsCompared = useCustomCompareMemo(
  //   () => {
  //     return additionalColumns;
  //   },
  //   [additionalColumns],
  //   ColumnAccesorsComparer,
  // );
  const columnsCompared = columns;

  // take care of configurable columns
  const {
    configurableColumns,
    handleConfigurableColumns,
    configCompleted,
    refreshConfigurableColumns,
  } = useConfigurableColumns<TRow>({
    screenId: screenId,
    columns: columnsCompared,
    ns: ns,
  });

  const getDisplayColumns = React.useCallback(
    ({
      screenId,
      configurableColumns,
      completed,
      basecolumns,
    }): Column<TRow>[] | undefined => {
      if (screenId === undefined) {
        return basecolumns;
      } else if (completed) {
        return configurableColumns;
      } else {
        return undefined;
      }
    },
    [],
  );

  //columnAccessors is changed several times on load Effect, so transformed to a function to control their content changed.
  // const columnAccessors = React.useMemo(() => {
  //   if (!configCompleted) {
  //     return undefined;
  //   }
  //   const columnAccessors = getDisplayColumns({
  //     screenId,
  //     configurableColumns,
  //     configCompleted,
  //     columns,
  //   });
  //   const result =
  //     columnAccessors === undefined
  //       ? undefined
  //       : getColumnAccessors(columnAccessors);
  //   return result;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [configCompleted, screenId, configurableColumns, columns]);
  const calculateColumnAccessors = React.useCallback(
    (
      //columns: Column<TRow>[],
      completed?: boolean,
      screenId?: ScreensId,
      configurableColumns?: Column<TRow>[],
    ) => {
      if (!completed) {
        return undefined;
      }
      const columnAccessors = getDisplayColumns({
        screenId,
        configurableColumns,
        completed,
        basecolumns: columnsCompared,
      });
      const result =
        columnAccessors === undefined
          ? undefined
          : columnAccessors
              .filter(c => typeof c.accessor === 'string')
              .filter(c => c.accessor !== undefined)
              .map(c => c.accessor as keyof TRow);
      return result;
    },
    [columnsCompared, getDisplayColumns],
  );
  const getAllAdditionalColumns = React.useCallback(
    (
      //columns: Column<TRow>[],
      additionalColumns?: (keyof TRow)[],
      completed?: boolean,
      screenId?: ScreensId,
      configurableColumns?: Column<TRow>[],
    ) => {
      if (!completed) {
        return undefined;
      }
      const res = getDisplayColumns({
        screenId,
        configurableColumns,
        completed,
        basecolumns: columnsCompared,
      })
        ?.filter(hasDeps)
        .flatMap(c => c.deps)
        .filter(notUndefined);
      return (res?.length ?? 0) > 0
        ? [...(additionalColumns ?? []), ...(res ?? [])]
        : additionalColumns ?? [];
    },
    [columnsCompared, getDisplayColumns],
  );
  //allAdditionalColumns is changed several times on load Effect, so transformed to a function to control their content changed.
  // const allAdditionalColumns = React.useMemo(() => {
  //   // early return in case and the config is not ready yet to prevent unnecessary fires of effects below that depend on this
  //   if (!configCompleted) {
  //     return undefined;
  //   }
  //   const res = getDisplayColumns({
  //     screenId,
  //     configurableColumns,
  //     configCompleted,
  //     columns,
  //   })
  //     ?.filter(hasDeps)
  //     .flatMap(c => c.deps)
  //     .filter(notUndefined);
  //   return [...(additionalColumns ?? []), ...(res ?? [])];
  // }, [
  //   additionalColumns,
  //   columns,
  //   configCompleted,
  //   configurableColumns,
  //   getDisplayColumns,
  //   screenId,
  // ]);

  // memo cleaned up filters without the Name which is loaded independently of the filter value and only affects appearance of the filter
  const customFilter = useMemoFilters({ appliedFilters: props.appliedFilters });

  const handleFilterChange = React.useCallback(
    items => {
      if (props.onFilterChange !== undefined) {
        props.onFilterChange(items);
      }
    },
    [props],
  );

  /**
   * Fetch data method that retrieves the data according to the current table state
   */
  const fetchData = React.useCallback(
    async (
      state: {
        api: string | IPathAndQuery;
        predicates: Array<Condition<TRow> | string>;
        pageSize: number;
        pageIndex: number;
        sortBy?: SortingRule<TRow>[] | null;
        globalFilterValue?: string;
        customFilter: IFilterSettings<TRow>[];
        serviceGroups: Entity<number>[];
        isOptionalServiceGroup: boolean;
        columns: Array<keyof TRow>;
        additionalColumns: Array<keyof TRow>;
        useCustomSearch?: boolean;
      },
      cancelToken: CancelToken,
    ) => {
      extendDataState({ loading: true });
      try {
        // todo: add support for object notation accessors like 'foo.bar'
        // todo: add support for accessor of type Function(originalRow, rowIndex) => any
        const oDataParams = buildParams<TRow>(
          {
            ...state,
            select: state.columns,
            serviceGroups: state.serviceGroups,
            expandedColumns: expandedColumns,
          },
          searchColumns,
          customParams,
        );
        var { url, params } = getUrlAndParams(
          state.api,
          oDataParams,
          useCustomSearch,
          state.globalFilterValue,
        );
        const response = await httpClient.get(url, params, cancelToken);

        const newState: IDataState<TRow> = {
          loading: false,
          initComplete: true,
          data: response.value,
          dataLength: response['@odata.count'],
          controlledPageCount: Math.ceil(
            response['@odata.count'] / state.pageSize,
          ),
        };
        setDataState(newState);
        if (!!onTableCountChange) {
          onTableCountChange(response['@odata.count']);
        }
      } catch (error: unknown) {
        // todo: show error message
        const requestCancelled =
          (error as any)?.message === REQUEST_CANCELLED_MESSAGE;
        if (!requestCancelled) {
          // console.error(error);
          extendDataState({ loading: false, initComplete: true });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [httpClient],
  );

  const pageIndex = React.useMemo(() => {
    return tableState.pageIndex!;
  }, [tableState.pageIndex]);
  const pageSize = React.useMemo(() => {
    return tableState.pageSize!;
  }, [tableState.pageSize]);
  // const sortBy = React.useMemo(() => {
  //   return tableState.sortBy!;
  // }, [tableState.sortBy]);
  const sortBy = useCustomCompareMemo(
    () => {
      return tableState.sortBy!;
    },
    [tableState.sortBy],
    SortByComparer,
  );
  const globalFilter = React.useMemo(() => {
    return tableState.globalFilter;
  }, [tableState.globalFilter]);
  const titleKey = React.useMemo(() => {
    const columnAccessors = calculateColumnAccessors(
      //columnsCompared,
      configCompleted,
      screenId,
      configurableColumns,
    );
    return props.titleKey ?? columnAccessors?.[0] ?? '';
  }, [
    calculateColumnAccessors,
    //columnsCompared,
    configCompleted,
    configurableColumns,
    props.titleKey,
    screenId,
  ]);
  const allAdditionalColumnsTable = React.useMemo(() => {
    return getAllAdditionalColumns(
      //columnsCompared,
      additionalColumns,
      configCompleted,
      screenId,
      configurableColumns,
    );
  }, [
    additionalColumns,
    configCompleted,
    configurableColumns,
    getAllAdditionalColumns,
    screenId,
  ]);
  const columnAccessorsTable = React.useMemo(() => {
    return calculateColumnAccessors(
      //columnsCompared,
      configCompleted,
      screenId,
      configurableColumns,
    );
  }, [
    calculateColumnAccessors,
    configCompleted,
    configurableColumns,
    screenId,
  ]);
  // connect table state changes to server data fetch side effect

  React.useEffect(() => {
    const cancelTokenSource = httpClient.cancelToken.source();
    // const allAdditionalColumns = getAllAdditionalColumns(
    //   //columnsCompared,
    //   additionalColumnsCompared,
    //   configCompleted,
    //   screenId,
    //   configurableColumns,
    // );
    // const columnAccessors = calculateColumnAccessors(
    //   //columnsCompared,
    //   configCompleted,
    //   screenId,
    //   configurableColumns,
    // );
    const columnsConfig = isFetchReady(
      columnAccessorsTable,
      allAdditionalColumnsTable,
      configCompleted,
    );
    if (columnsConfig === false) {
      return;
    }
    try {
      fetchData(
        {
          api,
          predicates: props.predicates ?? [],
          pageIndex,
          pageSize,
          sortBy,
          globalFilterValue: globalFilter as string,
          customFilter: customFilter ?? [],
          serviceGroups: serviceGroupsCompared,
          isOptionalServiceGroup: ServiceGroupsOptional || false,
          useCustomSearch: useCustomSearch,
          ...columnsConfig,
        },
        cancelTokenSource.token,
      );
    } catch (error) {
    } finally {
    }
    return () => {
      cancelTokenSource.cancel(REQUEST_CANCELLED_MESSAGE);
    };
  }, [
    ServiceGroupsOptional,
    allAdditionalColumnsTable,
    api,
    columnAccessorsTable,
    configCompleted,
    customFilter,
    fetchData,
    globalFilter,
    pageIndex,
    pageSize,
    props.predicates,
    serviceGroupsCompared,
    sortBy,
    useCustomSearch,
  ]);
  const handleExportClick = async () => {
    try {
      /**
       * export configurable columns should have been preloaded at this point and available immediately
       * otherwise this could take a bit of the time to refetch columns data from the server
       *  */
      const exportColumnAccessors = await TryGetExportColumns(
        screenId,
        columnsCompared,
        // isAuthenticated,
      );

      const { $top, $skip, ...tableParams } = buildParams(
        {
          pageIndex,
          pageSize,
          sortBy,
          globalFilterValue: globalFilter as string,
          customFilter: customFilter ?? [],
          select: exportColumnAccessors ?? [],
          additionalColumns: additionalColumns,
          serviceGroups: serviceGroupsCompared,
          isOptionalServiceGroup: ServiceGroupsOptional || false,
          expandedColumns: expandedColumns,
          predicates: props.predicates ?? [],
          useCustomSearch: useCustomSearch,
        },
        undefined,
        undefined,
        true,
      );

      //BKT-7579
      if (tableParams.$filter?.startsWith('() and ')) {
        tableParams.$filter = tableParams.$filter.replace('() and ', '');
      }
      var oDataParams = getExportParameters(tableParams);
      var { url, params } = getUrlAndParams(
        api,
        oDataParams,
        useCustomSearch,
        globalFilter as string,
      );
      if (screenId === undefined) {
        params['unConfigured'] = true;
      }
      const exporturl = buildURL(url, params);
      await openExportLink(exporturl, undefined, tz);
    } catch (error) {
      console.error(error);
    }
  };
  const getAllFilteredRows = async (prop: keyof TRow) => {
    return new Promise<TRow[]>((resolve, reject) => {
      const { $top, $skip, ...tableParams } = buildParams(
        {
          pageIndex,
          pageSize,
          sortBy,
          globalFilterValue: globalFilter as string,
          customFilter: customFilter ?? [],
          select: [prop],
          additionalColumns: additionalColumns,
          serviceGroups: serviceGroupsCompared,
          isOptionalServiceGroup: ServiceGroupsOptional || false,
          expandedColumns: undefined,
          predicates: props.predicates ?? [],
          useCustomSearch: useCustomSearch,
        },
        undefined,
        undefined,
        true,
      );
      if (tableParams.$filter?.startsWith('() and ')) {
        tableParams.$filter = tableParams.$filter.replace('() and ', '');
      }

      var oDataParams = getExportForImportParameters(tableParams);
      var { url, params } = getUrlAndParams(
        api,
        oDataParams,
        useCustomSearch,
        globalFilter as string,
      );
      httpClient.get(url, params).then(response => {
        resolve(response.value);
      });
      // try {
      //   const response = await httpClient.get(url, params);
      //   const rows: TRow[] = response.value;
      //   resolve(rows.map(f => f.Id as number));
      // } catch (error: unknown) {
      //   resolve([]);
      // }
    });
  };

  const [allRowsSelected, setAllRowsSelected] = React.useState<
    boolean | undefined
  >(undefined);
  const toggleAllRowsSelected = React.useCallback((value?: boolean) => {
    setAllRowsSelected(value);

    //resets to undefined if false so that the useEffect will notice a change when next changed to false
    // if (!value) {
    //   setAllRowsSelected(undefined);
    // }
  }, []);

  /**
   * Reloads the table and unselects all rows
   */
  const handleRefresh = React.useCallback<BasicTableRefreshEventHandler>(
    event => {
      const allAdditionalColumns = getAllAdditionalColumns(
        //columnsCompared,
        additionalColumns,
        configCompleted,
        screenId,
        configurableColumns,
      );
      const columnAccessors = calculateColumnAccessors(
        //columnsCompared,
        configCompleted,
        screenId,
        configurableColumns,
      );
      const columnsConfig = isFetchReady(
        columnAccessors,
        allAdditionalColumns,
        configCompleted,
      );
      if (columnsConfig === false) {
        return;
      }
      if (event?.reloadConfigurableColumns === true && screenId !== undefined) {
        refreshConfigurableColumns();
        return;
      }
      if (props.useRowSelect && !initialState?.selectedRowIds) {
        toggleAllRowsSelected(false);
      }
      fetchData(
        {
          api,
          predicates: props.predicates ?? [],
          pageIndex,
          pageSize,
          sortBy,
          globalFilterValue: globalFilter as string,
          customFilter: customFilter ?? [],
          serviceGroups: serviceGroupsCompared,
          isOptionalServiceGroup: ServiceGroupsOptional || false,
          useCustomSearch: useCustomSearch,
          ...columnsConfig,
        },
        httpClient.cancelToken.source().token,
      );
    },
    [
      ServiceGroupsOptional,
      additionalColumns,
      api,
      calculateColumnAccessors,
      configCompleted,
      configurableColumns,
      customFilter,
      fetchData,
      getAllAdditionalColumns,
      globalFilter,
      initialState?.selectedRowIds,
      pageIndex,
      pageSize,
      props.predicates,
      props.useRowSelect,
      refreshConfigurableColumns,
      screenId,
      serviceGroupsCompared,
      sortBy,
      toggleAllRowsSelected,
      useCustomSearch,
    ],
  );

  const refreshFromState = React.useCallback(() => {
    dispatch(layoutActions.setRefreshTable(false));
    handleRefresh();
  }, [dispatch, layoutActions, handleRefresh]);

  React.useEffect(() => {
    if (needRefresh !== undefined && needRefresh === true) {
      handleRefresh();
    }
    if (doRefresh === true) {
      refreshFromState();
    }
  }, [doRefresh, needRefresh, handleRefresh, refreshFromState]);

  React.useEffect(() => {
    onPageSizeChanged?.(pageSize);
  }, [onPageSizeChanged, pageSize]);

  React.useEffect(() => {
    onPageIndexChanged?.(pageIndex);
  }, [onPageIndexChanged, pageIndex]);

  const loading = dataState?.loading ?? true;

  if (dataState.initComplete !== true) {
    return (
      <TableSkeleton
        isCards={props.useCardsByDefault || isMobile}
        isMobile={props.compactTable ?? isMobile}
      />
    );
  }
  return (
    <ControlledTable
      id={id}
      api="foo"
      columns={configurableColumns || []} //(wrongColumnDefinition ? columns : [])
      compact={props.compactTable ?? isMobile}
      data={dataState?.data || []}
      loading={loading}
      dataLength={dataState?.dataLength || 0}
      onChangeState={handleChangeState}
      onExport={handleExportClick}
      pageCount={dataState?.controlledPageCount || 0}
      screenName={screenName}
      serviceGroups={serviceGroupsCompared}
      ServiceGroupsOptional={ServiceGroupsOptional}
      additionalColumns={additionalColumns}
      appliedFilters={props.appliedFilters}
      availableFilters={props.availableFilters}
      customParams={customParams}
      filterOpen={filterOpen}
      getRowId={getRowId}
      importHandler={importHandler}
      initialPageSize={pageSize}
      initialState={initialState}
      onDownloadTemplate={props.onDownloadTemplate}
      onFilterChange={handleFilterChange}
      pageActions={pageActions}
      rowActions={rowActions}
      searchColumns={searchColumns}
      selectedRowsActions={selectedRowsActions}
      onSelectedRowsChange={props.onSelectedRowsChange}
      subHeader={props.subHeader}
      topAlertMessage={topAlertMessage}
      useExport={useExport}
      onRefresh={handleRefresh}
      onConfigurableColumns={handleConfigurableColumns}
      useGlobalFilter={true}
      useCustomSearch={useCustomSearch}
      useImportLink={useImportLink}
      useRowSelect={props.useRowSelect}
      screenId={screenId}
      allRowsSelected={allRowsSelected}
      importLink={importLink}
      savedListSettings={savedListSettings}
      imageSrcKey={props.imageSrcKey}
      titleKey={titleKey}
      allowCards={props.allowCards}
      useCardsByDefault={props.useCardsByDefault}
      primaryAction={props.primaryAction}
      onModeChange={props.onModeChange}
      withToolBar={props.withToolBar}
      tabSectionSettings={props.tabSectionSettings}
      floatingTabSettings={floatingTabSettings}
      inlineRowActions={inlineRowActions}
      isRowSelectable={props.isRowSelectable}
      onSelectedChange={props.onSelectedChange}
      hideMenuOnMobile={props.hideMenuOnMobile}
      viewCardsCheck={props.viewCardsCheck}
      cardSize={props.cardSize}
      cardPosition={props.cardPosition}
      useConfigurable={props.useConfigurable}
      customToolbarSettings={props.customToolbarSettings}
      preventInnerClicks={props.preventInnerClicks}
      useExportForImport={useExportForImport}
      customEmptyTableSettings={customEmptyTableSettings}
      getAllFilteredRowsCallback={getAllFilteredRows}
      exportForImportFilterKey={exportForImportFilterKey}
      printing={printing}
      hideFilters={props.hideFilters}
      baseTableActionsOnToolbar={props.baseTableActionsOnToolbar}
      screenNameVariant={props.screenNameVariant}
      customExpandMenu={props.customExpandMenu}
      customScreenName={props.customScreenName}
      showGlobalSearchIconOnToolbar={props.showGlobalSearchIconOnToolbar}
      toggleGlobalSearchIcon={props.toggleGlobalSearchIcon}
      getSavedDefaultFilters={props.getSavedDefaultFilters}
      importMenuItems={props.importMenuItems}
      isContentEditable={props.isContentEditable}
      tablePaddingVariant={props.tablePaddingVariant}
      minCardHeight={props.minCardHeight}
      ns={ns}
    />
  );
}
function getUrlAndParams(
  api: string | IPathAndQuery,
  oDataParams: {
    $skip?: number;
    $top?: number;
    $count?: boolean;
    $select?: string;
    $orderby?: string | undefined;
    $filter?: string | undefined;
    $format?: string;
    $expand?: string;
  },
  useCustomSearch?: boolean,
  searchText?: string,
) {
  var url = typeof api === 'string' ? api : (api as IPathAndQuery).path;
  var search = (api as IPathAndQuery).search;
  if (useCustomSearch) {
    search = { ...search, q: quoteODataValue(searchText || '') };
  }
  const params = { ...oDataParams, ...search };
  return { url, params };
}

/**
 * Determines if fetchData function is ready to be called
 * @param configCompleted configCompleted - sourced from the configurable columns
 * @param columnAccessors column accessors used to fetch data
 * @param allAdditionalColumns additional column accessors used to fetch data
 * @returns returns either false in the case and configurable columns will return a bit later, or non-undefined accessors that can be used in the fetchData function
 */
function isFetchReady<TRow extends IRow>(
  columnAccessors: Array<keyof TRow> | undefined,
  allAdditionalColumns: Array<keyof TRow> | undefined,
  configCompleted: boolean,
):
  | false
  | {
      columns: Array<keyof TRow>;
      additionalColumns: Array<keyof TRow>;
    } {
  if (!configCompleted) {
    return false;
  } else if (columnAccessors === undefined) {
    return false;
  } else if (allAdditionalColumns === undefined) {
    return false;
  } else {
    return {
      columns: columnAccessors,
      additionalColumns: allAdditionalColumns,
    };
  }
}
