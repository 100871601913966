/**
 *
 * ServiceRequestMilestones
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ServiceRequestMilestoneDetailsProps } from '../ServiceRequestMilestoneDetails';
import { RowActionsMenu } from 'app/components/BasicTable/RowActions';
import { sum } from 'lodash';
import { ServiceRequestAddons } from '../ServiceRequestAddons';
import { ServiceRequestMilestoneStatusesUnion } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { dateUtils } from 'utils/date-utils';
import { TruncatedDescriptionText } from 'app/components/Description';
import { ServiceRequestMilestoneStatusMenuPicker } from 'app/components/pickers/StaticOptionsPickers/ServiceRequestMilestoneStatusMenuPicker';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import clsx from 'clsx';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { Body } from 'app/components/Typography';
import { Printing } from 'app/components/PrintToPDF';
import { InvoiceDetailsLink } from 'app/pages/Actions/GlobalSelectedActions/OpenInvoiceDetails';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';
import { TableRoot } from 'app/components/BasicTable/styled';
import { Entity } from 'types/common';

export interface ServiceRequestMilestonesProps {
  milestones?: Array<IServiceRequestMilestoneDto>;
  isAdmin: boolean;
  AddonType: AddonTypesUnion;
  InternalStatus: InternalServiceRequestStatusesUnion;
  ServiceRequestId: number;
  pageName?: string;
  tableContainerAsSection?: boolean;
  // indicates if this component is opened through side panel
  printing?: Printing;
  useSidePanel: boolean;
  UnitType?: Entity<number>;
}

interface IState {
  expanded: Record<number, boolean>;
}

export function ServiceRequestMilestones({
  milestones,
  pageName,
  tableContainerAsSection,
  useSidePanel,
  ...props
}: ServiceRequestMilestonesProps) {
  const { t } = useTranslation();
  const [state, , extendState] = useAsyncExtendedState<IState>({
    expanded: {},
  });
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const handleToggleClick = (id: number, expand: boolean) => {
    extendState(state => {
      const n = produce(expanded => {
        expanded[id] = expand;
      })(state.expanded);

      return { expanded: n };
    });
  };
  const handleAddMilestoneClick = React.useCallback(() => {
    const sidePanelProps: ServiceRequestMilestoneDetailsProps = {
      serviceRequestId: props.ServiceRequestId,
      type: 'Insert',
    };
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.ServiceRequestMilestone,
        props: sidePanelProps,
      }),
    );
  }, [actions, dispatch, props.ServiceRequestId]);

  const handleEditClick = React.useCallback(
    (milestone: IServiceRequestMilestoneDto) => {
      const sidePanelProps: ServiceRequestMilestoneDetailsProps = {
        serviceRequestId: props.ServiceRequestId,
        type: 'Update',
        value: milestone,
      };
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ServiceRequestMilestone,
          props: sidePanelProps,
        }),
      );
    },
    [actions, dispatch, props.ServiceRequestId],
  );
  const handleDeleteClick = React.useCallback(
    (milestone: IServiceRequestMilestoneDto) => {
      dispatch(actions.deleteMilestone(milestone));
    },
    [actions, dispatch],
  );
  const columnsCount = 9;
  const showActionsColumn = React.useMemo(
    () =>
      props.isAdmin &&
      milestones?.some(milestone => (milestone.InvoiceId ?? 0) === 0),
    [milestones, props.isAdmin],
  );
  const colSpan = React.useMemo(
    () => columnsCount + (showActionsColumn ? 1 : 0),
    [showActionsColumn],
  );
  const handleStatusChange = (
    milestone: IServiceRequestMilestoneDto,
    value: ServiceRequestMilestoneStatusesUnion | null,
  ) => {
    if (value !== null) {
      dispatch(
        actions.updateMilestone({
          ...milestone,
          ...{ Status: value },
        }),
      );
    }
  };
  const print = React.useMemo(() => {
    return props.printing !== undefined && props.printing.printing === true;
  }, [props.printing]);
  return (
    <TableRoot
      className={clsx('table-root', {
        'section-table': tableContainerAsSection,
      })}
      key="milestone-table-container"
      id={`milestone_table_container`}
    >
      <TableToolbar
        screenName={pageName ?? t(translations.Milestones)}
        screenNameVariant={'section'}
        showAlert={undefined}
        alertContent={undefined}
        showMenu={false}
        compact={undefined}
        titleAsColumn={true}
        titleIcon={
          <Body bold size="small">
            {milestones?.length} {t(translations.Records)}
          </Body>
        }
      >
        {print === false ? (
          <>
            {props.isAdmin && (
              <span>
                <TopActionButton
                  variant="main"
                  size="small"
                  startIcon="plus"
                  icon="plus"
                  onClick={handleAddMilestoneClick}
                  text={t(translations.Milestone)}
                />
              </span>
            )}
          </>
        ) : (
          <></>
        )}
        {<></>}
      </TableToolbar>
      <div
        id={`milestone_content`}
        className={clsx('table-content', {
          'table-content-short': isMobile,
        })}
      >
        <div className={'table-scrolling'}>
          <Table
            id={'milestone_table'}
            className={'table'}
            role="table"
            aria-label={'milestones'}
          >
            <TableHead>
              <TableRow>
                <TableCell component="th"></TableCell>
                <TableCell component="th">
                  {t(translations.Milestone)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.TargetDate)}
                </TableCell>
                <TableCell component="th">{t(translations.Total)}</TableCell>
                <TableCell component="th">
                  {t(translations.AssignedTo)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.CompletionDate)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.Description)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.InvoiceId)}
                </TableCell>
                <TableCell component="th">{t(translations.Status)}</TableCell>
                {showActionsColumn && <TableCell component="th"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {milestones?.map(milestone => {
                const isExpanded = state.expanded[milestone.Id] === true;

                return (
                  <React.Fragment key={`milestone-${milestone.Id}`}>
                    <TableRow key={`milestone-${milestone.Id}`}>
                      <TableCell>
                        <IconButton
                          variant="ghost"
                          size="small"
                          onClick={() =>
                            handleToggleClick(milestone.Id, !isExpanded)
                          }
                        >
                          <Icon
                            icon={isExpanded ? 'chevron-up' : 'chevron-right'}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>{milestone.Name}</TableCell>
                      <TableCell>
                        {dateUtils.shortDateFormat(milestone.TargetDate)}
                      </TableCell>
                      <TableCell>
                        {roundTwoPlacesAfterDecimalPoint(
                          sum(
                            milestone.ServiceRequestMilestoneCharges.map(
                              f => f.Amount ?? 0,
                            ),
                          ),
                        )}
                      </TableCell>
                      <TableCell>
                        {milestone.Assignees?.map(f => f.Name)?.join(', ')}
                      </TableCell>
                      <TableCell>
                        {dateUtils.shortDateFormat(milestone.CompletionDate)}
                      </TableCell>
                      <TableCell>
                        <TruncatedDescriptionText
                          value={milestone.Description}
                        />
                      </TableCell>
                      <TableCell>
                        {milestone.InvoiceId !== null && (
                          <InvoiceDetailsLink
                            id={milestone.InvoiceId}
                            useSidePanel={useSidePanel}
                          >
                            {milestone.InvoiceId}
                          </InvoiceDetailsLink>
                        )}
                      </TableCell>
                      <TableCell>
                        {milestone.Status !== null &&
                          (props.isAdmin && milestone.InvoiceId === null ? (
                            <ServiceRequestMilestoneStatusMenuPicker
                              value={milestone.Status}
                              onChange={value =>
                                handleStatusChange(milestone, value)
                              }
                            />
                          ) : (
                            t(milestone.Status)
                          ))}
                      </TableCell>

                      {showActionsColumn && (
                        <TableCell>
                          {milestone.InvoiceId === null && (
                            <RowActionsMenu
                              id={`row-actions-menu_${milestone.Id}`}
                              items={[
                                {
                                  icon: 'pencil-alt',
                                  text: t(translations.Edit),
                                  title: t(translations.Edit),
                                  onClick: () =>
                                    Promise.resolve(handleEditClick(milestone)),
                                },
                                {
                                  icon: 'times',
                                  text: t(translations.Delete),
                                  title: t(translations.Delete),
                                  onClick: () =>
                                    Promise.resolve(
                                      handleDeleteClick(milestone),
                                    ),
                                },
                              ]}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                    {isExpanded &&
                      milestone &&
                      milestone.ServiceRequestMilestoneCharges && (
                        <TableRow key={`milestone-charges-row-${milestone.Id}`}>
                          <TableCell
                            className="services-cell"
                            colSpan={colSpan}
                          >
                            <ServiceRequestAddons
                              isAdmin={props.isAdmin}
                              milestone={milestone}
                              AddonType={props.AddonType}
                              InternalStatus={props.InternalStatus}
                              charges={milestone.ServiceRequestMilestoneCharges}
                              useSidePanel={useSidePanel}
                              UnitType={props.UnitType}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </TableRoot>
  );
}

// interface ToolbarProps {
//   left?: React.ReactNode;
//   right?: React.ReactNode;
// }

// function Toolbar(props: ToolbarProps) {
//   return (
//     <Container>
//       <Box>{props.left}</Box>
//       <Box>{props.right}</Box>
//     </Container>
//   );
// }
// const Container = styled(Box)(({ theme }) => ({
//   margin: theme.spacing(1),
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
// }));
