import { ReactElement } from 'react';
import { Entity } from 'types/common';

export type TabType = string | number;
export interface TabContent extends Entity<TabType> {
  icon?: ReactElement;
  title?: string;
  disabled?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  Content: ReactElement;
}

export interface GroupedTab {
  groupId: TabType;
  groupName: string;
  tabs: Array<TabContent>;
}

export type GroupedTabContent = TabContent | GroupedTab;

export function isGroupedTab(tab: GroupedTabContent): tab is GroupedTab {
  return tab['groupId'] !== undefined;
}
