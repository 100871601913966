import { useTheme } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { BookitChip } from 'app/components/BasicChip';
import { ReactActions } from 'app/components/ReactActions';
import { SelectedRowsActionExtRenderer } from '../SelectedRowsActionButton/SelectedRowsActionRenderer';
import * as React from 'react';
import { IdType, Row } from 'react-table';
import { useSelector } from 'react-redux';
import { selectHideNavigation } from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';
import { IRow } from '..';
import { bookitColors } from 'styles/colors/bookitColors';
import { ICanRaiseBasicTableRefreshEvent } from '../ControlledTable/ControlledTableProps';
import styled from 'styled-components';

const genericMemo: <T>(component: T) => T = React.memo;

export interface FloatingActionsProps<TRow extends IRow>
  extends ICanRaiseBasicTableRefreshEvent {
  selectedActions: SelectedRowsActionExtRenderer<TRow>[];
  rows: Row<TRow>[];
  useSidePanel?: boolean;
  buttonActions?: SelectedRowsActionExtRenderer<TRow>[];

  selectedArray: TRow[];
  toggleAllRowsSelected: (value?: boolean) => void;
  toggleRowSelected: (rowId: IdType<TRow>, set?: boolean | undefined) => void;
  totalRows: number;
  compact?: boolean;
}

export const FloatingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  height: 72,
  background: '#ffffff',
  borderRadius: 8,
  boxShadow: '0px 2px 18px rgba(8, 24, 41, 0.16)',
  '&.fixedContainer': {
    position: 'fixed',
    insetBlock: 'auto 80px',
    insetInline: 'auto 160px',
    zIndex: 1070,
    width: '70%',
    padding: 16,
    '&.fullWidth': {
      width: '80%',
    },
  },
  '&.fixedContainerMobile': {
    //position: 'absolute',
    zIndex: 1070, //zIndex: 1500,
    width: '100%',
    padding: '16px 8px',
    //bottom: 'unset',
    position: 'fixed',
    bottom: '50px',
    '&.isPopup': {
      position: 'absolute',
      bottom: '50px',
    },
  },
  '& .buttonsContent': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 8,
    width: 'calc(100% - 200px)',
    '&.mobile': {
      width: 'calc(100% - 115px)',
    },
    '&.compact': {
      width: 'auto',
    },
    '& .actions': {
      display: 'inline-flex',
      flex: '1 1 auto',
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: 'auto hidden',
      alignItems: 'center',
      gap: 8,
      '&.compactActions': {
        flex: '0 1 auto',
      },
      '& .rowsCount': {
        display: 'inline-flex',
      },
    },
  },
  '& .buttonActions': {
    display: 'inline-flex',
    flex: '1 1 auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'auto hidden',
    gap: 8,
  },
}));

function FloatingActionsFunc<TRow>(props: FloatingActionsProps<TRow>) {
  const {
    selectedActions,
    onRefresh,
    selectedArray,
    toggleAllRowsSelected,
    totalRows,
    buttonActions,
    useSidePanel,
    toggleRowSelected,
    compact,
    rows,
  } = props;
  const isMobile = DetectIsMobile() === true;
  const hideNavigation = useSelector(selectHideNavigation);

  const maxButtons = React.useMemo(() => {
    return isMobile || compact === true ? 1 : 6;
  }, [compact, isMobile]);
  const baseActions = React.useMemo(() => {
    return selectedActions.length > maxButtons
      ? selectedActions.slice(0, maxButtons - 1)
      : selectedActions;
  }, [selectedActions, maxButtons]);
  const sperActions = React.useMemo(() => {
    return selectedActions.length > maxButtons
      ? selectedActions.slice(maxButtons - 1)
      : [];
  }, [selectedActions, maxButtons]);
  const theme = useTheme();
  // const toggleSingleSelected = (
  //   rowId: IdType<TRow>,
  //   set?: boolean | undefined,
  // ) => {
  //   let selectable = rows.filter(f => f.id === rowId).length > 0;
  //   if (selectable) {
  //     return toggleRowSelected(rowId, set);
  //   }
  // };
  return (
    <FloatingContainer
      theme={theme}
      className={clsx({
        fixedContainer: !isMobile && !compact,
        fixedContainerMobile: isMobile || compact === true,
        fullWidth: useSidePanel || hideNavigation,
        isPopup: useSidePanel ?? false,
      })}
    >
      <div
        className={clsx('buttonsContent', {
          mobile: isMobile,
          compact: compact === true || buttonActions === undefined,
        })}
      >
        <div
          className={clsx('actions', {
            compactActions: isMobile || compact === true,
          })}
          key={'selected_actions'}
        >
          <div className="rowsCount" key={'rows_count'}>
            <Body
              bold={true}
              size="small"
            >{`${selectedArray.length} of ${totalRows} Items`}</Body>
          </div>
          {sperActions.length > 0 ? (
            <React.Fragment>
              {baseActions.map((item, index) =>
                item(
                  selectedArray,
                  rows,
                  onRefresh,
                  toggleAllRowsSelected,
                  toggleRowSelected,
                ),
              )}
              <SperActions
                actions={sperActions}
                onRefresh={onRefresh}
                selectedArray={selectedArray}
                rows={rows}
                toggleAllRowsSelected={toggleAllRowsSelected}
                toggleRowSelected={toggleRowSelected}
                isMobile={isMobile || compact === true}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {selectedActions.map((item, index) => (
                <span key={index}>
                  {item(
                    selectedArray,
                    rows,
                    onRefresh,
                    toggleAllRowsSelected,
                    toggleRowSelected,
                  )}
                </span>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      {!!buttonActions && buttonActions.length > 0 && (
        <div key={'buttons_actions'} className={'buttonActions'}>
          {buttonActions.map((item, index) => (
            <span key={index}>
              {item(
                selectedArray,
                rows,
                onRefresh,
                toggleAllRowsSelected,
                toggleRowSelected,
              )}
            </span>
          ))}
        </div>
      )}
    </FloatingContainer>
  );
}
export interface SperActionsProps<TRow extends IRow>
  extends ICanRaiseBasicTableRefreshEvent {
  actions: SelectedRowsActionExtRenderer<TRow>[];
  selectedArray: TRow[];
  rows: Row<TRow>[];
  toggleAllRowsSelected: (value?: boolean) => void;
  toggleRowSelected: (rowId: IdType<TRow>, set?: boolean | undefined) => void;
  isMobile: boolean;
}
function SperActionsFunc<TRow>(props: SperActionsProps<TRow>) {
  const {
    actions,
    onRefresh,
    selectedArray,
    rows,
    toggleAllRowsSelected,
    toggleRowSelected,
    isMobile,
  } = props;
  return (
    <BookitChip
      onDelete={() => {}}
      deleteIcon={
        <ReactActions
          size="xs"
          iconVariant="ghost"
          id={`floating_actions`}
          items={
            [
              ...actions?.map((item, index) => (
                <span key={index}>
                  {item(
                    selectedArray,
                    rows,
                    onRefresh,
                    toggleAllRowsSelected,
                    toggleRowSelected,
                  )}
                </span>
              )),
            ].filter(v => !!v) as JSX.Element[]
          }
        ></ReactActions>
      }
      variant="default"
      customsize="medium"
      label={`and ${actions.length} more`}
      customwidth={isMobile ? 150 : 180}
      custombgcolor={bookitColors.primary.disabled}
      customcolor={bookitColors.grayscale.blackComponents}
      customiconcolor={bookitColors.primary.regular}
      shape="circle"
      key={'sper_actions'}
    />
  );
}
export const SperActions = genericMemo(SperActionsFunc);
export const FloatingActions = genericMemo(FloatingActionsFunc);
