/**
 * "Allowed" entries from .net's global::GlobalDbSettings.GlobalStringNames enum
 */
export enum AllowedSettings {
  'DefaultQuickAssetSearchAction',
  'UserFormatResult',
  'InstrumentOptionsInCalender',
  'CalendarPublicIntegrationFeed',
  'AllowUsersToOpt_Out',
  'InternalUserIdFieldLength',
  'InternalUserId2FieldLength',
  'ServiceGroupAdminsCanManageUsers',
  'EnableServiceGroupAffilationManagment',
  'FavIconImg',
  'FavIconTitle',
  'TopMenuBorder',
  'TopCustomerLogo',
  'TopCustomerLogoLink',
  'DeleteEmailNotficationsMonth',
  'AssetCatEnabled',
  'TrainingActivationAllowedBySGAOnly',
  'TrainingCreatedAsNotActiveByDefault',
  'HideInstrumentRestrictions',
  'EnableReservationSidePanel',
  'PriceSheetBy',
  'SGACreateProjects',
  'UGFCreateProjects',
  'HideRoomFloorTextBoxes',
  'EnableInvoicePartialEvents',
  'ISOCurrencySymbol',
  'AccumulateNewChargesToExistingInvoice',
  'CreateInovicesBy',
  'CreateInvoiceSyncLimit',
  'AllowMultiServiceGroupInvoices',
  'ReservationUserGroupGroupByBudgetUserGroup',
  'AllowedFeeTypes',
  'InvoiceFromBatchScreenOnly',
  'AllowExternalFundingInvoiceFinExport',
  'AllowExternalFundingInvoicePdfExport',
  'EnableFinAccountManagment',
  'AllowUsersToViewCharges',
  'GlobalInvoiceVAT',
  'NamePrefixMandatory',
  'AssetCatGroupEnabled',
  'FinSystemInvoicesEnviroment',
  'HideDymoPrinterMenu',
  'WarnDefaultBudgetEnabled',
  'DymoPrinterSupported',
  'ZebraPrinterSupported',
  'ShowDefaultBudgetOnUserProfile',
  'BudgetLimited_ByUsers',
  'AllowPartialMultipleProjectCharges',
  'AllowPartialSingleProjectCharges',
  'HideTrainingSessionsSettings',
  'HideAssistedReservationsSettings',
  'HideCalcPriceOnOfflineService',
  'CrossInstrumentTraining',
  'HideReferenceFieldInReservationDetails',
  'MultipleInstrumentReservations',
  'InstrumentAvailabilityDropDown',
  'MinBarcodeLength',
  'ShowDashboardAnnouncement',
  'DashboardAnnouncement',
  'RoomUserRoleEnabled',
  'GlobalPriceSheetPeriods',
  'AdvancedAssetSearchFilterAttributes',
  'AdvancedAssetPublicSearchFilterAttributes',
  'WarrantyEndDateVisibleAllUsers',
  'OnlyAdminCanImportAssets',
  'Help_Dashboard',
  'AllowWorkflowBooking',
  'MultipleTentativeReservationsUnderWorkflowBooking',
  'CalendarTimelineEmptySlotClickSettings',
  'NoDashboardRedirectOnCoreSelection',
  'HideTopServiceGroupFilter',
  'TerminateReservation',
  'CalendarServicesPickerCapacity',
  'MaximumTimelineRowsLimit',
  'IntervalRefreshingCalendar',
  'ShouldAllowCustomDashboards',
  'EnableLegacyScheduler',
  'EnableTwoDaysTimelineButton',
  'AutomaticAssetNamings',
  'EnableCustomDaysTimelineButton',
  'DefaultCustomDaysTimelineValue',
  'SkipCoreSelectionScreen',
  'SchedulerReservationBodyTemplate',
  'SchedulerReservationTooltipTemplate',
  'IncludeCustomFormInSchedulerReservationTooltip',
  'AllowToRemoveFromMultipleReservation',
  'DraftReservationsEnabled',
  'UserReservationsRestricted',
  'UsageKioskRoleEnabled',
  'RequestMilestones',
  'MandatoryOfflineServicesOnTrainingSignUps',
  'NewUsersSignupForm',
  'PurchaseOrderLengthSetting',
  'HideLinkToRegistrationForm',
  'SessionTimeout_Persistent',
  'Help_Login',
  'HideReservationCollaborators',
  'LoginScreenCompanyLogo',
  'LoginScreenCompanyLogoLink',
  'LoginScreenCompanyLogoHeight',
  'ShowForgotPassword',
  'BrowseByUnit',
  'MaxRecordsAllowedAssetImport',
  'DoNotAllowReservationUserChangeByStaff',
  'AssetImageSizeType',
  'AllowToSwitchToOtherSites',
  'ExternalSwitchTitle1',
  'ExternalSwitchUrl1',
  'ExternalSwitchTitle2',
  'ExternalSwitchUrl2',
  'ExternalSitesIcon',
  'RequireSupplierExternalId',
  'MaximumImagesPerOneUpload',
  'MaxLoadedAssetImageMbSize',
  'ModifyReservationNameForUsers',
  'HideCommentSharingForAllUsers',
  'AllowLargeFilesUpload',
  'AssetInventoryDaysWithoutVerification',
  'QRCodeBaseLength', // Asset Related
  'SideBarQuickActions',
  'LimitServiceGroupLicenses',
  'Help_ReservationHistory',
  'Help_UsageHistory',
  'Help_OtherServices',
  'Help_Training',
  'Help_Incidents',
  'Help_ServiceGroupRegistration',
  'BudgetLimited_ByUsers_UserAmountCap',
  'BudgetLimited_ByUsers_UserWorkPackage',
  'SSOuserFirstLogin',
  'SSOuserFirstLoginWithOrWithoutUG',
  'BudgetLimited_ByUsers_UserCostCenter',
  'ServiceRequestReservations',
  'NotifyUpcomingReservations',
  'EmailUpcomingReservationsDailyDigest',
  'UpcomingReservationsSpecificInstrumentAllowed',
  'AddUsersFromAD',
  'ShowDefaultUserGroupOnUserProfile',
  'UserServiceGroupComments',
  'UserLabTechComments',
  'DisplayEditUserDetails',
  'OpenUserProfileOnSidePanel',
  'ReservationTimeout',
  'HideDashboardSubMenu',
  'OpenAssetsInSidePanel',
}
