/**
 *
 * SessionTimeout
 *
 */
import * as React from 'react';
import { useState } from 'react';
import { CheckSessionTimeout, ExtendSessionTimeout } from './SessionTimeout';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { DialogAlert } from './DialogAlert';
import { Roles } from 'app/slice/types';
import { toRootedURL } from 'utils/url-utils';

export interface SessionTimeoutProps {}

export function SessionTimeout() {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  var loginUrl = toRootedURL('/login');
  // math.random is needed to bust the cache for clients with cached result
  var checkUrl = `/sessionTimeout.ashx`;
  var ignoreSession = React.useMemo(() => {
    return (
      authenticatedUser !== undefined &&
      (authenticatedUser.Roles.indexOf(Roles.CalendarOnly) >= 0 ||
        authenticatedUser.Roles.indexOf(Roles.RoomOnly) >= 0 ||
        authenticatedUser.Roles.indexOf(Roles.KioskAdmin) >= 0)
    );
  }, [authenticatedUser]);
  const [show, setShow] = useState(false);
  React.useEffect(() => {
    CheckSessionTimeout(loginUrl, checkUrl, () => {
      setShow(true);
    });
    if (ignoreSession) {
      ExtendSessionTimeout((value: string) => {});
    }
  }, [checkUrl, ignoreSession, loginUrl]);

  function onClose() {
    setShow(false);
  }

  function onButtonClick() {
    ExtendSessionTimeout((value: string) => {});
    setShow(false);
  }
  return ignoreSession ? (
    <React.Fragment></React.Fragment>
  ) : (
    <DialogAlert
      title={t(translations.Warning) as string}
      value={t(translations.SessionExpirationWarning) as string}
      buttonText={t(translations.OK) as string}
      show={show}
      onButtonClick={() => onButtonClick()}
      onClose={() => onClose()}
    />
  );
}
