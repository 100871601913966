import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useConnectedFiltersSlice } from 'app/components/Forms/FormConnectedFilters/slice';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { useOfflineServiceStateSlice } from '../Details/slice';
import {
  selectConsumablesCompleted,
  selectConsumablesData,
  selectConsumablesHasErrors,
  selectCredit,
  selectCreditCompleted,
  selectCreditProcessing,
  selectInitConsumablesProcessing,
} from '../Details/slice/selectors';
import {
  ErrorServices,
  OfflineServiceQueryStringParameters,
  ReportConsumablesState,
} from '../Details/slice/types';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ConsumablesForm } from './ConsumablesForm';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { Progress } from 'app/components/LoadingIndicator';
import { buildURL, toQueryString } from 'utils/url-utils';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Roles, ScanTarget } from 'app/slice/types';
import {
  selectConnectedFiltersData,
  selectConnectedSettings,
} from 'app/components/Forms/FormConnectedFilters/slice/selectors';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { Body, ButtonLabel } from 'app/components/Typography';
import { LinearProgress } from '@material-ui/core';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface ReportConsumablesProps
  extends CoverProps,
    SidePanelContentProps {
  queryParams: OfflineServiceQueryStringParameters;
  initialServices: IOtherServices[];
  onClose?: () => void;
}
export const ReportConsumables = React.memo(function ReportConsumables(
  props: ReportConsumablesProps,
) {
  const {
    useSidePanel,
    queryParams,
    initialServices,
    closeCover,
    isCover,
    onClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: connectedFiltersActions } = useConnectedFiltersSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const processing = useSelector(selectInitConsumablesProcessing);
  // const consumablesProcessing = useSelector(selectConsumablesProcessing);
  const actionCompleted = useSelector(selectConsumablesCompleted);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const hasError = useSelector(selectConsumablesHasErrors);
  const consumableServicesData = useSelector(selectConsumablesData);
  const connectedData = useSelector(selectConnectedFiltersData);
  const publicUrl = useSelector(selectPublicUrl);
  const serviceSettings = useSelector(selectConnectedSettings);
  const credit = useSelector(selectCredit);
  const creditProccessing = useSelector(selectCreditProcessing);
  const creditCompleted = useSelector(selectCreditCompleted);
  //   const { checkIsMobile } = useIsMobile();
  //   const mobile = checkIsMobile();
  const globalSettings = useGlobalSettingsHook();
  //Local State
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        dispatch(actions.resetConsumablesState());
        dispatch(connectedFiltersActions.resetConnectedState());
        if (!!onClose) {
          return false;
        }
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [showCalculateCredit, setShowCalculateCredit] = React.useState(false);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  //Refs
  const submitFormRef = React.useRef<any>(null);
  const connectedFiltersFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<ReportConsumablesState>>(null);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);

  //Methods
  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      if (!!onClose) {
        onClose();
        dispatch(actions.resetConsumablesState());
        dispatch(connectedFiltersActions.resetConnectedState());
      } else {
        closePanel({
          isCover: isCover,
          useSidePanel: useSidePanel,
          showConfirm: hasChanges,
          onClose: () => {
            dispatch(actions.resetConsumablesState());
            dispatch(connectedFiltersActions.resetConnectedState());
          },
        } as SidePanelCloseState);
      }
    } else {
      dispatch(push('/otherServices'));
    }
  }, [
    actions,
    closePanel,
    connectedFiltersActions,
    dispatch,
    hasChanges,
    isCover,
    onClose,
    useSidePanel,
  ]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (connectedFiltersFormRef.current) {
      connectedFiltersFormRef.current(e);
    }
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: ReportConsumablesState) => {
      setBusy(true);
      dispatch(layoutActions.setNotSavedChanges(false));
      dispatch(actions.createConsumables(values));
    },
    [actions, dispatch, layoutActions],
  );
  const handleCalculateCredit = React.useCallback(() => {
    if (!!innerFormRef?.current?.values?.Services) {
      setShowCalculateCredit(true);
      dispatch(actions.getTotalCredit(innerFormRef?.current?.values.Services));
    }
  }, [actions, dispatch]);
  const handleCalculateClose = React.useCallback(() => {
    setShowCalculateCredit(false);
    dispatch(actions.resetTotalCreditState());
  }, [actions, dispatch]);
  //effects
  useEffectOnMount(() => {
    dispatch(
      appSettingsActions.setBarcodeScannigTarget(ScanTarget.Consumables),
    );
    return () => {
      dispatch(appSettingsActions.setBarcodeScannigTarget(ScanTarget.All));
    };
  });
  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initConsumables({
          query: queryParams,
          globalSettings: globalSettings,
          services: initialServices,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    firstLoad,
    globalSettings,
    initialServices,
    queryParams,
  ]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          dispatch(layoutActions.setNotSavedChanges(false));
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    dispatch,
    handleCloselClick,
    hasError,
    layoutActions,
    processing,
    queryParams.id,
  ]);
  const assetsFilter = React.useMemo(() => {
    let filter: (string | Condition<IMyAssetsRow>)[] | undefined = [];
    if (globalSettings.budgetModuleEnabled && serviceSettings.budgetVisible) {
      if (
        connectedData?.Budget === null ||
        connectedData?.Budget === undefined
      ) {
        filter.push(
          new Condition<IMyAssetsRow>(
            'HideProjects',
            ODataOperators.Equals,
            serviceSettings.hideProject ?? false,
          ),
        );
      } else {
        let budget = connectedData?.Budget as IBudgetFilterDto;
        let byService = '';
        let byGroup = '';
        if (!budget.ServiceGroupServicesAllowed) {
          if (!!budget.AllowedServices && budget.AllowedServices.length > 0) {
            byService = `(${new Condition<IMyAssetsRow>(
              'ServiceId',
              ODataOperators.Includes,
              budget.AllowedServices.map(f => f.Id),
            ).toString()} and ServiceTypeId eq 2)`;
          }
          if (!!budget.ServiceGroupIds && budget.ServiceGroupIds.length > 0) {
            byGroup = `(${new Condition<IMyAssetsRow>(
              'ServiceGroupId',
              ODataOperators.Includes,
              budget.ServiceGroupIds,
            ).toString()})`;
          }
          let res = `(${[byService, byGroup]
            .filter(f => f !== '')
            .join(' or ')})`;
          if (res !== '()') {
            filter.push(res);
          }
        } else {
          filter.push('ServiceTypeId eq 2');
        }
      }
    }
    return filter.length > 0 ? filter : undefined;
  }, [
    connectedData?.Budget,
    globalSettings.budgetModuleEnabled,
    serviceSettings.budgetVisible,
    serviceSettings.hideProject,
  ]);

  const onRenewStockClick = React.useCallback(
    (service: IOtherServices) => {
      let renewProps = {
        saveCreatable: true,
        preventRefreshTable: true,
        initialService: undefined,
        queryParams: {
          id: '-1',
          stid: '' + service.ServiceTypeID,
          batchId:
            !!service.InventoryBatchId && service.InventoryBatchId !== null
              ? '' + service.InventoryBatchId
              : undefined,
        },
      };
      if (!useSidePanel) {
        dispatch(layoutActions.setNotSavedChanges(false));
      }
      openPanel({
        renderPageType: RenderPageType.RenewStock,
        renderPageProps: renewProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [dispatch, layoutActions, openPanel, useSidePanel],
  );
  const onAddBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceType: number) => {
      let batchProps = {
        initialBatch: batch ?? undefined,
        preventRefreshTable: true,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceType,
        },
        saveCreatable: true,
      };
      if (!useSidePanel) {
        dispatch(layoutActions.setNotSavedChanges(false));
      }
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [dispatch, layoutActions, openPanel, useSidePanel],
  );
  const onOfflineServiceClick = React.useCallback(
    (service: IOtherServices) => {
      let otherProps = {
        initialService: Object.assign({}, service, {
          UserGroup: connectedData?.UserGroup?.Id ?? null,
          UserGroupName: connectedData?.UserGroup?.Name ?? null,
          Budget: connectedData?.Budget?.Name ?? null,
          BudgetID: connectedData?.Budget?.Id ?? null,
          UserDisplayName: connectedData?.User?.Name ?? null,
          BookedBy: connectedData?.User?.Id ?? null,
        }),
        editCreatable: true,
        queryParams: {
          id: '' + service.Id ?? -1,
        },
      };
      dispatch(layoutActions.setNotSavedChanges(false));
      openPanel({
        renderPageType: RenderPageType.OtherServiceDetails,
        renderPageProps: otherProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [
      connectedData?.Budget?.Id,
      connectedData?.Budget?.Name,
      connectedData?.User?.Id,
      connectedData?.User?.Name,
      connectedData?.UserGroup?.Id,
      connectedData?.UserGroup?.Name,
      dispatch,
      layoutActions,
      openPanel,
      useSidePanel,
    ],
  );
  const onAddServiceClick = React.useCallback(
    (services: number[]) => {
      let selParams = {
        actionType: 'OfflineService',
        offTypeIds: services,
        customPredicates: assetsFilter,
      } as AssetsSelectionProps;
      if (!useSidePanel) {
        dispatch(layoutActions.setNotSavedChanges(false));
      }
      openPanel({
        renderPageType: RenderPageType.AssetsSelection,
        renderPageProps: selParams,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [assetsFilter, dispatch, layoutActions, openPanel, useSidePanel],
  );

  const [offlineServicesErrors, setOfflineServicesErrors] = React.useState<
    ErrorServices[]
  >([]);
  const handleSetError = React.useCallback(
    (error: ErrorServices) => {
      if (error.error !== undefined) {
        setOfflineServicesErrors(prev => [...prev, error]);
      } else {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
        ]);
      }
    },
    [offlineServicesErrors],
  );
  //memo
  const IsAdmin = React.useMemo(() => {
    return !!consumableServicesData &&
      consumableServicesData.Services.length > 0
      ? User?.IsAllGroupOrLabTechAdmin(
          consumableServicesData.Services.map(g => g.ServiceGroupId || 0),
          consumableServicesData.Services.map(f => {
            return {
              Id: f.ServiceTypeId,
              Name: f.ServiceTypeName,
              ServiceTypeId: ServiceType.Offline,
            } as IServiceTypeFilterDto;
          }),
        )
      : false;
  }, [User, consumableServicesData]);
  const leftActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={
          busy ||
          !(
            !!consumableServicesData &&
            consumableServicesData.Services.length > 0
          )
        }
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    if (
      globalSettings.showCalcPriceOnOfflineService &&
      !!consumableServicesData &&
      consumableServicesData.Services.length > 0 &&
      offlineServicesErrors.length < 1
    ) {
      actions.push(() => (
        <Button
          id={'CalculateTotalCreditPopupButtonId'}
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          title={t(translations.CalculatePricing_info) as string}
          onClick={handleCalculateCredit}
          disabled={creditProccessing}
          processing={creditProccessing}
        >
          {t(translations.CalculatePricing)}
        </Button>
      ));
    }
    return actions;
  }, [
    busy,
    consumableServicesData,
    creditProccessing,
    globalSettings.showCalcPriceOnOfflineService,
    handleCalculateCredit,
    handleSaveClick,
    offlineServicesErrors.length,
    requestCompleted,
    t,
  ]);
  const rightActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];

    actions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="times" />}
        onClick={handleCloselClick}
      >
        {t(translations.Close)}
      </Button>
    ));

    return actions;
  }, [handleCloselClick, t]);

  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.Consumables)}
        titlePage={t(translations.LogConsumables)}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || isCover}
        disableExpandToggle={true}
        closeSidePanel={handleCloselClick}
        // leftTopActions={topButtons}
        //rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : leftActions}
        rightActions={isReadOnlyUser ? [] : rightActions}
        children={
          consumableServicesData !== undefined && !processing ? (
            <ConsumablesForm
              initialValues={consumableServicesData}
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              connectedFiltersFormRef={connectedFiltersFormRef}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              editCreatable={true}
              onAddBatchClick={onAddBatchClick}
              onRenewStockClick={onRenewStockClick}
              onOfflineServiceClick={onOfflineServiceClick}
              onAddServiceClick={onAddServiceClick}
              setError={handleSetError}
              offlineServicesErrors={offlineServicesErrors}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Div100>
                <Progress inProgress={processing || false} size={80} />
              </Div100>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'reportConsumables', queryParams)}
        toPageLink={`reportConsumables?${toQueryString(queryParams)}`}
        isCover={isCover}
        cover={cover}
        coverClosed={coverClosed}
        closeCover={!isCover ? onCloseCover : closeCover}
        confirm={
          showCalculateCredit ? (
            <DialogConfirm
              isOpen={showCalculateCredit}
              title={t(translations.CalculatePricing)}
              onConfirm={() => handleCalculateClose()}
              onClose={() => handleCalculateClose()}
              body={
                creditProccessing && !creditCompleted ? (
                  <Div100>
                    <LinearProgress variant="query" color="primary" />
                  </Div100>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '16px',
                      gap: 32,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 16,
                        width: '100%',
                      }}
                    >
                      <ButtonLabel size="small">
                        {t(translations.Pricing_EstimatedCost)}
                      </ButtonLabel>
                      <Body size="small">
                        {(
                          t(
                            translations.PricingConfirmationOfflineText1,
                          ) as string
                        ).replace('{0}', (credit?.credit || 0).toFixed(2))}
                      </Body>
                    </div>
                    {!!credit?.balance && credit?.balance !== null && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: 16,
                          width: '100%',
                        }}
                      >
                        <ButtonLabel size="small">
                          {t(translations.Pricing_Balance)}
                        </ButtonLabel>
                        <Body size="small">
                          {(
                            t(translations.Pricing_BalanceResult) as string
                          ).replace('{0}', (credit?.balance || 0).toFixed(2))}
                        </Body>
                      </div>
                    )}
                  </div>
                )
              }
              maxWidth={'sm'}
            />
          ) : (
            <></>
          )
        }
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
