import * as React from 'react';
import { EntityType } from 'types/common';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from '../AutocompletePicker';
import { createFilterOptions } from '@material-ui/lab';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export interface BaseOptionsPickerProps<T extends EntityType>
  extends Omit<AutocompletePickerProps<T>, 'loadData'> {
  getOptions: (searchTerm: string | null, t?: TFunction) => T[];
}

export function BaseOptionsPicker<T extends EntityType>(
  props: BaseOptionsPickerProps<T>,
) {
  const { getOptions, ...other } = props;
  const { t } = useTranslation();
  const loadData = async (searchTerm: string | null): Promise<T[]> =>
    new Promise<T[]>(resolve => {
      const opts = getOptions(searchTerm, t);
      resolve(opts);
    });
  return (
    <AutocompletePicker
      loadData={loadData}
      filterOptions={
        props.filterSelectedOptions === false
          ? undefined
          : createFilterOptions({
              ignoreAccents: true,
              ignoreCase: true,
              stringify: option => option.Name,
              trim: true,
            })
      }
      {...other}
    />
  );
}
