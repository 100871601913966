import { translations } from 'locales/translations';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { ITrainingSessionsRow } from './ITrainingSessionsRow';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { CampusPickerFilter } from 'app/components/BasicTable/Filters';
import { DatesRangeFilter } from 'app/components/BasicTable/Filters/DatesRangeFilter';
import { BuildingPickerFilter } from 'app/components/BasicTable/Filters/BuildingPickerFilter';
import {
  arrayToQueryString,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getDefaultFilterDatePeriod,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  initMultiPickerValue,
  initPickerValue,
  presetDatesRangeToQueryString,
} from 'app/components/BasicTable/types/FilterParam';
import { initCampusData } from 'app/components/pickers/AutocompletePickers/CampusPicker';
import { initBuildingData } from 'app/components/pickers/AutocompletePickers/BuildingPicker';
import { IsAssetFieldEnabled } from 'types/AppSettings';
import { AssetAttributes } from 'enums/AssetAttributes';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { initRoomTSData } from 'app/components/pickers/AutocompletePickers/RoomTSPicker';
import RoomTSFilter from 'app/components/BasicTable/Filters/RoomTSFilter';
import { TrainingProgramsMultiFilter } from 'app/components/BasicTable/Filters/TrainingProgramsMultiFilter';
import { initTrainingProgramMultiData } from 'app/components/pickers/MultiSelectPickers/TrainingProgramsMultiPicker';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import { IsEntityType } from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { TrainingState } from 'enums/TrainingState';
import UserFilter from 'app/components/BasicTable/Filters/UserFilter';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';

//SG=&eqid=1&campus=&building=&room=&defaultSGids=AllCores&nextonly=false&period=5&Start=2021-11-01T00%3A00%3A00&End=2021-12-01T00%3A00%3A00&sSortCol_0=AssetId
export const GetFilters: GetPageFilters<ITrainingSessionsRow> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParams(search);
  const result: IFilterSettings<ITrainingSessionsRow>[] = [
    {
      fieldName: 'ServiceId',
      displayNameKey: translations.TrainingProgram,
      operator: ODataOperators.Includes,
      queryStringSerializer: arrayToQueryString('eqid'),
      value: getNumberEntityArray(params, 'eqid'),
      component: TrainingProgramsMultiFilter,
      initData: initMultiPickerValue(initTrainingProgramMultiData),
      urlKey: 'eqid',
    },
    {
      fieldName: 'StartDate',
      displayNameKey: translations.CustomDatePeriods,
      operator: ODataOperators.Between,
      component: DatesRangeFilter,
      queryStringSerializer: presetDatesRangeToQueryString(
        'period',
        'start',
        'end',
      ),
      value: getDefaultFilterDatePeriod('period', 'start', 'end', params),
      urlKey: 'period',
    },

    {
      id: 'TrainingState',
      fieldName: 'TrainingState',
      displayNameKey: translations.ShowOnlyWithAvailability,
      operator: ODataOperators.Includes,
      component: BooleanPickerFilter,
      queryStringSerializer: 'OWF',
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'OWF')),
        t,
      ),
      customFilter(value, settings) {
        if (IsEntityType(value) && value.Id === true) {
          return new Condition(settings.fieldName, settings.operator, [
            TrainingState.Available,
            TrainingState.UnderMinimum,
          ]).toString();
        }
      },
      urlKey: 'OWF',
    },
    {
      fieldName: 'IsUpcoming',
      displayNameKey: translations.UpcomingSessionsOnly,
      operator: ODataOperators.Equals,
      component: BooleanPickerFilter,
      queryStringSerializer: 'upcoming',
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'upcoming')),
        t,
      ),
      customFilter(value, settings) {
        if (IsEntityType(value) && value.Id === true) {
          return new Condition(
            settings.fieldName,
            settings.operator,
            value,
            settings.fieldName1,
          ).toString();
        }
      },
      urlKey: 'upcoming',
    },
    {
      id: 'Trainer',
      fieldName: 'Trainer',
      displayNameKey: translations.TrainerName,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'trainer',
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'trainer')),
      ),
      component: UserFilter,
      initData: initPickerValue(initUserData),
    },
  ];
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Building)) {
    result.push({
      fieldName: 'BuildingId',
      displayNameKey: translations.Building,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'building',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'building')),
      ),
      initData: initPickerValue(initBuildingData),
      component: BuildingPickerFilter,
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Room)) {
    result.push({
      fieldName: 'Room',
      displayNameKey: translations.Room,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'room',
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'room')),
      ),
      initData: initPickerValue(initRoomTSData),
      component: RoomTSFilter,
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Campus)) {
    result.push({
      fieldName: 'CampusId',
      displayNameKey: translations.Campus,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'campus',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'campus')),
      ),
      initData: initPickerValue(initCampusData),
      component: CampusPickerFilter,
    });
  }
  return result;
};
