/**
 *
 * BudgetPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import {
  IBudgetFilterDto,
  IBudgetMembersDto,
} from 'api/odata/generated/entities/IBudgetFilterDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import * as React from 'react';
import { Entity } from 'types/common';
import { CustomDate } from 'types/CustomDate';
import { BudgetsUrl } from '../BudgetMultiPicker';

export interface BudgetPickerProps
  extends Omit<AutocompletePickerProps<IBudgetFilterDto>, 'loadData'> {
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
  useSearchOrPredicate?: boolean;
  urlType?: BudgetsUrl;
}

const url = '/api/odata/v4/Budgets';
const allBudgetsUrl = '/api/odata/v4/Budgets/AllBudgets';

export const initBudgetData = (
  initval: string | undefined,
  sourceUrl?: BudgetsUrl,
  select?: (keyof IBudgetFilterDto)[],
  expand?: (keyof Pick<
    IBudgetFilterDto,
    | 'AllowedServices'
    | 'AdGroupUsers'
    | 'ApprovedUsers'
    | 'DefaultBudgetsUsers'
    | 'ServiceGroupIds'
  >)[],
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $select: string;
      $expand?: string;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
      $select: !!select
        ? select.join(',')
        : [
            'Id',
            'Name',
            'UserGroupId',
            'UserGroupName',
            'Active',
            'IsDefault',
            'HideExperiments',
            'FundingTypeId',
            'PurchaseOrderOptionId',
            'ServiceGroupServicesAllowed',
            'UserGroupBudgetMembersAllowed',
            'StartDate',
            'EndDate',
            'Type',
            'ApprovedUsers',
            'DefaultBudgetsUsers',
            'ServiceGroupIds',
            'AllowedServices',
            'AdGroupUsers',
            'Confidential',
          ].join(','),
      $expand: !!expand ? expand.join(',') : 'AllowedServices,ApprovedUsers',
    };
    let initUrl = sourceUrl === 'full' ? allBudgetsUrl : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as Entity<number>[]);
  }
};

const loadData = (
  queryUrl: BudgetsUrl,
  predicates: (string | Condition<IBudgetFilterDto>)[] | undefined,
  useSearchOrPredicate?: boolean,
) =>
  getAutoCompleteLoadDataFn<IBudgetFilterDto>({
    url: queryUrl === 'full' ? allBudgetsUrl : url,
    predicates: predicates,
    maxTop: 20,
    select: [
      'Id',
      'Name',
      'UserGroupId',
      'UserGroupName',
      'Active',
      'IsDefault',
      'HideExperiments',
      'FundingTypeId',
      'PurchaseOrderOptionId',
      'ServiceGroupServicesAllowed',
      'UserGroupBudgetMembersAllowed',
      'StartDate',
      'EndDate',
      'Type',
      'ApprovedUsers',
      'DefaultBudgetsUsers',
      'ServiceGroupIds',
      'AllowedServices',
      'AdGroupUsers',
      'Confidential',
    ],
    expand: 'AllowedServices,ApprovedUsers',
    useSearchOrPredicate: useSearchOrPredicate,
  });
export function BudgetPicker(props: BudgetPickerProps) {
  const { urlType, predicates, useSearchOrPredicate } = props;
  const innerLoadData = loadData(
    urlType ?? 'base',
    predicates,
    useSearchOrPredicate,
  );

  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'BudgetId'}
      {...props}
    />
  );
}
export const BudgetByUserGroupFilter = (value: FilterValueType) => {
  if (value !== undefined && value !== null) {
    return [
      `${new Condition<IBudgetFilterDto>(
        'UserGroupId',
        ODataOperators.Equals,
        null,
      ).toString()} or ${new Condition<IBudgetFilterDto>(
        'UserGroupId',
        ODataOperators.Equals,
        (value as Entity<string>).Id,
      ).toString()}`,
    ];
  }
  return [];
};
const memberTimeFilter = (start?: Date, end?: Date): string => {
  let query = '';
  if (start === undefined && end === undefined) {
    return query;
  } else {
    if (start !== undefined) {
      query += ` and (u/${new Condition<IBudgetMembersDto>(
        'ExpairedDate',
        ODataOperators.Equals,
        null,
      )} or u/${new Condition<IBudgetMembersDto>(
        'ExpairedDate',
        ODataOperators.GreaterThanEqual,
        new CustomDate(new Date(start), 'date'),
      )})`;
    }
    if (end !== undefined) {
      query += ` and (u/${new Condition<IBudgetMembersDto>(
        'ExpairedDate',
        ODataOperators.Equals,
        null,
      )} or u/${new Condition<IBudgetMembersDto>(
        'ExpairedDate',
        ODataOperators.GreaterThanEqual,
        new CustomDate(new Date(end), 'date'),
      )})`;
    }
  }
  return query;
};
export const loadBudgets = loadData;
export interface restrictedBudgetFilterProps {
  budgetLimitedByUsers: boolean;
  isBudgetsWithoutUserGroup: boolean;
  userGroupId?: string;
  serviceGroupId?: number;
  services?: IServiceTypeFilterDto[];
  startTime?: Date;
  endTime?: Date;
  user?: string;
  defaultBudgetEnabled?: boolean;
  userDefaultBudgetEnabled?: boolean;
  hideNonDefaultBudgets?: boolean;
  fundingType?: Entity<number> | null;
}
export function restrictedBudgetFilter({
  budgetLimitedByUsers,
  isBudgetsWithoutUserGroup,
  userGroupId,
  serviceGroupId,
  services,
  startTime,
  endTime,
  user,
  defaultBudgetEnabled,
  userDefaultBudgetEnabled,
  hideNonDefaultBudgets,
  fundingType,
}: restrictedBudgetFilterProps): (string | Condition<IBudgetFilterDto>)[] {
  const predicates: (string | Condition<IBudgetFilterDto>)[] = [];
  if (!!budgetLimitedByUsers && user !== undefined) {
    /**
     * when UserGroupBudgetMembersAllowed, it should give a pass for:
     * - all users in case and budget/usergroup field is turned off
     * - budgets/s user group users if the field is turned on and populated, all users otherwise
     * */

    let UserGroupBudgetMembersAllowed = new Condition<IBudgetFilterDto>(
      'UserGroupBudgetMembersAllowed',
      ODataOperators.Equals,
      true,
    ).toString();

    const budgetUserGroupMembers = isBudgetsWithoutUserGroup
      ? undefined
      : `(${new Condition<IBudgetFilterDto>(
          'UserGroupId',
          ODataOperators.Equals,
          null,
        )} or ${new Condition<IBudgetFilterDto>(
          'AdGroupUsers',
          ODataOperators.Any,
          user,
        )})`;
    const u = [UserGroupBudgetMembersAllowed, budgetUserGroupMembers]
      .filter(f => f !== undefined)
      .join(' and ');

    let usrFilter = `((${u}) or (ApprovedUsers/any(u: u/UserName eq ${quoteODataValue(
      user,
    )} ${memberTimeFilter(startTime, endTime)})))`;
    predicates.push(usrFilter);
  }
  if (!isBudgetsWithoutUserGroup) {
    if (userGroupId !== undefined) {
      predicates.push(
        new Condition<IBudgetFilterDto>(
          'UserGroupId',
          ODataOperators.Equals,
          userGroupId,
        ),
      );
    } else {
      predicates.push(
        new Condition<IBudgetFilterDto>(
          'UserGroupId',
          ODataOperators.NotEquals,
          null,
        ),
      );
    }
  }
  const fundingTypeId = fundingType?.Id ?? undefined;
  if (fundingTypeId !== undefined) {
    predicates.push(
      new Condition<IBudgetFilterDto>(
        'FundingTypeId',
        ODataOperators.Equals,
        fundingTypeId,
      ),
    );
  }

  if (startTime !== undefined || (endTime !== undefined && false)) {
    let startFilter = start =>
      `(${new Condition<IBudgetFilterDto>(
        'StartDate',
        ODataOperators.Equals,
        null,
      )} or ${new Condition<IBudgetFilterDto>(
        'StartDate',
        ODataOperators.LessThanEqual,
        start,
      )}) and (${new Condition<IBudgetFilterDto>(
        'EndDate',
        ODataOperators.Equals,
        null,
      )} or ${new Condition<IBudgetFilterDto>(
        'EndDate',
        ODataOperators.GreaterThanEqual,
        start,
      )})`;
    let endFilter = end =>
      `(${new Condition<IBudgetFilterDto>(
        'StartDate',
        ODataOperators.Equals,
        null,
      )} or ${new Condition<IBudgetFilterDto>(
        'StartDate',
        ODataOperators.LessThanEqual,
        end,
      )}) and (${new Condition<IBudgetFilterDto>(
        'EndDate',
        ODataOperators.Equals,
        null,
      )} or ${new Condition<IBudgetFilterDto>(
        'EndDate',
        ODataOperators.GreaterThanEqual,
        end,
      )})`;
    if (!!startTime && endTime === undefined) {
      predicates.push(startFilter(new CustomDate(new Date(startTime), 'date')));
    } else if (!!endTime && startTime === undefined) {
      predicates.push(endFilter(new CustomDate(new Date(endTime), 'date')));
    } else if (!!startTime && !!endTime) {
      predicates.push(
        `(${startFilter(
          new CustomDate(new Date(startTime), 'date'),
        )} and ${endFilter(new CustomDate(new Date(endTime), 'date'))})`,
      );
    }
  }
  if (serviceGroupId !== undefined) {
    let groupFilter = `${new Condition<IBudgetFilterDto>(
      'ServiceGroupServicesAllowed',
      ODataOperators.Equals,
      true,
    ).toString()} or ${new Condition<IBudgetFilterDto>(
      'ServiceGroupIds',
      ODataOperators.Any,
      serviceGroupId,
    ).toString()}`;
    predicates.push(groupFilter);
  }
  if (services !== undefined && services.length > 0) {
    let list = services
      .map(f => {
        return `(AllowedServices/any(s: s/Id eq ${quoteODataValue(
          f.Id,
        )} and s/ServiceTypeId eq ${
          f.ServiceTypeId
        }) or ServiceGroupIds/any(g: g eq ${quoteODataValue(
          f.ServiceGroupId,
        )}))`;
      })
      .join(' and ');
    let servFilter = `(${new Condition<IBudgetFilterDto>(
      'ServiceGroupServicesAllowed',
      ODataOperators.Equals,
      true,
    ).toString()} or (${list}))`;
    predicates.push(servFilter);
  }
  // if (isDefault) {
  //   predicates.push(
  //     new Condition<IBudgetFilterDto>('IsDefault', ODataOperators.Equals, true),
  //   );
  // }
  if (hideNonDefaultBudgets) {
    let deffFilter: (string | Condition<IBudgetFilterDto>)[] = [];
    if (defaultBudgetEnabled) {
      deffFilter.push(
        new Condition<IBudgetFilterDto>(
          'IsDefault',
          ODataOperators.Equals,
          true,
        ),
      );
    }
    if (userDefaultBudgetEnabled && user !== undefined) {
      deffFilter.push(
        new Condition<IBudgetFilterDto>(
          'DefaultBudgetsUsers',
          ODataOperators.Any,
          user,
        ),
      );
    }
    if (deffFilter.length > 0) {
      predicates.push(deffFilter.map(f => f.toString()).join(' or '));
    }
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
