import { DropDown } from 'app/components/DropDown';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { LinearProgress, List, ListItem, MenuItem } from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { useTranslation } from 'react-i18next';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { usePromise } from 'app/hooks/usePromise';
import { loadOfflineServiceData } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import styled from 'styled-components';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

export interface AddServiceProps {
  getServiceTypes?: (
    searchTerm: string | null,
    predicates: (string | Condition<IOfflineServiceFilterDto>)[] | undefined,
  ) => Promise<IOfflineServiceFilterDto[]>;
  addService: (serviceType: IOfflineServiceFilterDto) => void;
  selected: IOtherServices[];
  disabled?: boolean;
}

export function AddService({
  addService,
  getServiceTypes,
  selected,
  disabled,
}: AddServiceProps) {
  const { t } = useTranslation();

  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);

  const [input, setInnput] = React.useState<string>('');
  const fetchServiceTypes = async (): Promise<IOfflineServiceFilterDto[]> => {
    const filter =
      !!selected && selected.length > 0
        ? [
            new Condition<IOfflineServiceFilterDto>(
              'Id',
              ODataOperators.Excludes,
              selected.map(s => s.ServiceTypeID),
            ),
          ]
        : undefined;
    return new Promise(async (resolve, reject) => {
      if (!!getServiceTypes) {
        try {
          const data = await getServiceTypes(input || null, filter);
          resolve(data);
        } catch {
          resolve([]);
        }
      } else {
        try {
          const data = await loadOfflineServiceData(
            filter,
            'base',
            globalServiceGroupFilter || [],
          )(input || null, undefined);
          resolve(
            data.value.filter(
              f => !selected.some(s => s.ServiceTypeID === f.Id),
            ),
          );
        } catch (error) {
          resolve([]);
        }
      }
    });
  };
  const [serviceTypes, setServiceTypes] = useAsyncExtendedState<
    IOfflineServiceFilterDto[]
  >([]);

  const [fetchServiceTypeState, fetchServiceTypePromise] =
    usePromise(fetchServiceTypes);
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  useEffectOnMount(() => {
    setServiceTypes(fetchServiceTypePromise());
  });

  return (
    <DropDown
      variant="white"
      size="small"
      disabled={disabled}
      onOpen={() => {
        setServiceTypes([]);
        setServiceTypes(fetchServiceTypePromise());
      }}
      onHandleClose={() => {
        setInnput('');
        setServiceTypes([]);
      }}
      menuChildren={onClose => [
        <ListItem
          key="lists-search"
          onKeyDown={e => e.stopPropagation()}
          onClickCapture={stopImmediatePropagation}
        >
          <TextControlField
            placeholder={t(translations.SearchFilter)}
            startIcon={
              <Icon icon="search" color="filters" colorExtend="light" />
            }
            value={input || ''}
            size="small"
            aria-label={t(translations.SearchFilter)}
            inputProps={{
              'aria-label': t(translations.SearchFilter),
            }}
            onChange={e => {
              setInnput(e.target.value);
              setTimeout(() => setServiceTypes(fetchServiceTypePromise()), 200);
            }}
            autoFocus
            fullWidth
          />
        </ListItem>,
        <ListItem key="list-results">
          <ContentWrapper>
            <List>
              {fetchServiceTypeState.status === 'pending' && (
                <LinearProgress variant="query" color="primary" />
              )}
              {serviceTypes.length > 0 &&
                serviceTypes.map(s => (
                  <MenuItem
                    key={s.Id}
                    onClick={e => {
                      addService(s);
                      onClose();
                    }}
                  >
                    {s.Name}
                  </MenuItem>
                ))}
              {fetchServiceTypeState.status !== 'pending' &&
                serviceTypes.length < 1 && (
                  <BasicTypography display="inline">
                    {t(translations.NotFound)}
                  </BasicTypography>
                )}
            </List>
          </ContentWrapper>
        </ListItem>,
      ]}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      addChevron
    >
      {t(translations.AddConsumable)}
    </DropDown>
  );
}
const ContentWrapper = styled.div`
  width: 300px;
  height: 300px;
  max-height: 300px;
  max-width: 300px;
  overflow-y: auto;
`;
