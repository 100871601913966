import React from 'react';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { useTranslation } from 'react-i18next';
import { ITrainingSessionsRow } from './ITrainingSessionsRow';
import { translations } from 'locales/translations';

type RowType = ITrainingSessionsRow;

export function TrainingSessionsTable<
  TProps extends Omit<
    BasicTableProps<RowType>,
    'api' | 'additionalColumns' | 'searchColumns' | 'screenName'
  >
>({
  initialState,
  rowActions,
  serviceGroups,
  savedListSettings,
  ...props
}: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/TrainingSessions';
  return (
    <>
      <BasicTable
        api={url}
        initialState={initialState}
        additionalColumns={[
          'Id',
          'AssetId',
          'ServiceId',
          'HasNextSession',
          'hasReservation',
          'myReservationId',
          'TrainingState',
          'EndDate',
          'StartDate',
          'ServiceGroupId',
          'RoomId',
        ]}
        rowActions={rowActions}
        useRowSelect={true}
        searchColumns={[
          'cast(Id,Edm.String)',
          'AssetName',
          'ServiceGroup',
          'Campus',
          'Building',
          'Room',
          'Remarks',
        ]}
        screenName={t(translations.AllTrainingSessions)}
        serviceGroups={serviceGroups}
        savedListSettings={{
          enableSavedLists: true,
          savedListRelatedPickerName: 'ServiceId',
          savedListSerializedKey: 'eqid',
        }}
        {...(newProps as TProps)}
      />
    </>
  );
}
