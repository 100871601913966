import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { StyledFormProps } from '../StyledForm';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Section } from '../wrappers';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';
import { useIsMobile } from 'app/hooks/useIsMobile';

export const StyledFormFieldsContainer = (
  props: Omit<StyledFormProps, 'onSubmit' | 'ref'>,
) => {
  const {
    avoidStyles,
    children,
    useRequestSection,
    isCover,
    noPadding,
    ...other
  } = props;
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView =
    (isMobile && !isLandscape) ||
    (sidePanelOpen && !sidePanelExpanded) ||
    isCover;
  const theme = useTheme();
  return (
    <Section
      theme={theme}
      sidePanelWidth={SIDE_PANEL_WIDTH}
      className={
        avoidStyles
          ? undefined
          : clsx('formsection', {
              shortView: showShortView,
              noPadding: noPadding,
              'request-section': useRequestSection,
            })
      }
      {...other}
    >
      {children}
    </Section>
  );
};
