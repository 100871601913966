import { RouteComponentProps } from 'react-router-dom';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useCallback, useMemo, useRef, useState } from 'react';
import { isNullOrUndefined } from 'utils/typeUtils';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { Entity } from 'types/common';
import { FileAvailability } from './fileAvailability';
import * as Yup from 'yup';
import {
  EntityNumberSchema,
  EntityStringSchema,
} from 'app/components/Forms/Schemas';
import { Form, isFileType } from './Form';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { httpClient } from 'api/HttpClient';
import { odataUrlPrefix } from 'utils/url-utils';
import {
  formFileSchema,
  IFormFileValue,
} from 'app/components/CustomForm/CustomFormUtils';
import { useResponseModel } from 'app/hooks/useResponseModel';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';

export interface AssetFileProps {
  id?: string | null;
  assetId: number | null;
}

interface AssetFilePageProps extends AssetFileProps {
  useSidePanel: boolean;
}

export const AssetFilePage = (props: RouteComponentProps) => {
  const params = useMemo(() => {
    return new URLSearchParams(props.location.search);
  }, [props.location.search]);
  const assetId = parseInt(params.get('AssetId') ?? '');
  return (
    <AssetFile
      assetId={isNaN(assetId) ? null : assetId}
      id={params.get('id')}
      useSidePanel={false}
    />
  );
};

export type AssetFileType = {
  fileType: 'file';
  title?: string | null;
  description?: string | null;
  docType: Entity<number> | null;
  fileAvailable: FileAvailability;
  assetId: number | null;
  asset: Entity<number> | null;
  file: IFormFileValue[];
};

export type AssetLinkType = {
  fileType: 'link';
  assetId: Number | null;
  asset: Entity<number> | null;
  fileLink?: Entity<string> | null;
  fromAsset?: Entity<number> | null;
};

export type AssetTypes = AssetFileType | AssetLinkType;

const defaultAssetFile: AssetTypes = {
  fileType: 'file',
  docType: null,
  fileAvailable: FileAvailability.StaffUsersFiles,
  assetId: null,
  asset: null,
  file: [],
};

interface ServerSideAssetType {
  Id: string;
  Name: string;
  AssetId: number;
  FileType: AssetTypes['fileType'];
  AssetDocTypeId: number | null;
  AssetDocTypeName: string | null;
  Title: string;
  Description: string | null;
  AvailableToUsers: boolean | null;
  LinkedAssetId: number | null;
  LinkedAssetName: string | null;
}

const url = odataUrlPrefix + 'AssetFiles';

const toAssetType = (value: ServerSideAssetType): AssetTypes => {
  if (value.FileType.toLowerCase() === 'link') {
    return {
      fromAsset:
        value.LinkedAssetId === null
          ? null
          : { Id: value.LinkedAssetId, Name: value.LinkedAssetName },
      assetId: value.AssetId,
      fileType: 'link',
      asset: null,
      fileLink: { Id: value.Id, Name: value.Name },
    } as AssetLinkType;
  }
  return {
    asset: null,
    assetId: value.AssetId,
    fileAvailable:
      value.AvailableToUsers === true
        ? FileAvailability.StaffUsersFiles
        : value.AvailableToUsers === false
        ? FileAvailability.StaffOnlyFiles
        : FileAvailability.PublicFiles,
    fileType: 'file',
    file: [{ Value: value.Id, DisplayValue: value.Name }],
    title: value.Title,
    description: value.Description,
    docType: { Id: value.AssetDocTypeId, Name: value.AssetDocTypeName },
  } as AssetFileType;
};

const post = async (values: AssetTypes, id?: string | null) => {
  const form = new FormData();
  if (!!id) {
    form.append('oldId', id);
  }
  form.append('FileType', values.fileType);
  form.append('AssetId', values.assetId!.toString());
  if (!isFileType(values)) {
    form.append('LinkedAssetId', values.fromAsset!.Id.toString());
    form.append('Id', values.fileLink!.Id);
  } else {
    form.append('Title', values.title!);
    if (!!values.description) form.append('Description', values.description);
    form.append('AssetDocTypeId', values.docType!.Id.toString());
    if (values.fileAvailable !== FileAvailability.PublicFiles) {
      form.append(
        'AvailableToUsers',
        JSON.stringify(
          values.fileAvailable === FileAvailability.StaffUsersFiles,
        ),
      );
    }

    form.append('File', values.file![0].PostedFile!);
  }
  return httpClient.post(url, form);
};

export const AssetFile = ({
  useSidePanel,
  assetId,
  ...props
}: AssetFilePageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const close = useCallback(() => {
    if (useSidePanel) {
      dispatch(actions.resetSidePanel());
    }
  }, [actions, dispatch, useSidePanel]);
  const noAsset = assetId === null;
  const isNew = isNullOrUndefined(props.id) || noAsset;
  const [loading, setLoading] = useState(false);
  const baseModel: AssetTypes | undefined = useAsyncGetWthEffect(
    async () => {
      if (isNew) return Promise.resolve({ ...defaultAssetFile, assetId });
      const res = await httpClient.get<ServerSideAssetType>(
        url + '(' + props.id + ')',
        { assetId },
      );
      return toAssetType(res);
    },
    undefined,
    [],
    setLoading,
  );
  const sendNotification = useResponseModel();
  const shouldReset = useRef(false);

  const refreshTable = useCallback(() => {
    dispatch(actions.setRefreshTable(true));
  }, [dispatch, actions]);

  const handleSubmit = useCallback(
    (values: AssetTypes) => {
      post(values, isNew ? undefined : props.id)
        .then(sendNotification)
        .then(() =>
          shouldReset.current
            ? dispatch(
                actions.openSidePanel({
                  type: RenderPageType.AssetFile,
                  props: {
                    assetId: assetId,
                    id: props.id,
                  } as AssetFileProps,
                  expanded: true,
                }),
              )
            : close(),
        )
        .finally(() => {
          refreshTable();
        });
    },
    [
      actions,
      assetId,
      close,
      dispatch,
      isNew,
      props.id,
      refreshTable,
      sendNotification,
    ],
  );

  const FileSchema: Yup.SchemaOf<AssetFileType> | undefined = useMemo(
    () =>
      baseModel === undefined
        ? undefined
        : Yup.object({
            fileType: Yup.mixed().required(),
            assetId: Yup.number().nullable().required(),
            title: Yup.string().nullable().required(),
            description: Yup.string().nullable().notRequired(),
            docType: EntityNumberSchema.nullable().required(),
            fileAvailable: Yup.number().required(),
            file: Yup.array()
              .of(formFileSchema)
              .min(1, t(translations.Required)),
            asset:
              assetId === null
                ? EntityNumberSchema.nullable().required()
                : EntityNumberSchema.nullable().notRequired(),
          }),
    [assetId, baseModel, t],
  );

  const linkSchema: Yup.SchemaOf<AssetLinkType> | undefined = useMemo(
    () =>
      baseModel === undefined
        ? undefined
        : Yup.object({
            assetId: Yup.number().nullable().required(),
            fileLink: EntityStringSchema.nullable().required(),
            fromAsset: EntityNumberSchema.nullable().required(),
            fileType: Yup.mixed().required(),
            asset:
              assetId === null
                ? EntityNumberSchema.nullable().required()
                : EntityNumberSchema.nullable().notRequired(),
          }),
    [assetId, baseModel],
  );
  const submitForm = useRef<() => void>();
  const setSubmit = useCallback(func => (submitForm.current = func), []);
  const [valid, setValid] = useState(false);

  const leftActions: ActionRenderer[] = useMemo(() => {
    const newActions = [
      submit => (
        <Button
          startIcon={<Icon icon="save" />}
          disabled={loading || !valid}
          processing={loading}
          onClick={() => {
            shouldReset.current = false;
            submit();
          }}
        >
          {t(translations.Save)}
        </Button>
      ),
      submit => (
        <Button
          startIcon={<Icon icon="save" />}
          disabled={loading || !valid}
          processing={loading}
          onClick={() => {
            shouldReset.current = true;
            submit();
          }}
        >
          {t(translations.SavePlusOne)}
        </Button>
      ),
    ];
    if (!isNew) {
      newActions.push(() => (
        <Button
          startIcon={<Icon icon="trash" />}
          color="default"
          onClick={() => {
            httpClient
              .post(url + '/Remove', { id: props.id, assetId })
              .then(v => sendNotification(v))
              .then(close);
          }}
          disabled={loading}
          variant="danger"
        >
          {t(translations.Delete)}
        </Button>
      ));
    }
    if (useSidePanel) {
      newActions.push(() => (
        <Button
          onClick={() => {
            close();
          }}
          color="secondary"
          variant="gray"
          disabled={loading}
        >
          {t(translations.Cancel)}
        </Button>
      ));
    }
    return newActions;
  }, [
    assetId,
    close,
    isNew,
    loading,
    props.id,
    sendNotification,
    t,
    useSidePanel,
    valid,
  ]);
  return (
    <PageWrapper
      loading={loading}
      titlePage={t(translations.menu_AssetFile)}
      useSidePanel={useSidePanel}
      leftActions={leftActions}
      onSubmit={() => submitForm.current?.()}
      closable={useSidePanel}
      disableExpandToggle
      leftActionsMaxLength={4}
    >
      {!!baseModel && !!linkSchema && !!FileSchema && (
        <Form
          setValid={setValid}
          initialValues={baseModel}
          fileSchema={FileSchema}
          linkSchema={linkSchema}
          onSubmit={handleSubmit}
          processing={loading}
          hasAsset={!noAsset}
          isEdit={!isNew}
          setSubmit={setSubmit}
        />
      )}
    </PageWrapper>
  );
};
