import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Entity } from 'types/common';
import { IUsageDto, UsageDetailsState } from './types';

export function ConvertDtoToModel(
  dto: IUsageDto,
  globalSettings?: GlobalSettingsType,
) {
  let model: UsageDetailsState = {
    Id: dto.Id,
    BaseEquipment: dto.BaseEquipment as Entity<number>,
    BookedBy: dto.BookedBy as Entity<string>,
    AccServices: (dto.AccServices as Entity<number>[]) || [],
    Budget: dto.Budget as Entity<number>,
    EndTime: dto.EndTime,
    InstituteProject: (dto.InstituteProject as Entity<number>) || null,
    StartTime: dto.StartTime,
    Tutoring: dto.Tutoring,
    Operator: (dto.Operator as Entity<string>) || null,
    ADGroup: (dto.ADGroup as Entity<string>) || null,
    BudgetExperiment: (dto.BudgetExperiment as Entity<number>) || null,
    ExternalCustomer: (dto.ExternalCustomer as Entity<number>) || null,
    FundingType: getSingleInvoiceFundingType(dto.FundingTypeId || 0),
    PurchaseOrder: dto.PurchaseOrder,
    Reference: dto.Reference,
    Remarks: dto.Remarks,
  };
  return model;
}
