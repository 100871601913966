import { Theme } from '@material-ui/core';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const RichEditorRoot = styled.div<{
  theme: Theme;
}>`
  &.rich-editor-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${props => props.theme.palette.common.white};
    border: 1px solid ${props => props.theme.palette.common.grayComponentsLight};
    box-sizing: border-box;
    border-radius: 8px;
    & .rich-editor-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background: ${props => props.theme.palette.common.white};
      border-bottom: 1px solid
        ${props => props.theme.palette.common.grayComponentsLight};
      box-sizing: border-box;
      border-radius: 8px 8px 0px 0px;
      & .rich-editor-header-controls {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        width: 100%;
        flex-wrap: wrap;
        & .rich-editor-header-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border: none;
          border-radius: 0;
          box-shadow: inset -1px 0px 0px #e0e5ec;
          color: ${props => props.theme.palette.common.blackRegular};
        }
      }
    }
    & .rich-editor-text {
      padding-left: 8px;
      width: 100%;
      min-height: 32px;
      max-height: 300px;
      overflow-y: auto;
      &.text-with-info {
        padding-right: 24px;
      }
      &.text-with-controls {
        padding-top: 8px;
      }
      & .info-icon {
        position: absolute;
        right: 12px;
        top: 16px;
        &.info-icon-top-label {
          top: 42px;
        }
        &.info-icon-controls {
          top: 48px;
        }
        &.info-icon-top-controls {
          top: 72px;
        }
      }
    }
    & .rich-editor-body {
      width: 100%;
      max-height: 42vh;
      min-height: 100px;
      padding-left: 8px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 2px
          ${props => props.theme.palette.common.grayBorders};
        border-radius: 6px;
        background-color: ${props => props.theme.palette.common.grayBorders};
      }
      & .tr-row {
        text-align: center;
      }
      & .td-row {
        border: 1px solid ${props => props.theme.palette.common.grayBorders};
      }
      & .mention-element {
        padding: 3px 3px 2px;
        margin: 0 1px;
        vertical-align: baseline;
        display: inline-block;
        border-radius: 10px;
        background-color: #eee;
        font-size: 0.9em;
        box-shadow: none;
        &.mention-element-selected {
          box-shadow: 0 0 0 2px #b4d5ff;
        }
      }
      & .image-element {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        max-height: 20em;
        box-shadow: none;
        &.image-element-selected {
          box-shadow: 0 0 0 2px #b4d5ff;
        }
      }
    }
  }
`;

export const MentionsWrapper = styled.div`
  &.mentions-root {
    position: absolute;
    padding: 3px;
    background: ${bookitColors.base.white};
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 10000;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    & .mentions-item {
      padding: 1px 3px;
      border-radius: 3px;
      background: transparent;
      &.mentions-item-active {
        background: ${bookitColors.primary.background};
      }
    }
  }
`;
