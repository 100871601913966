import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { Button } from 'app/components/BasicButtons/Button';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { Link } from 'app/components/ExternalLink';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { InvoiceStatusPicker } from 'app/components/pickers/AutocompletePickers/InvoiceStatusPicker';
import { InvoiceTemplatePicker } from 'app/components/pickers/AutocompletePickers/InvoiceTemplatePicker';
import { ReactActions } from 'app/components/ReactActions';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useResponseModel } from 'app/hooks/useResponseModel';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
  selectPublicUrl,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { translations } from 'locales/translations';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { AllowedSettings } from 'utils/globalSettings';
import { buildURL, openExportLink, toRootedURL } from 'utils/url-utils';
import { ChargeProps } from '../AddChargePage';
import { PaymentProps } from '../AddPaymentPage';
import { InvoiceComments } from './InvoiceComments';
import { InvoiceInReview } from './InvoiceInReview';
import { InvoiceRemarks } from './InvoiceRemarks';
import { ReadOnlyDetails } from './ReadOnly';
import { useInvoiceDetailsSlice } from './Slice';
import {
  selectActiveFilters,
  selectInvoice,
  selectLoading,
  selectNotFound,
} from './Slice/selector';
import { DetailsTable } from './Tables/Details';
import { IInvoiceRow } from './Tables/Details/IInvoicRow';
import { InvoiceHistory } from './Tables/InvoiceHistory';
import { PayHistory } from './Tables/PayHistory';
import { PaymentTable } from './Tables/Payments';
import { RowHistory } from './Tables/RowHistory';
import { InvoiceIdMultiPicker } from 'app/components/pickers/AutocompletePickers/InvoiceIdMultiPicker';
import { useAppSettingsSlice } from 'app/slice';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import styled from 'styled-components';
const url = '/api/odata/v4/InvoiceDetails';
export interface InvoiceDetailsProps extends CoverProps, SidePanelContentProps {
  id: number;
}
export const InvoiceDetails = ({
  id,
  useSidePanel,
  closeCover,
  isCover,
}: InvoiceDetailsProps) => {
  const { t } = useTranslation();
  const loading = useSelector(selectLoading);
  const notFound = useSelector(selectNotFound);
  const filters = useSelector(selectActiveFilters);
  const dispatch = useDispatch();
  const { actions } = useInvoiceDetailsSlice();
  const { actions: appActions } = useAppSettingsSlice();
  const invoice = useSelector(selectInvoice);
  const publicUrl = useSelector(selectPublicUrl);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const response = useResponseModel();
  const [deleteInvoiceOpen, setDeleteInvoiceOpen] = useState(false);
  const settings = useSelector(selectglobalSettings);
  const allowedFees = settings.GetString(AllowedSettings.AllowedFeeTypes);
  const isChargesAllowed = allowedFees && allowedFees.trim() !== '';
  useEffectOnMount(() => {
    dispatch(actions.reset());
    dispatch(actions.getInvoice(id));
  });
  const pagelink = useMemo(
    () => buildURL(`${publicUrl}Invoices/Details/${id}`, filters ?? {}),
    [filters, id, publicUrl],
  );
  const setfilterCallback = useCallback(
    (v: IFilterSettings<IInvoiceRow>[] | undefined) => {
      const res = {};
      if (!!v) {
        toURLSearchParams(v).forEach((val, name) => {
          res[name] = val;
        });
      }
      dispatch(actions.setFilters(!v || v.length === 0 ? undefined : res));
    },
    [actions, dispatch],
  );
  const [invoiceHistoryOpen, setInvoiceHistoryOpen] = useState(false);
  const [rowHistoryOpen, setRowHistoryOpen] = useState(false);
  const [payHistoryOpen, setPayHistoryOpen] = useState(false);
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [updateInvoiceTemplateOpen, setUpdateInvoiceTemplateOpen] = useState(
    false,
  );
  const [updateInvoiceIdsOpen, setUpdateInvoiceIdsOpen] = useState(false);
  // const [cover, setCover] = useState<ReactNode>();
  const {
    cover,
    openPanel,
    closePanel,
    coverClosed,
    onCloseCover,
  } = useSidePanelState();
  const openNewCharge = useCallback(
    (chargProps: ChargeProps) => {
      openPanel({
        renderPageType: RenderPageType.AddCharge,
        renderPageProps: chargProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
      // if (isSidePanel) {
      //   setCover(
      //     <AddCharge
      //       closeCover={() => setCover(undefined)}
      //       isCover
      //       {...props}
      //     />,
      //   );
      // } else {
      //   dispatch(
      //     layoutActions.openSidePanel({
      //       type: RenderPageType.AddCharge,
      //       props,
      //     }),
      //   );
      // }
    },
    [openPanel, useSidePanel],
  );
  const openNewPayment = useCallback(
    (paymentProps: PaymentProps) => {
      openPanel({
        renderPageType: RenderPageType.AddPayment,
        renderPageProps: Object.assign({}, paymentProps, {
          userGroupId: invoice?.UserGroupId,
        }),
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
      // if (isSidePanel) {
      //   setCover(
      //     <AddPayment
      //       closeCover={() => setCover(undefined)}
      //       isCover
      //       userGroupId={invoice?.UserGroupId}
      //       {...props}
      //     />,
      //   );
      // } else {
      //   dispatch(
      //     layoutActions.openSidePanel({
      //       type: RenderPageType.AddPayment,
      //       props,
      //     }),
      //   );
      // }
    },
    [invoice?.UserGroupId, openPanel, useSidePanel],
  );
  const deletable = useMemo(() => !!invoice && !invoice.LastSentToBilling, [
    invoice,
  ]);
  const [newStatus, setNewStatus] = useState<Entity<number> | null>(null);
  const [invoiceTemplate, setInvoiceTemplate] = useState<Entity<number> | null>(
    null,
  );
  const [invoiceIds, setInvoiceIds] = useState<Entity<number>[] | undefined>(
    undefined,
  );

  const deleteInvoice = useCallback(() => {
    if (deletable) {
      httpClient
        .delete<IResponseType>(url + '(' + id + ')')
        .then((v: any) => {
          const value = v as IResponseType;
          if (value.ErrorMessages.length > 0) {
            response(value);
          } else {
            if (useSidePanel) {
              closePanel({
                useSidePanel: true,
                isCover: isCover,
              } as SidePanelCloseState);
            } else {
              dispatch(appActions.navigate(toRootedURL('/Billing')));
            }
          }
        })
        .catch(e => console.warn(e));
    }
  }, [
    appActions,
    closePanel,
    deletable,
    dispatch,
    id,
    isCover,
    response,
    useSidePanel,
  ]);
  const updateStatus = useCallback(
    (status: number) => {
      httpClient
        .post(url + '/UpdateStatus', {
          status,
          id,
        })
        .then(() => {
          dispatch(actions.getInvoice(id));
        })
        .catch(e => console.log(e));
    },
    [actions, dispatch, id],
  );

  const updateInvoiceTemplate = useCallback(
    (invoiceTemplateId: number) => {
      httpClient
        .post(url + '/UpdateInvoiceTemplate', {
          invoiceTemplateId,
          id,
        })
        .then(() => {
          dispatch(actions.getInvoice(id));
        })
        .catch(e => console.log(e));
    },
    [actions, dispatch, id],
  );

  const handlePreview = () => {
    if (!!invoiceIds) {
      const ids: string = invoiceIds.map(item => item.Id).join(',');
      var params = {
        ids: ids,
      };
      //BillingController->InvoiceExportPDFs - [Route("api/Billing/Invoices/InvoiceExportPDFs")]
      const url = buildURL('api/Billing/Invoices/InvoiceExportPDFs', params);
      openExportLink(url);
      setInvoiceIds([]);
    }
  };

  const topButtons: ActionRenderer[] = useMemo(
    () => [
      () => {
        if (!isChargesAllowed) return undefined;
        return (
          <TopActionButton
            icon="plus"
            title={t(translations.AddACharge) as string}
            text={t(translations.AddCharge) as string}
            startIcon="plus"
            variant="ghost"
            size="small"
            shape="square"
            onClick={() =>
              openNewCharge({
                invoiceId: id,
                budget:
                  invoice && invoice.BudgetId
                    ? { Id: invoice.BudgetId, Name: invoice.BudgetNumber }
                    : null,
                serviceGroup:
                  authenticatedUser?.Roles?.includes(
                    Roles.ServiceGroupBudgetsAdmin,
                  ) && authenticatedUser.AdminServiceGroups.length === 1
                    ? authenticatedUser.AdminServiceGroups[0]
                    : null,
                serviceGroupPredicate:
                  invoice &&
                  invoice.ServiceGroups &&
                  invoice.ServiceGroups.length > 0
                    ? [
                        new Condition(
                          'Id',
                          ODataOperators.Includes,
                          invoice.ServiceGroups,
                        ),
                      ]
                    : undefined,
              })
            }
          />
        );
      },
      () => {
        return (
          <TopActionButton
            title={t(translations.EditInvoiceStatus)}
            text={t(translations.EditInvoiceStatus)}
            icon="pencil"
            startIcon="pencil"
            size="small"
            onClick={() => setUpdateStatusOpen(true)}
            variant="ghost"
            shape="square"
          />
        );
      },
      () => {
        return (
          <TopActionButton
            title={t(translations.EditInvoiceTemplate)}
            text={t(translations.EditInvoiceTemplate)}
            icon="pencil"
            startIcon="pencil"
            size="small"
            onClick={() => setUpdateInvoiceTemplateOpen(true)}
            variant="ghost"
            shape="square"
          />
        );
      },
      () => {
        return (
          <TopActionButton
            text={t(translations.Delete)}
            title={
              deletable
                ? t(translations.Delete)
                : t(translations.CantDeleteInvoice)
            }
            onClick={() => setDeleteInvoiceOpen(true)}
            icon="trash"
            startIcon="trash"
            disabled={!deletable}
            size="small"
            variant="ghost"
            shape="square"
          />
        );
      },
      () => {
        return (
          <TopActionButton
            title={t(translations.InvoiceHistory)}
            text={t(translations.InvoiceHistory)}
            icon="history"
            startIcon="history"
            size="small"
            onClick={() => setInvoiceHistoryOpen(true)}
            variant="ghost"
            shape="square"
          />
        );
      },
      () => {
        return (
          <TopActionButton
            title={t(translations.InvoiceRowsHistory)}
            text={t(translations.InvoiceRowsHistory)}
            icon="rectangle-vertical-history"
            startIcon="rectangle-vertical-history"
            size="small"
            onClick={() => setRowHistoryOpen(true)}
            variant="ghost"
            shape="square"
          />
        );
      },
      () => {
        return (
          <TopActionButton
            title={t(translations.Preview)}
            text={t(translations.Preview)}
            icon="link"
            startIcon="save"
            size="small"
            onClick={() => {
              let invoiceIdEntity: Entity<number> = {
                Id: id,
                Name: id.toString(),
              };
              !!invoiceIdEntity
                ? setInvoiceIds([invoiceIdEntity])
                : setInvoiceIds([]);
              setUpdateInvoiceIdsOpen(true);
            }}
            variant="ghost"
            shape="square"
          />
        );
      },
    ],
    [
      authenticatedUser,
      isChargesAllowed,
      deletable,
      id,
      invoice,
      openNewCharge,
      t,
    ],
  );
  const paymentPageActions: PageActionRenderer[] = useMemo(
    () => [
      () => (
        <TopActionButton
          icon="plus"
          title={t(translations.AddPayment) as string}
          text={t(translations.AddPayment) as string}
          startIcon="plus"
          onClick={() =>
            openNewPayment({
              invoiceId: id,
              budget:
                invoice && invoice.BudgetId
                  ? { Id: invoice.BudgetId, Name: invoice.BudgetNumber }
                  : null,
              serviceGroup: null,
              serviceGroupPredicate:
                invoice &&
                invoice.ServiceGroups &&
                invoice.ServiceGroups.length > 0
                  ? [
                      new Condition(
                        'Id',
                        ODataOperators.Includes,
                        invoice.ServiceGroups,
                      ),
                    ]
                  : undefined,
            })
          }
        />
      ),
      () => (
        <ReactActions
          id="paymentTableActions"
          items={[
            <TopActionButton
              icon="history"
              title={t(translations.PaymentModificationsHistory) as string}
              text={t(translations.PaymentModificationsHistory) as string}
              startIcon="history"
              onClick={() => setPayHistoryOpen(true)}
              variant="ghost"
              shape="square"
              size="small"
            />,
          ]}
        />
      ),
    ],
    [id, invoice, openNewPayment, t],
  );
  if (notFound) {
    return <NotFoundPage />;
  }
  return (
    <PageWrapper
      pageLink={pagelink}
      useSidePanel={useSidePanel}
      loading={loading}
      pageName={
        <Link href="Invoices/Default.aspx">{t(translations.Invoices)}</Link>
      }
      titlePage={`${t(translations.Invoices)} #${id}`}
      closable
      disableExpandToggle
      rightTopActions={topButtons}
      cover={cover}
      closeCover={!isCover ? onCloseCover : closeCover}
      isCover={isCover}
      coverClosed={coverClosed}
      children={
        <InvoiceWrapper>
          <InvoiceInReview />
          <ReadOnlyDetails
            id={id}
            openChangeStatus={() => setUpdateStatusOpen(true)}
            openChangeInvoiceTemplate={() => setUpdateInvoiceTemplateOpen(true)}
          />
          <DetailsTable
            id={id}
            isSidePanel={useSidePanel}
            setFilters={setfilterCallback}
            OpenEditRow={rowId =>
              openNewCharge({
                invoiceId: id,
                rowId: rowId,
                budget: null,
                serviceGroup: null,
              })
            }
          />
          <PaymentTable
            pageActions={paymentPageActions}
            invoiceId={id}
            isSidePanel={useSidePanel}
            openEditPayment={PayId => {
              openNewPayment({
                invoiceId: id,
                paymentId: PayId,
                budget: null,
                serviceGroup: null,
              });
            }}
          />
          <InvoiceComments />
          <InvoiceRemarks />
          <Dialog open={invoiceHistoryOpen} maxWidth="md" disableEnforceFocus>
            <DialogContent>
              <InvoiceHistory invoiceId={id} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setInvoiceHistoryOpen(false)}>
                {t(translations.Done)}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={rowHistoryOpen} maxWidth="md" disableEnforceFocus>
            <DialogContent>
              <RowHistory invoiceId={id} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRowHistoryOpen(false)}>
                {t(translations.Done)}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={payHistoryOpen} maxWidth="md" disableEnforceFocus>
            <DialogContent>
              <PayHistory invoiceId={id} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPayHistoryOpen(false)}>
                {t(translations.Done)}
              </Button>
            </DialogActions>
          </Dialog>
          <DialogConfirm
            title={t(translations.EditInvoiceStatus)}
            body={
              <InvoiceStatusPicker
                variant="filled"
                fullWidth
                value={newStatus}
                onChange={v => setNewStatus(v)}
              />
            }
            confirmButtonLabel={t(translations.UpdateInvoiceStatus)}
            onConfirm={() => {
              updateStatus(newStatus?.Id ?? -1);
              setUpdateStatusOpen(false);
            }}
            disabled={!newStatus}
            onCancel={() => setUpdateStatusOpen(false)}
            isOpen={updateStatusOpen}
          />
          <DialogConfirm
            title={t(translations.EditInvoiceTemplate)}
            body={
              <InvoiceTemplatePicker
                variant="filled"
                fullWidth
                value={invoiceTemplate}
                onChange={v => setInvoiceTemplate(v)}
              />
            }
            confirmButtonLabel={t(translations.UpdateInvoiceTemplate)}
            onConfirm={() => {
              updateInvoiceTemplate(invoiceTemplate?.Id ?? -1);
              setUpdateInvoiceTemplateOpen(false);
            }}
            disabled={!invoiceTemplate}
            onCancel={() => setUpdateInvoiceTemplateOpen(false)}
            isOpen={updateInvoiceTemplateOpen}
          />
          <DialogConfirm
            title={t(translations.Invoice)}
            body={
              <InvoiceIdMultiPicker
                variant="filled"
                fullWidth
                placeholder={t(translations.AllValues)}
                value={invoiceIds}
                onChange={v => setInvoiceIds(v)}
              />
            }
            confirmButtonLabel={t(translations.Preview)}
            onConfirm={() => {
              handlePreview();
              setUpdateInvoiceIdsOpen(false);
            }}
            onClose={() => setInvoiceIds([])}
            disabled={
              invoiceIds === null ||
              invoiceIds === undefined ||
              invoiceIds.length === 0
            }
            onCancel={() => {
              setUpdateInvoiceIdsOpen(false);
              setInvoiceIds([]);
            }}
            isOpen={updateInvoiceIdsOpen}
          />
          <DialogConfirm
            isOpen={deleteInvoiceOpen}
            body={t(translations.InvoiceDeleteConfirmationMessage)}
            onConfirm={deleteInvoice}
            onCancel={() => setDeleteInvoiceOpen(false)}
            disabled={!deletable}
          />
        </InvoiceWrapper>
      }
    />
  );
};
const InvoiceWrapper = styled.div`
  padding: 16px;
  width: 100%;
`;
export const InvoiceDetailsUrl = url;
