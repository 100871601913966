/**
 *
 * Layout
 *
 */
import React from 'react';
import clsx from 'clsx';
import { Copyright } from 'app/components/Copyright';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanel } from './components/SidePanel';
import {
  selectConfirmOpen,
  selectExpandedSidePanel,
  selectHideNavigation,
  selectSidePanelOpen,
} from './slice/selectors';
import { TopBar } from './components/TopBar';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { useLayoutSlice } from './slice';
import { translations } from 'locales/translations';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { useAppSettingsSlice } from 'app/slice';
import { useRequiredRedirect } from 'app/hooks/useRequiredRedirectToCoreSelection';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { SideBarDrawer } from './SideBarDrawer';
import { LayoutRoot, Main } from './styled';
import { useTheme } from '@material-ui/core';
import { DRAWER_MOBILE_WIDTH, DRAWER_WIDTH } from 'styles/constantVariables';
import {
  selectAppSettings,
  selectGlobalServiceGroupFilter,
  selectAuthenticatedUser,
  selectglobalSettings,
  selectIsExpandedSideBar,
  selectIsSubBarCollapsed,
  selectShowGlobalServiceGroupFilter,
} from 'app/slice/selectors';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { AdminPanel } from './components/AdminPanel';
import { AllowedSettings } from 'utils/globalSettings';

interface Props {
  children: React.ReactNode;
}

export const FrontendLayout_MainContent_ID = 'main_div_content';
export function FrontendLayout({ children }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { actions: appActions } = useAppSettingsSlice();
  const { checkIsMobile, isLandscape } = useIsMobile();
  const selectedGroups = useSelector(selectGlobalServiceGroupFilter);
  const gloabalSettings = useSelector(selectglobalSettings);
  const isMobile = checkIsMobile();
  const expanded = useSelector(state =>
    selectIsExpandedSideBar(state, isMobile),
  );
  const { expand } = useExpandedSideBarState();

  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const appSettings = useSelector(selectAppSettings);
  const isBudgets = IsModuleEnabled(appSettings, KnownModules.Budgets);
  const collapsedBar = useSelector(selectIsSubBarCollapsed);
  const showGroups = useSelector(selectShowGlobalServiceGroupFilter);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const confirmOpen = useSelector(selectConfirmOpen);
  const hideNavigation = useSelector(selectHideNavigation);
  const toCoreEnabled = !gloabalSettings.GetBooleanByKey(
    AllowedSettings.HideTopServiceGroupFilter,
  );
  const {
    redirectToCoreSelection,
    redirectToTermsOfUseAgreement,
    redirectToActiveUserGroup,
    redirectToUsageKiosk,
    redirectToUsageRoom,
    redirectToConsumableKiosk,
    redirectToJoinAdGroup,
  } = useRequiredRedirect();
  const drawerWidth = React.useMemo(() => {
    return isMobile ? DRAWER_MOBILE_WIDTH : DRAWER_WIDTH;
  }, [isMobile]);
  const marginRight = React.useMemo(() => {
    return sidePanelOpen && !sidePanelExpanded && !isMobile;
  }, [isMobile, sidePanelExpanded, sidePanelOpen]);
  React.useEffect(() => {
    let isRedirectToTermsOfUseAgreement =
      redirectToTermsOfUseAgreement === true;
    let isRedirectToActiveUserGroup = redirectToActiveUserGroup === true;
    let isRedirectToCoreSelection = redirectToCoreSelection === true;
    let isRedirectToUsageKiosk = redirectToUsageKiosk === true;
    let isRedirectToUsageRoom = redirectToUsageRoom === true;
    let isRedirectToConsumableKiosk = redirectToConsumableKiosk === true;
    let isRedirectToJoinAdGroup = redirectToJoinAdGroup === true;
    if (isRedirectToTermsOfUseAgreement === true) {
      dispatch(appActions.navigate('/TermsOfUseAgreement/exp'));
      return;
    } else {
      if (isRedirectToActiveUserGroup === true) {
        dispatch(appActions.navigate('/ActiveUserGroup'));
        return;
      }
    }
    if (isRedirectToUsageKiosk) {
      window.open(toRootedURL('/UsageKiosk.aspx'));
      return;
    }
    if (isRedirectToUsageRoom) {
      window.open(toRootedURL('/InstrumentRoom.aspx'));
      return;
    }
    if (isRedirectToConsumableKiosk) {
      dispatch(
        appActions.navigate(
          isBudgets ? '/kiosk/projects' : '/kiosk/projects/consumables',
        ),
      );
      return;
    }
    if (isRedirectToJoinAdGroup) {
      let url: string = '/join';
      if (!!authenticatedUser?.ActiveUserGroup?.Id) {
        url = `${url}?${toQueryString({
          un: authenticatedUser?.Id,
          adg: authenticatedUser?.ActiveUserGroup?.Id,
          action: 'join',
        })}`;
      }
      dispatch(appActions.navigate(url));
      return;
    }
    if (isRedirectToCoreSelection) {
      dispatch(appActions.navigate('/coreselection'));
      dispatch(
        appActions.addNotification({
          key: 'coreSelection_error',
          message: 'Please select core/s',
          variant: 'error',
        }),
      );
    } else if (
      toCoreEnabled &&
      (selectedGroups === undefined || selectedGroups.length < 1)
    ) {
      dispatch(appActions.saveAllGlobalServiceGroupFilter());
    }
    return () => {
      isRedirectToCoreSelection = false;
      isRedirectToTermsOfUseAgreement = false;
      isRedirectToActiveUserGroup = false;
      isRedirectToUsageKiosk = false;
      isRedirectToUsageRoom = false;
      isRedirectToConsumableKiosk = false;
      isRedirectToJoinAdGroup = false;
    };
  }, [
    actions,
    appActions,
    dispatch,
    redirectToCoreSelection,
    redirectToTermsOfUseAgreement,
    redirectToActiveUserGroup,
    redirectToUsageKiosk,
    redirectToUsageRoom,
    redirectToConsumableKiosk,
    isBudgets,
    toCoreEnabled,
    selectedGroups,
    redirectToJoinAdGroup,
    authenticatedUser?.ActiveUserGroup,
    authenticatedUser?.Id,
  ]);

  const handleDrawerClose = React.useCallback(
    (event, reason) => {
      expand(!expanded);
    },
    [expand, expanded],
  );

  React.useEffect(() => {
    dispatch(actions.setIsMobileView(isMobile));
  }, [actions, dispatch, isMobile]);
  const handleRejectConfirm = React.useCallback(() => {
    dispatch(actions.setConfirmRejected(true));
  }, [actions, dispatch]);
  const handleApproveConfirm = React.useCallback(() => {
    dispatch(actions.setConfirmApproved(true));
  }, [actions, dispatch]);
  const theme = useTheme();
  return (
    <LayoutRoot
      theme={theme}
      className={clsx('layoutroot', { layoutRootMobile: isMobile })}
    >
      {!isMobile && !hideNavigation && (
        <TopBar
          drawerWidth={drawerWidth}
          sidePanelCollapsed={sidePanelOpen && !sidePanelExpanded}
          isOpen={expanded}
        />
      )}

      <SideBarDrawer
        hideNavigation={hideNavigation}
        isMobile={isMobile}
        isLandscape={isLandscape}
        handleDrawerClose={handleDrawerClose}
      />
      {/* id is required on main in order for infinite scroll to work according to main's scrollbar*/}
      <Main
        theme={theme}
        id="FrontendLayout_mainContent"
        className={clsx('content', marginRight ? 'shiftLeft' : '')}
      >
        {!isMobile && (
          <div
            className={
              hideNavigation
                ? undefined
                : clsx('appBarSpacer', {
                    subBarExpanded: !collapsedBar && showGroups,
                  })
            }
          />
        )}
        <div
          id={FrontendLayout_MainContent_ID}
          className={clsx('container', {
            useselectable: isMobile,
            mobileOpen: expanded && isMobile,
          })}
        >
          <React.Fragment>{children}</React.Fragment>
          {!isMobile && (
            <footer className={'footer'}>
              <Copyright />
            </footer>
          )}
        </div>
      </Main>

      <SidePanel />
      <AdminPanel />
      <DialogConfirm
        isOpen={confirmOpen}
        body={t(translations.NotSavedChanges_message) as string}
        title={t(translations.NotSavedChanges_title) as string}
        confirmButtonLabel={t(translations.Yes)}
        cancelButtonLabel={t(translations.No)}
        onConfirm={() => {
          handleApproveConfirm();
        }}
        onCancel={() => {
          handleRejectConfirm();
        }}
        id="confirm_sidepanel"
        aria-labelledby="side-panel-confirm"
      />
    </LayoutRoot>
  );
}
