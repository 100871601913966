import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Entity } from 'types/common';
import { translations } from 'locales/translations';
import { FormUsersPicker } from 'app/components/Forms/FormUsersPicker';
import { FormCheckbox } from 'app/components/Forms/FormCheckbox';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import { UserTrainingApi as api } from 'api/UserTrainingApi';
import { FormTrainingReservationsPicker } from 'app/components/Forms/FormTrainingReservationsPicker';
import { FormTrainingLevelPicker } from 'app/components/Forms/FormTrainingLevelPicker';
import { ITrainingLevelFilterDto } from 'api/odata/generated/entities/ITrainingLevelFilterDto';
import { AllowedSettings } from 'utils/globalSettings';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { useSelector } from 'react-redux';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { TrainingDetailsState } from '../slice/types';
import {
  FormRightSection,
  FormLeftSection,
  StyledForm,
  FormFieldsSection,
  FormRow,
} from 'app/components/Forms/FormsLayout';
import { UserTrainingComments } from './UserTrainingComments';
import { FormAdminSingleUsersPicker } from 'app/components/Forms/FormAdminUserPicker';
import { dateUtils } from 'utils/date-utils';
import { FormOnlineServicesComboPicker } from 'app/components/Forms/FormEquipmentsPicker';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { RestrictedByAdminFilter } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { TrainingLevelFilter } from 'app/components/pickers/AutocompletePickers/TrainingLevelPicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { AdminServicesFilter } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export interface UserTrainingFormProps {
  onSubmit: (item: TrainingDetailsState) => void;
  onCancel?: () => void;
  initialValues: TrainingDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  onEquipmentClicked?: (equipment: Entity<number>) => void;
  commentsFormRef: React.MutableRefObject<any>;
  isAdmin?: boolean;
}

export const UserTrainingForm = React.memo(function UserTrainingForm({
  onSubmit,
  processing,
  initialValues,
  onCancel,
  bindSubmitForm,
  isEdit,
  onEquipmentClicked,
  commentsFormRef,
  isAdmin,
}: UserTrainingFormProps) {
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const trainingLevelPermissionsEnabled = React.useMemo(() => {
    return IsModuleEnabled(appSettings, KnownModules.TrainingLevelPermissions);
  }, [appSettings]);
  const userPermissionsEnabled = React.useMemo(() => {
    return (
      IsModuleEnabled(appSettings, KnownModules.UserPermission) ||
      IsModuleEnabled(appSettings, KnownModules.GroupPermissions)
    );
  }, [appSettings]);
  const usersShema: Yup.SchemaOf<Entity<string>> = Yup.object({
    Id: Yup.string().ensure(),
    Name: Yup.string().ensure(),
  });
  const equipmentShema: Yup.SchemaOf<Entity<number>> = Yup.object({
    Id: Yup.number().defined(),
    Name: Yup.string().ensure(),
  });
  const schema: Yup.SchemaOf<TrainingDetailsState> = Yup.object({
    Id: Yup.number().notRequired().nullable(),
    Users: Yup.array()
      .of(usersShema)
      .required(t(translations.err_UserNameRequired))
      .label(t(translations.User))
      .min(1),
    Equipments: Yup.array()
      .of(equipmentShema)
      .min(1)
      .required(t(translations.err_OnlineServiceFieldRequired))
      .label(t(translations.ServiceType_Online)),
    UnhideServiceGroup: Yup.boolean(),
    TrainingDate: Yup.date()
      .required(t(translations.err_TrainingDateFieldRequired))
      .label(t(translations.TrainingDate)),
    ExpiredDate: Yup.date().nullable(true),
    Trainer: Yup.mixed()
      .required(t(translations.err_TrainerIsRequired))
      .label(t(translations.Trainer)),
    MustOrderEveryDays: Yup.number()
      .integer()
      .nullable()
      .notRequired()
      .label(t(translations.MustOrderEveryDays))
      .min(0)
      .max(365),
    MustUseEveryDays: Yup.number()
      .integer()
      .nullable()
      .notRequired()
      .label(t(translations.MustUseEveryDays))
      .min(0)
      .max(365),
    RequiresAdminApproval: Yup.boolean(),
    AllowBookingFromTrainingDate: Yup.boolean().default(true),
    Reservations: Yup.mixed(),
    TrainingLevel: Yup.mixed(),
    Remarks: Yup.string().nullable(),
    Active: Yup.boolean(),
    FatherId: Yup.number().notRequired().nullable(),
    ServiceGroupId: Yup.number().notRequired().nullable(),
    TrainingProgram: Yup.mixed(),
  });
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };
  const activeDisabled =
    isEdit &&
    settings.GetBooleanByKey(
      AllowedSettings.TrainingActivationAllowedBySGAOnly,
    );
  const [resDisbaled, setResDisbaled] = React.useState<boolean>(false);
  const [allowBookingDisbaled, setAllowBookingDisbaled] = React.useState<
    boolean | undefined
  >(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allowBookingVisible, setAllowBookingVisible] = React.useState<
    boolean | undefined
  >(true);
  const [equipmentChanged, setEquipmentChanged] =
    React.useState<boolean>(false);
  const [userChanged, setUserChanged] = React.useState<boolean>(false);
  const [trDateChanged, setTrDateChanged] = React.useState<boolean>(false);
  const [trLevelChanged, setTrLevelChanged] = React.useState<boolean>(false);
  const handleEquipmentChange = value => {
    setEquipmentChanged(true);
  };
  const handleUserChange = value => {
    setUserChanged(true);
  };
  const handleTrDateChange = value => {
    setTrDateChanged(true);
  };
  const handleTrLevelChange = value => {
    setTrLevelChanged(true);
  };
  const { newDate } = useSystemDate();
  const handleFormChange = React.useCallback(
    (values, isValid, dirty, setValue) => {
      setResDisbaled(
        values.Equipments.length !== 1 ||
          values.Users.length !== 1 ||
          !values.TrainingDate,
      );
      if (equipmentChanged || userChanged || trDateChanged) {
        setValue('Reservations', undefined, false);
        if (values.Equipments.length > 0 && values.Users.length > 0) {
          const params: {
            services: string;
            users: string;
          } = {
            services: values.Equipments.map(item => item.Id).join(','),
            users: values.Users.map(item => item.Id).join(','),
          };
          api.hasUntrainedNotAllowed(params).then(data => {
            let value = data.value;
            if (value === 0) {
              setAllowBookingVisible(false);
              setValue('AllowBookingFromTrainingDate', true, false);
            } else {
              if (isEdit) {
                setAllowBookingDisbaled(true);
              } else {
                if (value === 1) {
                  setAllowBookingDisbaled(false);
                } else {
                  setAllowBookingDisbaled(true);
                  setValue('AllowBookingFromTrainingDate', true, false);
                }
              }
            }
          });
        }
        if (equipmentChanged) {
          setValue('TrainingLevel', null);
          setValue('Trainer', null);
        }
        setUserChanged(false);
        setEquipmentChanged(false);
        setTrDateChanged(false);
      }
      if (trLevelChanged) {
        let level = values.TrainingLevel as ITrainingLevelFilterDto;
        if (level) {
          if (level.MustOrderEveryDays !== undefined) {
            if (
              values.MustOrderEveryDays === undefined ||
              values.MustOrderEveryDays === null
            ) {
              setValue('MustOrderEveryDays', level.MustOrderEveryDays, false);
            }
          }
          if (level.MustUseEveryDays !== undefined) {
            if (
              values.MustUseEveryDays === undefined ||
              values.MustUseEveryDays === null
            ) {
              setValue('MustUseEveryDays', level.MustUseEveryDays, false);
            }
          }
          if (
            level.ExpiredDays !== undefined &&
            level.ExpiredDays != null &&
            level.ExpiredDays > 0
          ) {
            let date = newDate();
            let trainingDate = dateUtils.parseISO(values.TrainingDate);
            date.setDate(trainingDate.getDate() + level.ExpiredDays);
            setValue('ExpiredDate', date, false);
          }
        }
        setTrLevelChanged(false);
      }
    },
    [
      equipmentChanged,
      isEdit,
      newDate,
      trDateChanged,
      trLevelChanged,
      userChanged,
    ],
  );
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={[
                    'Equipments',
                    'Users',
                    'TrainingDate',
                    'TrainingLevel',
                  ]}
                />

                <FormLeftSection
                  pageDescription={
                    (
                      (t(translations.UserTrainingTopMessage) as string) || ''
                    ).trim() !== ''
                      ? (t(translations.UserTrainingTopMessage) as string)
                      : undefined
                  }
                  warning={
                    isEdit && !formik.values.Active
                      ? (t(translations.NotActive) as string)
                      : undefined
                  }
                >
                  <FormFieldsSection>
                    <FormRow fullRow={true} hide={!sidePanelExpanded}>
                      <FormCheckbox
                        name="Active"
                        label={t(translations.Active)}
                        disabled={formik.isSubmitting || activeDisabled}
                        id="trainingActive"
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormOnlineServicesComboPicker
                        onChipClick={onEquipmentClicked}
                        name="Equipments"
                        info={t(translations.UserTraining_EquipmentInfo)}
                        label={t(translations.ServiceType_Online)}
                        placeholder={t(translations.PleaseSelectServices)}
                        disabled={formik.isSubmitting}
                        multiple={true}
                        urlType="admin"
                        predicates={RestrictedByAdminFilter(
                          formik.values.Trainer as IUserFilterDto,
                        )}
                        fullWidth
                        onChange={handleEquipmentChange}
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormUsersPicker
                        name="Users"
                        info={t(translations.UserTraining_UserInfo)}
                        label={t(translations.User)}
                        placeholder={t(translations.UsersPlaceholder)}
                        disabled={formik.isSubmitting}
                        fullWidth
                        onChange={handleUserChange}
                      />
                    </FormRow>
                    <FormRow>
                      <FormBookitDatePicker
                        id="TrainingDateId"
                        name="TrainingDate"
                        label={t(translations.TrainingDate)}
                        placeholder={t(translations.DateOfTraining)}
                        disabled={formik.isSubmitting}
                        fullWidth
                        onChange={handleTrDateChange}
                      />
                    </FormRow>
                    <FormRow>
                      <FormTrainingLevelPicker
                        name="TrainingLevel"
                        label={t(translations.LevelAchieved)}
                        placeholder={t(translations.TrainingLevelPlaceholder)}
                        disabled={
                          formik.isSubmitting ||
                          formik.values.Equipments.length < 1
                        }
                        onChange={handleTrLevelChange}
                        predicates={TrainingLevelFilter(
                          formik.values.Equipments as IServiceFilterDto[],
                          trainingLevelPermissionsEnabled,
                          userPermissionsEnabled,
                        )}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormAdminSingleUsersPicker
                        name="Trainer"
                        label={t(translations.Trainer)}
                        placeholder={t(translations.TrainerPlaceholder)}
                        disabled={formik.isSubmitting}
                        predicates={AdminServicesFilter(
                          formik.values.Equipments as IServiceFilterDto[],
                        )}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow hide={!sidePanelExpanded}>
                      <FormTrainingReservationsPicker
                        name="Reservations"
                        label={t(translations.LinkedReservation)}
                        placeholder={t(translations.SelectLinkedReservation)}
                        disabled={formik.isSubmitting || resDisbaled}
                        equipment={
                          formik.values.Equipments.length === 1
                            ? formik.values.Equipments[0]
                            : undefined
                        }
                        user={
                          formik.values.Users.length === 1
                            ? formik.values.Users[0]
                            : undefined
                        }
                        trainingDate={dateUtils.parseISO(
                          dateUtils.dateOrStringToDate(
                            formik.values.TrainingDate || newDate(),
                          ),
                        )}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                  <FormFieldsSection
                    titleSection={t(translations.TrainingRestrictions)}
                  >
                    {IsModuleEnabled(
                      appSettings,
                      KnownModules.TrainingExpiration,
                    ) && (
                      <React.Fragment>
                        <FormRow>
                          <FormBookitDatePicker
                            id="ExpiredDateId"
                            info={t(translations.User_ExpiredDate_Description)}
                            name="ExpiredDate"
                            disablePast={true}
                            label={t(translations.ExpiredDate)}
                            placeholder={t(translations.ExpiredDate)}
                            disabled={formik.isSubmitting}
                            clearable={true}
                            fullWidth
                          />
                        </FormRow>
                        <FormRow>
                          <FormNumberField
                            id="MustOrderEveryDaysId"
                            autoComplete="off"
                            name="MustOrderEveryDays"
                            info={t(
                              translations.User_MustOrderEveryDays_Description,
                            )}
                            label={t(translations.MustOrderEveryDays)}
                            placeholder={t(translations.EnterNumberOfDays)}
                            disabled={formik.isSubmitting}
                            min={0}
                            max={365}
                            fullWidth
                          />
                        </FormRow>
                        <FormRow>
                          <FormNumberField
                            id="MustUseEveryDaysId"
                            autoComplete="off"
                            info={t(
                              translations.User_MustUseEveryDays_Description,
                            )}
                            name="MustUseEveryDays"
                            label={t(translations.MustUseEveryDays)}
                            placeholder={t(translations.EnterNumberOfDays)}
                            disabled={formik.isSubmitting}
                            min={0}
                            max={365}
                            fullWidth
                          />
                        </FormRow>
                      </React.Fragment>
                    )}
                    <FormRow>
                      <FormCheckbox
                        name="RequiresAdminApproval"
                        label={t(translations.RequiresAdminApproval)}
                        disabled={formik.isSubmitting}
                        id="reqAdminApprovalId"
                      />
                    </FormRow>
                    {IsModuleEnabled(
                      appSettings,
                      KnownModules.HideInstrumentsFromUntrainedUsers,
                    ) && (
                      <FormRow>
                        <FormCheckbox
                          info={t(translations.UnhideServiceGroup_Description)}
                          name="UnhideServiceGroup"
                          label={t(translations.UnhideServiceGroup)}
                          disabled={formik.isSubmitting}
                          id="unhideCoreId"
                        />
                      </FormRow>
                    )}
                    {/* //!allowBookingVisible */}
                    <FormRow fullRow={true} hide={true}>
                      <FormCheckbox
                        name="AllowBookingFromTrainingDate"
                        label={t(translations.AllowBookingFromNow)}
                        disabled={formik.isSubmitting || allowBookingDisbaled}
                        id="bookFromTrainingDateId"
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
                <FormRightSection>
                  <FormFieldsSection titleSection={t(translations.Remarks)}>
                    <FormRow fullRow={true}>
                      <FormRichTextField
                        name="Remarks"
                        placeholder={t(translations.Remarks)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                  {isEdit && (
                    <FormFieldsSection>
                      <UserTrainingComments
                        commentsFormRef={commentsFormRef}
                        training={formik.values}
                        isAdmin={isAdmin}
                        disabled={formik.isSubmitting}
                      />
                    </FormFieldsSection>
                  )}
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
