import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectEventsCount,
  selectEventsCountLoading,
} from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { bookitColors } from 'styles/colors/bookitColors';
import { dateUtils } from 'utils/date-utils';
import { Roles } from 'app/slice/types';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useAppSettingsSlice } from 'app/slice';
import styled from 'styled-components';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
export interface EventsCountProps {}

const EVENTS_COUNT_ROLES = [
  Roles.Administrators,
  Roles.GroupAdministrators,
  Roles.LabTech,
  Roles.InstrumentEventsAdmin,
];

interface AllowRolesProps {
  /**
   * Array of allowed roles. Must be const/memoed
   */
  roles: Array<Roles>;
}
/**
 * Renders content only for specific role members.
 * @param props
 * @returns
 */
function AllowRoles(props: React.PropsWithChildren<AllowRolesProps>) {
  const user = useSelector(selectAuthenticatedUser);
  const isAdmin = React.useMemo(() => {
    return (
      user !== undefined && props.roles.some(role => user.Roles.includes(role))
    );
  }, [props.roles, user]);
  if (isAdmin) {
    return <>{props.children}</>;
  } else {
    return <React.Fragment />;
  }
}

interface ModuleContainerProps {
  module: KnownModules;
}
/**
 * Renders content only if specific module is enabled.
 * @param props
 * @returns
 */
function ModuleContainer(props: React.PropsWithChildren<ModuleContainerProps>) {
  const appSettings = useSelector(selectAppSettings);
  const isMaintenanceAssetsEnabled = IsModuleEnabled(appSettings, props.module);
  if (isMaintenanceAssetsEnabled) {
    return <>{props.children}</>;
  } else {
    return <React.Fragment />;
  }
}

function EventsCountInner(props: EventsCountProps) {
  const { actions } = useAppSettingsSlice();
  const count = useSelector(selectEventsCount);
  const { t } = useTranslation();
  const { newDate } = useSystemDate();

  const eventsLoading = useSelector(selectEventsCountLoading);
  useEffectOnMount(() => {
    actions.loadEventsCount();
  });

  const StyledSpanIcon = styled('span')(({ theme }) => ({
    fontSize: '20px',
    color: bookitColors.grayscale.graySubtitles,
    width: '20px',
    height: '20px',
    '& .fa-layers-counter': {
      whiteSpace: 'nowrap',
      fontSize: '26px',
      right: '-4px',
      top: '-4px',
    },
  }));

  //RENDER
  return !eventsLoading ? (
    <IconButton
      aria-label="search"
      to={`/workorders?openedOnly=false&period=0&et=${dateUtils.formatQueryStringDate(
        newDate(),
      )}`}
      title={t(translations.Alerts)}
    >
      <Tooltip title={t(translations.Alerts) as string}>
        <StyledSpanIcon className="fa-layers fa-fw">
          <Icon icon={['fas', 'tools']} />
          <span className="fa-layers-counter">{count}</span>
        </StyledSpanIcon>
      </Tooltip>
    </IconButton>
  ) : null;
}

export const EventsCount = function (props: EventsCountProps) {
  return (
    <ModuleContainer module={KnownModules.MaintenanceAssets}>
      <AllowRoles roles={EVENTS_COUNT_ROLES}>
        <EventsCountInner />
      </AllowRoles>
    </ModuleContainer>
  );
};
