import { FormControl, FormLabel, styled } from '@material-ui/core';
import * as React from 'react';
import { FieldHookConfig } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { buttonM } from 'styles/typography/bookitTypography';
import { InfoIcon } from '../BasicIcons/InfoIcon';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.common.blackRegular,
  ...buttonM,
  '&[class*="Mui-focused"]': {
    color: theme.palette.common.blackRegular,
  },
}));
export const FormParagraph = ({
  label,
  html,
  ...props
}: FieldHookConfig<string> & IFieldProps) => {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <StyledFormLabel>
        {label}
        {props.info && <InfoIcon title={props.info} />}
      </StyledFormLabel>
      <span>{html}</span>
    </FormControl>
  );
};
