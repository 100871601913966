import { Box } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
  selectPublicUrl,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings } from 'utils/globalSettings';
import { buildURL, toQueryString, toRootedURL } from 'utils/url-utils';
import { PricePeriodsForm } from './PricePeriodsForm';
import { usePricePeriodSlice } from './slice';
import {
  selectDetails,
  selectDetailsCompleted,
  selectDetailsHasError,
  selectProcessing,
} from './slice/selectors';
import {
  IPriceSheetPeriodDto,
  PricePeriodQueryParams,
  PricePeriodsDetailsState,
} from './slice/types';
import { Beforeunload } from 'react-beforeunload';
import { useAppSettingsSlice } from 'app/slice';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';

export interface PricePeriodDetailsProps
  extends CoverProps,
    SidePanelContentProps {
  queryParams: PricePeriodQueryParams;
  initialPeriod?: IPriceSheetPeriodDto;
  onClose?: () => void;
}

export const PricePeriodDetails = React.memo(function PricePeriodDetails(
  props: PricePeriodDetailsProps,
) {
  const { useSidePanel, queryParams, initialPeriod, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = usePricePeriodSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();

  /// Selectors ///
  const processing = useSelector(selectProcessing);
  const pricePeriod = useSelector(selectDetails);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const continueLink = useSelector(selectContinueToLink);
  const confirmApproved = useSelector(selectConfirmApproved);
  const confirmRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const publicUrl = useSelector(selectPublicUrl);
  const actionCompleted = useSelector(selectDetailsCompleted);
  const settings = useSelector(selectglobalSettings);
  const hasError = useSelector(selectDetailsHasError);
  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<PricePeriodsDetailsState>>(
    null,
  );
  //cover
  const [cover, setCover] = React.useState<React.ReactNode>();
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [edit, setEdit] = React.useState<boolean | undefined>(false);

  const globalPriceSheetEnabled = settings?.GetBooleanByKey(
    AllowedSettings.GlobalPriceSheetPeriods,
  );
  const priceSheetByEnabled = React.useMemo(() => {
    const priceSheetBy =
      settings !== null && settings !== undefined
        ? settings?.GetString(AllowedSettings.PriceSheetBy)
        : '';

    return priceSheetBy !== '' && priceSheetBy !== '3';
  }, [settings]);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const fullAdmin = React.useMemo(() => {
    return User !== undefined && User.Roles.includes(Roles.Administrators);
  }, [User]);
  const IsAdmin = React.useMemo(() => {
    return (
      fullAdmin ||
      (!globalPriceSheetEnabled &&
        User?.HasAdminGroupPermissions(pricePeriod?.data?.ServiceGroup?.Id))
    );
  }, [
    fullAdmin,
    globalPriceSheetEnabled,
    User,
    pricePeriod?.data?.ServiceGroup?.Id,
  ]);

  React.useEffect(() => {
    let active = firstLoad;
    if (active) {
      setFirstLoad(false);
      dispatch(
        actions.initDetails({
          query: queryParams,
          model: initialPeriod,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, initialPeriod, queryParams]);
  /// functions, callbacks ///
  const closeCover = () => {
    setCover(undefined);
  };
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCloselClick();
    }
  };
  const handleCloselClick = React.useCallback(() => {
    if (!!onClose) {
      onClose();
    } else {
      if (useSidePanel) {
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(push('/PricePeriods'));
      }
    }
    dispatch(actions.resetDetailsState());
  }, [actions, dispatch, layoutActions, onClose, useSidePanel]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: PricePeriodsDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updatePeriod({
            current: values,
            original: pricePeriod.data || values,
          }),
        );
      } else {
        dispatch(actions.createPeriod(values));
      }
    },
    [actions, dispatch, edit, pricePeriod.data],
  );
  /// Use Effects ///
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        setEdit(true);
      } else {
        setEdit(false);
      }
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    handleCloselClick,
    hasError,
    processing,
    queryParams.id,
  ]);
  React.useEffect(() => {
    if (confirmApproved) {
      dispatch(actions.resetDetailsState());
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
      //dispatch(actions.setChangedState(false));
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);

  const leftActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={busy}
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    return actions;
  }, [busy, handleSaveClick, requestCompleted, t]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCloselClick}
          >
            {t(translations.Close)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);
  const topButtons: ActionRenderer[] = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    let data =
      pricePeriod.data ||
      innerFormRef.current?.values ||
      ({} as PricePeriodsDetailsState);
    if (edit && IsAdmin && data !== undefined) {
      actions.push(() => (
        <TopActionButton
          text={t(translations.PriceSheets)}
          icon="money-check-edit"
          startIcon="money-check-edit"
          size="small"
          title={t(translations.OpenPriceSheetInfo)}
          onClick={() =>
            window.open(
              toRootedURL(
                `/Admin/PriceSheet.aspx?${toQueryString({
                  periodId: queryParams.id,
                  start: dateUtils.formatQueryStringDate(
                    dateUtils.dateOrStringToDate(data.StartDate),
                  ),
                  end:
                    data.EndDate === null
                      ? undefined
                      : dateUtils.formatQueryStringDate(
                          dateUtils.dateOrStringToDate(data.EndDate),
                        ),
                  groupId: globalPriceSheetEnabled
                    ? undefined
                    : data.ServiceGroup?.Id ?? undefined,
                  gName: globalPriceSheetEnabled
                    ? undefined
                    : data.ServiceGroup?.Name ?? undefined,
                  priceBy: data.Method?.Id ?? undefined,
                }).toString()}`,
              ),
              '_blank',
            )
          }
          variant="ghost"
          shape="square"
        />
      ));
    }
    return actions;
  }, [
    IsAdmin,
    edit,
    globalPriceSheetEnabled,
    pricePeriod.data,
    queryParams.id,
    t,
  ]);
  return (
    <React.Fragment>
      <PageWrapper
        pageName={
          edit
            ? t(translations.EditPriceSheetPeriod)
            : t(translations.AddPriceSheetPeriod)
        }
        titlePage={
          pricePeriod?.data
            ? edit
              ? ` ${t(translations.PricePeriod)} #${queryParams.id || 'N/A'}`
              : ` ${t(translations.AddPricePeriod)}`
            : undefined
        }
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={props.isCover}
        closeSidePanel={props.isCover ? onClose : handleClose}
        leftTopActions={topButtons}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={leftActions}
        children={
          pricePeriod?.data !== undefined && !processing ? (
            <PricePeriodsForm
              initialValues={
                pricePeriod?.data || ({} as PricePeriodsDetailsState)
              }
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isEdit={edit || false}
              isAdmin={IsAdmin || false}
              user={User}
              globalPriceSheetEnabled={globalPriceSheetEnabled}
              priceEnabled={priceSheetByEnabled}
              innerFormRef={innerFormRef}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={processing} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'pricePeriods/details', queryParams)}
        toPageLink={`pricePeriods/details?${toQueryString(queryParams)}`}
        rightActions={rightActions}
        isCover={props.isCover}
        cover={cover}
        closeCover={props.closeCover || closeCover}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
