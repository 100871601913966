import * as React from 'react';
import styled from 'styled-components/macro';
import { P } from './P';
import { Helmet } from 'react-helmet-async';
import { toRootedURL } from 'utils/url-utils';
import { Button } from '../../components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Wrapper id="not_found_page">
        <DivStyle>
          <svg
            width="270"
            height="276"
            viewBox="0 0 270 276"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M150.747 77.6918L147.08 58.562L143.959 41.7158V41.6921C143.932 41.7565 143.908 41.8242 143.881 41.8852C144.263 43.8639 150.476 76.1061 150.612 77.7426L150.747 77.6918Z"
                fill="#F8F8F8"
              />
              <path
                d="M144.91 37.7242V37.7008C144.912 37.7197 144.912 37.7388 144.91 37.7577V37.7242Z"
                fill="#F8F8F8"
              />
              <path
                d="M238.303 92.3591C231.64 80.9273 226.026 70.9456 220.639 62.2041C199.272 59.7815 186.938 50.1082 176.232 50.1082C174.986 50.1082 173.049 50.6164 172.314 50.6164L170.689 53.6658L170.737 57.8638L173.252 58.3381L176.638 60.144L179.039 62.3836L181.977 66.7612L181.778 66.8933C183.223 67.4524 184.361 68.313 185.001 69.5633C185.837 71.1896 185.766 73.1006 185.099 75.0827C190.276 94.4768 186.917 123.321 184.984 124.923C182.059 127.349 150.872 119.163 148.282 115.551C147.206 114.061 147.426 103.859 149.359 92.4201L149.606 92.3015C145.357 92.8098 141.541 92.3015 139.148 89.9128C133.69 84.451 136.365 46.1677 136.663 42.1391C135.799 40.0976 135.372 37.8978 135.41 35.6812C135.43 30.3888 137.573 26.0892 140.228 26.0892C141.409 26.0892 142.493 26.9464 143.329 28.3627L142.99 27.5495H144.236L145.547 28.4236L147.24 31.4967L147.659 33.8448L147.531 42.2035L152.481 70.6983L155.487 70.4035L157.014 67.0153L160.322 62.3667L162.912 59.5443V57.7554C157.888 56.8948 153.974 50.0167 153.974 41.6614C153.974 41.0312 153.994 40.4077 154.038 39.7979L154.323 36.2131L154.851 35.0307C154.874 34.9392 154.898 34.8477 154.925 34.7596C154.536 34.5461 153.232 33.3467 153.232 29.2131C153.232 21.0814 162.225 16.7106 169.765 15.0165C159.736 10.6796 148.513 7.31512 136.151 4.58083C110.944 -0.887732 81.1734 -3.75077 63.1545 9.48696C45.1355 22.7247 38.8753 51.887 28.5893 84.2342C18.229 116.578 3.84628 152.097 11.2712 184.525C18.6149 216.872 47.8373 246.123 79.8598 250.222C111.882 254.322 146.671 233.281 183.633 219.271C220.595 205.261 259.721 198.373 268.152 177.017C276.582 155.661 254.385 119.831 238.303 92.3591ZM98.2884 205.827H78.8L92.0248 201.148V198.413H98.2884V205.827Z"
                fill="#EDF0F4"
              />
              <path
                d="M156.449 31.4697C156.447 31.2999 156.456 31.1301 156.476 30.9615C155.856 31.9983 155.051 34.3497 154.918 34.7834L154.942 34.8342L156.449 31.4697Z"
                fill="#F8F8F8"
              />
              <path
                d="M57.9234 262.512H47.326V275.072H31.7888V262.512H0V257.768L36.7286 207.535H47.326V249.128H57.9234V262.512ZM31.7888 249.128V233.478L20.6802 249.128H31.7888Z"
                fill="#E0E5EC"
              />
              <path
                d="M66.7705 241.406C66.7705 221.948 72.4281 206.606 92.1636 206.606C111.801 206.606 117.266 221.948 117.266 241.406C117.266 260.763 111.815 276 92.1636 276C72.4281 276 66.7705 260.763 66.7705 241.406ZM101.749 241.406C101.749 229.67 99.4836 220.61 92.1806 220.61C84.7725 220.61 82.2027 229.67 82.2027 241.406C82.2027 253.038 84.7725 261.895 92.1806 261.895C99.4836 261.909 101.749 253.038 101.749 241.406Z"
                fill="#E0E5EC"
              />
              <path
                d="M181.171 262.512H170.574V275.072H155.04V262.512H123.241V257.768L159.977 207.535H170.574V249.128H181.171V262.512ZM155.04 249.128V233.478L143.928 249.128H155.04Z"
                fill="#E0E5EC"
              />
              <path
                d="M181.527 136.955C181.818 139.726 187.936 259.232 187.936 259.232H181.527L150.361 146.709L181.527 136.955Z"
                fill="#081829"
              />
              <path
                d="M184.997 124.93C185.807 141.258 178.856 154.631 166.785 154.631C150.216 154.631 128.279 144.06 122.242 138.093C120.736 142.552 99.365 198.715 99.365 198.715H90.5146C90.5146 198.715 96.1858 133.323 106.817 116.551C110.314 111.889 133.876 112.062 148.296 115.558C159.743 122.806 181.046 126.041 184.997 124.93Z"
                fill="#081829"
              />
              <path
                d="M142.99 27.8511C143.364 27.6247 143.801 27.5226 144.236 27.5597C145.533 27.6512 147.652 31.0801 147.652 33.855C147.652 36.63 146.87 37.6735 147.107 39.6895C147.344 41.7055 152.474 69.0416 152.474 70.7085C153.479 70.7104 154.483 70.6459 155.48 70.5154"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M170.567 57.742C175.754 58.3248 179.489 61.8621 182.13 67.0392"
                stroke="#081829"
                stroke-width="2.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M153.114 76.7702C155.484 69.4517 158.714 63.0886 162.905 59.88"
                stroke="#081829"
                stroke-width="2.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M185.112 75.0897C190.289 94.4838 186.93 123.328 184.997 124.93C182.072 127.356 150.886 119.17 148.296 115.558C147.219 114.068 147.439 103.866 149.372 92.4271"
                stroke="#081829"
                stroke-width="2.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M160.078 74.1205C158.7 74.588 150.618 77.7425 150.618 77.7425C150.483 76.1161 144.27 43.8604 143.888 41.8851C144.672 39.9134 145.059 37.8064 145.029 35.6846C145.029 30.3889 142.879 26.0892 140.228 26.0892C137.577 26.0892 135.43 30.3889 135.43 35.6846C135.392 37.9012 135.819 40.1011 136.683 42.1426C136.385 46.1745 133.71 84.4579 139.168 89.9163C144.734 95.4865 157.969 90.8243 166.528 87.0126"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M154.077 37.202L149.005 35.8543L148.064 39.4008L153.136 40.7486L154.077 37.202Z"
                fill="#081829"
              />
              <path
                d="M178.69 66.1786C181.585 66.5175 183.961 67.5339 185.014 69.5669C188.837 76.9938 173.699 90.3739 169.511 89.3777C165.323 88.3816 158.568 73.426 159.831 70.7426C160.19 69.9734 162.11 68.9536 164.747 68.0625"
                stroke="#081829"
                stroke-width="2.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M162.905 59.88V57.7793"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M170.567 53.8524V63.9424C170.567 64.5523 168.763 64.942 166.907 64.9996"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M172.314 50.6098C171.855 51.7527 171.269 52.8404 170.567 53.8524"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M165.986 57.542C165.332 57.7643 164.646 57.8787 163.955 57.8808C158.429 57.8808 153.953 50.6335 153.953 41.6954C153.942 39.3613 154.263 37.0375 154.905 34.7936"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M170.798 47.8313C170.994 49.6779 171.691 50.6097 172.314 50.6097C173.049 50.6097 174.986 50.1014 176.232 50.1014C188.468 50.1014 202.837 62.7428 230.404 62.7428C244.803 62.7428 267.4 59.1073 267.4 37.9445C267.4 26.5161 263.289 12.7058 241.563 12.7058C227.766 12.7058 218.1 19.4822 206.764 19.4822C192.206 19.4822 186.592 14.2068 176.533 14.2068C169.369 14.2068 153.246 18.3607 153.246 29.2436C153.246 33.3772 154.539 34.5767 154.939 34.7901C155.071 34.3497 155.877 32.005 156.496 30.9682C156.387 32.4874 156.822 33.9958 157.722 35.2238"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M168.692 41.9055C168.975 42.2285 169.24 42.5679 169.484 42.922"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M168.289 43.3997C168.709 43.1133 169.206 42.9609 169.714 42.9627C170.295 42.9622 170.859 43.1601 171.312 43.5236C171.766 43.8871 172.081 44.3946 172.208 44.9621C172.334 45.5296 172.263 46.1232 172.007 46.6449C171.75 47.1666 171.324 47.5852 170.797 47.8315"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M145.029 35.6847C145.05 37.6073 144.74 39.5192 144.111 41.3362C146.992 41.675 149.078 41.9224 149.078 41.9224L151.065 33.8821C151.065 33.8821 148.127 32.659 144.467 31.1716C144.848 32.6453 145.037 34.1623 145.029 35.6847Z"
                fill="#081829"
              />
              <path
                d="M135.43 35.6846C135.43 32.6929 136.107 30.0229 137.191 28.2645C134.032 27.021 131.374 26.0113 130.619 25.8216C128.422 25.2795 125.202 38.5646 127.338 39.0966C128.202 39.31 131.916 39.8081 136.036 40.3299C135.627 38.8157 135.423 37.2532 135.43 35.6846Z"
                fill="#081829"
              />
              <path
                d="M98.2884 198.414V205.827H78.8L92.0282 201.148V198.414"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M187.598 258.951V266.364H170.141L181.987 261.685V258.951"
                stroke="#081829"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="270" height="276" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </DivStyle>
        <DivStyle>
          <h1 id="page-not-found-heading">{t(translations.PageNotFound)}</h1>
          <P>
            Unfortunately the page you a looking for doesn’t exist <br />
            (anymore) or there was an error in the link that you <br />
            followed or typed.
          </P>
          <Button
            style={{ width: '100%' }}
            onClick={() => window.open(toRootedURL('/dashboards'), '_self')}
          >
            {' '}
            Go back to main{' '}
          </Button>
        </DivStyle>
      </Wrapper>
    </>
  );
}

const DivStyle = styled.div`
  display: inline-block;
  padding: 70px;
`;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 320px;
`;

/* const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: black;
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`;*/
