import React from 'react';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IAssetSearchHistoryDto } from './IAssetSearchResultsDto';

type RowType = IAssetSearchHistoryDto;

export function AssetSearchHistoryTable<
  TProps extends Omit<
    BasicTableProps<RowType>,
    'api' | 'additionalColumns' | 'searchColumns' | 'screenName'
  >
>({
  initialState,
  rowActions,
  serviceGroups,
  savedListSettings,
  ...props
}: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/AssetSearchHistory';
  return (
    <>
      <BasicTable
        api={url}
        initialState={initialState}
        additionalColumns={[
          'Id',
          'ServiceGroupId',
          'AssetCatId',
          'AssetCatGroupId',
        ]}
        rowActions={rowActions}
        useRowSelect={false}
        searchColumns={[
          'UserName',
          'MainText',
          'ServiceGroupName',
          'Tags',
          'ServiceName',
          'Description',
          'ModelNumber',
          'BuildingNumber',
          'AssetCatGroupName',
          'AssetCatName',
          'RoomNumber',
          'Barcode',
          'Availability',
        ]}
        screenName={t(translations.menu_AssetSearchHistory)}
        serviceGroups={serviceGroups}
        {...(newProps as TProps)}
      />
    </>
  );
}
