import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import React from 'react';
import { Link as MuiLink, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useArticlesSlice } from 'app/pages/ArticlesPage/slice';
import { useDispatch } from 'react-redux';

const TruncatedLabel = styled('span')({
  width: '30ch',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ArticleLink = (props: {
  value?: IArticleDto;
  Id?: number;
  externalId?: string;
  externalIdSource?: string;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const openArticle = useOpenArticlePopUp();
  const open = () => {
    if (props.Id === 0) {
      dispatch(actions.clearArticleDetails());
    }
    openArticle(
      {
        value: props.value,
        Id: props.Id,
        externalId: props.externalId,
        externalIdSource: props.externalIdSource,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.PublicationDetails)}>
      <TruncatedLabel>
        <MuiLink onClick={() => open()} color="textPrimary">
          {props.children}
        </MuiLink>
      </TruncatedLabel>
    </Tooltip>
  );
};

export const useOpenArticlePopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: {
        value?: IArticleDto;
        Id?: number;
        externalId?: string;
        externalIdSource?: string;
        useSidePanel?: boolean;
      },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      const panelState = {
        renderPageType: RenderPageType.Article,
        renderPageProps: props,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.Article,
          renderPageProps: props,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
