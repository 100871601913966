import { translations } from 'locales/translations';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { BuildingPickerFilter } from 'app/components/BasicTable/Filters/BuildingPickerFilter';
import { RoomPickerFilter } from 'app/components/BasicTable/Filters/RoomPickerFilter';

import {
  arrayToQueryString,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getDefaultFilterDatePeriod,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  initExPickerValue,
  initMultiPickerValue,
  initPickerValue,
  presetDatesRangeToQueryString,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { initBuildingData } from 'app/components/pickers/AutocompletePickers/BuildingPicker';
import { initRoomData } from 'app/components/pickers/AutocompletePickers/RoomPicker';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';

import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { TextFieldFilter } from 'app/components/BasicTable/Filters';
import { initAssetMultiData } from 'app/components/pickers/AutocompletePickers/AssetMultiPicker';
import { AssetMultiFilter } from 'app/components/BasicTable/Filters/AssetMultiFilter';
import { initLocationData } from 'app/components/pickers/AutocompletePickers/LocationPicker';
import { LocationPickerFilter } from 'app/components/BasicTable/Filters/LocationPickerFilter';
import { SubLocationFilter } from 'app/components/BasicTable/Filters/SubLocationListFilter';
import { initSubLocationListData } from 'app/components/pickers/AutocompletePickers/SubLocationListPicker';

import { AllowedSettings } from 'utils/globalSettings';
import { IBarcodeScanLogDto } from './IBarcodeScanLogDto';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import DatesRangeFilter from 'app/components/BasicTable/Filters/DatesRangeFilter';
import { dateUtils } from 'utils/date-utils';
import { initLogStatusesData } from 'app/components/pickers/StaticOptionsPickers/BarcodeLogStatusPicker';
import BarcodeLogStatusFilter from 'app/components/BasicTable/Filters/BarcodeLogStatusFilter';
import { tryParseBool } from 'utils/string-utils';
import { Roles } from 'api/odata/generated/enums/Roles';
import { Identifiable } from 'types/common';

export const GetFilters: GetPageFilters<IBarcodeScanLogDto> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParamsCI(search);
  const hideRoomFloor =
    tryParseBool(
      settings?.GetString(AllowedSettings.HideRoomFloorTextBoxes),
    ) === true;
  const isAdmin =
    user !== undefined &&
    user !== null &&
    (user?.Roles?.includes(Roles.Administrators) ||
      user?.Roles?.includes(Roles.InventoryAdmin));
  var result: IFilterSettings<IBarcodeScanLogDto>[] = [
    {
      fieldName: 'AssetId',
      queryStringSerializer: arrayToQueryString('aid'),
      displayNameKey: translations.AssetName, //(displayed as Resource Name)
      operator: ODataOperators.Includes,
      value: getNumberEntityArray(params, 'aid'),
      initData: initMultiPickerValue(initAssetMultiData),
      component: AssetMultiFilter,
      urlKey: 'aid',
    },
    {
      fieldName: 'Date',
      displayNameKey: translations.EntryDate,
      operator: ODataOperators.Between,
      defaultValue: PresetDatesRange.fromInitPreset(
        getPeriodTypesEntity('CustomDate'),
        dateUtils.addMonths(new Date(), -1),
        new Date(),
      ),
      value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
      queryStringSerializer: presetDatesRangeToQueryString(
        'period',
        'st',
        'et',
      ),
      dateTimeClear: true,
      component: DatesRangeFilter,
      inversionDisabled: true,
      urlKey: 'period',
    },
    {
      fieldName: 'BuildingId',
      displayNameKey: translations.BuildingName,
      queryStringSerializer: 'BuildingId',
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'BuildingId')),
      ),
      initData: initExPickerValue(
        initBuildingData,
        undefined,
        'locations',
        true,
      ),
      component: BuildingPickerFilter,
      urlType: 'locations',
    },
    {
      fieldName: 'RoomId',
      displayNameKey: translations.Room,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'roomId',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'roomId')),
      ),
      initData: initExPickerValue(initRoomData, undefined, 'base', true),
      urlType: 'base',
      withoutServiceGroups: true,
      component: RoomPickerFilter,
      urlKey: 'roomId',
    },
    {
      fieldName: 'StatusName',
      queryStringSerializer: 'status',
      displayNameKey: translations.StatusName,
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'status')),
      ),
      initData: initPickerValue(initLogStatusesData),
      component: BarcodeLogStatusFilter,
      urlKey: 'status',
    },
    {
      fieldName: 'LocationId',
      queryStringSerializer: 'locationid',
      displayNameKey: translations.LocationList,
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'locationid')),
      ),
      initData: initPickerValue(initLocationData),
      component: LocationPickerFilter,
    },
    {
      fieldName: 'SubLocationId',
      queryStringSerializer: 'sublocationid',
      displayNameKey: translations.SubLocationsList,
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'sublocationid')),
      ),
      initData: initPickerValue(initSubLocationListData),
      component: SubLocationFilter,
    },
  ];
  if (!hideRoomFloor) {
    result.push({
      fieldName: 'FloorName',
      displayNameKey: translations.FloorName,
      operator: ODataOperators.Contains,
      queryStringSerializer: 'floor',
      value: GetStringOrUndefined(GetRawValue(params, 'floor')),
      component: TextFieldFilter,
      urlKey: 'floor',
    });
  }
  if (isAdmin) {
    result.push({
      fieldName: 'Id',
      queryStringSerializer: 'empty',
      displayNameKey: translations.ShowBarcodesWithoutAssetsOrRooms,
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'empty')),
        t,
      ),
      component: BooleanPickerFilter,
      customFilter: (value, settings, filters) => {
        if ((value as Identifiable<boolean>)?.Id === true) {
          return `${new Condition(
            'AssetId',
            ODataOperators.Equals,
            null,
          ).toString()} and ${new Condition(
            'RoomId',
            ODataOperators.Equals,
            null,
          ).toString()}`;
        } else {
          return undefined;
        }
      },
      inversionDisabled: true,
    });
  }
  return result;
};
