import * as React from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { verticalScrolling } from 'styles/CssVariables/styledVariables';
import { selectMenuItems } from '../slice/selectors';
import {
  RowActionMenuItem,
  RowActionsMenu,
} from 'app/components/BasicTable/RowActions';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { sideBarActions } from '../slice';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
  bindFocus,
  bindHover,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { bookitColors } from 'styles/colors/bookitColors';
import {
  MenuItemEntry,
  MenuItemEntryL1,
  MenuItemEntryL2,
  MenuItems,
} from '../slice/types';
import { toRootedURL } from 'utils/url-utils';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import clsx from 'clsx';
import { useSpecificCoreChanged } from 'app/hooks/useSpecificCoreChanged';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { CoreSelectionProps } from 'app/pages/CoreSelectionPage/CoreSelection';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { appSettingsActions } from 'app/slice';
import { GroupsIcon } from '../GroupsIcon';

export interface SideBarMenuItemProps {
  menuItems: MenuItems;
  isOpen: boolean;
  loading: boolean;
  isMobile?: boolean;
  toggleExpand: (menuItem: MenuItemEntryL1, toggle?: boolean) => void;
  toggleMenu?: () => void;
  onSubMenuClick?: (menuItem: MenuItemEntryL2) => void;
  changeSubMenuName?: (menuItem: MenuItemEntryL2, newName: string) => void;
  openCoreSelection?: (isMobile?: boolean) => void;
}
const NavWrapper = styled('div')`
  &.side-bar-menu-wrapper {
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    isolation: isolate;
    ${verticalScrolling}
  }
`;

const mapStateToProps = state => ({
  menuItems: selectMenuItems(state),
});

const mapDispatchToProps = dispatch => {
  return {
    toggleExpand: (menuItem: MenuItemEntryL1) =>
      dispatch(sideBarActions.toggleExpand({ item: menuItem })),
    changeSubMenuName: (menuItem: MenuItemEntryL2, newName: string) =>
      dispatch(sideBarActions.setMenuName({ entry: menuItem, name: newName })),
    openCoreSelection: (isMobile?: boolean) => {
      dispatch(
        layoutActions.openSidePanel({
          type: RenderPageType.CoreSelection,
          props: { useSidePanel: true } as CoreSelectionProps,
          expanded: !isMobile,
        }),
      );
      dispatch(appSettingsActions.updateExpandedState_Local(false));
    },
  };
};

export const SideBarMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(function SideBarMenu({
  isOpen,
  isMobile,
  menuItems,
  toggleMenu,
  ...props
}: SideBarMenuItemProps) {
  const {
    doChange,
    menuItem,
    name,
    groupsCount,
    selected,
    onAllCoresClick,
    onSpecificCoresClick,
  } = useSpecificCoreChanged(menuItems);
  React.useEffect(() => {
    if (doChange && !!menuItem && !!name) {
      !!props.changeSubMenuName && props.changeSubMenuName(menuItem, name);
    }
  }, [doChange, menuItem, name, props]);
  const onSubMenuClick = React.useCallback(
    (item: MenuItemEntryL2) => {
      !!props.onSubMenuClick && props.onSubMenuClick(item);
      !!onAllCoresClick && onAllCoresClick(item);
      !!onSpecificCoresClick && onSpecificCoresClick(item);
    },
    [onAllCoresClick, onSpecificCoresClick, props],
  );
  // pass-through index for menuItems group/ menuItem so that each MenuItemL1 will have sequential unique index. this is needed for automated UI tests
  let passThroughIndex = 0;
  return (
    <NavWrapper className="side-bar-menu-wrapper">
      <List component="nav" role="menubar" className="side-bar-menu-nav">
        {menuItems.map((tabGroup, tabIndex) => {
          return (
            <React.Fragment key={tabIndex}>
              {tabGroup.map((item, index) => {
                passThroughIndex++;
                return (
                  <MenuItemL1
                    key={index}
                    id={`menuitem-${passThroughIndex}`}
                    index={passThroughIndex}
                    item={item}
                    isMobile={isMobile}
                    isExpanded={isOpen}
                    toggleMenu={toggleMenu}
                    toggleExpand={props.toggleExpand}
                    subMenuEndIconId={
                      !!groupsCount ? '/speccoresdash' : undefined
                    }
                    subMenuEndIcon={
                      !!groupsCount ? (
                        // <IconButton
                        //   title={t(translations.SwitchToOtherLaboratory)}
                        //   onClick={event => {
                        //     event.preventDefault();
                        //     event.stopPropagation();
                        //     props.openCoreSelection?.(isMobile);
                        //   }}
                        //   shape="circle"
                        //   variant="textGray"
                        //   size="xs"
                        // >
                        //   {groupsCount > 1 ? (
                        //     <div>
                        //       <Icon icon="toggle-off" />
                        //       {`+${groupsCount - 1}`}{' '}
                        //     </div>
                        //   ) : (
                        //     <Icon icon="toggle-on" />
                        //   )}
                        // </IconButton>
                        <GroupsIcon
                          groupsCount={groupsCount}
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            props.openCoreSelection?.(isMobile);
                          }}
                          selected={selected}
                        />
                      ) : undefined
                    }
                    onSubMenuClick={
                      !!props.onSubMenuClick ||
                      !!onAllCoresClick ||
                      !!onSpecificCoresClick
                        ? onSubMenuClick
                        : undefined
                    }
                  />
                );
              })}
              <Divider className="side-bar-menu-divider" />
            </React.Fragment>
          );
        })}
      </List>
    </NavWrapper>
  );
});

interface MenuItem1Props
  extends Pick<
      SideBarMenuItemProps,
      'toggleExpand' | 'toggleMenu' | 'onSubMenuClick'
    >,
    Pick<React.HTMLAttributes<HTMLElement>, 'id'> {
  /**
   * Indicates whether the menu is expanded or collapsed
   */
  isExpanded: boolean;
  /**
   * Top level menu entry
   */
  item: MenuItemEntryL1;
  /**
   * Menu entry's index in it's group
   */
  index: number;
  isMobile?: boolean;
  subMenuEndIcon?: React.ReactNode;
  subMenuEndIconId?: string;
}

/**
 * Primary component responsible for rendering everything related to the menu entry.
 * Scoped to a top level menu entry and down.
 * @returns
 */
const MenuItemL1 = React.memo(function MenuItemL1({
  isExpanded,
  item,
  toggleExpand,
  toggleMenu,
  onSubMenuClick,
  isMobile,
  subMenuEndIcon,
  subMenuEndIconId,
  ...props
}: MenuItem1Props) {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: `id-${props.index}`,
  });

  const { onClick: handlePopupClick } = {
    ...bindTrigger(popupState),
  };
  const handleTopLevelMenuItemClick = React.useCallback(
    e => {
      // nothing needs expanding if the side menu is in collaped form
      isExpanded === true && toggleExpand(item, true);

      // in collapsed state we need to show a second level menu in the popover
      handlePopupClick(e);
      if (isMobile && !!toggleMenu && !!item.link) toggleMenu();
    },
    [handlePopupClick, isExpanded, item, toggleExpand, toggleMenu, isMobile],
  );
  const aria = {
    role: 'menuitem',
    'aria-haspopup': item.items?.length > 0,
  };

  return (
    <>
      <StyledListItemMaybeLink
        id={props.id}
        key={props.index}
        link={item.link}
        external={item.external}
        className={clsx('side-bar-menu-item', {
          'side-bar-menu-item-selected': item.selected,
          'side-bar-menu-item-short': !isExpanded,
        })}
        onClick={handleTopLevelMenuItemClick}
        {...aria}
        {...bindFocus(popupState)}
        {...bindHover(popupState)}
      >
        {/* icon - should be there for all top level menus */}
        {item.icon !== undefined && (
          <ListItemIcon className="menuItemIcon">
            <Icon icon={item.icon} size="lg" />
          </ListItemIcon>
        )}
        {/* primary text - should be visible only in expanded mode */}
        {isExpanded && (
          <TopLevelListItemText>{t(item.name)}</TopLevelListItemText>
        )}
        {/* additional controls - toggle collapse and admin items */}
        {isExpanded && (
          <StyledListItemSecondaryAction>
            {item.adminItems !== undefined && !isMobile && (
              <RowActionsMenu
                id={`${item.mainTab}_settings`}
                variant="textGray"
                items={item.adminItems.map(x => ({
                  icon: 'circle-small',
                  text: t(x.name) as string,
                  href: x.external ? x.link : undefined,
                  to: x.external ? undefined : x.link,
                }))}
              />
            )}
            {item.items?.length > 0 && (
              <IconButton
                title={
                  t(item.selected ? translations.Close : translations.Expand) +
                  ' ' +
                  t(item.name)
                }
                onClick={e => toggleExpand(item)}
              >
                <Icon icon={item.selected ? 'chevron-up' : 'chevron-right'} />
              </IconButton>
            )}
          </StyledListItemSecondaryAction>
        )}
      </StyledListItemMaybeLink>
      {/* hover menu for collapsed menu variant */}
      {!isExpanded && !isMobile && item.items?.length > 0 && (
        <HoverMenu
          onClick={handlePopupClick}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorPosition={{ top: 0, left: 200 }}
          className={'hoverChildMenu'}
          getContentAnchorEl={null}
          {...bindMenu(popupState)}
        >
          {item.items?.map((x, index) => {
            return (
              <RowActionMenuItem
                dense
                item={{
                  icon: 'circle-small',
                  text: t(x.name) as string,
                  href: x.link,
                  to: x.link,
                }}
                key={index}
                onClick={() => {
                  popupState.close();
                  !!onSubMenuClick && onSubMenuClick(x);
                }}
              />
            );
          })}
        </HoverMenu>
      )}
      {/* second level menu items */}
      {isExpanded && item.items?.length > 0 && (
        <Collapse in={item.selected}>
          <List
            dense
            component="div"
            disablePadding
            className={clsx('side-bar-second-menu', {
              'side-bar-second-menu-open': item.selected,
            })}
          >
            {item.items.map((item2, index) => {
              return (
                <ListItemMaybeLink
                  id={`${props.id}-${index}`}
                  key={index}
                  link={item2.link}
                  external={item2.external}
                  selected={item2.selected}
                  className="side-bar-second-menu-item"
                  onClick={() => {
                    isMobile && !!toggleMenu && toggleMenu();
                    !!onSubMenuClick && onSubMenuClick(item2);
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText
                    primary={t(item2.name)}
                    className="side-bar-second-item-text"
                  />
                  {!!subMenuEndIconId &&
                    !!subMenuEndIcon &&
                    subMenuEndIconId === item2.id && (
                      <ListItemIcon>{subMenuEndIcon}</ListItemIcon>
                    )}
                </ListItemMaybeLink>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
});
const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  // override default right: 16px of MuiSecondaryActionRoot to increase usefull space on the right of menu item
  right: 4px;
`;
/**
 * This should be a standard ListItemText instead of span with an appropriate BasicTypography + appropriate variant
 * <ListItemText primary={...} primaryTypographyProps={{component:BasicTypography, variant:'????'}}/>
 * Note 'button' variant is an all caps and is not used on actual buttons in other places
 */
const TopLevelListItemText = styled('span')`
  color: ${bookitColors.grayscale.blackRegular};
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  line-height: 20px;
  white-space: initial;
  letter-spacing: 0.2px;
`;

interface TopLevelMenuItemProps
  extends Pick<MenuItemEntry, 'link' | 'external'>,
    Omit<ListItemProps, 'button' | 'component'> {}

/**
 * Renders list item either as "li" for non-linkable menu items, or internal/external link
 */
const ListItemMaybeLink = React.forwardRef(function TopLevelMenuItem(
  { link, external, ...props }: TopLevelMenuItemProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  if (link === undefined) {
    return (
      <ListItemX
        button
        disableRipple
        component="li"
        // @ts-ignore ref li/div/a conflict Type 'HTMLDivElement | null' is not assignable to type 'HTMLAnchorElement | null'
        ref={ref as React.ForwardedRef<HTMLLIElement>}
        {...props}
      />
    );
  } else {
    if (external) {
      return (
        <ListItemX
          button
          component="a"
          disableRipple
          href={toRootedURL(link)}
          target="_blank"
          // @ts-ignore ref li/div/a conflict Type 'HTMLDivElement | null' is not assignable to type 'HTMLAnchorElement | null'
          ref={ref}
          {...props}
        />
      );
    } else {
      return (
        <ListItemX
          button
          component={Link}
          disableRipple
          to={link}
          // @ts-ignore ref li/div/a conflict Type 'HTMLDivElement | null' is not assignable to type 'HTMLAnchorElement | null'
          ref={ref}
          {...props}
        />
      );
    }
  }
});
const ListItemX = withStyles(theme => ({
  secondaryAction: {
    // overrides MuiListItem-secondaryAction-332 to make space for 2 buttons (toggle + admin settings)
    paddingRight: 60,
  },
}))(ListItem);

const StyledListItemMaybeLink = styled(ListItemMaybeLink)`
  height: 40px;
`;
