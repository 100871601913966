import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { allowedToViewUserTraining } from 'app/permissions/UserTrainings/userTrainingPermissionUtils';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { TrainingDetailsProps } from '../UserTrainings/TrainingDetailsPage/Details';

export const userTrainingPrimaryAction: PrimaryActionDynamic<IUserTrainingDto> = (
  openPanel,
  row,
  toggle,
  user,
  useSidePanel,
) => {
  if (allowedToViewUserTraining(user, row.original)) {
    openPanel({
      renderPageType: RenderPageType.TrainingDetails,
      renderPageProps: {
        id: String(row.original.Id),
        useSidePanel: true,
      } as TrainingDetailsProps,
      useSidePanel: useSidePanel,
      expanded: false,
      isCover: useSidePanel,
    });
  }
};

export default userTrainingPrimaryAction;
