import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const TabsWrapper = styled('div')(({ theme }) => ({
  '&.tabsWrapper': {
    display: 'flex',
    padding: '0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
    width: '100%',
    '&.scrollContent': {
      overflow: 'hidden',
    },
    '&.tabsHorizontal': {
      '& .basicTabs': {
        flexGrow: 1,
        width: '100%',
        borderBottom: `1px solid ${bookitColors.grayscale.grayBorders}`,
      },
    },
    '&.tabsVertical': {
      flexDirection: 'row',
      //padding: '0px',
      '& .tabIndicator': {
        display: 'none',
      },
    },
    '& .basicTabs': {
      backgroundColor: 'transparent',
      '& .basicTabSelected': {
        backgroundColor: `${bookitColors.grayscale.graybgComponents}`,
      },
      '&.tabsVertical': {
        minWidth: '15%',
      },
    },
    '& .groupedHeader': {
      paddingLeft: '0px',
      marginLeft: '0px',
      fontSize: '14px',
      color: `${bookitColors.grayscale.blackRegular}`,
      '&.selected': {
        color: `${bookitColors.primary.components}`,
      },
      '& > span': {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '0px',
        marginLeft: '0px',
        justifyContent: 'start',
      },
    },
    '& .tabSizeSmall': {
      minHeight: '40px',
    },
  },
}));
export const TabPanelWrapper = styled('div')(({ theme }) => ({
  '&.tabPanel': {
    width: '100%',
    '&.scrollablePanel': {
      height: '100%',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
        borderRadius: '4px',
        backgroundColor: bookitColors.grayscale.grayBorders,
      },
    },
  },
}));

export const SubTabsWrapper = styled('div')(({ theme }) => ({
  color: `${bookitColors.grayscale.blackRegular}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  '& .subTabs': {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px ${bookitColors.grayscale.grayBorders} solid`,
    paddingLeft: '8px',
    color: `${bookitColors.grayscale.blackRegular}`,
    '& .subTab': {
      fontSize: '14px',
      color: `${bookitColors.grayscale.blackRegular}`,
      borderRadius: '8px',
      '& .buttonLabel': {
        alignSelf: 'self-start',
      },
    },
  },
}));

export const Divider = styled.div(() => ({
  width: '24px',
}));
