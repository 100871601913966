import React from 'react';
import { useAsyncDebounce } from 'react-table';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { TextControlField } from '../BasicInputs/TextControlField';
import { Icon } from '../BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';
import { IconButton } from '../BasicButtons/IconButton';

export interface IGlobalFilterProps {
  globalFilter?: string;
  setGlobalFilter: (value: string) => void;
  info?: string;
  useRemoveSearch?: boolean;
  placeholder?: string;
  ns?: string;
}
export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  info,
  placeholder,
  useRemoveSearch,
  ns,
}: IGlobalFilterProps) {
  const { t } = useTranslation(ns);
  const isMobile = DetectIsMobile();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextControlField
      fullWidth={true}
      value={value || ''}
      size="small"
      minInputWidth={isMobile ? undefined : 'default'}
      textBoxWidth={isMobile ? undefined : 'default'}
      placeholder={placeholder || t(translations.Search)}
      endIcon={
        useRemoveSearch && value !== undefined && value !== '' ? (
          <IconButton
            variant="text"
            size="xs"
            onClick={() => {
              setValue('');
              onChange('');
            }}
          >
            {' '}
            <Icon icon="times" />
          </IconButton>
        ) : !!info ? (
          <Icon icon="info-circle" />
        ) : (
          <Icon icon="search" />
        )
      }
      inputProps={{ 'aria-label': 'table search' }}
      info={info}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
}
