import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import RegionFilter from 'app/components/BasicTable/Filters/RegionFilter';
import { ServiceGroupsFilter } from 'app/components/BasicTable/Filters/ServiceGroupsFilter';
import { SuperGroupFilter } from 'app/components/BasicTable/Filters/SuperGroupFilter';
import {
  arrayToQueryString,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  initExPickerValue,
  initMultiExPickerValue,
  initPickerValue,
} from 'app/components/BasicTable/types/FilterParam';
import { initRegionData } from 'app/components/pickers/AutocompletePickers/RegionPicker';
import { initSuperGroupData } from 'app/components/pickers/AutocompletePickers/SuperGroupPicker';
import { initServiceGroupsMultiData } from 'app/components/pickers/MultiSelectPickers/ServiceGroupsPicker';
import { Roles } from 'app/slice/types';
import { translations } from 'locales/translations';
import { IsModuleEnabled } from 'types/AppSettings';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { KnownModules } from 'types/KnownModules';

export const GetFilters: GetPageFilters<ICoresDirectoryRow> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParams(search);
  const isAdmin =
    !!user &&
    (user.Roles.includes(Roles.Administrators) ||
      user.Roles.includes(Roles.GroupAdministrators));
  const hasOwnedGroups =
    !!user && user.AdminServiceGroups && user.AdminServiceGroups.length > 0;
  const res: IFilterSettings<ICoresDirectoryRow>[] = [
    {
      fieldName: 'Id',
      displayNameKey: translations.ServiceGroup,
      operator: ODataOperators.Includes,
      queryStringSerializer: arrayToQueryString('sgids'),
      value: getNumberEntityArray(params, 'sgids'),
      initData: initMultiExPickerValue(initServiceGroupsMultiData),
      component: ServiceGroupsFilter,
      urlKey: 'sgids',
    },
  ];
  if (IsModuleEnabled(appSettings, KnownModules.Divisions)) {
    res.push({
      fieldName: 'DivisionId',
      displayNameKey: translations.Division,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'divisionId',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'divisionId')),
      ),
      component: SuperGroupFilter,
      initData: initPickerValue(initSuperGroupData),
    });
    res.push({
      fieldName: 'RegionId',
      displayNameKey: translations.Region,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'regionid',
      initData: initExPickerValue(initRegionData, undefined, 'base'),
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'regionid')),
      ),
      component: RegionFilter,
      urlType: 'base',
    });
  }
  if (hasOwnedGroups) {
    res.push({
      fieldName: 'OwnedByMe',
      displayNameKey: translations.OnlyMyCores,
      queryStringSerializer: 'ownedbyme',
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'ownedbyme')),
        t,
      ),
      component: BooleanPickerFilter,
    });
  }
  if (isAdmin) {
    res.push({
      fieldName: 'Active',
      displayNameKey: translations.Active,
      queryStringSerializer: 'Active',
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'Active')),
        t,
      ),
      component: BooleanPickerFilter,
    });
  }
  return res;
};
