export interface ImportData {
  ScreenId: number;
  Columns: ImportColumn[];
  AddOnly: boolean;
  BudgetType?: number;
  Iad?: ImportAdditionalData;
  ImportAssetImageFiles: boolean;
  AutoGenerateAssetNamesBasedOn?: number;
  UpdateQRCode: boolean;
}
export enum ImportAdditionalData {
  none,
  AssetRanges,
  OfflineServiceTypes,
  SampleTypes,
}
export interface ImportColumn {
  Include: boolean;
  ExcelName: string | undefined;
  Name: string | undefined;
}

export enum ImportResult {
  success,
  error,
  failed,
  default,
}

export interface ImportResponseData {
  Result: number;
  Rows: number;
  InsertedRows: number;
  UpdatedRows: number;
  Errors: number;
}

export interface TemplateColumns {
  Id: string;
  DisplayName: string;
}
