import styled from 'styled-components';

export const AssistedRoot = styled.div`
  &.assisted-root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    & .assisted-list {
      padding: 0;
      margin: 0;
      & .assisted-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
      }
    }
  }
`;
