import {
  BudgetEntitySechema,
  FeeTypeEntitySchema,
  OfflineServiceTypeEntitySchema,
  OnlineServiceEntitySchema,
  PaymentMethodEntitySchema,
  ServiceGroupEntitySchema,
  UserEntitySchema,
} from 'app/components/Forms/Schemas';
import { InvoiceChildRowBaseSchema } from 'app/components/Forms/Schemas/InvoiceChildRowBaseSchema';
import { CatRateTypes } from 'enums/CatRateTypes';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';
import * as Yup from 'yup';

import { IPayment } from './IPayment';

export function getPaymentSchema(t: TFunction): Yup.SchemaOf<IPayment> {
  return InvoiceChildRowBaseSchema(t).shape({
    Budget: BudgetEntitySechema.nullable().notRequired().optional(),
    Method: PaymentMethodEntitySchema.required(
      t(translations.err_PaymentMethodRequired),
    ).nullable(),
    Amount: Yup.number()
      .required(t(translations.PleaseEnterAnAmount))
      .positive(t(translations.InvalidValue)),

    Reference: Yup.string().notRequired(),
    Remarks: Yup.string().notRequired(),
  });
}

export function getPaymentSchemaWithBudgetNotRequired(
  t: TFunction,
): Yup.SchemaOf<IPayment> {
  return InvoiceChildRowBaseSchema(t).shape({
    Method: PaymentMethodEntitySchema.required(
      t(translations.err_PaymentMethodRequired),
    ).nullable(),
    Amount: Yup.number()
      .required(t(translations.PleaseEnterAnAmount))
      .positive(t(translations.InvalidValue)),

    Reference: Yup.string().notRequired(),
    Remarks: Yup.string().notRequired(),
    Budget: BudgetEntitySechema.nullable().notRequired().optional(),
  });
}

export function foo(t: TFunction) {
  return InvoiceChildRowBaseSchema(t).shape({
    User: UserEntitySchema.nullable().when(
      'FeeType',
      (catRateType, schema: Yup.SchemaOf<Entity<string>>) =>
        [CatRateTypes.Refund, CatRateTypes.Other].includes(
          (catRateType as Entity<number>)?.Id,
        )
          ? schema.notRequired()
          : schema.required(),
    ),
    Resource: OnlineServiceEntitySchema.notRequired().nullable(),
    OtherService: OfflineServiceTypeEntitySchema.notRequired().nullable(),
    SampleType: OfflineServiceTypeEntitySchema.notRequired().nullable(),
    FeeType: FeeTypeEntitySchema.required().nullable(),
    Quantity: Yup.number().defined(),
    Rate: Yup.number()
      .defined()
      .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
        return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund ||
          (catRateType as Entity<number>)?.Id === CatRateTypes.Discount
          ? schema.negative()
          : schema.positive();
      }),
    Amount: Yup.number()
      .defined()
      .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
        return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund ||
          (catRateType as Entity<number>)?.Id === CatRateTypes.Discount
          ? schema.negative()
          : schema.positive();
      }),

    ServiceGroup: ServiceGroupEntitySchema.required().nullable(),
  });
}
