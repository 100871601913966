/**
 *
 * Asynchronously loads the component for ReservationDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.UserDetailsPage,
);
