import { IModificationsDto } from 'api/odata/generated/entities/IModificationsDto';
import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import DatesRangeFilter from 'app/components/BasicTable/Filters/DatesRangeFilter';
import {
  getDefaultFilterDatePeriod,
  presetDatesRangeToQueryString,
} from 'app/components/BasicTable/types/FilterParam';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { translations } from 'locales/translations';
import { PresetDatesRange } from 'types/PresetDatesRange';

export const GetFilters: GetPageFilters<IModificationsDto> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParams(search);
  const res: IFilterSettings<IModificationsDto>[] = [
    {
      fieldName: 'Date',
      displayNameKey: translations.Period,
      operator: ODataOperators.Between,
      defaultValue: PresetDatesRange.fromPreset(
        getPeriodTypesEntity('ThisMonth'),
      ),
      value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
      queryStringSerializer: presetDatesRangeToQueryString(
        'period',
        'st',
        'et',
      ),
      component: DatesRangeFilter,
      urlKey: 'period',
    },
  ];
  return res;
};
