import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { AuthenticatedUser, IAuthenticatedUser } from 'types/AuthenticatedUser';
import { SiteMapProvider } from 'types/SiteMapSettings';
import { dateUtils, parseLocale, WeekStartsOn } from 'utils/date-utils';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { UserProfile } from 'utils/userProfileSettings';
import { initialState } from '.';
import { UserRegistersToSpecificCoresType } from 'enums/enums';
import { appendTrailingSlash } from 'utils/url-utils';
import { KnownModules } from 'types/KnownModules';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { TFunction } from 'i18next';
import { Entity } from 'types/common';
import { pickBy } from 'lodash';
import { UserScreenType } from 'app/pages/UserDetails/Details/typeUtils';

const selectSlice = (state: RootState) => {
  return state.appSettings || initialState;
};
export const selectAppSettings = createSelector(
  [selectSlice],
  state => state.appSettings,
);

export const selectKnownModules = createSelector(
  [selectAppSettings],
  state => state?.Modules,
);
export const selectKnownModule = createSelector(
  [selectKnownModules, (state, knownModules: KnownModules) => knownModules],
  (state, knownModules: KnownModules) => state?.includes(knownModules) ?? false,
);

export const selectUseAmPm = createSelector([selectAppSettings], state => {
  return state?.Globalization?.UseAmPm;
});
export const selectSystemFirstDayOfWeek = createSelector(
  [selectAppSettings],
  (state): WeekStartsOn => {
    const DEFAULT_FIRST_DAY_OF_WEEK = 1;
    const firstDayOfWeek =
      state?.Globalization?.FirstDayOfWeek ?? DEFAULT_FIRST_DAY_OF_WEEK;
    if (firstDayOfWeek >= 0 && firstDayOfWeek <= 6) {
      return firstDayOfWeek as WeekStartsOn;
    } else {
      return DEFAULT_FIRST_DAY_OF_WEEK;
    }
  },
);
export const selectWebVersion = createSelector(
  [selectAppSettings],
  state => state?.WebVersion,
);
const DefaultUploadFileSizeLimit = 500000;
export const selectUploadFileSizeLimit = createSelector(
  [selectAppSettings],
  state => state?.UploadFileSizeLimit || DefaultUploadFileSizeLimit,
);
export const selectMinimizedBookitlabLogo = createSelector(
  [selectAppSettings],
  state => state?.MinimizedBookitlabLogo || false,
);
const defaultForbiddenFileExtensions = [
  '.dll',
  '.exe',
  '.vbs',
  '.js',
  '.ts',
  '.tsx',
  '.msi',
  '.com',
  '.bat',
];
export const selectForbiddenFileExtensions = createSelector(
  [selectAppSettings],
  state => state?.ForbiddenFileExtensions ?? defaultForbiddenFileExtensions,
);
export const selectLastCodeUpdate = createSelector(
  [selectAppSettings],
  state => state?.LastCodeUpdate,
);
export const selectAppTimeZone = createSelector(
  [selectAppSettings],
  state => state?.TimeZone,
);
export const selectPublicUrl = createSelector([selectAppSettings], state =>
  state?.PublicBaseUrl === undefined
    ? undefined
    : appendTrailingSlash(state.PublicBaseUrl),
);
export const selectRegisterUserGroup = createSelector(
  [selectAppSettings],
  state => state?.DefaultRegistrationGroup,
);
export const selectOffsetDate = createSelector(
  [selectAppTimeZone, (state, date: Date | string | undefined | null) => date],
  (timeZone, date) => {
    const systemOffset = timeZone?.TimezoneOffsetMinutes ?? 0;
    let correctDate =
      date === null || date === undefined
        ? dateUtils.dateOrStringToDate(new Date())
        : dateUtils.dateOrStringToDate(date);
    const currentDateOffset = correctDate.getTimezoneOffset();
    const dateOrNow = dateUtils.addMinutes(
      correctDate,
      systemOffset + currentDateOffset,
    );
    return dateOrNow;
  },
);
export const selectUserRegistersToSpecificCores = createSelector(
  [selectAppSettings],
  state =>
    (state?.UserRegistersToSpecificCores as UserRegistersToSpecificCoresType) ||
    UserRegistersToSpecificCoresType.UserRegistersToSpecificCores_NA,
);
export const selectEmailFromAddress = createSelector(
  [selectAppSettings],
  state => state?.EmailFromAddress,
);
export const selectExistingImages = createSelector(
  [selectAppSettings],
  state => state?.ExistingImages,
);

const constructAuthenticatedUser = (a?: IAuthenticatedUser) => {
  return a === undefined ? undefined : new AuthenticatedUser(a);
};
const selectAuthenticatedUserObject = createSelector(
  [selectSlice],
  state => state.authenticatedUser,
);
export const selectAuthenticatedUser = createSelector(
  [selectAuthenticatedUserObject],
  state => {
    const b = constructAuthenticatedUser(state);
    return b;
  },
);
export const selectSupportedLocales = createSelector(
  [selectAppSettings],
  state => state?.Globalization?.SupportedLocales ?? [],
);
export const selectDefaultLocaleId = createSelector(
  [selectAppSettings],
  state => state?.Globalization?.DefaultLocaleId,
);
export const selectLdapEnabled = createSelector(
  [selectAppSettings],
  state => state?.LdapEnabled ?? false,
);
export const selectCurrentLocale = createSelector(
  [selectSlice, selectAuthenticatedUser, selectDefaultLocaleId],
  (state, authenticatedUser, defaultLocaleId) => state.currentLocaleId,
);
export const selectCurrentLocaleIdentifier = createSelector(
  [selectCurrentLocale],
  state => {
    try {
      if (state === undefined) {
        return undefined;
      }
      return parseLocale(state);
    } catch (error) {
      return undefined;
    }
  },
);
export const selectCurrentLanguage = createSelector(
  [selectCurrentLocaleIdentifier],
  state => state?.language,
);

/**
 * Returns enabled asset attributes for current session (public/private)
 *  */
export const selectAssetFieldSettings = createSelector(
  [selectAppSettings, selectAuthenticatedUser],
  (appSettings, authenticatedUser) =>
    pickBy(
      appSettings?.AssetFieldSettings,
      (value, key) => value.IsPublic || authenticatedUser !== undefined,
    ),
);

export const selectAssetPopupSettings = createSelector(
  [selectAppSettings, selectAssetFieldSettings, selectAuthenticatedUser],
  (appSettings, assetFieldSettings, authenticatedUser) => {
    return Object.entries(assetFieldSettings).map(([key, value]) => ({
      Id: key,
      Visible: true,
      DisplayName: value.DisplayName,
    }));
  },
);
/**
 * Returns enabled user attributes for current user page
 *  */
export const selectEnabledUserFieldSettings = createSelector(
  [selectAppSettings],
  appSettings =>
    pickBy(
      appSettings?.UserFieldSettings,
      (value, key) => value.Disabled !== true,
    ),
);
export const selectUserFieldSettings = createSelector(
  [selectEnabledUserFieldSettings, (state, type: UserScreenType) => type],
  (userFieldSettings, type) => {
    return Object.entries(userFieldSettings)
      .filter(([key, value]) => value[type] === true)
      .map(([key, value]) => ({
        Id: key,
        Setting: value,
      }));
  },
);
export const selectIsFullScreen = createSelector(
  [selectSlice],
  state => state.isFullScreen,
);

export const selectLoading = createSelector([selectSlice], state => {
  let loading =
    state.isAuthenticated === true
      ? state.loadingAppSettings ||
        state.loadingSiteMapSettings ||
        state.loadingUserProfileSettings ||
        state.loadingSystemSettings
      : state.isAuthenticated === false
      ? state.loadingAppSettings || state.loadingSystemSettings
      : state.loadingAuthenticatedUser || state.loadingSystemSettings;
  return loading;
});
export const selectError = createSelector([selectSlice], state => state.error);
export const selectLoadingUser = createSelector(
  [selectSlice],
  state => state.loadingAuthenticatedUser,
);
export const selectNotifications = createSelector(
  [selectSlice],
  state => state.notifications,
);
const selectGlobalSettingsObj = createSelector(
  [selectSlice],
  state => state.systemSettings,
);
export const selectglobalSettings = createSelector(
  [selectGlobalSettingsObj],
  state => new GlobalSettings(state || ([] as SettingModel[])),
);
export const selectGlobalSetting = createSelector(
  [selectGlobalSettingsObj, (state, name: AllowedSettings) => name],
  (globalSettingsObj, name) => {
    const x = globalSettingsObj.find(
      f =>
        f.Name.trim().toLowerCase() ===
        AllowedSettings[name].trim().toLowerCase(),
    );
    return x?.Value ?? undefined;
  },
);
export const selectImageExist = createSelector(
  [selectExistingImages, (state, image: string | null) => image],
  (existingImages, image) => {
    let result = false;
    if (image === null) {
      return false;
    }
    if (!!existingImages && existingImages.length > 0) {
      result = existingImages.some(f => f === image);
    }
    return result;
  },
);
export const selectGlobalSettingBool = createSelector(
  [selectGlobalSettingsObj, (state, name: AllowedSettings) => name],
  (globalSettingsObj, name) => {
    const x = globalSettingsObj.find(
      f =>
        f.Name.trim().toLowerCase() ===
        AllowedSettings[name].trim().toLowerCase(),
    );
    return x?.Value === null ||
      x?.Value === undefined ||
      x.Value === '' ||
      x.Value.toLowerCase() === 'false'
      ? false
      : x.Value.toLowerCase() === 'true';
  },
);
export const selectSiteMapSettings = createSelector(
  [selectSlice],
  state => state.siteMapSettings,
);
export const selectSiteMapProvider = createSelector(
  [selectSlice],
  state => new SiteMapProvider(state.siteMapSettings),
);
export const selectEventsCount = createSelector(
  [selectSlice],
  state => state.eventsCount,
);
export const selectEventsCountLoading = createSelector(
  [selectSlice],
  state => state.loadingEventsCount,
);
export const selectUserProfileSettings = createSelector(
  [selectSlice],
  state => new UserProfile(state.userProfileSettings),
);
/**
 * Selects specific user profile setting value provided by the key
 * usage example:
 * const userProfileValue = useSelector(state => selectUserProfileSetting(state, "userProfileKey"))
 */
export const selectUserProfileSetting = createSelector(
  [selectUserProfileSettings, (state, key) => key],
  (state, key) => state.GetSettingByKey(key)?.Value,
);
export const selectIsExpanded = createSelector(
  [selectSlice],
  state => state.isExpanded,
);
export const selectIsSubBarCollapsed = createSelector(
  [selectSlice],
  state => state.subBarCollapsed,
);

export const selectUpdatedUserProfileSettings = createSelector(
  [selectSlice],
  state => state.updatedUserProfileSettings,
);
export const selectIsAuthenticated = createSelector(
  [selectSlice],
  state => state.isAuthenticated,
);
export const selectUpdatedExpandState = createSelector(
  [selectSlice],
  state => state.updatedExpandState,
);
export const selectIsExpandedSideBar = createSelector(
  [selectSlice, (state, isMobile) => isMobile],
  (state, isMobile) => {
    let result: boolean = false;
    if (isMobile) {
      result = state.isExpanded;
    } else {
      if (state.updatedExpandState) {
        result = state.isExpanded;
      } else {
        let sett = new UserProfile(state.userProfileSettings);
        let expanded = sett.GetSideBarExpanded('SideBarExpanded');
        result = expanded !== null ? expanded : true;
      }
    }
    return result;
  },
);
export const selectGlobalServiceGroupFilter = createSelector(
  [selectSlice],
  state => {
    let topServiceGroupsIsHidden = state.systemSettings.find(
      f => f.Id === 'HideTopServiceGroupFilter',
    );
    if (
      topServiceGroupsIsHidden !== null &&
      topServiceGroupsIsHidden !== undefined
    ) {
      if (topServiceGroupsIsHidden.Value === 'True') {
        return [];
      } else {
        return state.globalServiceGroupFilter;
      }
    } else {
      return state.globalServiceGroupFilter;
    }
  },
);
export const selectSpecificServiceGroupFilter = createSelector(
  [selectSlice],
  state => {
    let topServiceGroupsIsHidden = state.systemSettings.find(
      f => f.Id === 'HideTopServiceGroupFilter',
    );
    let hideDashboardSubMenu = state.systemSettings.find(
      f => f.Id === 'HideDashboardSubMenu',
    );
    if (
      topServiceGroupsIsHidden !== null &&
      topServiceGroupsIsHidden !== undefined
    ) {
      if (hideDashboardSubMenu !== null && hideDashboardSubMenu !== undefined) {
        if (hideDashboardSubMenu.Value === 'True') {
          return [];
        } else {
          return state.specificServiceGroupFilter;
        }
      } else {
        return state.specificServiceGroupFilter;
      }
    } else {
      return state.specificServiceGroupFilter;
    }
  },
);
export const selectAllCoresSelectableCount = createSelector(
  [selectSlice],
  state => state.allServiceGroupsSelectable?.length,
);
export const selectAllCoresSelectable = createSelector(
  [selectSlice],
  state => state.allServiceGroupsSelectable,
);
export const selectShowGlobalServiceGroupFilter = createSelector(
  [selectSlice],
  state => state.showGlobalServiceGroupFilter,
);
export const selectBarcodeScanTarget = createSelector(
  [selectSlice],
  state => state.barcodeScanTarget,
);
export const selectShowAllCores = createSelector(
  [selectSlice],
  state => state.showAllCores,
);
export const selectSkipLocationChanged = createSelector(
  [selectSlice],
  state => state.skipLocationChanged,
);
export const selectManualScanProcessing = createSelector(
  [selectSlice],
  state => state.manualScanProcessing,
);
/**
 * Selects reservation statuses enabled on the system level
 * ```
 * const statuses = useSelector(state => selectReservationStatuses(state, t));
 * ```
 */
export const selectReservationStatuses = createSelector(
  [
    selectSlice,
    state => selectKnownModule(state, KnownModules.UserGroupCoordRole),
    state =>
      selectGlobalSettingBool(state, AllowedSettings.AllowWorkflowBooking) &&
      selectGlobalSettingBool(
        state,
        AllowedSettings.MultipleTentativeReservationsUnderWorkflowBooking,
      ),
    state =>
      selectGlobalSettingBool(state, AllowedSettings.DraftReservationsEnabled),
    (state, t: TFunction) => t,
  ],
  (
    state,
    UserGroupCoordRole,
    AllowWorkflowBooking,
    DraftReservationsEnabled,
    t,
  ) => {
    const GetListItem = (s: ReservationStatus) => ({
      Id: s,
      Name: t(ReservationStatus[s]),
    });
    const list: Array<Entity<number>> = [];

    list.push(GetListItem(ReservationStatus.Approved));
    list.push(GetListItem(ReservationStatus.Pending));
    if (UserGroupCoordRole) {
      list.push(GetListItem(ReservationStatus.PendingCoord));
    }
    list.push(GetListItem(ReservationStatus.Cancelled));
    list.push(GetListItem(ReservationStatus.CancelledByAdmin));
    if (AllowWorkflowBooking) {
      list.push(GetListItem(ReservationStatus.Tentative));
    }
    if (DraftReservationsEnabled) {
      list.push(GetListItem(ReservationStatus.Draft));
    }
    return list;
  },
);
export const selectUsageEnabled = createSelector(
  [
    selectSlice,
    selectKnownModules,
    state =>
      selectGlobalSettingBool(state, AllowedSettings.RoomUserRoleEnabled),
  ],
  (state, knownModules, RoomUserRoleEnabled) => {
    return (
      knownModules?.includes(KnownModules.LocalApp) ||
      knownModules?.includes(KnownModules.Relay) ||
      knownModules?.includes(KnownModules.IpRelay) ||
      knownModules?.includes(KnownModules.WebRelayTm) ||
      knownModules?.includes(KnownModules.WebRelayX600m) ||
      knownModules?.includes(KnownModules.UsageForm) ||
      knownModules?.includes(KnownModules.LoanDesk) ||
      knownModules?.includes(KnownModules.DoorAccess) ||
      knownModules?.includes(KnownModules.NiDAQmx) ||
      knownModules?.includes(KnownModules.KmTronicRelay) ||
      knownModules?.includes(KnownModules.RaspberryPi) ||
      RoomUserRoleEnabled
    );
  },
);
