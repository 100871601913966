import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { ReservationDetailsProps } from '../ReservationDetails/Details';
import { ReservationQueryStringParameters } from '../ReservationDetails/Details/slice/types';
import { ITrainingSessionsRow } from './ITrainingSessionsRow';
import { dateUtils } from 'utils/date-utils';
import { IsReadOnlyUser } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';

export const trainingSessionPrimaryAction: PrimaryActionDynamic<ITrainingSessionsRow> = (
  openPanel,
  row,
  toggle,
  user,
  useSidePanel,
) => {
  if (!IsReadOnlyUser(user)) {
    const params = (row.original.hasReservation
      ? {
          id: String(row.original.myReservationId),
        }
      : {
          selectedIds: String(row.original.ServiceId),
          tsid: String(row.original.Id),
          Start: dateUtils.formatQueryStringDate(
            dateUtils.dateOrStringToDate(row.original.StartDate ?? new Date()),
          ),
          End: dateUtils.formatQueryStringDate(
            dateUtils.dateOrStringToDate(row.original.EndDate ?? new Date()),
          ),
          un: user?.Id,
        }) as ReservationQueryStringParameters;
    openPanel({
      renderPageType: RenderPageType.ReservationDetails,
      renderPageProps: {
        useSidePanel: true,
        queryParams: params,
      } as ReservationDetailsProps,
      useSidePanel: useSidePanel,
      expanded: false,
      isCover: useSidePanel,
    });
  }
};

export default trainingSessionPrimaryAction;
