import * as React from 'react';
import styled from 'styled-components';
import { StandardInput } from '../BaseInputs/StandardInput';
import clsx from 'clsx';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import { BaseFieldProps } from './BaseFieldDefinition';
import FilledInput from '../BaseInputs/FilledInput';
import { capitalize } from 'lodash';

export const styles = {
  /* Styles applied to the root element. */
  root: {
    '&.startIcon': {
      '& > label': {
        marginLeft: '24px',
      },
    },
  },
};
const BaseTetFieldControl = styled(FormControl)`
  &.base-text-field {
    &.startIcon {
      & > label {
        margin-left: 24px;
      }
    }
  }
`;
const variantComponent = {
  standard: StandardInput,
  filled: FilledInput,
  outlined: StandardInput,
};
export const BaseTextField = React.forwardRef<any, BaseFieldProps>(function (
  props,
  ref,
) {
  const {
    autoComplete,
    autoFocus = false,
    children,
    classes,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    hiddenLabel,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    focused,
    label,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    rowsMax,
    rowsMin,
    select = false,
    SelectProps,
    type,
    value,
    variant = 'filled',
    info,
    size = 'large',
    ...other
  } = props;

  let inputMore: any = {};
  if (variant === 'outlined') {
    if (InputLabelProps && typeof InputLabelProps.shrink !== 'undefined') {
      inputMore.notched = InputLabelProps.shrink;
    }
    if (label) {
      const displayRequired = InputLabelProps?.required ?? required;
      inputMore.label = (
        <React.Fragment>
          {label}
          {displayRequired && '\u00a0*'}
        </React.Fragment>
      );
    }
  }
  // if (info) {
  //   InputProps?.endAdornment = <InfoIcon title={info} />;
  // }
  if (select) {
    // unset defaults from textbox inputs
    if (!SelectProps || !SelectProps.native) {
      inputMore.id = undefined;
    }
    inputMore['aria-describedby'] = undefined;
  }
  const requiredPlaseholder = placeholder !== undefined && required && !label;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const InputComponent = variantComponent[variant];
  const sizeClass = `inputSize${capitalize(size ?? 'large')}`;
  const InputElement = (
    <InputComponent
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      // margin={sizeLarge ? 'none' : size === undefined ? 'dense' : 'none'}
      className={clsx(className, sizeClass, { noLabel: !label })}
      name={name}
      rows={rows}
      rowsMax={rowsMax}
      rowsMin={rowsMin}
      type={type}
      value={value}
      id={id}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={requiredPlaseholder ? placeholder + '\u00a0*' : placeholder}
      inputProps={inputProps}
      {...inputMore}
      {...InputProps}
    />
  );
  const LabelElement = (
    <InputLabel
      shrink={
        InputLabelProps?.shrink || variant === 'standard' ? true : undefined
      }
      htmlFor={id}
      id={inputLabelId}
      className={clsx(InputLabelProps?.className, sizeClass)}
      {...InputLabelProps}
    >
      {label}
    </InputLabel>
  );
  return (
    <BaseTetFieldControl
      className={clsx('base-text-field', classes?.root, className, sizeClass, {
        withLabel: !!label && (size === 'medium' || size === 'small'),
      })}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      ref={ref}
      required={required}
      focused={focused}
      color={color}
      variant={variant}
      size={'medium'}
      {...other}
    >
      {label && LabelElement}

      {select ? (
        <Select
          aria-describedby={helperTextId}
          id={id}
          labelId={inputLabelId}
          value={value}
          input={InputElement}
          variant={variant}
          className={clsx(SelectProps?.className, sizeClass)}
          {...SelectProps}
        >
          {children}
        </Select>
      ) : (
        InputElement
      )}

      {helperText && (
        <FormHelperText
          id={helperTextId}
          className={clsx(FormHelperTextProps?.className, sizeClass)}
          {...FormHelperTextProps}
        >
          {typeof helperText === 'string'
            ? helperText
            : JSON.stringify(helperText, null, 2)}
        </FormHelperText>
      )}
    </BaseTetFieldControl>
  );
});
export default BaseTextField;
//export default function BaseField(props: BaseFieldProps): JSX.Element;
