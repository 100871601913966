import * as React from 'react';
import { Body } from 'app/components/Typography';
import { FormFieldsBox } from '../wrappers';
import { useTheme } from '@material-ui/core';
import { capitalize } from 'lodash';
import clsx from 'clsx';

export interface FormFieldsSectionProps {
  children: React.ReactNode;
  titleSection?: string | React.ReactNode;
  titleLink?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  centered?: boolean;
  useRequest?: boolean;
}
export const FormFieldsSection = (props: FormFieldsSectionProps) => {
  const {
    children,
    titleSection,
    titleLink,
    maxWidth,
    centered,
    useRequest,
  } = props;
  const sectionMaxWidthClass = React.useMemo(() => {
    if (!maxWidth) {
      return '';
    }
    return `maxSectionWidth${capitalize(maxWidth)}`;
  }, [maxWidth]);
  const theme = useTheme();
  return (
    <FormFieldsBox
      theme={theme}
      className={clsx('fields-section', sectionMaxWidthClass, {
        centered: centered,
        'request-section': useRequest,
      })}
    >
      {titleSection && (
        <div className={'sectiontitle'}>
          <Body bold={true}>{titleSection}</Body>
          {titleLink || <></>}
        </div>
      )}

      <div className={`fields`}>{children}</div>
    </FormFieldsBox>
  );
};
