import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { samplePlatesDetailsActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import {
  ConvertEntityToModelSamplePlatesDetails,
  SamplePlatesDetailsQStringParameters,
  SamplePlatesDetailsResponse,
  SamplePlatesDetailsState,
} from './types';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { SamplePlatesDetailsProps } from '..';
import { SamplePlatesApi } from 'api/SamplePlatesApi';

function* doInitCreate(
  action: PayloadAction<SamplePlatesDetailsQStringParameters>,
) {
  try {
    const data = yield call(SamplePlatesApi.initCreateData, action.payload);
    yield put(actions.initSamplePlatesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initSamplePlatesDetails_Error(error));
  }
}

function* doInitUpdate(
  action: PayloadAction<SamplePlatesDetailsQStringParameters>,
) {
  try {
    const data = yield call(SamplePlatesApi.initUpdateData, action.payload);
    yield put(actions.initUpdateSamplePlatesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateSamplePlatesDetails_Error(Error));
  }
}

function* doCreate(action: PayloadAction<SamplePlatesDetailsState>) {
  const httpPayloads: ISamplePlateDto = ConvertEntityToModelSamplePlatesDetails(
    action.payload,
    undefined,
  );
  try {
    const result = yield call(
      SamplePlatesApi.insertSamplePlatesDetails,
      httpPayloads,
    );
    let response: SamplePlatesDetailsResponse = result as SamplePlatesDetailsResponse;

    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'samplePlatesInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'samplePlatesInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      if (response.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'samplePlatesInsert',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id,
              created: true,
              itemName: i18next.t(translations.SamplePlates),
              detailsType: RenderPageType.SamplePlatesDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.Id,
                } as SamplePlatesDetailsQStringParameters,
                useSidePanel: true,
              } as SamplePlatesDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'samplePlatesInsertSuccess',
            message: response.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }

    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.createSamplePlatesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'samplePlatesInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createSamplePlatesDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: SamplePlatesDetailsState;
    original: SamplePlatesDetailsState | undefined;
  }>,
) {
  const httpPayloads: ISamplePlateDto = ConvertEntityToModelSamplePlatesDetails(
    action.payload.current,
    action.payload.original,
  );
  try {
    const results = yield call(
      SamplePlatesApi.updateSamplePlatesDetails,
      action.payload.current.Id,
      httpPayloads,
    );
    let response = results as SamplePlatesDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'samplePlatesUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'samplePlatesUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'samplePlatesUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.SamplePlates),
            detailsType: RenderPageType.SamplePlatesDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as SamplePlatesDetailsQStringParameters,
              useSidePanel: true,
            } as SamplePlatesDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateSamplePlatesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'samplePlatesUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateSamplePlatesDetails_Error(Error));
  }
}

export function* samplePlatesDetailsSaga() {
  yield takeLatest(actions.initSamplePlatesDetails.type, doInitCreate);
  yield takeLatest(actions.initUpdateSamplePlatesDetails.type, doInitUpdate);
  yield takeLatest(actions.createSamplePlatesDetails.type, doCreate);
  yield takeLatest(actions.updateSamplePlatesDetails.type, doUpdate);
}
