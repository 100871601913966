import {
  BaseOptionsMultiPicker,
  BaseOptionsMultiPickerProps,
} from 'app/components/BasicPickers/BaseOptionsMultiPicker';
import { AssemblyTypesShort } from 'enums/InstrumentListAssemblyTypes';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface AssemblyTypeShortMultiPickerProps
  extends Omit<BaseOptionsMultiPickerProps<number>, 'getOptions'> {}

const options = (t: TFunction) => [
  { Id: AssemblyTypesShort.Normal, Name: t(translations.Normal) },
  { Id: AssemblyTypesShort.AssemblyParts, Name: t(translations.AssemblyParts) },
];

export const initAssemblyTypeShortData = (t: TFunction) => (
  initval: string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  }
  return Promise.resolve(
    options(t).filter(f =>
      initval
        .split('|')
        .map(s => parseInt(s))
        .includes(f.Id),
    ),
  );
};

export const AssemblyTypeShortMultiPicker = (
  props: AssemblyTypeShortMultiPickerProps,
) => {
  const { t } = useTranslation();
  const getOptions = useCallback(() => options(t), [t]);
  return <BaseOptionsMultiPicker getOptions={getOptions} {...props} />;
};
