/* eslint-disable prettier/prettier */
import { RowActionsProps } from 'app/components/BasicTable/RowActions';
import { Roles } from 'app/slice/types';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { isEmptyOrWhitespace, toLowerCaseSafely } from 'utils/typeUtils';
import {
  AuthenticatedUser,
  IAuthenticatedUser,
  isInRole,
} from './AuthenticatedUser';

export interface SiteMapSettings {
  userNodes: SiteMapNode[];
}
export enum MainTabs {
  Dashboard = 1,
  Cores = 2,
  Assets = 3,
  RoomMap = 4,
  Reservations = 5,
  Trainings = 6,
  Consumables = 7,
  Samples = 8,
  WorkOrders = 9,
  LoanDesk = 10,
  Eln = 11,
  Colonies = 12,
  Budgets = 13,
  Billing = 14,
  Messages = 15,
  Users = 16,
  Suppliers = 17,
  Expenses = 18,
  LargeAnimals = 19,
  Reports = 20,
  SystemSettings = 21,
}
export type MainTabsUnion = keyof typeof MainTabs;
export const MainTabsUnionArray: Array<MainTabsUnion> = [
  'Dashboard',
  'Cores',
  'Assets',
  'RoomMap',
  'Reservations',
  'Trainings',
  'Consumables',
  'Samples',
  'WorkOrders',
  'LoanDesk',
  'Eln',
  'Colonies',
  'Budgets',
  'Billing',
  'Messages',
  'Users',
  'Suppliers',
  'Expenses',
  'LargeAnimals',
  'Reports',
  'SystemSettings',
];
export enum AssetsSubTab {
  AssetsTabSetting = 1,
  InstrumentsTabSettings = 2,
  LocationsTabSettings = 3,
}
export type AssetsSubTabUnion = keyof typeof AssetsSubTab;
export const AssetsSubTabUnionArray: Array<AssetsSubTabUnion> = [
  'AssetsTabSetting',
  'InstrumentsTabSettings',
  'LocationsTabSettings',
];
export interface SiteMapNode {
  Roles?: string[];
  HasChildNodes: boolean;
  Key?: string;
  ResourceKey?: string;
  Title: string;
  Description?: string;
  Url: string;
  ChildNodes: SiteMapNode[];
  icon?: string | null;
  reactUrl: string;
  HideReact: boolean;
  isAdminNode: boolean;
  ParentNode: SiteMapNode | null;
  Hide: boolean;
  LinkWithChildren?: string | null;
  MainTab?: string;
  SubTab?: string;
  IsAdminSettings?: boolean;
  GroupTab?: number;
  IsLastTab?: boolean;
}
export const NoAuthNodes = (t: TFunction): SiteMapNode[] => {
  return [
    {
      Roles: [],
      HasChildNodes: false,
      Key: undefined,
      ResourceKey: undefined,
      Title: t(translations.reg_NewUserRegistration) as string,
      Description: undefined,
      Url: '/RegisterAccount',
      ChildNodes: [],
      icon: undefined,
      reactUrl: '/RegisterAccount',
      HideReact: false,
      isAdminNode: false,
      ParentNode: null,
      Hide: false,
      MainTab: undefined,
      SubTab: undefined,
      IsAdminSettings: false,
      GroupTab: undefined,
    },
    {
      Roles: [],
      HasChildNodes: false,
      Key: undefined,
      ResourceKey: undefined,
      Title: t(translations.menu_PublicAssets) as string,
      Description: undefined,
      Url: '/Assets/Public',
      ChildNodes: [],
      icon: undefined,
      reactUrl: '/Assets/Public',
      HideReact: false,
      isAdminNode: false,
      ParentNode: null,
      Hide: false,
      MainTab: undefined,
      SubTab: undefined,
      IsAdminSettings: false,
      GroupTab: undefined,
    },
    {
      Roles: [],
      HasChildNodes: false,
      Key: undefined,
      ResourceKey: undefined,
      Title: t(translations.Instrument) as string,
      Description: undefined,
      Url: '/Assets/Details',
      ChildNodes: [],
      icon: undefined,
      reactUrl: '/Assets/Details',
      HideReact: false,
      isAdminNode: false,
      ParentNode: null,
      Hide: false,
      MainTab: undefined,
      SubTab: undefined,
      IsAdminSettings: false,
      GroupTab: undefined,
    },
  ] as SiteMapNode[];
};

export class SiteMapProvider {
  settings: SiteMapSettings | null;
  constructor(sett: SiteMapSettings | null) {
    this.settings = sett;
  }
  public getUserNodes(): SiteMapNode[] {
    if (
      !!this.settings &&
      this.settings !== null &&
      !!this.settings.userNodes
    ) {
      return this.settings.userNodes;
    } else {
      return [] as SiteMapNode[];
    }
  }
  public getCurrentMainNode(pathname: string): SiteMapNode | undefined {
    let node: SiteMapNode[] | undefined = undefined;
    let searchItem = pathname;
    if (!!this.settings && this.settings != null) {
      node = this.settings.userNodes
        .filter(f => f.Hide === false)
        .filter((item, index) => {
          if (item.ChildNodes.length > 0) {
            let irurl = toLowerCaseSafely(item.reactUrl);

            return (
              item.ChildNodes.filter(chitem => {
                let rurl = toLowerCaseSafely(chitem.reactUrl);
                if (rurl !== null) {
                  let rpath = rurl.split('?')[0] ?? rurl;
                  return searchItem.toLowerCase().indexOf(rpath) > -1;
                } else {
                  return chitem.Url.toLowerCase().indexOf(searchItem) > -1;
                }
              }).length > 0 ||
              (irurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(irurl.split('?')[0] ?? irurl) > -1
                : item.Url.toLowerCase().indexOf(searchItem) > -1) === true
            );
          } else {
            let irurl = toLowerCaseSafely(item.reactUrl);
            let prurl =
              item.ParentNode !== null
                ? toLowerCaseSafely(item.ParentNode.reactUrl)
                : null;
            let purl = item.ParentNode !== null ? item.ParentNode.Url : null;
            return (
              (irurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(irurl.split('?')[0] ?? irurl) > -1
                : item.Url.toLowerCase().indexOf(searchItem) > -1) === true ||
              (item.ParentNode !== null && prurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(prurl.split('?')[0] ?? prurl) > -1
                : purl !== null
                ? purl.toLowerCase().indexOf(searchItem) > -1
                : false)
            );
          }
        });
    }
    // There may be multiple results.
    // This happens when the react page is selected.
    // It is necessary to choose the result whose reactUrl length is longer.
    if (node !== undefined && node.length > 0) {
      let res: SiteMapNode | undefined = node[0];
      if (node.length > 1) {
        var rurl = '';
        node.forEach(item => {
          item.ChildNodes.forEach(chitem => {
            let rurl1 = toLowerCaseSafely(chitem.reactUrl);
            if (rurl1 !== null) {
              let rpath = rurl1.split('?')[0] ?? rurl1;
              if (searchItem.toLowerCase().indexOf(rpath) > -1) {
                if (rpath.length > rurl.length) {
                  res = item;
                  rurl = rpath;
                }
              }
            }
          });
        });
      }
      return res;
    } else {
      return undefined;
    }
  }
  public currentMainNodeIsAdmin(pathname: string): boolean | undefined {
    let isAdmin = this.getCurrentMainNode(pathname)?.isAdminNode;
    return isAdmin;
  }
  /**
   * Returns visible sitemap nodes
   * @returns Visible sitemap nodes
   */
  public getCurrentNodes(): SiteMapNode[] {
    return this.getUserNodes().filter(f => f.Hide === false);
  }
  public findNode(pathname: string): SiteMapNode | undefined {
    let node: SiteMapNode | undefined = undefined;
    if (!!this.settings && this.settings != null) {
      let parentNodes = this.settings.userNodes;
      let childNodes = this.settings.userNodes.map(f => f.ChildNodes).flat();
      const currentPathNameLowered = pathname.toLowerCase();
      // first try exact match node
      node = childNodes.find(element => {
        const elementURL = element.reactUrl ?? element.Url;
        const [elementPathName] = elementURL.split('?');
        return currentPathNameLowered === elementPathName?.toLowerCase();
      });
      // then if no exact match was found - try the indexOf for whatever reason it was done originally
      if (!node) {
        childNodes.find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);

            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              //return pathname.toLowerCase() === rpath;
              return pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(pathname.toLowerCase()) > -1
              );
            }
          }
          return undefined;
        });
      }
      if (!node) {
        node = parentNodes.find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);
            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              //return pathname.toLowerCase() === rpath;
              return pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(pathname.toLowerCase()) > -1
              );
            }
          }
          return undefined;
        });
      }
    }
    return node;
  }
  public updatePath(pathname: string | undefined, newSearch: string) {
    if (!!this.settings && this.settings != null && pathname) {
      this.getCurrentNodes()
        .map(f => f.ChildNodes)
        .flat()
        .forEach(element => {
          if (element !== undefined) {
            const reactUrl = element.reactUrl;
            if (reactUrl !== undefined && reactUrl !== null) {
              const elementPath = reactUrl.split('?')[0] ?? reactUrl;
              if (elementPath === pathname) {
                element.reactUrl = `${pathname}?${newSearch}`;
                element.Url = `${pathname}?${newSearch}`;
              }
            }
          }
        });
    }
  }
  public accesibleByRole(pathname: string, user?: IAuthenticatedUser): boolean {
    if (user) {
      let currentNode = this.findNode(pathname);
      if (currentNode) {
        if (currentNode.Roles && currentNode.Roles.length > 0) {
          return (
            currentNode.Roles.filter(item => isInRole(user, item)).length > 0
          );
        } else if (
          currentNode.ParentNode !== null &&
          currentNode.ParentNode.Roles &&
          currentNode.ParentNode.Roles.length > 0
        ) {
          return (
            currentNode.ParentNode.Roles.filter(item => isInRole(user, item))
              .length > 0
          );
        }
      }
    }
    return false;
  }
  public nodeAccesibleByRole(
    node: SiteMapNode,
    user?: AuthenticatedUser,
  ): boolean {
    if (user) {
      if (node) {
        if (node.Roles && node.Roles.length > 0) {
          if(user?.Roles.includes(Roles.CustomRole) && node.Roles.filter(item => isInRole(user, item)).length > 0){
            return user?.HasCustomRoleUrlPermission(node.Url);
          }
          return node.Roles.filter(item => isInRole(user, item)).length > 0;
        } else if (
          node.ParentNode !== null &&
          node.ParentNode.Roles &&
          node.ParentNode.Roles.length > 0
        ) {
          if(user?.Roles.includes(Roles.CustomRole) && node.ParentNode.Roles.filter(item => isInRole(user, item)).length > 0){
            return user?.HasCustomRoleUrlPermission(node.ParentNode.Url);
          }
          return (
            node.ParentNode.Roles.filter(item => isInRole(user, item)).length > 0
          );
        } else {
          return true;
        }
      }
    }
    return false;
  }
  public findMainTabNode(menuTab: MainTabsUnion) {
    let filtered = this.getUserNodes().filter(
      f => f.Hide === false && !!f.MainTab && f.MainTab === menuTab,
    );
    if (!!filtered && filtered.length > 0) {
      return filtered[0];
    }
    return undefined;
  }
  public adminSettingsNodes(node: SiteMapNode, user?: AuthenticatedUser) {
    let result: SiteMapNode[] = [];
    const isAdmin =
      isInRole(user, Roles.Administrators) ||
      isInRole(user, Roles.GroupAdministrators) ||
      isInRole(user, Roles.EquipmentAdministrator) ||
      isInRole(user, Roles.CustomRole);
    if (
      isAdmin &&
      node.ChildNodes.length > 0 &&
      !!node.MainTab &&
      node.ChildNodes.some(f => f.IsAdminSettings)
    ) {
      let filtered = node.ChildNodes.filter(
        f => f.IsAdminSettings && this.nodeAccesibleByRole(f, user),
      ).map((it, index) => {
        return it;
      });
      return filtered;
    }
    return result;
  }
  public adminSettingsActions(
    menuTab: MainTabsUnion,
    user?: AuthenticatedUser,
    subTab?: any,
    action?: (link: string) => void,
    t: TFunction = s => s,
  ) {
    let result: RowActionsProps[] = [];
    const isAdmin =
      isInRole(user, Roles.Administrators) ||
      isInRole(user, Roles.GroupAdministrators) ||
      isInRole(user, Roles.EquipmentAdministrator) ||
      isInRole(user, Roles.CustomRole);
    let node = this.findMainTabNode(menuTab);
    if (
      isAdmin &&
      !!node &&
      node.ChildNodes.length > 0 &&
      !!node.MainTab &&
      node.ChildNodes.some(f => f.IsAdminSettings)
    ) {
      let filtered = node.ChildNodes.filter(
        f =>
          f.IsAdminSettings &&
          this.nodeAccesibleByRole(f, user) &&
          (!subTab || (!isEmptyOrWhitespace(subTab) && subTab === f.SubTab)),
      ).map((it, index) => {
        return it;
      });
      if (!!filtered && filtered.length > 0) {
        let subTabTitle =
          filtered.filter(f => !!f.SubTab).map(f => f.SubTab)?.[0] ??
          translations.Settings;
        result.push({
          text: t(subTabTitle),
          icon: 'circle',
          renderAsTitle: true,
        });
        filtered.forEach((item, index) => {
          result.push({
            href:
              !action && isEmptyOrWhitespace(item.reactUrl)
                ? item.Url
                : undefined,
            to: !isEmptyOrWhitespace(item.reactUrl) ? item.reactUrl : undefined,
            text: t(item.Title) as string,
            icon: 'circle-small',
            onClick:
              !!action && isEmptyOrWhitespace(item.reactUrl)
                ? () => action(item.Url)
                : undefined,
          } as RowActionsProps);
        });
      }
    }
    return result;
  }
}
