/**
 *
 * AccompaniedServicesPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { InputBaseComponentProps } from '@material-ui/core';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { AutocompletePicker } from 'app/components/BasicPickers/AutocompletePicker';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
export interface AccompaniedServicesPickerProps
  extends Omit<
    AutocompleteMultiPickerExProps<IEquipmentAccServiceDto>,
    'loadData'
  > {
  inputProps?: InputBaseComponentProps;
}
const url = '/api/odata/v4/AccompaniedServices';
export const initAccompaniedData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = (initval.indexOf(',') !== -1
      ? initval.split(',')
      : [initval]
    ).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];
    let foo: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: string | Condition<IEquipmentAccServiceDto>[] = [];

      predicates.push(new Condition('Id', ODataOperators.Includes, ids));

      foo = predicates.map(f => f.toString()).join(' and ');
    }

    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $select: string;
    } = {
      $orderby: 'Name asc',
      $filter: foo,
      $skip: 0,
      $top: 10,
      $select: [
        'Id',
        'Name',
        'Active',
        'CustomFormId',
        'EquipmentId',
        'EquipmentName',
        'OverrideTraining',
        'StaffOnly',
        'ServiceGroupId',
        'Color',
        'InstrumentCatId',
        'InstrumentCatName',
        'MaxOrderDuration',
        'MinOrderDuration',
        'MinOrderGap',
        'Inventory',
      ].join(','),
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IEquipmentAccServiceDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<IEquipmentAccServiceDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'Active',
      'CustomFormId',
      'EquipmentId',
      'EquipmentName',
      'OverrideTraining',
      'StaffOnly',
      'ServiceGroupId',
      'Color',
      'InstrumentCatId',
      'InstrumentCatName',
      'MaxOrderDuration',
      'MinOrderDuration',
      'MinOrderGap',
      'Inventory',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IEquipmentAccServiceDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
export function AccompaniedServicesPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  ...props
}: AccompaniedServicesPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(predicates, globalServiceGroupFilter || []);
  return (
    <AutocompleteMultiPickerEx
      variant={variant}
      loadData={innerLoadData}
      onChange={onChange}
      disabled={disabled}
      info={info}
      id={id || 'acc-services-multi-picker'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue ?? []}
      label={label}
      value={value ?? []}
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
export function SingleAccompaniedServicesPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  ...props
}: AccompaniedServicesPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(predicates, globalServiceGroupFilter || []);
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      variant={variant}
      onChange={value => {
        if (!!onChange) {
          if (value !== null) {
            return onChange([value]);
          } else {
            return onChange([]);
          }
        }
      }}
      disabled={disabled}
      info={info}
      id={id || 'acc-service-single-picker'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={
        !!defaultValue
          ? defaultValue.length > 0
            ? (defaultValue[0] as IEquipmentAccServiceDto | undefined | null)
            : undefined
          : undefined
      }
      label={label}
      value={
        !!value
          ? value.length > 0
            ? (value[0] as IEquipmentAccServiceDto | undefined | null)
            : undefined
          : undefined
      }
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
export function ReservationsAccServicesFilter(
  Equipments: number[],
): (string | Condition<IEquipmentAccServiceDto>)[] {
  const predicates: (string | Condition<IEquipmentAccServiceDto>)[] = [];
  if (Equipments.length > 0) {
    let ids = Equipments.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IEquipmentAccServiceDto>(
        'EquipmentId',
        ODataOperators.Includes,
        ids,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}

export const loadEquipments = loadData;
