import { FormikErrors } from 'formik';
import styled from 'styled-components';
import { IServiceRequestDetailsFormModel } from '../slice/types';
import * as React from 'react';
import { Caption } from 'app/components/Typography';

export interface RequestErrorsSummuryProps {
  errors: FormikErrors<IServiceRequestDetailsFormModel>;
}
export interface FormValuesErrorsSummuryProps {
  errors?: FormikErrors<Pick<IServiceRequestDetailsFormModel, 'FormValues'>>;
}
export interface RowsErrorsSummuryProps {
  errors?: FormikErrors<Pick<IServiceRequestDetailsFormModel, 'Rows'>>;
}
const SummuryBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export function RequestErrorsSummury(props: RequestErrorsSummuryProps) {
  const data = React.useMemo(() => {
    return Object.entries(props.errors).map(f => {
      return { name: f[0], msg: f[1] };
    });
  }, [props.errors]);
  const formValuesErrors = React.useMemo(() => {
    if (data.length === 0) return [];
    let formValuesData = data.find(f => f.name === 'FormValues');
    if (!!formValuesData) {
      if (Array.isArray(formValuesData.msg)) {
        return (formValuesData.msg as Array<string>)
          .filter(f => !!f)
          .map((msgs, index) => {
            return { error: msgs, indx: 'FormValues' + index };
          });
      } else {
        return [{ error: formValuesData.msg, indx: 'FormValues' }];
      }
    }
    return [];
  }, [data]);
  const rowsErrors = React.useMemo(() => {
    if (data.length === 0) return [];
    let rowsData = data.find(f => f.name === 'Rows');
    if (!!rowsData) {
      if (Array.isArray(rowsData.msg)) {
        if ((rowsData.msg as Array<any>).length === 0) return [];
        if ((rowsData.msg as Array<any>)[0] === null) return [];
        if ((rowsData.msg as Array<any>)[0] === undefined) return [];
        let valsObj = (rowsData.msg as Array<any>)[0].Values as any;
        return Object.entries(valsObj).map(([key, value]) => {
          return { error: value as string, indx: key };
        });
      } else {
        return [{ error: rowsData.msg, indx: 'Rows' }];
      }
    }
    return [];
  }, [data]);
  return formValuesErrors.length > 0 || rowsErrors.length > 0 ? (
    <SummuryBox>
      {formValuesErrors.length > 0 &&
        formValuesErrors.map(item => (
          <Caption color="error" key={item.indx}>
            {item.error}
          </Caption>
        ))}
      {rowsErrors.length > 0 &&
        rowsErrors.map(item => (
          <Caption color="error" key={item.indx}>
            {`Rows: ${item.error}`}
          </Caption>
        ))}
    </SummuryBox>
  ) : null;
}
export function FormValuesErrorsSummury(props: FormValuesErrorsSummuryProps) {
  const data = React.useMemo(() => {
    if (!props.errors) return undefined;
    return Object.entries(props.errors)
      .filter(f => f[0] === 'FormValues')
      .map(f => {
        return { name: f[0], msg: f[1] };
      })[0];
  }, [props.errors]);
  const formValuesErrors = React.useMemo(() => {
    if (!!data) {
      if (Array.isArray(data.msg)) {
        return (data.msg as Array<string>)
          .filter(f => !!f)
          .map((msgs, index) => {
            return { error: msgs, indx: 'FormValues' + index };
          });
      } else {
        return [{ error: data.msg, indx: 'FormValues' }];
      }
    }
    return [];
  }, [data]);
  return formValuesErrors.length > 0 ? (
    <SummuryBox>
      {formValuesErrors.length > 0 &&
        formValuesErrors.map(item => (
          <Caption color="error" key={item.indx}>
            {item.error}
          </Caption>
        ))}
    </SummuryBox>
  ) : null;
}
export function RowsErrorsSummury(props: RowsErrorsSummuryProps) {
  const data = React.useMemo(() => {
    if (!props.errors) return undefined;
    return Object.entries(props.errors)
      .filter(f => f[0] === 'Rows')
      .map(f => {
        return { name: f[0], msg: f[1] };
      })[0];
  }, [props.errors]);

  const rowsErrors = React.useMemo(() => {
    if (!!data && data.msg) {
      if (Array.isArray(data.msg)) {
        if ((data.msg as Array<any>)[0] === null) return [];
        if ((data.msg as Array<any>)[0] === undefined) return [];
        if ((data.msg as Array<any>).length === 0) return [];
        let valsObj = (data.msg as Array<any>)[0].Values as any;
        if (!valsObj) return [];
        return Object.entries(valsObj).map(([key, value]) => {
          return { error: value as string, indx: key };
        });
      } else {
        return [{ error: data.msg, indx: 'Rows' }];
      }
    }
    return [];
  }, [data]);
  return rowsErrors.length > 0 ? (
    <SummuryBox>
      {rowsErrors.length > 0 &&
        rowsErrors.map(item => (
          <Caption color="error" key={item.indx}>
            {`Rows: ${item.error}`}
          </Caption>
        ))}
    </SummuryBox>
  ) : null;
}
