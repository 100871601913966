import { CheckEntity } from 'app/components/CheckBoxGroup';
import { useSystemDate } from 'app/hooks/useSystemDate';
import {
  RepeatMonthlyBy,
  RepeatPeriod,
  RepetitiveOptions,
} from 'enums/RepetitiveOptions';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { IRepetitiveDto, ShortWeekDays } from 'types/IRepetitiveDto';
import { dateUtils } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { FormBookitDatePicker } from '../FormBookitDatePicker';
import { FormCheckBoxGroup } from '../FormCheckBoxGroup';
import { FormEveryDaysPicker } from '../FormEveryDaysPicker';
import {
  FieldHandler,
  FormFieldProps,
  FormikSection,
  FormListener,
  IFieldSection,
} from '../FormRender/FormRenderer';
import { FormRepeatMonthlyByPicker } from '../FormRepeatMonthlyByPicker';
import { FormRepeatPeriodPicker } from '../FormRepeatPeriodPicker';
import { FormRepetitiveOptionsPicker } from '../FormRepetitiveOptionsPicker';
import { RepetitiveSchema } from '../Schemas/RepititiveSchema';
import { useRepetitiveSlice } from './slice';
import { selectRepetitiveFieldHandler } from './slice/selectors';
import { RepetitiveFor } from './slice/types';

export interface FormRepetitiveProps {
  onSubmit: (item: IRepetitiveDto) => void;
  RepetitiveData?: IRepetitiveDto;
  processing?: boolean;
  bindSubmitForm: any;
  StartDate?: Date;
  title: string | undefined;
  type: RepetitiveFor;
  disabled?: boolean;
  formIsValid?: (isValid: boolean) => void;
  withTopSeparator?: boolean;
  withBottomSeparator?: boolean;
  onFormChange?: (
    values: IRepetitiveDto,
    isValid: boolean,
    dirty: boolean,
    setValue?: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => void;
  setTouched?: (dirty: boolean) => void;
}

export function RepetitiveForm({
  onSubmit,
  processing,
  RepetitiveData,
  bindSubmitForm,
  StartDate,
  title,
  type,
  disabled,
  withTopSeparator,
  withBottomSeparator,
  formIsValid,
  setTouched,
}: FormRepetitiveProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRepetitiveSlice();
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };
  const { newDate } = useSystemDate();
  const [recurringChanged, setRecurringChanged] = React.useState<boolean>(
    false,
  );
  const [repeatPeriodChanged, setRepeatPeriodChanged] = React.useState<boolean>(
    false,
  );
  const [repeatEndChanged, setRepeatEndChanged] = React.useState<boolean>(
    false,
  );
  const fieldHandler = useSelector(selectRepetitiveFieldHandler);
  const reserFieldHandler = () => {
    dispatch(actions.resetRepetitiveHandler());
  };
  const schema = React.useMemo(() => RepetitiveSchema(t), [t]);

  const handleFormChange = React.useCallback(
    (values: IRepetitiveDto, isValid, dirty, setValue) => {
      if (recurringChanged) {
        if (
          !!values.RecurringEvents &&
          values.RecurringEvents.Id ===
            (RepetitiveOptions.RepeatCustom as number)
        ) {
          setValue('RepeatsEveryDay', { Id: 1, Name: '1' }, false);
          setValue(
            'RepeatsEveryPeriod',
            enumToEntityArray(RepeatPeriod, t).filter(f => f.Id === 2)[0],
            false,
          );
          setValue(
            'RepeatsOnDays',
            ShortWeekDays().map(f => {
              if (f.Id === 1) f.checked = true;
              return f;
            }),
            false,
          );
          setValue('RepeatsMonthlyBy', null, false);
        }
        dispatch(
          actions.saveState({
            StartDate:
              values.StartDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.StartDate),
                  ),
            RecurringEvents: values.RecurringEvents,
            RepeatsOnDays: values.RepeatsOnDays,
            RecurringEndDate:
              values.RecurringEndDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.RecurringEndDate),
                  ),
            RepeatsEveryDay: values.RepeatsEveryDay,
            RepeatsEveryPeriod: values.RepeatsEveryPeriod,
            RepeatsMonthlyBy: values.RepeatsMonthlyBy,
          } as IRepetitiveDto),
        );
        setRecurringChanged(false);
      }
      if (repeatPeriodChanged) {
        let period = values.RepeatsEveryPeriod;
        if (period !== null && period !== undefined) {
          if (period.Id === (RepeatPeriod.Days as number)) {
            setValue('RepeatsMonthlyBy', null, false);
            setValue(
              'RepeatsOnDays',
              ShortWeekDays().map(f => {
                if (f.Id === 1) f.checked = true;
                return f;
              }),
              false,
            );
          } else if (period.Id === (RepeatPeriod.Weeks as number)) {
            setValue('RepeatsMonthlyBy', null, false);
            setValue(
              'RepeatsOnDays',
              ShortWeekDays().map(f => {
                if (f.Id === 1) f.checked = true;
                return f;
              }),
              false,
            );
          } else if (period.Id === (RepeatPeriod.Months as number)) {
            setValue(
              'RepeatsMonthlyBy',
              enumToEntityArray(RepeatMonthlyBy, t)[0],
              false,
            );
            setValue(
              'RepeatsOnDays',
              ShortWeekDays().map(f => {
                if (f.Id === 1) f.checked = true;
                return f;
              }),
              false,
            );
          }
        }
        dispatch(
          actions.saveState({
            StartDate:
              values.StartDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.StartDate),
                  ),
            RecurringEvents: values.RecurringEvents,
            RepeatsOnDays: values.RepeatsOnDays,
            RecurringEndDate:
              values.RecurringEndDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.RecurringEndDate),
                  ),
            RepeatsEveryDay: values.RepeatsEveryDay,
            RepeatsEveryPeriod: values.RepeatsEveryPeriod,
            RepeatsMonthlyBy: values.RepeatsMonthlyBy,
          } as IRepetitiveDto),
        );
        setRepeatPeriodChanged(false);
      }
      if (repeatEndChanged) {
        dispatch(
          actions.saveState({
            StartDate:
              values.StartDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.StartDate),
                  ),
            RecurringEvents: values.RecurringEvents,
            RepeatsOnDays: values.RepeatsOnDays,
            RecurringEndDate:
              values.RecurringEndDate === undefined
                ? undefined
                : dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(values.RecurringEndDate),
                  ),
            RepeatsEveryDay: values.RepeatsEveryDay,
            RepeatsEveryPeriod: values.RepeatsEveryPeriod,
            RepeatsMonthlyBy: values.RepeatsMonthlyBy,
          } as IRepetitiveDto),
        );
        setRepeatEndChanged(false);
      }
    },
    [
      actions,
      dispatch,
      recurringChanged,
      repeatEndChanged,
      repeatPeriodChanged,
      t,
    ],
  );
  const leftSideSections = (
    values: IRepetitiveDto,
    isSubmitting: boolean,
    title: string | undefined,
  ): IFieldSection<IRepetitiveDto>[] => {
    return [
      {
        title: title,
        order: 1,
        withBottomSeparator: withBottomSeparator,
        withTopSeparator: withTopSeparator,
        fields: [
          {
            id: 'RecurringEvents',
            name: 'RecurringEvents',
            startDate: dateUtils.dateOrStringToDate(
              values.StartDate || newDate(),
            ),
            label: undefined,
            ariaLabel:
              type === RepetitiveFor.WorkOrder
                ? t(translations.RecurringEvents)
                : type === RepetitiveFor.Reservations
                ? t(translations.RepetitiveReservation)
                : t(translations.Repeat),
            disabled: isSubmitting || disabled,
            disableClearable: true,
            onChange: () => setRecurringChanged(true),
            fullWidth: true,
            component: FormRepetitiveOptionsPicker,
            order: 1,
          } as FormFieldProps<Entity<number> | null>,
          {
            minRow: true,
            hide:
              values.RecurringEvents?.Id ===
              (RepetitiveOptions.DoNotRepeat as number),
            id: 'RecurringEndDate',
            name: 'RecurringEndDate',
            label: t(translations.Until),
            disabled: isSubmitting || disabled,
            fullWidth: true,
            component: FormBookitDatePicker,
            onChange: () => setRepeatEndChanged(true),
            order: 2,
          } as FormFieldProps<Date | null>,
          {
            hide:
              values.RecurringEvents?.Id !==
              (RepetitiveOptions.RepeatCustom as number),
            id: 'RepeatsEveryDay',
            name: 'RepeatsEveryDay',
            label: t(translations.RepeatsEvery),
            disabled: isSubmitting || disabled,
            disableClearable: true,
            fullWidth: true,
            component: FormEveryDaysPicker,
            order: 3,
          } as FormFieldProps<Entity<number> | null>,
          {
            hide:
              values.RecurringEvents?.Id !==
              (RepetitiveOptions.RepeatCustom as number),
            id: 'RepeatsEveryPeriod',
            name: 'RepeatsEveryPeriod',
            label: t(translations.RepeatsEveryPeriod),
            onChange: () => setRepeatPeriodChanged(true),
            disabled: isSubmitting || disabled,
            disableClearable: true,
            fullWidth: true,
            component: FormRepeatPeriodPicker,
            order: 4,
          } as FormFieldProps<Entity<number> | null>,
          {
            hide:
              values.RecurringEvents?.Id !==
                (RepetitiveOptions.RepeatCustom as number) ||
              values.RepeatsEveryPeriod?.Id !== (RepeatPeriod.Months as number),
            id: 'RepeatsMonthlyBy',
            name: 'RepeatsMonthlyBy',
            label: t(translations.RepeatsBy),
            disabled: isSubmitting || disabled,
            disableClearable: true,
            fullWidth: true,
            component: FormRepeatMonthlyByPicker,
            order: 5,
          } as FormFieldProps<Entity<number> | null>,
          {
            fullRow: true,
            hide:
              values.RecurringEvents?.Id !==
                (RepetitiveOptions.RepeatCustom as number) ||
              values.RepeatsEveryPeriod?.Id !== (RepeatPeriod.Weeks as number),
            id: 'RepeatsOnDays',
            name: 'RepeatsOnDays',
            label: t(translations.RepeatsOn),
            options: values.RepeatsOnDays,
            disabled: isSubmitting || disabled,
            fullWidth: true,
            component: FormCheckBoxGroup,
            order: 6,
          } as FormFieldProps<CheckEntity[]>,
        ],
        item: values,
      } as IFieldSection<IRepetitiveDto>,
    ];
  };
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={RepetitiveData || ({} as IRepetitiveDto)}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('submitting', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          // if (formik.errors) {
          //   console.log('Repetitive errors', formik.errors);
          // }
          if (formIsValid) {
            formIsValid(formik.isValid);
          }
          setTouched?.(formik.dirty);
          return (
            <>
              <form id="nestedForm" onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={[
                    'RecurringEvents',
                    'RepeatsEveryPeriod',
                    'RecurringEndDate',
                  ]}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={reserFieldHandler}
                />
                <React.Fragment>
                  {leftSideSections(formik.values, formik.isSubmitting, title)
                    .sort((a, b) => a.order - b.order)
                    .map((section, index) => (
                      <FormikSection
                        {...section}
                        key={`${section.order}-${index}`}
                      />
                    ))}
                </React.Fragment>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
  // return (
  //   <FormikWrapper
  //     bindSubmitForm={bindSubmitForm}
  //     initialValues={RepetitiveData || ({} as IRepetitiveDto)}
  //     onSubmit={handleSubmit}
  //     onFormChange={handleFormChange}
  //     observedFields={['RecurringEvents', 'RepeatsEveryPeriod']}
  //     schema={RepetitiveShema}
  //     rightSideSections={undefined}
  //     avoidLeftSection={true}
  //     avoidRightSection={true}
  //     avoidStyledForm={true}
  //     leftSideSections={leftSideSections}
  //     id="repetetive"
  //   />
  // );
}
