import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import {
  EquipmentsChangeStateParameters,
  getSingleReservationStatus,
  IMandatoryEquipmentsDto,
  IReservationEquipmentDto,
  PrerequisitsResult,
  ReservationDetailsState,
  ReservationQueryStringParameters,
  ReservationSettingsState,
  ReservationValidationResult,
  TrainingSessionResult,
  UserTrainingReservationData,
} from '../slice/types';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { translations } from 'locales/translations';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAsyncChangeLoading,
  selectCloneReservations,
  selectEquipmentChangeCompleted,
  selectEquipmentChangeState,
  selectEquipmentsSettings,
  selectFieldHandler,
  selectIsCreditSubmited,
  selectIsEdit,
  selectRequestedValueFor,
  selectReservationCompleted,
  selectReservationHasError,
  selectReservationSettings,
  selectSavedForLater,
  selectSavedOfflineServices,
} from '../slice/selectors';
import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { IndexNode } from 'app/components/Forms/FormsLayout/StyledForm';
import {
  FieldHandler,
  FieldValue,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { FormReservationEquipmentsPicker } from 'app/components/Forms/FormReservationEquipmentsPicker';
import { FormReservationStatusPicker } from 'app/components/Forms/FormReservationStatusPicker';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormBudgetPicker } from 'app/components/Forms/FormBudgetPicker';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { ServicesFilter } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { RepetitiveControl } from 'app/components/Forms/FormRepetitive/RepetitiveControl';
import { dateUtils } from 'utils/date-utils';
import { FormRestrictedUserGroupPicker } from 'app/components/Forms/FormRestrictedUserGroupPicker';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { RestrictedFilter } from 'app/components/pickers/AutocompletePickers/UserGroupPicker';
import { restrictedBudgetFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Box } from '@material-ui/core';
import { ReservationCustomForm } from '../components/ReservationCustomForm';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import {
  formValueSchema,
  IFormFileValue,
} from 'app/components/CustomForm/CustomFormUtils';
import { RepetitiveFor } from 'app/components/Forms/FormRepetitive/slice/types';
import { Entity } from 'types/common';
import {
  CheckEntityShema,
  EntityNumberSchema,
  EntityStringSchema,
} from 'app/components/Forms/Schemas';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  updateFormValues,
  validateAccServices,
  validateReservation,
} from '../components/utils';
import { useReservationSlice } from '../slice';
import {
  initEquipmentsData,
  ReservationsEquipmentFilter,
} from 'app/components/pickers/MultiSelectPickers/EquipmentReservationPicker';
import { Caption } from 'app/components/Typography';
import { FormSwitch } from 'app/components/Forms/Switch';
import { Body } from 'app/components/Typography';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { Button } from 'app/components/BasicButtons/Button';
import { BudgetListener } from '../components/FormFieldListeners/BudgetListener';
import { UserNameListener } from '../components/FormFieldListeners/UserNameListener';
import { UserGroupListener } from '../components/FormFieldListeners/UserGroupListener';
import { StartTimeListener } from '../components/FormFieldListeners/StartTimeListener';
import { EndTimeListener } from '../components/FormFieldListeners/EndTimeListener';

import {
  FormChipCheckBoxGroup,
  FormChipLabelGroup,
} from 'app/components/Forms/FormChipsGroup';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { ReservationOfflineServices } from '../components/ReservationOfflineServices';
import { FormAccompaniedServicesPicker } from 'app/components/Forms/FormAccompaniedServicesPicker';
import { ReservationsAccServicesFilter } from 'app/components/pickers/MultiSelectPickers/AccompaniedServicesPicker';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { TerminateReservation } from '../components/TerminateReservation';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { quoteODataValue } from 'api/odata/ODataFilter';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';
import {
  customFormErrorsHandler,
  otheMessagesHandler,
  permissionsErrorsHandler,
  prerquisitErrorsHandler,
  staffUserChangeHandler,
  trainingSessionChangeHandler,
} from './handlers';
import { FormTrainingSessionPicker } from 'app/components/Forms/FormTrainingSessionPicker';
import { ReservRelatedFilter } from 'app/components/pickers/AutocompletePickers/TrainingSessionPicker';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { generatePath } from 'react-router-dom';
import { RUN_DETAILS_PAGE_PATH } from 'app/pages/Samples/RunDetailsPage';
import { combineOfflineServiceTypes } from '../slice/combineOfflineServiceTypes';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { FormUsersPicker } from 'app/components/Forms/FormUsersPicker';
import { getReservationCollaboratorsFilter } from './getReservationCollaboratorsFilter';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { FormTextField } from 'app/components/Forms/FormTextField';
import produce from 'immer';
import { FormReservationBudgetExperimentPicker } from 'app/components/Forms/ReservationBudgetExperimentPicker';
import { TrainingSignUpAdminAlertContent } from '../components/TrainingSignUpAdminAlertContent';
import { FormReservationTimePeriodPicker } from '../components/ReservationTimePicker';
import { adjustSchedulerEventTimes } from 'app/pages/CalendarPage/Scheduler/utils/adjustSchedulerEventTimes';
import { EquipmentsDataAssemblies } from '../components/EquipmentsDataAssemblies';
import { useAppSettingsSlice } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import {
  ListMessagesProps,
  SingleMessagesProps,
} from 'app/Layout/FrontendLayout/components/Snackbar/Actions';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { ReservationWorkOrders } from '../components/ReservationWorkOrders';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { FormWorkFlowBooking } from 'app/components/Forms/FormWorkFlowBooking';
import { PrintPDFSection } from 'app/components/PrintToPDF/PrintPDFSection';
import { selectPrintPDFSections } from 'app/components/PrintToPDF/slice/selectors';
import { ReadOnlyDetails } from '../components/ReadOnlyDetails';
import { ReservationRequestDetails } from '../components/RequestDetails';
import { getErrorMessages } from 'utils/modelStateUtils';

export interface ReservationFormProps {
  onSubmit: (
    item: ReservationDetailsState,
    customFormFiles: IFormFileValue[],
    originalFormFiles: IFormFileValue[],
  ) => void;
  initialValues: ReservationDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  isUserGroupCoordinator?: boolean;
  repetitiveFormRef: React.MutableRefObject<any>;
  globalSettings: GlobalSettingsType;
  customFormRef: React.MutableRefObject<any>;
  formRef: React.MutableRefObject<FormikProps<ReservationDetailsState> | null>;
  onEquipmentClicked?: (equipment: Entity<number>) => void;
  onRestrictionsClick?: (equipment: Entity<number>) => void;
  onOtherSrviceClick?: (service: IOtherServices) => void;
  onStatusChange?: (event: any, status: ReservationStatus) => void;
  onOfflineServiceClick?: (service: IOtherServices) => void;
  onAddBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  onWorkTypeClick: (
    event: IWorkOrderTypeDto,
    equipmentIds: number[],
    reservationId: number,
  ) => void;
  trainingSignUp?: boolean;
  trainingSessionEnabled?: boolean;
  editable?: boolean;
  timeEnabled?: boolean;
  cancelTransfer?: () => void;
  waitingTransferApproval?: boolean;
  transferToUser?: Entity<string> | null;
  transferForId?: number;
  terminationEnabled?: boolean;
  handleTerminate: () => void;
  allowEditMultipleEquipments: boolean;
  useSidePanel: boolean;
  offlineServicesErrors: ErrorServices[];
  setError?: (error: ErrorServices) => void;
  assistedTrainingData: UserTrainingReservationData | null;
  allowOngoingModifications?: boolean;
  isCover?: boolean;
  print?: boolean;
}

export const ReservationForm = React.memo(function ReservationForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  isUserGroupCoordinator,
  repetitiveFormRef,
  globalSettings,
  customFormRef,
  formRef,
  onEquipmentClicked,
  onRestrictionsClick,
  onStatusChange,
  onOfflineServiceClick,
  onWorkTypeClick,
  trainingSessionEnabled,
  editable,
  timeEnabled,
  trainingSignUp,
  cancelTransfer,
  waitingTransferApproval,
  transferToUser,
  transferForId,
  terminationEnabled,
  handleTerminate,
  allowEditMultipleEquipments,
  useSidePanel,
  onAddBatchClick,
  offlineServicesErrors,
  setError,
  assistedTrainingData,
  allowOngoingModifications,
  isCover,
  print,
}: ReservationFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useReservationSlice();
  const { actions: appActions } = useAppSettingsSlice();

  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const reservationSettings = useSelector(selectReservationSettings);
  const equipmentSettings = useSelector(selectEquipmentsSettings);
  const fieldHandler = useSelector(selectFieldHandler);
  const requestedFor = useSelector(selectRequestedValueFor);
  const equipmentChangeState = useSelector(selectEquipmentChangeState);
  const equipmentChangeCompleted = useSelector(selectEquipmentChangeCompleted);
  const asyncChangeLoading = useSelector(selectAsyncChangeLoading);
  const savedOfflineServices = useSelector(selectSavedOfflineServices);
  const hasError = useSelector(selectReservationHasError);
  const isCloned = useSelector(selectCloneReservations);
  const insertUpdateCompleted = useSelector(selectReservationCompleted);
  const isEdit = useSelector(selectIsEdit);
  const savedForLater = useSelector(selectSavedForLater);
  const isCreditSubmited = useSelector(selectIsCreditSubmited);
  const printPDFSections = useSelector(selectPrintPDFSections);

  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const { newDate } = useSystemDate();
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  // const isMobile = DetectIsMobile();
  const [durationErrors, setDurationErrors] = React.useState<IndexNode[]>([]);
  const [permissionErrors, setPermissionErrors] = React.useState<IndexNode[]>(
    [],
  );
  const [prerequisitErrors, setPrerequisitErrors] = React.useState<IndexNode[]>(
    [],
  );
  const [customFormErrors, setCustomFormErrors] = React.useState<IndexNode[]>(
    [],
  );
  const [otherErrors, setOtherErrors] = React.useState<IndexNode[]>([]);
  const [otherWarnings, setOtherWarnings] = React.useState<IndexNode[]>([]);
  const [otherInfo, setOtherInfo] = React.useState<IndexNode[]>([]);
  const [otherSuccess, setOtherSuccess] = React.useState<IndexNode[]>([]);
  const errorMessages = React.useMemo(() => {
    return [
      ...otherErrors,
      ...customFormErrors,
      ...prerequisitErrors,
      ...permissionErrors,
      ...durationErrors,
    ];
  }, [
    customFormErrors,
    durationErrors,
    otherErrors,
    permissionErrors,
    prerequisitErrors,
  ]);
  const customFormOnlyPrinted = React.useMemo(() => {
    return (
      printPDFSections?.some(f => f.id === 'CustomFormDetails' && f.checked) &&
      printPDFSections?.some(f => f.id === 'ReservationDetails' && !f.checked)
    );
  }, [printPDFSections]);
  const mandatoryServices = React.useMemo(() => {
    if (
      !!reservationSettings?.MandatoriesEquipments &&
      !isEdit &&
      reservationSettings.MandatoriesEquipments.length > 0
    ) {
      return reservationSettings.MandatoriesEquipments.map(f => {
        return {
          Id: f.Id,
          Name: f.Name,
          info: `Mandatory instrument for ${f.ParentName}`,
        };
      });
    } else return [];
  }, [isEdit, reservationSettings?.MandatoriesEquipments]);
  const resetAllErrors = () => {
    setDurationErrors([]);
    setPermissionErrors([]);
    setPrerequisitErrors([]);
    setOtherErrors([]);
    setCustomFormErrors([]);
    setOtherWarnings([]);
    setOtherInfo([]);
    setOtherSuccess([]);
  };
  const handleSubmit = (values: ReservationDetailsState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values, currentFormFiles, originalFormFiles);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetValueHandler());
  };
  const reserValueKey = () => {
    dispatch(actions.resetRequestedFor());
  };
  const saveRequestedValue = (value: any) => {
    dispatch(actions.getAnyValueSuccess(value));
  };
  /// func Help ///
  const addPrerquisitErrors = React.useCallback(
    (data: PrerequisitsResult, equipments: Entity<number>[]) => {
      if (!data.IsValid) {
        prerquisitErrorsHandler(
          data,
          equipments,
          (nodes: IndexNode[]) => setPrerequisitErrors(nodes),
          onEquipmentClicked,
          onRestrictionsClick,
        );
      }
    },
    [onEquipmentClicked, onRestrictionsClick],
  );
  // const addDurationErrors = React.useCallback(
  //   (data: string[], equipments: Entity<number>[]) => {
  //     if (data.length > 0) {
  //       durationErrorsHandler(
  //         data,
  //         equipments,
  //         (nodes: IndexNode[]) => setDurationErrors(nodes),
  //         onRestrictionsClick,
  //       );
  //     }
  //   },
  //   [onRestrictionsClick],
  // );
  const addOtheMessages = React.useCallback(
    (data: string[], type: 'warning' | 'error' | 'info' | 'success') => {
      if (data.length > 0) {
        otheMessagesHandler(data, type, (nodes: IndexNode[]) => {
          switch (type) {
            case 'error':
              setOtherErrors(() => nodes);
              break;
            case 'warning':
              setOtherWarnings(() => nodes);
              break;
            case 'info':
              setOtherInfo(() => nodes);
              break;
            case 'success':
              setOtherSuccess(() => nodes);
              break;
          }
        });
      }
    },
    [],
  );
  const addCustomFormErrors = React.useCallback((data: string[]) => {
    if (data.length > 0) {
      customFormErrorsHandler(data, (nodes: IndexNode[]) =>
        setCustomFormErrors(nodes),
      );
    }
  }, []);
  const addPermissionsErrors = React.useCallback(
    (data: string[], equipments: Entity<number>[]) => {
      permissionsErrorsHandler(
        data,
        equipments,
        (nodes: IndexNode[]) => setPermissionErrors(nodes),
        onRestrictionsClick,
      );
    },
    [onRestrictionsClick],
  );

  /// Local State ///
  const [userGroupChanged, setUserGroupChanged] = React.useState(false);
  const [bookedbyChanged, setBookedByChanged] = React.useState(false);
  const [staffChanged, setStaffChanged] = React.useState(false);
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [accServiceChanged, setAccServiceChanged] = React.useState(false);
  const [budgetChanged, setBudgetChanged] = React.useState(false);
  const [statusChanged, setStatusChanged] = React.useState(false);
  const [startTimeChanged, setStartTimeChanged] = React.useState(false);
  const [endTimeChanged, setEndTimeChanged] = React.useState(false);
  const [tutoringChanged, setTutoringChanged] = React.useState(false);
  const [optionalChanged, setOptionalChanged] = React.useState(false);

  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [trainingSessionChanged, setTrainingSessionChanged] =
    React.useState(false);
  const [originalFormFiles, setOriginalFormFiles] = React.useState<
    IFormFileValue[]
  >([]);
  const [currentFormFiles, setCurrentFormFiles] = React.useState<
    IFormFileValue[]
  >([]);

  const [firstInitEquipments, setFirstInitEquipments] = React.useState<
    number[]
  >(reservationSettings?.FirstInitEquipments || []);
  const [currentStatus, setCurrentStatus] = React.useState<number>(
    initialValues.Status?.Id || 1,
  );
  const [isTrainingSignUp, setIsTrainingSignUp] = React.useState<
    boolean | undefined
  >(trainingSignUp);
  const changeOfflineServices = React.useCallback(
    (services: IOtherServices[], operation: 'add' | 'remove' | 'update') => {
      if (operation === 'add') {
        let addedServ = services.filter(
          f =>
            !savedOfflineServices.some(
              s => s.ServiceTypeID === f.ServiceTypeID,
            ),
        );
        dispatch(
          actions.setSavedOfflineServices([
            ...savedOfflineServices,
            ...addedServ,
          ]),
        );
      }
      if (operation === 'remove') {
        dispatch(
          actions.setSavedOfflineServices(
            savedOfflineServices.filter(f => {
              return !services.some(a => a.ServiceTypeID === f.ServiceTypeID);
            }),
          ),
        );
      }
      if (operation === 'update') {
        dispatch(
          actions.setSavedOfflineServices(
            savedOfflineServices.map(f => {
              let service = services.find(
                s => s.ServiceTypeID === f.ServiceTypeID,
              );
              if (!!service && service !== null) {
                return Object.assign({}, f, service);
              } else {
                return f;
              }
            }),
          ),
        );
      }
    },
    [actions, dispatch, savedOfflineServices],
  );
  /// memo ///
  const isServiceAdmin = React.useMemo(() => {
    return equipmentSettings?.IsAllAdmin;
  }, [equipmentSettings?.IsAllAdmin]);

  const readonly = React.useMemo(() => {
    return (
      equipmentChangeCompleted === false ||
      asyncChangeLoading === true ||
      waitingTransferApproval === true
    );
  }, [asyncChangeLoading, equipmentChangeCompleted, waitingTransferApproval]);
  const fundingTypeVisible = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      globalSettings.fundingTypeBudgetEnabled
    );
  }, [globalSettings]);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return !!equipmentSettings
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
          !equipmentSettings.HasHideProjects &&
          !equipmentSettings.HasConflictProjects &&
          equipmentSettings.BudgetsTurnedOn
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  }, [
    equipmentSettings,
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  ]);

  const userGroupVisible = React.useMemo(() => {
    return (
      isServiceAdmin &&
      (!isEdit ||
        (isEdit && !globalSettings.DoNotAllowReservationUserChangeByStaff)) &&
      // !isUserGroupCoordinator &&
      !ReservationUserGroupGroupByBudgetUserGroup
    );
  }, [
    isServiceAdmin,
    isEdit,
    globalSettings.DoNotAllowReservationUserChangeByStaff,
    ReservationUserGroupGroupByBudgetUserGroup,
  ]);
  const userVisible = React.useMemo(() => {
    return (
      (isServiceAdmin || isUserGroupCoordinator) &&
      (!isEdit ||
        (isEdit && !globalSettings.DoNotAllowReservationUserChangeByStaff))
    );
  }, [
    isServiceAdmin,
    isUserGroupCoordinator,
    isEdit,
    globalSettings.DoNotAllowReservationUserChangeByStaff,
  ]);
  const userProfileVisible = React.useMemo(() => {
    return (
      (isServiceAdmin || isUserGroupCoordinator) &&
      isEdit &&
      globalSettings.DoNotAllowReservationUserChangeByStaff
    );
  }, [
    isServiceAdmin,
    isUserGroupCoordinator,
    isEdit,
    globalSettings.DoNotAllowReservationUserChangeByStaff,
  ]);
  const tutoringVisible = React.useMemo(() => {
    return (
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringModulesEnabled &&
      equipmentSettings?.IsEquipmentTutoring &&
      !isTrainingSignUp
    );
  }, [
    equipmentSettings?.IsEquipmentTutoring,
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringModulesEnabled,
    isTrainingSignUp,
  ]);
  const tutoringEnabled = React.useMemo(() => {
    let tutoringAllowed =
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringModulesEnabled &&
      equipmentSettings?.IsEquipmentTutoring &&
      !isTrainingSignUp;
    let forceTutoring =
      equipmentSettings?.ForceTutoring || reservationSettings?.IsForceTutoring;
    let hasValidTraining =
      assistedTrainingData === null
        ? false
        : assistedTrainingData.Equipments.some(f => f.RequiresAdminApproval)
        ? false
        : isEdit
        ? editable
        : true;
    if (!isEdit) {
      return (
        (tutoringAllowed && (isServiceAdmin || hasValidTraining)) ||
        !forceTutoring
      );
    } else {
      return tutoringAllowed && forceTutoring
        ? isServiceAdmin || hasValidTraining
        : editable;
    }
  }, [
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringModulesEnabled,
    equipmentSettings?.IsEquipmentTutoring,
    equipmentSettings?.ForceTutoring,
    isTrainingSignUp,
    reservationSettings?.IsForceTutoring,
    assistedTrainingData,
    isEdit,
    editable,
    isServiceAdmin,
  ]);
  const trainingSessionVisible = React.useMemo(() => {
    return (
      trainingSessionEnabled &&
      (reservationSettings?.TrainingSessionOnly || isTrainingSignUp)
    );
  }, [
    reservationSettings?.TrainingSessionOnly,
    trainingSessionEnabled,
    isTrainingSignUp,
  ]);
  const workflowBookingVisible = React.useMemo(() => {
    return globalSettings.AllowWorkflowBooking;
  }, [globalSettings.AllowWorkflowBooking]);
  const workflowBookingEnabled = React.useMemo(() => {
    return (
      workflowBookingVisible &&
      (globalSettings.ModifyReservationNameForUsers || isAdmin)
    );
  }, [
    globalSettings.ModifyReservationNameForUsers,
    isAdmin,
    workflowBookingVisible,
  ]);
  const trainingSignUpVisible = React.useMemo(() => {
    return (
      globalSettings.trainingSessionEnabled &&
      !equipmentSettings?.HideTrainingSession &&
      reservationSettings?.HasTrainingSession
    );
  }, [
    globalSettings.trainingSessionEnabled,
    equipmentSettings?.HideTrainingSession,
    reservationSettings?.HasTrainingSession,
  ]);
  const staffVisible = React.useMemo(() => {
    return (
      equipmentSettings?.StaffUserVisibleAllways ||
      (equipmentSettings?.StaffUserVisibleOnPending &&
        currentStatus === ReservationStatus.Pending)
    );
  }, [
    equipmentSettings?.StaffUserVisibleAllways,
    equipmentSettings?.StaffUserVisibleOnPending,
    currentStatus,
  ]);
  const reservationCollaboratorsVisible = React.useMemo(() => {
    return equipmentSettings?.AllowReservationCollaborators;
  }, [equipmentSettings?.AllowReservationCollaborators]);
  const ForceFullDayReservations = React.useMemo(
    () => equipmentSettings?.ForceFullDayReservations,
    [equipmentSettings?.ForceFullDayReservations],
  );
  const budgetExperimentVisible = React.useMemo(
    () => equipmentSettings?.budgetExperimentVisible,
    [equipmentSettings?.budgetExperimentVisible],
  );
  const staffRequired = React.useMemo(() => {
    return (
      equipmentSettings?.StaffUserRequiredAlwais ||
      (equipmentSettings?.StaffUserRequiredOnPending &&
        currentStatus === ReservationStatus.Pending)
    );
  }, [
    equipmentSettings?.StaffUserRequiredAlwais,
    equipmentSettings?.StaffUserRequiredOnPending,
    currentStatus,
  ]);
  const warningMessages: IndexNode[] = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    if (firstInitEquipments.length > 0) {
      nodes.push({
        index: 'notMoreThenOneIndex',
        node: (
          <Caption>
            {t(translations.AvailableInstrumentsMessage) as string}
          </Caption>
        ),
      });
    }
    if (isEdit) {
      if (
        currentStatus !== ReservationStatus.Cancelled &&
        currentStatus !== ReservationStatus.CancelledByAdmin
      ) {
        if (
          initialValues.Tutoring &&
          currentStatus === ReservationStatus.Pending
        ) {
          nodes.push({
            index: 'pendingApproval',
            node: (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <Body size="small" bold={true}>
                  {t(translations.ReservationPendingApproval) as string}
                </Body>
                {isServiceAdmin && (
                  <Button
                    variant="main"
                    size="small"
                    onClick={e =>
                      !!onStatusChange &&
                      onStatusChange(e, ReservationStatus.Approved)
                    }
                  >
                    {t(translations.Approve) as string}
                  </Button>
                )}
                <Caption>
                  {t(translations.res_wrn_NewPendingReservation) as string}
                </Caption>
              </Box>
            ),
            variant: 'outlined',
          });
        } else if (currentStatus === ReservationStatus.PendingCoord) {
          nodes.push({
            index: 'pendingCoordApproval',
            node: (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <Body size="small" bold={true}>
                  {t(translations.RequireGroupCoordApprovalMessage) as string}
                </Body>
                <Caption>
                  {t(translations.res_wrn_NewPendingCoordReservation) as string}
                </Caption>
                {isUserGroupCoordinator && (
                  <Button
                    variant="main"
                    size="small"
                    onClick={e =>
                      !!onStatusChange &&
                      onStatusChange(e, ReservationStatus.Approved)
                    }
                  >
                    {t(translations.Approve) as string}
                  </Button>
                )}
              </Box>
            ),
            variant: 'outlined',
          });
        }
        if (
          equipmentSettings?.DraftSaveForLater &&
          currentStatus === ReservationStatus.Draft
        ) {
          nodes.push({
            index: 'draftReservation',
            node: (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <Body size="small" bold={true}>
                  {t(translations.res_wrn_DraftReservation) as string}
                </Body>
                {(isServiceAdmin ||
                  initialValues.BookedBy?.Id === user?.Id) && (
                  <Button
                    variant="main"
                    size="small"
                    onClick={e =>
                      !!onStatusChange &&
                      onStatusChange(e, ReservationStatus.Approved)
                    }
                  >
                    {t(translations.MakeFirm) as string}
                  </Button>
                )}
              </Box>
            ),
            variant: 'outlined',
          });
        }
      }
    }
    if (
      !isEdit &&
      initialValues.TransferFromId !== null &&
      initialValues.TransferFromId !== undefined
    ) {
      nodes.push({
        index: 'transferWarning',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.Transfer) as string}
            </Body>
            <Caption>
              {t(translations.ReservationTransferMessage) as string}
            </Caption>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    if (
      !isEdit &&
      (equipmentSettings?.RequireUserGroupCoordApproval ||
        user?.RequireUserGroupCoordApproval)
    ) {
      nodes.push({
        index: 'pendingCoordApproval',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.RequireGroupCoordApprovalMessage) as string}
            </Body>
            <Caption>
              {t(translations.res_wrn_NewPendingCoordReservation) as string}
            </Caption>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    if (
      reservationSettings?.TrainingSessionOnly === true &&
      reservationSettings?.HasTrainingSession !== true
    ) {
      nodes.push({
        index: 'trainingSessionMissingWarning',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.TrainingSession) as string}
            </Body>
            <Caption>
              {t(translations.TrainingSessionsRes_warning) as string}
            </Caption>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    return [...nodes, ...otherWarnings];
  }, [
    currentStatus,
    equipmentSettings?.DraftSaveForLater,
    equipmentSettings?.RequireUserGroupCoordApproval,
    firstInitEquipments.length,
    initialValues.BookedBy?.Id,
    initialValues.TransferFromId,
    initialValues.Tutoring,
    isEdit,
    isServiceAdmin,
    isUserGroupCoordinator,
    onStatusChange,
    otherWarnings,
    reservationSettings?.HasTrainingSession,
    reservationSettings?.TrainingSessionOnly,
    t,
    user?.Id,
    user?.RequireUserGroupCoordApproval,
  ]);
  const infoMessages = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    if (
      globalSettings.assistedReservationsEnabled &&
      !equipmentSettings?.IsAllAdmin &&
      equipmentSettings?.ForceTutoring &&
      !isEdit &&
      currentStatus === ReservationStatus.Pending
    ) {
      nodes.push({
        index: 'asiistedTutorInfo',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>{`${
              t(translations.TutoringAssistedReservation) as string
            } - ${getSingleReservationStatus(currentStatus).Name}`}</Body>
            <Caption>
              {
                t(
                  translations.AssistedReservationsPendingApprovalInfo,
                ) as string
              }
            </Caption>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    if (
      isEdit &&
      transferToUser !== null &&
      transferToUser !== undefined &&
      waitingTransferApproval === true
    ) {
      nodes.push({
        index: 'waitingTraferInfo',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.TransferReservation) as string}
            </Body>
            <Caption>
              {(t(translations.TransferredToDetailsMessage) as string).replace(
                '{0}',
                transferToUser?.Name || '',
              )}
            </Caption>
            <Box style={{ display: 'flex', gap: 8 }}>
              {editable && (
                <Button
                  variant="white"
                  size="small"
                  onClick={e => {
                    !!cancelTransfer && cancelTransfer();
                  }}
                >
                  {t(translations.CancelTransfer) as string}
                </Button>
              )}

              {(isServiceAdmin ||
                initialValues.TransferredTo?.Id === user?.Id) && (
                <Button
                  variant="white"
                  size="small"
                  href={{
                    path: 'reservations/details',
                    search: {
                      selectedIds: reservationSettings?.BaseEquipment?.Id,
                      Start: dateUtils.formatISO(
                        dateUtils.dateOrStringToDate(initialValues.StartTime),
                      ),
                      End: dateUtils.formatISO(
                        dateUtils.dateOrStringToDate(initialValues.EndTime),
                      ),
                      resIdTransferredFrom: initialValues.Id,
                      un: transferToUser?.Id,
                    },
                  }}
                >
                  {t(translations.TakeOverTransfer) as string}
                </Button>
              )}
            </Box>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    if (isEdit && terminationEnabled) {
      nodes.push({
        index: 'terminationInfo',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.NotUsingThisReservation)}
            </Body>
            {/* <Caption>{t(translations.NotUsingThisReservation)}</Caption> */}
            <Box style={{ display: 'flex', gap: 8 }}>
              <TerminateReservation
                onTerminate={handleTerminate}
                isLoaanDesc={equipmentSettings?.IsLoanDesc || false}
                reservationId={initialValues.Id}
                serviceId={reservationSettings?.BaseEquipment?.Id || 0}
                title={t(translations.TerminateReservationInfo)}
                text={t(translations.ReleaseUnusedTimeButton)}
              />
            </Box>
          </Box>
        ),
        variant: 'outlined',
      });
    }
    if (trainingSessionVisible && isServiceAdmin) {
      let reservation = formRef.current?.values || initialValues;
      let session =
        reservation.TrainingSession !== null
          ? (reservation.TrainingSession as ITrainingSessionDto)
          : null;
      if (session !== null && trainingSignUp) {
        nodes.push({
          index: 'trainingSignUps',
          showMore: false,
          node: (
            <div>
              <TrainingSignUpAdminAlertContent trainingSessionId={session.Id} />
            </div>
          ),
          variant: 'outlined',
        });
      }
    }
    if (!isEdit) {
      const assemblyPartsSelected = formRef.current?.values.EquipmentsData.some(
        service => (service as IReservationEquipmentDto)?.AssemblyId !== null,
      );
      if (assemblyPartsSelected) {
        nodes.push({
          index: 'index',
          showMore: false,
          node: (
            <>{t(translations.ReservationSidePanelAssemblyPart_ErrorMessage)}</>
          ),
        });
      }
    }

    return [...nodes, ...otherInfo];
  }, [
    cancelTransfer,
    currentStatus,
    editable,
    equipmentSettings?.ForceTutoring,
    equipmentSettings?.IsAllAdmin,
    equipmentSettings?.IsLoanDesc,
    formRef,
    globalSettings.assistedReservationsEnabled,
    handleTerminate,
    initialValues,
    isEdit,
    isServiceAdmin,
    otherInfo,
    reservationSettings?.BaseEquipment?.Id,
    t,
    terminationEnabled,
    trainingSessionVisible,
    trainingSignUp,
    transferToUser,
    user?.Id,
    waitingTransferApproval,
  ]);
  const successMessages = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    if (
      globalSettings.assistedReservationsEnabled &&
      equipmentSettings?.ForceTutoring &&
      isEdit &&
      currentStatus === ReservationStatus.Approved
    ) {
      nodes.push({
        index: 'asiistedTutorApproved',
        node: (
          <Body size="small" bold={true}>
            {t(translations.ReservationAssistedApproved) as string}
          </Body>
        ),
        variant: 'outlined',
      });
    }
    return [...nodes, ...otherSuccess];
  }, [
    currentStatus,
    equipmentSettings?.ForceTutoring,
    globalSettings.assistedReservationsEnabled,
    isEdit,
    otherSuccess,
    t,
  ]);
  const equipmentsChangeHandler = React.useCallback(
    (
      values: ReservationDetailsState,
      changeStatus?: boolean,
      selectedEqs?: IReservationEquipmentDto[],
    ) => {
      let allEquipments = !!selectedEqs
        ? selectedEqs
        : [
            ...(values.EquipmentsData as IReservationEquipmentDto[]),
            ...((reservationSettings?.MandatoriesEquipments ||
              []) as Entity<number>[] as IReservationEquipmentDto[]),
            ...(values.OptionalsEquipments.filter(
              f => f.checked === true,
            ) as Entity<number>[] as IReservationEquipmentDto[]),
          ];
      dispatch(
        actions.extendEquipmentSettingsState({
          equipments: allEquipments,
          mandatories: [],
          accServices: values.AccServices as IEquipmentAccServiceDto[],
          globalSettings: globalSettings,
          settings: equipmentSettings,
        }),
      );
      const payload: EquipmentsChangeStateParameters = {
        AccServices: values.AccServices.map(f => f.Id),
        Equipments: allEquipments.map(f => f.Id),
        ReservationId: (values.Id ?? 0) > 0 ? values.Id : null,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(values.StartTime),
        ),
        End: dateUtils.formatISO(dateUtils.dateOrStringToDate(values.EndTime)),
        BookedBy: values.BookedBy?.Id || null,
        Status: values.Status?.Id || 1,
        TrainingSignUp: values.TrainingSignUp,
        Tutoring: values.Tutoring,
        ADGroup: values.ADGroup?.Id || user?.ActiveUserGroup?.Id || null,
        FundingTypeId: values.FundingType?.Id || 0,
        CheckStatus: changeStatus || false,
        TrainingSessionId: values.TrainingSession?.Id || null,
        Remarks: values.Remarks || null,
        BudgetId: values.Budget?.Id || null,
        CheckSession: !equipmentSettings?.HideTrainingSession,
        FullDayReservation: values.FullDayReservation,
      };
      console.log('initValidationData-equipment');
      resetAllErrors();
      dispatch(
        actions.initValidationData({
          parameters: payload,
          warnings: [],
          errors: [],
        }),
      );
    },
    [
      actions,
      dispatch,
      equipmentSettings,
      globalSettings,
      reservationSettings?.MandatoriesEquipments,
      user?.ActiveUserGroup?.Id,
    ],
  );
  const extendStaffMandatoryEquipment = React.useCallback(
    (staff: IReservationEquipmentDto | null) => {
      dispatch(
        actions.extendReservationSettings({
          StaffMandatoryEquipment: staff,
        }),
      );
    },
    [actions, dispatch],
  );
  const extendMandatoryEquipments = React.useCallback(
    (mandatories: IMandatoryEquipmentsDto[]) => {
      dispatch(
        actions.extendReservationSettings({
          MandatoriesEquipments: mandatories,
        }),
      );
    },
    [actions, dispatch],
  );
  const initStaffReservation = React.useCallback(
    (staffEquipmentId: number, values: ReservationDetailsState) => {
      resetAllErrors();
      dispatch(
        actions.initReservation({
          query: {
            id: undefined,
            selectedIds: values.EquipmentsData.map(f => f.Id).join(','),
            Start: dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(values.StartTime),
            ),
            End: dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(values.EndTime),
            ),
            tsid:
              values.TrainingSession === null
                ? undefined
                : values.TrainingSession.Id.toString(),
            remarks: values.Remarks,
            accid: values.AccServices.map(f => f.Id).join(','),
            singleClick: undefined,
            duration: undefined,
            ug: values.ADGroup?.Id,
            un: values.BookedBy?.Id,
            ft:
              values.FundingType === null || values.FundingType === undefined
                ? undefined
                : values.FundingType.Id.toString(),
            srid: String(reservationSettings?.AssignToServiceRequestId),
            srrid: String(reservationSettings?.AssignToServiceRequestRowIds),
            srsid: String(reservationSettings?.AssignToServiceRequestSampleIds),
            sampleRunId: undefined,
            defaultMulti: undefined,
            resIdTransferredFrom: values.TransferFromId?.toString(),
            source: undefined,
            UpdateToNow: undefined,
            terminated: undefined,
            resourceChanged: true.toString(),
            staffEquipmentId: staffEquipmentId.toString(),
            staffUser: values.StaffUser?.Id,
          } as ReservationQueryStringParameters,
          globalSettings: globalSettings,
          prevState: values,
        }),
      );
      setFirstInitEquipments([]);
      setServiceChanged(false);
      setAccServiceChanged(false);
    },
    [
      actions,
      dispatch,
      globalSettings,
      reservationSettings?.AssignToServiceRequestId,
      reservationSettings?.AssignToServiceRequestRowIds,
      reservationSettings?.AssignToServiceRequestSampleIds,
    ],
  );
  const initTrainingSession = React.useCallback(
    (
      trainingSessionData: TrainingSessionResult,
      values: ReservationDetailsState,
    ) => {
      resetAllErrors();
      dispatch(
        actions.initReservation({
          query: {
            id: undefined,
            selectedIds: values.EquipmentsData.map(f => f.Id).join(','),
            Start: dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(trainingSessionData.Start),
            ),
            End: dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(trainingSessionData.End),
            ),
            tsid:
              trainingSessionData.TrainingSessionId === null
                ? undefined
                : trainingSessionData.TrainingSessionId.toString(),
            remarks: values.Remarks,
            accid: undefined,
            singleClick: undefined,
            duration: undefined,
            ug: values.ADGroup?.Id,
            un: values.BookedBy?.Id,
            ft:
              values.FundingType === null || values.FundingType === undefined
                ? undefined
                : values.FundingType.Id.toString(),
            srid: undefined,
            srrid: undefined,
            srsid: undefined,
            sampleRunId: undefined,
            defaultMulti: undefined,
            resIdTransferredFrom: undefined,
            source: undefined,
            UpdateToNow: undefined,
            terminated: undefined,
            resourceChanged: true.toString(),
            staffEquipmentId: undefined,
            staffUser: trainingSessionData.StaffUser,
          } as ReservationQueryStringParameters,
          globalSettings: globalSettings,
          prevState: values,
        }),
      );
      setFirstInitEquipments([]);
      setServiceChanged(false);
      setAccServiceChanged(false);
    },
    [actions, dispatch, globalSettings],
  );
  const setStaffEquipmentRestricted = React.useCallback(
    (staffUserName: string) => {
      setOtherErrors(() => [
        {
          index: 3,
          node: (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <Caption key="ind_staff">
                {(
                  t(translations.err_res_StaffUserEquipmentRestricted) as string
                ).replace('{0}', staffUserName)}
              </Caption>
            </Box>
          ),
        },
      ]);
    },
    [t],
  );
  const staffChangeHandler = React.useCallback(
    (
      values: ReservationDetailsState,
      setValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => {
      return staffUserChangeHandler(
        values,
        isEdit,
        setValue,
        equipmentsChangeHandler,
        extendStaffMandatoryEquipment,
        extendMandatoryEquipments,
        initStaffReservation,
        setStaffEquipmentRestricted,
        reservationSettings,
      );
    },
    [
      equipmentsChangeHandler,
      extendMandatoryEquipments,
      extendStaffMandatoryEquipment,
      initStaffReservation,
      isEdit,
      reservationSettings,
      setStaffEquipmentRestricted,
    ],
  );
  const multipleEquipmentsChangeHandler = React.useCallback(
    (
      values: ReservationDetailsState,
      setValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => {
      let equipments = values.EquipmentsData as IReservationEquipmentDto[];
      let mandatories = (
        equipments.length > 0 &&
        equipments.some(
          f => !!f.MandatoriesEquipments && f.MandatoriesEquipments.length > 0,
        )
          ? equipments.reduce((acc, next) => {
              return acc;
            }, equipments.filter(f => !!f.MandatoriesEquipments && f.MandatoriesEquipments.length > 0)[0].MandatoriesEquipments)
          : []
      ).filter(f => !equipments.some(s => f.Id === s.Id));
      /**
       * Gets the mandatory offline service types attached to reservation services.
       * @param services services selected for the reservation
       * @returns array of mandatory offline service type details
       */
      const getMandatoryOfflineServices = (
        services: IReservationEquipmentDto[],
      ) => {
        const MandatoryOfflineServicesOnTrainingSignUps =
          globalSettings.MandatoryOfflineServicesOnTrainingSignUps ||
          !values.TrainingSignUp;
        /**
         * If the MandatoryOfflineServicesOnTrainingSignUps system setting is turned off, then no offline service types should be mandatory for training signups
         */

        const initialValue: Pick<
          ReservationSettingsState,
          'OfflineMandatory' | 'OfflineOptional'
        > = {
          OfflineMandatory: [],
          OfflineOptional: [],
        };
        const result = combineOfflineServiceTypes(services)?.reduce(
          (previousValue, currentValue) => {
            const mandatory =
              MandatoryOfflineServicesOnTrainingSignUps && currentValue;

            (mandatory
              ? previousValue.OfflineMandatory
              : previousValue.OfflineOptional
            ).push(currentValue as unknown as IOtherServices);
            return previousValue;
          },
          initialValue,
        );
        return result;
      };
      if (mandatories.length > 0) {
        (async () => {
          try {
            const data = (await initEquipmentsData(
              mandatories.map(f => f.Id).join(','),
              'AccServices,OfflineServices,StaffUserSelection,MandatoriesEquipments,OptionalsEquipments',
            )) as IMandatoryEquipmentsDto[];

            const allEquipments = [
              ...values.EquipmentsData,
              ...data,
            ] as IReservationEquipmentDto[];
            dispatch(
              actions.extendReservationSettings({
                MandatoriesEquipments: data,
                ...getMandatoryOfflineServices(allEquipments),
              }),
            );

            setValue('EquipmentsData', allEquipments);
            equipmentsChangeHandler(
              Object.assign({}, values, {
                EquipmentsData: allEquipments,
              }),
              true,
            );
          } catch {}
        })();
      } else {
        dispatch(
          actions.extendReservationSettings({
            MandatoriesEquipments: mandatories,
            ...getMandatoryOfflineServices(equipments),
          }),
        );
        equipmentsChangeHandler(
          Object.assign({}, values, {
            EquipmentsData: equipments,
          }),
          true,
        );
      }
    },
    [
      actions,
      dispatch,
      equipmentsChangeHandler,
      globalSettings.MandatoryOfflineServicesOnTrainingSignUps,
    ],
  );
  const warnOnTrainingSession = React.useCallback(
    (data: TrainingSessionResult | null, values: ReservationDetailsState) => {
      trainingSessionChangeHandler(
        data,
        (nodes: IndexNode[]) => setOtherWarnings(nodes),
        (data, values) => {
          initTrainingSession(data, values);
          setIsTrainingSignUp(true);
        },
        values,
      );
    },
    [initTrainingSession],
  );
  const handleFormChange = React.useCallback(
    (
      values: ReservationDetailsState,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (staffChanged) {
        staffChangeHandler(values, setValue);
        setStaffChanged(false);
      }
      if ((serviceChanged || accServiceChanged) && !isEdit) {
        resetAllErrors();
        dispatch(
          actions.initReservation({
            query: {
              id: undefined,
              selectedIds: values.EquipmentsData.map(f => f.Id).join(','),
              Start: dateUtils.formatQueryStringDate(
                dateUtils.dateOrStringToDate(values.StartTime),
              ),
              End: dateUtils.formatQueryStringDate(
                dateUtils.dateOrStringToDate(values.EndTime),
              ),
              tsid:
                values.TrainingSession === null
                  ? undefined
                  : values.TrainingSession.Id.toString(),
              remarks: values.Remarks,
              accid: values.AccServices.map(f => f.Id).join(','),
              singleClick: undefined,
              duration: undefined,
              ug: values.ADGroup?.Id,
              un: values.BookedBy?.Id,
              ft:
                values.FundingType === null || values.FundingType === undefined
                  ? undefined
                  : values.FundingType.Id.toString(),
              srid: String(reservationSettings?.AssignToServiceRequestId),
              srrid: String(reservationSettings?.AssignToServiceRequestRowIds),
              srsid: String(
                reservationSettings?.AssignToServiceRequestSampleIds,
              ),
              sampleRunId: values.SampleRunId?.toString(),
              defaultMulti: undefined,
              resIdTransferredFrom: values.TransferFromId?.toString(),
              source: undefined,
              UpdateToNow: undefined,
              terminated: undefined,
              resourceChanged: true.toString(),
              staffEquipmentId: (
                (values.StaffUser as IUserFilterDto)?.StaffEquipmentId ??
                undefined
              )?.toString(),
              staffUser: values.StaffUser?.Id,
            } as ReservationQueryStringParameters,
            globalSettings: globalSettings,
            prevState: values,
          }),
        );
        setFirstInitEquipments([]);
        setServiceChanged(false);
        setAccServiceChanged(false);
        setStaffChanged(false);
      }
      if (serviceChanged && isEdit && allowEditMultipleEquipments) {
        multipleEquipmentsChangeHandler(values, setValue);
        setServiceChanged(false);
      }
      if (userGroupChanged) {
        console.log('User Group Changed', values.ADGroup);
        setValue('BookedBy', null);
        setDurationErrors([]);
        setPermissionErrors([]);
        setPrerequisitErrors([]);
        setUserGroupChanged(false);
      }
      if (bookedbyChanged) {
        console.log('BookedBy Changed', values.BookedBy);
        if (values.BookedBy !== null) {
          equipmentsChangeHandler(values, true);
        }
        resetAllErrors();
        setBookedByChanged(false);
      }
      if (budgetChanged) {
        console.log('Budget Changed', values.Budget);
        setBudgetChanged(false);
      }
      if (statusChanged) {
        setCurrentStatus(values.Status?.Id || 1);
        console.log('Status Changed', values.Status);
        setStatusChanged(false);
      }
      if (startTimeChanged) {
        resetAllErrors();
        console.log('Start Time Changed', values.StartTime);
        setStartTimeChanged(false);
      }
      if (endTimeChanged) {
        resetAllErrors();
        console.log('End Time Changed', values.EndTime);
        setEndTimeChanged(false);
      }
      if (tutoringChanged) {
        console.log('Tutoring Changed', values.Tutoring);
        if (values.Tutoring === true) {
          setValue(
            'Status',
            getSingleReservationStatus(ReservationStatus.Pending),
          );
          setCurrentStatus(
            getSingleReservationStatus(ReservationStatus.Pending).Id,
          );
        } else {
          setValue(
            'Status',
            getSingleReservationStatus(ReservationStatus.Approved),
          );
          setCurrentStatus(
            getSingleReservationStatus(ReservationStatus.Approved).Id,
          );
          if (equipmentSettings?.StaffUserVisibleOnPending) {
            setValue('StaffUser', null);
          }
        }
        setTutoringChanged(false);
      }
      if (optionalChanged) {
        setOtherErrors([]);
        equipmentsChangeHandler(values);
        console.log('Optional Changed');
        setOptionalChanged(false);
      }
      if (trainingSessionChanged) {
        if (!!values.TrainingSession && values.TrainingSession !== null) {
          setValue(
            'StartTime',
            (values.TrainingSession as ITrainingSessionDto).StartDate,
          );
          setValue(
            'EndTime',
            (values.TrainingSession as ITrainingSessionDto).EndDate,
          );
          setValue('StaffUser', {
            Id: (values.TrainingSession as ITrainingSessionDto).Trainer,
            Name: (values.TrainingSession as ITrainingSessionDto).TrainerName,
          });
        }
        setTrainingSessionChanged(false);
      }
    },
    [
      staffChanged,
      serviceChanged,
      accServiceChanged,
      isEdit,
      allowEditMultipleEquipments,
      userGroupChanged,
      bookedbyChanged,
      budgetChanged,
      statusChanged,
      startTimeChanged,
      endTimeChanged,
      tutoringChanged,
      optionalChanged,
      trainingSessionChanged,
      staffChangeHandler,
      dispatch,
      actions,
      reservationSettings?.AssignToServiceRequestId,
      reservationSettings?.AssignToServiceRequestRowIds,
      reservationSettings?.AssignToServiceRequestSampleIds,
      globalSettings,
      multipleEquipmentsChangeHandler,
      equipmentsChangeHandler,
      equipmentSettings?.StaffUserVisibleOnPending,
    ],
  );
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);

  /// useEffects
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);
  const handleValidate = React.useCallback(
    (userGroup: Entity<string> | null, budget: IBudgetFilterDto | null) => {
      let values = formRef.current?.values || initialValues;
      if (!!values) {
        let allEquipments = [
          ...(values.EquipmentsData as IReservationEquipmentDto[]),
          ...((reservationSettings?.MandatoriesEquipments ||
            []) as Entity<number>[] as IReservationEquipmentDto[]),
          ...(values.OptionalsEquipments.filter(
            f => f.checked === true,
          ) as Entity<number>[] as IReservationEquipmentDto[]),
        ];
        const payload = {
          AccServices: values.AccServices.map(f => f.Id),
          Equipments: allEquipments.map(f => f.Id),
          ReservationId: (values.Id ?? 0) > 0 ? values.Id : null,
          Start: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(values.StartTime),
          ),
          End: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(values.EndTime),
          ),
          BookedBy: values.BookedBy?.Id || null,
          Status: values.Status?.Id || 1,
          TrainingSignUp: values.TrainingSignUp,
          Tutoring: values.Tutoring,
          ADGroup: values.ADGroup?.Id || userGroup?.Id || null,
          FundingTypeId: fundingTypeVisible ? 0 : values.FundingType?.Id || 0,
          CheckStatus: false,
          TrainingSessionId: values.TrainingSession?.Id || null,
          Remarks: values.Remarks || null,
          BudgetId: values.Budget?.Id || budget?.Id || null,
          CheckSession: false,
        } as EquipmentsChangeStateParameters;
        (async () => {
          var validations = await validateReservation(payload);
          resetAllErrors();
          let resultValidation = validations as ReservationValidationResult;
          if (!!resultValidation) {
            if (!!resultValidation.PrerequisiteTrainings) {
              if (!resultValidation.PrerequisiteTrainings.IsValid) {
                addPrerquisitErrors(
                  resultValidation.PrerequisiteTrainings,
                  values.EquipmentsData,
                );
              }
            }
            let permErrors = getErrorMessages(
              resultValidation.PermissionErrors,
            );
            if (permErrors.length > 0) {
              addPermissionsErrors(permErrors, values.EquipmentsData);
              if (
                !reservationSettings?.UserRequestedByApproval &&
                resultValidation.UserCanRequestApproval
              ) {
                dispatch(actions.setUserCanRequestApproval(true));
                dispatch(actions.setByApprovalErrors(permErrors));
              }
            }
            let allErrors = getErrorMessages(resultValidation.ErrorMessages);
            if (allErrors.length > 0) {
              addOtheMessages(allErrors, 'error');
            }
            if (resultValidation.WarningMessages.length > 0) {
              addOtheMessages(resultValidation.WarningMessages, 'error');
            }
          }
          //accServicesValidation
          if (
            allEquipments.some(
              f => !!f.AccServices && f.AccServices.length > 0,
            ) &&
            !isTrainingSignUp
          ) {
            let res = validateAccServices(allEquipments);
            if (res.length > 0) {
              let error = (
                t(
                  translations.err_MultipleEquipmentsWithAndWithoutAccServices,
                ) as string
              ).replace('{0}', res.join(', '));
              addOtheMessages([error], 'error');
            }
          }
        })();
      }
      if (reservationSettings?.UserRequestedByApproval) {
        setPermissionErrors([]);
      }
    },
    [
      actions,
      addOtheMessages,
      addPermissionsErrors,
      addPrerquisitErrors,
      dispatch,
      formRef,
      fundingTypeVisible,
      initialValues,
      isTrainingSignUp,
      reservationSettings?.MandatoriesEquipments,
      reservationSettings?.UserRequestedByApproval,
      t,
    ],
  );
  React.useEffect(() => {
    if (!!equipmentChangeState) {
      console.log('equipment settings: ', equipmentSettings);
      if (
        !!equipmentChangeState.CustomForms &&
        equipmentChangeState.CustomForms.ErrorMessages.length > 0
      ) {
        addCustomFormErrors(equipmentChangeState.CustomForms.ErrorMessages);
      }

      if (equipmentChangeState.ErrorMessages.length > 0) {
        addOtheMessages(equipmentChangeState.ErrorMessages, 'error');
      }
      if (equipmentChangeState.WarningMessages.length > 0) {
        addOtheMessages(equipmentChangeState.ErrorMessages, 'warning');
      }
      if (equipmentChangeState.TrainingSessionData !== null) {
        if (equipmentChangeState.TrainingSessionData.HasTrainingSession) {
          setOtherErrors([]);
          warnOnTrainingSession(
            equipmentChangeState.TrainingSessionData,
            formRef.current?.values || initialValues,
          );
        }
      }
      let budget: IBudgetFilterDto | null = null;
      let userGroup: Entity<string> | null = null;
      if (equipmentSettings?.BudgetVisible && !isEdit) {
        dispatch(
          actions.setAnyValue({
            fieldKey: 'Budget',
            fieldValue: equipmentChangeState.DefaultBudget,
          }),
        );
        if (
          fundingTypeVisible &&
          equipmentChangeState.DefaultBudget?.FundingTypeId !== undefined &&
          equipmentChangeState.DefaultBudget?.FundingTypeId !== null
        ) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'FundingType',
              fieldValue: getSingleInvoiceFundingType(
                equipmentChangeState.DefaultBudget?.FundingTypeId,
              ),
            }),
          );
        }
        budget = equipmentChangeState.DefaultBudget;
        if (
          ReservationUserGroupGroupByBudgetUserGroup &&
          equipmentChangeState.DefaultBudget !== null &&
          !!equipmentChangeState.DefaultBudget.UserGroupId
        ) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'ADGroup',
              fieldValue: {
                Id: equipmentChangeState.DefaultBudget.UserGroupId,
                Name: equipmentChangeState.DefaultBudget.UserGroupName,
              },
            }),
          );
          userGroup = {
            Id: equipmentChangeState.DefaultBudget.UserGroupId,
            Name: equipmentChangeState.DefaultBudget.UserGroupName ?? '',
          };
        }
      }
      if (equipmentChangeState.StatusChange !== null) {
        dispatch(
          actions.setAnyValue({
            fieldKey: 'Status',
            fieldValue: getSingleReservationStatus(
              equipmentChangeState.StatusChange.Status,
            ),
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'Tutoring',
            fieldValue: equipmentChangeState.StatusChange.Tutoring,
          }),
        );
        setCurrentStatus(equipmentChangeState.StatusChange.Status);
      }
      handleValidate(userGroup, budget);
      dispatch(actions.resetEquipmentChangeState());
    }
  }, [
    ReservationUserGroupGroupByBudgetUserGroup,
    actions,
    addCustomFormErrors,
    addOtheMessages,
    dispatch,
    equipmentChangeState,
    equipmentSettings,
    formRef,
    fundingTypeVisible,
    handleValidate,
    initialValues,
    isEdit,
    warnOnTrainingSession,
  ]);
  const computedSchema = React.useMemo(() => {
    const reservationSchema: Yup.SchemaOf<ReservationDetailsState> = Yup.object(
      {
        Id: Yup.number().notRequired().default(-1),
        FatherId: Yup.number().optional().nullable(),
        EquipmentsData: Yup.array()
          .of(Yup.mixed() as Yup.SchemaOf<IReservationEquipmentDto>)
          .min(1)
          .label(t(translations.OnlineService) as string)
          .required(t(translations.err_OnlineServiceFieldRequired) as string)
          .test(
            'assembly',
            t(
              translations.AssemblyPartReservationCanNotBeUpdatedDirectly,
            ) as string,

            (value, context) => {
              const isValid = (value as IReservationEquipmentDto[]).every(
                service => service.AssemblyId === null,
              );
              return isValid;
            },
          ),
        Budget: Yup.mixed()
          .label(t(translations.BudgetNumber) as string)
          .nullable(true),
        BudgetExperiment: Yup.mixed()
          .label(t(translations.BudgetExperiment) as string)
          .nullable(true)
          .when('Budget', {
            is: (Budget: IBudgetFilterDto | null) =>
              equipmentSettings?.BudgetVisible !== false &&
              equipmentSettings?.budgetExperimentVisible &&
              Budget !== null &&
              Budget.HideExperiments !== true,
            then: schema =>
              schema.required(
                t(translations.res_err_BudgetExperimentRequired) as string,
              ),
            otherwise: schema => schema,
          }),

        TrainingSession: Yup.mixed().label(
          t(translations.TrainingSession) as string,
        ),
        StaffUser: Yup.mixed()
          .label(t(translations.StaffUser) as string)
          .nullable(true),
        BookedBy: Yup.mixed()
          .label(t(translations.User) as string)
          .required(() => t(translations.err_UserFieldRequired) as string),
        Remarks: Yup.string()
          .nullable()
          .label(t(translations.Remarks) as string),
        FullDayReservation: Yup.boolean().required(),
        StartTime: Yup.date()
          .default(newDate())
          .label(t(translations.StartTime) as string)
          .required(t(translations._errStartTimeRequired) as string),
        EndTime: Yup.date()
          .label(t(translations.EndTime) as string)
          .required(t(translations._errEndTimeRequired) as string)
          .test(
            'min',
            t(translations.err_EndTimeLowerThanStartTime) as string,
            (value, context) => {
              let start = (context as any).from[0].value.StartTime;
              let end = (context as any).from[0].value.EndTime;
              if (
                start === null ||
                start === undefined ||
                value === null ||
                value === undefined
              ) {
                return true;
              }
              let s = dateUtils.dateOrStringToDate(start);
              let e = dateUtils.dateOrStringToDate(end);
              if (e.valueOf() < s.valueOf()) {
                return false;
              }

              return true;
            },
          ),
        // .min(
        //   Yup.ref('StartTime'),
        //   t(translations.err_EndTimeLowerThanStartTime) as string,
        // ),
        Tutoring: Yup.boolean()
          .label(t(translations.TutoringAssistedReservation) as string)
          .default(false),
        TutorName: Yup.string()
          .label(t(translations.TutorName) as string)
          .nullable(true),
        Status: Yup.mixed()
          .label(t(translations.OrderStatus) as string)
          .required(),
        TrainingSignUp: Yup.boolean()
          .default(false)
          .label(t(translations.TrainingSignUp) as string),
        ADGroup: Yup.mixed()
          .label(t(translations.UserGroup) as string)
          .nullable(true),
        DisplayName: Yup.string()
          .label(t(translations.Name) as string)
          .nullable(),
        InstituteProject: Yup.mixed()
          .label(t(translations.InstituteProject) as string)
          .nullable(true),
        ExperimentEln: Yup.mixed()
          .label(t(translations.Experiment) as string)
          .nullable(true),
        WorkflowBooking: Yup.mixed()
          .label(t(translations.WorkflowBooking) as string)
          .nullable(true),
        ExternalCustomer: Yup.mixed()
          .label(t(translations.ExternalCustomer) as string)
          .nullable(true),
        FundingType: Yup.mixed().label(t(translations.FundingType) as string),
        PurchaseOrder: Yup.string()
          .label(t(translations.PurchaseOrder) as string)
          .nullable(true)
          .min(globalSettings.purchaseOrderLengthSetting ?? 0)
          .when('Budget', {
            is: budget => {
              const result =
                globalSettings.purchaseOrderBudgetEnabled &&
                (budget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
                  PurchaseOrderOptions.Mandatory;
              return result;
            },
            then: schema => schema.required(),
          }),

        Reference: Yup.string()
          .label(t(translations.Reference) as string)
          .nullable(true),
        PartialAttendance: Yup.boolean()
          .default(false)
          .label(t(translations.Attendance) as string),
        PartialAttendanceStart: Yup.number()
          .label(t(translations.PartialAttendanceStartDuration) as string)
          .nullable(true),
        PartialAttendanceEnd: Yup.number()
          .label(t(translations.PartialAttendanceEndDuration) as string)
          .nullable(true),
        TransferredTo: Yup.mixed()
          .label(t(translations.TransferReservation) as string)
          .nullable(true),
        SampleRunId: Yup.number()
          .label(t(translations.SampleRunId) as string)
          .nullable(true),
        AccServices: Yup.array()
          .of(EntityNumberSchema)
          .label(t(translations.AccompaniedService) as string),
        FormValues: Yup.array().of(formValueSchema),
        CollaboratedUsers: Yup.array()
          .of(EntityStringSchema)
          .label(t(translations.CollaboratorUsers) as string),
        SampleIds: Yup.array()
          .of(Yup.number().required())
          .label(t(translations.SampleIds) as string),
        OptionalsEquipments: Yup.array().of(CheckEntityShema),
        WaitingTransferApproval: Yup.boolean().default(false),
        TransferFromId: Yup.number().nullable(true).default(null),
        // not really needed on the form
        AssemblyParts: Yup.mixed().nullable().optional(),
      },
    );
    if (equipmentSettings?.BudgetVisible === true) {
      reservationSchema.fields.Budget.withMutation(schema =>
        schema.required(t(translations.BudgetIsRequired) as string),
      );
    }
    if (
      globalSettings.accServicesEnabled &&
      equipmentSettings?.HasAccServices &&
      !isTrainingSignUp
    ) {
      reservationSchema.fields.AccServices.withMutation(schema =>
        schema
          .min(1, t(translations.err_AccompaniedService_required) as string)
          .required(t(translations.err_AccompaniedService_required) as string),
      );
    }
    if (staffRequired) {
      reservationSchema.fields.StaffUser.withMutation(schema =>
        schema.required(t(translations.StaffUserRequired) as string),
      );
    }
    if (workflowBookingVisible) {
      reservationSchema.fields.WorkflowBooking.withMutation(schema =>
        schema.required(t(translations.err_WorkflowBookingRequired) as string),
      );
    }
    return reservationSchema;
  }, [
    equipmentSettings?.BudgetVisible,
    equipmentSettings?.HasAccServices,
    equipmentSettings?.budgetExperimentVisible,
    globalSettings.accServicesEnabled,
    globalSettings.purchaseOrderBudgetEnabled,
    globalSettings.purchaseOrderLengthSetting,
    isTrainingSignUp,
    newDate,
    staffRequired,
    t,
    workflowBookingVisible,
  ]);
  const handleFullDayReservationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // todo: move this to a callback with the formik.values accessible there instead of this mess
    const startTime = formRef.current?.values.StartTime;
    const endTime = formRef.current?.values.EndTime;
    if (
      startTime !== null &&
      endTime !== null &&
      startTime !== undefined &&
      endTime !== undefined
    ) {
      const fullDayReservation = event.target.checked === true;
      if (fullDayReservation) {
        const currentPeriod = {
          start_date: dateUtils.dateOrStringToDate(startTime),
          end_date: dateUtils.dateOrStringToDate(endTime),
        };
        /**
         * When full day reservation is being checked, the end time needs to be moved to the end of the day, start - to the start of the day
         */
        const adjusted = adjustSchedulerEventTimes(
          currentPeriod,
          fullDayReservation,
        );
        const partialReservationDetailsState: Pick<
          ReservationDetailsState,
          'StartTime' | 'EndTime'
        > = {
          /**
           * TODO: adjust the starttime of the reservation. Currently setting the startTime messes up the end time also
           * e.g.
           * {start:'2023-08-02T11:05:00', end:'2023-08-02T12:05:00'} -> (original)
           * {start:'2023-08-02T00:00:00', end:'2023-08-03T00:00:00'} -> (in setValues)
           * {start:'2023-08-02T00:00:00', end:'2023-08-03T22:27:12Z'} -> (final formik Values)
           */
          StartTime: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(adjusted.start_date),
          ),
          EndTime: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(adjusted.end_date),
          ),
          // StartTime: dateUtils.formatQueryStringDate(adjusted.start_date, {
          //   representation: 'complete',
          // }),
          // EndTime: dateUtils.formatQueryStringDate(adjusted.end_date, {
          //   representation: 'complete',
          // }),
        };
        formRef.current?.setValues(values => ({
          ...values,
          ...partialReservationDetailsState,
        }));
      }
    }
  };
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            console.log(reservationSettings);
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
                console.debug('errors', responseErrors);
              } else if (errorMessages.length > 0) {
                console.debug('errors', errorMessages);
                formikHelpers.setSubmitting(false);
              } else if (offlineServicesErrors.length > 0) {
                formikHelpers.setSubmitting(false);
                dispatch(
                  appActions.addNotification({
                    variant: 'error',
                    message: '',
                    messageType: SnackBarMessageType.messageList,
                    messageTypeProps: {
                      messages: offlineServicesErrors.map(f => {
                        return {
                          Id: f.id.toString(),
                          message: f.error,
                        } as SingleMessagesProps;
                      }),
                    } as ListMessagesProps,
                  }),
                );
                if (isCreditSubmited) {
                  dispatch(actions.setSubmitCredit(false));
                }
              } else {
                if (isCreditSubmited) {
                  handleSubmit(values);
                } else {
                  console.debug('submitting', values);
                  formikHelpers.setSubmitting(true);
                  dispatch(actions.resetOperateState());
                  if (submitting) {
                    setSubmitting(undefined);
                  }
                  handleSubmit(values);
                }
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Reservation errors', formik.errors);
          }
          console.log('Reservation submitting', formik.isSubmitting);
          const reservationCollaboratorsFilter =
            getReservationCollaboratorsFilter(equipmentSettings, formik);
          const staffUserPickerPredicates =
            (equipmentSettings?.StaffUserSelection?.length || 0) > 0
              ? [
                  `(Id in ${quoteODataValue(
                    equipmentSettings?.StaffUserSelection.filter(f =>
                      (
                        formik.values
                          .EquipmentsData as IReservationEquipmentDto[]
                      ).some(c =>
                        c.StaffUserSelection.some(u => u.Id === f.Id),
                      ),
                    ) || [],
                  )})`,
                ]
              : [
                  `(IsLabTech or isEquipmentAdmin or isGroupAdmin) and ${(
                    formik.values.EquipmentsData as IReservationEquipmentDto[]
                  )
                    ?.map(
                      equipment =>
                        `(Services/any(s:s/Id eq ${equipment.Id}) or (${equipment.ServiceGroupId} in ServiceGroupIds)`,
                    )
                    .join(' and ')})`,
                ];

          return (
            <React.Fragment>
              <PrintPDFSection
                print={print || false}
                id="ReservationDetails"
                forceChecked={true}
                sectionClass="print-reservation"
              >
                <StyledForm onSubmit={formik.handleSubmit} isCover={isCover}>
                  <>
                    <FormListener
                      onFormChange={handleFormChange}
                      fields={[
                        'ADGroup',
                        'BookedBy',
                        'EquipmentsData',
                        'Status',
                        'StartTime',
                        'EndTime',
                        'OptionalsEquipments',
                        'AccServices',
                        'StaffUser',
                        'Tutoring',
                      ]}
                    />
                    <FieldHandler
                      {...fieldHandler}
                      resetFieldState={resetFieldHandler}
                    />
                    <FieldValue
                      setValue={saveRequestedValue}
                      resetValueKey={reserValueKey}
                      fieldKey={requestedFor}
                    />
                    <SubmittingHandler
                      value={
                        (hasError === true || isCloned === true) &&
                        insertUpdateCompleted === true &&
                        formik.isSubmitting &&
                        submitting === undefined
                      }
                      resetSubmitting={resetSubmitting}
                    />
                    <BudgetListener
                      budget={formik.values.Budget}
                      ReservationUserGroupGroupByBudgetUserGroup={
                        ReservationUserGroupGroupByBudgetUserGroup
                      }
                      setUserGroup={value =>
                        formik.setFieldValue('ADGroup', value)
                      }
                      handleValidate={handleValidate}
                      values={formik.values}
                    />
                    <UserGroupListener
                      userName={formik.values.BookedBy}
                      equipments={formik.values.EquipmentsData}
                      userGroup={formik.values.ADGroup}
                      settings={
                        !!equipmentSettings
                          ? {
                              HasHideProjects:
                                equipmentSettings.HasHideProjects,
                              HasConflictProjects:
                                equipmentSettings.HasConflictProjects,
                              BudgetsTurnedOn:
                                equipmentSettings.BudgetsTurnedOn,
                            }
                          : undefined
                      }
                      globalSettings={globalSettings}
                      user={user}
                      isEdit={isEdit}
                    />
                    <UserNameListener
                      userName={formik.values.BookedBy}
                      equipments={formik.values.EquipmentsData}
                      globalSettings={globalSettings}
                      user={user}
                      isEdit={isEdit}
                      isReservations={true}
                      reservationUserGroupGroupByBudgetUserGroup={
                        ReservationUserGroupGroupByBudgetUserGroup
                      }
                      //changeHandler={equipmentsChangeHandler}
                      values={formik.values}
                    />
                    <StartTimeListener
                      userName={formik.values.BookedBy}
                      equipments={formik.values.EquipmentsData}
                      equipmentSettings={equipmentSettings}
                      endTime={formik.values.EndTime}
                      startTime={formik.values.StartTime}
                      initialStart={initialValues.StartTime}
                      initialEnd={initialValues.EndTime}
                      globalSettings={globalSettings}
                      user={user}
                      changeHandler={equipmentsChangeHandler}
                      isEdit={isEdit}
                      values={formik.values}
                      fullDayReservation={
                        ForceFullDayReservations ||
                        formik.values.FullDayReservation
                      }
                    />
                    <EndTimeListener
                      userName={formik.values.BookedBy}
                      equipments={formik.values.EquipmentsData}
                      globalSettings={globalSettings}
                      user={user}
                      endTime={formik.values.EndTime}
                      initialEnd={initialValues.EndTime}
                      isEdit={isEdit}
                      changeHandler={equipmentsChangeHandler}
                      values={formik.values}
                    />
                  </>
                  <FormLeftSection
                    warningNodes={warningMessages}
                    infoNodes={infoMessages}
                    errors={errorMessages}
                    successNodes={successMessages}
                    isCover={isCover}
                  >
                    {/* <ErrorsSummury errors={formik.errors} /> */}
                    {(!print || (!customFormOnlyPrinted && print)) && (
                      <FormFieldsSection
                        titleSection={
                          t(translations.ReservationDetails) as string
                        }
                      >
                        <FormRow fullRow={true} minRow>
                          <FormReservationEquipmentsPicker
                            predicates={ReservationsEquipmentFilter(
                              firstInitEquipments,
                              !!reservationSettings &&
                                !!reservationSettings.MandatoriesEquipments
                                ? reservationSettings.MandatoriesEquipments.map(
                                    f => f.Id,
                                  )
                                : [],
                            )}
                            name="EquipmentsData"
                            label={t(translations.OnlineService)}
                            placeholder={t(translations.PleaseSelectServices)}
                            disabled={
                              formik.isSubmitting ||
                              (isEdit &&
                                !(editable && allowEditMultipleEquipments)) ||
                              readonly
                            }
                            onChange={() => setServiceChanged(true)}
                            onChipClick={onEquipmentClicked}
                            multiple={
                              globalSettings.multipleInstrumentsEnabled &&
                              !trainingSessionVisible
                            }
                            autoExpand={
                              'MandatoriesEquipments,OptionalsEquipments,AccServices,OfflineServices,StaffUserSelection'
                            }
                            fullWidth
                          />
                          {globalSettings.AssemblyPartsEnabled && (
                            <EquipmentsDataAssemblies
                              equipmentsData={
                                formik.values
                                  .EquipmentsData as IReservationEquipmentDto[]
                              }
                              reservedAssemblyParts={
                                formik.values.AssemblyParts ?? null
                              }
                            />
                          )}
                        </FormRow>
                        {globalSettings.accServicesEnabled &&
                          equipmentSettings?.HasAccServices &&
                          !isTrainingSignUp && (
                            <FormRow fullRow={true}>
                              <FormAccompaniedServicesPicker
                                predicates={ReservationsAccServicesFilter(
                                  formik.values.EquipmentsData.map(f => f.Id),
                                )}
                                name="AccServices"
                                label={t(translations.AccompaniedService)}
                                placeholder={t(
                                  translations.PleaseSelectAnAccompaniedService,
                                )}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  readonly
                                }
                                onChange={() => setAccServiceChanged(true)}
                                multiple={
                                  equipmentSettings.HasAccServices &&
                                  !equipmentSettings.AllowOneAccSerOnly
                                }
                                fullWidth
                              />
                            </FormRow>
                          )}
                        {mandatoryServices.length > 0 && (
                          <React.Fragment>
                            <FormRow fullRow={true}>
                              <Icon
                                icon="level-up-alt"
                                transform={{ rotate: 90 }}
                              />
                              <FormChipLabelGroup
                                options={mandatoryServices}
                                name="MandatoriesEquipments"
                                id="MandatoriesEquipmentsId"
                                label={t(
                                  translations.AddingsMandatoryEquipments,
                                )}
                              />
                            </FormRow>
                          </React.Fragment>
                        )}
                        {!!reservationSettings?.OptionalsEquipments &&
                          !isEdit &&
                          reservationSettings.OptionalsEquipments.length >
                            0 && (
                            <FormRow fullRow={true}>
                              <Icon
                                icon="level-up-alt"
                                transform={{ rotate: 90 }}
                              />
                              <FormChipCheckBoxGroup
                                options={formik.values.OptionalsEquipments}
                                name="OptionalsEquipments"
                                id="OptionalsEquipmentsId"
                                setOnChanged={() => {
                                  setOptionalChanged(() => true);
                                }}
                                disabled={formik.isSubmitting || readonly}
                                label={t(
                                  translations.AddingsOptionalEquipments,
                                )}
                                size="small"
                              />
                            </FormRow>
                          )}
                        {trainingSessionVisible && (
                          <FormRow>
                            <FormTrainingSessionPicker
                              name="TrainingSession"
                              id="trainingSession"
                              label={t(translations.TrainingSession)}
                              disabled={formik.isSubmitting || readonly}
                              onChange={() => setTrainingSessionChanged(true)}
                              predicates={ReservRelatedFilter(
                                formik.values.EquipmentsData[0].Id,
                              )}
                              fullWidth
                            />
                          </FormRow>
                        )}
                        {workflowBookingVisible && (
                          <FormRow fullRow={true}>
                            <FormWorkFlowBooking
                              name="WorkflowBooking"
                              label={t(translations.WorkflowBooking)}
                              info={t(translations.ReservationName_Desc)}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                !workflowBookingEnabled
                              }
                              fullWidth
                            />
                          </FormRow>
                        )}
                        {!trainingSessionVisible && (
                          <React.Fragment>
                            <FormRow fullRow={true}>
                              <FormSwitch
                                boldLebel
                                name="FullDayReservation"
                                info={t(
                                  translations.FullDayReservation_Description,
                                )}
                                label={t(translations.FullDayReservation)}
                                disabled={
                                  ForceFullDayReservations ||
                                  formik.isSubmitting ||
                                  readonly ||
                                  !tutoringEnabled
                                }
                                onChange={handleFullDayReservationChange}
                                id={'fullDaySwitchId'}
                              />
                            </FormRow>
                            <FormReservationTimePeriodPicker
                              StartTimePropertyName="StartTime"
                              EndTimePropertyName="EndTime"
                              FullDayReservation={
                                ForceFullDayReservations ||
                                formik.values.FullDayReservation
                              }
                              startTimeDisabled={
                                formik.isSubmitting ||
                                readonly ||
                                formik.values.TrainingSignUp ||
                                (isEdit && !editable)
                              }
                              endTimeDisabled={
                                formik.isSubmitting ||
                                readonly ||
                                formik.values.TrainingSignUp ||
                                (isEdit && !timeEnabled)
                                //  ||
                                // ((equipmentSettings?.RestrictDurationUnitType ??
                                //   0) > 0 &&
                                //   (equipmentSettings?.RestrictDurationUnitsAmount ??
                                //     0) > 0)
                              }
                              onStartTimeChange={() =>
                                setStartTimeChanged(true)
                              }
                              onEndTimeChange={() => setEndTimeChanged(true)}
                              startTimeLabel={t(translations.From)}
                              endTimeLabel={t(translations.To)}
                            />
                          </React.Fragment>
                        )}

                        {userGroupVisible ? (
                          <FormRow>
                            <FormRestrictedUserGroupPicker
                              name="ADGroup"
                              label={t(translations.UserGroup)}
                              predicates={RestrictedFilter(
                                undefined,
                                undefined,
                                undefined,
                                globalSettings.onlyOwnUserGroupsAllowed,
                              )}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                (isEdit && !editable)
                              }
                              onChange={() => setUserGroupChanged(true)}
                              fullWidth
                            />
                          </FormRow>
                        ) : (
                          <FormRow>
                            <FormLabelText
                              id="UserGroupId"
                              name="ADGroup"
                              label={t(translations.UserGroup)}
                              info={
                                ReservationUserGroupGroupByBudgetUserGroup
                                  ? t(translations.SponsorDepartment_Desc)
                                  : undefined
                              }
                              html={
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  {formik.values.ADGroup !== undefined &&
                                  formik.values.ADGroup !== null
                                    ? formik.values.ADGroup.Name
                                    : (t(translations.NA) as string)}
                                </Box>
                              }
                            />
                          </FormRow>
                        )}
                        {userVisible ? (
                          <FormRow>
                            <FormUserPicker
                              name="BookedBy"
                              info={t(translations.ReservationUser_Desc)}
                              selectArray={[
                                'Id',
                                'Name',
                                'UserGroupId',
                                'UserGroupName',
                                'UserGroups',
                                'Budgets',
                                'DefaultBudgetId',
                              ]}
                              predicates={ServicesFilter(
                                (
                                  formik.values
                                    .EquipmentsData as IReservationEquipmentDto[]
                                ).map(f => {
                                  return {
                                    Id: f.Id,
                                    Name: f.Name,
                                    ServiceTypeId: 1,
                                    ServiceGroupId: f.ServiceGroupId,
                                    HideProject: f.HideProjects,
                                  } as IServiceTypeFilterDto;
                                }),
                                globalSettings.budgetExperimentModuleEnabled,
                                formik.values.ADGroup?.Id,
                                formik.values.Budget?.Id,
                                globalSettings.defaultBudgetEnabled,
                                (formik.values.Budget as IBudgetFilterDto)
                                  ?.IsDefault,
                              )}
                              label={t(translations.User)}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                (isEdit && !editable)
                              }
                              onChange={value => {
                                setBookedByChanged(true);
                              }}
                              fullWidth
                            />
                          </FormRow>
                        ) : (
                          <FormRow hide={!isServiceAdmin}>
                            <FormLabelText
                              id="bookedById"
                              name="Bookedby"
                              label={t(translations.User) as string}
                              info={
                                t(translations.ReservationUser_Desc) as string
                              }
                              html={
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  {userProfileVisible &&
                                    formik.values.BookedBy !== null && (
                                      <UserProfileLink
                                        userName={formik.values.BookedBy.Id}
                                        displayName={
                                          formik.values.BookedBy.Name
                                        }
                                        twoRows={false}
                                      />
                                    )}
                                  {/* {formik.values.BookedBy !== null
                                  ? formik.values.BookedBy.Name
                                  : (t(translations.NA) as string)} */}
                                </Box>
                              }
                            />
                          </FormRow>
                        )}
                        {staffVisible && (
                          <FormRow>
                            <FormUserPicker
                              name="StaffUser"
                              info={t(translations.StaffUser_Info)}
                              placeholder={t(
                                translations.PleaseSelectStaffUser,
                              )}
                              selectArray={['Id', 'Name', 'StaffEquipmentId']}
                              urlType={
                                (equipmentSettings?.StaffUserSelection
                                  ?.length || 0) > 0
                                  ? 'full'
                                  : 'admin'
                              }
                              predicates={staffUserPickerPredicates}
                              label={t(translations.StaffUser)}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                (isEdit && !editable)
                              }
                              onChange={() => setStaffChanged(true)}
                              fullWidth
                            />
                          </FormRow>
                        )}
                        {equipmentSettings?.BudgetVisible &&
                          fundingTypeVisible && (
                            <FormRow>
                              <FormFundingTypePicker
                                name="FundingType"
                                label={t(translations.FundingType)}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  readonly
                                }
                                onChange={value => {
                                  formik.setValues(
                                    produce(values => {
                                      // clear budget selection when funding type is changed to an incompatible value
                                      // for example, if an external budget is selected, and funding type is changed to internal - budget should be reset
                                      if (
                                        (value as Entity<number>).Id !==
                                        (values.Budget as IBudgetFilterDto)
                                          ?.FundingTypeId
                                      ) {
                                        values.Budget = null;
                                      }
                                      values.FundingType = value;
                                    }),
                                  );
                                }}
                                fullWidth
                              />
                            </FormRow>
                          )}
                        {equipmentSettings?.BudgetVisible && (
                          <>
                            <FormRow>
                              <FormBudgetPicker
                                name="Budget"
                                predicates={restrictedBudgetFilter({
                                  budgetLimitedByUsers:
                                    globalSettings.budgetLimitedByUsers,
                                  isBudgetsWithoutUserGroup:
                                    globalSettings.isBudgetsWithoutUserGroup,
                                  userGroupId:
                                    ReservationUserGroupGroupByBudgetUserGroup
                                      ? undefined
                                      : formik.values.ADGroup?.Id,
                                  serviceGroupId: undefined,
                                  services: (
                                    formik.values
                                      .EquipmentsData as IReservationEquipmentDto[]
                                  ).map(f => {
                                    return {
                                      Id: f.Id,
                                      Name: f.Name,
                                      ServiceTypeId: 1,
                                      ServiceGroupId: f.ServiceGroupId,
                                      HideProject: f.HideProjects,
                                    } as IServiceTypeFilterDto;
                                  }),
                                  startTime: dateUtils.dateOrStringToDate(
                                    formik.values.StartTime,
                                  ),
                                  endTime: dateUtils.dateOrStringToDate(
                                    formik.values.EndTime,
                                  ),
                                  user: isServiceAdmin
                                    ? formik.values.BookedBy === null
                                      ? undefined
                                      : formik.values.BookedBy.Id
                                    : user?.Id,
                                  defaultBudgetEnabled:
                                    globalSettings.defaultBudgetEnabled,
                                  userDefaultBudgetEnabled:
                                    globalSettings.userDefaultBudgetEnabled,
                                  hideNonDefaultBudgets:
                                    globalSettings.hideNonDefaultBudgets,
                                  // funding type is set to Internal by default even if it's not in use
                                  fundingType: fundingTypeVisible
                                    ? formik.values.FundingType
                                    : null,
                                })}
                                label={t(translations.BudgetNumber)}
                                onChange={() => {
                                  setBudgetChanged(true);
                                  formik.setFieldValue('CollaboratedUsers', []);
                                }}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  (isEdit && !editable)
                                }
                                useSearchOrPredicate={
                                  globalSettings.hideNonDefaultBudgets
                                }
                                fullWidth
                              />
                            </FormRow>
                            {budgetExperimentVisible &&
                              !(
                                (formik.values.Budget as IBudgetFilterDto)
                                  ?.HideExperiments ?? true
                              ) && (
                                <FormRow>
                                  <FormReservationBudgetExperimentPicker
                                    fullWidth
                                    name="BudgetExperiment"
                                    isEdit={isEdit}
                                    placeholder={t(
                                      translations.BudgetExperiment,
                                    )}
                                    label={t(translations.BudgetExperiment)}
                                    disabled={
                                      formik.isSubmitting ||
                                      readonly ||
                                      (isEdit && !editable)
                                    }
                                    serviceId={formik.values.EquipmentsData}
                                    budgetId={formik.values.Budget?.Id ?? null}
                                    userId={formik.values.BookedBy?.Id ?? null}
                                    start={formik.values.StartTime}
                                    end={formik.values.EndTime}
                                  />
                                </FormRow>
                              )}
                          </>
                        )}
                        {globalSettings.purchaseOrderBudgetEnabled &&
                          equipmentSettings?.BudgetVisible &&
                          ((formik.values.Budget as IBudgetFilterDto)
                            ?.PurchaseOrderOptionId ??
                            PurchaseOrderOptions.Hidden) !==
                            PurchaseOrderOptions.Hidden && (
                            <FormRow>
                              <FormTextField
                                name="PurchaseOrder"
                                label={t(translations.PurchaseOrder)}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  (isEdit && !editable)
                                }
                                fullWidth
                              />
                            </FormRow>
                          )}
                        {reservationCollaboratorsVisible && (
                          <FormRow>
                            <FormUsersPicker
                              name="CollaboratedUsers"
                              label={t(translations.CollaboratorUsers)}
                              info={t(translations.CollaboratorUsers_Info)}
                              selectArray={[
                                'Id',
                                'Name',
                                'UserGroupId',
                                'UserGroupName',
                                'UserGroups',
                                'Budgets',
                              ]}
                              predicates={reservationCollaboratorsFilter}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                (isEdit && !editable)
                              }
                              fullWidth
                            />
                          </FormRow>
                        )}
                      </FormFieldsSection>
                    )}

                    {isEdit &&
                      (!useSidePanel || (useSidePanel && sidePanelExpanded)) &&
                      allowOngoingModifications && (
                        <ReservationWorkOrders
                          reservationId={formik.values.Id}
                          equipments={
                            formik.values
                              .EquipmentsData as IReservationEquipmentDto[]
                          }
                          onWorkTypeClick={onWorkTypeClick}
                        />
                      )}
                  </FormLeftSection>

                  <FormRightSection isCover={isCover}>
                    {(!print || (!customFormOnlyPrinted && print)) && (
                      <FormFieldsSection>
                        {(!useSidePanel ||
                          (useSidePanel && sidePanelExpanded)) && (
                          <React.Fragment>
                            <FormRow fullRow={true}>
                              <FormReservationStatusPicker
                                name="Status"
                                label={t(translations.ReservationStatus)}
                                disabled={
                                  formik.isSubmitting || readonly || !isAdmin
                                }
                                onChange={() => setStatusChanged(true)}
                                fullWidth
                              />
                            </FormRow>
                          </React.Fragment>
                        )}
                        {tutoringVisible && (
                          <FormRow fullRow={true}>
                            <FormSwitch
                              boldLebel
                              name="Tutoring"
                              id="tutoringSwitchId"
                              info={t(
                                translations.ReservationTrainingApproval_Desc,
                              )}
                              label={t(
                                translations.TutoringAssistedReservation,
                              )}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                !tutoringEnabled
                              }
                              onChange={() => setTutoringChanged(true)}
                            />
                          </FormRow>
                        )}
                        {(!useSidePanel ||
                          (useSidePanel && sidePanelExpanded && !isCover)) && (
                          <React.Fragment>
                            {trainingSignUpVisible && (
                              <FormRow fullRow={true}>
                                <FormSwitch
                                  boldLebel
                                  id="trainingSignupId"
                                  name="TrainingSignUp"
                                  info={t(translations.TrainingSignUp_info)}
                                  label={t(translations.TrainingSignUp)}
                                  disabled={
                                    formik.isSubmitting ||
                                    readonly ||
                                    reservationSettings?.TrainingSessionOnly
                                  }
                                  onChange={() => {
                                    setIsTrainingSignUp(!isTrainingSignUp);
                                  }}
                                />
                              </FormRow>
                            )}
                          </React.Fragment>
                        )}

                        {!customFormOnlyPrinted && print && (
                          <FormRow fullRow={true}>
                            <FormRichTextField
                              name="Remarks"
                              placeholder={t(translations.Remarks)}
                              disabled={
                                formik.isSubmitting ||
                                (isEdit && !editable) ||
                                readonly
                              }
                              fullWidth={true}
                            />
                          </FormRow>
                        )}
                      </FormFieldsSection>
                    )}
                    {!isEdit && !formik.values.TrainingSignUp && (
                      <RepetitiveControl
                        startDate={dateUtils.dateOrStringToDate(
                          formik.values.StartTime,
                        )}
                        disabled={readonly}
                        submitFormRef={repetitiveFormRef}
                        title={t(translations.RepetitiveReservation) as string}
                        type={RepetitiveFor.Reservations}
                        formIsValid={validateForms}
                      />
                    )}
                    {globalSettings.ServiceRequestReservationEnabled &&
                      isServiceAdmin &&
                      isEdit && (
                        <PrintPDFSection
                          print={print || false}
                          id="ServiceRequest"
                        >
                          <ReservationRequestDetails
                            reservationId={formik.values.Id}
                          />
                        </PrintPDFSection>
                      )}
                    {globalSettings.offlineServiceWithReservation &&
                      !!reservationSettings &&
                      (equipmentSettings?.OfflineServicesVisible ||
                        (reservationSettings?.OfflineMandatory !== undefined &&
                          reservationSettings?.OfflineMandatory?.length >
                            0)) && (
                        <PrintPDFSection
                          print={print || false}
                          id="OfflineServicesDetails"
                        >
                          <ReservationOfflineServices
                            hanleUpdateLocalServices={changeOfflineServices}
                            onServiceClick={onOfflineServiceClick}
                            equipmentSettings={equipmentSettings}
                            reservationSettings={reservationSettings}
                            globalSettings={globalSettings}
                            isEdit={isEdit}
                            onAddBatchClick={onAddBatchClick}
                            values={formik.values}
                            title={
                              t(translations.ReservationConsumables) as string
                            }
                            setError={setError}
                            disabled={
                              formik.isSubmitting ||
                              (isEdit && !editable) ||
                              readonly
                            }
                          />
                        </PrintPDFSection>
                      )}

                    {globalSettings.resCustomFormEnabled &&
                      !!reservationSettings &&
                      (reservationSettings.CustomForms?.length > 0 ||
                        (isEdit && formik.values.FormValues?.length > 0)) && (
                        <PrintPDFSection
                          print={print || false}
                          fullWidth={true}
                          id="CustomFormDetails"
                        >
                          <ReservationCustomForm
                            multiple={globalSettings.resMultipleFormsEnabled}
                            customForms={
                              !!reservationSettings &&
                              reservationSettings.CustomForms?.length > 0
                                ? reservationSettings.CustomForms
                                : []
                            }
                            readonly={readonly}
                            isAdmin={isAdmin}
                            customFormRef={customFormRef}
                            formIsValid={validateForms}
                            disabled={
                              formik.isSubmitting ||
                              (isEdit && !editable) ||
                              readonly
                            }
                            validationEnabled={!savedForLater}
                            saveFormValues={(
                              values: IFormValueDto[],
                              files: IFormFileValue[],
                              originalFiles: IFormFileValue[],
                            ) => {
                              formik.setFieldValue(
                                'FormValues',
                                updateFormValues(
                                  formik.values.FormValues,
                                  values,
                                ),
                                false,
                              );
                              setCurrentFormFiles(files);
                              setOriginalFormFiles(originalFiles);
                            }}
                            formValues={formik.values.FormValues}
                          />
                        </PrintPDFSection>
                      )}

                    {formik.values.SampleRunId !== null &&
                      formik.values.SampleRunId !== undefined && (
                        <FormRow>
                          <Button
                            variant="text"
                            to={generatePath(RUN_DETAILS_PAGE_PATH, {
                              id: formik.values.SampleRunId,
                            })}
                            title={t(translations.ReservationSampleRunId_Desc)}
                          >
                            {t(translations.SampleRunId)}:&ensp;
                            {formik.values.SampleRunId}
                          </Button>
                        </FormRow>
                      )}
                    {!print && (
                      <FormRow fullRow={true}>
                        <FormRichTextField
                          name="Remarks"
                          placeholder={t(translations.Remarks)}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            readonly
                          }
                          fullWidth={true}
                        />
                      </FormRow>
                    )}
                    {isEdit &&
                      !!reservationSettings &&
                      (!useSidePanel ||
                        (useSidePanel && sidePanelExpanded && !isCover)) && (
                        <FormFieldsSection>
                          <ReadOnlyDetails details={reservationSettings} />
                        </FormFieldsSection>
                      )}
                  </FormRightSection>
                </StyledForm>
              </PrintPDFSection>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
