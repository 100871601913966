/**
 *
 * EquipmentReservationPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { InputBaseComponentProps } from '@material-ui/core';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { IReservationEquipmentDto } from 'app/pages/ReservationDetails/Details/slice/types';
import { AutocompletePicker } from 'app/components/BasicPickers/AutocompletePicker';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
export interface ReservationEquipmentsPickerProps
  extends Omit<
    AutocompleteMultiPickerExProps<IReservationEquipmentDto>,
    'loadData'
  > {
  inputProps?: InputBaseComponentProps;
}
const url = '/api/odata/v4/ReservationEquipments';
export const initEquipmentsData = (
  initval: string | undefined,
  expand: string | undefined,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = (initval.indexOf(',') !== -1
      ? initval.split(',')
      : [initval]
    ).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];
    let foo: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: string | Condition<IReservationEquipmentDto>[] = [];

      predicates.push(new Condition('Id', ODataOperators.Includes, ids));

      foo = predicates.map(f => f.toString()).join(' and ');
    }

    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $expand?: string;
    } = {
      $orderby: 'Name asc',
      $filter: foo,
      $skip: 0,
      $top: 10,
      $expand: expand,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IReservationEquipmentDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  expandedColumns?: string,
) =>
  getAutoCompleteLoadDataFn<IReservationEquipmentDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'AccServices',
      'AllowOneAccSerOnly',
      'AllowPartialProjectCharges',
      'AllowPartialSingleProjectCharges',
      'AllowReservationCollaborators',
      'ForceFullDayReservations',
      'AssemblyId',
      'HasAssemblyParts',
      'AssetId',
      'Billable',
      'BudgetsTurnedOn',
      'Color',
      'DefaultAccServiceId',
      'DefaultReservationDuration',
      'FirstCustomFormId',
      'ForceTutoring',
      'HasAttendance',
      'HideProjects',
      'HideTrainingSession',
      'IsEquipmentTutoring',
      'MandatoriesEquipments',
      'OfflineServices',
      'MinOrderHours',
      'NotAllowReservations',
      'OptionalsEquipments',
      'ReservationOfflineServicesEnabled',
      'RestrictReservationToTimeSlots',
      'TrainingSignUpApproval',
      'ServiceGroupId',
      'ServiceGroupName',
      'CustomFormId',
      'FirstCustomFormId',
      'CustomForms',
      'AllowModification',
      'AllowExtendReservation',
      'AllowUsersToShortenReservations',
      'AllowedToTransfer',
      'AllowedToTransferAll',
      'EnforceReservationRestrictions',
      'LimitlessReservationAllowed',
      'MaxOrderHours',
      'MinOrderGap',
      'LoanTypeId',
      'StaffUserSelection',
      'StaffUserSelectionRequired',
      'StaffUserSelectionVisible',
      'RequireUserGroupCoordApproval',
      'DraftReservationsTypeId',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IReservationEquipmentDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],

    expand: expandedColumns,
  });
export function ReservationEquipmentsPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  expandedColumns,
  ...props
}: ReservationEquipmentsPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    predicates,
    globalServiceGroupFilter || [],
    expandedColumns,
  );
  return (
    <AutocompleteMultiPickerEx
      variant={variant}
      loadData={innerLoadData}
      onChange={onChange}
      disabled={disabled}
      info={info}
      id={id || 'reservation-equip-multi-picker'}
      helperText={helperText}
      required={required}
      fullWidth={props.fullWidth}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue ?? []}
      label={label}
      value={value ?? []}
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
export function ReservationSingleEquipmentPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  expandedColumns,
  ...props
}: ReservationEquipmentsPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    predicates,
    globalServiceGroupFilter || [],
    expandedColumns,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      variant={variant}
      onChange={value => {
        if (!!onChange) {
          if (value !== null) {
            return onChange([value]);
          } else {
            return onChange([]);
          }
        }
      }}
      disabled={disabled}
      info={info}
      id={id || 'reservation-equip-single-picker'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={
        !!defaultValue
          ? defaultValue.length > 0
            ? (defaultValue[0] as IReservationEquipmentDto | undefined | null)
            : undefined
          : undefined
      }
      label={label}
      value={
        !!value
          ? value.length > 0
            ? (value[0] as IReservationEquipmentDto | undefined | null)
            : undefined
          : undefined
      }
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
export function ReservationsEquipmentFilter(
  firstInitEquipments: number[],
  mandatoryIds: number[],
): (string | Condition<IReservationEquipmentDto>)[] {
  const predicates: (string | Condition<IReservationEquipmentDto>)[] = [
    // assembly parts should not appear in the equipment picker on the reservation side panel
    // only regular equipments and those that are at the top of the assembly are bookable directly
    new Condition<IReservationEquipmentDto>(
      'AssemblyId',
      ODataOperators.Equals,
      null,
    ),
  ];
  if (firstInitEquipments.length > 0) {
    let ids = firstInitEquipments.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IReservationEquipmentDto>(
        'Id',
        ODataOperators.Includes,
        ids,
      ),
    );
  }
  if (mandatoryIds.length > 0) {
    let ids = mandatoryIds.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IReservationEquipmentDto>(
        'Id',
        ODataOperators.Excludes,
        ids,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}

export const loadEquipments = loadData;
