import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scheduler } from 'app/pages/CalendarPage/Scheduler/Loadable';
import { SchedulerProps } from './Scheduler';
import { useSchedulerSlice } from './Scheduler/slice';
import { IViewState, ViewLengths, ViewTypes } from './Scheduler/slice/types';
import {
  IWithSavedHistoryComponentProps,
  withSavedHistoryT,
} from 'app/components/BasicTable/withSavedHistory';

import { PresetDatesRange } from 'types/PresetDatesRange';
import { dateUtils } from 'utils/date-utils';
import { presetPeriodToViewLength } from './Scheduler/utils/parseLength';
import { SchedulerDatePositionUnion } from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { CalendarHeader } from 'app/pages/CalendarPage/Scheduler/components/CalendarHeader/Loadable';
import { ValueChangeHandler } from 'types/ValueChangeHandler';
import { CombinedSchedulerTRow, getFilters } from './Filter';
import { getDefaultFilter } from './getDefaultFilter';
import { getRequiredFilter } from './getRequiredFilter';
import { MyListFilter } from 'app/pages/ReservationHistoryPage/MyListFilter';
import { getPredicates } from 'api/odata/getPredicates';
import { ErrorBoundary } from 'app/components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

import {
  selectCustomDaysTimelineButtonEnabled,
  selectDefaultCustomDaysTimelineValue,
  selectSchedulerLoadingEvents,
  selectSchedulerServicesLimit,
  selectSchedulerServicesLimitReached,
  selectTwoDaysTimelineButtonEnabled,
} from './Scheduler/slice/selectors';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import {
  TableRowsSkeleton,
  TableSkeleton,
} from 'app/components/BasicTable/components/TableSkeleton';
import {
  getPeriodFromViewLength,
  viewStateFromFilter,
} from './Scheduler/utils';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useLocation } from 'react-router';
import { H1 } from 'app/components/Typography';
import {
  groupByFilterFieldName,
  periodFilterFieldName,
} from './parseCalendarPageParams';

export type SchedulerWithFiltersProps = IWithSavedHistoryComponentProps<
  CombinedSchedulerTRow
> &
  SchedulerProps;
export const SchedulerWithFilters = function ({
  appliedFilters,
  availableFilters,
  getFilterValue,
  setFilterValue,
  ...props
}: SchedulerWithFiltersProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useSchedulerSlice();
  const location = useLocation();
  const customDaysEnabled = useSelector(selectCustomDaysTimelineButtonEnabled);
  const customDays = useSelector(selectDefaultCustomDaysTimelineValue);
  const twoDaysEnabled = useSelector(selectTwoDaysTimelineButtonEnabled);
  const isMobile = DetectIsMobile();
  const loadingEvents = useSelector(selectSchedulerLoadingEvents);
  // change scheduler period & date when "Date Period" is changed in the filter
  React.useEffect(() => {
    const periodValue: PresetDatesRange = getFilterValue?.(
      periodFilterFieldName,
    ) as PresetDatesRange;
    const preset = (periodValue as PresetDatesRange)?.type?.Id;
    const date = (periodValue as PresetDatesRange)?.start;
    if (preset !== undefined && date !== undefined && date !== null) {
      const new_state: Partial<IViewState> = {
        viewLength: presetPeriodToViewLength(
          preset as SchedulerDatePositionUnion,
          props.viewType,
        ),
        date: dateUtils.formatISO(date),
      };

      dispatch(actions.changeViewModePartial(new_state));
    }
  }, [actions, dispatch, getFilterValue, props.viewType]);

  // collapsed state of the filters
  // reference to the show/hide filters button
  const [
    filtersButtonAnchorEl,
    setFiltersButtonAnchorEl,
  ] = React.useState<HTMLButtonElement | null>(null);
  const filterRef = React.useRef(null);
  // collapsed state of the filters
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const handleFilterOpen = React.useCallback(() => {
    setFiltersButtonAnchorEl(filterRef.current);
    setFilterOpen(true);
  }, []);
  const handleFilterClose = React.useCallback(() => {
    setFiltersButtonAnchorEl(null);
    setFilterOpen(false);
  }, []);

  const fetchData = React.useCallback(() => {
    const serviceFilterId = 'service';
    const service = getFilterValue?.(serviceFilterId);
    // wait for a services value where service name is already populated
    // if the Name is empty then there the appliedFilters will immediately change after the population
    if (Array.isArray(service) && service.some(f => f.Name === undefined)) {
      return;
    }
    const foo = appliedFilters?.reduce((acc, current) => {
      if (current.id !== undefined) {
        let setValue: FilterValueType | undefined = undefined;
        if (current.id === 'period') {
          setValue = (current.value as PresetDatesRange)?.type?.Id;
        } else {
          setValue = current.value;
        }
        const predicate = {
          value: setValue,
          predicate: getPredicates<CombinedSchedulerTRow>(
            [current],
            // todo: deal with the customParams
            () => void 0,
            () => void 0,
          ).join(' and '),
        };
        if (predicate.predicate !== '') {
          acc[current.id] = predicate;
        }
      }
      return acc;
    }, {});
    dispatch(actions.setFilters(foo));
  }, [actions, appliedFilters, dispatch, getFilterValue]);

  const handleRefresh = React.useCallback(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = (
    value: IFilterSettings<CombinedSchedulerTRow>[],
  ) => {
    props.onFilterChange?.(value);
  };
  const handleSetPeriodFilter = React.useCallback(
    (length: ViewLengths, value?: Date) => {
      let changedValue = getPeriodFromViewLength(
        length,
        value ?? dateUtils.parseISO(props.date),
        customDays,
      );
      !!setFilterValue && setFilterValue(periodFilterFieldName, changedValue);
    },
    [customDays, props.date, setFilterValue],
  );
  const updateSearchPeriod = (
    preset: SchedulerDatePositionUnion | null,
    search?: string,
  ) => {
    if (preset !== null) {
      const params = new URLSearchParams(search ?? '');
      if (params.has(periodFilterFieldName)) {
        params.delete(periodFilterFieldName);
      }
      params.set(periodFilterFieldName, preset);
      return params.toString();
    }
    return search;
  };
  const handleViewTypeLengthChange = (
    type: ViewTypes,
    length: ViewLengths,
    value?: Date,
    noChangeLocation?: boolean,
  ) => {
    let presetValue = getPeriodFromViewLength(
      length,
      value ?? dateUtils.parseISO(props.date),
      customDays,
    );
    handleSetPeriodFilter(length, value);
    let searchComp =
      updateSearchPeriod(
        viewStateFromFilter(presetValue.type)?.preset ?? null,
        location.search,
      ) ?? location.search;
    dispatch(
      actions.changeViewMode({
        current: {
          viewType: type,
          viewLength: length,
          date: !!value ? dateUtils.formatISO(value) : props.date,
          preset: isMobile
            ? null
            : viewStateFromFilter(presetValue.type)?.preset ?? null,
          search: searchComp,
          customDays: customDays,
          customStart: undefined,
        },
        withNoChangeLocation: noChangeLocation,
      }),
    );
  };

  const handleMultipleModeChange: ValueChangeHandler<boolean> = value => {
    dispatch(actions.updateMultiMode(value));
  };
  const servicesLimit = useSelector(selectSchedulerServicesLimit);
  const limitReached = useSelector(selectSchedulerServicesLimitReached);
  React.useEffect(() => {
    if (limitReached) {
      const msg = t(translations.SchedulerServicesLimitReachedWarningMessage, {
        servicesLimit: servicesLimit,
      });
      dispatch(
        appSettingsActions.addNotification({
          key: translations.SchedulerServicesLimitReachedWarningMessage,
          variant: 'warning',
          message: msg,
        }),
      );
    }
  }, [dispatch, limitReached, servicesLimit, t]);

  if (appliedFilters === undefined) {
    return null;
  }

  const showSkeleton =
    props.services === undefined ||
    props.viewType === undefined ||
    props.viewLength === undefined;

  if (showSkeleton) {
    return <TableSkeleton isMobile={isMobile} isCards={false} />;
  }

  return (
    <>
      <CalendarHeader
        viewType={props.viewType}
        viewLength={props.viewLength}
        date={props.date}
        appliedFilters={appliedFilters}
        availableFilters={availableFilters}
        onFilterChange={handleFilterChange}
        onMultipleModeChange={handleMultipleModeChange}
        filtersButtonAnchorEl={filtersButtonAnchorEl}
        onFilterClose={handleFilterClose}
        onFilterOpen={handleFilterOpen}
        filterRef={filterRef}
        setFilterValue={setFilterValue}
        filterOpen={filterOpen}
        customDays={customDays}
        customDaysEnabled={customDaysEnabled}
        twoDaysEnabled={twoDaysEnabled}
        handleViewTypeLengthChange={handleViewTypeLengthChange}
        handleSetPeriodFilter={handleSetPeriodFilter}
        updateSearchPeriod={updateSearchPeriod}
        getSavedDefaultFilters={props.getSavedDefaultFilters}
        onRefresh={handleRefresh}
        loading={!!loadingEvents}
      />
      {loadingEvents ? (
        <TableRowsSkeleton isMobile={isMobile} isCards={false} />
      ) : (
        <ErrorBoundary fallBack={<H1>{t(translations.AnErrorOccurred)}</H1>}>
          <Scheduler
            services={props.services}
            events={props.events}
            defaultDuration={props.defaultDuration}
            nonWorkingHours={props.nonWorkingHours}
            tutoringHours={props.tutoringHours}
            date={props.date}
            viewType={props.viewType}
            viewLength={props.viewLength}
            presetPosition={props.presetPosition}
            search={props.search}
            onDataUpdated={props.onDataUpdated}
            onViewModeChange={props.onViewModeChange}
            onFilterChange={handleFilterChange}
            onEdit={props.onEdit}
            appliedFilters={appliedFilters}
            onCreate={props.onCreate}
            setFilterValue={setFilterValue}
            reservationEditEvents={props.reservationEditEvents}
            customDays={customDays}
            customDaysEnabled={customDaysEnabled}
            twoDaysEnabled={twoDaysEnabled}
            handleViewTypeLengthChange={handleViewTypeLengthChange}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export const SchedulerConnectedWithFilters = withSavedHistoryT<
  CombinedSchedulerTRow,
  SchedulerWithFiltersProps
>(
  SchedulerWithFilters,
  '/schedule',
  getFilters,
  getDefaultFilter,
  [periodFilterFieldName, groupByFilterFieldName],
  getRequiredFilter,
  MyListFilter,
  true,
);
