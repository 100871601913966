/**
 *
 * QuizDetails
 *
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IQuizSubmissionDto } from 'api/odata/generated/entities/IQuizSubmissionDto';
import { httpClient } from 'api/HttpClient';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';

import { useDispatch, useSelector } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { Box, Grid } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import parse from 'html-react-parser';
import { Link } from 'app/components/ExternalLink';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { Button } from 'app/components/BasicButtons/Button';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { push } from 'connected-react-router';
import { ParseFormFieldEnums } from 'app/components/CustomForm/CustomFormUtils';
import { Progress } from 'app/components/LoadingIndicator';
import {
  FormFieldsSection,
  FormLeftSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';

export interface QuizDetailsProps extends SidePanelContentProps {
  id: string;
}

export function QuizDetails(props: QuizDetailsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { id, useSidePanel } = props;
  const quizSubmissionId = +id;
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const isMobile = DetectIsMobile();
  const { actions: layoutActions } = useLayoutSlice();
  const fetchData = async (id: number) =>
    httpClient
      .get<IQuizSubmissionDto>(
        `/api/odata/v4/quizsubmissions(${id})?$expand=UserTraining($select=Id,EquipmentName,TrainingLevelName,EquipmentId)`,
      )
      .then(value => {
        ParseFormFieldEnums(value.CustomForm.FormFields);
        return value;
      });
  const [fetchDataPromiseState, fetchDataPromise] = usePromise(fetchData);
  const [state, setState] = useAsyncExtendedState<
    IQuizSubmissionDto | undefined
  >(undefined);
  useEffectOnMount(() => {
    setState(fetchDataPromise(quizSubmissionId));
    return () => {};
  });
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  useEffect(() => {
    if (fetchDataPromiseState.error !== undefined) {
      dispatch(
        actions.addNotification({
          message: 'Error',
          key: 'QuizDetailsDataLoad',
          variant: 'error',
        }),
      );
    }
  }, [actions, dispatch, fetchDataPromiseState.error]);
  const success = React.useMemo(() => state?.UserTraining !== null, [
    state?.UserTraining,
  ]);

  const backToQuizess = () => {
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
      dispatch(push(`/quizzes`));
    } else {
      dispatch(push(`/quizzes`));
    }
  };
  const pageActions = [
    () => (
      <React.Fragment>
        <Button
          size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
          startIcon={<Icon icon="arrow-left" />}
          onClick={backToQuizess}
        >
          {t(translations.BackToQuizzes)}
        </Button>
      </React.Fragment>
    ),
  ] as ActionRenderer[];
  return (
    <PageWrapper
      useSidePanel={useSidePanel}
      pageName={
        !useSidePanel || sidePanelExpanded
          ? (t(translations.Quiz) as string)
          : undefined
      }
      titlePage={state?.CustomForm.Name}
      children={
        !!fetchDataPromiseState.status &&
        fetchDataPromiseState.status !== 'pending' &&
        !!state ? (
          <Box component="div" marginTop={3}>
            <StyledFormFieldsContainer>
              <FormLeftSection>
                <FormFieldsSection>
                  <Grid container direction="column" spacing={2}>
                    <Grid item key="score">
                      <Body bold={true}>
                        {(t(translations.QuizzYourScoreIsXoutofY) as string)
                          .replace('{0}', (state?.Score ?? 0).toString())
                          .replace('{1}', (state?.MaxScore ?? 0).toString())}
                      </Body>
                    </Grid>
                    {success ? (
                      <Grid item key="score-succs">
                        <Box
                          component="span"
                          style={{
                            display: 'flex',
                            gap: 16,
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            component="span"
                            style={{
                              display: 'flex',
                              gap: 8,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {t(
                              translations.CongratulationsYouHavePassedTheQuiz,
                            )}
                          </Box>
                          {state?.UserTraining?.EquipmentName && (
                            <Box
                              component="span"
                              style={{
                                display: 'flex',
                                gap: 8,
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <span>{`${t(
                                translations.YouAreNowCertified,
                              )} on ${t(translations.Equipment)}: `}</span>
                              <Link
                                href={`/Assets/Details.aspx?EquipmentId=${state?.UserTraining?.EquipmentId}`}
                                target="_blank"
                              >
                                {state?.UserTraining?.EquipmentName}
                              </Link>
                              <span>
                                {state?.UserTraining?.TrainingLevelName
                                  ? ` with ${t(translations.Level)}: ${
                                      state?.UserTraining?.TrainingLevelName
                                    }`
                                  : ''}
                              </span>
                            </Box>
                          )}
                          {state?.CustomForm?.QuizSuccessTestMessage && (
                            <Box>
                              {parse(
                                state.CustomForm.QuizSuccessTestMessage || '',
                              )}
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item key="score-err">
                        <Body bold={true}>
                          {t(translations.SorryThisDidNotWorkSoWell)}
                        </Body>
                        {state?.CustomForm?.QuizFailureTestMessage && (
                          <Box>
                            {parse(
                              state.CustomForm.QuizFailureTestMessage || '',
                            )}
                          </Box>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </FormFieldsSection>
              </FormLeftSection>
            </StyledFormFieldsContainer>
          </Box>
        ) : (
          <React.Fragment>
            <Box component="div">
              <Progress
                inProgress={fetchDataPromiseState.status === 'pending'}
                size={80}
              />
            </Box>
          </React.Fragment>
        )
      }
      leftActions={pageActions}
      closable={true}
    />
  );
}
