import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { IUsageEquipmentDto } from 'app/pages/UsageDetails/Details/slice/types';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export interface UsageEquipmentPickerProps
  extends Omit<AutocompletePickerProps<IUsageEquipmentDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
}
const url = '/api/odata/v4/UsageEquipment';
export const initUsageEquipmentsData = (
  initval: string | undefined,
  expand: string | undefined,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = (initval.indexOf(',') !== -1
      ? initval.split(',')
      : [initval]
    ).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];
    let foo: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: string | Condition<IUsageEquipmentDto>[] = [];

      predicates.push(new Condition('Id', ODataOperators.Includes, ids));

      foo = predicates.map(f => f.toString()).join(' and ');
    }

    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $expand?: string;
    } = {
      $orderby: 'Name asc',
      $filter: foo,
      $skip: 0,
      $top: 10,
      $expand: expand,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IUsageEquipmentDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  expandedColumns?: string,
) =>
  getAutoCompleteLoadDataFn<IUsageEquipmentDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'AccServices',
      'AllowOneAccSerOnly',
      'Inventory',
      'AllowOverlappingUsages',
      'AutoEndUsageOnStart',
      'TypeId',
      'ReservationRequired',
      'AssetId',
      'Billable',
      'BudgetsTurnedOn',
      'Color',
      'DefaultAccServiceId',
      'ForceTutoring',
      'HideProjects',
      'IsEquipmentTutoring',
      'NotAllowReservations',
      'ServiceGroupId',
      'ServiceGroupName',
      'OfflineUsageEndServices',
      'LoanTypeId',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IUsageEquipmentDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],

    expand: expandedColumns,
  });

export function UsageEquipmentPicker({
  id,
  predicates,
  expandedColumns,
  ...props
}: UsageEquipmentPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    predicates,
    globalServiceGroupFilter || [],
    expandedColumns,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={id || 'usage-equip-single-picker'}
      ariaLabel={'Equipment search'}
      {...props}
    />
  );
}
