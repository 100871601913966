import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { PaymentApi } from 'api/PaymentApi';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
// import { FormAdminServiceGroupPicker } from 'app/components/Forms/FormAdminServiceGroupPicker';
import { FormBudgetPicker } from 'app/components/Forms/FormBudgetPicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormPaymentMethodPicker } from 'app/components/Forms/FormPaymentMethodPicker';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { serviceGroupFilterUrl } from 'app/components/pickers/ServiceGroupPicker';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useResponseModel } from 'app/hooks/useResponseModel';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { selectAppSettings } from 'app/slice/selectors';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { Entity } from 'types/common';
import * as Yup from 'yup';
import { IPayment } from './IPayment';
import { getPaymentSchema } from './PaymentSchema';
import { getPaymentSchemaWithBudgetNotRequired } from './PaymentSchema';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export interface PaymentProps extends CoverProps {
  invoiceId?: number;
  budget: Entity<number> | null;
  serviceGroup: Entity<number> | null;
  amount?: number;
  paymentId?: number;
  serviceGroupPredicate?: (string | Condition<Entity<number>>)[];
  userGroupId?: string;
}

export const AddPayment = (props: PaymentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const appSettings = useSelector(selectAppSettings);
  const { actions: notifyActions } = useAppSettingsSlice();
  const submitRef = useRef<any>();
  const [busy, setBusy] = useState(false);
  const close = useCallback(() => {
    if (props.isCover) {
      props.closeCover?.();
    } else {
      dispatch(actions.resetSidePanel());
    }
  }, [props, dispatch, actions]);
  const refreshTable = useCallback(() => {
    dispatch(actions.setRefreshTable(true));
  }, [dispatch, actions]);
  const sendResponse = useResponseModel();
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const closeApproved = useSelector(selectConfirmApproved);
  const closeRejected = useSelector(selectConfirmRejected);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isEdit = !!props.paymentId && !!props.invoiceId;
  const { newDate } = useSystemDate();
  const submit = useCallback(
    (payment: IPayment) => {
      setBusy(true);
      if (!isEdit) {
        PaymentApi.AddPayment(payment)
          .then(v => {
            if (v.SuccessMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  key: 'payment_success',
                  message: v.SuccessMessages[0],
                  variant: 'success',
                }),
              );
              v.SuccessMessages = [];
            }
            sendResponse(v);
          })
          .catch(() => {
            dispatch(
              notifyActions.addNotification({
                message: t(translations.err_InvoicePaymentAdd),
                variant: 'error',
              }),
            );
          })
          .finally(() => {
            close();
            refreshTable();
          });
      } else {
        PaymentApi.UpdatePayment(props.paymentId!, payment)
          .then(() => {
            dispatch(
              notifyActions.addNotification({
                message: t(translations.success_EditInvoicePayment),
                variant: 'success',
              }),
            );
          })
          .catch(() => {
            dispatch(
              notifyActions.addNotification({
                message: t(translations.err_EditInvoicePayment),
                variant: 'error',
              }),
            );
          })
          .finally(() => {
            close();
            refreshTable();
          });
      }
    },
    [
      close,
      dispatch,
      isEdit,
      notifyActions,
      props.paymentId,
      refreshTable,
      sendResponse,
      t,
    ],
  );
  const [initialValues, setinitialValues] = useState<IPayment>({
    InvoiceId: props.invoiceId,
    Budget: props.budget,
    ServiceGroup: props.serviceGroup,
    Amount: props.amount,
    Method: null,
    Date: newDate(),
  });
  useEffect(() => {
    if (closeApproved) {
      dispatch(actions.resetSidePanel());
    }
    if (closeRejected) {
      dispatch(actions.resetConfirmState());
    }
  }, [actions, closeApproved, closeRejected, dispatch]);
  useEffectOnMount(() => {
    //dispatch(layoutActions.setUpSnackBarIndex(true)); // increase snack bar z-index
    if (!isEdit) {
      if (props.serviceGroup === null) {
        setBusy(true);
        let isMounted = true;
        (async () => {
          const res = await getAutoCompleteLoadDataFn<Entity<number>>({
            url: serviceGroupFilterUrl,
            predicates: authenticatedUser
              ? [
                  new Condition(
                    'Id',
                    ODataOperators.Includes,
                    authenticatedUser.AdminServiceGroups,
                  ),
                ]
              : undefined,
          })('', props.serviceGroupPredicate).then(v => v.value);
          if (isMounted) {
            if (res && res.length === 1) {
              setinitialValues(prev => ({ ...prev, ServiceGroup: res[0] }));
            }
            setBusy(false);
          }
        })();
        return () => {
          isMounted = false;
        };
      }
      return;
    }
    setBusy(true);
    let mounted = true;
    (async () => {
      try {
        let res = await httpClient.get(
          '/api/odata/v4/InvoicePayments(' + props.paymentId + ')',
          {
            id: props.invoiceId,
            $select: [
              'InvoiceId',
              'MethodId',
              'PaymentMethodName',
              'Amount',
              'Date',
              'Remarks',
              'Reference',
              'BudgetId',
              'BudgetNumber',
              'ServiceGroupId',
              'ServiceGroupName',
            ].join(','),
          },
        );
        if (mounted) {
          setinitialValues({
            Id: props.paymentId,
            InvoiceId: res.InvoiceId,
            Budget: res.BudgetId
              ? { Id: res.BudgetId, Name: res.BudgetNumber }
              : null,
            ServiceGroup: res.ServiceGroupId
              ? { Id: res.ServiceGroupId, Name: res.ServiceGroupName }
              : null,
            Method: { Id: res.MethodId, Name: res.PaymentMethodName },
            Date: res.Date,
            Amount: res.Amount,
            Reference: res.Reference ?? undefined,
            Remarks: res.Remarks ?? undefined,
          });
          setBusy(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      //dispatch(layoutActions.setUpSnackBarIndex(false)); // decrease snack bar z-index
      mounted = false;
    };
  });

  const PaymentSchema: Yup.SchemaOf<IPayment> = getPaymentSchema(t);
  const PaymentSchemaWithBudgetNotRequired: Yup.SchemaOf<IPayment> = getPaymentSchemaWithBudgetNotRequired(
    t,
  );
  if (isEdit) {
    PaymentSchema.fields.ServiceGroup = PaymentSchema.fields.ServiceGroup.notRequired();
  }

  const Paymentform = () => (
    <Formik
      validationSchema={
        IsModuleEnabled(appSettings, KnownModules.Budgets) &&
        initialValues.Budget !== null
          ? PaymentSchema
          : PaymentSchemaWithBudgetNotRequired
      }
      initialValues={initialValues}
      validateOnMount
      onSubmit={submit}
    >
      {formik => {
        submitRef.current = formik.submitForm;
        return (
          <StyledForm onSubmit={formik.handleSubmit}>
            {/* By using FormListener, the fields become not editable */}
            {/* <FormListener fields={[]} onFormChange={() => {}} /> */}
            <FormLeftSection>
              <FormFieldsSection>
                <FormRow fullRow>
                  <FormServiceGroupPicker
                    name="ServiceGroup"
                    fullWidth
                    placeholder={t(translations.ServiceGroup)}
                    label={t(translations.ServiceGroup)}
                    disabled={
                      busy || initialValues.ServiceGroup !== null || isEdit
                    }
                    urlType="admin"
                    predicates={props.serviceGroupPredicate}
                    autoFocus
                  />
                </FormRow>
                {IsModuleEnabled(appSettings, KnownModules.Budgets) && (
                  <FormRow fullRow>
                    <FormBudgetPicker
                      name="Budget"
                      fullWidth
                      placeholder={t(translations.Budget)}
                      label={t(translations.Budget)}
                      disabled={initialValues.Budget !== null || isEdit}
                      predicates={
                        props.userGroupId
                          ? [
                              new Condition(
                                'UserGroupId',
                                ODataOperators.Equals,
                                props.userGroupId,
                              ),
                            ]
                          : undefined
                      }
                    />
                  </FormRow>
                )}
                <FormRow fullRow>
                  <FormBookitDatePicker
                    fullWidth
                    name="PaymentDate"
                    id="paymentDate_id"
                    placeholder={t(translations.Date)}
                    label={t(translations.Date)}
                    disabled={busy || isEdit}
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormNumberField
                    fullWidth
                    name="Amount"
                    id="payment_amount_Id"
                    inputMode="decimal"
                    placeholder={t(translations.Amount)}
                    label={t(translations.Amount)}
                    disabled={busy}
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormPaymentMethodPicker
                    fullWidth
                    name="Method"
                    placeholder={t(translations.PaymentMethod)}
                    label={t(translations.PaymentMethod)}
                    disabled={busy || isEdit}
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormTextField
                    name="Reference"
                    id="referenceId"
                    fullWidth
                    placeholder={t(translations.Reference)}
                    label={t(translations.Reference)}
                    disabled={busy}
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormRichTextField
                    fullWidth
                    name="Remarks"
                    id="remarksId"
                    placeholder={t(translations.Remarks)}
                    label={t(translations.Remarks)}
                    disabled={busy}
                  />
                </FormRow>
              </FormFieldsSection>
            </FormLeftSection>
          </StyledForm>
        );
      }}
    </Formik>
  );
  const leftActions = [
    () => (
      <>
        <Button
          onClick={() => submitRef.current?.()}
          size="small"
          disabled={busy}
        >
          {t(translations.Submit)}
        </Button>
      </>
    ),
  ];
  const rightActions = [
    () => (
      <>
        <Button
          variant="ghost"
          size="small"
          startIcon={<Icon icon="times" />}
          onClick={close}
          disabled={busy}
        >
          {t(translations.Cancel)}
        </Button>
      </>
    ),
  ];
  return (
    <PageWrapper
      id="add_payment_page"
      loading={busy}
      titlePage={
        isEdit
          ? `${t(translations.Payment)} #${props.paymentId}`
          : t(translations.AddPaymentInvoice)
      }
      closable
      useSidePanel
      children={<Paymentform />}
      leftActions={leftActions}
      rightActions={rightActions}
      disableExpandToggle
      closeSidePanel={() => {
        if (hasChanges) {
          dispatch(actions.setConfirmOpen(true));
        } else {
          dispatch(actions.resetSidePanel());
        }
      }}
      isCover={props.isCover}
      closeCover={props.closeCover}
    />
  );
};
