import { IEditEvent } from 'app/pages/CalendarPage/Scheduler/slice/types';
import { bookitColors } from 'styles/colors/bookitColors';
import {
  captionBold,
  captionRegular,
  tipsRegular,
} from 'styles/typography/bookitTypography';
import cliTruncate from 'cli-truncate';
import { dateUtils } from 'utils/date-utils';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';

export interface ReservationEventProps {
  event: IEditEvent;
}

export function ReservationEvent(props: ReservationEventProps) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: '8px',
        background: bookitColors.primary.background,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0 0',
          gap: 4,
          width: '100%',
        }}
      >
        <span
          style={{
            ...captionBold,
            color: bookitColors.grayscale.blackRegular,
          }}
        >
          {cliTruncate(props.event.original.EquipmentName, 50, {
            position: 'end',
          })}
          {`  by  `}
          {(props.event.original as ICalendarReservationDto).BookedBy ??
            props.event.original.BookedById}
        </span>
        <span
          style={{
            ...captionRegular,
            color: bookitColors.grayscale.graySubtitles,
          }}
        >
          {`New Time: ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.start_date),
            'dd MMM hh:mm',
          )} - ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.end_date),
            'dd MMM hh:mm',
          )}`}
        </span>
        <span
          style={{
            ...tipsRegular,
            color: bookitColors.grayscale.grayComponents,
          }}
        >
          {`Before: ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.original.StartTime),
            'dd MMM hh:mm',
          )} - ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.original.EndTime),
            'dd MMM hh:mm',
          )}`}
        </span>
      </div>
    </div>
  );
}
