import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Link } from 'app/components/ExternalLink';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { ReservationQueryStringParameters } from 'app/pages/ReservationDetails/Details/slice/types';
import { IsReadOnlyUser } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { TrainingState } from 'enums/TrainingState';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { DetectIsMobile } from 'utils/mobileDetect';
import { buildURL } from 'utils/url-utils';

export interface ReservationLinkProps {
  Id: number | null;
  HasNextSession: boolean;
  TrainingState: number;
  hasReservation: boolean;
  myReservationId: number | null;
  ServiceId: number | null;
  NextSession: Date | null;
  EndDate: Date | null;
  StartDate: Date | null;
  iconsize?: SizeProp;
  asLinkRendered?: boolean;
}
export const ReservationLinkAction = React.forwardRef<
  any,
  ReservationLinkProps
>(function ReservationLinkAction(props, ref) {
  const { t } = useTranslation();
  const { openPanel } = useSidePanelState();
  const user = useSelector(selectAuthenticatedUser);
  const isMobile = DetectIsMobile();
  const readOnlyUser = IsReadOnlyUser(user);
  //const item = props.row;
  //const hasSession = props.HasNextSession;

  const state = props.TrainingState as TrainingState;
  const params = (props.hasReservation
    ? {
        id: String(props.myReservationId),
      }
    : {
        selectedIds: String(props.ServiceId),
        tsid: String(props.Id),
        Start: dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(props.StartDate ?? new Date()),
        ),
        End: dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(props.EndDate ?? new Date()),
        ),
        un: user?.Id,
      }) as ReservationQueryStringParameters;

  const url = buildURL('/reservations/details', params);
  return props.HasNextSession && !readOnlyUser ? (
    <Link
      ref={ref}
      href={url}
      onClick={event => {
        if (!event.ctrlKey) {
          event.preventDefault();
          openPanel({
            renderPageType: RenderPageType.ReservationDetails,
            renderPageProps: {
              queryParams: params,
              useSidePanel: true,
            } as ReservationDetailsProps,
            expanded: false,
            useSidePanel: false,
            isCover: false,
          } as SidePanelOpenState);
        }
      }}
      aria-label="Session Indicator"
    >
      <Tooltip
        ref={ref}
        title={
          state === TrainingState.SignedUp
            ? (t(translations.TrainingSessions_Calendar_SignedUp) as string)
            : state === TrainingState.UnderMinimum
            ? (t(translations.TrainingSessions_Calendar_UnderMinimum) as string)
            : state === TrainingState.Maximum
            ? (t(translations.TrainingSessions_Calendar_Maximum) as string)
            : (t(translations.TrainingSessions_Calendar_Available) as string)
        }
        arrow
      >
        <Box display="flex" justifyContent="center">
          <Icon
            size={isMobile ? undefined : props.iconsize || '2x'}
            color={
              state === TrainingState.SignedUp
                ? 'success'
                : state === TrainingState.UnderMinimum
                ? 'primary'
                : state === TrainingState.Maximum
                ? 'danger'
                : 'primary'
            }
            icon={
              state === TrainingState.SignedUp
                ? isMobile
                  ? 'calendar-check'
                  : ['fad', 'calendar-check']
                : state === TrainingState.UnderMinimum
                ? isMobile
                  ? 'calendar-exclamation'
                  : ['fad', 'calendar-exclamation']
                : state === TrainingState.Maximum
                ? isMobile
                  ? 'calendar-times'
                  : ['fad', 'calendar-times']
                : isMobile
                ? 'calendar-plus'
                : ['fad', 'calendar-plus']
            }
          />
        </Box>
      </Tooltip>
    </Link>
  ) : (
    <React.Fragment>{'N/A'}</React.Fragment>
  );
});
