import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { dateUtils } from 'utils/date-utils';
import {
  createOptions,
  getDropDownInitValue,
} from './CustomFormFields/CustomFormDropDown';
import * as Yup from 'yup';
import * as React from 'react';
import { ObjectShape } from 'yup/lib/object';
import { RequiredTypes } from 'api/odata/generated/enums/RequiredTypes';
import { Entity } from 'types/common';
import { DisplayTypes } from 'api/odata/generated/enums/DisplayTypes';
import { isEmptyOrWhitespace, isNullOrUndefined } from 'utils/typeUtils';
import { useSelector } from 'react-redux';
import {
  selectForbiddenFileExtensions,
  selectUploadFileSizeLimit,
} from 'app/slice/selectors';
import { differenceWith } from 'lodash';
import {
  IServiceRequestTableColumnModel,
  IServiceRequestTableRowValueModel,
} from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { IFormFieldOptionDto } from 'api/odata/generated/entities/IFormFieldOptionDto';
import { assertExhaustive } from 'utils/assertExhaustive';

export interface IFormFileValue {
  Id: number;
  Value: string | null;
  DisplayValue?: string | null;
  Size: number | null;
  PostedFile: File | null;
}
export function instanceOfIFormValueType(
  objects: any,
): objects is IFormFileValue[] {
  const members = ['Id', 'Value', 'DisplayValue', 'Size', 'PostedFile'];
  return (
    Array.isArray(objects) &&
    objects.every(object => members.every(member => member in object))
  );
}
export const formFileSchema: Yup.SchemaOf<IFormFileValue> = Yup.object({
  Id: Yup.number().default(-1),
  Value: Yup.string().nullable().default(null),
  DisplayValue: Yup.string().nullable(),
  Size: Yup.number().nullable(true).default(0),
  PostedFile: Yup.mixed().nullable(true).default(null),
});
export const formValueSchema: Yup.SchemaOf<IFormValueDto> = Yup.object({
  Id: Yup.number().default(-1),
  Value: Yup.string().default('').nullable(),
  DisplayValue: Yup.string().nullable().default(null),
  FormFieldId: Yup.number().default(-1),
  Readonly: Yup.boolean().default(false),
  SourceValueId: Yup.number().nullable(true).notRequired().default(null),
  AlertId: Yup.number().nullable(true).notRequired().default(null),
  AlertTypeId: Yup.number().nullable(true).notRequired().default(null),
  CustomFormId: Yup.number().nullable().default(null),
  CustomFormName: Yup.string().nullable().default(null),
  RequestId: Yup.number().nullable().default(null),
  ReservationId: Yup.number().nullable().default(null),
  ExperimentItemId: Yup.number().nullable().default(null),
  BudgetExperimentId: Yup.number().nullable().default(null),
  ServiceGroupUserId: Yup.number().nullable().default(null),
  ServiceGroupId: Yup.number().nullable().default(null),
});

export const ParseFormFieldEnums = (formFields: IFormFieldDto[]) =>
  formFields.forEach(ff => {
    ff.Required = RequiredTypes[ff.Required.toString()];
  });
export const DeserializeValue = (
  formField: IFormFieldDto,
  formValue: IFormValueDto | null,
) => {
  switch (formField.Type) {
    case 'ShortText':
    case 'Email':
    case 'Number':
    case 'PositiveNumber':
    case 'RichText':
    case 'MultipleAnswersQuestion':
    case 'RadioButtons':
      if (
        formValue &&
        formValue.Value &&
        !isEmptyOrWhitespace(formValue.Value)
      ) {
        return formValue.Value;
      }
      return null;
    case 'Date':
      if (
        formValue &&
        formValue.Value &&
        !isEmptyOrWhitespace(formValue.Value)
      ) {
        return dateUtils.tryParse(formValue.Value);
      }
      return null;
    // case 'AlphanumericWithDashes':
    //   return getAlphaNumericValue(formValue);
    case 'CheckBox':
    case 'Disclaimer':
      if (
        formValue &&
        formValue.Value &&
        !isEmptyOrWhitespace(formValue.Value)
      ) {
        return formValue.Value.toLowerCase() === 'true';
      }
      return false;
    case 'DropDownList':
    case 'UserSelection':
      if (
        formValue &&
        formValue.Value &&
        !isEmptyOrWhitespace(formValue.Value)
      ) {
        const options = createOptions(formField);
        let ddlValue = getDropDownInitValue(
          formValue.Value ?? undefined,
          formField.IsMultiple,
          options,
        );
        return formField.IsMultiple ? ddlValue : ddlValue[0];
      } else {
        return formField.IsMultiple ? [] : null;
      }
    case 'FormSection':
      return null;
    default:
      return null;
  }
};
export type CustomFormValueType =
  | string
  | Date
  | number
  | boolean
  | IFormFileValue
  | Entity<string>
  | Array<Entity<string>>;
export const SerializeValue = (
  formField: IFormFieldDto,
  formValue: CustomFormValueType,
): string | null => {
  if (formValue === null || formValue === undefined) {
    return null;
  }
  switch (formField.Type) {
    case 'CheckBox':
    case 'Disclaimer':
      return (formValue as boolean).toString();
    case 'Date':
      return (
        dateUtils.tryFormatIso(formValue, {
          format: 'basic',
          representation: 'date',
        }) ?? null
      );

    case 'Email':
    case 'Number':
    case 'PositiveNumber':
    case 'RichText':
    case 'ShortText':
    case 'MultipleAnswersQuestion':
    case 'RadioButtons':
      return typeof formValue === 'string' && isEmptyOrWhitespace(formValue)
        ? ''
        : formValue.toString();
    case 'File':
      if (instanceOfIFormValueType(formValue)) {
        let fileValue = formValue as IFormFileValue[];
        if (fileValue.length > 0) {
          return formField.IsMultiple
            ? fileValue
                .filter(f => f.Value !== null)
                .map(v => v.Value)
                .join(',')
            : fileValue[0].Value ?? '';
        } else {
          return '';
        }
      }
      break;
    case 'FormSection':
      return '';
    case 'DropDownList':
    case 'UserSelection':
      if (formValue === null || formValue === undefined) {
        return '';
      } else {
        if (Array.isArray(formValue)) {
          return (formValue as Entity<string>[]).map(f => f.Id).join(',');
        } else {
          return (formValue as Entity<string>).Id.toString();
        }
      }
    default:
      return '';
  }
  return null;
};
export const getSerializableIsRequired = (
  formField: IFormFieldDto,
  formValue: CustomFormValueType,
) => {
  const val = SerializeValue(formField, formValue);
  return (
    val === null ||
    val === undefined ||
    isEmptyOrWhitespace(val) ||
    val === 'false'
  );
};

export function getFieldSchema(
  formField: IFormFieldDto,
  isAdmin: boolean,
  fileSize: number,
  notAllowedExt: string[],
  values: Record<string, any> | undefined,
) {
  let parentDepValue = null;
  let parentReqValue = null;
  if (formField.DependencyFieldId !== null) {
    if (!!values && Object.keys(values).length > 0) {
      parentDepValue = values[String(formField.DependencyFieldId)];
    }
  }
  if (formField.RequiredFieldToCompare !== null) {
    if (!!values && Object.keys(values).length > 0) {
      parentReqValue = values[String(formField.RequiredFieldToCompare)];
    }
  }
  switch (formField.Type) {
    case 'MultipleAnswersQuestion':
      let result = Yup.string()
        .nullable()
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.string()

              .required(`${formField.Label} answer is required.`)
              .nullable(),
          },
        )
        .test({
          name: 'required',
          message: `${formField.Label} answer is required.`,
          test: (value, context) => {
            if (isEmptyOrWhitespace(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return result;
    case 'RadioButtons':
      let radioButtons = Yup.string()
        .nullable()
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.string()

              .required(`${formField.Label} radio is required.`)
              .nullable(),
          },
        )
        .test({
          name: 'required',
          message: `${formField.Label} radio is required.`,
          test: (value, context) => {
            if (isEmptyOrWhitespace(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return radioButtons;
    case 'File':
      let resultFile = Yup.array()
        .nullable()
        .of(formFileSchema)
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.array()
              .of(formFileSchema)
              .required(`${formField.Label} file is required.`)
              .min(1, `${formField.Label} file is required.`),
          },
        )
        .test({
          name: 'required',
          message: `${formField.Label} file is required.`,
          test: (value, context) => {
            if (value === undefined || value === null || value.length === 0) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: true,
        })
        .test({
          name: 'size',
          message: `${formField.Label} file is to large.`,
          test: (value, context) => {
            if (value !== undefined && value !== null && value.length > 0) {
              let files = value as IFormFileValue[];
              if (files.length > 0) {
                return files.every(f => f.Size == null || f.Size <= fileSize);
              }
            }
            return true;
          },
          exclusive: true,
        })
        .test({
          name: 'type',
          message: `${formField.Label} file type is forbidden.`,
          test: async (value, context) => {
            try {
              const { lookup } = await import('mime-types');
              if (value !== undefined && value !== null && value.length > 0) {
                let files = value as IFormFileValue[];
                if (files.length > 0) {
                  let blockedTypes = notAllowedExt.map(f => lookup(f));
                  let allowedExt =
                    formField.Options === null
                      ? []
                      : formField.Options.split(',').map(f => lookup(f));
                  return files.every(f => {
                    if (f.PostedFile !== null) {
                      return (
                        !blockedTypes.includes(f.PostedFile.type) &&
                        (allowedExt.length === 0 ||
                          allowedExt.includes(f.PostedFile.type))
                      );
                    } else {
                      return true;
                    }
                  });
                }
              }
              return true;
            } catch (error) {
              return false;
            }
          },
          exclusive: true,
        });
      return resultFile;
    case 'Date':
      let resultDate = getCustomFormDateSchema(
        formField,
        isAdmin,
        parentDepValue,
        parentReqValue,
      );
      return resultDate;
    case 'Email':
      let resultEmail = Yup.string()
        .nullable()
        .email(`${formField.Label} - Email format is not valid`)
        .max(
          formField.SizeLimit,
          `Maximum length of this field is ${formField.SizeLimit}`,
        )
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.string()
              .max(
                formField.SizeLimit,
                `Maximum length of this field is ${formField.SizeLimit}`,
              )
              .required(`${formField.Label} email is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} email is required.`,
          test: (value, context) => {
            if (isEmptyOrWhitespace(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return resultEmail;
    case 'ShortText':
      let resultText = Yup.string()
        .nullable()
        .max(
          formField.SizeLimit,
          `${formField.Label} must be ${formField.SizeLimit} characters long.`,
        )
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.string()
              .max(
                formField.SizeLimit,
                `${formField.Label} must be ${formField.SizeLimit} characters long.`,
              )
              .required(`${formField.Label} text is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} text is required.`,
          test: (value, context) => {
            if (isEmptyOrWhitespace(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      // if (formField.Required === 'Conditional') {
      //   if (formField.RequiredFieldToCompare !== null) {
      //     resultText.when((formField.RequiredFieldToCompare ?? formField.DependencyFieldId ?? 'N/A').toString(), {
      //       is: value => isRequired(formField, isAdmin, groupAdmin, value),
      //       then: Yup.string()
      //         .max(
      //           formField.SizeLimit,
      //           `${formField.Label} must be ${formField.SizeLimit} characters long.`,
      //         )
      //         .required(`${formField.Label} text is required.`),
      //     });
      //   }
      // } else if (isRequired(formField, isAdmin, groupAdmin, null)) {
      //   resultText = resultText.required(
      //     `${formField.Label} text is required.`,
      //   );
      // } else if (
      //   formField.DependencyFieldId !== null &&
      //   formField.DependencyValue !== null
      // ) {
      //   resultText.when(formField.DependencyFieldId.toString(), {
      //     is: value => requiredByDependency(formField, value),
      //     then: Yup.string()
      //       .max(
      //         formField.SizeLimit,
      //         `${formField.Label} must be ${formField.SizeLimit} characters long.`,
      //       )
      //       .required(`${formField.Label} text is required.`),
      //     otherwise: Yup.string()
      //       .nullable()
      //       .max(
      //         formField.SizeLimit,
      //         `${formField.Label} must be ${formField.SizeLimit} characters long.`,
      //       ),
      //   });
      // } else {
      //   resultText = resultText.notRequired();
      // }
      return resultText;
    case 'RichText':
      let resultRichText = Yup.string()
        .nullable()
        .max(
          formField.SizeLimit,
          `${formField.Label} must be ${formField.SizeLimit} characters long.`,
        )
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.string()
              .max(
                formField.SizeLimit,
                `${formField.Label} must be ${formField.SizeLimit} characters long.`,
              )
              .required(`${formField.Label} rich text is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} rich text is required.`,
          test: (value, context) => {
            if (isEmptyOrWhitespace(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return resultRichText;
    case 'Number':
      let resultNumber = Yup.number()
        .nullable()
        .max(
          formField.SizeLimit,
          `${formField.Label} must be less than ${formField.SizeLimit} .`,
        )
        .min(
          -formField.SizeLimit,
          `${formField.Label} must be greater than ${formField.SizeLimit} .`,
        )
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.number()
              .max(
                formField.SizeLimit,
                `${formField.Label} must be less than ${formField.SizeLimit} .`,
              )
              .min(
                -formField.SizeLimit,
                `${formField.Label} must be greater than ${formField.SizeLimit} .`,
              )
              .required(`${formField.Label} number is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} number is required.`,
          test: (value, context) => {
            if (isNullOrUndefined(value) || isNaN(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return resultNumber;
    case 'PositiveNumber':
      let resultPosNumber = Yup.number()
        .nullable()
        .max(
          formField.SizeLimit,
          `${formField.Label} must be less than ${formField.SizeLimit} .`,
        )
        .min(
          0,
          `${formField.Label} must be greater than ${formField.SizeLimit} .`,
        )
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.number()
              .max(
                formField.SizeLimit,
                `${formField.Label} must be less than ${formField.SizeLimit} .`,
              )
              .min(
                0,
                `${formField.Label} must be greater than ${formField.SizeLimit} .`,
              )
              .required(`${formField.Label} number is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} number is required.`,
          test: (value, context) => {
            if (isNullOrUndefined(value) || isNaN(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return resultPosNumber;
    case 'Disclaimer':
      let resultDesc = Yup.boolean()
        .default(false)
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} check is required`,
          test: (value, context) => value === true,
          exclusive: true,
        });
      return resultDesc;
    case 'CheckBox':
      let resultCheck = Yup.boolean()
        .default(false)
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.boolean()
              .default(false)
              .test({
                name: formField.Id.toString(),
                message: `${formField.Label} check is required`,
                test: (value, context) => value === true,
                exclusive: true,
              }),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} rich text is required.`,
          test: (value, context) => {
            if (isNullOrUndefined(value) || value === false) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      return resultCheck;
    case 'DropDownList':
    case 'UserSelection':
      let singleResult = Yup.mixed()
        .nullable()
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.mixed()
              .required(`${formField.Label} select option is required.`)
              .nullable(),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} select option is required.`,
          test: (value, context) => {
            if (isNullOrUndefined(value)) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      let multipleResult = Yup.array()
        .of(Yup.mixed())
        .when(
          [
            (formField.RequiredFieldToCompare ?? 'NA').toString(),
            (formField.DependencyFieldId ?? 'NA').toString(),
          ],
          {
            is: value =>
              isRequired(
                formField,
                isAdmin,
                value,
                parentDepValue,
                parentReqValue,
              ),
            then: Yup.array()
              .of(Yup.mixed())
              .min(1)
              .required(`${formField.Label} select option is required.`),
          },
        )
        .test({
          name: formField.Id.toString(),
          message: `${formField.Label} select option is required.`,
          test: (value, context) => {
            if (isNullOrUndefined(value) || value.length === 0) {
              let pDepVal =
                formField.DependencyFieldId !== null
                  ? context.parent[String(formField.DependencyFieldId)]
                  : null;
              let pReqVal =
                formField.RequiredFieldToCompare !== null
                  ? context.parent[String(formField.RequiredFieldToCompare)]
                  : null;
              return (
                isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
              );
            }
            return true;
          },
          exclusive: false,
        });
      // if (formField.Required === 'Conditional') {
      //   if (formField.RequiredFieldToCompare !== null) {
      //     if (formField.IsMultiple) {
      //       multipleResult.when(formField.RequiredFieldToCompare.toString(), {
      //         is: value =>
      //           value !== null &&
      //           value !== undefined &&
      //           (value as Entity<string>[]).some(
      //             f => f.Id === formField.RequiredValueToCompare ?? '',
      //           ),
      //         then: Yup.array()
      //           .of(Yup.mixed())
      //           .required(`${formField.Label} select option is required.`),
      //         otherwise: Yup.array()
      //           .of(Yup.mixed())
      //           .nullable(true)
      //           .notRequired(),
      //       });
      //     } else {
      //       singleResult.when(formField.RequiredFieldToCompare.toString(), {
      //         is: value =>
      //           value !== null &&
      //           value !== undefined &&
      //           ((value as Entity<string>).Id ===
      //             formField.RequiredValueToCompare ??
      //             ''),
      //         then: Yup.mixed().required(
      //           `${formField.Label} select option is required.`,
      //         ),
      //         otherwise: Yup.mixed().nullable(true).notRequired(),
      //       });
      //     }
      //   }
      // } else if (isRequired(formField, isAdmin, null)) {
      //   if (formField.IsMultiple) {
      //     multipleResult = multipleResult.required(
      //       `${formField.Label} select option is required.`,
      //     );
      //   } else {
      //     singleResult = singleResult.required(
      //       `${formField.Label} select option is required.`,
      //     );
      //   }
      // } else if (
      //   formField.DependencyFieldId !== null &&
      //   formField.DependencyValue !== null
      // ) {
      //   if (formField.IsMultiple) {
      //     multipleResult.when(formField.DependencyFieldId.toString(), {
      //       is: value => isDependencyVisible(formField, value),
      //       then: Yup.array()
      //         .of(Yup.mixed())
      //         .required(`${formField.Label} select option is required.`),
      //       otherwise: Yup.array().of(Yup.mixed()).nullable(true).notRequired(),
      //     });
      //   } else {
      //     singleResult.when(formField.DependencyFieldId.toString(), {
      //       is: value => isDependencyVisible(formField, value),
      //       then: Yup.mixed().required(
      //         `${formField.Label} select option is required.`,
      //       ),
      //       otherwise: Yup.mixed().nullable(true).notRequired(),
      //     });
      //   }
      // } else {
      //   if (formField.IsMultiple) {
      //     multipleResult = multipleResult.nullable(true).notRequired();
      //   } else {
      //     singleResult = singleResult.nullable(true).notRequired();
      //   }
      // }
      if (formField.IsMultiple) {
        return multipleResult;
      } else {
        return singleResult;
      }
  }
  let def = Yup.string().nullable().default(null);
  return def;
}

function getCustomFormDateSchema(
  formField: Pick<
    IFormFieldDto,
    | 'Id'
    | 'Label'
    | 'Required'
    | 'DependencyFieldId'
    | 'DependencyValue'
    | 'DependencyMultiple'
    | 'DependencyVisibility'
    | 'RequiredFieldToCompare'
    | 'RequiredValueToCompare'
  >,
  isAdmin: boolean,
  parentDepVal: any,
  parentReqVal: any,
) {
  return Yup.date()
    .nullable()
    .when(
      [
        (formField.RequiredFieldToCompare ?? 'NA').toString(),
        (formField.DependencyFieldId ?? 'NA').toString(),
      ],
      {
        is: value =>
          isRequired(formField, isAdmin, value, parentDepVal, parentReqVal),
        then: Yup.date()
          .required(`${formField.Label} date is required.`)
          .nullable(),
      },
    )
    .test({
      name: formField.Id.toString(),
      message: `${formField.Label} date is required.`,
      test: (value, context) => {
        if (isNullOrUndefined(value)) {
          let pDepVal =
            formField.DependencyFieldId !== null
              ? context.parent[String(formField.DependencyFieldId)]
              : null;
          let pReqVal =
            formField.RequiredFieldToCompare !== null
              ? context.parent[String(formField.RequiredFieldToCompare)]
              : null;
          return (
            isRequired(formField, isAdmin, null, pDepVal, pReqVal) === false
          );
        }
        return true;
      },
      exclusive: false,
    });
}

export function isRequired(
  formField: Pick<
    IFormFieldDto,
    | 'Required'
    | 'DependencyFieldId'
    | 'DependencyValue'
    | 'DependencyMultiple'
    | 'DependencyVisibility'
    | 'RequiredFieldToCompare'
    | 'RequiredValueToCompare'
  >,
  isAdmin: boolean,
  value: any,
  depValue: any,
  reqValue: any,
): boolean {
  let required = false;
  let requiredType =
    typeof formField.Required === 'number'
      ? RequiredTypes[formField.Required].toString()
      : formField.Required;
  switch (requiredType) {
    case 'Optional':
      required = false;
      break;
    case 'User':
      required = true;
      break;
    case 'Admin':
      required = isAdmin;
      break;
    case 'Conditional':
      if (
        formField.RequiredFieldToCompare !== null &&
        formField.RequiredValueToCompare !== null
      ) {
        let comparedValue = getParentValue(reqValue);
        return formField.RequiredValueToCompare === comparedValue;
      }
      break;
    default:
      required = false;
      break;
  }
  if (required && formField.DependencyFieldId !== null) {
    required = isDependencyVisible(formField, depValue);
  }
  return required;
}
export function isVisible(
  formField: IFormFieldDto,
  isAdmin: boolean,
  visibleOnComletion?: () => boolean,
) {
  let visible = true;
  let displayType =
    typeof formField.DisplayType === 'number'
      ? DisplayTypes[formField.DisplayType].toString()
      : formField.DisplayType;
  switch (displayType) {
    case 'AlwaysVisible':
      visible = true;
      break;
    case 'VisibleToAdminOnly':
      visible = isAdmin;
      break;
    case 'VisibleToUserOnDoneRequest':
      if (visibleOnComletion) {
        visible = visibleOnComletion();
      }
      break;
    default:
      visible = true;
  }
  return visible;
}
export function isDependencyVisible(
  formField: Pick<
    IFormFieldDto,
    | 'DependencyFieldId'
    | 'DependencyValue'
    | 'DependencyMultiple'
    | 'DependencyVisibility'
    | 'RequiredFieldToCompare'
    | 'RequiredValueToCompare'
  >,
  value: any,
): boolean {
  if (
    formField.DependencyFieldId === null ||
    formField.DependencyValue === null
  ) {
    return true;
  }
  let valueTocompare = formField.DependencyValue;
  let parentValue = getParentValue(value);
  if (formField.DependencyMultiple) {
    if (parentValue === null) {
      return !(formField.DependencyVisibility === true);
    }
    let vp = valueTocompare.split(',');
    let pv = parentValue.split(',');
    let comparedRes = vp.filter(f => pv.filter(vf => vf === f).length > 0);
    let valid = comparedRes.length === vp.length;
    if (valid) {
      return formField.DependencyVisibility === true;
    } else {
      return !(formField.DependencyVisibility === true);
    }
  } else {
    if (valueTocompare === parentValue) {
      return formField.DependencyVisibility === true;
    } else {
      return !(formField.DependencyVisibility === true);
    }
  }
}
export function getParentValue(value: any): string | null {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return null;
  } else if (typeof value === 'string') {
    return value;
  } else if (Array.isArray(value)) {
    return (value as Entity<string>[]).map(f => f.Id).join(',');
  } else if (value.hasOwnProperty('Id')) {
    return (value as Entity<string>).Id;
  } else {
    return value.toString();
  }
}
export const getFieldsVisibilities = (
  formFields: IFormFieldDto[],
  isAdmin: boolean,
  values: Record<string, any> | undefined,
  visibleOnCompletion?: () => boolean,
): Record<number, boolean> | undefined => {
  if (values) {
    const visibilities: Record<number, boolean> = {};
    formFields.forEach(field => {
      if (field.Active) {
        visibilities[field.Id] =
          isVisible(field, isAdmin, visibleOnCompletion) &&
          (field.DependencyFieldId === null ||
            (field.DependencyFieldId !== null &&
              isDependencyVisible(
                field,
                values[field.DependencyFieldId.toString()],
              )));
      }
    });
    return visibilities;
  }
  return undefined;
};
export const useCustomFormSchema = (
  formFields: IFormFieldDto[],
  isAdmin: boolean,
  values: Record<string, any> | undefined,
): Yup.SchemaOf<object> => {
  const ForbiddenFileExtensions = useSelector(selectForbiddenFileExtensions);
  const UploadFileSizeLimit = useSelector(selectUploadFileSizeLimit);
  return React.useMemo(() => {
    const shape: ObjectShape = {};
    formFields.forEach(field => {
      if (field.Active) {
        shape[field.Id] = getFieldSchema(
          field,
          isAdmin,
          UploadFileSizeLimit,
          ForbiddenFileExtensions,
          values,
        );
      }
    });
    return Yup.object(shape);
  }, [
    ForbiddenFileExtensions,
    UploadFileSizeLimit,
    formFields,
    isAdmin,
    values,
  ]);
};
export const getCustomFormInitialValues = (
  formFields: IFormFieldDto[],
  formValues?: IFormValueDto[],
  startQuiz?: boolean,
): Record<string, unknown> | undefined => {
  if (
    formValues === undefined &&
    (startQuiz === undefined || startQuiz === false)
  ) {
    return undefined;
  }
  const xx = formFields
    .map(formField => ({
      formField: formField,
      formValue: formValues?.find(
        formValue => formValue.FormFieldId === formField.Id,
      ),
    }))
    .map(item => ({
      formField: item.formField,
      value:
        item.formField.Type === 'File'
          ? item.formValue === null || item.formValue === undefined
            ? null
            : [item.formValue]
          : DeserializeValue(item.formField, item.formValue ?? null),
    }));

  const result =
    xx.reduce((initialValues, item) => {
      initialValues[item.formField.Id.toString()] = item.value ?? null;

      return initialValues;
    }, {}) ?? {};
  return result;
};
export const orderFields = (item: IFormFieldDto, next: IFormFieldDto) => {
  return item.Index! - next.Index!;
};
export const serializeValues = (
  formFields: IFormFieldDto[],
  value: Record<string, unknown>,
) => {
  const serialized: Record<number, string | null> = {};
  let files: IFormFileValue[] = [];
  formFields.forEach(field => {
    if (field.Active) {
      serialized[field.Id] = SerializeValue(
        field,
        value[field.Id] as CustomFormValueType,
      );

      if (field.Type === 'File') {
        files = [...files, ...(value[field.Id] as IFormFileValue[])];
      }
    }
  });
  return { serialized, files };
};
export const toFormValuesArray = (
  serialized: Record<number, string | null>,
  formValues: IFormValueDto[],
  formFields: IFormFieldDto[],
): IFormValueDto[] => {
  const ffs = RecordToFormValuesArray(formFields, formValues, serialized);
  const diff = differenceWith(
    ffs,
    formValues,
    (a, b) => a.FormFieldId === b.FormFieldId && a.Value === b.Value,
  ).filter(f => !(f.Value === null && f.Id === 0));
  return diff;
};
function RecordToFormValuesArray(
  formFields: IFormFieldDto[],
  formValues: IFormValueDto[],
  serialized: Record<number, string | null>,
): IFormValueDto[] {
  return formFields.map(formField => {
    const result = formValues.find(
      formValue => formValue.FormFieldId === formField.Id,
    ) ?? {
      Id: 0,
      FormFieldId: formField.Id,
      Value: null,
      DisplayValue: null,
      RequestId: null,
      ReservationId: null,
      ExperimentItemId: null,
      SourceValueId: null,
      Readonly: false,
      BudgetExperimentId: null,
      AlertId: null,
      AlertTypeId: null,
      ServiceGroupUserId: null,
      ServiceGroupId: null,
      CustomFormId: null,
      CustomFormName: null,
    };
    return { ...result, ...{ Value: serialized[formField.Id.toString()] } };
  });
}

export function serializeCustomFormRowValue(
  value: IServiceRequestTableRowValueModel,
): Pick<IFormValueDto, 'Value' | 'DisplayValue'> {
  if (value == null) {
    return { Value: null, DisplayValue: null };
  } else if (typeof value === 'string') {
    return { Value: value, DisplayValue: null };
  } else if (typeof value === 'number') {
    return { Value: value.toString(), DisplayValue: null };
  } else if (typeof value === 'boolean') {
    return { Value: value.toString(), DisplayValue: null };
  } else if (value instanceof Date) {
    return {
      Value:
        dateUtils.tryFormatIso(value, {
          format: 'basic',
          representation: 'date',
        }) ?? null,
      DisplayValue: null,
    };
  } else {
    return {
      Value: value.Id,
      DisplayValue: value.Name,
    };
  }
}

export function getCustomFormRowDisplay(
  value: IServiceRequestTableRowValueModel,
) {
  if (value == null) {
    return '';
  } else if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (typeof value === 'boolean') {
    return value.toString();
  } else if (value instanceof Date) {
    return dateUtils.shortDateFormat(value);
  } else {
    return value.Name;
  }
}

export function getCustomFormColumnSchema(
  props: Pick<
    IServiceRequestTableColumnModel,
    'Label' | 'ColumnType' | 'isRequired' | 'Options' | 'SizeLimit'
  >,
) {
  const baseSchema = getCustomFormColumnBaseSchema(props).label(props.Label);
  const result = props.isRequired ? baseSchema.required() : baseSchema;
  return result;
}
function getCustomFormColumnBaseSchema(
  props: Pick<
    IServiceRequestTableColumnModel,
    'Label' | 'ColumnType' | 'Options' | 'SizeLimit'
  >,
) {
  switch (props.ColumnType) {
    case 'CheckBox':
      return Yup.boolean().label(props.Label);
    case 'Date':
      return Yup.date().label(props.Label);
    case 'DropDownList':
      //TODO: validate selected option is present in the column options
      return Yup.mixed<IFormFieldOptionDto>().label(props.Label);
    case 'UserSelection':
      return Yup.mixed<Entity<string>>().label(props.Label);
    case 'Email':
      return Yup.string().label(props.Label).email();
    case 'Number':
      return Yup.number()
        .label(props.Label)
        .min(-props.SizeLimit)
        .max(props.SizeLimit);
    case 'PositiveDecimal':
      return Yup.number().label(props.Label).min(0).max(props.SizeLimit);
    case 'PositiveInteger':
      return Yup.number()
        .integer()
        .label(props.Label)
        .min(0)
        .max(props.SizeLimit);
    case 'ShortText':
      return Yup.string();
    case 'Serial':
      return Yup.string();

    default:
      assertExhaustive(props.ColumnType);
  }
}
