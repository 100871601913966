import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import {
  ErrorServices,
  ReportConsumablesState,
  ServiceChangeStateParameters,
} from '../Details/slice/types';
import React from 'react';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormFieldsSection,
  FormLeftSection,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import {
  selectConnectedFiltersData,
  selectConnectedSettings,
} from 'app/components/Forms/FormConnectedFilters/slice/selectors';
import {
  selectConnectedInitState,
  selectConsumablesHasErrors,
  selectConsumablesValueHandler,
  selectEditCreatableService,
  selectServiceChangeState,
} from '../Details/slice/selectors';
import { useOfflineServiceStateSlice } from '../Details/slice';
import { useConnectedFiltersSlice } from 'app/components/Forms/FormConnectedFilters/slice';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { isEmpty } from 'lodash';
import { ConnectedFiltersControl } from 'app/components/Forms/FormConnectedFilters/ConnectedFiltersControl';
import {
  FieldHandler,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { BookQuickServices } from '../Details/components/BookQuickServices';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { dateUtils } from 'utils/date-utils';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { ErrorsSummury } from 'app/components/Forms/FormsLayout/FormErrorSummury';
import { useAppSettingsSlice } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import {
  ListMessagesProps,
  SingleMessagesProps,
} from 'app/Layout/FrontendLayout/components/Snackbar/Actions';

export interface ConsumablesFormProps {
  onSubmit: (item: ReportConsumablesState) => void;
  onAddServiceClick: (services: number[]) => void;
  initialValues: ReportConsumablesState;
  processing?: boolean;
  bindSubmitForm: any;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<ReportConsumablesState>>;
  connectedFiltersFormRef: React.MutableRefObject<any>;
  globalSettings: GlobalSettingsType;
  onOfflineServiceClick?: (service: IOtherServices) => void;
  editCreatable?: boolean;
  onAddBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  onRenewStockClick?: (service: IOtherServices) => void;
  setError?: (errors: ErrorServices) => void;
  offlineServicesErrors: ErrorServices[];
}

export const ConsumablesForm = React.memo(function ConsumablesForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  connectedFiltersFormRef,
  globalSettings,
  innerFormRef,
  onOfflineServiceClick,
  onAddServiceClick,
  editCreatable,
  onAddBatchClick,
  onRenewStockClick,
  offlineServicesErrors,
  setError,
}: ConsumablesFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: connectedFiltersActions } = useConnectedFiltersSlice();
  const { actions: appActions } = useAppSettingsSlice();

  const fieldHandler = useSelector(selectConsumablesValueHandler);
  const hasError = useSelector(selectConsumablesHasErrors);
  const serviceSettings = useSelector(selectConnectedSettings);
  const connectedData = useSelector(selectConnectedFiltersData);
  const connectedInit = useSelector(selectConnectedInitState);
  const serviceChangeState = useSelector(selectServiceChangeState);
  const editCreatableService = useSelector(selectEditCreatableService);
  const { newDate } = useSystemDate();
  // const readonly = !isAdmin;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const fundingTypeVisible = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      globalSettings.fundingTypeBudgetEnabled
    );
  }, [globalSettings]);
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: ReportConsumablesState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetConsumableValueHandler());
  };
  /// Local State ///
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [servicesSelected, setServicesSelected] = useAsyncExtendedState<
    IOtherServices[]
  >(initialValues.Services ?? []);

  const handleFormChange = React.useCallback(
    (
      values: ReportConsumablesState,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (serviceChanged) {
        dispatch(
          connectedFiltersActions.extendSettingsState({
            services:
              values.Services.length < 1
                ? []
                : values.Services.map(f => {
                    return {
                      Id: f.ServiceTypeId,
                      Name: f.ServiceTypeName,
                      ServiceGroupId: f.ServiceGroupId,
                      ServiceTypeId: ServiceType.Offline,
                      Active: true,
                      BudgetsTurnedOn: f.BudgetsTurnedOn,
                      HideProject: f.HideProjects,
                    } as IServiceTypeFilterDto;
                  }),
            globalSettings: globalSettings,
            settings: serviceSettings,
            isEdit: false,
            data: connectedData,
          }),
        );
        if (values.Services.length > 0 && connectedData?.User !== null) {
          const parameters = {
            ServiceTypes: values.Services.map(f => f.ServiceTypeID),
            ReservationId: null,
            UsageId: null,
            BookedBy: connectedData?.User?.Id || null,
            ADGroup: connectedData?.UserGroup?.Id || null,
            Start: dateUtils.formatISO(
              dateUtils.dateOrStringToDate(
                connectedData?.StartTime || newDate(),
              ),
            ),
            BudgetId: connectedData?.Budget?.Id || null,
            FundingTypeId: connectedData?.FundingType?.Id || 0,
          } as ServiceChangeStateParameters;
          dispatch(actions.initServiceChangeStateData(parameters));
          // if (!!connectedData) {
          //   dispatch(connectedFiltersActions.saveState(connectedData));
          // }
        }
        //setServiceTypesSelected(values.Services);
        setServiceChanged(false);
      }
    },
    [
      serviceChanged,
      dispatch,
      connectedFiltersActions,
      globalSettings,
      serviceSettings,
      connectedData,
      newDate,
      actions,
    ],
  );
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);

  /// useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);
  React.useEffect(() => {
    if (!!serviceChangeState) {
      console.log('service settings: ', serviceSettings);
      if (!!serviceChangeState) {
        if (serviceSettings.budgetVisible) {
          dispatch(
            connectedFiltersActions.setConnectedValue({
              fieldKey: 'Budget',
              fieldValue: serviceChangeState.DefaultBudget,
            }),
          );
          if (
            fundingTypeVisible &&
            serviceChangeState.DefaultBudget?.FundingTypeId !== undefined &&
            serviceChangeState.DefaultBudget?.FundingTypeId !== null
          ) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'FundingType',
                fieldValue: serviceChangeState.DefaultBudget.FundingTypeId,
              }),
            );
          }
          if (serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
            if (serviceChangeState.DefaultBudget !== null) {
              dispatch(
                connectedFiltersActions.setConnectedValue({
                  fieldKey: 'UserGroup',
                  fieldValue: {
                    Id: serviceChangeState.DefaultBudget.UserGroupId,
                    Name: serviceChangeState.DefaultBudget.UserGroupName,
                  },
                }),
              );
            }
          } else {
            if (
              connectedData?.UserGroup === null ||
              connectedData?.UserGroup === undefined
            ) {
              dispatch(
                connectedFiltersActions.setConnectedValue({
                  fieldKey: 'UserGroup',
                  fieldValue: {
                    Id:
                      (connectedData?.User as IUserFilterDto).UserGroupId ??
                      user?.ActiveUserGroup?.Id,
                    Name:
                      (connectedData?.User as IUserFilterDto).UserGroupName ??
                      user?.ActiveUserGroup?.Name,
                  },
                }),
              );
            }
          }
          if (
            serviceSettings.budgetExpirementVisible &&
            serviceChangeState.DefaultExperiment !== null
          ) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'BudgetExperiment',
                fieldValue: serviceChangeState.DefaultExperiment,
              }),
            );
          }
        } else {
          if (serviceSettings.isUserGroupCoordinator) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'UserGroup',
                fieldValue: {
                  Id: user?.ActiveUserGroup?.Id,
                  Name: user?.ActiveUserGroup?.Name,
                },
              }),
            );
          } else if (
            connectedData?.UserGroup === null ||
            connectedData?.UserGroup === undefined
          ) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'UserGroup',
                fieldValue: {
                  Id:
                    (connectedData?.User as IUserFilterDto).UserGroupId ??
                    user?.ActiveUserGroup?.Id,
                  Name:
                    (connectedData?.User as IUserFilterDto).UserGroupName ??
                    user?.ActiveUserGroup?.Name,
                },
              }),
            );
          }
        }
      }
      dispatch(actions.resetServiceChangeState());
    }
  }, [
    actions,
    connectedData?.User,
    connectedData?.UserGroup,
    connectedFiltersActions,
    dispatch,
    fundingTypeVisible,
    serviceChangeState,
    serviceSettings,
    user?.ActiveUserGroup?.Id,
    user?.ActiveUserGroup?.Name,
  ]);
  React.useEffect(() => {
    dispatch(
      actions.setConsumableValue({
        fieldKey: 'Services',
        fieldValue: servicesSelected,
      }),
    );
    dispatch(actions.extendConsumablesData(servicesSelected));
  }, [actions, dispatch, servicesSelected]);

  const otherServicesSchema = Yup.array().of(
    Yup.mixed() as Yup.SchemaOf<IOtherServices>,
  );
  const reportConsumableSchema: Yup.SchemaOf<ReportConsumablesState> = Yup.object(
    {
      Services: otherServicesSchema
        .min(1)
        .label(t(translations.Consumables) as string)
        .required(t(translations.err_OfflineServiceTypeRequired) as string),
    },
  );
  const changeOfflineServices = React.useCallback(
    (services: IOtherServices[], operation: 'add' | 'remove' | 'update') => {
      if (operation === 'add') {
        let addedServ = services.filter(
          f => !servicesSelected.some(s => s.ServiceTypeID === f.ServiceTypeID),
        );
        setServicesSelected([...servicesSelected, ...addedServ]);
        setServiceChanged(true);
      }
      if (operation === 'remove') {
        let otherServices = servicesSelected.filter(f => {
          return !services.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServicesSelected(otherServices);
        setServiceChanged(true);
      }
      if (operation === 'update') {
        setServicesSelected(
          servicesSelected.map(f => {
            let service = services.find(
              s => s.ServiceTypeID === f.ServiceTypeID,
            );
            if (!!service && service !== null) {
              return Object.assign({}, f, service);
            } else {
              return f;
            }
          }),
        );
      }
    },
    [servicesSelected, setServicesSelected],
  );
  const initServices = async (): Promise<IOtherServices[]> => {
    return new Promise(async resolve => {
      resolve(servicesSelected);
    });
  };
  const sortFunc = (a: IInventoryBatchDto, b: IInventoryBatchDto) => {
    return b.Id - a.Id;
  };
  const prepareService = (
    serviceType: IOfflineServiceFilterDto,
    otherData?: IConnectedFiltersDto,
  ) => {
    let batch = serviceType.InventoryBatchesEnabled
      ? !!serviceType.InventoryBatches &&
        serviceType.InventoryBatches.length > 0
        ? serviceType.InventoryBatches.filter(
            f =>
              (f.ExpirationDate === null || f.ExpirationDate > newDate()) &&
              (serviceType.NotLessThanZero ? f.Inventory > 0 : true) &&
              f.StokRenewed,
          ).sort(sortFunc)[0]
        : null
      : null;
    let data = {
      Id: 0,
      BookedBy: otherData?.User?.Id,
      UserDisplayName: otherData?.User?.Name,
      BudgetID: otherData?.Budget?.Id,
      Budget: otherData?.Budget?.Name,
      BudgetExperimentId: otherData?.BudgetExperiment?.Id,
      BudgetExperimentName: otherData?.BudgetExperiment?.Name,
      Quantity: serviceType.DefaultQuantity,
      DefaultQuantity: serviceType.DefaultQuantity,
      ExternalCustomerId: otherData?.ExternalCustomer?.Id,
      ExternalCustomerName: otherData?.ExternalCustomer?.Name,
      FundingType: otherData?.FundingType?.Id,
      IntQuantityOnly: serviceType.IntQuantityOnly,
      DiscountFactor: 1,
      InstituteProjectId: otherData?.InstituteProject?.Id,
      InstituteProjectName: otherData?.InstituteProject?.Name,
      PurchaseOrder: otherData?.PurchaseOrder,
      Reference: otherData?.Reference,
      Mandatory: false,
      Remarks: null,
      ReservationId: null,
      ServiceDate: dateUtils.formatISO(
        dateUtils.dateOrStringToDate(otherData?.StartTime ?? newDate()),
      ),
      ServiceTypeID: serviceType.Id,
      ServiceType: serviceType.Name,
      ServiceGroupId: serviceType.ServiceGroupId,
      Units: serviceType.UnitTypeName,
      UserGroup: otherData?.UserGroup?.Id,
      UserGroupName: otherData?.UserGroup?.Name,
      AllowToUser: serviceType.AllowToUser,
      BudgetsTurnedOn: serviceType.BudgetsTurnedOn,
      StaffOnly: serviceType.StaffOnly,
      HideProjects: serviceType.HideProjects,
      InventoryBatchesEnabled: serviceType.InventoryBatchesEnabled,
      InventoryBatchId: batch === null ? null : batch.Id,
      InventoryBatchName: batch === null ? null : batch.Name,
      InventoryBatchAmount: batch === null ? null : batch.Inventory,
    } as IOtherServices;
    return data;
  };
  const createService = async (
    serviceType: IOfflineServiceFilterDto,
  ): Promise<IOtherServices | undefined> => {
    return new Promise(async (resolve, reject) => {
      let staticService = prepareService(serviceType, connectedData);
      changeOfflineServices([staticService], 'add');
      resolve(staticService);
    });
  };
  const createServices = async (
    serviceTypes: IOfflineServiceFilterDto[],
  ): Promise<IOtherServices[]> => {
    return new Promise(async (resolve, reject) => {
      let staticServices = serviceTypes.map(f =>
        prepareService(f, connectedData),
      );
      changeOfflineServices(staticServices, 'add');
      resolve(staticServices);
    });
  };
  const onRemoveService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'remove');
      resolve(service);
    });
  };
  const onRemoveServices = async (services: IOtherServices[]) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices(services, 'remove');
      resolve(services);
    });
  };
  const onUpdateService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'update');
      resolve(service);
    });
  };
  const onAddAndRemoveServices = async (
    serviceTypes: IOfflineServiceFilterDto[],
    removed: IOtherServices[],
  ): Promise<IOtherServices[]> => {
    return new Promise(async (resolve, reject) => {
      let staticServices = serviceTypes.map(f =>
        prepareService(f, connectedData),
      );
      let addedServ = staticServices.filter(
        f => !servicesSelected.some(s => s.ServiceTypeID === f.ServiceTypeID),
      );
      let otherServices = servicesSelected.filter(f => {
        return !removed.some(a => a.ServiceTypeID === f.ServiceTypeID);
      });
      setServicesSelected([...otherServices, ...addedServ]);
      setServiceChanged(true);
      resolve([...otherServices, ...addedServ]);
    });
  };
  React.useEffect(() => {
    let active =
      editCreatableService !== undefined &&
      servicesSelected.some(
        f => f.ServiceTypeID === editCreatableService.ServiceTypeID,
      );
    if (active && editCreatableService !== undefined) {
      changeOfflineServices([editCreatableService], 'update');
      dispatch(actions.setEditCreatableService(undefined));
      dispatch(actions.setRefreshConsumableOfflineServices(true));
      setServiceChanged(true);
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    changeOfflineServices,
    dispatch,
    editCreatableService,
    servicesSelected,
  ]);
  return (
    <>
      <Formik
        validationSchema={reportConsumableSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
                console.debug('errors', responseErrors);
              } else if (values.Services.length < 1) {
                formikHelpers.setErrors({
                  Services: t(
                    translations.err_OfflineServiceTypeRequired,
                  ) as string,
                });
                formikHelpers.setSubmitting(false);
              } else if (offlineServicesErrors.length > 0) {
                formikHelpers.setSubmitting(false);
                dispatch(
                  appActions.addNotification({
                    variant: 'error',
                    message: '',
                    messageType: SnackBarMessageType.messageList,
                    messageTypeProps: {
                      messages: offlineServicesErrors.map(f => {
                        return {
                          Id: f.id.toString(),
                          message: f.error,
                        } as SingleMessagesProps;
                      }),
                    } as ListMessagesProps,
                  }),
                );
              } else {
                console.debug('submitting', values);
                formikHelpers.setSubmitting(true);
                handleSubmit(values);
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Consumables errors', formik.errors);
          }
          console.log('Consumables submitting', formik.isSubmitting);
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['Services']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                // errors={errorMessages}
                // successNodes={successMessages}
                >
                  <ErrorsSummury errors={formik.errors} />
                  <FormFieldsSection
                    titleSection={t(translations.ReportedConsumables) as string}
                  >
                    <BookQuickServices
                      createServiceFromServiceType={createService}
                      createServicesFromServiceType={createServices}
                      onAddAndRemoveServices={onAddAndRemoveServices}
                      initServicesFunc={initServices}
                      onServiceRemove={onRemoveService}
                      onServicesRemove={onRemoveServices}
                      onServiceUpdate={onUpdateService}
                      onServiceClick={onOfflineServiceClick}
                      isEditMode={false}
                      disabled={false}
                      useAssetSelection={true}
                      onAddClick={onAddServiceClick}
                      userName={connectedData?.User?.Id}
                      createdEditable={editCreatable}
                      onAddBatchClick={onAddBatchClick}
                      onRenewStockClick={onRenewStockClick}
                      setError={setError}
                    />
                  </FormFieldsSection>
                  <FormFieldsSection
                    titleSection={
                      t(translations.ReportConsumablesMainInfo) as string
                    }
                  >
                    <ConnectedFiltersControl
                      initialState={connectedInit}
                      submitFormRef={connectedFiltersFormRef}
                      isEdit={false}
                      serviceTypeEnum={ServiceType.Offline}
                      serviceTypes={
                        formik.values.Services.length < 1
                          ? []
                          : formik.values.Services.map(s => {
                              return {
                                Id: s.ServiceTypeID,
                                Name: s.ServiceType,
                                Active: true,
                                BudgetsTurnedOn: s.BudgetsTurnedOn,
                                HideProject: s.HideProjects,
                                ServiceGroupId: s.ServiceGroupId,
                                ServiceTypeId: ServiceType.Offline,
                              } as IServiceTypeFilterDto;
                            })
                      }
                      globalSettings={globalSettings}
                      formIsValid={validateForms}
                      startTimeLabel={
                        t(translations.LogDateTimeLabel) as string
                      }
                      startLabelInline={true}
                      //orderForConsumables={true}
                      //forceDisabled={servicesSelected.length > 1}
                      budgetPickerInfo={
                        t(translations.ConsumableProject_info) as string
                      }
                    />
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
