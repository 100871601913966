import { Body } from 'app/components/Typography';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dateUtils } from 'utils/date-utils';
import styled from 'styled-components';
import { ReservationSettingsState } from '../../slice/types';

export interface ReadOnlyDetailsProps {
  details: ReservationSettingsState;
}

export function ReadOnlyDetails(props: ReadOnlyDetailsProps) {
  const { details } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <DetailsWrapper className={'readonly-root'}>
        <Body bold={true}>{t(translations.ReservationAdvancedDetails)}</Body>
        <div className={'readonly-content'}>
          <div className={'readonly-row'}>
            <Body size="small">{t(translations.RecordDate)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.RecordDate)}
            </Body>
          </div>
          {details.LastUpdateDate !== null && !!details.LastUpdateDate && (
            <div className={'readonly-row'}>
              <Body size="small">{t(translations.LastUpdated)}</Body>
              <Body size="small" bold={true}>
                {dateUtils.longDateTimeFormat(details.LastUpdateDate)}
              </Body>
            </div>
          )}
          {details.LastUpdateAdmin !== null && !!details.LastUpdateAdmin.Id && (
            <div className={'readonly-row'}>
              <Body size="small">{t(translations.LastUpdatedBy)}</Body>
              <Body size="small" bold={true}>
                {details.LastUpdateAdmin?.Name}
              </Body>
            </div>
          )}
        </div>
      </DetailsWrapper>
    </React.Fragment>
  );
}
const DetailsWrapper = styled.div`
  &.readonly-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 4px;
    padding: 0;
    width: 100%;
  }
  & .readonly-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    padding: 16px 0px;
    width: 100%;
  }
  & .readonly-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: inherit;
  }
`;
