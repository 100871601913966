/**
 *
 * TrainingStatePickerFilter
 *
 */

import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { TrainingStatePicker } from 'app/components/pickers/AutocompletePickers/TrainingStatePicker';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { GetEnum, GetRawValue } from '../../types/FilterParam';
import { TFunction } from 'i18next';
import { TrainingState } from 'enums/TrainingState';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';

export function TrainingStatePickerFilter(props: IFilterComponentProps) {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <TrainingStatePicker
      value={props.value}
      isOpen={props.open}
      onChange={handleOnChange}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      fullWidth={props.fullWidth}
      id={props.id || 'TrainingStatePicker'}
    />
  );
}
export function getTrainingStatePickerFilter<TRow>(
  accessor: keyof TRow,
  params: URLSearchParams,
  t: TFunction,
): IFilterSettings<TRow> {
  return {
    id: 'TrainingStateId',
    fieldName: accessor,
    displayNameKey: translations.TrainingState,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'TrainingStateId',
    value: GetEnum(GetRawValue(params, 'TrainingStateId'), TrainingState, t),
    component: TrainingStatePickerFilter,
    customFilter: (value, settings) => {
      const id = (value as Entity<number>)?.Id;
      switch (id) {
        case TrainingState.Available:
          const x = new Condition(settings.fieldName, ODataOperators.Includes, [
            TrainingState.Available,
            TrainingState.UnderMinimum,
          ]).toString();
          return x;
        case TrainingState.Maximum:
        case TrainingState.SignedUp:
        case TrainingState.UnderMinimum:
          return new Condition(
            settings.fieldName,
            ODataOperators.Equals,
            id,
          ).toString();
      }
    },
  };
}
export default TrainingStatePickerFilter;
