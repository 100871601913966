import { Portal } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { Fragment, MutableRefObject, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { dateUtils } from 'utils/date-utils';
import { ConnectedFiltersForm } from '.';
import { useConnectedFiltersSlice } from './slice';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface ConnectedFiltersControlProps {
  initialState?: IConnectedFiltersDto;
  saveConnectedFilters?: (values: IConnectedFiltersDto) => void;
  submitFormRef: MutableRefObject<any>;
  isEdit: boolean;
  serviceTypeEnum?: ServiceType;
  serviceTypes: IServiceTypeFilterDto[];
  formIsValid?: (isValid: boolean) => void;
  globalSettings: GlobalSettingsType;
  startTimeLabel?: string;
  endTimeLabel?: string;
  startLabelInline?: boolean;
  endLabelInline?: boolean;
  orderForConsumables?: boolean;
  forceDisabled?: boolean;
  budgetPickerInfo?: string;
}

export function ConnectedFiltersControl(
  props: ConnectedFiltersControlProps,
): JSX.Element {
  //PROPS
  const {
    initialState,
    saveConnectedFilters,
    submitFormRef,
    isEdit,
    serviceTypeEnum,
    serviceTypes,
    formIsValid,
    globalSettings,
    startTimeLabel,
    endTimeLabel,
    startLabelInline,
    endLabelInline,
    orderForConsumables,
    forceDisabled,
    budgetPickerInfo,
  } = props;
  //REDUX
  const dispatch = useDispatch();
  const { actions } = useConnectedFiltersSlice();

  //REFS
  const portalRef = useRef(null);
  //BIND
  const bindSubmitForm = useCallback(
    submitForm => {
      submitFormRef.current = submitForm;
    },
    [submitFormRef],
  );
  //ON SUBMIT
  const handleSubmit = useCallback(
    (values: IConnectedFiltersDto) => {
      if (saveConnectedFilters) {
        saveConnectedFilters(values);
      } else {
        var d = {
          StartTime:
            values.StartTime === undefined
              ? undefined
              : dateUtils.formatISO(
                  dateUtils.dateOrStringToDate(values.StartTime),
                ),
          User: values.User,
          UserGroup: values.UserGroup,
          Budget: values.Budget,
          BudgetExperiment: values.BudgetExperiment,
          InstituteProject: values.InstituteProject,
          EndTime: values.EndTime,
          ExternalCustomer: values.ExternalCustomer,
          FundingType: values.FundingType,
          PurchaseOrder: values.PurchaseOrder,
          Reference: values.Reference,
        } as IConnectedFiltersDto;
        dispatch(actions.saveState(d));
      }
    },
    [actions, dispatch, saveConnectedFilters],
  );

  //RENDER
  return initialState ? (
    <Fragment>
      <Div100 ref={portalRef} />
      <Portal container={portalRef.current}>
        <ConnectedFiltersForm
          isEdit={isEdit}
          onSubmit={handleSubmit}
          ConnectedFiltersData={initialState ?? undefined}
          bindSubmitForm={bindSubmitForm}
          formIsValid={formIsValid}
          serviceTypeEnum={serviceTypeEnum}
          serviceTypes={serviceTypes}
          globalSettings={globalSettings}
          startTimeLabel={startTimeLabel}
          processing={initialState === undefined}
          orderForConsumables={orderForConsumables}
          forceDisabled={forceDisabled}
          budgetPickerInfo={budgetPickerInfo}
          endTimeLabel={endTimeLabel}
          startLabelInline={startLabelInline}
          endLabelInline={endLabelInline}
        />
      </Portal>
    </Fragment>
  ) : (
    <Fragment>
      <Skeleton variant="rect" />
    </Fragment>
  );
}
