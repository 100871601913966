import Link from '@material-ui/core/Link';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IInvoiceTemplatePopUp } from './IInvoiceTemplatePopUp';
import { InvoiceTemplatesApi } from 'api/InvoiceTemplatesApi';
import { useAppSettingsSlice } from 'app/slice';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import {
  StyledForm,
  FormLeftSection,
  FormFieldsSection,
  FormRow,
  FormRightSection,
} from 'app/components/Forms/FormsLayout';
import { createStyles, makeStyles, Theme, Paper, Box } from '@material-ui/core';
import { HtmlDefaultTemplate, PopUpListItems } from './PopUpListItems';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { dateUtils } from 'utils/date-utils';
import { FormInputFile } from 'app/components/Forms/FormInputFile';
import { formFileSchema } from 'app/components/CustomForm/CustomFormUtils';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { HtmlTextBox } from './HtmlTextBox';
import { selectAppSettings } from 'app/slice/selectors';
import { BudgetFields } from 'enums/BudgetFields';
import { IsBudgetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { FormColorPicker } from 'app/components/Forms/FormColorPicker';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { PreviewButton } from './PreviewButton';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import React from 'react';
import { KnownModules } from 'types/KnownModules';

export interface InvoiceTemplatePopUpProps extends CoverProps {
  Id?: number;
  serviceGroup?: Entity<number> | null;
  isMainAdmin?: boolean | null;
  onClose?: () => void;
}

export const InvoiceTemplatePopUpLink = ({
  children,
  ...props
}: InvoiceTemplatePopUpProps & { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const onClick = () => {
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.InvoiceTemplatePopUp,
        expanded: true,
        props: {
          Id: props.Id,
        } as InvoiceTemplatePopUpProps,
      }),
    );
  };
  return <Link onClick={onClick}>{children}</Link>;
};

export const InvoiceTemplatePopUp = ({
  Id,
  serviceGroup,
  isMainAdmin,
  onClose,
  ...props
}: InvoiceTemplatePopUpProps) => {
  //SETTINGS
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const appSettings = useSelector(selectAppSettings);
  const budgetFieldFundingTypeEnabled = React.useMemo(() => {
    return (
      IsBudgetFieldEnabled(appSettings, BudgetFields.FundingType) &&
      IsModuleEnabled(appSettings, KnownModules.Budgets)
    );
  }, [appSettings]);

  //PROPS
  const isEdit = !!Id;
  const submitRef = useRef<any>();

  //REDUX
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { actions: notifyActions } = useAppSettingsSlice();

  //STATES
  const [busy, setBusy] = useState(false);
  const [initialValues, setInitialValue] = useState<IInvoiceTemplatePopUp>({
    Id: -1,
    Name: null,
    CreatedAt: newDate(),
    LastUpdated: newDate(),
    FundingType: null,
    ServiceGroup: null,
    Html: HtmlDefaultTemplate,
    TableHeaderColor: '#8D8BC9',
    TableBodyColor: '#ffffff',
    HeaderFile: [],
    FooterFile: [],
    HeaderFileName: null,
    FooterFileName: null,
  });

  //SCHEMAS
  async function testHex(input: string | null | undefined) {
    let result = false;
    if (!!input) {
      const regHex = /^#([0-9a-f]{3}){1,2}$/i;
      result = regHex.test(input);
    }
    return result;
  }

  const InvoiceTemplatePopUpSchema: Yup.SchemaOf<IInvoiceTemplatePopUp> =
    Yup.object({
      Id: Yup.number().notRequired().default(-1),
      Name: Yup.string().required(),
      CreatedAt: Yup.date().required().default(newDate()),
      LastUpdated: Yup.date().notRequired().nullable(),
      FundingType: Yup.array().of(EntityNumberSchema).notRequired().nullable(),
      ServiceGroup: Yup.array().of(EntityNumberSchema).notRequired().nullable(),
      Html: Yup.string().notRequired().nullable(),
      TableHeaderColor: Yup.string().notRequired().nullable().test({
        name: 'TableHeaderColor',
        test: testHex,
      }),
      TableBodyColor: Yup.string().notRequired().nullable().test({
        name: 'TableBodyColor',
        test: testHex,
      }),
      HeaderFile: Yup.array().of(formFileSchema).notRequired().nullable(),
      FooterFile: Yup.array().of(formFileSchema).notRequired().nullable(),
      HeaderFileName: Yup.string().notRequired().nullable(),
      FooterFileName: Yup.string().notRequired().nullable(),
    });
  /*   const InvoiceTemplatePopUpSGASchema: Yup.SchemaOf<IInvoiceTemplatePopUp> = Yup.object(
    {
      Id: Yup.number().notRequired().default(-1),
      Name: Yup.string().required(),
      CreatedAt: Yup.date().required().default(new Date()),
      LastUpdated: Yup.date().notRequired().nullable(),
      FundingType: Yup.array().of(EntityNumberSchema).notRequired().nullable(),
      ServiceGroup: Yup.mixed().required(),
      Html: Yup.string().notRequired().nullable(),
      TableHeaderColor: Yup.string().notRequired().nullable(),
      TableBodyColor: Yup.string().notRequired().nullable(),
      HeaderFile: Yup.array().of(formFileSchema).notRequired().nullable(),
      FooterFile: Yup.array().of(formFileSchema).notRequired().nullable(),
    },
  ); */

  const close = useCallback(() => {
    if (props.isCover) {
      props.closeCover?.();
    } else {
      dispatch(actions.resetSidePanel());
    }
  }, [props, dispatch, actions]);

  const refreshTable = useCallback(() => {
    dispatch(actions.setRefreshTable(true));
  }, [dispatch, actions]);

  function html_replace(html: string, valueToReplace: string) {
    const patrn = /background-color: #(?:[A-Fa-f0-9]{3}){1,2}\b/;
    if (html.match(patrn)) {
      //console.log(html.match(patrn)?.toString());
      let originalValue: string = html.match(patrn)?.toString() as string;
      html = html.replaceAll(originalValue, valueToReplace);
    }
    return html;
  }

  const OnSubmit = useCallback(
    (invoiceTemplate: IInvoiceTemplatePopUp) => {
      setBusy(true);
      let invoiceTemplateHtml = html_replace(
        invoiceTemplate.Html as string,
        'background-color: ' + invoiceTemplate.TableHeaderColor ??
          'background-color: #FFFFFF',
      );
      //if Add mode
      if (!isEdit) {
        let formValues = {
          Id: invoiceTemplate.Id,
          Name: invoiceTemplate.Name,
          CreatedAt: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(invoiceTemplate.CreatedAt),
          ),
          LastUpdated: !!invoiceTemplate.LastUpdated
            ? dateUtils.formatISO(
                dateUtils.dateOrStringToDate(invoiceTemplate.LastUpdated),
              )
            : null,
          FundingType: invoiceTemplate.FundingType?.Id,
          ServiceGroupId: invoiceTemplate.ServiceGroup?.Id,
          ServiceGroup: invoiceTemplate.ServiceGroup?.Name ?? '',
          Html: invoiceTemplateHtml,
          TableHeaderColor: invoiceTemplate.TableHeaderColor,
          TableBodyColor: invoiceTemplate.TableBodyColor,
          HeaderFile: invoiceTemplate.HeaderFile,
          FooterFile: invoiceTemplate.FooterFile,
          HeaderFileName: invoiceTemplate.HeaderFileName,
          FooterFileName: invoiceTemplate.FooterFileName,
        };
        InvoiceTemplatesApi.insertInvoiceTemplate(formValues)
          .then(response => {
            if (response.ErrorMessages.length > 0) {
              response.ErrorMessages.forEach(errmsg =>
                dispatch(
                  notifyActions.addNotification({
                    variant: 'error',
                    message: errmsg,
                  }),
                ),
              );
            } else {
              dispatch(
                notifyActions.addNotification({
                  message: t(translations.InvoiceTemplateAddedSuccessfully),
                  variant: 'success',
                }),
              );
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            close();
            refreshTable();
          });
      } else {
        //if Edit mode
        let formValues = {
          Id: invoiceTemplate.Id,
          Name: invoiceTemplate.Name,
          CreatedAt: dateUtils.formatISO(
            dateUtils.dateOrStringToDate(invoiceTemplate.CreatedAt),
          ),
          LastUpdated: !!invoiceTemplate.LastUpdated
            ? dateUtils.formatISO(
                dateUtils.dateOrStringToDate(invoiceTemplate.LastUpdated),
              )
            : null,
          FundingType: invoiceTemplate.FundingType?.Id,
          ServiceGroupId: invoiceTemplate.ServiceGroup?.Id,
          ServiceGroup: invoiceTemplate.ServiceGroup?.Name ?? '',
          Html: invoiceTemplateHtml,
          TableHeaderColor: invoiceTemplate.TableHeaderColor,
          TableBodyColor: invoiceTemplate.TableBodyColor,
          HeaderFile: invoiceTemplate.HeaderFile,
          FooterFile: invoiceTemplate.FooterFile,
          HeaderFileName: invoiceTemplate.HeaderFileName,
          FooterFileName: invoiceTemplate.FooterFileName,
        };
        InvoiceTemplatesApi.updateInvoiceTemplate(Id, formValues)
          .then(response => {
            if (response.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  message: response.ErrorMessages[0],
                  variant: 'error',
                }),
              );
              setBusy(false);
              return;
            }
            dispatch(
              notifyActions.addNotification({
                message: t(translations.InvoiceTemplateUpdateSuccess),
                variant: 'success',
              }),
            );
            close();
            refreshTable();
          })
          .catch(e => console.error(e));
      }
    },
    [Id, close, dispatch, isEdit, notifyActions, refreshTable, t],
  );

  //USE EFFECT ON MOUNT
  useEffectOnMount(() => {
    //if Edit mode
    if (isEdit) {
      setBusy(true);
      let mounted = true;
      (async () => {
        try {
          const invoiceTemplate =
            await InvoiceTemplatesApi.getInvoiceTemplateById(Id);
          if (mounted) {
            setInitialValue({
              Id: invoiceTemplate.value[0].Id ?? -1,
              Name: invoiceTemplate.value[0].Name ?? null,
              CreatedAt: invoiceTemplate.value[0].CreatedAt ?? null,
              LastUpdated: invoiceTemplate.value[0].LastUpdated ?? null,
              FundingType:
                getSingleInvoiceFundingType(
                  invoiceTemplate.value[0].FundingType,
                ) ?? null,
              ServiceGroup: invoiceTemplate.value[0].ServiceGroup
                ? {
                    Id: invoiceTemplate.value[0].ServiceGroupId,
                    Name: invoiceTemplate.value[0].ServiceGroup,
                  }
                : null,
              //"split"-"join" is to avoid breaking the RichTextEditor (path, offset problems)
              Html:
                invoiceTemplate.value[0].Html.split(/[\r\n]+/).join('') ?? null,
              //Html: invoiceTemplate.value[0].Html ?? null,
              TableHeaderColor:
                invoiceTemplate.value[0].TableHeaderColor ?? null,
              TableBodyColor: invoiceTemplate.value[0].TableBodyColor ?? null,
              HeaderFile: [],
              FooterFile: [],
              HeaderFileName: invoiceTemplate.value[0].HeaderFileName,
              FooterFileName: invoiceTemplate.value[0].FooterFileName,
            });
            setBusy(false);
          }
        } catch (e) {
          console.error(e);
        }
      })();
      return () => {
        mounted = false;
      };
    } else {
      //if Add mode
      //if (!!serviceGroup) {
      setBusy(true);
      let isMounted = true;
      if (isMounted) {
        const values = { ...initialValues };
        values.Name = t(translations.InvoiceTemplate) as string;
        if (!!serviceGroup && isMainAdmin === false) {
          //set first service group
          values.ServiceGroup = serviceGroup;
        }
        setInitialValue(values);
        setBusy(false);
      }
      return () => {
        isMounted = false;
      };
      //}
      //return;
    }
  });

  //CSS
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      leftSectionH6Image: {
        padding: theme.spacing(1),
        backgroundColor: '#026AA2',
        color: '#FFFFFF',
        marginRight: 10,
      },
      rightSectionH5Title: {
        padding: theme.spacing(2),
        backgroundColor: '#026AA2',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        width: '100%',
      },
    }),
  );
  const classes = useStyles();

  //FORM
  const InvoiceTemplateForm = () => {
    return (
      <Formik
        validationSchema={InvoiceTemplatePopUpSchema}
        /*         validationSchema={
          isMainAdmin === true
            ? InvoiceTemplatePopUpSchema
            : InvoiceTemplatePopUpSGASchema
        } */
        initialValues={initialValues}
        onSubmit={OnSubmit}
      >
        {formik => {
          submitRef.current = formik.submitForm;
          const additionalButtons = [
            (classname, key) => {
              return (
                <HtmlTextBox
                  Id={Id}
                  className={classname}
                  setInitialValue={setInitialValue}
                  isEdit={isEdit}
                  setBusy={setBusy}
                  formikValues={formik.values}
                  disabled={false}
                  key={key}
                />
              );
            },
          ];
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              <FormLeftSection>
                <FormFieldsSection>
                  <FormRow fullRow>
                    <PreviewButton />
                    <Box component="div">
                      <TopActionButton
                        text={
                          t(
                            translations.AdditionalInformationOnInvoicetemplate,
                          ) as string
                        }
                        title={
                          t(
                            translations.AdditionalInformationOnInvoicetemplate_info,
                          ) as string
                        }
                        icon="link"
                        //startIcon="info"
                        size="small"
                        variant="ghost"
                        css={{ marginLeft: '8px', cursor: 'auto' }}
                        disabled={true}
                        noChangeOnMobile={true}
                      />
                    </Box>
                  </FormRow>
                  <FormRow fullRow>
                    <FormTextField
                      id="NameID"
                      name="Name"
                      label={t(translations.TemplateName)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormServiceGroupPicker
                      id="ServiceGroupID"
                      name="ServiceGroup"
                      placeholder={t(translations.AllServiceGroups)}
                      label={t(translations.ServiceGroup)}
                      disabled={formik.isSubmitting}
                      urlType="base"
                      fullWidth
                      //adminOnly={isMainAdmin ? false : true}
                    />
                  </FormRow>
                  <FormRow fullRow hide={!budgetFieldFundingTypeEnabled}>
                    <FormFundingTypePicker
                      id="FundingTypeID"
                      name="FundingType"
                      placeholder={t(translations.AllFundingTypes)}
                      label={t(translations.FundingType)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormInputFile
                      id="HeaderFileID"
                      uploadLabel={initialValues.HeaderFileName ?? ''}
                      type="file"
                      name="HeaderFile"
                      label={t(translations.TemplateHeaderImage)}
                      inputProps={{ multiple: false }}
                      disabled={formik.isSubmitting}
                      accept="image/png, image/jpeg"
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormRichTextField
                      id="HtmlID"
                      name="Html"
                      label={t(translations.ModifyInvoiceLetterTemplateHere)}
                      disabled={formik.isSubmitting}
                      isInvoiceTemplateEditor={true}
                      extendedMode={true}
                      additionalButtons={additionalButtons}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormInputFile
                      id="FooterFileID"
                      uploadLabel={initialValues.FooterFileName ?? ''}
                      type="file"
                      name="FooterFile"
                      label={t(translations.TemplateFooterImage)}
                      inputProps={{ multiple: false }}
                      disabled={formik.isSubmitting}
                      accept="image/png, image/jpeg"
                    />
                  </FormRow>
                  <FormRow>
                    <FormColorPicker
                      id="TableHeaderColorID"
                      name="TableHeaderColor"
                      label={t(translations.InvoiceLetterTableHeadColor)}
                      info={t(translations.InvoiceLetterTableHeadColor_info)}
                      value={initialValues.TableHeaderColor ?? ''}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow>
                    <FormColorPicker
                      id="TableBodyColorID"
                      name="TableBodyColor"
                      label={t(translations.InvoiceLetterTableBodyColor)}
                      info={t(translations.InvoiceLetterTableBodyColor_info)}
                      value={initialValues.TableHeaderColor ?? ''}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                </FormFieldsSection>
              </FormLeftSection>
              <FormRightSection>
                <FormFieldsSection>
                  <FormRow>
                    <Paper className={classes.rightSectionH5Title}>
                      <BasicTypography
                        style={{ color: '#FFFFFF' }}
                        variant="boldM"
                      >
                        {t(
                          translations.ReservedWordsForInvoiceLetterTemplateMsg,
                        )}
                      </BasicTypography>
                    </Paper>
                  </FormRow>
                  <FormRow>
                    <PopUpListItems />
                  </FormRow>
                </FormFieldsSection>
              </FormRightSection>
            </StyledForm>
          );
        }}
      </Formik>
    );
  };

  const leftActions = [
    () => (
      <>
        <Button
          onClick={() => submitRef.current?.()}
          size="small"
          disabled={busy}
        >
          {t(translations.Save)}
        </Button>
      </>
    ),
  ];

  const rightActions = [
    () => (
      <>
        <Button
          variant="ghost"
          startIcon={<Icon icon="times" />}
          onClick={close}
          size="small"
          disabled={busy}
        >
          {t(translations.Cancel)}
        </Button>
      </>
    ),
  ];

  //RENDER
  return (
    <PageWrapper
      loading={busy}
      pageName={
        isEdit
          ? t(translations.EditInvoiceTemplate)
          : t(translations.menu_InvoiceTemplates)
      }
      titlePage={
        isEdit
          ? `${t(translations.InvoiceTemplate)} #${Id}`
          : t(translations.AddInvoiceTemplate)
      }
      titleTooltip={t(translations.EditArticle)}
      closable={true}
      useSidePanel={true}
      children={<InvoiceTemplateForm />}
      leftActions={leftActions}
      rightActions={rightActions}
      disableExpandToggle={true}
      /*       closeSidePanel={() => {
        if (hasChanges) {
          dispatch(actions.setConfirmOpen(true));
        } else {
          dispatch(actions.resetSidePanel());
        }
      }} */
      isCover={props.isCover}
      closeCover={props.closeCover}
    />
  );
};
