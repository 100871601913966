import { Body } from 'app/components/Typography';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dateUtils } from 'utils/date-utils';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import styled from 'styled-components';
import {
  getSingleReservationStatus,
  IReservationDto,
} from 'app/pages/ReservationDetails/Details/slice/types';

export interface UsageReservationDetailsProps {
  details: IReservationDto;
  openPanel?: (state: SidePanelOpenState) => void;
  useSidePanel?: boolean;
}

export function UsageReservationDetails(props: UsageReservationDetailsProps) {
  const { details, openPanel, useSidePanel } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <DetailsRoot className={'details-root'}>
        <Body bold={true}>{t(translations.ReservationDetails)}</Body>
        <div className={'details-content'}>
          <div className={'details-row'}>
            <Body size="small">{t(translations.ReservationId)}</Body>
            <ReservationLink
              id={details.Id}
              useSidePanel={useSidePanel}
              openPanelWithCover={openPanel}
            >
              {`# ${details.Id}`}
            </ReservationLink>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.StartTime)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.StartTime)}
            </Body>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.EndTime)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.EndTime)}
            </Body>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.ReservationStatus)}</Body>
            <Body size="small" bold={true}>
              {getSingleReservationStatus(details.StatusId).Name ?? 'N/A'}
            </Body>
          </div>
        </div>
      </DetailsRoot>
    </React.Fragment>
  );
}
export const DetailsRoot = styled.div`
  &.details-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 0;
    width: 100%;
    & .details-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 16px 0px;
      width: 100%;
      & .details-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        width: inherit;
      }
    }
  }
`;
