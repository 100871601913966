import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { uploadStyle } from './styles';
import * as React from 'react';
import { openExportLink, toRootedURL } from 'utils/url-utils';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import cliTruncate from 'cli-truncate';
import { Body } from 'app/components/Typography';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { FilePreview } from './FilePreview/Loadable';
import clsx from 'clsx';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface FileViewProps {
  file: IFormFileValue;
  onRemove: (value: any) => void;
  withShrinkLabel?: boolean;
}

export function FileView(props: FileViewProps) {
  const { file, onRemove, withShrinkLabel } = props;
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const fileName =
    file.DisplayValue !== null && file.DisplayValue !== undefined
      ? file.DisplayValue
      : '';
  const fileSize =
    file.Size == null
      ? null
      : prettyBytes(file.Size, { maximumFractionDigits: 2 });

  const fileUrl =
    file.PostedFile === null || file.PostedFile === undefined
      ? toRootedURL(`/file.ashx?id=${file.Id}&ap=true`)
      : file.PostedFile.webkitRelativePath;

  const downloadClick = async () => {
    if (file.PostedFile === null || file.PostedFile === undefined) {
      await openExportLink(fileUrl);
    }
  };
  // const fileDownloadClick = async () => {
  //   fileDownload(res.data, filename)
  // };

  const classes = uploadStyle();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  return (
    <React.Fragment>
      <div
        className={clsx(classes.previewContainer, {
          shrinkLabel: withShrinkLabel,
        })}
      >
        <div className={classes.previewIconAndText}>
          {file.Value !== null ? (
            <Tooltip title={t(translations.Download)}>
              <div
                className={classes.previewIcon}
                onClick={() => downloadClick()}
              >
                <FilePreview
                  fileName={fileName}
                  fileUrl={fileUrl}
                  fileDisplayValue={file.DisplayValue}
                />
              </div>
            </Tooltip>
          ) : (
            <div className={classes.previewIcon}>
              <Icon icon="link" />
            </div>
          )}
          <div className={classes.previewText}>
            <Body bold={true} size="small">
              {cliTruncate(fileName, sidePanelExpanded && !isMobile ? 40 : 25, {
                position: 'middle',
              })}
            </Body>

            {fileSize && (
              <Body size="small" className={classes.previewTextSize}>
                {fileSize}
              </Body>
            )}
          </div>
        </div>
        <div className={classes.previewCloseButton}>
          <IconButton size="xs" onClick={() => onRemove(fileName)}>
            <Icon icon="times" className={classes.previewCloseButtonIcon} />
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );
}
