import { httpClient } from 'api/HttpClient';
import { Body } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { RichTextEditor } from 'app/components/RichTextEditor';
import { useAppSettingsSlice } from 'app/slice';
import { translations } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../ReadOnly/styles';
import { selectInvoice } from '../Slice/selector';
const url = '/api/odata/v4/InvoiceDetails';
export const InvoiceRemarks = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();

  const invoice = useSelector(selectInvoice);
  const [remarks, setRemarks] = useState(invoice?.Remarks);
  if (!invoice) return null;
  const updateRemarks = () => {
    httpClient
      .patch(url + '(' + invoice.Id + ')', { Remarks: remarks })
      .then(() =>
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: t(translations.UpdatedOK),
          }),
        ),
      )
      .catch(e =>
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: t(translations.UpdateFailed),
          }),
        ),
      );
  };
  return (
    <div className={classes.base}>
      <Body bold={true}>{t(translations.Remarks)}</Body>
      <RichTextEditor onChange={setRemarks} value={remarks} />
      <br />
      <Button onClick={updateRemarks}>{t(translations.Update)}</Button>
    </div>
  );
};
