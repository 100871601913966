/**
 *
 * FormUsageEquipmentsPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IUsageEquipmentDto } from 'app/pages/UsageDetails/Details/slice/types';
import { UsageEquipmentPicker } from 'app/components/pickers/AutocompletePickers/UsageEquipmentPicker';
import { IFieldProps } from '../FormRender/IFormFieldSetting';

export const FormUsageEquipmentsPicker = ({
  label,
  ...props
}: FieldHookConfig<IUsageEquipmentDto | null> &
  IFieldProps & { autoExpand?: string }) => {
  const [field, meta, helpers] = useField<IUsageEquipmentDto | null>(props);

  return (
    <UsageEquipmentPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={props.disabled}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      expandedColumns={props.autoExpand}
    />
  );
};
