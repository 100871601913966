import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Caption } from '../Typography';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import clsx from 'clsx';

export type AlertVariant = 'success' | 'info' | 'warning' | 'error';
export type AlertContentType = {
  message?: string;
  type: AlertVariant;
  closable: boolean;
  children?: React.ReactNode;
  variant?: 'outlined' | 'filled' | 'standard';
};

export interface BasicAlertProps {
  content: AlertContentType;
  variant?: 'standard' | 'filled' | 'outlined';
  className?: string;
  showMore?: boolean;
  collapsedMinHeight?: number;
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
}

const MessageText = styled.div`
  ${(props: { showMore: boolean; minHeight: number }) => {
    return css`
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: normal;
      -webkit-box-orient: horizontal;
      max-height: ${props.showMore ? '100%' : props.minHeight + 'px'};
    `;
  }}
`;
const defaultIconMapping = {
  success: <Icon icon={['fal', 'circle-check']} fontSize="inherit" size="xs" />,
  warning: (
    <Icon icon={['fal', 'triangle-exclamation']} fontSize="inherit" size="xs" />
  ),
  error: (
    <Icon icon={['fal', 'circle-quarters']} fontSize="inherit" size="xs" />
  ),
  info: (
    <Icon icon={['fal', 'circle-exclamation']} fontSize="inherit" size="xs" />
  ),
};
export function BasicAlert(props: BasicAlertProps) {
  const {
    content,
    variant,
    className,
    showMore,
    collapsedMinHeight,
    onClose,
  } = props;
  const [isShowMore, setIsShowMore] = React.useState(false);
  const toggleReadMore = () => setIsShowMore(show => !show);
  const [open, setOpen] = React.useState(true);
  return (
    <Collapse in={open}>
      <Alert
        variant={variant || content.variant}
        severity={content.type}
        className={clsx(className, { showMore: !showMore })}
        iconMapping={defaultIconMapping}
        onClose={onClose}
        action={
          <React.Fragment>
            {content.closable && (
              <IconButton
                aria-label="close"
                variant="text"
                size="small"
                shape="square"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="times" />
              </IconButton>
            )}
            {showMore && (
              <IconButton
                aria-label="more"
                variant="text"
                size="small"
                shape="square"
                title={isShowMore ? 'show less' : 'show more'}
                onClick={() => {
                  toggleReadMore();
                }}
              >
                <Icon
                  icon={
                    isShowMore ? 'chevron-double-up' : 'chevron-double-down'
                  }
                />
              </IconButton>
            )}
          </React.Fragment>
        }
      >
        {showMore ? (
          <MessageText
            showMore={isShowMore}
            minHeight={collapsedMinHeight || 45}
          >
            {!!content.message && <Caption>{content.message}</Caption>}
            {!!content.children && (
              <React.Fragment>{content.children}</React.Fragment>
            )}
          </MessageText>
        ) : (
          <React.Fragment>
            {!!content.message && <Caption>{content.message}</Caption>}
            {!!content.children && (
              <React.Fragment>{content.children}</React.Fragment>
            )}
          </React.Fragment>
        )}
      </Alert>
    </Collapse>
  );
}
