import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  take,
  select,
  takeLatest,
  takeLeading,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { serviceRequestsKanbanActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { httpClient } from 'api/HttpClient';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import {
  selectFilter,
  selectGlobalFilter,
  selectSectionOrders,
  selectServiceRequestsKanbanData,
  selectServiceRequestsKanbanTickets,
  selectTicketIds,
} from './selectors';
import {
  Dto,
  IDataList,
  ISection,
  ReorderPayload,
  order,
  sectionOrders,
} from './types';
import { omit } from 'lodash';
import { takeLatestByKey } from 'utils/redux-saga';
import { PartialExceptId } from 'utils/typeUtils';
import { extractErrorMessages, getModelStateErrors } from 'api/utils';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ServiceRequestTicketDetailsProps } from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';

const ENTITY_SET_URL = '/api/odata/v4/ServiceRequestTickets';

const fetchTicketsByStatusId = async (params: {
  statusId: number;
  top: number;
  skip: number;
  globalFilter: string;
  filter: string;
  order: order | null;
}) => {
  const response: IODataQueryResponse<IServiceRequestStatusDto> = await httpClient.get(
    ENTITY_SET_URL,
    fetchTicketsParams({
      statusId: params.statusId,
      top: params.top + 1,
      skip: params.skip,
      globalFilter: params.globalFilter,
      filter: params.filter,
      order: params.order,
    }),
  );
  const count = response['@odata.count'];
  const result: IDataList<IServiceRequestStatusDto> = {
    id: params.statusId,
    items: response.value.slice(0, params.top),
    count: count ?? 0,
    more: response.value.length > params.top,
  };
  return result;
};
const fetchTicketById = async (params: { Id: number | null }) => {
  if (params.Id === null) {
    return Promise.resolve(undefined);
  } else {
    const response = await httpClient.get(
      `${ENTITY_SET_URL}(${params.Id})`,
      FETCH_SELECT_EXPAND,
    );
    return response;
  }
};

const FETCH_SELECT_EXPAND = {
  $select: [
    'Id',
    'Index',
    'SubSamplesCount',
    'TotalSamplesCount',
    'SamplesCount',
    'Priority',
    'ParentTicketId',
    'ServiceRequestId',
  ].join(','),
  $expand: [
    'Status($select=Id,Name)',
    //'Service($select=Id,Name)',
    //'ServiceRequestStatus($select=Id,Name)',
    'AssignedTo($select=Id,Name)',
    'ServiceRequest($select=CreatedFor,Service;$expand=CreatedFor($select=Id,Name),Service($select=Id,Name;$expand=ServiceGroup($select=Id,Name)),Budget($select=Id,Name))',
  ].join(','),
};
function fetchTicketsParams(params: {
  statusId: number;
  top: number;
  skip: number;
  globalFilter: string;
  filter: string;
  order: order | null;
}): any {
  const $filter = new ODataFilterBuilder<any>({
    predicates: [`Status/Id eq ${params.statusId}`, params.filter],
    stringSearch: params.globalFilter,
    stringColumns: [
      'cast(ServiceRequestId,Edm.String)',
      'cast(Priority,Edm.String)',
      'ServiceRequest/CreatedFor/Name',
      'ServiceRequest/CreatedFor/Id',
    ],
  }).toString();
  return {
    ...FETCH_SELECT_EXPAND,
    $filter,
    $top: params.top,
    $skip: params.skip,
    $count: true,
    $orderBy:
      params.order === null
        ? 'Index,ServiceRequestId'
        : 'ServiceRequestId ' + params.order,
  };
}
/**
 * Number of initially fetched tickets per each status
 */
const INITIAL_TOP = 10;
const notificationMessageKey_getItems = 'getItems';
function* doGetItems(action: PayloadAction<number[]>) {
  const globalFilter: string = yield select(selectGlobalFilter);
  const filter: string = yield select(selectFilter);
  const orders: sectionOrders[] = yield select(selectSectionOrders);
  try {
    const bar: Array<IDataList<IServiceRequestTicketDto>> = yield all(
      action.payload.map(statusId =>
        call(fetchTicketsByStatusId, {
          statusId,
          top: INITIAL_TOP,
          skip: 0,
          globalFilter,
          filter,
          order: orders.find(o => o.sectionId === statusId)?.order ?? null,
        }),
      ),
    );

    yield put(actions.getItems_Success(bar));
  } catch (error: unknown) {
    yield put(
      appSettingsActions.addNotification({
        key: notificationMessageKey_getItems,
        message: i18next.t(translations.errormessage),
        variant: 'error',
      }),
    );
    yield put(actions.getItems_Error(Error));
  }
}
function* doGetMoreItems(action: PayloadAction<number>) {
  const tickets: Record<
    string,
    IDataList<IServiceRequestTicketDto>
  > = yield select(selectServiceRequestsKanbanTickets);
  const globalFilter: string = yield select(selectGlobalFilter);
  const filter: string = yield select(selectFilter);
  const orders: sectionOrders[] = yield select(selectSectionOrders);
  try {
    const section = tickets[action.payload.toString()];
    const bar: IDataList<IServiceRequestTicketDto> = yield call(
      fetchTicketsByStatusId,
      {
        statusId: action.payload,
        top: INITIAL_TOP,
        skip: section.items.length,
        globalFilter,
        filter,
        order: orders.find(o => o.sectionId === action.payload)?.order ?? null,
      },
    );

    yield put(actions.getMoreItems_Success(bar));
  } catch (error: unknown) {
    yield put(
      appSettingsActions.addNotification({
        key: notificationMessageKey_getItems,
        message: i18next.t(translations.errormessage),
        variant: 'error',
      }),
    );
    yield put(actions.getItems_Error(Error));
  }
}

function* doSetSelectedStatuses(
  action: PayloadAction<IServiceRequestStatusDto[] | undefined>,
) {
  if (action.payload !== undefined) {
    const original: Record<string, Dto> = yield select(
      selectServiceRequestsKanbanTickets,
    );
    const loadedIds = Object.keys(original);
    const currentStatusIdentifiers = action.payload
      .map(f => f.Id)
      .filter(statusId => !loadedIds.includes(statusId.toString()));

    if (currentStatusIdentifiers.length > 0) {
      yield put(actions.getItems(currentStatusIdentifiers));
    }
  }
}
function* doReorder(action: PayloadAction<ReorderPayload>) {
  if (action.payload.destinationSectionId === action.payload.sourceSectionId) {
    return;
  }
  try {
    const data: ISection<
      IServiceRequestStatusDto,
      IServiceRequestTicketDto
    >[] = yield select(selectServiceRequestsKanbanData);

    const indexChanges = data
      .flatMap(section => {
        return (
          section.data?.items?.map((item, index) => {
            return {
              Id: item.Id,
              Index: item.Index === index ? undefined : index,
              Status:
                item.Status.Id === section.id ? undefined : section.section,
            };
          }) ?? []
        );
      })
      .filter(item => item.Status !== undefined);

    const payload = {
      Id: action.payload.Id,
      ServiceRequestStatusId: action.payload.destinationSectionId,
      Index: action.payload.destinationIndex,
    };

    yield call(
      httpClient.patch,
      `${ENTITY_SET_URL}(${payload.Id})`,
      omit(payload, 'Id'),
    );
    yield put(actions.reorder_Success(indexChanges));
  } catch (error) {
    yield put(
      actions.reorder_Error({
        destinationIndex: action.payload.sourceIndex,
        destinationSectionId: action.payload.sourceSectionId,
        Id: action.payload.Id,
        sourceIndex: action.payload.destinationIndex,
        sourceSectionId: action.payload.destinationSectionId,
      }),
    );
  }
}
function* doInsert(action: PayloadAction<Partial<IServiceRequestTicketDto>>) {
  const url = ENTITY_SET_URL;
  const notificationMessageKey = 'serviceRequestTicektsInsert';
  try {
    const payload: Partial<Dto> = {
      AssignedTo_Id: action.payload.AssignedTo?.Id,
      Index: action.payload.Index,
      Priority: action.payload.Priority,
      SamplesCount: action.payload.SamplesCount,
      ServiceRequestId: action.payload.ParentTicket?.ServiceRequestId,
      ServiceRequestStatusId: action.payload.Status?.Id,
      ParentTicketId: action.payload.ParentTicketId,
    };
    const response = yield call(httpClient.post, url, payload);
    const p: Dto = {
      ...action.payload.ParentTicket,
      ...response,
      ...action.payload,
    };
    yield put(actions.insert_Success(p));
    yield put(
      appSettingsActions.addNotification({
        key: notificationMessageKey,
        variant: 'success',
        message: i18next.t(translations.GenericInsertSuccessMessage),
      }),
    );
  } catch (error) {
    yield put(actions.insert_Error());
    const modelState = getModelStateErrors(error);
    console.error(error);
    if (modelState !== undefined) {
      const messages = Object.values(modelState).flat();
      for (const message of messages) {
        yield put(
          appSettingsActions.addNotification({
            key: notificationMessageKey,
            variant: 'error',
            message: message,
          }),
        );
      }
    }
  }
}
function* doUpdate(
  action: PayloadAction<PartialExceptId<IServiceRequestTicketDto>>,
) {
  const url = `${ENTITY_SET_URL}(${action.payload.Id})`;
  const notificationMessageKey = 'serviceRequestTicketUpdate';
  try {
    const payload: Partial<IServiceRequestTicketDto> = {
      AssignedTo_Id: action.payload.AssignedTo?.Id,
      Priority: action.payload.Priority,
      Index: action.payload.Index,
      ServiceRequestStatusId: action.payload.Status?.Id,
      SamplesCount: action.payload.SamplesCount,
    };
    yield call(httpClient.patch, url, payload);
    yield put(actions.update_Success(action.payload));
    yield put(layoutActions.resetSidePanel());
    yield put(
      appSettingsActions.addNotification({
        key: notificationMessageKey,
        variant: 'success',
        message: i18next.t(translations.GenericUpdateSuccessMessage),
      }),
    );
    //yield put(layoutActions.resetSidePanel());
  } catch (error) {
    yield put(actions.update_Error());
    const messages = extractErrorMessages(error);
    if (messages !== undefined) {
      for (const message of messages) {
        yield put(
          appSettingsActions.addNotification({
            key: notificationMessageKey,
            variant: 'error',
            message: message,
          }),
        );
      }
    }
  }
}

/**
 * Runs a monitor on a given selector
 * @param selector selector to monitor
 */
function* selectorChangeSaga(selector, pattern) {
  let previousValue;
  while (true) {
    const currentValue = yield* monitorSelector(
      selector,
      previousValue,
      pattern,
    );
    if (previousValue !== currentValue) {
      //yield spawn(loadRowSaga, focusType, focusId);
    }
    previousValue = currentValue;
  }
}

function* monitorSelector(selector, previousValue, takePattern = '*') {
  while (true) {
    const nextValue = yield select(selector);
    if (nextValue !== previousValue) {
      return nextValue;
    }
    yield take(takePattern);
  }
}
function* doShowEdit(action: PayloadAction<Dto>) {
  // const data = yield select(s)

  const openSidePanelProps: ServiceRequestTicketDetailsProps = {
    item: action.payload,
    useSidePanel: true,
  };
  const parent = yield call(fetchTicketById, {
    Id: action.payload.ParentTicketId,
  });
  yield put(
    actions.showEdit_Success({
      ...action.payload,
      ParentTicket: parent,
    }),
  );
  yield put(
    layoutActions.openSidePanel({
      type: RenderPageType.ServiceRequestTicketDetails,
      props: openSidePanelProps,
    }),
  );
}
function* doShowEditParent(action: PayloadAction<Dto>) {
  try {
    const ticket = yield call(fetchTicketById, {
      Id: action.payload.ParentTicketId,
    });
    yield put(actions.showEdit(ticket));
  } catch (error) {
    yield put(actions.showEdit_Error());
  }
}

function* doUpdateitemsForFilter(action: PayloadAction<string>) {
  const tickets: number[] = yield select(selectTicketIds);
  yield* doGetItems({ payload: tickets, type: action.type });
}

function* doUpdateSecionOrder(action: PayloadAction<sectionOrders>) {
  const globalFilter: string = yield select(selectGlobalFilter);
  const filter: string = yield select(selectFilter);
  try {
    const bar: IDataList<IServiceRequestTicketDto> = yield call(
      fetchTicketsByStatusId,
      {
        statusId: action.payload.sectionId,
        top: INITIAL_TOP,
        skip: 0,
        globalFilter,
        filter,
        order: action.payload.order ?? null,
      },
    );

    yield put(actions.getItems_Success([bar]));
  } catch (error: unknown) {
    yield put(
      appSettingsActions.addNotification({
        key: notificationMessageKey_getItems,
        message: i18next.t(translations.errormessage),
        variant: 'error',
      }),
    );
    yield put(actions.getItems_Error(Error));
  }
}

export function* serviceRequestsKanbanSaga() {
  yield takeLatest(actions.setGlobalFilter.type, doUpdateitemsForFilter);
  yield takeLatest(actions.setFilterString.type, doUpdateitemsForFilter);
  yield takeLatest(actions.getItems.type, doGetItems);
  yield takeLatest(actions.setSectionOrder.type, doUpdateSecionOrder);
  yield takeLatestByKey<number>(
    actions.getMoreItems.type,
    f => f,
    doGetMoreItems,
  );
  yield takeLatest(actions.setSelectedStatuses.type, doSetSelectedStatuses);
  yield takeEvery(actions.reorder.type, doReorder);
  yield takeLeading(actions.insert.type, doInsert);
  yield takeLeading(actions.update.type, doUpdate);
  yield takeLeading(actions.showEdit.type, doShowEdit);
  yield takeLeading(actions.showEditParent.type, doShowEditParent);
  yield selectorChangeSaga(
    selectServiceRequestsKanbanTickets,
    actions.setSelectedStatuses.type,
  );
}
