import { UsersApi } from 'api/UsersApi';
import { Button } from 'app/components/BasicButtons/Button';
import { BaseOptionsPicker } from 'app/components/BasicPickers/BaseOptionsPicker';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { useAppSettingsSlice } from 'app/slice';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { AttributeValue } from '../../typeUtils';

export function DefaultUserGroup(props: AttributeValue<IUsers>) {
  const { original } = props;
  const { t } = useTranslation();
  const { actions } = useAppSettingsSlice();
  const dispatch = useDispatch();
  const [updated, setUpdated] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<Entity<
    string
  > | null>(
    original.AllowedUserGroups.find(
      option => option.Id === original.DefaultUserGroupId,
    ) ?? null,
  );
  const getOptions = React.useCallback(
    (searchTerm: string | null) => {
      let items = original.AllowedUserGroups;
      if (searchTerm !== null && searchTerm !== '') {
        return items.filter(
          f =>
            f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
        );
      }
      return items;
    },
    [original.AllowedUserGroups],
  );
  const onChange = React.useCallback((option: Entity<string> | null) => {
    setSelectedOption(option);
  }, []);
  const handleUpdate = React.useCallback(async () => {
    setUpdated(true);
    try {
      const result: IResponseType = await UsersApi.updateDefaultUserGroup(
        original.Name,
        selectedOption?.Id ?? undefined,
      );
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: result.ErrorMessages[0],
          }),
        );
        setUpdated(false);
      } else {
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: result.SuccessMessages[0],
          }),
        );
        setUpdated(false);
      }
    } catch (e) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Default User Group update failed',
        }),
      );
      setUpdated(false);
    }
  }, [actions, dispatch, original.Name, selectedOption?.Id]);

  return (
    <FormRow>
      <BaseOptionsPicker
        getOptions={getOptions}
        value={selectedOption}
        onChange={onChange}
        fullWidth
        variant="filled"
        disabled={updated}
        label={t(translations.DefaultUserGroup)}
      />
      <Button
        variant="main"
        size="small"
        disabled={
          selectedOption === null ||
          selectedOption.Id === original.DefaultUserGroupId ||
          updated
        }
        onClick={handleUpdate}
        processing={updated}
      >
        {t(translations.Update)}
      </Button>
    </FormRow>
  );
}
