import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { Column } from 'react-table';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import {
  selectAuthenticatedUser,
  selectUsageEnabled,
} from 'app/slice/selectors';
import { IReservationWaitingListDto } from 'app/pages/ReservationWaitingListPage/IReservationWaitingListDto';
import { getColumns } from 'app/pages/ReservationWaitingListPage/Columns';
import { GetActions } from 'app/pages/ReservationWaitingListPage/Actions/GetActions';
import waitingListPrimaryAction from 'app/pages/ReservationWaitingListPage/PrimaryAction';
import { ReservationWaitingListTable } from 'app/pages/ReservationWaitingListPage/ReservationWaitingListTable';
import { BaseFilterValues, useFilters } from './TableFilters';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { GetFilters } from 'app/pages/ReservationWaitingListPage/Filter';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';

const AssetWaitingListColumns: string[] = ['UserDisplayName', 'WhenToNotify'];

const url = '/api/odata/v4/ReservationWaitingList';

const GetPredicates = (
  serviceId: number,
): Array<Condition<IReservationWaitingListDto> | string> => {
  const res: Array<Condition<IReservationWaitingListDto> | string> = [
    new Condition<IReservationWaitingListDto>(
      'EquipmentId',
      ODataOperators.Equals,
      serviceId,
    ),
  ];
  return res;
};
export async function GetWaitingListTableCount(
  serviceId: number,
  name: string,
  updateCounters: (name: string, count: number) => void,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(serviceId) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface AssetWaitingListProps {
  serviceId: number;
  assetName: string;
  getFiltersDefinition?: GetPageFilters<IReservationWaitingListDto>;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
}
class FilterValues extends BaseFilterValues {
  UserName: FilterValueType | null = null;
}
const filterValues = new FilterValues();
export function AssetWaitingList(props: AssetWaitingListProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const usageEnabled = useSelector(selectUsageEnabled);
  const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(GetFilters, filterValues.GetFilter(), filterValues.SetValue);
  const waitingListColumns = React.useMemo(
    () =>
      getColumns(
        t,
        undefined,
        undefined,
        authenticatedUser,
        usageEnabled,
        props.useSidePanel,
        props.openPanel,
      ),
    [authenticatedUser, props.openPanel, props.useSidePanel, t, usageEnabled],
  );
  const columns = React.useMemo(() => {
    let res: Column<IReservationWaitingListDto>[] = [];
    for (const c of AssetWaitingListColumns) {
      const column = waitingListColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [waitingListColumns]);

  const PageActions: PageActionRenderer[] = GetActions({
    equipmentId: props.serviceId,
    useSidePanel: props.useSidePanel,
    openPanelWithCover: props.openPanel,
    isMobile: props.isMobile,
  });
  const primaryAction: PrimaryAction<IReservationWaitingListDto> = React.useCallback(
    (...args) => {
      waitingListPrimaryAction(
        props.openPanel,
        args[0],
        args[1],
        args[2],
        props.useSidePanel,
      );
    },
    [props.openPanel, props.useSidePanel],
  );
  const goToWaitingList = React.useCallback(() => {
    dispatch(actions.toggleSidePanel(false));
    history.push(
      '/waitinglist?eqid=' +
        props.serviceId +
        '&userName=' +
        authenticatedUser?.Id ?? '',
    );
  }, [actions, authenticatedUser?.Id, dispatch, history, props.serviceId]);

  const predicates = React.useMemo(() => {
    return GetPredicates(props.serviceId);
  }, [props.serviceId]);
  const initialState: IBasicTableState<IReservationWaitingListDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: true }],
    }),
    [],
  );
  return (
    <ReservationWaitingListTable
      columns={columns}
      initialState={initialState}
      predicates={predicates}
      pageActions={PageActions}
      useConfigurable={false}
      availableFilters={allfilters}
      appliedFilters={appliedFilters}
      onFilterChange={handleFilterChange}
      getFilterValue={getFilterValue}
      setFilterValue={setFilterValue}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      serviceGroups={[]}
      primaryAction={primaryAction}
      customScreenName={
        <React.Fragment>
          <FormSectionTitle title={t(translations.WaitingList)} />
          <IconButton aria-label="link" onClick={goToWaitingList}>
            <Icon icon="arrow-up-right-from-square" />
          </IconButton>
        </React.Fragment>
      }
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
    />
  );
}
