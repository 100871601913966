import { ChargeApi } from 'api/ChargeApi';
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { InputLabel } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FormBudgetPicker } from 'app/components/Forms/FormBudgetPicker';
import { FormCatRateTypePicker } from 'app/components/Forms/FormCatRateTypePicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormOfflineServicePicker } from 'app/components/Forms/FormOfflineServicePicker';
import { FormSampleTypePicker } from 'app/components/Forms/FormSampleTypePicker';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import { FormServiceUserGroupPicker } from 'app/components/Forms/FormServiceUserGroupPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { loadBudgets } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { serviceGroupFilterUrl } from 'app/components/pickers/ServiceGroupPicker';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { AllowCreateInvoicesBy } from 'enums/allowCreateInvoicesBy';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings } from 'utils/globalSettings';
import { GetChargeSchema } from './ChargeSchema';
import { ICharge } from './ICharge';
import { FormOnlineServicesSinglePicker } from 'app/components/Forms/FormEquipmentsPicker';
import { ServiceGroupFilter } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import useGlobalSettingsHook from '../ReservationDetails/Details/components/useGlobalSettingsHook';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import produce from 'immer';
import { restrictedBudgetFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';

export interface ChargeProps extends CoverProps {
  invoiceId?: number;
  budget: Entity<number> | null;
  serviceGroup: Entity<number> | null;
  rowId?: number;
  serviceGroupPredicate?: (string | Condition<Entity<number>>)[];
}

const onlyManual = true;

export const AddCharge = ({ serviceGroupPredicate, ...props }: ChargeProps) => {
  const { newDate } = useSystemDate();
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { actions: notifyActions } = useAppSettingsSlice();
  const submitRef = useRef<any>();
  const [busy, setBusy] = useState(false);
  const continueLink = useSelector(selectContinueToLink);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const closeApproved = useSelector(selectConfirmApproved);
  const closeRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const globalSettings = useGlobalSettingsHook();

  const [insertedAt, setinsertedAt] = useState<Date | null>(null);
  const [insertedBy, setinsertedBy] = useState<string | null>(null);
  const isEdit = !!props.rowId;
  const userGroupByBudeget = settings.GetBoolean(
    AllowedSettings[AllowedSettings.ReservationUserGroupGroupByBudgetUserGroup],
  );
  const fundingTypeVisible = React.useMemo(() => {
    return (
      globalSettings.fundingTypeBudgetEnabled &&
      globalSettings.budgetModuleEnabled
    );
  }, [globalSettings]);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const [initialValues, setInitialValue] = useState<ICharge>({
    Id: props.invoiceId,
    Quantity: 0,
    Rate: 0,
    Amount: 0,
    Date: newDate(),
    User: null,
    FeeType: null,
    OtherService: null,
    Resource: null,
    SampleType: null,
    Budget: props.budget,
    ServiceGroup: props.serviceGroup,
    Manual: true,
    UserGroup: null,
    Remarks: '',
    FundingType:
      fundingTypeVisible &&
      !!props.budget &&
      props.budget !== null &&
      (props.budget as IBudgetFilterDto).FundingTypeId !== null
        ? getSingleInvoiceFundingType(
            (props.budget as IBudgetFilterDto).FundingTypeId ?? 0,
          )
        : null,
    PurchaseOrder: null,
  });

  useEffect(() => {
    if (closeApproved) {
      if (continueToOther.continueOnApprove) {
        dispatch(actions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            actions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(actions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(notifyActions.navigate(continueLink));
        dispatch(actions.resetSidePanel());
      } else {
        dispatch(actions.resetSidePanel());
      }
    }
    if (closeRejected) {
      dispatch(actions.resetConfirmState());
    }
  }, [
    actions,
    closeApproved,
    closeRejected,
    continueLink,
    continueToOther,
    dispatch,
    notifyActions,
  ]);
  useEffect(() => {
    if (!isEdit) {
      if (props.budget !== null) {
        setBusy(true);
        let isMounted = true;
        (async () => {
          try {
            let queryBudg = loadBudgets('base', [
              new Condition('Id', ODataOperators.Equals, props.budget!.Id),
            ]);
            const budgetRes = await queryBudg(null);
            const serviceGroupRes = await (() => {
              if (props.serviceGroup !== null) return Promise.resolve([]);
              return getAutoCompleteLoadDataFn<Entity<number>>({
                url: serviceGroupFilterUrl,
                predicates: authenticatedUser
                  ? [
                      new Condition(
                        'Id',
                        ODataOperators.Includes,
                        authenticatedUser.AdminServiceGroups,
                      ),
                    ]
                  : undefined,
              })('', serviceGroupPredicate).then(v => v.value);
            })();
            if (isMounted) {
              const values = { ...initialValues };
              if (budgetRes && budgetRes.value.length > 0) {
                const budegtval = budgetRes.value[0];
                values.UserGroup =
                  budegtval.UserGroupId && budegtval.UserGroupName
                    ? {
                        Id: budegtval.UserGroupId,
                        Name: budegtval.UserGroupName,
                      }
                    : values.UserGroup;
              }
              if (serviceGroupRes && serviceGroupRes.length === 1) {
                values.ServiceGroup = serviceGroupRes[0];
              }
              setInitialValue(values);
              setBusy(false);
            }
          } catch (error) {
            console.error(error);
          }
        })();
        return () => {
          isMounted = false;
        };
      }
      return;
    }
    setBusy(true);
    let mounted = true;
    (async () => {
      try {
        const res = await httpClient.get(
          '/api/odata/v4/InvoiceRows(' + props.rowId + ')',
          {
            id: props.invoiceId ?? 'null',
            $select: [
              'InvoiceId',
              'BudgetId',
              'Budget',
              'OnlineService',
              'OnlineServiceId',
              'OfflineServiceType',
              'OfflineServiceTypeId',
              'SampleService',
              'SampleServiceId',
              'CatRateTypeId',
              'CatRateType',
              'Quantity',
              'BaseRate',
              'Amount',
              'SubsProfPercentage',
              'User',
              'UserId',
              'Date',
              'ServiceGroupId',
              'ServiceGroupName',
              'Manual',
              'ActualRate',
              'UserGroupId',
              'UserGroup',
              'InsertedAt',
              'InsertedByDisplay',
              'Remarks',
              'FundingTypeId',
              'PurchaseOrder',
              'PurchaseOrderOptionId',
            ].join(','),
          },
        );
        if (mounted) {
          setInitialValue({
            Id: res.InvoiceId ?? 0,
            Budget:
              !!res.BudgetId && res.BudgetId !== null
                ? ({
                    Id: res.BudgetId,
                    Name: res.Budget,
                    PurchaseOrderOptionId: res.PurchaseOrderOptionId,
                  } as Entity<number>)
                : null,
            User: res.UserId ? { Id: res.UserId, Name: res.User } : null,
            Resource: res.OnlineServiceId
              ? { Id: res.OnlineServiceId, Name: res.OnlineService }
              : null,
            OtherService: res.OfflineServiceTypeId
              ? { Id: res.OfflineServiceTypeId, Name: res.OfflineServiceType }
              : null,
            FeeType: { Id: res.CatRateTypeId, Name: res.CatRateType },
            SampleType: res.SampleServiceId
              ? { Id: res.SampleServiceId, Name: res.SampleService }
              : null,
            Quantity: res.Quantity,
            Rate: res.BaseRate,
            Amount: res.Amount,
            Date: res.Date,
            ServiceGroup: res.ServiceGroupId
              ? { Id: res.ServiceGroupId, Name: res.ServiceGroupName }
              : null,
            Manual: res.Manual,
            ActualRate: res.ActualRate,
            UserGroup: res.UserGroupId
              ? {
                  Id: res.UserGroupId,
                  Name: res.UserGroup,
                }
              : null,
            Remarks: res.Remarks,
            FundingType:
              res.FundingTypeId !== null
                ? getSingleInvoiceFundingType(res.FundingTypeId)
                : null,
            PurchaseOrder: res.PurchaseOrder,
          });
          setinsertedAt(res.InsertedAt);
          setinsertedBy(res.InsertedBy);
          setBusy(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowId]);
  const close = useCallback(() => {
    if (props.isCover) {
      props.closeCover?.();
    } else {
      dispatch(actions.resetSidePanel());
    }
  }, [props, dispatch, actions]);
  const refreshTable = useCallback(() => {
    dispatch(actions.setRefreshTable(true));
  }, [dispatch, actions]);
  const submit = useCallback(
    (charge: ICharge) => {
      setBusy(true);
      if (!isEdit) {
        ChargeApi.AddCharge(charge)
          .then(() => {
            dispatch(
              notifyActions.addNotification({
                message: t(translations.msg_addedChargeSuccessfully),
                variant: 'success',
              }),
            );
          })
          .catch(e => console.error(e))
          .finally(() => {
            close();
            refreshTable();
          });
      } else {
        ChargeApi.UpdateCharge(charge, props.rowId!)
          .then(res => {
            if (res.ErrorMessages.length > 0) {
              dispatch(
                notifyActions.addNotification({
                  message: res.ErrorMessages[0],
                  variant: 'error',
                }),
              );
              setBusy(false);
              return;
            }
            dispatch(
              notifyActions.addNotification({
                message: t(translations.success_EditInvoiceLine),
                variant: 'success',
              }),
            );
            close();
            refreshTable();
          })
          .catch(e => console.error(e));
      }
    },
    [close, dispatch, isEdit, notifyActions, props.rowId, refreshTable, t],
  );
  const budgetOnly = useMemo(() => {
    return (
      settings.GetString(AllowedSettings.CreateInovicesBy) ===
      AllowCreateInvoicesBy[
        AllowCreateInvoicesBy.AlllowCreateInvoicesBy_BudgetsOnly
      ]
    );
  }, [settings]);
  const ChargeSchema = GetChargeSchema(t, isEdit, globalSettings);
  const ChargeForm = () => {
    return (
      <Formik
        validationSchema={ChargeSchema}
        initialValues={initialValues}
        onSubmit={submit}
      >
        {formik => {
          submitRef.current = formik.submitForm;
          const setAmount = (rate: number, quantity: number) => {
            formik
              .getFieldHelpers('Amount')
              .setValue(+(quantity * rate).toFixed(2), true);
          };
          const serviceGroupChanged = () => {
            formik.getFieldHelpers('Resource').setValue(null, true);
            formik.getFieldHelpers('OtherService').setValue(null, true);
            formik.getFieldHelpers('SampleType').setValue(null, true);
          };
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              {/* By using FormListener, the fields become not editable */}
              {/* <FormListener fields={[]} onFormChange={() => {}} /> */}
              <FormLeftSection>
                <FormFieldsSection>
                  {/* ServiceGroup */}
                  <FormRow fullRow>
                    <FormServiceGroupPicker
                      name="ServiceGroup"
                      fullWidth
                      placeholder={t(translations.ServiceGroup)}
                      label={t(translations.ServiceGroup)}
                      onChange={serviceGroupChanged}
                      disabled={
                        busy ||
                        initialValues.ServiceGroup !== null ||
                        (isEdit && !initialValues.Manual)
                      }
                      urlType="admin"
                      predicates={serviceGroupPredicate}
                      autoFocus
                    />
                  </FormRow>
                  {/* FeeType */}
                  <FormRow fullRow>
                    <FormCatRateTypePicker
                      variant="filled"
                      name="FeeType"
                      bySystemSetting
                      fullWidth
                      manual={onlyManual}
                      label={t(translations.FeeType)}
                      placeholder={t(translations.FeeType)}
                      disabled={isEdit && !initialValues.Manual}
                    />
                  </FormRow>
                  {/* Date */}
                  <FormRow fullRow>
                    <FormBookitDatePicker
                      name="SampleDate"
                      fullWidth
                      id="chargeDate_id"
                      placeholder={t(translations.PleaseSelectADate)}
                      label={t(translations.Date)}
                      disabled={isEdit && !initialValues.Manual}
                      onChange={value => {
                        formik.setFieldValue('Date', value);
                      }}
                    />
                  </FormRow>
                  {/* UserGroup */}
                  <FormRow fullRow>
                    <FormServiceUserGroupPicker
                      fullWidth
                      label={t(translations.UserGroup)}
                      placeholder={t(translations.UserGroup)}
                      disabled={
                        isEdit && !initialValues.Manual
                        // || !userGroupByBudeget
                      }
                      name="UserGroup"
                      predicates={
                        [
                          formik.values.User
                            ? new Condition(
                                'Users',
                                ODataOperators.In,
                                formik.values.User.Id,
                              )
                            : undefined,
                          formik.values.Budget
                            ? new Condition(
                                'Id',
                                ODataOperators.Equals,
                                formik.values.Budget['UserGroupId'],
                              )
                            : undefined,
                        ].filter(v => v !== undefined) as Condition<any>[]
                      }
                    />
                  </FormRow>
                  {/* User */}
                  <FormRow fullRow>
                    <FormUserPicker
                      predicates={
                        [
                          // formik.values.Budget
                          //   ? new Condition(
                          //       'Budgets',
                          //       ODataOperators.In,
                          //       formik.values.Budget.Id,
                          //     )
                          //   : undefined,
                          formik.values.UserGroup
                            ? new Condition<IUserFilterDto>(
                                'UserGroups',
                                ODataOperators.Any,
                                formik.values.UserGroup.Id,
                                'Id',
                              )
                            : undefined,
                        ].filter(v => v !== undefined) as Condition<any>[]
                      }
                      name="User"
                      fullWidth
                      placeholder={t(translations.User)}
                      label={t(translations.User)}
                      disabled={isEdit && !initialValues.Manual}
                    />
                  </FormRow>
                  {/* FundingType */}
                  {fundingTypeVisible && (
                    <FormRow>
                      <FormFundingTypePicker
                        name="FundingType"
                        label={t(translations.FundingType)}
                        disabled={
                          formik.isSubmitting ||
                          (isEdit && !initialValues.Manual)
                        }
                        onChange={value => {
                          formik.setValues(
                            produce(values => {
                              // clear budget selection when funding type is changed to an incompatible value
                              // for example, if an external budget is selected, and funding type is changed to internal - budget should be reset
                              if (
                                (value as Entity<number>).Id !==
                                (values.Budget as IBudgetFilterDto)
                                  ?.FundingTypeId
                              ) {
                                values.Budget = null;
                              }
                              values.FundingType = value;
                            }),
                          );
                        }}
                        fullWidth
                      />
                    </FormRow>
                  )}
                  {/* Budget */}
                  {IsModuleEnabled(appSettings, KnownModules.Budgets) && (
                    <FormRow fullRow>
                      <FormBudgetPicker
                        onChange={val => {
                          if (!userGroupByBudeget && val && val.UserGroupId) {
                            formik.getFieldHelpers('UserGroup').setValue(
                              {
                                Id: val.UserGroupId,
                                Name: val.UserGroup,
                              },
                              true,
                            );
                          }
                        }}
                        name="Budget"
                        fullWidth
                        placeholder={t(translations.Budget)}
                        label={t(translations.Budget)}
                        predicates={restrictedBudgetFilter({
                          budgetLimitedByUsers:
                            globalSettings.budgetLimitedByUsers,
                          isBudgetsWithoutUserGroup:
                            globalSettings.isBudgetsWithoutUserGroup,
                          userGroupId:
                            globalSettings.reservationUserGroupGroupByBudgetUserGroup
                              ? undefined
                              : formik.values.UserGroup?.Id,
                          serviceGroupId: formik.values.ServiceGroup?.Id,
                          services: formik.values.Resource
                            ? [
                                {
                                  Id: formik.values.Resource.Id,
                                  Name: formik.values.Resource.Name,
                                  ServiceTypeId: 1,
                                  ServiceGroupId:
                                    formik.values.ServiceGroup?.Id ?? null,
                                } as IServiceTypeFilterDto,
                              ]
                            : formik.values.OtherService
                            ? [
                                {
                                  Id: formik.values.OtherService.Id,
                                  Name: formik.values.OtherService.Name,
                                  ServiceTypeId: 2,
                                  ServiceGroupId:
                                    formik.values.ServiceGroup?.Id ?? null,
                                } as IServiceTypeFilterDto,
                              ]
                            : formik.values.SampleType
                            ? [
                                {
                                  Id: formik.values.SampleType.Id,
                                  Name: formik.values.SampleType.Name,
                                  ServiceTypeId: 3,
                                  ServiceGroupId:
                                    formik.values.ServiceGroup?.Id ?? null,
                                } as IServiceTypeFilterDto,
                              ]
                            : undefined,
                          startTime: !!formik.values.Date
                            ? dateUtils.dateOrStringToDate(formik.values.Date)
                            : undefined,
                          endTime: !!formik.values.Date
                            ? dateUtils.dateOrStringToDate(formik.values.Date)
                            : undefined,
                          user: formik.values.User?.Id ?? authenticatedUser?.Id,
                          defaultBudgetEnabled:
                            globalSettings.defaultBudgetEnabled,
                          userDefaultBudgetEnabled:
                            globalSettings.userDefaultBudgetEnabled,
                          hideNonDefaultBudgets:
                            globalSettings.hideNonDefaultBudgets,
                          // funding type is set to Internal by default even if it's not in use
                          fundingType: fundingTypeVisible
                            ? formik.values.FundingType
                            : null,
                        })}
                        disabled={
                          (initialValues.Budget !== null && budgetOnly) ||
                          (isEdit && !initialValues.Manual)
                        }
                      />
                    </FormRow>
                  )}
                  {/* PurchaseOrder */}
                  {fundingTypeVisible &&
                    ((formik.values.Budget as IBudgetFilterDto)
                      ?.PurchaseOrderOptionId ??
                      PurchaseOrderOptions.Hidden) !==
                      PurchaseOrderOptions.Hidden && (
                      <FormRow>
                        <FormTextField
                          name="PurchaseOrder"
                          label={t(translations.PurchaseOrder)}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !initialValues.Manual)
                          }
                          fullWidth
                        />
                      </FormRow>
                    )}

                  {/* Quantity */}
                  {onlyManual && (
                    <>
                      <FormRow fullRow>
                        <FormNumberField
                          placeholder={t(translations.Quantity)}
                          label={t(translations.Quantity)}
                          name="Quantity"
                          id="quantity_Id"
                          fullWidth
                          onChange={v =>
                            setAmount(
                              +formik
                                .getFieldMeta<number>('Rate')
                                .value.toFixed(2),
                              +v.target.value,
                            )
                          }
                        />
                      </FormRow>
                      {/* Rate */}
                      <FormRow fullRow>
                        <FormNumberField
                          placeholder={t(translations.BaseRate)}
                          label={t(translations.BaseRate)}
                          name="Rate"
                          id="rate_Id"
                          fullWidth
                          onChange={v =>
                            setAmount(
                              +v.target.value,
                              +formik
                                .getFieldMeta<number>('Quantity')
                                .value.toFixed(2),
                            )
                          }
                        />
                      </FormRow>
                    </>
                  )}
                  {/* Amount */}
                  <FormRow fullRow>
                    <FormNumberField
                      placeholder={t(translations.Amount)}
                      label={t(translations.Amount)}
                      name="Amount"
                      id="amount_Id"
                      fullWidth
                      onChange={v => {
                        formik.getFieldHelpers('Quantity').setValue(1, false);
                        formik
                          .getFieldHelpers('Rate')
                          .setValue(+v.target.value, true);
                      }}
                      disabled={isEdit && !initialValues.Manual}
                    />
                  </FormRow>

                  {/* Resource */}
                  <FormRow fullRow>
                    <FormOnlineServicesSinglePicker
                      name="Resource"
                      fullWidth
                      placeholder={t(translations.Equipment)}
                      label={t(translations.Equipment)}
                      disabled={isEdit && !initialValues.Manual}
                      predicates={ServiceGroupFilter(
                        formik.values.ServiceGroup?.Id,
                      )}
                    />
                  </FormRow>
                  {/* OtherService */}
                  <FormRow fullRow>
                    <FormOfflineServicePicker
                      serviceGroupId={formik.values.ServiceGroup?.Id}
                      name="OtherService"
                      fullWidth
                      placeholder={t(translations.OfflineServiceType)}
                      label={t(translations.OfflineServiceType)}
                      disabled={isEdit && !initialValues.Manual}
                      variant="filled"
                    />
                  </FormRow>
                  {/* SampleType */}
                  <FormRow fullRow>
                    <FormSampleTypePicker
                      serviceGroupId={formik.values.ServiceGroup?.Id}
                      name="SampleType"
                      fullWidth
                      placeholder={t(translations.SampleType)}
                      label={t(translations.SampleType)}
                      disabled={isEdit && !initialValues.Manual}
                      variant="filled"
                    />
                  </FormRow>

                  {/* Remarks */}
                  <FormRow>
                    <FormRichTextField
                      id="RemarksID"
                      fullWidth
                      name="Remarks"
                      label={t(translations.Remarks)}
                      disabled={formik.isSubmitting}
                    />
                  </FormRow>
                </FormFieldsSection>
                {insertedAt && (
                  <FormRow fullRow>
                    <InputLabel size="xs">
                      {t(translations.InsertedAt)}
                    </InputLabel>
                    {dateUtils.shortDateTimeFormat(insertedAt)}
                  </FormRow>
                )}
                {insertedBy && (
                  <FormRow fullRow>
                    <InputLabel size="xs">
                      {t(translations.InsertedBy)}
                    </InputLabel>
                    {insertedBy}
                  </FormRow>
                )}
              </FormLeftSection>
            </StyledForm>
          );
        }}
      </Formik>
    );
  };
  const leftActions = [
    () => (
      <>
        <Button
          onClick={() => submitRef.current?.()}
          size="small"
          disabled={busy}
        >
          {t(translations.Submit)}
        </Button>
      </>
    ),
  ];
  const rightActions = [
    () => (
      <>
        <Button
          variant="ghost"
          startIcon={<Icon icon="times" />}
          onClick={close}
          size="small"
          disabled={busy}
        >
          {t(translations.Cancel)}
        </Button>
      </>
    ),
  ];
  return (
    <PageWrapper
      id="add_charge_page"
      loading={busy}
      titlePage={
        isEdit
          ? `${t(translations.Charge)} #${props.rowId}`
          : t(translations.AddManualInvoiceLine)
      }
      closable={true}
      useSidePanel={true}
      children={<ChargeForm />}
      leftActions={leftActions}
      rightActions={rightActions}
      disableExpandToggle={true}
      closeSidePanel={() => {
        if (hasChanges) {
          dispatch(actions.setConfirmOpen(true));
        } else {
          dispatch(actions.resetSidePanel());
        }
      }}
      isCover={props.isCover}
      closeCover={props.closeCover}
    />
  );
};
