import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLocation } from 'react-router-dom';
import {
  GetNumberOrUndefined,
  GetRawValue,
  GetStringOrUndefined,
} from 'app/components/BasicTable/types/FilterParam';

import { DetectIsMobile } from 'utils/mobileDetect';
import { PageTopActionsProps } from '../type';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import React from 'react';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { Entity } from 'types/common';
import { SignUpWaitingListDetailsProps } from 'app/pages/ReservationWaitingListPage/SignUpWaitingListDetails';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { initOnlineServiceData } from 'app/components/pickers/AutocompletePickers/OnlineServicePicker';

export function SignUpToInstrument({
  size,
  text,
  variant,
  icon,
  isIconButton,
  useSidePanel,
  isExpanded,
  compact = true,
  equipmentId,
  userName,
  openPanelWithCover,
  ...props
}: PageTopActionsProps & { equipmentId?: number; userName?: string }) {
  const location = useLocation();
  const { openPanel } = useSidePanelState();
  const isMobile = DetectIsMobile();
  const { t } = useTranslation();

  const serviceIdRawValue = GetNumberOrUndefined(
    GetRawValue(new URLSearchParams(location.search), 'eqid'),
  );
  const userNameRawValue = GetStringOrUndefined(
    GetRawValue(new URLSearchParams(location.search), 'userName'),
  );
  const serviceId = React.useMemo(() => {
    return equipmentId ?? serviceIdRawValue;
  }, [equipmentId, serviceIdRawValue]);
  const [equipmentInitialized, setEquipmentInitialized] = React.useState<
    boolean
  >(false);
  const [userInitialized, setUserInitialized] = React.useState<boolean>(false);
  const userId = React.useMemo(() => {
    return userName ?? userNameRawValue;
  }, [userName, userNameRawValue]);

  const selectedEquipment: Entity<number> | undefined = useAsyncGetWthEffect(
    async () =>
      initOnlineServiceData(
        serviceId === undefined || serviceId === null
          ? undefined
          : serviceId.toString(),
      ).then(res => (!!res && !!res.length ? res[0] : undefined)),
    undefined,
    [serviceId],
    setEquipmentInitialized,
  );
  const selectedUser: Entity<string> | undefined = useAsyncGetWthEffect(
    async () =>
      initUserData(
        userId === undefined || userId === null ? '' : userId,
      ).then(res => (!!res && !!res.length ? res[0] : undefined)),
    undefined,
    [userId],
    setUserInitialized,
  );
  return (
    <TopActionButton
      text={
        text || (t(translations.UserNotificationsSignUpForInstrument) as string)
      }
      title={t(translations.ReservationNewWaitingListSignUp) as string}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      variant={
        isMobile && compact && !isIconButton ? 'ghost' : variant || 'ghost'
      }
      size={size || 'small'}
      noChangeOnMobile={!compact}
      isIconButton={isIconButton}
      disabled={equipmentInitialized || userInitialized}
      processing={equipmentInitialized || userInitialized}
      onClick={() => {
        let selParams: SignUpWaitingListDetailsProps = {
          useSidePanel: !isMobile && !useSidePanel,
          Equipment: selectedEquipment,
          SignUpUser: selectedUser,
        };
        const panelProps: SidePanelOpenState = {
          renderPageType: RenderPageType.SignUpWaitingListDetails,
          renderPageProps: selParams,
          expanded: false,
          useSidePanel: useSidePanel,
          isCover: useSidePanel,
        };

        !!openPanelWithCover
          ? openPanelWithCover(panelProps)
          : openPanel(panelProps);
      }}
      {...props}
    />
  );
}
