import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { useOfflineServiceStateSlice } from '../Details/slice';
import {
  selectStockRenewalCompleted,
  selectStockRenewalData,
  selectStockRenewalHasErrors,
  selectStockRenewalProcessing,
} from '../Details/slice/selectors';
import {
  RenewStockState,
  StockRenewalParams,
  StockRenewalState,
} from '../Details/slice/types';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { Progress } from 'app/components/LoadingIndicator';
import { buildURL, toQueryString } from 'utils/url-utils';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Roles, ScanTarget } from 'app/slice/types';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import { StockRenewalForm } from './StockRenewalForm';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface StockRenewalProps extends CoverProps, SidePanelContentProps {
  queryParams: StockRenewalParams;
  initialServices: IOtherServices[];
  onClose?: () => void;
}
export const StockRenewal = React.memo(function StockRenewal(
  props: StockRenewalProps,
) {
  const {
    useSidePanel,
    queryParams,
    initialServices,
    closeCover,
    isCover,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const processing = useSelector(selectStockRenewalProcessing);
  const actionCompleted = useSelector(selectStockRenewalCompleted);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const hasError = useSelector(selectStockRenewalHasErrors);
  const stockRenewalServicesData = useSelector(selectStockRenewalData);
  const publicUrl = useSelector(selectPublicUrl);
  //   const { checkIsMobile } = useIsMobile();
  //   const mobile = checkIsMobile();
  const globalSettings = useGlobalSettingsHook();
  //Local State
  const {
    cover,
    openPanel,
    closePanel,
    coverClosed,
    onCloseCover,
  } = useSidePanelState(
    () => {
      dispatch(actions.resetStockRenewalState());
      return true;
    },
    undefined,
    useSidePanel,
    isCover,
  );
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  // const [submitAllowed, setSubmitAllowed] = React.useState<boolean | undefined>(
  //   false,
  // );
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  //Refs
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<StockRenewalState>>(null);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);
  const assetsFilter = React.useMemo(() => {
    const userGroups = User?.AdminServiceGroups.map(f => f.Id);
    let filter: (string | Condition<IMyAssetsRow>)[] | undefined = [];
    filter.push(
      new Condition<IMyAssetsRow>(
        'TrackInventory',
        ODataOperators.Equals,
        true,
      ),
    );
    filter.push(
      new Condition<IMyAssetsRow>(
        'ServiceGroupId',
        ODataOperators.Includes,
        userGroups || [],
      ),
    );
    return filter.length > 0 ? filter : undefined;
  }, [User?.AdminServiceGroups]);
  //Methods

  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges,
        onClose: () => {
          dispatch(actions.resetStockRenewalState());
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/otherServices'));
    }
  }, [actions, closePanel, dispatch, hasChanges, isCover, useSidePanel]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: StockRenewalState) => {
      setBusy(true);
      dispatch(actions.createStockRenewals(values));
    },
    [actions, dispatch],
  );
  const onRenewStockClick = React.useCallback(
    (service: IOtherServices) => {
      let renewProps = {
        initialService: service,
        editCreatable: true,
        queryParams: {
          id: '' + service.Id ?? -1,
        },
      };
      openPanel({
        renderPageType: RenderPageType.RenewStock,
        renderPageProps: renewProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const onAddBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceType: number) => {
      let batchProps = {
        initialBatch: batch ?? undefined,
        saveCreatable: true,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceType,
        },
      };
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const onAddServiceClick = React.useCallback(
    (services: number[]) => {
      let selParams = {
        actionType: 'OfflineService',
        offTypeIds: services,
        customPredicates: assetsFilter,
      } as AssetsSelectionProps;
      openPanel({
        renderPageType: RenderPageType.AssetsSelection,
        renderPageProps: selParams,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [assetsFilter, openPanel, useSidePanel],
  );
  //effects
  useEffectOnMount(() => {
    dispatch(
      appSettingsActions.setBarcodeScannigTarget(ScanTarget.Consumables),
    );
    return () => {
      dispatch(appSettingsActions.setBarcodeScannigTarget(ScanTarget.All));
    };
  });
  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initStockRenewal({
          query: queryParams,
          services: initialServices,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    firstLoad,
    globalSettings,
    initialServices,
    queryParams,
  ]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          dispatch(layoutActions.setNotSavedChanges(false));
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    dispatch,
    handleCloselClick,
    hasError,
    layoutActions,
    processing,
  ]);

  // React.useEffect(() => {
  //   setSubmitAllowed(
  //     !!stockRenewalServicesData &&
  //       stockRenewalServicesData.Services.length > 0,
  //   );
  // }, [setSubmitAllowed, stockRenewalServicesData]);
  //memo
  const IsAdmin = React.useMemo(() => {
    return !!innerFormRef.current &&
      innerFormRef.current.values.Services.length > 0
      ? User?.IsAllGroupOrLabTechAdmin(
          innerFormRef.current.values.Services.map(g => g.ServiceGroupId || 0),
          innerFormRef.current.values.Services.map(f => {
            return {
              Id: f.ServiceTypeId,
              Name: f.ServiceTypeName,
              ServiceTypeId: ServiceType.Offline,
            } as IServiceTypeFilterDto;
          }),
        )
      : false;
  }, [User]);
  const rightActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={busy} // || !submitAllowed
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    actions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="times" />}
        onClick={handleCloselClick}
      >
        {t(translations.Close)}
      </Button>
    ));
    return actions;
  }, [busy, handleCloselClick, handleSaveClick, requestCompleted, t]);

  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.StockRenewals)}
        titlePage={t(translations.StockRenewals)}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={true}
        closeSidePanel={handleCloselClick}
        // leftTopActions={topButtons}
        //rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : rightActions}
        //rightActions={rightActions}
        children={
          stockRenewalServicesData !== undefined &&
          !processing &&
          globalSettings.loadCompleted ? (
            <StockRenewalForm
              initialValues={
                stockRenewalServicesData || ({} as RenewStockState)
              }
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              editCreatable={true}
              onRenewStockClick={onRenewStockClick}
              onAddBatchClick={onAddBatchClick}
              onAddServiceClick={onAddServiceClick}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Div100>
                <Progress inProgress={processing || false} size={80} />
              </Div100>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'stockRenewals', queryParams)}
        toPageLink={`stockRenewals?${toQueryString(queryParams)}`}
        isCover={isCover}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
