import * as React from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, FormLabel } from '@material-ui/core';
import { useIsRequired } from './ValidationSchemaProvider';
import { BasicRadioGroup, BasicRadioGroupProps } from '../BasicRadioGroup';
import { Entity } from 'types/common';
import { FormNumberField } from './FormNumberField';
import { FormRow } from './FormsLayout';
import clsx from 'clsx';
import BasicTypography from '../Typography/BasicTypography';

export type FormRadioGroupBaseProps = FieldHookConfig<string> & {
  helperText?: string;
  inputProps?: any;
  inputName?: string;
  inputId?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  useInputField?: boolean;
  inputAfterLabel?: React.ReactNode;
  onInputFocus?: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
} & BasicRadioGroupProps<Entity<string>>;
export const FormRadioGroupBase = ({
  id,
  label,
  onChange,
  value,
  name,
  error,
  title,
  options,
  helperText,
  inputProps,
  inputName,
  inputId,
  inputMode,
  useInputField,
  onInputFocus,
  inputAfterLabel,
  ...props
}: FormRadioGroupBaseProps) => {
  const [field, meta] = useField<string>({ name, ...props });
  const handleChange = (value, event) => {
    if (onChange !== undefined) {
      onChange(value, event);
    }
    field.onChange(event);
  };
  const { submitCount } = useFormikContext();
  const isRequired = useIsRequired(name);
  const hasError = meta?.error !== undefined;
  const showError = hasError && (meta.touched || submitCount > 0);
  return (
    <FormControl
      className={'radio-group-base'}
      required={isRequired}
      error={showError}
    >
      <FormLabel
        className={clsx('radio-group-base-label', { withTitle: !!title })}
      >
        {label}
      </FormLabel>
      {title && (
        <BasicTypography variant="bodyS" italic>
          {title}
        </BasicTypography>
      )}

      <BasicRadioGroup
        name={name}
        options={options}
        value={field.value}
        label={label}
        id={id}
        orientation={props.orientation ?? 'vertical'}
        shortLabel={props.shortLabel}
        onChange={handleChange}
        {...props}
      />
      {useInputField && (
        <FormRow>
          <FormNumberField
            id={inputId}
            name={inputName ?? ''}
            inputMode={inputMode}
            inputProps={inputProps}
            onFocus={onInputFocus}
            textBoxWidth={'large'}
            minInputWidth={'large'}
          />
          {!!inputAfterLabel && <span>{inputAfterLabel}</span>}
        </FormRow>
      )}
      <FormHelperText required={isRequired} error={showError}>
        <span>{showError ? meta.error : helperText}</span>
      </FormHelperText>
    </FormControl>
  );
};
