import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { DropDown } from 'app/components/DropDown';
import * as React from 'react';
import { savedViewsApi as api } from 'api/savedViewsApi';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useSavedViewSlice } from '../SavedViewPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Entity } from 'types/common';
import { Roles } from 'app/slice/types';
import { ListItem, MenuItem } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { SavedViewDetailsProps } from '../SavedViewPage/Details';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { useTranslation } from 'react-i18next';
import {
  selectUpdateSavedViewCompleted,
  selectUpdateSavedViewHasError,
} from '../SavedViewPage/slice/selectors';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';

export interface AddToListsProps<TRow> {
  rows: TRow[];
  getEquipments: (rows: TRow[]) => Entity<number>[];
  getAssets: (rows: TRow[]) => Promise<Entity<number>[]>;
  useSidePanel?: boolean;
}

export function AddToLists<TRow>({
  rows,
  getEquipments,
  getAssets,
  useSidePanel,
}: AddToListsProps<TRow>) {
  const { t } = useTranslation();
  const user = useSelector(selectAuthenticatedUser);
  const { openPanel } = useSidePanelState();
  const isAdmin = React.useMemo(() => {
    return (
      user !== undefined &&
      (user.Roles.indexOf(Roles.Administrators) >= 0 ||
        user.Roles.indexOf(Roles.GroupAdministrators) >= 0 ||
        user.Roles.indexOf(Roles.LabTech) >= 0)
    );
  }, [user]);
  const dispatch = useDispatch();
  const [myViews, setMyViews] = React.useState<ISavedViewDto[]>([]);
  const savedActions = useSavedViewSlice();
  const [, setLoadingCompleted] = React.useState<boolean>(false);
  const requestUpdateCompleted = useSelector(selectUpdateSavedViewCompleted);
  const hasUpdateError = useSelector(selectUpdateSavedViewHasError);
  const [input, setInnput] = React.useState<string>();
  const options = myViews.filter((item, index) =>
    item.Name.toLowerCase().includes((input || '').toLowerCase()),
  );

  useEffectOnMount(() => {
    api
      .getMySavedViews({
        user: user,
        serviceGroups: undefined,
        isAdmin: isAdmin,
        onlyMyView: true,
      })
      .then(response => {
        setMyViews(() => {
          return response || [];
        });
        setLoadingCompleted(true);
      });
    return () => {};
  });
  const openDetailsList = React.useCallback(
    (id?: number) => {
      let params = {
        id: id,
        useSidePanel: true,
        eid: getEquipments(rows)
          .map(f => f.Id)
          .join(','),
      } as SavedViewDetailsProps;
      openPanel({
        renderPageType: RenderPageType.SavedViewDetails,
        renderPageProps: params,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [getEquipments, openPanel, rows, useSidePanel],
  );
  const updateAssets = React.useCallback(
    (id: number) => {
      let view = myViews.filter(f => f.Id === id)[0];
      getAssets(rows).then(assets => {
        dispatch(
          savedActions.actions.updateViewAssets({
            assets: assets,
            view: view,
          }),
        );
      });
    },
    [dispatch, getAssets, myViews, rows, savedActions.actions],
  );
  React.useEffect(() => {
    let active = requestUpdateCompleted && !hasUpdateError;
    if (active) {
      dispatch(savedActions.actions.resetUpdateSavedViewState());
    }
    return () => {
      active = false;
    };
  }, [dispatch, hasUpdateError, requestUpdateCompleted, savedActions.actions]);
  return (
    <DropDown
      variant="white"
      size="small"
      menuChildren={onClose => [
        <ListItem key="lists-search">
          <TextControlField
            placeholder={t(translations.SearchFilter)}
            size="small"
            startIcon={
              <Icon icon="search" color="filters" colorExtend="light" />
            }
            value={input || ''}
            aria-label={t(translations.SearchFilter)}
            inputProps={{ 'aria-label': t(translations.SearchFilter) }}
            onChange={e => setInnput(e.target.value)}
            autoFocus
            fullWidth
          />
        </ListItem>,
        <ListItem key="create-lists" value="action">
          <Button
            variant="white"
            size="small"
            fullWidth={true}
            aria-label="create view"
            startIcon={<Icon icon="plus" />}
            onClick={() => {
              openDetailsList();
              onClose();
            }}
          >
            {t(translations.CreateList)}
          </Button>
        </ListItem>,
        ...options.map(s => (
          <MenuItem
            key={s.Id}
            onClick={e => {
              updateAssets(s.Id);
              onClose();
            }}
          >
            {s.Name}
          </MenuItem>
        )),
      ]}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      addChevron
    >
      {t(translations.AddToList)}
    </DropDown>
  );
}
