import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectExpandedSidePanel,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useWorkOrderSlice } from './slice';
import {
  selectCreateWorkOrder,
  selectCreateWorkOrderCompleted,
  selectCreateWorkOrderHasError,
  selectCreateWorkOrderProcessing,
  selectReadonlyWorkOrder,
  selectReservationOfflineCompleted,
  selectReservationOfflineData,
  selectReservationOfflineProcessing,
  selectSendEmailOnResOffline,
  selectUpdateWorkOrder,
  selectUpdateWorkOrderCompleted,
  selectUpdateWorkOrderHasError,
  selectUpdateWorkOrderProcessing,
  selectWorkOrderProcessing,
} from './slice/selectors';
import {
  ContinueToState,
  WorkOrderDetailsState,
  WorkOrderGlobalState,
  WorkOrderQueryStringParameters,
} from './slice/types';
import * as React from 'react';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { buildURL, toQueryString } from 'utils/url-utils';
import { push, routerActions } from 'connected-react-router';
import {
  DeleteOrderButton,
  ReopenOrderButton,
  SaveFormButton,
} from './Actions/WorkOrderActions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Button } from 'app/components/BasicButtons/Button';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { WorkOrderForm } from './WorkOrderForm';
import { Roles } from 'app/slice/types';
import {
  IAssetDto,
  mapAssetsToServiceTypeFilterDto,
} from 'api/odata/generated/entities/IAssetDto';
import { selectRepetitiveData } from 'app/components/Forms/FormRepetitive/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { Beforeunload } from 'react-beforeunload';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import { isNullOrUndefined } from 'utils/typeUtils';
import { Progress } from 'app/components/LoadingIndicator';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { IWorkOrderTypeStatusDto } from 'api/odata/generated/entities/IWorkOrderTypeStatusDto';
import { DetectIsMobile } from 'utils/mobileDetect';
import { WorkOrderApi as api } from 'api/WorkOrderApi';
import { dateUtils } from 'utils/date-utils';
import { ActionIconButton } from 'app/components/BasicButtons/ActionIconButton';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { RadioGroup } from 'app/components/RadioGroup';
import { ReservationQueryStringParameters } from 'app/pages/ReservationDetails/Details/slice/types';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import {
  SwitchActionsProps,
  SwitchTarget,
} from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/SwitchActions';
import { FormikProps } from 'formik';
import { CalendarButton } from 'app/components/CalendarButton';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import {
  FlexRowCenter,
  NotAllowedContent,
} from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { H3 } from 'app/components/Typography';
import { DropDown } from 'app/components/DropDown';
import { MenuItem } from '@material-ui/core';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { useOpenAssetPopUp } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
export type NotifyUsersRadioGroupOptionTypes =
  | 'No'
  | 'JustBookedUsers'
  | 'AllAffectedUsers';

export type CancelAffectedReservationsRadioGroupOptionTypes = 'No' | 'Yes';
export type ContinueWOAction = 'list' | 'edit';
export interface WorkOrderDetailsProps
  extends CoverProps,
    SidePanelContentProps {
  queryParams: WorkOrderQueryStringParameters;
  useSwithButtons?: boolean;
  continueTo?: ContinueToState;
  onClose?: () => void;
}
export function WorkOrderDetails(props: WorkOrderDetailsProps) {
  const {
    queryParams,
    useSidePanel,
    useSwithButtons,
    continueTo,
    closeCover,
    isCover,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const { actions } = useWorkOrderSlice();
  const { actions: globalActions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  /// Use Effect on Mount ///

  /// Selectors ///
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const loading = useSelector(selectWorkOrderProcessing);
  const hasChanges = useSelector(selectHasNotSavedChanges, shallowEqual);
  const repetitiveValues = useSelector(selectRepetitiveData);
  const readonlyDetails = useSelector(selectReadonlyWorkOrder);
  const User = useSelector(selectAuthenticatedUser);
  const sendOfflineEmail = useSelector(selectSendEmailOnResOffline);
  const sendOfflineProcessing = useSelector(selectReservationOfflineProcessing);
  const sendOfflineData = useSelector(selectReservationOfflineData);
  const sendEmailCompleted = useSelector(selectReservationOfflineCompleted);
  const createModel = useSelector(selectCreateWorkOrder, shallowEqual);
  const editModel = useSelector(selectUpdateWorkOrder, shallowEqual);
  const requestCreateCompleted = useSelector(selectCreateWorkOrderCompleted);
  const requestUpdateCompleted = useSelector(selectUpdateWorkOrderCompleted);
  const hasCreateProcessing = useSelector(selectCreateWorkOrderProcessing);
  const hasUpdateUpdateProcessing = useSelector(
    selectUpdateWorkOrderProcessing,
  );
  const hasCreateError = useSelector(selectCreateWorkOrderHasError);
  const hasUpdateError = useSelector(selectUpdateWorkOrderHasError);
  const publicUrl = useSelector(selectPublicUrl);
  const globalSettings = useGlobalSettingsHook();
  const { newDate } = useSystemDate();
  //cover
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        if (edit) {
          dispatch(actions.resetUpdateWorkOrderState());
        } else {
          dispatch(actions.resetCreateWorkOrderState());
        }
        dispatch(actions.resetReservationsOfflineState());
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );

  /// Local State ///
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [edit, setEdit] = React.useState<boolean | undefined>(false);
  const innerFormRef = React.useRef<FormikProps<WorkOrderDetailsState> | null>(
    null,
  );
  const [showConfirmRecurring, setShowConfirmRecurring] = React.useState<
    boolean | undefined
  >(undefined);
  const [showDeleteRecurring, setShowDeleteRecurring] = React.useState<
    boolean | undefined
  >(undefined);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const [showSendEmail, setShowSendEmail] = React.useState<boolean | undefined>(
    undefined,
  );
  const [showCloseStatus, setShowCloseStatus] = React.useState<
    boolean | undefined
  >(undefined);
  const [closedStatus, setClosedStatus] = React.useState<
    IWorkOrderTypeStatusDto | undefined
  >(undefined);
  const [radioNotifyUsersValue, setRadioNotifyUsersValue] = React.useState<
    NotifyUsersRadioGroupOptionTypes | undefined
  >('JustBookedUsers');

  const [
    radioCancelAffectedReservationsValue,
    setRadioCancelAffectedReservationsValue,
  ] = React.useState<
    CancelAffectedReservationsRadioGroupOptionTypes | undefined
  >('No');

  const [
    workOrderOfflineStartChangedValue,
    setWorkOrderOfflineStartChangedValue,
  ] = React.useState<Date | string | null | undefined>();

  const [workOrderOfflineEndChangedValue, setWorkOrderOfflineEndChangedValue] =
    React.useState<Date | string | null | undefined>();

  const [isCloseWorkOrderClicked, setIsCloseWorkOrderClicked] =
    React.useState<boolean>(false);

  /// Memo variables ///
  const hasProcessing = React.useMemo(() => {
    return hasCreateProcessing || hasUpdateUpdateProcessing;
  }, [hasCreateProcessing, hasUpdateUpdateProcessing]);

  const hasError = React.useMemo(() => {
    return hasCreateError || hasUpdateError;
  }, [hasCreateError, hasUpdateError]);

  const baseModel = React.useMemo(() => {
    return createModel || editModel || undefined;
  }, [createModel, editModel]);

  const showShortView = React.useMemo(() => {
    return isMobile || (useSidePanel && !sidePanelExpanded);
  }, [isMobile, useSidePanel, sidePanelExpanded]);

  const IsAdmin = React.useMemo(() => {
    return (
      User?.Roles?.includes(Roles.InstrumentEventsAdmin) ||
      User?.IsAllGroupOrLabTechAdmin(
        baseModel?.Assets.map(g => (g as IAssetDto).ServiceGroupId ?? 0),
        mapAssetsToServiceTypeFilterDto(
          (baseModel?.Assets === undefined
            ? []
            : baseModel?.Assets) as IAssetDto[],
        ),
      ) ||
      User?.IsAllInstrumentEventsGroupPermissions(
        baseModel?.Assets.map(g => (g as IAssetDto).ServiceGroupId ?? 0),
      )
    );
  }, [User, baseModel]);
  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);
  const viewPermissions = React.useMemo(() => {
    return (
      !edit ||
      (edit &&
        (IsAdmin ||
          (baseModel?.ReportedBy === (User?.Id || '') &&
            !(baseModel?.WorkOrderType as IWorkOrderTypeDto).StaffOnly)))
    );
  }, [
    IsAdmin,
    User?.Id,
    baseModel?.ReportedBy,
    baseModel?.WorkOrderType,
    edit,
  ]);
  const openAsset = useOpenAssetPopUp();
  const repeat = React.useMemo(() => {
    return (
      edit &&
      (!isNullOrUndefined<number>(baseModel?.RecurringAssetId) ||
        !isNullOrUndefined<number>(baseModel?.RecurringGroupId))
    );
  }, [edit, baseModel]);

  const [typeName, setTypeName] = React.useState<string | undefined>(
    baseModel?.WorkOrderType?.Name,
  );
  React.useEffect(() => {
    let active = firstLoad;

    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      if (
        queryParams.id !== undefined &&
        !isNaN(parseInt(queryParams.id || ''))
      ) {
        dispatch(actions.initUpdateWorkOrder(queryParams));
      } else {
        dispatch(actions.initWorkOrder(queryParams));
      }
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, globalSettings, queryParams]);
  React.useEffect(() => {
    setTypeName(
      innerFormRef.current?.values.WorkOrderType?.Name ??
        baseModel?.WorkOrderType?.Name,
    );
  }, [
    baseModel?.WorkOrderType?.Name,
    innerFormRef.current?.values.WorkOrderType?.Name,
  ]);

  const reservationSwithProps = React.useMemo(() => {
    if (!edit && baseModel) {
      let eqids = queryParams.eqid;
      let start = queryParams.eStart || queryParams.offStart;
      let end = queryParams.offEnd;
      let defaultMulti = globalSettings.multipleInstrumentsEnabled;
      if (!!baseModel) {
        start = dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(baseModel.EventStart ?? newDate()),
        );
        end =
          baseModel.OfflineEventEnd !== null &&
          baseModel.OfflineEventEnd !== undefined
            ? dateUtils.formatQueryStringDate(
                dateUtils.dateOrStringToDate(baseModel.OfflineEventEnd),
              )
            : undefined;
        defaultMulti =
          globalSettings.multipleInstrumentsEnabled &&
          baseModel.Assets.length > 0;
        eqids = (baseModel.Assets as IAssetDto[])
          .map(f => f.ServiceId)
          .filter(f => f !== undefined && f !== null)
          .join(',');
      }

      let p = {
        useSidePanel: true,
        queryParams: {
          selectedIds: eqids,
          Start: start,
          End: end,
          defaultMulti: defaultMulti.toString(),
          source: queryParams.source,
        } as ReservationQueryStringParameters,
        useSwitchButtons: useSwithButtons,
      } as ReservationDetailsProps;
      return p;
    }
    return undefined;
  }, [
    baseModel,
    edit,
    globalSettings.multipleInstrumentsEnabled,
    newDate,
    queryParams.eStart,
    queryParams.eqid,
    queryParams.offEnd,
    queryParams.offStart,
    queryParams.source,
    useSwithButtons,
  ]);
  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const repetitiveFormRef = React.useRef<any>(null);
  const workOrderTypeFormRef = React.useRef<any>(null);
  const workOrderStaffFormRef = React.useRef<any>(null);
  const submitCommentRef = React.useRef<any>(null);

  /// Handlers ///
  const handleSaveClick = React.useCallback(
    (e: any) => {
      if (repeat) {
        setShowConfirmRecurring(true);
      } else {
        handleSubmitForm(e);
      }
    },
    [repeat],
  );
  const handleSubmitForm = (e: any) => {
    if (workOrderTypeFormRef.current) {
      workOrderTypeFormRef.current(e);
    }
    if (workOrderStaffFormRef.current) {
      workOrderStaffFormRef.current(e);
    }
    if (repetitiveFormRef.current) {
      repetitiveFormRef.current(e);
    }
    // if (submitCommentRef.current) {
    //   submitCommentRef.current(e);
    // }
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (
      values: WorkOrderDetailsState,
      subFormFiles: IFormFileValue[],
      staffFormFiles: IFormFileValue[],
    ) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateWorkOrder({
            current: values,
            original: baseModel,
            originalSubFormFiles: subFormFiles,
            originalStaffFormFiles: staffFormFiles,
          }),
        );
      } else {
        let value = {
          workOrderDetails: values,
          RepetitiveOptions: repetitiveValues,
        } as WorkOrderGlobalState;
        dispatch(actions.createWorkOrder(value));
      }
    },
    [actions, baseModel, dispatch, edit, repetitiveValues],
  );

  const handleCancelClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges,
        onClose: () => {
          if (edit) {
            dispatch(actions.resetUpdateWorkOrderState());
          } else {
            dispatch(actions.resetCreateWorkOrderState());
          }
          dispatch(actions.resetReservationsOfflineState());
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/workorders'));
    }
  }, [actions, closePanel, dispatch, edit, hasChanges, isCover, useSidePanel]);
  const handleTypeChange = React.useCallback(
    (orderType: IWorkOrderTypeDto | undefined) => {},
    [],
  );
  //------ Confirm Repetitive Update/Delete handlers -------//
  const handleUpdateAllRecurrent = (e: any) => {
    dispatch(actions.setUpdateAllRecurrent(true));
    handleSubmitForm(e);
    setShowConfirmRecurring(false);
  };
  const handleUpdateJustOne = (e: any) => {
    dispatch(actions.setUpdateAllRecurrent(false));
    handleSubmitForm(e);
    setShowConfirmRecurring(false);
  };
  const handleDeleteOrder = React.useCallback(
    (action: boolean | null) => {
      var payload = {
        Id: baseModel?.Id || 0,
        all: action,
      };
      api
        .deleteOrders(payload)
        .then(response => {
          handleCancelClick();
          if (response.ErrorMessages.length > 0) {
            dispatch(
              globalActions.addNotification({
                key: 'deleteOrderError',
                message: t(
                  translations.OnlyMainOrResourceWorkOrdersAdminCanDeleteCalibrationRecords,
                ) as string,
                variant: 'error',
              }),
            );
          } else {
            dispatch(
              globalActions.addNotification({
                key: 'deleteOrder',
                message: 'Item deleted successfully',
                variant: 'success',
              }),
            );
          }
          dispatch(layoutActions.setRefreshTable(true));
        })
        .catch(error => {
          console.log('status error', error);
          let err = t(translations.Failed) as string;
          dispatch(
            globalActions.addNotification({
              variant: 'error',
              message:
                'Some thing went wrong. Item can not be deleted.: ' + !!err
                  ? err
                  : '',
              key: 'deleteOrder',
            }),
          );
        });
    },
    [baseModel, dispatch, globalActions, handleCancelClick, layoutActions, t],
  );
  //------ Send Emails on Reservation Downtime handlets ------//
  const handleSendEmailClick = React.useCallback(() => {
    if (sendOfflineData && sendOfflineData.length > 0) {
      dispatch(
        actions.sendReservationsOfflineEmail({
          cancel: radioCancelAffectedReservationsValue === 'Yes' ? true : false,
          deleted: false,
          insert: !edit,
          doNotNotifyUsers: radioNotifyUsersValue === 'No' ? true : false,
          allAffectedUsers:
            radioNotifyUsersValue === 'AllAffectedUsers' ? true : false,
          reservationOffline: sendOfflineData,
        }),
      );
    }
    setIsCloseWorkOrderClicked(false);
  }, [
    actions,
    dispatch,
    edit,
    radioCancelAffectedReservationsValue,
    radioNotifyUsersValue,
    sendOfflineData,
  ]);
  /*   const handleSendEmailWithCancel = React.useCallback(() => {
    if (sendOfflineData && sendOfflineData.length > 0) {
      dispatch(
        actions.sendReservationsOfflineEmail({
          cancel: true,
          deleted: false,
          insert: !edit,
          reservationOffline: sendOfflineData,
        }),
      );
    }
  }, [actions, dispatch, edit, sendOfflineData]); */
  const handleCancelSend = React.useCallback(() => {
    dispatch(actions.resetReservationsOfflineState());
    setIsCloseWorkOrderClicked(false);
  }, [actions, dispatch]);
  const handleCloseOrder = React.useCallback(
    (event: any, status: IWorkOrderTypeStatusDto) => {
      dispatch(
        actions.setAnyValue({
          fieldKey: 'WorkOrderTypeStatus',
          fieldValue: status,
        }),
      );
      //setChangedStatus(status);
      handleSaveClick(event);
      setIsCloseWorkOrderClicked(true);
    },
    [actions, dispatch, handleSaveClick],
  );
  const handleOpenOrder = React.useCallback(
    (event: any) => {
      api
        .getNewWorkOrderStatus(baseModel?.WorkOrderType?.Id || 0)
        .then(result => {
          let res = result as IWorkOrderTypeStatusDto[];
          if (res.length > 0) {
            dispatch(
              actions.setAnyValue({
                fieldKey: 'WorkOrderTypeStatus',
                fieldValue: res[0],
              }),
            );
            //setChangedStatus(res[0]);
            handleSaveClick(event);
          }
        });
    },
    [actions, baseModel?.WorkOrderType?.Id, dispatch, handleSaveClick],
  );
  const handleDeleteClick = React.useCallback(() => {
    if (repeat) {
      setShowDeleteRecurring(true);
    } else {
      handleDeleteOrder(null);
    }
  }, [handleDeleteOrder, repeat]);

  const handleDeleteAllNext = (e: any) => {
    handleDeleteOrder(false);
    setShowDeleteRecurring(false);
  };
  const handleDeleteAll = (e: any) => {
    handleDeleteOrder(true);
    setShowDeleteRecurring(false);
  };
  const handleNotifyUsersChange = event => {
    setRadioNotifyUsersValue(event);
    console.log(radioNotifyUsersValue);
  };

  const handleCancelAffectedReservationsChange = event => {
    setRadioCancelAffectedReservationsValue(event);
    console.log(radioCancelAffectedReservationsValue);
  };

  const isChangedDowntimeShorter = (): boolean => {
    let isChangedStartDowntimeShorter: boolean = true;
    let isChangedEndDowntimeShorter: boolean = true;
    if (
      !!workOrderOfflineStartChangedValue &&
      !!baseModel &&
      !!baseModel.OfflineEventStart
    ) {
      isChangedStartDowntimeShorter =
        dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(workOrderOfflineStartChangedValue ?? ''),
        ) <
        dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(baseModel?.OfflineEventStart ?? ''),
        );
    }
    if (
      !!workOrderOfflineEndChangedValue &&
      !!baseModel &&
      !!baseModel.OfflineEventEnd
    ) {
      isChangedEndDowntimeShorter =
        dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(workOrderOfflineEndChangedValue ?? ''),
        ) >
        dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(baseModel?.OfflineEventEnd ?? ''),
        );
    }
    //return workOrderOfflineStartChangedValue < OfflineEventStart || workOrderOfflineEndChangedValue > OfflineEventEnd
    return isChangedStartDowntimeShorter && isChangedEndDowntimeShorter;
  };

  /// Use Effects ///
  React.useEffect(() => {
    let active = loading === false;
    if (active) {
      if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        setEdit(true);
      } else {
        setEdit(false);
      }
      if (requestCreateCompleted === true || requestUpdateCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          if (sendOfflineEmail) {
            setShowSendEmail(true);
          } else {
            setShowSendEmail(false);
            handleCancelClick();
          }
        }
      } else if (
        requestCreateCompleted === false ||
        requestUpdateCompleted === false
      ) {
        setRequestCompleted(false);
        setBusy(false);
      }
      if (sendEmailCompleted) {
        handleCancelClick();
      }
    }
    return () => {
      active = false;
    };
  }, [
    baseModel,
    handleCancelClick,
    hasError,
    queryParams,
    loading,
    requestCompleted,
    requestCreateCompleted,
    requestUpdateCompleted,
    sendEmailCompleted,
    sendOfflineEmail,
  ]);
  // To do: implement confirmSendEmails when downtime pass on existing reservation

  const leftActions = React.useMemo(() => {
    let actions = [
      () => (
        <React.Fragment>
          <SaveFormButton
            size="small"
            startIcon={<Icon icon="save" />}
            onClick={handleSaveClick}
            disabled={busy || (baseModel?.NotEditable && baseModel?.IsClosed)}
            processing={!requestCompleted && busy}
            edit={edit || false}
            text={t(translations.Save)}
          />
        </React.Fragment>
      ),
    ] as ActionRenderer[];
    if (
      edit &&
      IsAdmin &&
      baseModel &&
      !baseModel.IsClosed &&
      baseModel.ClosedStatuses.length > 0
    ) {
      actions.push(() => (
        <React.Fragment>
          {baseModel.ClosedStatuses.length === 1 ? (
            <Button
              variant="white"
              size="small"
              disabled={busy}
              processing={!requestCompleted && busy}
              onClick={() => {
                setShowCloseStatus(true);
                setClosedStatus(
                  baseModel.ClosedStatuses[0] as IWorkOrderTypeStatusDto,
                );
              }}
            >
              {showShortView
                ? t(translations.CloseIncident)
                : t(translations.CloseEvent)}
            </Button>
          ) : (
            <DropDown
              variant="white"
              size="small"
              disabled={busy}
              processing={!requestCompleted && busy}
              menuChildren={() =>
                (baseModel.ClosedStatuses as IWorkOrderTypeStatusDto[]).map(
                  s => (
                    <div key={s.StatusId}>
                      <MenuItem
                        onClick={() => {
                          setShowCloseStatus(true);
                          setClosedStatus(s);
                        }}
                      >
                        {s.Name}
                      </MenuItem>
                    </div>
                  ),
                )
              }
              menuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              }}
              addChevron
            >
              {showShortView
                ? t(translations.CloseIncident)
                : t(translations.CloseEvent)}
            </DropDown>
          )}
        </React.Fragment>
        // <CloseWorkOrderButton

        //   handleClose={handleCloseOrder}

        //   closedStatuses={baseModel.ClosedStatuses as IWorkOrderTypeStatusDto[]}
        //   body={
        //     (baseModel.ShowOnCalibrationHistory
        //       ? t(translations.YouAreClosingCalibrationRecord) + ' '
        //       : '') +
        //     (baseModel.NotEditable
        //       ? t(translations.RecordWillBeNotEditableAfterClosing)
        //       : '')
        //   }
        // />
      ));
    }
    if (edit && IsAdmin && baseModel && baseModel.IsClosed) {
      actions.push(() => (
        <ReopenOrderButton
          size="small"
          variant="white"
          handleOpen={handleOpenOrder}
          text={
            showShortView
              ? t(translations.ReopenIncident)
              : t(translations.ReopenEvent)
          }
          disabled={(baseModel.IsClosed && baseModel.NotEditable) || busy}
          processing={!requestCompleted && busy}
        />
      ));
    }
    if (edit && IsAdmin && baseModel) {
      actions.push(() => (
        <DeleteOrderButton
          size="small"
          variant="ghost"
          handleDelete={handleDeleteClick}
          text={
            showShortView
              ? t(translations.DeleteIncident)
              : t(translations.DeleteEvent)
          }
          disabled={busy}
          processing={!requestCompleted && busy}
        />
      ));
    }
    return actions;
  }, [
    IsAdmin,
    baseModel,
    busy,
    edit,
    handleDeleteClick,
    handleOpenOrder,
    handleSaveClick,
    requestCompleted,
    showShortView,
    t,
  ]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton title={t(translations.Close)} onClick={handleCancelClick}>
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCancelClick, t]);

  const topActions = React.useMemo(() => {
    let actions = [] as ActionRenderer[];
    if (edit) {
      if (baseModel?.Downtime) {
        if (IsAdmin) {
          actions.push(() => (
            <Button
              variant="white"
              size="small"
              target="_blank"
              startIcon={<Icon icon="envelope" />}
              href={`/SendEmailToUsers?eid=${
                (baseModel.Assets[0] as IAssetDto).ServiceId || ''
              }&rf=${
                !isNullOrUndefined(baseModel.OfflineEventStart)
                  ? dateUtils.formatQueryStringDate(
                      dateUtils.dateOrStringToDate(baseModel.OfflineEventStart),
                    )
                  : ''
              }&rt=${
                !isNullOrUndefined(baseModel.OfflineEventEnd)
                  ? dateUtils.formatQueryStringDate(
                      dateUtils.dateOrStringToDate(baseModel.OfflineEventEnd),
                    )
                  : ''
              }`}
            >
              {showShortView
                ? t(translations.SendCustomEmail_short)
                : t(translations.SendCustomEmail)}
            </Button>
          ));
        }
        actions.push(() => (
          <CalendarButton
            size="small"
            startIcon={<Icon icon="calendar" />}
            variant="white"
            target={'_blank'}
            start={
              !isNullOrUndefined(baseModel.OfflineEventStart)
                ? dateUtils.formatQueryStringDate(
                    dateUtils.dateOrStringToDate(baseModel.OfflineEventStart),
                  )
                : ''
            }
            equipments={[
              (baseModel.Assets[0] as IAssetDto)?.ServiceId ?? undefined,
            ]}
          >
            {showShortView
              ? t(translations.ViewCalendar_short)
              : t(translations.ViewCalendar)}
          </CalendarButton>
        ));
      }
      if (!isNullOrUndefined(baseModel?.RecurringGroupId)) {
        actions.push(() => (
          <ActionIconButton
            variant="white"
            size="small"
            title={t(translations.ShowAllRecurringEvents)}
            shape="square"
            icon="th-list"
            target={'_blank'}
            href={`/workorders?rid=${baseModel?.RecurringGroupId}`}
          />
        ));
      }
    }
    return actions;
  }, [IsAdmin, baseModel, edit, showShortView, t]);

  const notifyUsersRadioGroupOptions = [
    {
      Id: 'No',
      Name: t(translations.No),
      Info: '',
    },
    {
      Id: 'JustBookedUsers',
      Name: t(translations.JustBookedUsers),
      Info: '',
    },
    {
      Id: 'AllAffectedUsers',
      Name: t(translations.AllAffectedUsers),
      Info: t(translations.AllAffectedUsers_info),
    },
  ];

  const cancelAffectedReservationsRadioGroupOptions = [
    {
      Id: 'No',
      Name: t(translations.No),
      Info: '',
    },
    {
      Id: 'Yes',
      Name: t(translations.Yes),
      Info: '',
    },
  ];

  return (
    <React.Fragment>
      <PageWrapper
        pageName={
          edit
            ? t(translations.menu_EditWorkOrder)
            : t(translations.menu_AddNewWorkOrder)
        }
        titlePage={
          baseModel
            ? edit
              ? ` ${typeName || ''} #${queryParams.id || 'N/A'}`
              : ` ${t(translations.Add)} ${typeName || ''}`
            : undefined
        }
        loading={loading}
        useSidePanel={useSidePanel}
        closable={useSidePanel || isCover}
        useSwitchActions={!edit && IsAdmin && useSwithButtons}
        switchState={
          !!reservationSwithProps && !edit && useSwithButtons
            ? ({
                target: SwitchTarget.Reservation,
                getProps: () => reservationSwithProps,
              } as SwitchActionsProps)
            : undefined
        }
        closeSidePanel={handleCancelClick}
        leftTopActions={viewPermissions ? topActions : []}
        leftActionsMaxLength={edit ? 2 : 1}
        pageLink={buildURL(publicUrl + 'workorders/details', props.queryParams)}
        toPageLink={`workorders/details?${toQueryString(props.queryParams)}`}
        leftActions={isReadOnlyUser || !viewPermissions ? [] : leftActions}
        rightActions={isReadOnlyUser || !viewPermissions ? [] : rightActions}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        isCover={isCover}
        coverClosed={coverClosed}
        children={
          baseModel !== undefined && !loading ? (
            <React.Fragment>
              {viewPermissions || isReadOnlyUser ? (
                <WorkOrderForm
                  isCover={isCover}
                  initialValues={baseModel || ({} as WorkOrderDetailsState)}
                  bindSubmitForm={bindSubmitForm}
                  onSubmit={handleSubmit}
                  isEdit={edit || false}
                  handleTypeChange={handleTypeChange}
                  repetitiveFormRef={repetitiveFormRef}
                  workOrderTypeFormRef={workOrderTypeFormRef}
                  workOrderStaffFormRef={workOrderStaffFormRef}
                  formRef={innerFormRef}
                  showShortView={showShortView}
                  isAdmin={IsAdmin || false}
                  bringUp={!!queryParams.Up}
                  pageLink={buildURL(
                    publicUrl + 'workorders/details',
                    props.queryParams,
                  )}
                  useSidePanel={useSidePanel}
                  openPanel={openPanel}
                  onAssetClicked={e =>
                    openAsset(
                      { id: e.Id, useSidePanel: useSidePanel },
                      openPanel,
                    )
                  }
                  onLinkedClick={linked =>
                    openPanel({
                      renderPageType: RenderPageType.WorkOrderDetails,
                      renderPageProps: {
                        queryParams: {
                          id: '' + linked.Id ?? -1,
                        },
                      },
                      expanded: false,
                      useSidePanel: useSidePanel,
                      isCover: useSidePanel,
                    } as SidePanelOpenState)
                  }
                  user={User}
                  canView={viewPermissions}
                  readonlyDetails={readonlyDetails}
                  commentsFormRef={submitCommentRef}
                  expanded={!showShortView}
                  setWorkOrderOfflineStartChangedValue={
                    setWorkOrderOfflineStartChangedValue
                  }
                  setWorkOrderOfflineEndChangedValue={
                    setWorkOrderOfflineEndChangedValue
                  }
                />
              ) : (
                // <Redirect to={'/notallowed'} />
                <FlexRowCenter>
                  <NotAllowedContent>
                    <H3>
                      {t(translations.WorkOrderRestrictedNotAllowed) as string}
                    </H3>
                    <Button
                      variant="text"
                      size="medium"
                      startIcon={<Icon icon="times" />}
                      onClick={() => {
                        if (!!continueTo) {
                          if (!!continueTo.pageProps && !!continueTo.pageType) {
                            dispatch(
                              layoutActions.openSidePanel({
                                type: continueTo.pageType,
                                props: continueTo.pageProps,
                              }),
                            );
                          } else if (!!continueTo.continueToLink) {
                            dispatch(
                              routerActions.push(continueTo.continueToLink),
                            );
                          } else {
                            handleCancelClick();
                          }
                        } else {
                          handleCancelClick();
                        }
                      }}
                    >
                      {t(translations.Continue)}
                    </Button>
                  </NotAllowedContent>
                </FlexRowCenter>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {' '}
              <Div100>
                <Progress inProgress={loading} size={80} />
              </Div100>
            </React.Fragment>
          )
        }
        confirm={
          requestCompleted && sendOfflineEmail ? (
            <DialogConfirm
              isOpen={showSendEmail || false}
              title={
                edit
                  ? t(translations.UpdateDowntimeAdditionalActions)
                  : t(translations.CreateDowntimeAdditionalActions)
              }
              processing={sendOfflineProcessing}
              body={
                <React.Fragment>
                  <BasicTypography>
                    {edit
                      ? t(translations.TheDowntimePeriodHasChangedNotifyUsers)
                      : t(translations.NotifyUsers)}
                  </BasicTypography>
                  <RadioGroup
                    id="notifyUsersRadioGroupID"
                    name="notifyUsersRadioGroup"
                    options={notifyUsersRadioGroupOptions}
                    value={radioNotifyUsersValue}
                    row={false}
                    onChange={handleNotifyUsersChange}
                  />
                  {isChangedDowntimeShorter() &&
                    isCloseWorkOrderClicked === false && (
                      <React.Fragment>
                        <BasicTypography>
                          {t(translations.CancelAffectedReservations)}
                        </BasicTypography>
                        <RadioGroup
                          id="cancelAffectedReservationsRadioGroupID"
                          name="cancelAffectedReservationsRadioGroup"
                          options={cancelAffectedReservationsRadioGroupOptions}
                          value={radioCancelAffectedReservationsValue}
                          row={false}
                          onChange={handleCancelAffectedReservationsChange}
                        />
                      </React.Fragment>
                    )}
                </React.Fragment>
              }
              confirmButtonLabel={
                edit
                  ? t(translations.UpdateDowntime)
                  : t(translations.CreateDowntime)
              }
              cancelButtonLabel={t(translations.Cancel)}
              onConfirm={handleSendEmailClick}
              onCancel={handleCancelSend}
              maxWidth="md"
              /* actions={[
                () => (
                  <ActionButton
                    text={t(translations.ConfirmSendDowntimeEmailsCancelOkBtn)}
                    variant="main"
                    processing={sendOfflineProcessing}
                    onClick={handleSendEmailWithCancel}
                  />
                ),
              ]} */
            />
          ) : showConfirmRecurring ? (
            <DialogConfirm
              isOpen={showConfirmRecurring}
              title={t(translations.UpdateRecurringEventsTitle)}
              processing={hasProcessing}
              body={t(translations.EventRepeatConfirmationMessage)}
              confirmButtonLabel={t(translations.JustThisOne)}
              onConfirm={handleUpdateJustOne}
              maxWidth="md"
              onCancel={() => {
                setShowConfirmRecurring(false);
              }}
              actions={[
                () => (
                  <ActionButton
                    text={t(translations.UpdateAllRecurringEvents)}
                    variant="main"
                    onClick={handleUpdateAllRecurrent}
                  />
                ),
              ]}
            />
          ) : showDeleteRecurring ? (
            <DialogConfirm
              isOpen={showDeleteRecurring}
              title={t(translations.DeleteRecurringEvents)}
              body={t(translations.EventRepeatConfirmationMessage)}
              confirmButtonLabel={t(translations.DeleteAllRecurringEvents)}
              onConfirm={handleDeleteAll}
              maxWidth="md"
              onCancel={() => {
                setShowConfirmRecurring(false);
              }}
              actions={[
                () => (
                  <ActionButton
                    text={t(translations.JustThisOne)}
                    variant="main"
                    onClick={() => handleDeleteOrder(null)}
                  />
                ),
                () => (
                  <ActionButton
                    text={t(translations.DeleteAllFutureRecurringEvents)}
                    variant="main"
                    onClick={handleDeleteAllNext}
                  />
                ),
              ]}
            />
          ) : showCloseStatus ? (
            <DialogConfirm
              isOpen={showCloseStatus}
              title={t(translations.CloseEvent)}
              processing={!requestCompleted && busy}
              body={
                (baseModel?.ShowOnCalibrationHistory
                  ? t(translations.YouAreClosingCalibrationRecord) + ' '
                  : '') +
                (baseModel?.NotEditable
                  ? t(translations.RecordWillBeNotEditableAfterClosing)
                  : '')
              }
              confirmButtonLabel={t(translations.Save)}
              cancelButtonLabel={t(translations.Cancel)}
              onConfirm={e => {
                if (!!closedStatus) {
                  handleCloseOrder(e, closedStatus);
                }
              }}
              onCancel={() => {
                setClosedStatus(undefined);
                setShowCloseStatus(false);
              }}
              onClose={() => {
                setClosedStatus(undefined);
                setShowCloseStatus(false);
              }}
              maxWidth="md"
            />
          ) : (
            <></>
          )
        }
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
}
