import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import UserRenderer from 'app/components/BasicTable/CellRenderers/UserRenderer';
import { BudgetFields } from 'enums/BudgetFields';
import { PriceSheetBy } from 'enums/enums';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import {
  AppSettings,
  IsBudgetFieldEnabled,
  IsModuleEnabled,
} from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { IInvoiceRow } from './IInvoicRow';
import { Link as MuiLink } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { InvoiceRowReferenceLinks } from 'app/components/InvoiceRowReferenceLinks';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';

const hasSubRows = row => {
  return !!row.original.Rows && row.original.Rows.length > 1;
};

export const getColumns = (
  t: TFunction,
  settings: GlobalSettings,
  appsettings: AppSettings | null,
  openEdit?: (id: number) => void,
  useSidePanel?: boolean,
) => {
  const currency = settings.GetString(AllowedSettings.ISOCurrencySymbol);
  const cols: (Column<IInvoiceRow> & { invisible?: boolean })[] = [
    {
      accessor: 'Id',
      Header: t(translations.HashSymbol) as string,
      Cell: ({ value }) => {
        return !openEdit ? (
          <>{value}</>
        ) : (
          <MuiLink onClick={() => openEdit(value)}>{value}</MuiLink>
        );
      },
    },
    {
      accessor: 'BudgetCat',
      Header: t(translations.BudgetCat) as string,
      invisible:
        !IsModuleEnabled(appsettings, KnownModules.BudgetCats) &&
        settings.TryGet('PriceSheetBy') !== null &&
        parseInt(
          settings.TryGet('PriceSheetBy') ??
            PriceSheetBy.InstrumentsAndServices.toString(),
        ) === PriceSheetBy.InstrumentsAndServices,
    },
    {
      accessor: 'Budget',
      Header: t(translations.Budget) as string,
      invisible: !IsModuleEnabled(appsettings, KnownModules.Budgets),
    },
    {
      Header: t(translations.PurchaseOrder) as string,
      accessor: 'PurchaseOrder',
    },
    {
      accessor: 'Task',
      Header: t(translations.Task) as string,
      invisible: !IsModuleEnabled(appsettings, KnownModules.BudgetExperiments),
    },
    {
      accessor: 'Experiment',
      Header: t(translations.Experiment) as string,
      invisible: !IsModuleEnabled(appsettings, KnownModules.BudgetExperiments),
    },
    {
      accessor: 'User',
      Header: t(translations.User) as string,
      Cell: UserRenderer,
    },
    {
      accessor: 'InstrumentCat',
      Header: t(translations.InstrumentCat) as string,
      invisible:
        parseInt(
          settings.TryGet('PriceSheetBy') ??
            PriceSheetBy.InstrumentsAndServices.toString(),
        ) === PriceSheetBy.InstrumentsAndServices,
    },
    {
      accessor: 'EquipmentService',
      Header: t(translations.EquipmentService) as string,
    },
    /*     {
      accessor: 'OnlineService',
      Header: t(translations.EquipmentService) as string,
      Cell: ({ row }) => {
        const vals = [
          row.original.OnlineService,
          row.original.SampleService,
          row.original.OfflineServiceType,
          row.original.AccService,
          row.original.SubscriptionName,
        ];
        if (
          !!row.original.ServiceRequestMilestoneChargeId &&
          !row.original.OfflineServiceType
        ) {
          vals.push(t(translations.Charges) as string);
        }
        return vals.filter(v => !!v).join(' - ');
      },
    }, */
    {
      accessor: 'Date',
      Header: t(translations.Date) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'CatRateType',
      Header: t(translations.FeeType) as string,
      Cell: ({ row, value }) => {
        if (hasSubRows(row)) return <>{t(translations.Mixed)}</>;
        return (
          <>
            {value +
              (!!row.original.TimeSlotType
                ? '(' + row.original.TimeSlotType + ')'
                : '')}
          </>
        );
      },
    },
    {
      accessor: 'Quantity',
      Header: t(translations.Quantity) as string,
      Cell: ({ row, value }) => {
        if (hasSubRows(row)) return <>{t(translations.Mixed)}</>;
        return <>{value}</>;
      },
    },
    {
      accessor: 'Units',
      Header: t(translations.Units) as string,
      Cell: ({ row, value }) => {
        if (hasSubRows(row)) return <>{t(translations.Mixed)}</>;
        return <>{value}</>;
      },
    },
    {
      accessor: 'BaseRate',
      Header: t(translations.BaseRate) as string,
      Cell: ({ value, row }) => {
        if (hasSubRows(row)) return <>{t(translations.Mixed)}</>;
        return <>{currency + (value ?? 0).toFixed(2)}</>;
      },
    },
    {
      accessor: 'SubsProfPercentage',
      Header: t(translations.SubsProfPercentage) as string,
      Cell: ({ value, row }) => {
        if (hasSubRows(row)) {
          return t(translations.Mixed);
        } else if (value === null || value === undefined) {
          return t(translations.NA);
        } else {
          return value.toFixed(2) + '%';
        }
      },
      invisible: !IsBudgetFieldEnabled(appsettings, BudgetFields.SubsidyProfit),
    },
    {
      accessor: 'ActualRate',
      Header: t(translations.ActualRate) as string,
      Cell: ({ value, row }) => {
        if (hasSubRows(row)) return <>{t(translations.Mixed)}</>;
        return <>{currency + (value ?? 0).toFixed(2)}</>;
      },
      invisible: !IsBudgetFieldEnabled(appsettings, BudgetFields.SubsidyProfit),
    },
    {
      accessor: 'Amount',
      Header: t(translations.Amount) as string,
      Cell: ({ value }) => <>{currency + (value ?? 0).toFixed(2)}</>,
    },
    {
      accessor: 'InsertedAt',
      Header: (
        <Tooltip title={t(translations.RelevantToManualCharges)}>
          <span>{t(translations.InsertedAt)}</span>
        </Tooltip>
      ),
      Cell: DateRenderer,
    },
    {
      accessor: 'InsertedBy',
      Header: (
        <Tooltip title={t(translations.RelevantToManualCharges)}>
          <span>{t(translations.InsertedBy)}</span>
        </Tooltip>
      ),
      Cell: ({ value, row }) => {
        if (value && row.original.InsertedBy) {
          return (
            <>
              <UserProfileLink
                userName={row.original.InsertedBy}
                displayName={row.original.InsertedByDisplay ?? undefined}
              />
            </>
          );
        }
        return <>{value}</>;
      },
    },
    {
      accessor: 'ServiceGroupName',
      Header: (
        <Tooltip title={t(translations.RelevantToManualCharges)}>
          <span>{t(translations.ServiceGroup)}</span>
        </Tooltip>
      ),
    },
    {
      accessor: 'ReservationId',
      Header: t(translations.Reference) as string,
      // Cell:  InvoiceRowReferenceRenderer,
      Cell: ({ value, row }) => (
        <InvoiceRowReferenceLinks
          OfflineServiceId={row.original.OfflineServiceId ?? null}
          ReservationId={row.original.ReservationId ?? null}
          ServiceRequestId={row.original.ServiceRequestId ?? null}
          SubscriptionId={row.original.SubscriptionId ?? null}
          UsageId={row.original.UsageId ?? null}
          useSidePanel={useSidePanel ?? false}
        />
      ),
    },
    {
      Header: t(translations.Remarks) as string,
      accessor: 'Remarks',
      Cell: DescriptionCell({ width: 'medium' }),
    },
  ];
  return cols.filter(v => !v.invisible) as Column<IInvoiceRow>[];
};
