import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import {
  BookitDatePicker,
  BookitDatePickerProps,
} from 'app/components/BookitDatePicker';
import { IFieldProps } from '../FormRender/IFormFieldSetting';

/**
 * TODO:
 * A non-serializable value was detected in an action, in the path: `payload.ReceivedAt`. Value: 
Date Sun May 02 2021 14:58:00 GMT+0300 (Israel Daylight Time)
 
Take a look at the logic that dispatched this action:  
Object { type: "animals/insertItem", payload: {…} }
 
(See https://redux.js.org/faq/actions#why-should-type-be-a-string…t-least-serializable-why-should-my-action-types-be-constants) 
(To allow non-serializable values see: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data)
 * */

export const FormBookitDatePicker = ({
  label,
  disableFuture,
  disablePast,
  disableClearable,
  ...props
}: FieldHookConfig<Date | null> &
  Pick<
    BookitDatePickerProps,
    | 'endTime'
    | 'truncateTime'
    | 'minDate'
    | 'maxDate'
    | 'labelFunc'
    | 'clearable'
    | 'fullScreen'
  > &
  IFieldProps) => {
  const [field, meta, helpers] = useField<Date | null>(props);
  return (
    <BookitDatePicker
      id={props.id}
      name={props.name}
      disableFuture={disableFuture}
      disablePast={disablePast}
      label={label}
      inputVariant="filled"
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      fullWidth={true}
      helperText={meta.error}
      labelFunc={props.labelFunc}
      info={props.info}
      disabled={props.disabled}
      clearable={props.clearable}
      fullScreen={props.fullScreen}
      endTime={props.endTime}
      truncateTime={props.truncateTime}
      variant="date"
      autoFocus={props.autoFocus}
      minDate={props.minDate}
      maxDate={props.maxDate}
      disableClearable={disableClearable}
      required={props.required}
    />
  );
};
