import {
  OfflineServiceTypeUrl,
  loadOfflineServiceData,
} from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import {
  InventoryBatchParams,
  InventoryBatchResponse,
  OfflineServiceQueryStringParameters,
  OfflineServicesResponse,
  RenewStockParams,
  ServiceChangeStateParameters,
  StockRenewalParams,
} from 'app/pages/OtherServiceDetails/Details/slice/types';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
import { IInventoryBatchDto } from './odata/generated/entities/IInventoryBatchDto';
import { quoteODataValue } from './odata/ODataFilter';

export const OtherServicesApi = {
  initOfflineService: (payload: OfflineServiceQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OtherServices/InitService`;
    return httpClient.post(url, data);
  },
  insertOtherService: (otherService: IOtherServices) => {
    const url = '/api/odata/v4/OtherServices';
    return httpClient.post(url, otherService);
  },
  insertConsumableServices: (otherServices: IOtherServices[]) => {
    const url = '/api/odata/v4/OtherServices/CreateConsumables';
    const data = {
      params: otherServices,
    };
    return httpClient.post(url, data);
  },
  insertStockRenewalServices: (otherServices: IOtherServices[]) => {
    const url = '/api/odata/v4/OtherServices/CreateStockRenewals';
    const data = {
      params: otherServices,
    };
    return httpClient.post(url, data);
  },
  updateOtherService: (otherService: IOtherServices, rowId: number) => {
    const url = '/api/odata/v4/OtherServices';
    return httpClient.put<any, IResponseType>(
      url + '(' + rowId + ')',
      otherService,
    );
  },
  deleteService: (Id: number) => {
    const url = `/api/odata/v4/OtherServices/${Id}`;
    return httpClient.deleteCustom<OfflineServicesResponse>(url);
  },
  calculateCredit: (calculateCreditModel: IOtherServices) => {
    const url = '/api/odata/v4/OtherServices/CalculateCredit';
    const data = {
      params: calculateCreditModel,
    };
    return httpClient.post(url, data);
  },
  calculateTotalCredit: (calculateCreditModel: IOtherServices[]) => {
    const url = '/api/odata/v4/OtherServices/CalculateTotalCredit';
    const data = {
      params: calculateCreditModel,
    };
    return httpClient.post(url, data);
  },
  getServiceChangeState: (payload: ServiceChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OtherServices/GetServiceChangeState`;
    return httpClient.post(url, data);
  },
  getReservationOfflineServices: (reservationId: number, repeat?: boolean) => {
    const url =
      repeat === true
        ? `/api/odata/v4/OtherServices/ReservationOfflineServicesWithParts(key=${reservationId})`
        : `/api/odata/v4/OtherServices/ReservationOfflineServices(key=${reservationId})`;
    return httpClient.get(url);
  },
  getUsageOfflineServices: (usageId: number) => {
    const url = `/api/odata/v4/OtherServices/UsageOfflineServices(key=${usageId})`;
    return httpClient.get(url);
  },
  initConsumableServices: (payload: OfflineServiceQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OtherServices/InitConsumableServices`;
    return httpClient.post(url, data);
  },
  initStockRenewalServices: (payload: StockRenewalParams) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OtherServices/InitStockRenewalServices`;
    return httpClient.post(url, data);
  },
  initRenewStockService: (payload: RenewStockParams) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OtherServices/InitRenewStock`;
    return httpClient.post(url, data);
  },
  initInventoryBatch: (payload: InventoryBatchParams) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/InventoryBatches/InitInventoryBatch`;
    return httpClient.post(url, data);
  },
  insertInventoryBatch: (batch: IInventoryBatchDto) => {
    const url = '/api/odata/v4/InventoryBatches';
    return httpClient.post(url, batch);
  },
  updateInventoryBatch: (batch: IInventoryBatchDto, rowId: number) => {
    const url = '/api/odata/v4/InventoryBatches';
    return httpClient.put<any, IResponseType>(url + '(' + rowId + ')', batch);
  },
  deleteInventoryBatch: (batch: IInventoryBatchDto) => {
    const url = `/api/odata/v4/InventoryBatches/${batch.Id}`;
    return httpClient.deleteCustom<InventoryBatchResponse>(url);
  },
  getServiceType: (
    payload: number | null,
    urlType?: OfflineServiceTypeUrl,
  ): Promise<IOfflineServiceFilterDto | null> => {
    return new Promise(async (resolve, reject) => {
      if (payload === null || payload < 1) {
        resolve(null);
      } else {
        try {
          const data = await loadOfflineServiceData(
            [`Id eq ${quoteODataValue(payload)}`],
            'public',
            [],
            undefined,
            'InventoryBatches',
          )(null, undefined);
          resolve(data.value[0] || null);
        } catch (error) {
          resolve(null);
        }
      }
    });
  },
};
