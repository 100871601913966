import {
  call,
  put,
  select,
  debounce,
  takeLeading,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { appSettingsActions } from 'app/slice';
import { requestSamplesActions as actions } from '.';
import { httpClient } from 'api/HttpClient';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  InsertServiceRequestMilestoneChargeAction,
  ILoadServiceRequestMilestonesAction,
  IRequestMilestoneManualChargeModel,
  ISample,
  IServiceRequestPatchAction,
  IServiceRequestUpdateBudgetAction,
  IServiceRequestUpdateStatusAction,
  UpdateServiceRequestMilestoneChargeAction,
  ServiceRequestMilestoneInsertModel,
  UpdateServiceRequestRowValueAction,
  IServiceRequestTableRowModel,
  IRequestDetailsModel,
  IServiceRequestUpdatePurchaseOrderAction,
  IServiceRequestUpdateFundingTypeAction,
  IServiceRequestUpdateExternalInternalNumberAction,
  IServiceRequestUpdateGradientAction,
  IServiceRequestUpdateOverheadAction,
  ILoadServiceRequestRowsAction,
} from './types';
import { TableState } from 'react-table';
import { IRow } from 'app/components/BasicTable';
import { TableFilterBuilder } from 'app/components/BasicTable/TableFilterBuilder';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  selectRequestDetailsData,
  selectSamplesTableState,
  selectServiceRequestCustomFormColumns,
  selectServiceRequestCustomFormFields,
  selectServiceRequestId,
} from './selectors';
import { AxiosError, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { deleteSamples, IDeleteSamplesResult } from 'api/SamplesApi';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { Entity } from 'types/common';
import { takeLatestByKey } from 'utils/redux-saga';
import {
  selectAuthenticatedUser,
  selectGlobalSettingBool,
  selectKnownModule,
} from 'app/slice/selectors';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import { IConsumableServiceDto } from 'api/odata/generated/entities/IConsumableServiceDto';
import {
  ServiceRequestMilestoneStatuses,
  ServiceRequestMilestoneStatusesUnion,
} from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import {
  IOpenSidePanelPayload,
  layoutActions,
} from 'app/Layout/FrontendLayout/slice';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { AllowedSettings } from 'utils/globalSettings';
import { selectSidePanelState } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  RenderPageType,
  SidePanelState,
} from 'app/Layout/FrontendLayout/slice/type';
import { push } from 'connected-react-router';
import { GetServiceRequestDetailsPath } from './path';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import { getLogger } from 'utils/logLevel';
import { KnownModules } from 'types/KnownModules';
import { IServiceRequestRowStatusDto } from 'api/odata/generated/entities/IServiceRequestRowStatusDto';
import {
  prepareServiceRequestRowModel,
  serviceRequestModelToDto,
} from './prepareServiceRequestRowModel';
import { omit } from 'lodash';
import { getCustomFormInitialValues } from 'app/components/CustomForm/CustomFormUtils';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';

const log = getLogger('ServiceRequestDetails');

function* doInit(action: PayloadAction) {
  try {
    const statuses: IODataQueryResponse<IServiceRequestStatusDto> = yield call(
      httpClient.get,
      '/api/odata/v4/ServiceRequestStatusFilter',
    );
    yield put(actions.initServiceRequestStatuses_Success(statuses.value));
  } catch (error) {
    log.error(error);
  }
  yield* doLoadServiceRequestRowStatuses();
}
function* doLoadRequestDetails(action: PayloadAction<number>) {
  try {
    const SampleManagementTablesEnabled = yield select(state =>
      selectKnownModule(state, KnownModules.SampleManagementTables),
    );
    const requestDetails: IServiceRequestDto = yield call(
      httpClient.get,
      `api/odata/v4/ServiceRequests/${action.payload}`,
      {
        $expand: [
          'Status',
          'Service($expand=ServiceGroup,CustomForm)',
          'CreatedBy($select=Id,Name)',
          'CreatedFor($expand=UserGroup)',
          'UpdatedBy($select=Id,Name)',
          'SubmittedBy($select=Id,Name)',
          'UserGroup($select=Id,Name)',
          'Budget($select=Id,Name,FundingTypeId,PurchaseOrderOptionId)',
          'UnitType($select=Id,Name)',
          'Gradient($select=Id,Name,ServiceGroupId,EstimatedRuntimeMinutes)',
        ].join(','),
      },
    );
    yield put(
      actions.loadRequestDetails_Success(
        omit(requestDetails, 'Rows', 'FormValues'),
      ),
    );

    if (
      requestDetails.Service.CustomForm.FormFields.some(
        f => f.Active && f.IsColumn === false,
      )
    ) {
      yield put(actions.loadFormValues(action.payload));
    }

    if (
      requestDetails.Service.CustomForm.FormFields.some(
        f => f.Active && f.IsColumn === true,
      ) ||
      SampleManagementTablesEnabled
    ) {
      yield* doLoadServiceRequestRows({
        type: action.type,
        payload: {
          serviceRequestId: requestDetails.Id,
          state: {
            pageIndex: 0,
            pageSize: 10,
          },
        },
      });
    }

    // trigger loading the milestones if the request milestones are enabled both in the global settings and on the service settings
    const requestMilestones: boolean = yield select(state =>
      selectGlobalSettingBool(state, AllowedSettings.RequestMilestones),
    );
    if (requestDetails.Service.MilestonesAddonsType !== 'NA') {
      if (requestMilestones === true) {
        yield put(actions.loadMilestones({ Id: action.payload }));
      }
    }
    if (requestDetails.InvoiceId !== null) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'warning',
          message: i18next.t(translations.requestblockedbyinvoice),
        }),
      );
    }
  } catch (error) {
    log.error(error);
    yield put(
      actions.loadRequestDetails_Error({
        response: {
          status: (error as AxiosError)?.response?.status,
        },
      }),
    );
  }
}
function* doRefreshRequestDetails(action: PayloadAction<number>) {
  try {
    const requestDetails = yield call(
      httpClient.get,
      `api/odata/v4/ServiceRequests/${action.payload}`,
      {
        $expand: [
          'Status',
          'Service($expand=ServiceGroup,CustomForm)',
          'CreatedBy($select=Id,Name)',
          'CreatedFor($expand=UserGroup)',
          'UpdatedBy($select=Id,Name)',
          'SubmittedBy($select=Id,Name)',
          'UserGroup($select=Id,Name)',
          'Budget($select=Id,Name,FundingTypeId,PurchaseOrderOptionId)',
          'UnitType($select=Id,Name)',
          'Gradient($select=Id,Name,ServiceGroupId,EstimatedRuntimeMinutes)',
        ].join(','),
      },
    );
    yield put(actions.refreshRequestDetails_Success(requestDetails));
  } catch (error) {
    log.error(error);
  }
}
function* doLoadFormValues(action: PayloadAction<number>) {
  const url = `api/odata/v4/ServiceRequests(${action.payload})/FormValues`;
  const data: IODataQueryResponse<IFormValueDto> = yield call(
    httpClient.get,
    url,
  );

  const formFields = yield select(selectServiceRequestCustomFormFields);
  const valuesObject = getCustomFormInitialValues(formFields, data.value);
  if (valuesObject !== undefined) {
    yield put(actions.loadFormValues_Success(valuesObject));
  }
}
function* doSaveFormValue(
  action: PayloadAction<
    Pick<IFormValueDto, 'RequestId' | 'FormFieldId' | 'DisplayValue'> &
      Pick<IFormFieldDto, 'Type'> & { Value: any }
  >,
) {
  if (action.payload.RequestId === null) {
    log.warn('doSaveFormValue: requestId is empty');
  }
  try {
    if (action.payload.Type === 'File' && action.payload.Value?.length === 0) {
      action.payload.Value = null;
    }
    if (action.payload.Type === 'File' && action.payload.Value !== null) {
      const formData = new FormData();
      formData.append(
        'file',
        action.payload.Value[0].PostedFile,
        action.payload.Value[0].DisplayName,
      );
      yield call(
        httpClient.post,
        `/api/requests/${action.payload.RequestId}/formvalues/${action.payload.FormFieldId}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        },
      );
    } else {
      const url = `/api/odata/v4/ServiceRequests(${action.payload.RequestId})/FormValues`;
      const payload = action.payload;
      yield call(httpClient.post, url, omit(payload, 'Type'));
    }
  } catch (error) {
    log.error(error);
  }
}

function* doLoadSamples(
  action: PayloadAction<
    Partial<
      Partial<
        TableState<IRow> & {
          customFilters: IFilterSettings<IRow>[];
          searchColumns: string[];
        }
      >
    >
  >,
) {
  // todo: switch to odata v4: const url = `/api/odata/v4/ServiceRequests(${id})`;

  const serviceRequestId = yield select(selectServiceRequestId);
  //const url = '/api/odata/v4/samples';
  const url = `api/odata/v4/ServiceRequests(${serviceRequestId})/Samples`;

  const currentTableState = yield select(selectSamplesTableState);
  const filters = ['(not SourceRunIds/any())'];

  // new Condition<ISample>(
  //   'ServiceRequestId',
  //   ODataOperators.Equals,
  //   serviceRequestId,
  // ),
  const params = {
    $count: true,
    $top: currentTableState.pageSize,
    $skip:
      (currentTableState.pageIndex ?? 0) * (currentTableState.pageSize ?? 5),
    $orderby:
      currentTableState.sortBy
        ?.map(f => `${f.id} ${f.desc ?? false ? 'desc' : 'asc'}`)
        .join(',') || undefined,

    $filter: TableFilterBuilder(
      currentTableState.customFilters,
      currentTableState.globalFilter,
      undefined,
      currentTableState.searchColumns,
      filters,
    ),
  };
  try {
    const data = yield call(httpClient.get, url, params);
    yield put(actions.loadSamples_Success(data));
  } catch (error) {
    yield put(
      actions.loadSamples_Error(i18next.t(translations.AnErrorOccurred)),
    );
    const forbidden = (error as AxiosError)?.response?.status === 403;
    if (forbidden) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.Forbidden),
        }),
      );
    }
  }
}
// function* doLoadServiceRequestRows(
//   id: number,
//   lanesEnabled?: boolean,
//   visibleToAdminOnly?: boolean,
// ) {
//   const url = `api/odata/v4/ServiceRequests(${id})/Rows`;
//   const data: IODataQueryResponse<IServiceRequestRowDto> = yield call(
//     httpClient.get,
//     url,
//     //TODO: bring back server side paging?
//     //params,
//   );
//   const columns = yield select(selectServiceRequestCustomFormColumns);
//   const isAdmin = yield select(selectIsServiceRequestAdmin);
//   const rowsDisabled =
//     !lanesEnabled || (visibleToAdminOnly && !isAdmin?.isAdmin);
//   const model = prepareServiceRequestRowModel(
//     rowsDisabled ? [] : data.value,
//     columns,
//   );
//   yield put(actions.loadServiceRequestRows_Success(model));
// }
function* doLoadServiceRequestRows(action: ILoadServiceRequestRowsAction) {
  const url = `api/odata/v4/ServiceRequests(${action.payload.serviceRequestId})/Rows`;
  const data: IODataQueryResponse<IServiceRequestRowDto> = yield call(
    httpClient.get,
    url,
    //TODO: bring back server side paging?
    //params,
  );
  const columns = yield select(selectServiceRequestCustomFormColumns);
  const model = prepareServiceRequestRowModel(data.value, columns);
  yield put(actions.loadServiceRequestRows_Success(model ?? []));
}
function* doLoadMilestones(action: ILoadServiceRequestMilestonesAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/Milestones`;
  const params = {
    $expand: [
      'Assignees($select=Id,Name)',
      `ServiceRequestMilestoneCharges($expand=${[
        'Service($select=Id,Name,Rate;$expand=UnitType($select=Id,Name))',
        'UpdatedBy($select=Id,Name)',
        'UnitType($select=Id,Name)',
      ].join(',')})`,
    ].join(','),
  };
  const data: IODataQueryResponse<IServiceRequestMilestoneDto> = yield call(
    httpClient.get,
    url,
    params,
  );
  yield put(actions.loadMilestones_Success(data.value));
}
function* doCreateSample(action: PayloadAction<ISample>) {
  try {
    const url = `/api/odata/v4/samples`;
    const response = yield call(httpClient.post, url, action.payload);
    yield put(actions.createSample_Success(response));
  } catch (error) {
    log.error('doCreateSample', error);
    yield put(actions.createSample_Error());
    const forbidden = (error as AxiosError)?.response?.status === 403;
    if (forbidden) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.Forbidden),
        }),
      );
    }
  }
}
function* doUpdateServiceRequestSampleField(
  action: PayloadAction<Partial<ISample>>,
) {
  const url = `/api/odata/v4/samples(${action.payload.Id})`;
  const payload: Omit<Partial<ISample>, 'Id'> = action.payload;
  try {
    yield call(httpClient.patch, url, payload);
    yield put(actions.updateServiceRequestSampleField_Success());
  } catch (error) {
    log.error(error);
    yield put(actions.updateServiceRequestSampleField_Error());
    const forbidden = (error as AxiosError)?.response?.status === 403;
    if (forbidden) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.Forbidden),
        }),
      );
      return;
    }
    const msg = (error as AxiosError)?.response?.data?.error?.innererror
      ?.message;
    if (msg !== undefined) {
      yield put(
        appSettingsActions.addNotification({
          key: 'SamplesListPage.saga.updateField',
          variant: 'error',
          message: msg,
        }),
      );
    }
    yield put(actions.updateServiceRequestSampleField_Error());
  }
}

function* doUpdateServiceRequestRowValue(
  action: UpdateServiceRequestRowValueAction,
) {
  // TODO: migrate value updates to the new api
  // const url = `/api/odata/v4/ServiceRequests(${action.payload.serviceRequestId})/Rows(${action.payload.serviceRequestValue.RowId})/Values`;
  const url = `/api/requests/${action.payload.serviceRequestId}/tabledata/${action.payload.serviceRequestValue.RowId}/${action.payload.serviceRequestValue.ColumnId}`;
  const payload =
    action.payload.serviceRequestValue.DisplayValue === null
      ? action.payload.serviceRequestValue.Value
      : {
          id: action.payload.serviceRequestValue.Value,
          text: action.payload.serviceRequestValue.DisplayValue,
        };

  try {
    yield call(httpClient.post, url, JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' },
    });
    yield put(actions.updateServiceRequestRowValue_Success());
  } catch (error) {
    log.error(error);
    yield put(actions.updateServiceRequestRowValue_Error());
    const forbidden = (error as AxiosError)?.response?.status === 403;
    if (forbidden) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.Forbidden),
        }),
      );
      return;
    }
    const msg = (error as AxiosError)?.response?.data?.error?.innererror
      ?.message;
    if (msg !== undefined) {
      yield put(
        appSettingsActions.addNotification({
          key: 'SamplesListPage.saga.updateField',
          variant: 'error',
          message: msg,
        }),
      );
    }
    yield put(actions.updateServiceRequestSampleField_Error());
  }
}

function* doImport(action: PayloadAction<any>) {
  const file = action.payload.file;
  const serviceRequestId = action.payload.serviceRequestId;
  const url = `/api/SamplesImport/import?serviceRequestId=${serviceRequestId}`;
  try {
    const response = yield call(httpClient.uploadFile, url, file);
    const allRowsImportedSuccessfully = response.data?.size === 0;
    if (allRowsImportedSuccessfully) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'success',
          message: i18next.t(translations.AllRowsImportedSuccessfully),
        }),
      );
    } else {
      yield call(fileDownload, response.data, 'import-result.xlsx');
      yield put(
        appSettingsActions.addNotification({
          variant: 'warning',
          message: i18next.t(
            translations.OneOrMoreErrorOccurredDuringImportPleaseReviewOutputFile,
          ),
        }),
      );
    }
    yield put(actions.import_Success());
  } catch (error) {
    log.error(error);
    yield put(actions.import_Error());
    const forbidden = (error as AxiosError)?.response?.status === 403;
    if (forbidden) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.Forbidden),
        }),
      );
      return;
    }
  }
}

function* doDeleteSamples(action: PayloadAction<ISample[]>) {
  let r: IDeleteSamplesResult = yield call(deleteSamples, action.payload);

  for (const error of r.errors) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: error.message,
      }),
    );
  }
  if (r.successCount > 0) {
    // reload table data
    const tableState = yield select(selectSamplesTableState);
    yield put(actions.loadSamples(tableState));

    // post success notification of deleted records
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.XRecordsWereBeDeleted, {
          x: r.successCount,
        }),
      }),
    );
  }
}
function* doPatchRequestDetails(action: IServiceRequestPatchAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})`;
  const payload = action.payload;
  try {
    const response = yield call(httpClient.patch, url, payload);
    yield put(actions.patch_Success({ ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(actions.patch_Error(action.payload));
    const axiosError = error as AxiosError;
    const reason =
      axiosError?.response?.data?.error?.innererror?.message ??
      axiosError?.response?.data?.error?.message ??
      axiosError?.response?.data?.statusText ??
      i18next.t(translations.NA);
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: reason,
      }),
    );
  }
}
function* doUpdateStatus(action: IServiceRequestUpdateStatusAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/Status`;
  const payload = action.payload;
  try {
    const response = yield call(httpClient.post, url, {
      ...payload.Status,
      ...{ Reason: action.payload.Reason },
    });
    yield put(
      actions.updateStatus_Success({
        ...response,
        // old API returns Status in old format, override it here with IServiceRequestStatusDto
        Status: action.payload.Status,
      }),
    );
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.StatusUpdatedTo, action.payload.Status),
        variant: 'success',
      }),
    );
  } catch (error) {
    log.error(error);
    yield put(actions.updateStatus_Error(action.payload));
    const axiosError = error as AxiosError;
    const reason =
      axiosError?.response?.data?.error?.innererror?.message ??
      axiosError?.response?.data?.error?.message ??
      axiosError?.response?.data?.statusText ??
      i18next.t(translations.NA);
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: i18next.t(translations.ServiceRequestStatusUpdate_Error, {
          reason: reason,
        }),
      }),
    );
  }
}
function* doUpdateServiceRequestRowStatus(
  action: PayloadAction<{
    serviceRequestId: number;
    serviceRequestRowId: number;
    status: IServiceRequestRowStatusDto | null;
  }>,
) {
  try {
    yield call(
      httpClient.post,
      `api/requests/${action.payload.serviceRequestId}/tabledata/rows/${action.payload.serviceRequestRowId}/Status`,
      JSON.stringify(action.payload.status?.Id),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    yield put(actions.updateServiceRequestRowStatus_Success(action.payload));
  } catch (error) {
    log.error(error);
  }
}

function* doUpdateBudget(action: IServiceRequestUpdateBudgetAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/Budget`;
  const payload = action.payload;
  try {
    const response: IServiceRequestDto = yield call(
      httpClient.post,
      url,
      payload.Budget,
    );
    yield put(actions.updateBudget_Success(response));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.BudgetUpdatedTo, {
          Name: action.payload.Budget.Name,
        }),
        variant: 'success',
      }),
    );
    yield put(actions.refreshRequestDetails(action.payload.Id));
  } catch (error) {
    log.error(error);
    yield put(actions.updateBudget_Error(action.payload));
  }
}
function* doUpdateGradient(action: IServiceRequestUpdateGradientAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/Gradient`;
  const payload = action.payload;
  try {
    yield call(httpClient.post, url, payload.Gradient);
    yield put(actions.updateGradient_Success(action.payload));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.GradientUpdatedTo, {
          Name: action.payload.Gradient?.Name,
        }),
        variant: 'success',
      }),
    );
    //yield put(actions.refreshRequestDetails(action.payload.Id));
    console.debug(payload);
  } catch (error) {
    log.error(error);
    yield put(actions.updateGradient_Error(action.payload));
  }
}
function* doUpdateOverheadMinutes(action: IServiceRequestUpdateOverheadAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/OverheadMinutes`;
  const payload = action.payload;
  try {
    const response: IServiceRequestDto = yield call(
      httpClient.post,
      url,
      payload.OverheadMinutes,
    );
    yield put(actions.updateOverheadMinutes_Success(response));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.OverheadTimeUpdatedTo, {
          Name: action.payload.OverheadMinutes,
        }),
        variant: 'success',
      }),
    );
    yield put(actions.refreshRequestDetails(action.payload.Id));
    console.debug(payload);
  } catch (error) {
    log.error(error);
    yield put(actions.updateOverheadMinutes_Error(action.payload));
  }
}
function* doUpdatePurchaseOrder(
  action: IServiceRequestUpdatePurchaseOrderAction,
) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/PurchaseOrder`;
  const payload = action.payload;
  try {
    const response: IServiceRequestDto = yield call(
      httpClient.post,
      url,
      payload.PurchaseOrder,
    );
    yield put(actions.updatePurchaseOrder_Success(response));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.PurchaseOrderUpdatedTo, {
          Name: action.payload.PurchaseOrder,
        }),
        variant: 'success',
      }),
    );
    yield put(actions.refreshRequestDetails(action.payload.Id));
  } catch (error) {
    log.error(error);
    yield put(actions.updatePurchaseOrder_Error(action.payload));
  }
}

function* doUpdateExternalInternalNumber(
  action: IServiceRequestUpdateExternalInternalNumberAction,
) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/ExternalInternalNumber`;
  const payload = action.payload;
  try {
    const response: IServiceRequestDto = yield call(
      httpClient.post,
      url,
      payload.ExternalInternalNumber,
    );
    yield put(actions.updateExternalInternalNumber_Success(response));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.ExternalInternalNumberUpdatedTo, {
          Name: action.payload.ExternalInternalNumber,
        }),
        variant: 'success',
      }),
    );
    yield put(actions.refreshRequestDetails(action.payload.Id));
  } catch (error) {
    log.error(error);
    yield put(actions.updateExternalInternalNumber_Error(action.payload));
  }
}

function* doUpdateFundingType(action: IServiceRequestUpdateFundingTypeAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/FundingType`;
  const payload = action.payload;
  try {
    const response: IServiceRequestDto = yield call(
      httpClient.post,
      url,
      payload.FundingTypeId,
    );
    yield put(actions.updateFundingType_Success(response));
    if (action.payload.FundingTypeId !== null) {
      yield put(
        appSettingsActions.addNotification({
          message: i18next.t(translations.FundingTypeUpdatedTo, {
            Name: getSingleInvoiceFundingType(action.payload.FundingTypeId)
              .Name,
          }),
          variant: 'success',
        }),
      );
    }
    yield put(actions.refreshRequestDetails(action.payload.Id));
  } catch (error) {
    log.error(error);
    yield put(actions.updateFundingType_Error(action.payload));
  }
}
function* doSubmit(action: PayloadAction<IRequestDetailsModel>) {
  const url = `api/ServiceRequests/${action.payload.Id}/SubmitStep`;
  try {
    yield call(httpClient.post, url);
    yield put(actions.submitStep_success(action.payload));
    yield put(actions.refreshRequestDetails(action.payload.Id));
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.StepHasBeenSubmitted),
        variant: 'success',
      }),
    );
  } catch (error) {
    log.error(error);
    const errorMessages = getErrorMessages(error);
    yield* putErrorMessages(errorMessages);
    yield put(actions.submitStep_Error());
  }
}

function* putErrorMessages(errorMessages: Array<string> | undefined) {
  if (errorMessages !== undefined) {
    for (const message of errorMessages) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: message,
        }),
      );
    }
  }
}

function getErrorMessages(error: unknown): Array<string> | undefined {
  const axiosError = error as AxiosError;
  const isAxiosError = axiosError.isAxiosError;
  if (isAxiosError) {
    const dataErrorMessages = axiosError.response?.data?.ErrorMessages;
    if (
      Array.isArray(dataErrorMessages) &&
      dataErrorMessages.length > 0 &&
      typeof dataErrorMessages[0] === 'string'
    ) {
      return dataErrorMessages as Array<string>;
    }
  }
}

function* doCancelServiceRequest(action: IServiceRequestUpdateStatusAction) {
  const url = `/api/odata/v4/ServiceRequests(${action.payload.Id})/Status`;
  const payload = action.payload;
  try {
    yield call(httpClient.post, url, {
      ...payload.Status,
      ...{ Reason: action.payload.Reason },
    });
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.RequestCancelled),
      }),
    );
    yield put(actions.refreshRequestDetails(action.payload.Id));
  } catch (error) {
    log.error(error);
    //yield put(actions.updateStatus_Error(action.payload));
    const axiosError = error as AxiosError;
    const reason =
      axiosError?.response?.data?.error?.innererror?.message ??
      axiosError?.response?.data?.error?.message ??
      axiosError?.response?.data?.statusText ??
      i18next.t(translations.NA);
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: i18next.t(translations.ServiceRequestStatusUpdate_Error, {
          reason: reason,
        }),
      }),
    );
  }
}

function* doInsertMilestoneCharge(
  action: InsertServiceRequestMilestoneChargeAction,
) {
  const serviceRequest: IServiceRequestDto = yield select(
    selectRequestDetailsData,
  );
  if (serviceRequest === undefined) {
    return;
  }
  try {
    const url = `/api/requests/${action.payload.ServiceRequestId}/ServiceRequestMilestone/${action.payload.ServiceRequestMilestoneId}/ServiceRequestMilestoneCharge`;

    const result: IRequestMilestoneManualChargeModel = yield call(
      httpClient.post,
      url,
      action.payload,
    );

    const charge: IServiceRequestMilestoneChargeDto = {
      Date: result.Date,
      Id: result.Id,
      Amount:
        (result.Rate ?? action.payload.OfflineServiceType?.Rate ?? 0) *
        (result.Quantity ?? 0),
      InvoiceId: null,
      Name: result.Name,
      //OfflineServiceTypeId: result.OfflineServiceType?.Id,
      Quantity: result.Quantity,
      OfflineServiceTypeId: result.OfflineServiceType?.Id ?? null,
      Rate: result.Rate,
      Remarks: result.Remarks,
      ServiceRequestMilestoneId: action.payload.ServiceRequestMilestoneId,
      Service:
        (action.payload.OfflineServiceType as IConsumableServiceDto) ?? null,
      UnitTypeId: null,

      UpdatedBy:
        result.UpdatedBy === null
          ? null
          : {
              Id: result.UpdatedBy?.Id,
              Name: result.UpdatedBy?.Title,
            },
      UnitType:
        (result.Unit as Entity<number>) ??
        action.payload.OfflineServiceType?.UnitType ??
        null,
    };
    yield put(actions.insertMilestoneCharge_Success(charge));
    yield put(actions.closeSidePanel());
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.InsertSuccess),
      }),
    );
  } catch (error) {
    log.error(error);
    yield put(actions.insertMilestoneCharge_Error());
  }
}
function* doDeleteServiceRequestMilestoneCharge(
  action: PayloadAction<IServiceRequestMilestoneChargeDto>,
) {
  const serviceRequest: IServiceRequestDto = yield select(
    selectRequestDetailsData,
  );
  if (serviceRequest === undefined) {
    return;
  }
  try {
    const url = `api/requests/${serviceRequest.Id}/ServiceRequestMilestone/${action.payload.ServiceRequestMilestoneId}/ServiceRequestMilestoneCharge/${action.payload.Id}`;
    yield call(httpClient.delete, url);
    yield put(actions.deleteMilestoneCharge_Success(action.payload));
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.DeleteSuccess),
      }),
    );
  } catch (error) {
    log.error(error);
  }
}
function* doUpdateMilestoneCharge(
  action: UpdateServiceRequestMilestoneChargeAction,
) {
  const serviceRequest: IServiceRequestDto = yield select(
    selectRequestDetailsData,
  );
  if (serviceRequest === undefined) {
    return;
  }
  try {
    const url = `/api/requests/${action.payload.ServiceRequestId}/ServiceRequestMilestone/${action.payload.ServiceRequestMilestoneId}/ServiceRequestMilestoneCharge`;
    const authenticatedUser: IAuthenticatedUser = yield select(
      selectAuthenticatedUser,
    );

    const result: IRequestMilestoneManualChargeModel = yield call(
      httpClient.put,
      url,
      action.payload,
    );

    const charge: IServiceRequestMilestoneChargeDto = {
      Date: result.Date,
      Id: result.Id,
      Amount:
        (result.Rate ?? action.payload.OfflineServiceType?.Rate ?? 0) *
        (result.Quantity ?? 0),
      InvoiceId: null,
      Name: result.Name,
      //OfflineServiceTypeId: result.OfflineServiceType?.Id,
      Quantity: result.Quantity,
      OfflineServiceTypeId: result.OfflineServiceType?.Id ?? null,
      Rate: result.Rate,
      Remarks: result.Remarks,
      ServiceRequestMilestoneId: action.payload.ServiceRequestMilestoneId,
      Service:
        result.OfflineServiceType === null
          ? null
          : ({
              Id: result.OfflineServiceType.Id,
              Name: result.OfflineServiceType.Name,
              Rate: result.OfflineServiceType.Rate ?? 0,
            } as IConsumableServiceDto),
      UnitTypeId: action.payload.Unit?.Id ?? null,
      UpdatedBy:
        action.payload.UpdatedBy === null ||
        action.payload.UpdatedBy === undefined
          ? result.UpdatedBy
            ? {
                Id: result.UpdatedBy.Id,
                Name: result.UpdatedBy.Title,
              }
            : authenticatedUser
          : {
              Id: action.payload.UpdatedBy.Id,
              Name: action.payload.UpdatedBy.Title,
            } ?? authenticatedUser,
      UnitType:
        (result.Unit as Entity<number>) ??
        action.payload.OfflineServiceType?.UnitType ??
        null,
    };
    yield put(actions.updateMilestoneCharge_Success(charge));
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.UpdateSuccess),
      }),
    );
    yield put(actions.closeSidePanel());
  } catch (error) {
    log.error(error);
    yield put(actions.updateMilestoneCharge_Error());
  }
}
function* doInsertMilestone(
  action: PayloadAction<IServiceRequestMilestoneDto>,
) {
  try {
    var url = `api/requests/${action.payload.ServiceRequestId}/ServiceRequestMilestones`;
    var data: Partial<ServiceRequestMilestoneInsertModel> = {
      Name: action.payload.Name,
      CompletionDate: action.payload.CompletionDate,
      Assignees: action.payload.Assignees?.map(function (u) {
        return u.Id;
      }).join(','),
    };
    const response = yield call(httpClient.post, url, data);

    const p: IServiceRequestMilestoneDto = {
      Assignees: action.payload.Assignees,
      Charged: false,
      Id: response.Id,
      ServiceRequestMilestoneCharges: [],
      CompletionDate: response.CompletionDate,
      Name: response.Name,
      Description: response.Description,
      InvoiceId: null,
      ServiceRequestId: action.payload.ServiceRequestId,
      Status: action.payload.Status,
      TargetDate: response.TargetDate,
    };
    yield put(actions.insertMilestone_Success(p));
    yield put(actions.closeSidePanel());
    yield put(
      appSettingsActions.addNotification({
        key: 'foo',
        variant: 'success',
        message: i18next.t(translations.InsertSuccess),
      }),
    );
  } catch (error) {
    log.error(error);
    const msg = (error as AxiosError)?.message;
    if (msg !== undefined) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          key: 'foo',
          message: msg,
        }),
      );
    }
    yield put(actions.insertMilestone_Error());
  }
}
function* doDeleteMilestone(
  action: PayloadAction<IServiceRequestMilestoneDto>,
) {
  try {
    var url = `/api/requests/${action.payload.ServiceRequestId}/ServiceRequestMilestones/${action.payload.Id}`;
    yield call(httpClient.delete, url);
    yield put(actions.deleteMilestone_Success(action.payload));
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.DeleteSuccess),
      }),
    );
  } catch (error) {
    //todo
    log.error(error);
  }
}
function* doUpdateMilestone(
  action: PayloadAction<IServiceRequestMilestoneDto>,
) {
  try {
    var url = `/api/requests/${action.payload.ServiceRequestId}/ServiceRequestMilestones/${action.payload.Id}`;
    const requestPayload: ServiceRequestMilestoneInsertModel = {
      Assignees: action.payload.Assignees?.map(f => f.Id).join(','),

      Id: action.payload.Id,

      CompletionDate: action.payload.CompletionDate,
      Name: action.payload.Name,
      Description: action.payload.Description,
      ServiceRequestId: action.payload.ServiceRequestId,
      StatusId:
        ServiceRequestMilestoneStatuses[action.payload.Status ?? 'NotStarted'],
      TargetDate: action.payload.TargetDate,
    };
    const response: ServiceRequestMilestoneInsertModel = yield call(
      httpClient.put,
      url,
      requestPayload,
    );
    yield put(
      actions.updateMilestone_Success({
        ...action.payload,
        ...{
          Status:
            (response.StatusId === null
              ? undefined
              : (ServiceRequestMilestoneStatuses[
                  response.StatusId
                ] as ServiceRequestMilestoneStatusesUnion)) ?? 'NotStarted',
          CompletionDate: response.CompletionDate,
        },
      }),
    );
    yield put(actions.closeSidePanel());
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.UpdateSuccess),
      }),
    );
  } catch (error) {
    //todo
    log.error(error);
  }
}
function* doOpenSidePanel(action: PayloadAction<IOpenSidePanelPayload>) {
  const sidePanelState: SidePanelState = yield select(selectSidePanelState);
  const isSidePanelOpen =
    sidePanelState.sidePanelOpen &&
    sidePanelState.pageType === RenderPageType.ServiceRequestDetails;
  if (!isSidePanelOpen) {
    yield put(
      layoutActions.openSidePanel({
        type: action.payload.type,
        props: Object.assign({}, action.payload.props, {
          useSidePanel: true,
        }),
        expanded: action.payload.expanded,
      }),
    );
  } else {
    yield put(
      actions.setCover({
        type: action.payload.type,
        props: Object.assign({}, action.payload.props, {
          isCover: true,
          useSidePanel: true,
        }),
      }),
    );
  }
}
function* doCloseSidePanel(action: PayloadAction) {
  const sidePanelState: SidePanelState = yield select(selectSidePanelState);
  const isSidePanelOpen =
    sidePanelState.sidePanelOpen &&
    sidePanelState.pageType === RenderPageType.ServiceRequestDetails;
  if (isSidePanelOpen) {
    yield put(actions.setCover(undefined));
  } else {
    yield put(layoutActions.resetSidePanel());
    yield put(actions.resetRequestDetails);
  }
}
function* doCloneServiceRequest(action: PayloadAction<number>) {
  try {
    const url = `api/ServiceRequests/${action.payload}/CopyStep`;
    const response: { NewRequestId: number } = yield call(httpClient.post, url);
    yield put(push(GetServiceRequestDetailsPath(response.NewRequestId)));
  } catch (error) {
    log.error(error);
    const axiosError = error as AxiosError;
    const messages = getv2ControllerErrorMessages(axiosError?.response);
    if (messages !== undefined && messages.length > 0) {
      appSettingsActions.addNotifications(
        messages.map(message => ({
          variant: 'error',
          message: message,
        })),
      );
    }
  }
}

function* doCreateServiceRequestRow(
  action: PayloadAction<Partial<IServiceRequestTableRowModel>>,
) {
  const messageKey = 'doCreateServiceRequestRow';
  try {
    if (action.payload.ServiceRequestId === undefined) {
      log.error(
        'doCreateServiceRequestRow - ServiceRequestId is required',
        action.payload,
      );
      return;
    }
    const url = `api/odata/v4/ServiceRequests(${action.payload.ServiceRequestId})/Rows`;
    const columns = yield select(selectServiceRequestCustomFormColumns);
    const dto = serviceRequestModelToDto(action.payload, columns);
    const response: IServiceRequestRowDto = yield call(
      httpClient.post,
      url,
      dto,
    );
    yield put(
      actions.createServiceRequestRow_Success(
        prepareServiceRequestRowModel([response], columns),
      ),
    );
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        key: messageKey,
        message: i18next.t(
          translations.ServiceRequestRow_InsertSuccess,
          response,
        ),
      }),
    );
  } catch (error) {
    log.error(error);
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        key: messageKey,
        message: i18next.t(translations.ServiceRequestRow_InsertError),
      }),
    );
  }
}

const getv2ControllerErrorMessages = (
  response?: AxiosResponse<any>,
): string[] | undefined => {
  if (response === undefined) {
    return undefined;
  }
  const message: string[] = [];

  if (typeof response.data == 'string') {
    message.push(response.data);
  } else if (response.data.ExceptionMessage !== undefined) {
    message.push(response.data.ExceptionMessage);
  } else if (response.data.Message !== undefined) {
    message.push(response.data.Message);
  } else if (response.data.message !== undefined) {
    message.push(response.data.message);
  } else if (response.data.ErrorMessages) {
    for (var i = 0; i < response.data.ErrorMessages.length; i++) {
      message.push(response.data.ErrorMessages[i] as string);
    }
  }

  return message;
};

function* doLoadServiceRequestRowStatuses() {
  try {
    const SampleManagementTablesEnabled = yield select(state =>
      selectKnownModule(state, KnownModules.SampleManagementTables),
    );
    if (SampleManagementTablesEnabled) {
      const data: IODataQueryResponse<IServiceRequestRowStatusDto> = yield call(
        httpClient.get,
        '/api/odata/v4/ServiceRequestRowStatuses',
      );
      yield put(actions.loadServiceRequestRowStatuses_Success(data.value));
    }
  } catch (error) {
    log.error('Error loading service request row statuses');
  }
}

export function* requestSamplesSaga() {
  yield takeLeading(actions.init.type, doInit);
  yield takeLatest(actions.loadRequestDetails.type, doLoadRequestDetails);
  yield takeLatest(actions.refreshRequestDetails.type, doRefreshRequestDetails);
  yield takeLatest(actions.loadFormValues.type, doLoadFormValues);
  yield takeLatest(actions.loadMilestones.type, doLoadMilestones);
  yield takeLatest(actions.insertMilestoneCharge.type, doInsertMilestoneCharge);
  yield takeLatest(actions.updateMilestoneCharge.type, doUpdateMilestoneCharge);
  yield takeLatest(
    actions.deleteMilestoneCharge.type,
    doDeleteServiceRequestMilestoneCharge,
  );
  yield takeLatest(actions.openSidePanel.type, doOpenSidePanel);
  yield takeLatest(actions.closeSidePanel.type, doCloseSidePanel);

  yield takeLatest(actions.insertMilestone.type, doInsertMilestone);
  yield takeLatest(actions.updateMilestone.type, doUpdateMilestone);
  yield takeLatest(actions.deleteMilestone.type, doDeleteMilestone);

  yield takeLatest(actions.loadSamples.type, doLoadSamples);
  yield takeLatest(actions.patch.type, doPatchRequestDetails);
  yield takeLatest(actions.updateStatus.type, doUpdateStatus);
  yield takeLatest(
    actions.updateServiceRequestRowStatus.type,
    doUpdateServiceRequestRowStatus,
  );
  yield takeLatest(actions.updateBudget.type, doUpdateBudget);
  yield takeLatest(actions.updatePurchaseOrder.type, doUpdatePurchaseOrder);
  yield takeLatest(
    actions.updateExternalInternalNumber.type,
    doUpdateExternalInternalNumber,
  );
  yield takeLatest(actions.updateFundingType.type, doUpdateFundingType);
  yield takeLatest(actions.updateGradient.type, doUpdateGradient);
  yield takeLatest(actions.updateOverheadMinutes.type, doUpdateOverheadMinutes);
  yield takeLatest(actions.submitStep.type, doSubmit);
  yield takeLatest(actions.cancelServiceRequest.type, doCancelServiceRequest);
  yield takeLatest(actions.createSample.type, doCreateSample);
  yield debounce(
    100,
    actions.updateServiceRequestSampleField.type,
    doUpdateServiceRequestSampleField,
  );
  yield debounce(
    100,
    actions.updateServiceRequestRowValue.type,
    doUpdateServiceRequestRowValue,
  );
  yield takeLatest(actions.import.type, doImport);
  yield takeLatest(actions.import_Success.type, doLoadSamples);
  yield takeLeading(actions.deleteSamples.type, doDeleteSamples);
  yield takeLeading(actions.cloneServiceRequest, doCloneServiceRequest);

  yield takeEvery(
    actions.createServiceRequestRow.type,
    doCreateServiceRequestRow,
  );

  yield takeLatest(
    actions.loadServiceRequestRows.type,
    doLoadServiceRequestRows,
  );

  yield takeLatestByKey<IFormValueDto>(
    actions.saveFormValues.type,
    f => f?.FormFieldId,
    doSaveFormValue,
  );
}
