import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import {
  UsageDetailsState,
  UsageSettingsState,
  IUsageDto,
  IUsageEquipmentDto,
} from './types';

export function ConvertModelToEntity(
  original: IUsageDto,
  details: UsageDetailsState,
  offlineServices: IOtherServices[],
  user: AuthenticatedUser | undefined,
  settings?: UsageSettingsState,
  manually: boolean = false,
  reset?: boolean,
) {
  const entity: IUsageDto = {
    AbruptlyEnded: original.AbruptlyEnded,
    Id: details.Id,
    AccSerIds: null,
    AccServiceId: null,
    AutoEnd: original.AutoEnd,
    IsGuessLogOffTime: original.IsGuessLogOffTime,
    Reservation: null,
    Budget: null,
    BudgetExperiment: null,
    BookedBy: null,
    UpdatedAt: null,
    Operator: null,
    InstituteProject: null,
    UpdatedBy: null,
    HasCharges: original.HasCharges,
    HasInvoicedCharges: original.HasInvoicedCharges,
    HasOfflineServices: original.HasOfflineServices,
    IdleTimeout: original.IdleTimeout,
    IsEditable: original.IsEditable,
    IsForceTutoring: original.IsForceTutoring,
    OngoingUsage: original.OngoingUsage,
    PastUsage: original.PastUsage,
    Source: original.Source,
    ADGroup: null,
    ExternalCustomer: null,
    SavedOfflineServices: offlineServices,
    UpdatedManually: manually,
    FundingTypeId: details.FundingType?.Id ?? 0,
    StartTime: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(details.StartTime),
      { representation: 'complete' },
    ),
    EndTime:
      !!details.EndTime && details.EndTime !== null
        ? dateUtils.formatISO(dateUtils.dateOrStringToDate(details.EndTime))
        : undefined,
    BudgetId: details.Budget?.Id ?? null,
    BudgetExperimentId: details.BudgetExperiment?.Id ?? null,
    UserGroupId: details.ADGroup?.Id ?? '',
    UserName: details.BookedBy?.Id ?? user?.Id ?? '',
    InstituteProjectId: details.InstituteProject?.Id ?? null,
    ExternalCustomerId: details.ExternalCustomer?.Id ?? null,
    PurchaseOrder: details.PurchaseOrder,
    Reference: details.Reference,
    Remarks: details.Remarks,
    Tutoring: details.Tutoring,
    TutorName: details.Operator?.Id,
    AccServices: details.AccServices as IEquipmentAccServiceDto[],
    EquipmentID: details.BaseEquipment?.Id ?? 0,
    BaseEquipment: details.BaseEquipment as IUsageEquipmentDto,
    ReservationId: settings?.ReservationId ?? original.ReservationId,
    Reset: reset === true,
  };
  // {
  //   Id: details.Id,
  //   ADGroup: details.ADGroup,
  //   AccServices: details.AccServices,
  //   Budget: details.Budget,
  //   BudgetExperiment: details.BudgetExperiment,
  //   BaseEquipment: details.BaseEquipment,
  //   IdleTimeout: details.IdleTimeout,
  //   StartTime: dateUtils.formatISO(
  //     dateUtils.dateOrStringToDate(state.details.StartTime),
  //   ),
  //   EndTime: dateUtils.formatISO(
  //     dateUtils.dateOrStringToDate(state.details.EndTime),
  //   ),
  //   ReservationId: details.ReservationId,
  //   Remarks: details.Remarks || null,
  //   ExternalCustomer: details.ExternalCustomer,
  //   FundingTypeId: details.FundingType?.Id ?? 0,
  //   InstituteProject: details.InstituteProject,
  //   Tutoring: details.Tutoring,
  //   BookedBy: details.BookedBy,
  //   PurchaseOrder: details.PurchaseOrder,
  //   Reference: details.Reference,
  //   // UpdatedAt: dateUtils.formatISO(new Date()),
  //   // UpdatedBy: {Id: user?.Id, Name: user?.Name},
  //   OfflineServices: offlineServices,
  //   TutorName: settings.TutorName,
  //   TotalCost: settings.TotalCost ?? 0,
  // } as IUsageDto;

  return entity;
}
