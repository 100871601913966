import { httpClient } from 'api/HttpClient';

import { IAssetDetails2Dto } from 'api/odata/generated/entities/IAssetDetails2Dto';
import {
  AvailabilityTypes,
  AvailabilityTypesUnion,
} from 'api/odata/generated/enums/AvailabilityTypes';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { AutoMode } from 'enums/GlobalEnums';
import { ServiceTypeUnion } from 'api/odata/generated/enums/ServiceType';
import { IAsset } from '../IAsset';
import { AssetRowState, IAssetRow } from '../IAssetRow';
import {
  AssetDetailsAdditionalData,
  RawSection,
  Section,
  Sections,
} from './types';

export const isBookable = (availabilityType?: AvailabilityTypesUnion) => {
  if (availabilityType === undefined) {
    return false;
  }
  const bookable: AvailabilityTypesUnion[] = [
    'FreelyBookable',
    'LectureRoom',
    'LoanableEquipment',
    'TrainingProgram',
  ];
  return bookable.includes(availabilityType);
};
export const isOnlineService = (availabilityType?: AvailabilityTypesUnion) => {
  if (availabilityType === undefined) {
    return false;
  }
  const notOnline: AvailabilityTypesUnion[] = [
    'ServiceGroup',
    'ServiceRelated',
    'RequestRelated',
    'SuperGroup',
  ];
  return !notOnline.includes(availabilityType);
};
export function removeArrayItem(target, id: string) {
  const ri = target.findIndex(v => v.Id === id);
  if (ri >= 0) {
    const rows = target.splice(ri, 1);
    return rows[0];
  } else return null;
}
export function moveArrayItem(target, id: string, pos: number) {
  const ri = target.findIndex(v => v.Id === id);
  if (pos < target.length && ri >= 0 && pos !== ri) {
    const row = target.splice(ri, 1);
    target.splice(pos, 0, row[0]);
  }
}
export function moveArrayItemToEnd(target, id: string) {
  const ri = target.findIndex(v => v.Id === id);
  if (ri >= 0) {
    const row = target.splice(ri, 1);
    target.splice(target.length, 0, row[0]);
  }
}
export function moveArrayItemByName(target, id: string, name: string) {
  if (id !== name) {
    const ri = target.findIndex(v => v.Id === id);
    if (ri >= 0) {
      const rn = target.findIndex(v => v.Id === name);
      if (rn >= 0) {
        const row = target.splice(ri, 1);
        if (rn < ri) {
          target.splice(rn + 1, 0, row[0]);
        } else {
          target.splice(rn, 0, row[0]);
        }
      }
    }
  }
}
export function addArrayItemByName(target, id: string, name: string) {
  const ri = target.findIndex(v => v.Id === id);
  if (ri < 0) {
    const rn = target.findIndex(v => v.Id === name);
    if (rn > 0) {
      target.splice(rn + 1, 0, {
        Id: id,
        Visible: true,
      });
    } else {
      target.unshift({
        Id: id,
        Visible: true,
      });
    }
  }
}
export function moveArrayItemByKey(target, key: string, pos: number) {
  const ri = target.findIndex(v => v.key === key);
  if (pos < target.length && ri >= 0 && pos !== ri) {
    const row = target.splice(ri, 1);
    target.splice(pos, 0, row[0]);
  }
}
export function GetIsBookable(availabilityType: AvailabilityTypes) {
  let bookable: boolean = false;
  switch (availabilityType) {
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.LoanableEquipment:
    case AvailabilityTypes.LectureRoom:
    case AvailabilityTypes.TrainingProgram:
      bookable = true;
      break;
    default:
      bookable = false;
      break;
  }
  return bookable;
}
export function GetReservationEnabled(
  availabilityType: AvailabilityTypes,
  active: boolean,
  notAllowReservations: boolean,
) {
  let reservationEnabled: boolean = false;
  switch (availabilityType) {
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
    case AvailabilityTypes.LoanableEquipment:
    case AvailabilityTypes.LectureRoom:
      reservationEnabled = notAllowReservations === false && active === true;
      break;
    case AvailabilityTypes.TrainingProgram:
      reservationEnabled = active === true;
      break;
    case AvailabilityTypes.ServiceRelated:
    case AvailabilityTypes.ServiceGroup:
    case AvailabilityTypes.SuperGroup:
    case AvailabilityTypes.NotAvailable:
    case AvailabilityTypes.AvailableNotBookable:
    default:
      reservationEnabled = false;
      break;
  }
  return reservationEnabled;
}

export function GetUsageEnabled(
  availabilityType: AvailabilityTypes,
  active: boolean,
  autoMode?: number,
) {
  var result: boolean = autoMode === AutoMode?.LocalAppEnabled;
  switch (availabilityType) {
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
    case AvailabilityTypes.TrainingProgram:
    case AvailabilityTypes.LoanableEquipment:
    case AvailabilityTypes.LectureRoom:
      result = result && active === true;
      break;
    case AvailabilityTypes.ServiceRelated:
    case AvailabilityTypes.ServiceGroup:
    case AvailabilityTypes.SuperGroup:
    case AvailabilityTypes.NotAvailable:
    case AvailabilityTypes.AvailableNotBookable:
    default:
      result = result && false;
      break;
  }
  return result;
}

export function GetAlertsEnabled(
  availabilityType: AvailabilityTypes,
  active: boolean,
) {
  let alertsEnabled: boolean = false;
  switch (availabilityType) {
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
    case AvailabilityTypes.LoanableEquipment:
    case AvailabilityTypes.LectureRoom:
    case AvailabilityTypes.TrainingProgram:
    case AvailabilityTypes.NotAvailable:
    case AvailabilityTypes.AvailableNotBookable:
      alertsEnabled = active === true;
      break;
    case AvailabilityTypes.ServiceGroup:
    case AvailabilityTypes.SuperGroup:
    case AvailabilityTypes.ServiceRelated:
    case AvailabilityTypes.RequestRelated:
    default:
      alertsEnabled = false;
      break;
  }
  return alertsEnabled;
}
export function GetNotificationAvailable(availabilityType: AvailabilityTypes) {
  let available: boolean = false;
  if (
    (availabilityType as number) === 7 ||
    availabilityType === (8 as number)
  ) {
    return true;
  } else {
    switch (availabilityType) {
      case AvailabilityTypes.FreelyBookable:
      case AvailabilityTypes.BookableWApproval:
      case AvailabilityTypes.LoanableEquipment:
      case AvailabilityTypes.LectureRoom:
      case AvailabilityTypes.TrainingProgram:
        available = true;
        break;
      default:
        available = false;
        break;
    }
  }
  return available;
}

export const isValid = (row: IAssetRow, data: IAsset) => {
  if (
    row.State === AssetRowState.SectionName ||
    row.State === AssetRowState.Mandatory
  ) {
    return true;
  }
  const value = data![row.PropName ?? row.Id];
  const valid =
    row.validToRender !== undefined &&
    row.validToRender({ value: data![row.PropName ?? row.Id], original: data });
  if ((value !== null && value !== undefined && value !== '') || valid) {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
export function GetValidRos(rows: IAssetRow[], data: IAsset): IAssetRow[] {
  return rows.filter(row => isValid(row, data));
}
export function RemoveLastEmptySections(rows: IAssetRow[]) {
  if (rows.length > 0) {
    if (rows[rows.length - 1].State === AssetRowState.SectionName) {
      do {
        rows.pop();
      } while (
        rows.length > 0 &&
        rows[rows.length - 1].State === AssetRowState.SectionName
      );
    }
  }
  return rows;
}

export function GetRawSections(rows: IAssetRow[]): RawSection[] {
  const sections: RawSection[] = rows
    .map((r, index) => {
      return {
        Id: r.Id,
        Index: index,
        SectionName: r.State === AssetRowState.SectionName,
        remove: false,
      };
    })
    .filter(f => f.SectionName);
  return sections;
}
export function CheckEmptyRawSections(sections: RawSection[]) {
  if (sections.length > 1) {
    for (let i = 0; i < sections.length - 1; i++) {
      const s = sections[i];
      const s1 = sections[i + 1];
      if (s1.Index - s.Index === 1) {
        s.remove = true;
      }
    }
  }
}
export function RemoveEmptySections(sections: RawSection[], rows: IAssetRow[]) {
  for (let i = 0; i < sections.length - 1; i++) {
    const s = sections[i];
    if (s.remove) {
      const ri = rows.findIndex(v => v.Id === s.Id);
      rows.splice(ri, 1);
    }
  }
}

export function GetSections(rows: IAssetRow[]): Section[] {
  const noNameSection: Section = { Id: '', row: {} as IAssetRow, rows: [] };
  const sections: Section[] = [];
  let section: number = -1;
  for (let r of rows) {
    if (r.State === AssetRowState.SectionName) {
      section++;
      sections.push({
        Id: r.Id,
        Index: -1,
        row: r,
        rows: [],
      } as Section);
    } else {
      if (section >= 0) {
        sections[section].rows.push(r);
      } else {
        noNameSection.rows.push(r);
      }
    }
  }
  if (noNameSection.rows.length > 0) {
    sections.unshift(noNameSection);
  }
  return sections;
}
export function RowsToSections(rows: IAssetRow[]): Section[] {
  const sections: Section[] = [];
  for (let r of rows) {
    sections.push({
      Id: r.Id,
      Index: -1,
      row: r,
      rows: [r],
    } as Section);
  }
  return sections;
}
export function RowToSections(rows: IAssetRow[], id: string): Section | null {
  const row = removeArrayItem(rows, id);
  if (row !== null) {
    const section = {
      Id: row.Id,
      Index: -1,
      row: row,
      rows: [row],
    } as Section;
    return section;
  } else return null;
}
export function GetRowsWithSections(
  rows: IAssetRow[],
  data: IAsset,
): IAssetRow[] {
  let res: IAssetRow[] = GetValidRos(rows, data);
  res = RemoveLastEmptySections(res);
  const sections = GetRawSections(res);
  CheckEmptyRawSections(sections);
  RemoveEmptySections(sections, res);
  return res;
}
export function GetRowsSections(
  rows: IAssetRow[],
  data: IAsset,
  isMobile?: boolean,
): Sections {
  let leftRes: IAssetRow[] = [];
  let rightRes: IAssetRow[] = [];
  const checkSection = (row: IAssetRow) => {
    return isMobile
      ? ['Description', 'Comments', 'AssetAssemblyStructure'].includes(
          row.Id,
        ) === false
      : [
          'ImageName',
          'Description',
          'Comments',
          'AssetAssemblyStructure',
        ].includes(row.Id) === false;
  };
  for (const row of rows) {
    if (isValid(row, data)) {
      if (checkSection(row)) {
        leftRes.push(row);
      } else {
        rightRes.push(row);
      }
    }
  }
  leftRes = RemoveLastEmptySections(leftRes);
  const sections = GetRawSections(leftRes);
  CheckEmptyRawSections(sections);
  RemoveEmptySections(sections, leftRes);
  if (isMobile) {
    if (rightRes.length > 0) {
      moveArrayItem(rightRes, 'Description', 0);
      moveArrayItem(rightRes, 'AssetAssemblyStructure', 1);
      moveArrayItem(rightRes, 'Comments', 2);
    }
  } else {
    if (rightRes.length > 0) {
      moveArrayItem(rightRes, 'ImageName', 0);
      moveArrayItem(rightRes, 'Description', 1);
      moveArrayItem(rightRes, 'AssetAssemblyStructure', 2);
      moveArrayItem(rightRes, 'Comments', 3);
    }
  }

  leftRes = leftRes.filter(getSectionsFilter(data));
  rightRes = rightRes.filter(getSectionsFilter(data));
  const resSections: Sections = {} as Sections;
  if (isMobile) {
    const imageSection = RowToSections(leftRes, 'ImageName');
    resSections.leftSections = GetSections(leftRes);
    if (imageSection !== null) {
      resSections.leftSections.unshift(imageSection);
    }
  } else {
    resSections.leftSections = GetSections(leftRes);
  }
  resSections.rightSections = RowsToSections(rightRes);
  return resSections;
}

const getSectionsFilter = (data: IAssetDetails2Dto) => (row: IAssetRow) => {
  return (
    row.validToRender === undefined ||
    row.validToRender({
      value: data![row.PropName ?? row.Id],
      original: data,
    })
  );
};
/**
 * Main asset identifier based on primary key
 */
export interface AssetIdIdentifier {
  id: number;
}
/**
 * Service/ServiceType identification. In this case both properties must be supplied in order to prevent potential conflicts between different service types.
 */
export interface AssetServiceIdentifier {
  serviceType: ServiceTypeUnion;
  serviceId: number;
}
/**
 * Internal code based identifier.
 */
export interface AssetInternalCodeIdentifier {
  internalCode: string;
}

/**
 * Combined interface that uniquely identifies asset by one of the unioned interfaces
 */
export type AssetIdentifier =
  | AssetIdIdentifier
  | AssetServiceIdentifier
  | AssetInternalCodeIdentifier
  | {};

export async function fetchAssetBy(props: {
  id: AssetIdentifier;
  params: Record<string, string>;
  isAuth?: boolean;
}) {
  const res = await fetchAssetByInternal(props);
  if (res !== undefined) {
    res.Division = res.ServiceGroup?.DivisionName;
    res.AssetCatGroup =
      res.AssetCat !== null &&
      !!res.AssetCat &&
      res.AssetCat.GroupId &&
      res.AssetCat.AssetCatGroupName
        ? {
            Id: res.AssetCat.GroupId,
            Name: res.AssetCat.AssetCatGroupName,
            ServiceGroups: [],
          }
        : null;
    res.Status = ' ';
    res.AlertsEnabled =
      props.isAuth === true
        ? GetAlertsEnabled(
            AvailabilityTypes[res.AvailabilityType.Id],
            res.Active,
          )
        : false;
  }
  return res;
}
async function fetchAssetByInternal(props: {
  id: AssetIdentifier;
  params: Record<string, string>;
  isAuth?: boolean;
}) {
  const url = props.isAuth
    ? `/api/odata/v4/AssetDetails2`
    : `/api/odata/v4/PublicAsset`;
  if ((props.id as AssetIdIdentifier).id !== undefined) {
    const res: IAssetDetails2Dto = await httpClient.get(
      `${url}/${(props.id as AssetIdIdentifier).id}`,
      props.params,
    );
    return res;
  } else if (
    (props.id as AssetServiceIdentifier).serviceId !== undefined &&
    (props.id as AssetServiceIdentifier).serviceType !== undefined
  ) {
    const res: IODataQueryResponse<IAssetDetails2Dto> = await httpClient.get(
      url,
      {
        ...props.params,
        $filter: `ServiceType eq '${
          (props.id as AssetServiceIdentifier).serviceType
        }' and ServiceId eq ${(props.id as AssetServiceIdentifier).serviceId}`,
      },
    );
    return res.value[0];
  } else if (
    (props.id as AssetInternalCodeIdentifier).internalCode !== undefined
  ) {
    const res: IODataQueryResponse<IAssetDetails2Dto> = await httpClient.get(
      `${url}`,
      {
        ...props.params,
        $filter: new Condition<IAssetDetails2Dto>(
          'InternalCode',
          ODataOperators.Equals,
          (props.id as AssetInternalCodeIdentifier).internalCode,
        ).toString(),
      },
    );
    return res.value[0];
  }
}
export async function fetchAssetAdditionalData(props: {
  Id: number;
  typeId: number;
  isAuth?: boolean;
  buttonsData?: boolean;
}) {
  try {
    const res = await httpClient.post<AssetDetailsAdditionalData>(
      props.isAuth
        ? `/api/odata/v4/AssetDetails2/GetAdditionalDetails`
        : `/api/odata/v4/PublicAsset/GetAdditionalDetails`,
      { key: props.Id, buttonsData: props.buttonsData, typeId: props.typeId },
    );
    return res;
  } catch {}
}

export async function fetchAssetButtonsData(
  Id: number,
): Promise<AssetDetailsAdditionalData> {
  const res = await httpClient.post<AssetDetailsAdditionalData>(
    `/api/odata/v4/AssetDetails2/GetButtonsData`,
    { key: Id },
  );
  return { ...{ Id: Id }, ...res };
}
