import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { intersection } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import useGlobalSettingsHook, {
  GlobalSettingsType,
} from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { IUsers } from 'app/pages/UsersPage/IUsers';

export function useUserPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const globalSettings = useGlobalSettingsHook();
  const roles: number[] | undefined = user?.Roles;
  const allowedToView = React.useCallback(
    (row: IUserPermissionCheckRecord): boolean =>
      allowedToViewUserDetails(row, globalSettings, user),
    [globalSettings, user],
  );
  const allowedToEditDetails = React.useCallback(
    (row: IUserPermissionCheckRecord): boolean =>
      allowedToEditUserDetails(row, globalSettings, user),
    [globalSettings, user],
  );
  const allowedToEditSettings = React.useCallback(
    (row: IUserPermissionCheckRecord): boolean =>
      allowedToEditUserSettings(globalSettings, user),
    [globalSettings, user],
  );
  const allowedroles = [
    Roles.Administrators,
    Roles.GroupAdministrators,
    Roles.LabTech,
  ];
  const hasRolePermissions: boolean =
    intersection(roles, allowedroles).length > 0;
  return {
    hasRolePermissions,
    allowedToView,
    allowedToEditDetails,
    allowedToEditSettings,
  };
}
export type IUserPermissionCheckRecord = Pick<IUsers, 'Name'>;
export const allowedToEditUserDetails = (
  row: IUserPermissionCheckRecord,
  globalSettings: GlobalSettingsType,
  user?: AuthenticatedUser,
): boolean => {
  if (!user) return false;
  if (row.Name === '') return false;
  const IsAdmin = user?.isAdmin();
  const GroupAdminAffiliated = user?.Roles.includes(Roles.GroupAdminAffiliated);
  const IsGroupAdmin = user?.isGroupAdmin();

  const userIsAffiliatedAdmin =
    GroupAdminAffiliated && globalSettings.EnableCoreAffilationManagment;

  const coreManagementEnabled =
    IsAdmin ||
    (IsGroupAdmin &&
      (globalSettings.ServiceGroupAdminsCanManageAllUsers ||
        globalSettings.ServiceGroupAdminsCanManageAdUsers));

  const allowEdit =
    IsAdmin || (!IsAdmin && (userIsAffiliatedAdmin || coreManagementEnabled));

  return user.Id === row.Name || allowEdit;
};
export const allowedToEditUserSettings = (
  globalSettings: GlobalSettingsType,
  user?: AuthenticatedUser,
): boolean => {
  if (!user) return false;
  const IsAdmin = user?.isAdmin();
  const GroupAdminAffiliated = user?.Roles.includes(Roles.GroupAdminAffiliated);
  const IsGroupAdmin = user?.isGroupAdmin();

  const userIsAffiliatedAdmin =
    GroupAdminAffiliated && globalSettings.EnableCoreAffilationManagment;

  const coreManagementEnabled =
    IsAdmin ||
    (IsGroupAdmin &&
      (globalSettings.ServiceGroupAdminsCanManageAllUsers ||
        globalSettings.ServiceGroupAdminsCanManageAdUsers));

  const allowEdit =
    IsAdmin || (!IsAdmin && (userIsAffiliatedAdmin || coreManagementEnabled));

  return allowEdit;
};
export const allowedToViewUserDetails = (
  row: IUserPermissionCheckRecord,
  globalSettings: GlobalSettingsType,
  user?: AuthenticatedUser,
): boolean => {
  if (!user) return false;
  if (row.Name === '') return false;
  const IsAdmin = user?.isAdmin();
  const GroupAdminAffiliated = user?.Roles.includes(Roles.GroupAdminAffiliated);
  const IsGroupAdmin = user?.isGroupAdmin();
  const isLabTech = user?.isLabTech();
  const userIsAffiliatedAdmin =
    GroupAdminAffiliated && globalSettings.EnableCoreAffilationManagment;

  const allowToView =
    IsAdmin ||
    userIsAffiliatedAdmin ||
    IsGroupAdmin ||
    isLabTech ||
    user.Id === row.Name;

  return allowToView;
};
