import { PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from 'api/HttpClient';
import { appSettingsActions } from 'app/slice';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { messagesActions as actions } from '.';
import {
  CalendarMessageProps,
  LoanDesk,
  Message,
  MessageTypeEnum,
  StartedReservation,
  UsageReservation,
} from './type';
import { odataUrlPrefix } from 'utils/url-utils';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import { MessageProps, pageNames } from '..';

const reservePrefix = 'reservationStarted_';
const usagePrefix = 'usageReservation_';
const loanPrefix = 'LoanDeskReservation_';
// const announcePrefix = 'Announcment_';
const calendarprefix = 'calendar_';

const reservationStartedurl = '/api/odata/v4/ReservationStarted';
const usageReservationUrl = '/api/odata/v4/UsageReservation';
const LoanDeskReservationUrl = '/api/odata/v4/LoanDeskReservation';
const calendarUrl = odataUrlPrefix + 'CalendarMessages/Show';
function* doInit(action: PayloadAction<MessageProps>) {
  const messages: Message[] = [];
  const page = action.payload.pageName;
  const isAuth: boolean | undefined = yield select(selectIsAuthenticated);
  if (isAuth === true) {
    const funcs = getPageMessages(page);
    for (let index = 0; index < funcs.length; index++) {
      const func = funcs[index];
      yield* func(messages, action.payload);
    }
  }

  // if (page !== 'calendar') {
  //   yield* addReservations(messages);
  //   yield* addUsages(messages);
  //   yield* addLoanDesks(messages);
  //   // yield* doAnnouncement(messages);
  // }
  // yield* addCalendarMessages(messages, action.payload);
  yield put(actions.init_success(messages));
}

type MessageGetter = (
  messages: Message[],
  props: MessageProps,
) => Generator<unknown, void, unknown>;

function getPageMessages(pageName: pageNames): MessageGetter[] {
  switch (pageName) {
    case 'calendar':
      return [addCalendarMessages];
    case 'assetDetails':
      return [addReservations, addUsages];
    default:
      return [addReservations, addUsages, addLoanDesks, addCalendarMessages];
  }
}

function* doeRefreshReservations(_action: PayloadAction) {
  const messages: Message[] = [];
  yield* addReservations(messages);
  yield put(actions.init_success(messages));
}

function* addReservations(messages: Message[]) {
  const reservationsInValue = yield call(httpClient.get, reservationStartedurl);
  const reservations = reservationsInValue.value as StartedReservation[];
  messages.push(
    ...reservations.map((r, _i) => ({
      type: MessageTypeEnum.reservationStarted,
      props: r,
      key: reservePrefix + r.Id,
    })),
  );
}

function* addUsages(messages: Message[]) {
  const usagesInValue = yield call(httpClient.get, usageReservationUrl);
  const usages = usagesInValue.value as UsageReservation[];
  messages.push(
    ...usages.map((r, _i) => ({
      type: MessageTypeEnum.usageReservation,
      props: r,
      key: usagePrefix + r.Id,
    })),
  );
}

function* addLoanDesks(messages: Message[]) {
  const LoanDeskInValue = yield call(httpClient.get, LoanDeskReservationUrl);
  const LoanDesks = LoanDeskInValue.value as LoanDesk[];
  messages.push(
    ...LoanDesks.map((r, _i) => ({
      type: MessageTypeEnum.loadDesk,
      props: r,
      key: loanPrefix + r.Id,
    })),
  );
}

function* addCalendarMessages(messages: Message[], props: MessageProps) {
  const params: {
    $select: string;
    $filter?: string;
  } = { $select: 'Id,Title,Body,DisplaySeconds' };
  const now = new Date();
  const groups = yield select(selectGlobalServiceGroupFilter);
  params.$filter = new ODataFilterBuilder({
    predicates: [
      new Condition(
        'StartDate',
        ODataOperators.LessThanEqual,
        now,
      ).withNull() ?? '',
      new Condition(
        'EndDate',
        ODataOperators.GreaterThanEqual,
        now,
      ).withNull() ?? '',
      new Condition(
        'ServiceGroupId',
        ODataOperators.Includes,
        groups,
      ).withNull() ?? '',
      new Condition('ShowOn', ODataOperators.Contains, props.pageName),
      new Condition(
        'EquipmentId',
        ODataOperators.Includes,
        props.selectedEquipment,
      ).withNull() ?? '',
    ],
  }).toString();
  const calendarInValue = yield call(httpClient.get, calendarUrl, params);
  const calendars = calendarInValue.value as CalendarMessageProps[];
  messages.push(
    ...calendars.map(c => ({
      type: MessageTypeEnum.calendar,
      key: calendarprefix + c.Id,
      props: c,
    })),
  );
}

// function* doAnnouncement(messages: Message[]) {
//   const settings: GlobalSettings = yield select(selectglobalSettings);
//   const userSettings: UserProfile = yield select(selectUserProfileSettings);
//   if (
//     settings.GetBooleanByKey(AllowedSettings.ShowDashboardAnnouncement) &&
//     !userSettings.GetSettingByKey(
//       AllowedSettings[AllowedSettings.DashboardAnnouncement],
//     )
//   ) {
//     const announcent = settings.GetString(
//       AllowedSettings.DashboardAnnouncement,
//     );
//     if (!!announcent) {
//       messages.push({
//         type: messageType.removableAnnouncment,
//         props: announcent,
//         key: announcePrefix + 0,
//       });
//     }
//   }
//   const userHelp = settings.GetString(AllowedSettings.Help_Dashboard);
//   if (userHelp.trim() !== '') {
//     messages.push({
//       type: messageType.Announcment,
//       props: userHelp,
//       key: announcePrefix + 1,
//     });
//   }
// }

function* doGotIt(action: PayloadAction<string>) {
  const authenticatedUser: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  yield put(
    appSettingsActions.saveUserProfileSetting({
      Key: action.payload,
      Value: true.toString(),
      UserName: authenticatedUser?.Id ?? '',
      Enabled: true,
    }),
  );
}

export function* messagesSaga() {
  yield takeLatest(actions.init.type, doInit);
  yield takeLatest(actions.gotIt.type, doGotIt);
  yield takeLatest(actions.refreshUsages.type, doeRefreshReservations);
}
