import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { useTranslation } from 'react-i18next';
import { IEmailNotificationsRow } from '../IEmailNotificationsRow';
import * as React from 'react';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { translations } from 'locales/translations';
import { Body } from 'app/components/Typography';
import { IResponseType } from 'types/ResponseType';
import { EmailNotificationsApi } from 'api/EmailNotificationsApi';

export interface GetSelectedActionsProps {}

export function GetSelectedActions(
  props: GetSelectedActionsProps,
): SelectedRowsActionRenderer<IEmailNotificationsRow>[] {
  const { t } = useTranslation();
  const SelectedActions: SelectedRowsActionRenderer<
    IEmailNotificationsRow
  >[] = React.useMemo(
    () => [
      (rows, onRefresh) => (
        <SelectedRowsActionIconButton
          title={t(translations.DeleteEmailNotificationsRows_info)}
          text={t(translations.Delete)}
          confirm={true}
          confirmTitle={t(translations.ConfirmDeleteEmailNotif_title)}
          confirmBody={
            <Body bold={true}>
              {t(translations.ConfirmDeleteEmailNotif_message)}
            </Body>
          }
          confirmButtonLabel={t(translations.OK)}
          cancelButtonLabel={t(translations.Cancel)}
          rows={rows}
          onRefresh={onRefresh}
          variantButton="button"
          validConfirm={true}
          action={() =>
            new Promise<IResponseType>((resolve, reject) => {
              const postData = rows.map(item => item.Id);
              // do some work on the selected rows

              return EmailNotificationsApi.deleteSelectedRows(postData)
                .then(response => {
                  let success = t(
                    translations.DeleteNotifications_success,
                  ) as string;
                  resolve({
                    SuccessMessages: [success],
                    ErrorMessages: [],
                    WarningMessages: [],
                  } as IResponseType);
                })
                .catch(error => {
                  let err = t(translations.DeleteNotifications_error) as string;
                  reject(err);
                });
            })
          }
        />
      ),
    ],
    [t],
  );
  return SelectedActions;
}
