import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const SpanPaddingInline8 = styled.span`
  padding-inline: 8px;
`;
export const FlexColumn16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
`;
export const FlexRow8 = styled(FlexRow)`
  gap: 8px;
`;

export const StyledStatusLabel = styled.span<{
  variant?: 'success' | 'info' | 'warning' | 'error';
  size?: 'small' | 'medium' | 'large';
}>`
  &.status-label {
    background-color: ${props =>
      props.variant === 'success'
        ? bookitColors.success.background
        : props.variant === 'info'
        ? bookitColors.primary.background
        : props.variant === 'warning'
        ? bookitColors.warning.background
        : props.variant === 'error'
        ? bookitColors.danger.background
        : bookitColors.base.white};
    color: ${props =>
      props.variant === 'success'
        ? bookitColors.success.dark
        : props.variant === 'info'
        ? bookitColors.primary.dark
        : props.variant === 'warning'
        ? bookitColors.warning.dark
        : props.variant === 'error'
        ? bookitColors.danger.dark
        : bookitColors.grayscale.blackRegular};
    border-radius: 8px;
    padding: ${props =>
      props.size === 'small' ? 4 : props.size === 'large' ? 10 : 6}px;
  }
`;
export const StyledLimitLabel = styled.span`
  &.limit-label {
    display: inline-flex;
    align-items: center;
    & .infinity-icon {
      font-size: 24px;
    }
  }
`;
