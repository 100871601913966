import { BasicTabs } from 'app/components/BasicTabs';
import { TabContent } from 'app/components/BasicTabs/TabContent';
import { TabPanel, TabPanelProps } from 'app/components/BasicTabs/TabPanel';
import * as React from 'react';
import { MobileDropDownTabs } from './MobileDropDownTabs';

export interface AssetDetailsTabsProps {
  tabs: TabContent[];
  shortView: boolean;
  isMobile: boolean;
}
export function AssetDetailsTabs(props: AssetDetailsTabsProps) {
  const { tabs, shortView, isMobile } = props;

  const [selectedTab, setSelectedTab] = React.useState<TabContent>(tabs[0]);

  const tabsId = 'assetdetails-tab';
  const tabsPanelId = 'assetdetails-tabpanel';

  const handleSelectedTabChange = (newValue: TabContent) => {
    setSelectedTab(newValue);
  };
  const tabsPanels: Array<React.ReactElement<
    TabPanelProps
  >> = React.useMemo(() => {
    return tabs.map((item, index) => (
      <TabPanel
        value={tabs.findIndex(f => f.Id === selectedTab.Id)}
        index={index}
        tabsId={tabsId}
        tabsPanelId={tabsPanelId}
        scrollableContent={true}
        key={item.Id}
      >
        {item.Content}
      </TabPanel>
    ));
  }, [selectedTab.Id, tabs]);
  return (
    <React.Fragment>
      {shortView ? (
        <MobileDropDownTabs
          tabsId={'assetdetails-tab'}
          tabsPanelId={'assetdetails-tabpanel'}
          tabs={tabs}
          isMobile={isMobile}
          handleTabChange={handleSelectedTabChange}
          selectedTab={selectedTab}
          tabPanels={tabsPanels}
        />
      ) : (
        <BasicTabs
          orientation="horizontal"
          tabsId={'assetdetails-tab'}
          tabsPanelId={'assetdetails-tabpanel'}
          tabs={tabs}
          variant={'scrollable'}
          selectedTabIndex={tabs.findIndex(f => f.Id === selectedTab.Id)}
          scrollableContent={true}
          handleTabChange={handleSelectedTabChange}
          tabPanels={tabsPanels}
        />
      )}
    </React.Fragment>
  );
}
