import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { IReservationWaitingListDto } from './IReservationWaitingListDto';
import { SignUpWaitingListDetailsProps } from './SignUpWaitingListDetails';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';

export const waitingListPrimaryAction: PrimaryActionDynamic<IReservationWaitingListDto> = (
  openPanel,
  row,
  toggle,
  user,
  useSidePanel,
) => {
  let signupProps = {
    initialDetails: row.original,
    useSidePanel: true,
  } as SignUpWaitingListDetailsProps;
  openPanel({
    renderPageType: RenderPageType.SignUpWaitingListDetails,
    renderPageProps: signupProps,
    expanded: false,
    useSidePanel: false,
    isCover: useSidePanel,
  } as SidePanelOpenState);
};

export default waitingListPrimaryAction;
