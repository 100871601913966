import { ClickAwayListener, Fade, Paper, useTheme } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import * as React from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { DateInput, DateInputProps } from './DateInput';
import {
  DatePopupVariants,
  getInputViewDate,
  getValueDateOrTruncat,
  setInputViewDate,
} from './utils/types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  BaseDatePickerProps,
  BaseTimePickerProps,
  DatePicker,
  KeyboardDatePickerProps,
  TimePicker,
  useUtils,
} from '@material-ui/pickers';
import { DatePickerValueProps } from '../DatePicker';
import { PickerPopper, StyledPicker } from './StyledPicker';
import { SeparatedPickerProps } from '../KeyBoardDatePicker';
import { KeyboardDateInputProps } from '@material-ui/pickers/_shared/KeyboardDateInput';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Button } from '../BasicButtons/Button';
import { BaseKeyboardPickerProps } from '@material-ui/pickers/_shared/hooks/useKeyboardPickerState';
import { dateUtils } from 'utils/date-utils';
import { DateDropDown } from './DateDropDown';
import { TimeDropDown } from './TimeDropDown';
import { useSelector } from 'react-redux';
import { selectUseAmPm } from 'app/slice/selectors';
import {
  validateDate,
  validateTime,
} from '../DatePicker/utils/pickerDateValidate';
import { useKeyUpOutside } from 'app/hooks/useKeyUpOutside';
import { KeyboardInput } from './KeyboardInput';
import { TimeOptionPicker } from './TimeOptionPicker';

export interface BookitDatePickerProps
  extends BaseDatePickerProps,
    Pick<BaseTimePickerProps, 'minutesStep'>,
    SeparatedPickerProps,
    Pick<BaseKeyboardPickerProps, 'onChange' | 'inputValue'>,
    Pick<
      KeyboardDateInputProps,
      | 'validationError'
      | 'keyboardIcon'
      | 'mask'
      | 'maskChar'
      | 'KeyboardButtonProps'
      | 'rifmFormatter'
      | 'refuse'
    >,
    Pick<
      KeyboardDatePickerProps,
      | 'labelFunc'
      | 'clearable'
      | 'maxDateMessage'
      | 'minDateMessage'
      | 'invalidDateMessage'
      | 'onError'
    >,
    Pick<DatePickerValueProps, 'value' | 'endTime' | 'truncateTime'>,
    Pick<
      DateInputProps,
      | 'autoFocus'
      | 'fullWidth'
      | 'helperText'
      | 'placeholder'
      | 'error'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'margin'
      | 'inputProps'
      | 'InputProps'
      | 'TextFieldComponent'
      | 'onBlur'
      | 'onFocus'
    > {
  inputVariant?: 'filled' | 'standard' | 'outlined';
  closablePicker?: boolean;
  closeTitle?: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  variant?: DatePopupVariants;
  info?: string;
  ariaLabel?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  size?: 'medium' | 'small' | undefined;
  className?: string;
  fullScreen?: boolean;
  readonlyInput?: boolean;
  useTimeClockPicker?: boolean;
  useTimeDropDowns?: boolean;
  useDateDropDowns?: boolean;
  useDateCalendarPicker?: boolean;
  saveOnSelect?: boolean;
  maskIfEmpty?: boolean;
  clearable?: boolean;
  disableClearable?: boolean;
}

export function BookitDatePicker(props: BookitDatePickerProps) {
  const isMobile = DetectIsMobile();
  const theme = useTheme();
  const { t } = useTranslation();
  const useAmPm = useSelector(selectUseAmPm);
  const utils = useUtils();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const {
    inputVariant,
    closablePicker = true,
    closeTitle = 'Close',
    onClose,
    onOpen,
    value,
    endTime,
    truncateTime,
    variant = 'date',
    info,
    startIcon,
    endIcon,
    ariaLabel,
    minDateMessage = 'Date should not be before minimal date',
    maxDateMessage = 'Date should not be after maximal date',
    invalidDateMessage = 'Invalid Date Format',
    onChange,
    labelFunc,
    readonlyInput,
    useTimeClockPicker,
    useTimeDropDowns,
    useDateDropDowns,
    saveOnSelect,
    maskIfEmpty = true,
    clearable,
    disableClearable,
    ...other
  } = props;

  const dateInputProps = React.useMemo(() => {
    return {
      autoFocus: props.autoFocus,
      fullWidth: props.fullWidth,
      helperText: props.helperText,
      placeholder: props.placeholder,
      error: props.error,
      required: props.required,
      disabled: props.disabled,
      name: props.name || 'dateInputName',
      id: props.id || 'dateInputId',
      label: props.label,
      margin: props.margin,
      inputProps: props.inputProps,
      InputProps: props.InputProps,
      TextFieldComponent: props.TextFieldComponent,
      onBlur: props.onBlur,
      onFocus: props.onFocus,
    };
  }, [
    props.InputProps,
    props.TextFieldComponent,
    props.autoFocus,
    props.disabled,
    props.error,
    props.fullWidth,
    props.helperText,
    props.id,
    props.inputProps,
    props.label,
    props.margin,
    props.name,
    props.onBlur,
    props.onFocus,
    props.placeholder,
    props.required,
  ]);
  const keyboardInputProps = React.useMemo(() => {
    return {
      ...dateInputProps,
      minDateMessage: minDateMessage,
      maxDateMessage: maxDateMessage,
      invalidDateMessage: invalidDateMessage,
      ariaLabel: ariaLabel,
      startIcon: startIcon,
      endIcon: endIcon,
      info: info,
      labelFunc: labelFunc,
      minDate: other.minDate,
      maxDate: other.maxDate,
      disableFuture: other.disableFuture,
      disablePast: other.disablePast,
      strictCompareDates: other.strictCompareDates,
    };
  }, [
    ariaLabel,
    dateInputProps,
    endIcon,
    info,
    invalidDateMessage,
    labelFunc,
    maxDateMessage,
    minDateMessage,
    other.disableFuture,
    other.disablePast,
    other.maxDate,
    other.minDate,
    other.strictCompareDates,
    startIcon,
  ]);
  const [dateValue, setDateValue] = React.useState<
    Date | string | null | undefined
  >(getValueDateOrTruncat(variant, value, endTime, truncateTime));
  const [open, setOpen] = React.useState<boolean | undefined>(undefined);
  const [validationError, setValidationError] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const dateTimeValue = React.useMemo(() => {
    return getValueDateOrTruncat(variant, value, endTime, truncateTime);
  }, [endTime, truncateTime, value, variant]);
  React.useEffect(() => {
    setDateValue(() => {
      if (dateTimeValue === null || dateTimeValue === undefined) {
        return null;
      } else {
        return dateUtils.dateOrStringToDate(dateTimeValue);
      }
    });
  }, [dateTimeValue]);
  useKeyUpOutside(
    elementRef,
    () => {
      if (open) {
        if (!validationError || validationError === '') {
          handleSave();
        }
        handleClose();
      }
    },
    'keyup',
    'Tab',
  );
  // useGlobalDOMEvents({
  //   keyup(ev) {
  //     if ((ev as KeyboardEvent).key === 'Tab') {
  //       console.log('tab event target', ev.target);
  //       console.log('tab event currentTarget', ev.currentTarget);
  //     }
  //   },
  // });
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  React.useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  const handleClickAway = (event: React.MouseEvent<Document>) => {
    if (open) {
      if (!validationError || validationError === '') {
        handleSave();
      }
      handleClose();
    }
  };
  const handleSave = React.useCallback(() => {
    onChange(setInputViewDate(variant, dateValue, endTime, truncateTime));
    setOpen(false);
    onClose?.();
  }, [dateValue, endTime, onChange, onClose, truncateTime, variant]);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
    onOpen?.();
  }, [onOpen]);
  const togglePicker = React.useCallback(() => {
    if (open) {
      if (!validationError || validationError === '') {
        handleClose();
      }
    } else {
      handleOpen();
    }
  }, [handleClose, handleOpen, open, validationError]);
  const inputDisplayValue = React.useMemo(() => {
    return getInputViewDate(variant, dateValue, endTime, truncateTime);
  }, [endTime, truncateTime, dateValue, variant]);

  const onError = React.useCallback(
    (error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate) => {
      setValidationError(error);
      if (!!props.onError) {
        props.onError(error, value);
      }
    },
    [props],
  );
  return (
    <ClickAwayListener
      mouseEvent={'onMouseDown'}
      onClickAway={event => handleClickAway(event)}
    >
      <StyledPicker theme={theme} className={'picker-root'} ref={elementRef}>
        {readonlyInput || isMobile ? (
          <DateInput
            openPicker={handleOpen}
            togglePicker={togglePicker}
            inputVariant={inputVariant || 'filled'}
            inputValue={inputDisplayValue ?? ''}
            validationError={
              dateInputProps.error ? dateInputProps.helperText : undefined
            }
            className={clsx('picker-date-input', props.className)}
            size={props.size}
            info={info}
            startIcon={startIcon}
            variant={variant}
            endIcon={endIcon}
            ariaLabel={ariaLabel}
            {...dateInputProps}
          />
        ) : (
          <KeyboardInput
            value={dateValue}
            openPicker={handleOpen}
            togglePicker={togglePicker}
            inputVariant={inputVariant || 'filled'}
            inputValue={''}
            variant={variant}
            className={clsx('keyboard-date-input', props.className)}
            size={props.size}
            maskIfEmpty={maskIfEmpty}
            isPickerOpen={open}
            clearable={clearable}
            onChange={(date, inpValue, withCompletion) => {
              setDateValue(date);
              if (withCompletion) {
                if (!validationError || validationError === '') {
                  onChange(
                    setInputViewDate(variant, date, endTime, truncateTime),
                    inpValue,
                  );
                  setOpen(false);
                  onClose?.();
                }
              }
              // onChange(date, inpValue);
              // if (open) {
              //   setOpen(false);
              //   onClose?.();
              // }
              //onChange(setInputViewDate(variant, dateValue, endTime, truncateTime));
            }}
            onError={(error, date) => {
              if (
                !!error &&
                !!date &&
                date !== null &&
                dateUtils.dateFnsUtils.isValid(date)
              ) {
                let setDate = dateUtils.getParsableDate(date);
                setDateValue(setDate);
              }

              onError(error, date);
            }}
            ampm={useAmPm}
            format={
              variant === 'time'
                ? dateUtils.DateIOFormats.fullTime
                : dateUtils.DateIOFormats.keyboardDate
            }
            validationError={
              keyboardInputProps.error
                ? keyboardInputProps.helperText
                : undefined
            }
            {...keyboardInputProps}
          />
        )}

        <PickerPopper
          theme={theme}
          open={Boolean(open)}
          anchorEl={elementRef.current}
          className={clsx('picker-popper', {
            'picker-popper-mobile': isMobile,
          })}
          // placement={'bottom-end'}
          transition
          disablePortal={false}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            const target = event.target as any; // as HTMLInputElement;

            if (event.key === 'Enter') {
              if (!!target && target.nodeName === 'INPUT') {
                // if (!validationError || validationError === '') {
                //   handleSave();
                // }
              } else {
                if (!validationError || validationError === '') {
                  handleSave();
                } else {
                  handleClose();
                }
              }
            }
          }}
          // onBlur={event => {
          //   console.log('blur event', event.target);
          // }}
          // modifiers={{
          //   keepTogether: { enabled: true },
          //   flip: { enabled: false },
          //   preventOverflow: {
          //     enabled: true,
          //     boundariesElement: 'viewport',
          //   },
          //   // offset: {
          //   //   offset: '-15,0',
          //   // },
          // }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={33}>
              <Paper
                className={clsx('picker-paper', {
                  'picker-paper-mobile': isMobile,
                })}
              >
                <div
                  className={clsx('picker-header', {
                    'picker-header-mobile': isMobile,
                  })}
                >
                  {closablePicker && (
                    <IconButton
                      size="xs"
                      aria-label="Close"
                      onClick={event => {
                        handleClose();
                      }}
                      disabled={!!validationError && validationError !== ''}
                      className={'absolute-close-button'}
                    >
                      {!!closeTitle ? (
                        <Tooltip title={closeTitle}>
                          <span>
                            <Icon
                              icon="times"
                              color={
                                !!validationError && validationError !== ''
                                  ? 'danger'
                                  : 'default'
                              }
                              pointerEvents="none"
                              colorExtend="textHover"
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Icon
                          icon="times"
                          color={
                            !!validationError && validationError !== ''
                              ? 'danger'
                              : 'default'
                          }
                          pointerEvents="none"
                          colorExtend="textHover"
                        />
                      )}
                    </IconButton>
                  )}
                  <div
                    className={clsx('picker-header-title', {
                      'picker-header-title-mobile': isMobile,
                    })}
                  ></div>
                </div>
                <div
                  className={clsx('picker-content', {
                    'picker-content-mobile': isMobile,
                  })}
                >
                  {variant === 'time' ? (
                    <React.Fragment>
                      {useTimeClockPicker ? (
                        <TimePicker
                          value={dateValue}
                          disableToolbar
                          variant={'static'}
                          onChange={timeValue => {
                            if (timeValue !== null) {
                              // let validateDate = dateUtils.convertToMeridiem(
                              //   timeValue,
                              //   dateUtils.getMeridiem(timeValue),
                              //   useAmPm ?? false,
                              // );
                              let error = validateTime(
                                timeValue.getHours(),
                                timeValue.getMinutes(),
                                {
                                  maxDate: props.maxDate,
                                  minDate: props.minDate,
                                  disablePast: props.disablePast,
                                  disableFuture: props.disableFuture,
                                  maxDateMessage: maxDateMessage,
                                  minDateMessage: minDateMessage,
                                  invalidDateMessage: invalidDateMessage,
                                  strictCompareDates: props.strictCompareDates,
                                },
                                utils,
                                timeValue,
                                useAmPm ?? false,
                              );
                              if (!!error) {
                                onError(error, validateDate);
                              } else {
                                setDateValue(timeValue);
                                if (saveOnSelect) {
                                  onChange(
                                    setInputViewDate(
                                      variant,
                                      timeValue,
                                      endTime,
                                      truncateTime,
                                    ),
                                  );
                                  setOpen(false);
                                  onClose?.();
                                }
                              }
                            } else {
                              setDateValue(timeValue);
                              if (saveOnSelect) {
                                onChange(
                                  setInputViewDate(
                                    variant,
                                    timeValue,
                                    endTime,
                                    truncateTime,
                                  ),
                                );
                                setOpen(false);
                                onClose?.();
                              }
                            }
                          }}
                          ampm={useAmPm}
                        />
                      ) : (
                        <TimeOptionPicker
                          value={dateValue}
                          id={`inner-${dateInputProps.id ?? 'dateInputId'}`}
                          name={`inner-${
                            dateInputProps.name ?? 'dateInputName'
                          }`}
                          label={dateInputProps.label}
                          inputVariant={'standard'}
                          onChange={(val, withCompletion) => {
                            setDateValue(val);
                            if (withCompletion) {
                              if (!validationError || validationError === '') {
                                onChange(
                                  setInputViewDate(
                                    variant,
                                    val,
                                    endTime,
                                    truncateTime,
                                  ),
                                );
                                setOpen(false);
                                onClose?.();
                              }
                            }
                          }}
                          onBlur={props.onBlur}
                          error={props.error}
                          onError={onError}
                          fullWidth={props.fullWidth || true}
                          helperText={props.helperText}
                          disabled={props.disabled}
                          placeholder={props.placeholder}
                          required={props.required}
                          size={props.size}
                          maxDateMessage={maxDateMessage}
                          minDateMessage={minDateMessage}
                          invalidDateMessage={invalidDateMessage}
                          strictCompareDates={true}
                          // dateId={`inner-date-${props.dateId ?? 'dateId'}`}
                          // timeId={`inner-time-${props.timeId ?? 'timeId'}`}
                          // dateName={`inner-date-${props.dateName ?? 'dateName'}`}
                          // timeName={`inner-time-${props.timeName ?? 'timeName'}`}
                          // dateInputProps={props.dateInputProps}
                          // timeInputProps={props.timeInputProps}
                          autoFocus={true}
                          ampm={useAmPm}
                          minutesStep={props.minutesStep}
                          {...other}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <DatePicker
                      variant="static"
                      disableToolbar
                      value={dateValue}
                      // onError={onError}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                      disableFuture={props.disableFuture}
                      disablePast={props.disablePast}
                      onChange={dateVal => {
                        if (dateVal !== null) {
                          let error = validateDate(
                            dateVal.getDate(),
                            dateVal.getMonth(),
                            dateVal.getFullYear(),
                            {
                              maxDate: props.maxDate,
                              minDate: props.minDate,
                              disablePast: props.disablePast,
                              disableFuture: props.disableFuture,
                              maxDateMessage: maxDateMessage,
                              minDateMessage: minDateMessage,
                              invalidDateMessage: invalidDateMessage,
                              strictCompareDates: props.strictCompareDates,
                            },
                            utils,
                            dateVal,
                          );
                          if (!!error) {
                            onError(error, dateVal);
                          } else {
                            setDateValue(dateVal);
                            if (saveOnSelect) {
                              onChange(
                                setInputViewDate(
                                  variant,
                                  dateVal,
                                  endTime,
                                  truncateTime,
                                ),
                              );
                              setOpen(false);
                              onClose?.();
                            }
                          }
                        } else {
                          setDateValue(dateVal);
                          if (saveOnSelect) {
                            onChange(
                              setInputViewDate(
                                variant,
                                dateVal,
                                endTime,
                                truncateTime,
                              ),
                            );
                            setOpen(false);
                            onClose?.();
                          }
                        }
                      }}
                    />
                  )}
                  {((variant === 'time' && useTimeDropDowns) ||
                    (variant === 'date' && useDateDropDowns)) && (
                    <div className={'picker-content-date'}>
                      {variant === 'time' && useTimeDropDowns && (
                        <TimeDropDown
                          value={dateValue}
                          id={`inner-${dateInputProps.id ?? 'dateInputId'}`}
                          name={`inner-${
                            dateInputProps.name ?? 'dateInputName'
                          }`}
                          label={dateInputProps.label}
                          inputVariant={'standard'}
                          onChange={(val, withCompletion) => {
                            setDateValue(val);
                            if (withCompletion) {
                              if (!validationError || validationError === '') {
                                onChange(
                                  setInputViewDate(
                                    variant,
                                    val,
                                    endTime,
                                    truncateTime,
                                  ),
                                );
                                setOpen(false);
                                onClose?.();
                              }
                            }
                          }}
                          onBlur={props.onBlur}
                          error={props.error}
                          onError={onError}
                          fullWidth={props.fullWidth || true}
                          helperText={props.helperText}
                          disabled={props.disabled}
                          placeholder={props.placeholder}
                          required={props.required}
                          size={props.size}
                          maxDateMessage={maxDateMessage}
                          minDateMessage={minDateMessage}
                          invalidDateMessage={invalidDateMessage}
                          // dateId={`inner-date-${props.dateId ?? 'dateId'}`}
                          // timeId={`inner-time-${props.timeId ?? 'timeId'}`}
                          // dateName={`inner-date-${props.dateName ?? 'dateName'}`}
                          // timeName={`inner-time-${props.timeName ?? 'timeName'}`}
                          // dateInputProps={props.dateInputProps}
                          // timeInputProps={props.timeInputProps}
                          autoFocus={true}
                          ampm={useAmPm}
                          minutesStep={props.minutesStep}
                          {...other}
                        />
                      )}
                      {variant === 'date' && useDateDropDowns && (
                        <DateDropDown
                          value={dateValue}
                          id={`inner-${dateInputProps.id ?? 'dateInputId'}`}
                          name={`inner-${
                            dateInputProps.name ?? 'dateInputName'
                          }`}
                          label={dateInputProps.label}
                          inputVariant={'standard'}
                          onChange={(val, withCompletion) => {
                            setDateValue(val);
                            if (withCompletion) {
                              if (!validationError || validationError === '') {
                                onChange(
                                  setInputViewDate(
                                    variant,
                                    val,
                                    endTime,
                                    truncateTime,
                                  ),
                                );
                                setOpen(false);
                                onClose?.();
                              }
                            }
                          }}
                          onBlur={props.onBlur}
                          error={props.error}
                          onError={onError}
                          fullWidth={props.fullWidth || true}
                          helperText={props.helperText}
                          disabled={props.disabled}
                          placeholder={props.placeholder}
                          required={props.required}
                          size={props.size}
                          maxDateMessage={maxDateMessage}
                          minDateMessage={minDateMessage}
                          invalidDateMessage={invalidDateMessage}
                          // dateId={`inner-date-${props.dateId ?? 'dateId'}`}
                          // timeId={`inner-time-${props.timeId ?? 'timeId'}`}
                          // dateName={`inner-date-${props.dateName ?? 'dateName'}`}
                          // timeName={`inner-time-${props.timeName ?? 'timeName'}`}
                          // dateInputProps={props.dateInputProps}
                          // timeInputProps={props.timeInputProps}
                          autoFocus={true}
                          {...other}
                        />
                      )}
                    </div>
                  )}

                  <div className={'picker-buttons'}>
                    <Button
                      size="small"
                      disabled={!!validationError && validationError !== ''}
                      onClick={ev => {
                        if (!validationError || validationError === '') {
                          handleSave();
                        }
                      }}
                    >
                      {t(translations.Save)}
                    </Button>
                    <Button
                      size="small"
                      variant="white"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {t(translations.Cancel)}
                    </Button>
                    {(clearable || isMobile || disableClearable) && (
                      <Button
                        size="small"
                        variant="accent"
                        className={'clearValue'}
                        onClick={() => {
                          setDateValue(null);
                          onChange(null);
                          setOpen(false);
                          onClose?.();
                        }}
                      >
                        {t(translations.Clear)}
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            </Fade>
          )}
        </PickerPopper>
      </StyledPicker>
    </ClickAwayListener>
  );
}
