import { GetBooleanFilterOptions } from 'app/components/BooleanPicker';
import { ALL_PERIOD_TYPES, PeriodTypes } from 'app/components/DatePeriods';
import startOfDay from 'date-fns/startOfDay';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Entity, Identifiable } from 'types/common';
import { CustomDate, DateType } from 'types/CustomDate';
import { INumbersRange } from 'types/INumbersRange';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { dateUtils } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { tryParseInt } from 'utils/string-utils';
import { isNullOrUndefined, valOrNull } from 'utils/typeUtils';
import { FilterValueType } from '../BasicFilter/FilterValueType';
import {
  FilterIntializer,
  FilterSerializer,
} from '../BasicFilter/IFilterSettings';
import { AssetRangeType, isAssetRangeType } from '../Filters/AssetRangeFilter';

export interface FilterParam {
  Name: string;
  Value?: FilterValueType;
}

/**
 * Case Insensitive URLSearchParams
 */
export class URLSearchParamsCI extends URLSearchParams {
  constructor(value?: string | URLSearchParams) {
    super();

    const params = new URLSearchParams(value);
    for (const [name, value] of params) {
      this.append(name.toLowerCase(), value);
    }
  }
  public get(name: string): string | null {
    return super.get(name.toLowerCase());
  }
  public set(name: string, value: string | undefined) {
    this.delete(name.toLowerCase());
    if (value !== undefined) {
      super.set(name, value);
    }
  }

  public toObject() {
    const result = {};
    for (const key of this.keys()) {
      result[key] = this.get(key);
    }
    return result;
  }

  public equals(other: URLSearchParams): boolean {
    const b = new URLSearchParamsCI(other);
    return this.toString() === b.toString();
  }
}

export function GetRawValue(params: URLSearchParams, fieldName: string) {
  var value = params.get(fieldName);
  if (value === null) {
    return undefined;
  }
  if (value === '') {
    return null;
  }
  return setisInversedForUrl(value, false);
}

export const isInversedPrefix = 'not;';

export function isFilterInversed(val: string | null | undefined) {
  if ((val ?? '') === '') {
    return false;
  }
  return val!.startsWith(isInversedPrefix);
}

export function findIsfilterInversed(search: string, key: string | undefined) {
  if (key === undefined) return undefined;
  const params = new URLSearchParams(search);
  const val = params.get(key);
  return isFilterInversed(val);
}

export function setisInversedForUrl<
  T extends string | null | undefined = string | null | undefined
>(val: T, isInversed: boolean) {
  if ((val ?? '') === '') return val;
  const definedVal = val!;
  const hasprefix = definedVal.startsWith(isInversedPrefix);
  if (isInversed && !hasprefix) {
    return isInversedPrefix + definedVal;
  }
  if (!isInversed && hasprefix) {
    return definedVal.substring(isInversedPrefix.length);
  }
  return definedVal;
}

/**
 *
 * @param rawValue
 * @returns
 */
export function GetNumberOrUndefined(rawValue: string | undefined | null) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return null;
  }
  var number = parseInt(rawValue);
  if (isNaN(number)) {
    return undefined;
  }
  return number;
}
export function GetStringOrUndefined(rawValue: string | undefined | null) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return null;
  }
  if (rawValue === '') {
    return null;
  }
  return rawValue;
}
export function getStringUnionOrUndefined<TUnion extends string>(
  rawValue: string | undefined | null,
  unionArray: Array<TUnion>,
) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return null;
  }
  if (rawValue === '') {
    return null;
  }
  return unionArray.find(
    f => f.toLocaleLowerCase() === rawValue?.toLowerCase(),
  );
}

export function getStringUnionArrayOrUndefined<TUnion extends string>(
  rawValue: string | undefined | null,
  unionArray: Array<TUnion>,
) {
  if (isNullOrUndefined(rawValue)) return rawValue;
  const values = splitFilterValue(rawValue)
    .map(v => getStringUnionOrUndefined(v, unionArray))
    .filter(v => !isNullOrUndefined(v));
  if (!values.length) return undefined;
  return values as Array<TUnion>;
}
export function GetBooleanOrUndefined(rawValue: string | undefined | null) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return null;
  }
  const value = rawValue.toLowerCase() === 'true';
  return value;
}
export function GetDateOrUndefined(
  rawValue: string | undefined | null,
  type?: DateType,
) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return null;
  }
  const date = dateUtils.parseQueryStringDate(rawValue);
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return new CustomDate(date, type ?? 'complete');
}

export function GetPartialEntity<T extends number | string | boolean>(
  id: T | undefined | null,
): Identifiable<T> | undefined | null {
  if (id === undefined) {
    return undefined;
  }
  if (id === null) {
    return null;
  }
  return { Id: id };
}
export function GetEnum(
  value: string | null | undefined,
  enumObject: object,
  t: TFunction,
) {
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }
  var options = enumToEntityArray(enumObject, t);
  var numValue = tryParseInt(value);
  if (numValue === undefined) {
    return null;
  }
  if (isNaN(numValue)) {
    return null;
  }
  var item = options.find(f => f.Id === numValue);

  return item ?? null;
}
export function GetBooleanEntity(
  value: boolean | undefined | null,
  t: TFunction,
): Entity<boolean> | undefined | null {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Id: value,
    Name: t(value === true ? translations.Yes : translations.No),
  };
}

export const initPickerValue = <T extends string | number | boolean>(
  f: (value: string) => Promise<Entity<T>[]>,
  additionalOptions?: Array<Entity<T>>,
): FilterIntializer => {
  return async (item: FilterValueType) => {
    const x = item as Identifiable<T>;
    const id = x.Id;
    // if some "addionalItem" are injected through the filter - first try to look the entity there
    // for example, the "all my managed servcies" - with the magic -999 id
    const additionalItem = additionalOptions?.find(option => option.Id === id);
    if (additionalItem !== undefined) {
      return Promise.resolve(additionalItem);
    }
    const failureResult: Entity<T> = { Id: id, Name: 'N/A' };
    if (item === null || (!!x && x.Id === '')) {
      return Promise.resolve(failureResult);
    }
    try {
      const data: any = await f(id.toString());
      const verifiedData = !!data.value ? data.value : data;
      const name =
        verifiedData.length > 0
          ? (verifiedData[0] as Entity<T>)
          : failureResult;
      return name;
    } catch {
      return failureResult;
    }
  };
};
export const initExPickerValue = <T extends string | number | boolean>(
  f: (
    value: string,
    sourceUrl?: any,
    withoutServiceGroups?: boolean,
  ) => Promise<Entity<T>[]>,
  additionalOptions?: Array<Entity<T>>,
  sourceUrl?: any,
  withoutServiceGroups?: boolean,
) => {
  return async (item: FilterValueType) => {
    const x = item as Identifiable<T>;
    const id = x.Id;
    // if some "addionalItem" are injected through the filter - first try to look the entity there
    // for example, the "all my managed servcies" - with the magic -999 id
    const additionalItem = additionalOptions?.find(option => option.Id === id);
    if (additionalItem !== undefined) {
      return Promise.resolve(additionalItem);
    }
    const failureResult: Entity<T> = { Id: id, Name: 'N/A' };
    if (item === null || (!!x && x.Id === '')) {
      return Promise.resolve(failureResult);
    }
    try {
      const data: any = await f(id.toString(), sourceUrl, withoutServiceGroups);
      const verifiedData = !!data.value ? data.value : data;
      const name =
        verifiedData.length > 0
          ? (verifiedData[0] as Entity<T>)
          : failureResult;
      return name;
    } catch {
      return failureResult;
    }
  };
};

export const initMultiExPickerValue = <T extends string | number | boolean>(
  f: (
    value: string | undefined,
    sourceUrl?: any,
    separator?: string,
    serviceGroups?: Array<Identifiable<number>>,
  ) => Promise<Entity<T>[]>,
  additionalOptions?: Entity<T>[],
  sourceUrl?: any,
  separator?: string,
): FilterIntializer => {
  return async (
    item: FilterValueType,
    serviceGroups?: Array<Identifiable<number>>,
  ) => {
    const x = item as Identifiable<T>[];
    const ids = x.map(v => v.Id);
    const additionalItems = additionalOptions?.filter(option =>
      ids.includes(option.Id),
    );
    if (additionalItems && additionalItems.length) {
      return additionalItems;
    }
    const failureResult: Entity<T>[] = [];
    if (item === null || (!!x && !x.length) || ids.every(v => !v || v === '')) {
      return failureResult;
    }
    try {
      const data: Entity<T>[] = await f(
        ids.join(separator || '|'),
        sourceUrl,
        separator || '|',
        serviceGroups,
      );
      return data.length > 0 ? data : failureResult;
    } catch {
      return failureResult;
    }
  };
};

export const initMultiPickerValue = <T extends string | number>(
  f: (value: string) => Promise<Entity<T>[]>,
  additionalOptions?: Entity<T>[],
) => {
  return async (item: FilterValueType) => {
    const x = item as Identifiable<T>[];
    const ids = x.map(v => v.Id);
    const additionalItems = additionalOptions?.filter(option =>
      ids.includes(option.Id),
    );
    if (additionalItems && additionalItems.length) {
      return Promise.resolve(additionalItems);
    }
    const failureResult: Entity<T>[] = [];
    if (
      item === null ||
      (!!x && !x.length) ||
      ids.every(v => v === undefined || v === null || v === '')
    ) {
      return Promise.resolve(failureResult);
    }
    try {
      const data = await f(ids.join('|'));
      return data.length > 0 ? data : failureResult;
    } catch {
      return failureResult;
    }
  };
};

export function getFilterParams(search: string): FilterParam[] {
  let result = [] as FilterParam[];
  const params = new URLSearchParams(search);
  params.forEach((value, key) => {
    if (value !== '' && value !== undefined)
      result.push({ Name: key, Value: value });
  });
  return result;
}

export function getDefaultFilterValue(
  name: string,
  params: FilterParam[],
): string | undefined {
  const f = params.find(item => item.Name === name && item.Value !== '');
  if (f !== undefined) {
    return f.Value as string;
  } else return undefined;
}

export function getDefaultFilterBool(
  name: string,
  params: FilterParam[],
  t: TFunction,
): Entity<boolean> | null {
  const f = params.find(item => item.Name === name && item.Value !== '');
  const value = f?.Value;
  const options = GetBooleanFilterOptions(t);

  return options.find(item => item.Id.toString() === value) ?? null;
}

export function getDefaultFilterDate(
  name: string,
  params: FilterParam[],
): Date | null {
  const st = getDefaultFilterValue(name, params);
  if (st === undefined) {
    return null;
  }
  const date = dateUtils.parseQueryStringDate(st);
  if (date === null) {
    return null;
  }
  return startOfDay(date);
}
export const getPeriodTypeNameOrUndefined = (s: string | null | undefined) => {
  if (s === undefined) {
    return undefined;
  }
  const lowered = s?.toLowerCase();
  const namedValue: PeriodTypes | undefined = ALL_PERIOD_TYPES.find(
    f => f.toLowerCase() === lowered,
  );
  if (namedValue !== undefined) {
    return namedValue;
  }

  const num = GetNumberOrUndefined(s);
  if (num === null || num === undefined) {
    return null;
  }
  if (isNaN(num)) {
    return null;
  }

  if (num >= 0 && num < ALL_PERIOD_TYPES.length) {
    const numberedValue = ALL_PERIOD_TYPES[num];
    return numberedValue;
  }

  return namedValue;
};

export function getDefaultFilterDatePeriod(
  periodTypeFieldName: string,
  customStartFieldName: string,
  customEndFieldName: string,
  params: URLSearchParams,
): PresetDatesRange | undefined | null {
  let periodType = getPeriodTypeNameOrUndefined(
    GetRawValue(params, periodTypeFieldName),
  );

  const start = GetDateOrUndefined(
    GetRawValue(params, customStartFieldName),
    'complete',
  );
  const end = GetDateOrUndefined(
    GetRawValue(params, customEndFieldName),
    'complete',
  );

  // set the periodType to CustomDate if either start or end date were provided
  // this intended to handle cases where a link to the page is not fully constructed
  // e.g. /sompage?start=xxx will be translated to period type=CustomDate, start=xxx
  if (isNullOrUndefined(periodType)) {
    if (!isNullOrUndefined(start) || !isNullOrUndefined(end)) {
      periodType = 'CustomDate';
    }
  }

  if (periodType === undefined || periodType === null) {
    return periodType;
  }

  if (periodType === 'CustomDate') {
    return PresetDatesRange.Custom(start, end);
  } else {
    return PresetDatesRange.fromType(periodType, null, null);
  }
}

export const presetDatesRangeToQueryString = (
  periodTypeFieldName,
  customStartFieldName,
  customEndFieldName,
) => (value: FilterValueType | undefined) => {
  var result = new URLSearchParams();

  if (value === null) {
    result.append(periodTypeFieldName, '');
    return result;
  }
  if (value !== null && value !== undefined) {
    const type = (value as PresetDatesRange).type?.Id;
    const id = (value as PresetDatesRange).type?.Id;

    if (id !== undefined) {
      result.append(periodTypeFieldName, id.toString());
      if (type === 'CustomDate') {
        var start = (value as PresetDatesRange).start;
        if (start !== null && start !== undefined) {
          result.append(
            customStartFieldName,
            dateUtils.formatQueryStringDate(start, {
              representation:
                (start as CustomDate)?.representation ?? 'complete',
            }),
          );
        }
        var end = (value as PresetDatesRange).end;
        if (end !== null && end !== undefined) {
          result.append(
            customEndFieldName,
            dateUtils.formatQueryStringDate(end, {
              representation:
                (start as CustomDate)?.representation ?? 'complete',
            }),
          );
        }
      }
    }
  }
  return result;
};

export const getDefaultFilterNumberPeriod = (
  fieldName: string,
  params: URLSearchParams,
): INumbersRange | undefined | null => {
  const start = GetNumberOrUndefined(GetRawValue(params, `${fieldName}-min`));
  const end = GetNumberOrUndefined(GetRawValue(params, `${fieldName}-max`));
  if (start === undefined && end === undefined) {
    return undefined;
  } else if (start === null && end === null) {
    return null;
  } else {
    return {
      start: start ?? null,
      end: end ?? null,
    };
  }
};
export const NumebersRangeToQueryString = fieldName => (
  value: FilterValueType | undefined,
) => {
  var result = new URLSearchParams();
  const startFieldName = `${fieldName}-min`;
  const endFieldName = `${fieldName}-max`;
  if (value === null) {
    result.append(startFieldName, '');
    result.append(endFieldName, '');
    return result;
  }
  if (value !== null && value !== undefined) {
    const start = (value as INumbersRange)?.start;
    const end = (value as INumbersRange)?.end;

    result.append(startFieldName, start?.toString() ?? '');
    result.append(endFieldName, end?.toString() ?? '');
  }
  return result;
};
export const formatQueryStringDateValue: (
  fieldName: string,
) => FilterSerializer = (fieldName: string) => (
  value: FilterValueType | undefined | null,
) => {
  var result = new URLSearchParams();
  if (value === null) {
    result.set(fieldName, '');
  }
  if (
    value !== undefined &&
    value !== null &&
    !isNaN((value as Date)?.getTime?.())
  ) {
    result.set(
      fieldName,
      dateUtils.formatQueryStringDate(value as Date, {
        representation: 'date',
      }),
    );
  }
  return result;
};
function serializeValue(value: FilterValueType): string | undefined | null {
  try {
    if (value === null) {
      return null;
    }
    return typeof value === 'string'
      ? value
      : typeof value === 'number'
      ? value?.toString()
      : value instanceof CustomDate
      ? dateUtils.formatQueryStringDate(value, {
          representation: (value as CustomDate).representation ?? 'date',
        })
      : value instanceof Date
      ? dateUtils.formatQueryStringDate(value)
      : (value as Entity<number>)?.Id?.toString();
  } catch {
    return undefined;
  }
}

export const defaultSerializer: (
  fieldName: string,
) => FilterSerializer = fieldName => value => {
  var result = new URLSearchParams();
  if (value === null) {
    result.set(fieldName, '');
  }
  if (value !== undefined) {
    var stringValue = serializeValue(value);
    if (stringValue !== null && stringValue !== undefined) {
      result.set(fieldName, stringValue);
    }
  }
  return result;
};

export const arrayToQueryString: (
  fieldName: string,
) => FilterSerializer = fieldName => value => {
  let result = new URLSearchParams();
  if (Array.isArray(value) || value == null) {
    let stringValues = value
      ?.map(v => serializeValue(v))
      .filter(v => v !== undefined);
    result.set(fieldName, stringValues?.join('|') ?? '');
  }
  return result;
};

export const arrayByNameEntityNumberToQueryString: (
  fieldName: string,
) => FilterSerializer = fieldName => value => {
  let result = new URLSearchParams();
  if (Array.isArray(value) || value == null) {
    let stringValues = value
      ?.map(v => (v as Entity<number>)?.Name?.toString())
      .filter(v => v !== undefined);
    result.set(fieldName, stringValues?.join('|') ?? '');
  }
  return result;
};
export const getStringEntityArray = (
  params: URLSearchParams,
  fieldName: string,
): FilterValueType | undefined => {
  let search = GetRawValue(params, fieldName);
  if (search === undefined) return undefined;
  if (search === null) return null;
  let allSearch = splitFilterValue(search).map(v => GetStringOrUndefined(v));
  allSearch = allSearch.filter(v => v !== undefined);
  if (!allSearch.length) return undefined;
  let allEntities = allSearch.map(v => GetPartialEntity(v));
  allEntities = allEntities.filter(v => v !== undefined);
  if (!allEntities.length) return undefined;
  return allEntities as FilterValueType;
};

export const getNumberEntityArray = (
  params: URLSearchParams,
  fieldName: string,
): FilterValueType | undefined => {
  let search = GetRawValue(params, fieldName);
  return getNumberEntityArrayFromString(search) as FilterValueType;
};
export const getNumberEntityArrayFromString = (
  search: string | undefined | null,
) => {
  if (search === undefined) return undefined;
  if (search === null) return null;
  let allSearch = splitFilterValue(search).map(v => GetNumberOrUndefined(v));
  allSearch = allSearch.filter(v => v !== undefined);
  if (!allSearch.length) return undefined;
  let allEntities = allSearch.map(v => GetPartialEntity(v));
  allEntities = allEntities.filter(v => v !== undefined);
  if (!allEntities.length) return undefined;
  return allEntities;
};

export const assetRangeSerializer: (fieldName: string) => FilterSerializer = (
  fieldname: string,
) => value => {
  const res = new URLSearchParams();
  if (isAssetRangeType(value)) {
    res.set(fieldname + 'Val', serializeValue(value.value) ?? '');
    res.set(fieldname + 'Type', serializeValue(value.unitType) ?? '');
  }
  return res;
};

export const getAssetRange = (
  params: URLSearchParams,
  fieldName: string,
): FilterValueType | undefined => {
  const val = GetRawValue(params, fieldName + 'Val');
  const type = GetRawValue(params, fieldName + 'Type');
  if (
    valOrNull(val) === null ||
    isNaN(parseFloat(val ?? '')) ||
    valOrNull(type) === null ||
    isNaN(parseInt(type ?? ''))
  ) {
    return undefined;
  }
  return {
    unitType: { Id: parseInt(type ?? '') },
    value: parseFloat(val ?? ''),
  } as AssetRangeType;
};
export const getSplitNumberArray = (search: string): number[] => {
  return splitFilterValue(search)
    .map(v => {
      var number = parseInt(v);
      if (isNaN(number)) {
        return 0;
      }
      return number;
    })
    .filter(f => f > 0);
};
export const splitFilterValue = (search: string, separator?: string) => {
  let x = search.indexOf(separator ?? '|') >= 0 ? separator ?? '|' : ',';
  let allSearch = search.split(x);
  return allSearch;
};
export const joinArray = (values: Array<any>, separator?: string) => {
  return values.join(separator ?? '|');
};
