import { Link } from '@material-ui/core';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { Comments } from 'app/components/Comments';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Renderer } from 'react-table';
import { IInvoiceBatchesDto } from './IInvoiceBatchesDto';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { intersection } from 'lodash';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
export interface InvoiceBatchesCommentsProps {
  batchId: number;
}

export const InvoiceBatchesComments = (props: InvoiceBatchesCommentsProps) => {
  const { t } = useTranslation();
  const User: AuthenticatedUser | undefined = useSelector(
    selectAuthenticatedUser,
  );

  //PERMISSIONS
  const roles: number[] | undefined = User?.Roles;

  const allowedRoles: Roles[] = [
    Roles.Administrators,
    Roles.GroupAdministrators,
    Roles.FinAdmin,
    Roles.CustomRole,
  ];

  const adminRoles: Roles[] = [Roles.Administrators, Roles.GroupAdministrators];

  const isAdmin: boolean = intersection(roles, adminRoles).length > 0;
  const hasPermissions: boolean = intersection(roles, allowedRoles).length > 0;
  const { onCommentAdded } = useUploadToNewComment();
  return (
    <PageWrapper
      disableExpandToggle
      titlePage={`Batch #${props.batchId} ${
        t(translations.CommentsCount) as string
      }`}
      useSidePanel
      closable
    >
      <Comments
        isAdmin={isAdmin}
        cannotComment={!hasPermissions}
        commentType={CommentReferenceTypes.batches}
        referenceId={props.batchId}
        onCommentAdded={onCommentAdded}
      />
    </PageWrapper>
  );
};

export const InvoiceBatchesCommentsRenderer: Renderer<IInvoiceBatchesDto> = ({
  value,
  row: { original },
}) => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const User: AuthenticatedUser | undefined = useSelector(
    selectAuthenticatedUser,
  );

  //PERMISSIONS
  const roles: number[] | undefined = User?.Roles;

  const allowedRoles: Roles[] = [
    Roles.Administrators,
    Roles.GroupAdministrators,
    Roles.FinAdmin,
    Roles.CustomRole,
  ];
  const hasPermissions: boolean = intersection(roles, allowedRoles).length > 0;
  const onClick = () => {
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.InvoiceBatchesComments,
        props: { batchId: original.Id },
      }),
    );
  };
  if (!hasPermissions) return <>{value}</>;
  return <Link onClick={onClick}>{value}</Link>;
};
