import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { ReservationsApi } from 'api/ReservationsApi';
import { initEquipmentsData } from 'app/components/pickers/MultiSelectPickers/EquipmentReservationPicker';
import { Entity } from 'types/common';
import * as React from 'react';
import {
  IMandatoryEquipmentsDto,
  IReservationEquipmentDto,
  PrerequisitsResult,
  ReservationDetailsState,
  ReservationSettingsState,
  TrainingSessionResult,
} from '../slice/types';
import { IndexNode } from 'app/components/Forms/FormsLayout/StyledForm';
import { Box, Link as MuiLink } from '@material-ui/core';
import { Caption } from 'app/components/Typography';
import { Body } from 'app/components/Typography';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { BulletedList } from 'app/components/BasicList/StyledList';
import { Button } from 'app/components/BasicButtons/Button';
import { dateUtils } from 'utils/date-utils';

export const staffUserChangeHandler = (
  values: ReservationDetailsState,
  isEdit: boolean,
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  equipmentsChangeHandler: (
    values: ReservationDetailsState,
    changeStatus?: boolean | undefined,
    selectedEqs?: IReservationEquipmentDto[],
  ) => void,
  extendStaffMandatoryEquipment: (
    staff: IReservationEquipmentDto | null,
  ) => void,
  extendMandatoryEquipments: (mandatories: IMandatoryEquipmentsDto[]) => void,
  initStaffReservation: (
    staffEquipmentId: number,
    values: ReservationDetailsState,
  ) => void,
  setStaffEquipmentRestricted: (staffUser: string) => void,
  reservationSettings?: ReservationSettingsState,
) => {
  let staffEquipmentId =
    values.StaffUser !== null
      ? (values.StaffUser as IUserFilterDto).StaffEquipmentId
      : null;
  if (staffEquipmentId !== null) {
    let allEquipments = [
      ...(values.EquipmentsData as IReservationEquipmentDto[]),
      ...((reservationSettings?.MandatoriesEquipments ||
        []) as Entity<number>[] as IReservationEquipmentDto[]),
      ...(values.OptionalsEquipments.filter(
        f => f.checked === true,
      ) as Entity<number>[] as IReservationEquipmentDto[]),
    ];
    if (!allEquipments.some(f => f.Id === staffEquipmentId)) {
      if (isEdit) {
        (async () => {
          try {
            let setEquipments: Entity<number>[] = [];
            let data = await initEquipmentsData(
              staffEquipmentId.toString(),
              'AccServices,OfflineServices,StaffUserSelection,MandatoriesEquipments,OptionalsEquipments',
            );
            if (data.length > 0) {
              let staffEquipment = data[0];
              if (
                !!reservationSettings &&
                reservationSettings.StaffMandatoryEquipment !== null
              ) {
                setEquipments = [
                  ...values.EquipmentsData.filter(
                    f =>
                      f.Id !== reservationSettings.StaffMandatoryEquipment?.Id,
                  ),
                  Object.assign({}, staffEquipment, {
                    noremoved: true,
                    titleInfo: `Mandatory instrument for ${values.StaffUser?.Name}`,
                  }),
                ];
              } else {
                setEquipments = [
                  ...values.EquipmentsData,
                  Object.assign({}, staffEquipment, {
                    noremoved: true,
                    titleInfo: `Mandatory instrument for ${values.StaffUser?.Name}`,
                  }),
                ];
              }
              setValue('EquipmentsData', setEquipments);
              extendStaffMandatoryEquipment(
                staffEquipment as IReservationEquipmentDto,
              );
              equipmentsChangeHandler(
                Object.assign({}, values, {
                  EquipmentsData: setEquipments,
                }),
                true,
              );
            } else {
              setStaffEquipmentRestricted(values.StaffUser?.Name ?? '');
              setValue('StaffUser', null);
            }
          } catch {}
        })();
      } else {
        (async () => {
          try {
            let notRestricted = await ReservationsApi.userCanBookEquipment(
              staffEquipmentId,
            );

            if (notRestricted.value === true) {
              initStaffReservation(staffEquipmentId, values);
            } else {
              setStaffEquipmentRestricted(values.StaffUser?.Name ?? '');
              setValue('StaffUser', null);
            }
          } catch {}
        })();
      }
    }
  } else {
    if (
      !!reservationSettings &&
      reservationSettings.StaffMandatoryEquipment !== null
    ) {
      if (isEdit) {
        let setEquipments = values.EquipmentsData.filter(
          f => f.Id !== reservationSettings.StaffMandatoryEquipment?.Id,
        );
        setValue('EquipmentsData', setEquipments);
        equipmentsChangeHandler(
          Object.assign({}, values, { EquipmentsData: setEquipments }),
          true,
        );
      } else {
        if (reservationSettings.MandatoriesEquipments.length > 0) {
          let mand = reservationSettings.MandatoriesEquipments.filter(
            f => f.Id !== reservationSettings.StaffMandatoryEquipment?.Id,
          );
          let alleqs = [
            ...(values.EquipmentsData as IReservationEquipmentDto[]),
            ...(mand as Entity<number>[] as IReservationEquipmentDto[]),
            ...(values.OptionalsEquipments.filter(
              f => f.checked === true,
            ) as Entity<number>[] as IReservationEquipmentDto[]),
          ];
          extendMandatoryEquipments(mand);
          equipmentsChangeHandler(values, true, alleqs);
        }
      }
      extendStaffMandatoryEquipment(null);
    }
  }
};
export const prerquisitErrorsHandler = (
  data: PrerequisitsResult,
  equipments: Entity<number>[],
  setPrerequisitErrors: (items: IndexNode[]) => void,
  onEquipmentClicked?: (equipment: Entity<number>) => void,
  onRestrictionsClick?: (equipment: Entity<number>) => void,
) => {
  setPrerequisitErrors(
    data.PrerequisiteTrainings.length > 0
      ? [
          {
            index: 'prerequisiteIndex',
            node: (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <Caption>{data.ErrorMessage}</Caption>
                {data.PrerequisiteTrainings.map(pre => (
                  <Caption key={pre.Id}>
                    {' '}
                    <MuiLink
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        !!onEquipmentClicked && onEquipmentClicked(pre)
                      }
                    >
                      {pre.Name}
                    </MuiLink>
                  </Caption>
                ))}
                {equipments.length === 1 ? (
                  <Caption>
                    <MuiLink
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      onClick={() =>
                        !!onRestrictionsClick &&
                        onRestrictionsClick(equipments[0])
                      }
                    >
                      {i18next.t(translations.AllRestrictions)}
                    </MuiLink>
                  </Caption>
                ) : (
                  <React.Fragment>
                    <Body size="small" bold={true}>
                      {i18next.t(translations.AllRestrictions)}
                    </Body>
                    <BulletedList
                      items={equipments.map(per => {
                        return {
                          key: per.Id,
                          node: (
                            <Caption>
                              <MuiLink
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  !!onRestrictionsClick &&
                                  onRestrictionsClick(per)
                                }
                              >
                                {per.Name}
                              </MuiLink>
                            </Caption>
                          ),
                        };
                      })}
                    />
                  </React.Fragment>
                )}
              </Box>
            ),
          },
        ]
      : [
          {
            index: 'pErrorIndex',
            node: <Caption>{data.ErrorMessage}</Caption>,
          },
        ],
  );
};
export const trainingSessionChangeHandler = (
  data: TrainingSessionResult | null,
  setMessage: (items: IndexNode[]) => void,
  initTrainingSession: (
    data: TrainingSessionResult,
    values: ReservationDetailsState,
  ) => void,
  values: ReservationDetailsState,
) => {
  if (data !== null && data.HasTrainingSession === true) {
    setMessage([
      {
        index: 'trainingSessionWarning',
        node: (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {(i18next.t(translations.warn_TrainingSessionsTimeSlot) as string)
                .replace('{0}', dateUtils.shortDateTimeFormat(data.Start) ?? '')
                .replace('{1}', dateUtils.shortDateTimeFormat(data.End) ?? '')}
            </Body>
            <Button
              variant="main"
              size="small"
              onClick={e => initTrainingSession(data, values)}
            >
              {i18next.t(translations.SignUp) as string}
            </Button>
          </Box>
        ),
      },
    ]);
  }
};
export const durationErrorsHandler = (
  data: string[],
  equipments: Entity<number>[],
  setDurationErrors: (items: IndexNode[]) => void,
  onRestrictionsClick?: (equipment: Entity<number>) => void,
) => {
  setDurationErrors([
    {
      index: 'durationErrors',
      node: (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {data.map(dur => (
            <Caption key={dur.slice(0, 3 + dur.length)}>{dur}</Caption>
          ))}
          {equipments.length === 1 ? (
            <Caption>
              <MuiLink
                style={{ cursor: 'pointer', marginLeft: 'auto' }}
                onClick={() =>
                  !!onRestrictionsClick && onRestrictionsClick(equipments[0])
                }
              >
                {i18next.t(translations.AllRestrictions)}
              </MuiLink>
            </Caption>
          ) : (
            <React.Fragment>
              <Body size="small" bold={true}>
                {i18next.t(translations.AllRestrictions)}
              </Body>
              <BulletedList
                items={equipments.map(per => {
                  return {
                    key: per.Id,
                    node: (
                      <Caption>
                        <MuiLink
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            !!onRestrictionsClick && onRestrictionsClick(per)
                          }
                        >
                          {per.Name}
                        </MuiLink>
                      </Caption>
                    ),
                  };
                })}
              />
            </React.Fragment>
          )}
        </Box>
      ),
    },
  ]);
};
export const otheMessagesHandler = (
  data: string[],
  type: 'warning' | 'error' | 'info' | 'success',
  setMessage: (items: IndexNode[]) => void,
) => {
  let index = `other_${type}_${data.length}`;
  let message = [
    {
      index: index,
      node: (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {data.map(msg => (
            <Caption key={`ind_${msg.slice(0, 3)}_${msg.length}`}>
              {msg}
            </Caption>
          ))}
        </Box>
      ),
    },
  ];
  setMessage(message);
};
export const customFormErrorsHandler = (
  data: string[],
  setMessage: (items: IndexNode[]) => void,
) => {
  setMessage([
    {
      index: 'cutFErrors',
      node: (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {data.map(dur => (
            <Caption key={`ind_${dur.slice(0, 3)}_${dur.length}`}>
              {dur}
            </Caption>
          ))}
        </Box>
      ),
    },
  ]);
};
export const permissionsErrorsHandler = (
  data: string[],
  equipments: Entity<number>[],
  setMessage: (items: IndexNode[]) => void,
  onRestrictionsClick?: (equipment: Entity<number>) => void,
) => {
  setMessage([
    {
      index: 'permIndex',
      node: (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {data.map((t, index) => (
            <Caption key={`index_${t.slice(0, 3)}_${t.length}`}>{t}</Caption>
          ))}
          {equipments.length === 1 ? (
            <Caption>
              <MuiLink
                style={{ cursor: 'pointer', marginLeft: 'auto' }}
                onClick={() =>
                  !!onRestrictionsClick && onRestrictionsClick(equipments[0])
                }
              >
                {i18next.t(translations.AllRestrictions)}
              </MuiLink>
            </Caption>
          ) : (
            <React.Fragment>
              <Body size="small" bold={true}>
                {i18next.t(translations.AllRestrictions)}
              </Body>
              <BulletedList
                items={equipments.map(per => {
                  return {
                    key: per.Id,
                    node: (
                      <Caption>
                        <MuiLink
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            !!onRestrictionsClick && onRestrictionsClick(per)
                          }
                        >
                          {per.Name}
                        </MuiLink>
                      </Caption>
                    ),
                  };
                })}
              />
            </React.Fragment>
          )}
        </Box>
      ),
    },
  ]);
};
