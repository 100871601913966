import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const BookedByRenderer: Renderer<CellProps<RecordType>> = ({ value, row }) => {
  if (value === null || value === undefined) {
    return <></>;
  }
  return (
    <UserProfileLink userName={row.original.BookedById} displayName={value} />
  );
};
export default BookedByRenderer;
