/**
 *
 * ServiceRequestAddons
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { IConsumableServiceDto } from 'api/odata/generated/entities/IConsumableServiceDto';
import { ControlledTable } from 'app/components/BasicTable/ControlledTable';
import { useDispatch } from 'react-redux';
import { CellProps } from 'react-table';
import { IUserBaseDto } from 'api/odata/generated/entities/IUserBaseDto';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import {
  ServiceRequestMilestoneChargeDetailsProps,
  ServiceRequestMilestoneChargeType,
} from '../ServiceRequestMilestoneChargeDetails';
import { showAddonCharge, showManualCharge } from './utils';
import { RowActionsMenu } from 'app/components/BasicTable/RowActions';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { ColumnWithStrictAccessor } from 'react-table';
import { IUnitTypeDto } from 'api/odata/generated/entities/IUnitTypeDto';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Printing } from 'app/components/PrintToPDF';
import { DetectIsMobile } from 'utils/mobileDetect';
import { InvoiceDetailsLink } from 'app/pages/Actions/GlobalSelectedActions/OpenInvoiceDetails';
import { Entity } from 'types/common';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';

export interface ServiceRequestAddonsProps {
  charges: IServiceRequestMilestoneChargeDto[];
  milestone?: IServiceRequestMilestoneDto;
  isAdmin: boolean;
  AddonType: AddonTypesUnion;
  InternalStatus: InternalServiceRequestStatusesUnion;
  pageName?: string;
  tableContainerAsSection?: boolean;
  // indicates if this component is opened through side panel
  printing?: Printing;
  useSidePanel: boolean;
  UnitType?: Entity<number>;
}

export function ServiceRequestAddons(props: ServiceRequestAddonsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isMobile = DetectIsMobile();
  const columns: Array<
    ColumnWithStrictAccessor<IServiceRequestMilestoneChargeDto>
  > = React.useMemo(
    () => [
      { Header: 'Id', accessor: 'Id' },
      {
        Header: t(translations.Date) as string,
        accessor: 'Date',

        Cell: DateRenderer,
      },
      {
        Header: t(translations.ServiceOrCharge) as string,
        accessor: 'Service',
        Cell: ({
          value,
          row,
        }: CellProps<
          IServiceRequestMilestoneChargeDto,
          IConsumableServiceDto
        >) => value?.Name ?? row.original.Name ?? t(translations.NA),
      },
      { Header: t(translations.Quantity) as string, accessor: 'Quantity' },
      {
        Header: t(translations.Unit) as string,
        accessor: 'UnitType',
        Cell: ({
          value,
          row,
        }: CellProps<IServiceRequestMilestoneChargeDto, IUnitTypeDto>) =>
          (value ?? row.original.Service?.UnitType)?.Name ?? t(translations.NA),
      },
      {
        Header: t(translations.Rate) as string,
        accessor: 'Rate',
        Cell: ({ value, row }) => {
          return (
            <>
              {roundTwoPlacesAfterDecimalPoint(
                value ?? row.original.Service?.Rate,
              )}
            </>
          );
        },
      },
      {
        Header: t(translations.Amount) as string,
        accessor: 'Amount',
        Cell: ({ value }) => {
          return <>{roundTwoPlacesAfterDecimalPoint(value)}</>;
        },
      },
      {
        Header: t(translations.InvoiceId) as string,
        accessor: 'InvoiceId',
        Cell: ({ value, row }) =>
          value !== null && value !== undefined && typeof value === 'number' ? (
            <InvoiceDetailsLink id={value} useSidePanel={props.useSidePanel}>
              {value}
            </InvoiceDetailsLink>
          ) : (
            <>{value}</>
          ),
      },
      {
        Header: t(translations.Remarks) as string,
        accessor: 'Remarks',
        Cell: DescriptionCell({ width: 'medium' }),
      },
      {
        Header: t(translations.UpdatedBy) as string,
        accessor: 'UpdatedBy',
        Cell: ({
          value,
        }: CellProps<IServiceRequestMilestoneChargeDto, IUserBaseDto>) =>
          value?.Name ?? t(translations.NA),
      },
    ],
    [props.useSidePanel, t],
  );

  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();

  const show_newAddonCharge = React.useMemo(() => {
    const result = showAddonCharge({
      Charged: props.milestone?.Charged,
      isAdmin: props.isAdmin,
      ServiceRequestAddonTypes: props.AddonType,
      ServiceRequestInternalStatus: props.InternalStatus,
    });
    return result;
  }, [
    props.AddonType,
    props.InternalStatus,
    props.isAdmin,
    props.milestone?.Charged,
  ]);

  const show_newManualCharge = React.useMemo(() => {
    const result = showManualCharge({
      Charged: props.milestone?.Charged,
      isAdmin: props.isAdmin,
      ServiceRequestAddonTypes: props.AddonType,
      ServiceRequestInternalStatus: props.InternalStatus,
    });
    return result;
  }, [
    props.AddonType,
    props.InternalStatus,
    props.isAdmin,
    props.milestone?.Charged,
  ]);
  const handleAddServiceClick = React.useCallback(() => {
    if (props.milestone === undefined) {
      return;
    }
    const payload: ServiceRequestMilestoneChargeDetailsProps = {
      serviceRequestId: props.milestone?.ServiceRequestId,
      serviceRequestMilestoneId: props.milestone.Id,
      type: 'service',
      enableAddonCharge: show_newAddonCharge,
      enableManualCharge: show_newManualCharge,
    };
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.ServiceRequestMilestoneCharge,
        props: payload,
      }),
    );
  }, [
    actions,
    dispatch,
    props.milestone,
    show_newAddonCharge,
    show_newManualCharge,
  ]);
  const handleAddChargeClick = React.useCallback(() => {
    if (props.milestone === undefined) {
      return;
    }
    const payload: ServiceRequestMilestoneChargeDetailsProps = {
      serviceRequestId: props.milestone?.ServiceRequestId,
      serviceRequestMilestoneId: props.milestone.Id,
      type: 'charge',
      enableAddonCharge: show_newAddonCharge,
      enableManualCharge: show_newManualCharge,
      unitType: props.UnitType,
    };
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.ServiceRequestMilestoneCharge,
        props: payload,
      }),
    );
  }, [
    actions,
    dispatch,
    props.UnitType,
    props.milestone,
    show_newAddonCharge,
    show_newManualCharge,
  ]);

  const handleEditClick = React.useCallback(
    (value: IServiceRequestMilestoneChargeDto) => {
      if (props.milestone === undefined) {
        return;
      }
      const type: ServiceRequestMilestoneChargeType =
        value.OfflineServiceTypeId === null ? 'charge' : 'service';
      const payload: ServiceRequestMilestoneChargeDetailsProps = {
        serviceRequestId: props.milestone?.ServiceRequestId,
        serviceRequestMilestoneId: props.milestone.Id,
        type: type,
        enableAddonCharge: show_newAddonCharge,
        enableManualCharge: show_newManualCharge,
        value: value,
      };
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ServiceRequestMilestoneCharge,
          props: payload,
        }),
      );
    },
    [
      actions,
      dispatch,
      props.milestone,
      show_newAddonCharge,
      show_newManualCharge,
    ],
  );

  const handleDeleteClick = React.useCallback(
    (value: IServiceRequestMilestoneChargeDto) => {
      dispatch(actions.deleteMilestoneCharge(value));
    },
    [actions, dispatch],
  );
  const rowActions = React.useMemo(() => {
    if (show_newAddonCharge) {
      return [
        ({ value, row, updateMyData }) =>
          show_newAddonCharge && (
            <RowActionsMenu
              id={`row-actions-menu_${row.original.Id}`}
              items={[
                {
                  icon: 'pencil-alt',
                  text: t(translations.Edit),
                  title: t(translations.Edit),
                  onClick: () => Promise.resolve(handleEditClick(row.original)),
                },
                {
                  icon: 'times',
                  text: t(translations.Delete),
                  title: t(translations.Delete),
                  onClick: () =>
                    Promise.resolve(handleDeleteClick(row.original)),
                },
              ]}
            />
          ),
      ];
    }
  }, [handleDeleteClick, handleEditClick, show_newAddonCharge, t]);
  const pageActions: PageActionRenderer[] = React.useMemo(() => {
    const result: PageActionRenderer[] = [];
    if (show_newManualCharge) {
      result.push(() => (
        <TopActionButton
          size="small"
          variant={show_newAddonCharge ? 'white' : 'main'}
          startIcon="circle-dollar-to-slot"
          icon="circle-dollar-to-slot"
          key={'show_newAddonCharge'}
          onClick={handleAddChargeClick}
          text={t(translations.AddCharge) as string}
        />
      ));
    }
    if (show_newAddonCharge) {
      result.push(() => (
        <TopActionButton
          size="small"
          variant={'main'}
          startIcon="plus"
          icon="plus"
          key={'show_newAddonCharge'}
          onClick={handleAddServiceClick}
          text={t(translations.AddService) as string}
        />
      ));
    }
    return result;
  }, [
    handleAddChargeClick,
    handleAddServiceClick,
    show_newAddonCharge,
    show_newManualCharge,
    t,
  ]);
  return (
    <>
      <ControlledTable<IServiceRequestMilestoneChargeDto>
        columns={columns as Array<Column<IServiceRequestMilestoneChargeDto>>}
        //withToolBar={false}
        data={props.charges ?? []}
        useRowSelect={false}
        hideMenuOnMobile={true}
        screenName={props.pageName ?? t(translations.AddOnServices)}
        useGlobalFilter={false}
        api={'foo'}
        customEmptyTableSettings={{
          headTitle: t(translations.dash_EmptyWidgetTitle),
          title:
            show_newAddonCharge || show_newManualCharge
              ? t(translations.AddonCharges_Empty_AddEnabled)
              : undefined,
        }}
        manualPagination={false}
        manualSorting={false}
        compactTable={false}
        enablePagination={false}
        compact={isMobile}
        screenNameVariant="section"
        dataLength={props.charges?.length ?? 0}
        loading={props.charges === undefined}
        onChangeState={() => void 0}
        onExport={() => Promise.resolve()}
        useExport={false}
        pageCount={123}
        initialPageSize={1000}
        serviceGroups={[]}
        rowActions={rowActions}
        pageActions={pageActions}
        tableContainerAsSection={props.tableContainerAsSection}
        printing={props.printing}
      />
    </>
  );
}
// const Container = styled(Box)(({ theme }) => ({
//   // margin is needed here to replace the containing cell padding because of a disappearing border there
//   margin: theme.spacing(2),
// }));
// const ButtonsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(1),
// }));
