import { PayloadAction } from '@reduxjs/toolkit';
import { AppSettings } from 'types/AppSettings';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity } from 'types/common';
import { KnownModules } from 'types/KnownModules';
import { Notification } from 'types/Notification';
import { SiteMapSettings } from 'types/SiteMapSettings';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { buildURL } from 'utils/url-utils';
/* --- STATE --- */

/**
 * AppSettings state interface
 * TODO: auto generate based on bkt_webApp.Api.V4.Models.SettingsModel
 */
export interface AppSettingsState {
  error: string | null;
  loadingAppSettings: boolean;
  appSettings: AppSettings | null;
  loadingAuthenticatedUser: boolean;
  loadingSiteMapSettings: boolean;
  authenticatedUser?: IAuthenticatedUser;
  currentLocaleId?: string;
  notifications: Notification[];
  siteMapSettings: SiteMapSettings | null;
  //siteMapProvider: SiteMapProvider | null;
  eventsCount: number;
  loadingEventsCount: boolean;
  userProfileSettings: UserProfileSettings[];
  loadingUserProfileSettings: boolean;
  updatedUserProfileSettings: boolean;
  isExpanded: boolean;
  systemSettings: SettingModel[];
  loadingSystemSettings: boolean;
  isAuthenticated?: boolean;
  /**
   * List of Service Groups selected by the user in the global service group filter aka Top Sg filter
   */
  globalServiceGroupFilter: Entity<number>[] | undefined;
  specificServiceGroupFilter: Entity<number>[] | undefined;
  showGlobalServiceGroupFilter: boolean;
  allServiceGroupsSelectable?: Entity<number>[] | undefined;
  isFullScreen: boolean;
  barcodeScanTarget?: ScanTarget;
  showAllCores?: boolean;
  subBarCollapsed?: boolean;
  updatedExpandState?: boolean;
  skipLocationChanged?: boolean;
  manualScanProcessing?: boolean;
}

export type ContainerState = AppSettingsState;

/**
 * User roles enum - local/Bookit.Data/Enums.cs
 */

export enum Roles {
  Administrators = 1,
  GroupAdministrators = 2,
  InstituteCoord = 3,
  HeadOfLab = 4,
  CagesAdministrators = 5,
  LabTech = 6,
  FinAdmin = 7,
  RepAdmin = 8,
  InventoryAdmin = 9,
  UserGroupCoord = 10,
  InstrumentEventsAdmin = 11,
  GroupBudgetsAdmin = 12,
  CalendarOnly = 13,
  Readonly = 14,
  LoanDeskManager = 15,
  EquipmentAdministrator = 16,
  UserInquiryAdmin = 17,
  ModelAdmin = 18,
  RoomOnly = 19,
  InvoicesReader = 20,
  KioskAdmin = 21,
  CustomRole = 22,
  BudgetAdmin = 23,
  GroupAdminAffiliated = 24,
  ServiceGroupBudgetsAdmin,
  AnimalAdmin = 25,
  /// <summary>
  /// Users in this role will unlock the large animals operating room pos
  /// </summary>
  LargeAnimalsOperatingRoomUser = 26,
  LargeAnimalsOperatingRoomTechnician = 27,
  LargeAnimalsOperatingRoomVeterinarian = 28,
  CoreBudgetsAdmin = 29,
  ConsumableKiosk = 30,
  CreateNewAssetsAdmin = 31,
}

export enum LoginTypes {
  Password,
  InternalId,
}
export type ISaveUserProfileSettingPayload = Pick<
  UserProfileSettings,
  'Key' | 'Value' | 'UserName' | 'Enabled'
>;
export type SaveUserProfileSettingAction =
  PayloadAction<ISaveUserProfileSettingPayload>;
export interface NoAuthSettings {
  defaultUserGroup?: Entity<string>;
  registrationEnabled?: boolean;
  requiredUserProps: string[];
  hiddenUserProps: string[];
}
export const anonimusUrls = [
  'registeraccount',
  'assets/public',
  'assets/details',
];
export enum ScanTarget {
  All,
  Consumables = 1,
  Asset = 2,
  Inventory = 3,
  Login = 4,
}
export interface BarcodeData {
  data: string;
  key: ScanTarget;
}
export interface LoginSettingsModel {
  Modules?: KnownModules[];
  settings?: SettingModel[];
  LoginScreenCompanyLogoLink?: string;
  LoginScreenCompanyLogo?: string;
  LoginScreenCompanyLogoHeight?: string;
  ShowForgotPassword?: boolean;
  Help_Login?: string;
  WebVersion?: string;
  LastCodeUpdate?: Date;
}
export enum ScanTemplateResult {
  Asset,
  Room,
  Location,
  SubLocation,
  Plate,
  External,
  Internal,
}
export interface BarcodeScanResult {
  url: string;
  type: ScanTemplateResult;
  InternalCode?: string;
  RoomId?: string;
  LocationId?: string;
  SubLocationId?: string;
  PlateId?: string;
  external?: boolean;
}
export function parseAssetUrl(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    // const publicBaseURL = new URL(appSettings.PublicBaseUrl);
    const template =
      appSettings.AssetBarcodeRelativeUrlTemplate.indexOf('?') >= 0
        ? appSettings.AssetBarcodeRelativeUrlTemplate.split('?').join('/')
        : appSettings.AssetBarcodeRelativeUrlTemplate;

    const regExpTemplate = template.replace(/:([^\\}]+)/g, '(?<$1>\\d+)');

    var url = new URL(data);
    const path = url.pathname + url.search;
    let toCompareUrl =
      path.indexOf('?') >= 0 ? path.split('?').join('/') : path;
    let internalCode: string | undefined = undefined;
    let regObject = new RegExp(regExpTemplate).exec(toCompareUrl);
    if (regObject !== null) {
      let groups = regObject.groups;
      if (groups !== undefined) {
        internalCode = groups['internalCode'];
      }
    }
    if (internalCode !== undefined) {
      let resUrl = buildURL(`${appSettings.PublicBaseUrl}Assets/Details.aspx`, {
        InternalCode: internalCode,
      });
      return {
        type: ScanTemplateResult.Asset,
        url: resUrl,
        InternalCode: internalCode,
      } as BarcodeScanResult;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}
function parseRoomUrl(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    // const publicBaseURL = new URL(appSettings.PublicBaseUrl);
    const template =
      appSettings.RoomBarcodeRelativeUrlTemplate.indexOf('?') >= 0
        ? appSettings.RoomBarcodeRelativeUrlTemplate.split('?').join('/')
        : appSettings.RoomBarcodeRelativeUrlTemplate;

    const regExpTemplate = template.replace(
      /\{\{([^\\}]+)\}\}/g,
      '(?<$1>\\d+)',
    );

    var url = new URL(data);
    let toCompareUrl =
      url.pathname.indexOf('?') >= 0
        ? url.pathname.split('?').join('/')
        : url.pathname;
    let roomId: string | undefined = undefined;
    let regObject = new RegExp(regExpTemplate).exec(toCompareUrl);
    if (regObject !== null) {
      let groups = regObject.groups;
      if (groups !== undefined) {
        roomId = groups['Id'];
      }
    }
    if (roomId !== undefined) {
      let resUrl = buildURL(`${appSettings.PublicBaseUrl}Assets/Public`, {
        RoomId: roomId,
      });
      return {
        type: ScanTemplateResult.Room,
        url: resUrl,
        RoomId: roomId,
      } as BarcodeScanResult;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}
export function parseLocationUrl(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    // const publicBaseURL = new URL(appSettings.PublicBaseUrl);
    const template =
      appSettings.LocationBarcodeRelativeUrlTemplate.indexOf('?') >= 0
        ? appSettings.LocationBarcodeRelativeUrlTemplate.split('?').join('/')
        : appSettings.LocationBarcodeRelativeUrlTemplate;

    const regExpTemplate = template.replace(
      /\{\{([^\\}]+)\}\}/g,
      '(?<$1>\\d+)',
    );

    var url = new URL(data);
    let toCompareUrl =
      url.pathname.indexOf('?') >= 0
        ? url.pathname.split('?').join('/')
        : url.pathname;
    let locationId: string | undefined = undefined;
    let roomId: string | undefined = undefined;
    let regObject = new RegExp(regExpTemplate).exec(toCompareUrl);
    if (regObject !== null) {
      let groups = regObject.groups;
      if (groups !== undefined) {
        locationId = groups['Id'];
        roomId = groups['RoomId'];
      }
    }
    if (locationId !== undefined && roomId !== undefined) {
      let resUrl = buildURL(`${appSettings.PublicBaseUrl}Assets/Public`, {
        RoomId: roomId,
        locationid: locationId,
      });
      return {
        type: ScanTemplateResult.Location,
        url: resUrl,
        RoomId: roomId,
        LocationId: locationId,
      } as BarcodeScanResult;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}
export function parseSubLocationUrl(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    // const publicBaseURL = new URL(appSettings.PublicBaseUrl);
    const template =
      appSettings.SubLocationBarcodeRelativeUrlTemplate.indexOf('?') >= 0
        ? appSettings.SubLocationBarcodeRelativeUrlTemplate.split('?').join('/')
        : appSettings.SubLocationBarcodeRelativeUrlTemplate;

    const regExpTemplate = template.replace(
      /\{\{([^\\}]+)\}\}/g,
      '(?<$1>\\d+)',
    );

    var url = new URL(data);
    let toCompareUrl =
      url.pathname.indexOf('?') >= 0
        ? url.pathname.split('?').join('/')
        : url.pathname;
    let subLocationId: string | undefined = undefined;
    let locationId: string | undefined = undefined;
    let roomId: string | undefined = undefined;
    let regObject = new RegExp(regExpTemplate).exec(toCompareUrl);
    if (regObject !== null) {
      let groups = regObject.groups;
      if (groups !== undefined) {
        subLocationId = groups['Id'];
        locationId = groups['LocationListId'];
        roomId = groups['RoomId'];
      }
    }
    if (
      subLocationId !== undefined &&
      locationId !== undefined &&
      roomId !== undefined
    ) {
      let resUrl = buildURL(`${appSettings.PublicBaseUrl}Assets/Public`, {
        RoomId: roomId,
        locationid: locationId,
        sublocationid: subLocationId,
      });
      return {
        type: ScanTemplateResult.SubLocation,
        url: resUrl,
        RoomId: roomId,
        LocationId: locationId,
        SubLocationId: subLocationId,
      } as BarcodeScanResult;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

export function parseBarcodeURL(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    data = data.replace(/&#x3D;/g, '='); // for backward compatibility
    let assetScanned = parseAssetUrl(data, appSettings);
    let subLocationScanned = parseSubLocationUrl(data, appSettings);
    let locationScanned = parseLocationUrl(data, appSettings);
    let roomScanned = parseRoomUrl(data, appSettings);
    if (!!assetScanned) {
      return assetScanned;
    }
    if (!!subLocationScanned) {
      return subLocationScanned;
    }
    if (!!locationScanned) {
      return locationScanned;
    }
    if (!!roomScanned) {
      return roomScanned;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}
export function parseBarcode(
  data: string,
  appSettings: AppSettings,
): BarcodeScanResult | undefined {
  try {
    // in case when barcode come as URL, try to create url...
    var url = new URL(data);
    var isLocal = url
      .toString()
      .toLowerCase()
      .startsWith(appSettings.PublicBaseUrl.toLowerCase());
    if (!isLocal) {
      return {
        url: url.toString(),
        type: ScanTemplateResult.External,
        InternalCode: data,
        external: true,
      };
    } else {
      return parseBarcodeURL(data, appSettings);
    }
  } catch (error) {
    return {
      url: buildURL(`${appSettings.PublicBaseUrl}Assets/Details.aspx`, {
        InternalCode: data,
      }),
      type: ScanTemplateResult.Internal,
      InternalCode: data,
    } as BarcodeScanResult;
  }
}
export type LocaleIdentifier = {
  language: string;
  region: string;
};
