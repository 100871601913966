/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { Entity, Identifiable } from 'types/common';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import { httpClient } from 'api/HttpClient';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { FormAutocompleteMulti } from 'app/components/Forms/FormAutocompleteMulti';
import { FormAutocomplete } from 'app/components/Forms/FormAutocomplete';

const url = '/api/odata/v4/UserFilter/GetUserSelections';
export const initUserData = (
  id: string,
  formField: IFormFieldDto | undefined,
) => {
  let filter: string | undefined = '';
  const initids = id.split(',').map(item => {
    return { Id: item };
  }) as Identifiable<string>[];
  if (formField) {
    if (
      formField.UserSelectionType === 'FixedRoles' &&
      formField.FixedRoles !== null &&
      formField.FixedRoles !== ''
    ) {
      let ids = formField.FixedRoles.split(',').map(item => {
        return { Id: item };
      }) as Identifiable<string>[];
      filter = new Condition(
        'UserRoles',
        ODataOperators.AnyIn,
        ids,
        'Name',
      ).toString();
    }
    if (
      formField.UserSelectionType === 'CustomRoles' &&
      formField.CustomRoles !== null &&
      formField.CustomRoles !== ''
    ) {
      let ids = formField.CustomRoles.split(',')
        .map(item => {
          let id = item.split('#');
          return { Id: parseInt(id[0]) };
        })
        .filter(item => !isNaN(item.Id)) as Identifiable<number>[];
      filter = new Condition(
        'CustomRoles',
        ODataOperators.AnyIn,
        ids,
        'Id',
      ).toString();
    }
  }
  if (filter !== '') {
    filter += ` and ${new Condition(
      'Id',
      ODataOperators.Includes,
      initids,
    ).toString()}`;
  } else {
    filter = new Condition('Id', ODataOperators.Includes, initids).toString();
  }
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: filter,
    $select: 'Id,Name,UserRoles,CustomRoles',
    $skip: 0,
    $top: initids.length,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};
export const getGetUserSelectionsAutocompleteFn = (
  formField: Pick<
    IFormFieldDto,
    'UserSelectionType' | 'FixedRoles' | 'CustomRoles'
  >,
) => (searchTerm: string | null) => {
  const params: {
    $orderby: string;
    $top: number;
    $filter?: string;
    $select?: string;
  } = {
    $orderby: 'Name asc',
    $select: 'Id,Name,UserRoles,CustomRoles',
    $top: 100,
  };
  const predicates: Condition<IUserFilterDto>[] = [];
  if (
    formField.UserSelectionType === 'FixedRoles' &&
    formField.FixedRoles !== null &&
    formField.FixedRoles !== ''
  ) {
    let ids = formField.FixedRoles.split(',').map(item => {
      return { Id: item };
    }) as Identifiable<string>[];
    predicates.push(
      new Condition('UserRoles', ODataOperators.AnyIn, ids, 'Name'),
    );
  }
  if (
    formField.UserSelectionType === 'CustomRoles' &&
    formField.CustomRoles !== null &&
    formField.CustomRoles !== ''
  ) {
    let ids = formField.CustomRoles.split(',')
      .map(item => {
        let id = item.split('#');
        return { Id: parseInt(id[0]) };
      })
      .filter(item => !isNaN(item.Id)) as Identifiable<number>[];
    predicates.push(
      new Condition('CustomRoles', ODataOperators.AnyIn, ids, 'Id'),
    );
  }
  if (searchTerm !== null || predicates.length > 0) {
    params.$filter = new ODataFilterBuilder<IUserFilterDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm ?? undefined,
    }).toString();
  }
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};
export function CustomFormUserSelection({
  formField,
  readonly,
  parentValue,
  disabled,
  ...props
}: CustomFormFieldProps) {
  const loadData = getGetUserSelectionsAutocompleteFn(formField);
  return (
    <React.Fragment>
      {formField.IsMultiple ? (
        <FormAutocompleteMulti
          key={formField.Id}
          disabled={readonly || disabled}
          loadData={loadData}
          label={formField.Label}
          info={formField.Description ?? undefined}
          variant="filled"
          fullWidth
          {...props}
        />
      ) : (
        <FormAutocomplete
          key={formField.Id}
          disabled={readonly || disabled}
          info={formField.Description ?? undefined}
          loadData={loadData}
          label={formField.Label}
          variant="filled"
          fullWidth
          {...props}
        />
      )}
    </React.Fragment>
  );
}
