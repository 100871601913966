import { Box } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { push } from 'connected-react-router';
import { translations } from 'locales/translations';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { SaveFormButton } from './Actions/RequestStatusesActions';
import { useRequestStatusesDetailsSlice } from './slice';
import {
  selectCreateRequestStatusesDetailsCompleted,
  selectRequestStatusesDetailsProcessing,
  selectUpdateRequestStatusesDetails,
  selectUpdateRequestStatusesDetailsCompleted,
} from './slice/selectors';
import {
  RequestStatusesDetailsQStringParameters,
  RequestStatusesDetailsState,
} from './slice/types';
import { Beforeunload } from 'react-beforeunload';
import { RequestStatusesForm } from './RequestStatusesForm';

export interface RequestStatusesDetailsProps extends SidePanelContentProps {
  queryParams: RequestStatusesDetailsQStringParameters;
}

export function RequestStatusesDetails(
  props: RequestStatusesDetailsProps,
): JSX.Element {
  //PROPS
  const { queryParams, useSidePanel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //PERMISSIONS
  const User = useSelector(selectAuthenticatedUser);
  const IsAdmin =
    User !== undefined && User.Roles.includes(Roles.Administrators);

  //SLICES
  const { actions } = useRequestStatusesDetailsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();

  //SELECTORS
  const publicUrl = useSelector(selectPublicUrl);
  //====================================================================
  const hasUnsavedChanges = useSelector(selectHasNotSavedChanges, shallowEqual);
  const continueLink = useSelector(selectContinueToLink);
  const loading = useSelector(selectRequestStatusesDetailsProcessing);
  //====================================================================
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const continueToOther = useSelector(selectContinueState);
  //====================================================================
  const requestCreateCompleted = useSelector(
    selectCreateRequestStatusesDetailsCompleted,
  );
  const requestUpdateCompleted = useSelector(
    selectUpdateRequestStatusesDetailsCompleted,
  );
  //====================================================================
  const editModel = useSelector(
    selectUpdateRequestStatusesDetails,
    shallowEqual,
  );
  const baseModel = editModel;
  //====================================================================

  //STATES
  const [edit, setEdit] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [requestCompleted, setRequestCompleted] = useState<boolean | undefined>(
    undefined,
  );

  //REF
  const submitFormRef = useRef<any>(null);

  //FUNCTIONS
  const bindSubmitForm = useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };

  const handleSaveClick = (e: any) => {
    handleSubmitForm(e);
  };

  const handleSubmit = useCallback(
    (values: RequestStatusesDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateRequestStatusesDetails({
            current: values,
            original: baseModel,
          }),
        );
      }
    },
    [actions, baseModel, dispatch, edit],
  );

  const handleCancelClick = useCallback(() => {
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
    } else {
      dispatch(push('/RequestStatuses'));
    }
    if (edit) {
      dispatch(actions.resetUpdateRequestStatusesDetailsState());
    }
  }, [actions, dispatch, edit, layoutActions, useSidePanel]);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCancelClick();
    }
  };

  //USE EFFECT ON MOUNT
  useEffectOnMount(() => {
    if (
      queryParams.id !== undefined &&
      !isNaN(parseInt(queryParams.id || ''))
    ) {
      dispatch(actions.initUpdateRequestStatusesDetails(queryParams));
    } else {
      dispatch(actions.initRequestStatusesDetails(queryParams));
    }
    setBusy(false);
    setEdit(false);
    setRequestCompleted(undefined);
    return () => {};
  });

  //USE EFFECT
  useEffect(() => {
    let active = loading === false;
    if (active) {
      if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        setEdit(true);
      }
      if (requestCreateCompleted === true || requestUpdateCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
      } else if (
        requestCreateCompleted === false ||
        requestUpdateCompleted === false
      ) {
        setRequestCompleted(false);
        setBusy(false);
      }
      if (requestUpdateCompleted === true || requestCreateCompleted === true) {
        handleCancelClick();
      }
    }
    return () => {
      active = false;
    };
  }, [
    handleCancelClick,
    loading,
    queryParams.id,
    requestCreateCompleted,
    requestUpdateCompleted,
  ]);

  //USE EFFECT 2
  useEffect(() => {
    if (confirmApproved) {
      if (edit) {
        dispatch(actions.resetUpdateRequestStatusesDetailsState());
      }
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }
    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);

  //LEFT ACTIONS
  const leftActions = [
    () => (
      <Fragment>
        <SaveFormButton
          size="small"
          startIcon={<Icon icon="save" />}
          onClick={handleSaveClick}
          disabled={busy}
          processing={!requestCompleted && busy}
          edit={edit}
          text={t(translations.Save)}
        />
      </Fragment>
    ),
  ] as ActionRenderer[];

  //RIGHT ACTIONS
  const rightActions = [
    () => (
      <Fragment>
        <Button
          variant="ghost"
          size="small"
          startIcon={<Icon icon="times" />}
          onClick={handleCancelClick}
        >
          {t(translations.Cancel)}
        </Button>
      </Fragment>
    ),
  ] as ActionRenderer[];

  //RENDER
  return (
    <>
      <PageWrapper
        pageName={edit ? t(translations.Edit) : t(translations.Add)}
        titlePage={
          baseModel
            ? edit
              ? ` ${t(translations.Edit)} #${queryParams.id || 'N/A'}`
              : ` ${t(translations.Add)}`
            : undefined
        }
        loading={loading}
        useSidePanel={useSidePanel}
        leftActions={leftActions}
        rightActions={rightActions}
        closable={true}
        disableExpandToggle={true}
        closeSidePanel={handleClose}
        pageLink={buildURL(
          publicUrl + 'RequestStatuses/details',
          props.queryParams,
        )}
        children={
          baseModel !== undefined && !loading ? (
            <Fragment>
              <RequestStatusesForm
                initialValues={baseModel || ({} as RequestStatusesDetailsState)}
                bindSubmitForm={bindSubmitForm}
                onSubmit={handleSubmit}
                isEdit={edit}
                isAdmin={IsAdmin}
              />
            </Fragment>
          ) : (
            <Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={loading} size={80} />
              </Box>
            </Fragment>
          )
        }
      />
      {hasUnsavedChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </>
  );
}
