/**
 *
 * AssetCatPicker
 *
 */
import * as React from 'react';
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { selectReservationStatuses } from 'app/slice/selectors';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export interface ReservationStatusProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const initReservationStatusData =
  (t: TFunction) => (initialValue: string | undefined) => {
    const id = initialValue === undefined ? NaN : parseInt(initialValue);
    if (isNaN(id)) {
      return Promise.resolve([] as Entity<number>[]);
    } else {
      return Promise.resolve([
        {
          Id: id,
          Name: t(translations[ReservationStatus[id]]),
        },
      ]);
    }
  };

export function ReservationStatusPicker(props: ReservationStatusProps) {
  const { t } = useTranslation();
  const options = useSelector(state => selectReservationStatuses(state, t));
  const getOptions = React.useCallback(() => options, [options]);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'reservation-status-id'}
      getOptions={getOptions}
      {...props}
    />
  );
}
