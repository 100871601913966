import { IServiceRequestRowValueDto } from 'api/odata/generated/entities/IServiceRequestRowValueDto';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import * as React from 'react';
import {
  EditorProps,
  EditableCellProps,
} from '../../EditableTable/EditableCell/index';
import { FastFieldProps, getIn } from 'formik';
import {
  CustomFormColumnTypes,
  IServiceRequestTableRowModel,
} from '../../RequestSamplesPage/slice/types';
import { Column } from 'react-table';
import { ServiceRequestTableColumn } from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';
import { isNullOrUndefined } from 'utils/typeUtils';
import { assertExhaustive } from 'utils/assertExhaustive';

/**
 * Custom function passed through react-table for updating row values
 */
export type UpdateServiceRequestRowValue<TValue> = (props: {
  serviceRequestRowId: number;
  columnId: number;
  value: TValue;
}) => void;

export interface IServiceRequestRowEditableCellProps
  extends EditableCellProps<
    IServiceRequestRowValueDto,
    Column<IServiceRequestTableRowModel>,
    IServiceRequestRowDto
  > {
  fieldProps: FastFieldProps;
}
export function ServiceRequestRowEditableCell(
  WrappedComponent: React.FunctionComponent<EditorProps<any>>,
  props: {
    column: ServiceRequestTableColumn;
    focused?: boolean;
  },
) {
  return React.memo(function Comp({
    value: initialValue,
    row,
    column,
    updateMyData, // This is a custom function that we supplied to our table instance
    fieldProps,
  }: IServiceRequestRowEditableCellProps) {
    const name = fieldProps?.field.name;
    const handleBlur = event => {
      event.target.name = name;
      fieldProps?.field.onBlur(event);
    };

    /**
     * debounced handleChange intended to reduce the number of rerenders & http requests in text based fields.
     */
    const { value, handleChange } = useDebounce({
      value: fieldProps.field.value,
      timeout: getDebounceValue(props.column.ColumnType),
      handleChange: value => {
        try {
          // updates form value
          fieldProps?.field.onChange({
            target: { value: value, name },
          });

          // clear child columns values (connected dropdowns)
          props.column.Children?.forEach(childColumn => {
            fieldProps?.form.setFieldValue(
              getServiceRequestRowFieldName(
                row.index,
                childColumn.getFieldName(),
              ),
              null,
            );
          });

          // persists data on the server
          updateMyData({
            columnId: props.column.Id,
            value: value,
            serviceRequestRowId: row.original.Id,
          });
        } catch (error) {
          console.error(error);
        }
      },
    });

    /**
       * after arrayHelpers.insert, the new row gets inserted with null as the validation error on the whole row
       * e.g. formik.errors become (notice null as the first element in the array)
       * {
        "friends": [
            null,
            {
              "Values": {
                "column_1006": "Positive Decimal must be greater than or equal to 0",
                "column_1008": "Email must be a valid email"
              }
            }
          ]
        }
       */
    const hasError = !isNullOrUndefined(fieldProps?.meta?.error);

    /**
     * parent value - extracted from the current row according to column configuration. used for connected drop downs.
     */
    const parentValue =
      props.column.Parent === null
        ? undefined
        : getIn(
            fieldProps?.form.values,
            getServiceRequestRowFieldName(
              row.index,
              props.column.Parent.getFieldName(),
            ),
          ) ?? undefined;
    return (
      <>
        <WrappedComponent
          {...{
            key: name,
            name: name,
            value: value,
            onChange: handleChange,
            helperText: fieldProps?.meta?.error,
            error: hasError,
            parentValue: parentValue,
            onBlur: handleBlur,
            autoFocus: props.focused && hasError,
            //(value === null || value === undefined || value === ''),
          }}
        />
      </>
    );
  });
}
export function getServiceRequestRowName(
  rowIndex: number,
  key: keyof IServiceRequestTableRowModel,
) {
  return `Rows[${rowIndex}]${key}`;
}
export function getServiceRequestRowFieldName(
  rowIndex: number,
  columnFieldName: string,
) {
  return `${getServiceRequestRowName(rowIndex, 'Values')}[${columnFieldName}]`;
}

function getDebounceValue(columnType: CustomFormColumnTypes) {
  switch (columnType) {
    case 'CheckBox':
    case 'Date':
    case 'DropDownList':
    case 'UserSelection':
    case 'Serial':
      return;
    case 'Email':
    case 'Number':
    case 'PositiveDecimal':
    case 'PositiveInteger':
    case 'ShortText':
      return 100;
    default:
      assertExhaustive(columnType);
  }
}

interface useDebounceProps {
  value: any;
  handleChange: (value: any) => void;
  timeout?: number;
}
function useDebounce(props: useDebounceProps) {
  // tracks inner state in between the debounced updates
  const [innerValue, setInnerValue] = React.useState(props.value);

  // sync value changes from the outside of current field
  React.useEffect(() => {
    setInnerValue(props.value);
  }, [props.value]);

  // active timeout tracker
  const timeout = React.useRef<number | undefined>();

  if (props.timeout === undefined || props.timeout <= 0) {
    return props;
  }

  return {
    value: innerValue,
    handleChange: value => {
      setInnerValue(value);
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        props.handleChange(value);
        timeout.current = undefined;
      }, props.timeout);
    },
  };
}
