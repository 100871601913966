import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';
import { CellProps, Renderer } from 'react-table';

const UserRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
  row,
}) => {
  if (value === null || value === undefined) {
    return <></>;
  }
  return <UserProfileLink userName={row.original.UserId} displayName={value} />;
};
export default UserRenderer;
