/**
 *
 * WithReservationsOnlyFilter
 *
 */
import { ODataOperators } from 'api/odata/ODataFilter';
import { BooleanPicker } from 'app/components/BooleanPicker';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { URLSearchParams } from 'url';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';
import {
  GetBooleanEntity,
  GetBooleanOrUndefined,
  GetRawValue,
  URLSearchParamsCI,
} from '../../types/FilterParam';
import { IFilterComponentProps } from '../IFilterComponentProps';

export interface WithReservationsOnlyFilterProps {}

export function WithReservationsOnlyFilter(props: IFilterComponentProps) {
  const handleOnChange = (value: Entity<boolean> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <BooleanPicker
      value={props.value}
      isOpen={props.open}
      falseName={t(translations.ShowAll) as string}
      size="small"
      onChange={handleOnChange}
      placeholder={t(translations.ShowAll)}
      fullWidth={props.fullWidth}
      ariaLabel={props.label}
      id={props.id || 'withEventspickerId'}
      info={t(translations.WithEventsOnly_info)}
    />
  );
}
export function pushReservationsOnlyFilter<
  T extends { WithReservationsOnly: boolean | null },
>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  const id = 'WithReservationsOnly';
  result.push({
    id: id,
    fieldName: 'WithReservationsOnly',
    displayNameKey: translations.WithEventsOnly,
    operator: ODataOperators.Equals,
    queryStringSerializer: id,
    value: GetBooleanEntity(GetBooleanOrUndefined(GetRawValue(params, id)), t),
    component: WithReservationsOnlyFilter,
    defaultValue: false,
    urlKey: id,
    notDefault,
  });
}
export function getWithReservationsOnlyFilter<TRow>(
  fieldName: keyof TRow,
  params: URLSearchParams,
  t: TFunction,
): IFilterSettings<TRow> {
  const id = 'WithReservationsOnly';
  return {
    id: id,
    fieldName: fieldName,
    queryStringSerializer: id,
    displayNameKey: translations.WithEventsOnly,
    operator: ODataOperators.Equals,
    value: GetBooleanEntity(GetBooleanOrUndefined(GetRawValue(params, id)), t),
    component: WithReservationsOnlyFilter,
    urlKey: id,
  };
}
