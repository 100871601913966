import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IServiceRequestMilestoneForm } from '../../RequestSamplesPage/slice/types';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { dateUtils } from 'utils/date-utils';
import { ServiceRequestMilestoneStatusesUnion } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { ServiceRequestMilestoneStatusesUnionArray } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { FormUsersPicker } from 'app/components/Forms/FormUsersPicker';
import { FormServiceRequestMilestoneStatusPicker } from 'app/components/Forms/FormServiceRequestMilestoneStatusPicker';
import { selectServiceRequestMilestoneProcessing } from '../../RequestSamplesPage/slice/selectors';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export interface ServiceRequestMilestoneDetailsProps {
  serviceRequestId: number;
  type: 'Insert' | 'Update';
  value?: IServiceRequestMilestoneDto;
}

export function ServiceRequestMilestoneDetails(
  props: ServiceRequestMilestoneDetailsProps,
) {
  const { t } = useTranslation();
  const submitRef = React.useRef<(() => Promise<void>) | undefined>();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const processing = useSelector(selectServiceRequestMilestoneProcessing);

  const formType =
    props.value?.Id !== undefined && props.value.Id > 0 ? 'Update' : 'Insert';
  const insert = React.useCallback(
    (value: IServiceRequestMilestoneForm) => {
      dispatch(
        actions.insertMilestone({
          ...toDto(value),
          ...{ ServiceRequestId: props.serviceRequestId },
        }),
      );
    },

    [actions, dispatch, props.serviceRequestId],
  );
  const updateCharge = React.useCallback(
    (value: IServiceRequestMilestoneForm) => {
      dispatch(
        actions.updateMilestone({
          ...toDto(value),
          ...{ ServiceRequestId: props.serviceRequestId },
        }),
      );
    },

    [actions, dispatch, props.serviceRequestId],
  );

  const handleSubmit = React.useCallback(
    (values: IServiceRequestMilestoneForm) => {
      switch (formType) {
        case 'Insert':
          insert(values);
          break;
        case 'Update':
          updateCharge(values);
          break;
      }
    },
    [formType, insert, updateCharge],
  );
  const handleSubmitButtonClick = () => {
    submitRef.current?.();
  };
  const initialValues = toInitialValues(props.value);
  const title =
    props.type === 'Insert'
      ? t(translations.AddMilestone)
      : t(translations.Milestone);
  const handleClose = () => dispatch(actions.closeSidePanel());
  return (
    <PageWrapper
      useSidePanel
      closable
      disableExpandToggle
      isCover={false}
      pageName={title}
      titlePage={title}
      topProcessing={processing}
      closeSidePanel={handleClose}
      leftActions={[
        () => (
          <Button
            onClick={handleSubmitButtonClick}
            size="small"
            startIcon={<Icon icon="save" />}
            processing={processing}
          >
            {t(translations.Submit)}
          </Button>
        ),
      ]}
      rightActions={[
        () => (
          <Button
            onClick={() => dispatch(actions.closeSidePanel())}
            size="small"
            startIcon={<Icon icon="times" />}
          >
            {t(translations.Cancel)}
          </Button>
        ),
      ]}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={serviceRequestMilestoneSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          submitRef.current = formik.submitForm;
          return (
            <StyledForm isCover={true}>
              <FormFieldsSection>
                <FormLeftSection>
                  <FormRow fullRow>
                    <FormTextField
                      name="Name"
                      id="Name"
                      label={t(translations.Name)}
                      fullWidth
                      autoFocus
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormBookitDatePicker
                      name="TargetDate"
                      id="TargetDate"
                      label={t(translations.TargetDate)}
                      fullWidth
                    />
                  </FormRow>

                  <FormRow fullRow>
                    <FormUsersPicker
                      name="Assignees"
                      id="Assignees"
                      label={t(translations.AssignedTo)}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormBookitDatePicker
                      name="CompletionDate"
                      id="CompletionDate"
                      label={t(translations.CompletionDate)}
                      onChange={value => {
                        const currentStatus = formik.values.Status;
                        const newStatus:
                          | ServiceRequestMilestoneStatusesUnion
                          | undefined =
                          value !== null
                            ? 'Completed'
                            : currentStatus === 'Completed'
                            ? 'NotStarted'
                            : currentStatus;
                        formik.setFieldValue('Status', newStatus);
                      }}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormServiceRequestMilestoneStatusPicker
                      name="Status"
                      id="Status"
                      label={t(translations.Status)}
                      onChange={value => {
                        switch (value) {
                          case 'Completed':
                            if (
                              formik.values.CompletionDate === null ||
                              formik.values.CompletionDate === undefined
                            ) {
                              formik.setFieldValue(
                                'CompletionDate',
                                new Date(),
                              );
                            }
                            break;
                          case 'NotStarted':
                          case 'Started':
                            formik.setFieldValue('CompletionDate', null);
                            break;
                        }
                      }}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormTextField
                      name="Description"
                      id="Description"
                      label={t(translations.Description)}
                      fullWidth
                    />
                  </FormRow>
                </FormLeftSection>
              </FormFieldsSection>
            </StyledForm>
          );
        }}
      </Formik>
    </PageWrapper>
  );
}

const serviceRequestMilestoneSchema: Yup.SchemaOf<IServiceRequestMilestoneForm> =
  Yup.object({
    Assignees: Yup.mixed().optional(),
    CompletionDate: Yup.date().optional().nullable(),
    Id: Yup.number().optional(),
    Name: Yup.string().required(),
    Status: Yup.string()
      .oneOf(ServiceRequestMilestoneStatusesUnionArray)
      .optional() as Yup.SchemaOf<ServiceRequestMilestoneStatusesUnion>,
    TargetDate: Yup.date().optional().nullable(),
    Description: Yup.string().optional(),
  });

const toDto = (
  value: IServiceRequestMilestoneForm,
): Partial<IServiceRequestMilestoneDto> &
  Pick<IServiceRequestMilestoneDto, 'TargetDate'> => {
  return {
    Id: value.Id,
    Charged: false,
    CompletionDate:
      value.CompletionDate === null
        ? undefined
        : value.CompletionDate === undefined
        ? undefined
        : dateUtils.formatISO(value.CompletionDate),
    Description: value.Description,
    Name: value.Name ?? null,
    Status: value.Status,
    TargetDate:
      value.TargetDate === null
        ? null
        : value.TargetDate === undefined
        ? null
        : dateUtils.formatISO(value.TargetDate),
    Assignees: value.Assignees,
  };
};
const toInitialValues = (
  value?: IServiceRequestMilestoneDto,
): IServiceRequestMilestoneForm => {
  if (value === undefined) {
    return {
      TargetDate: null,
      CompletionDate: null,
    };
  }

  const result: IServiceRequestMilestoneForm = {
    Id: value.Id,
    Assignees: value.Assignees,
    CompletionDate:
      value.CompletionDate === null
        ? null
        : dateUtils.parseISO(value.CompletionDate),
    Description: value.Description ?? undefined,
    Name: value.Name ?? undefined,
    Status: value.Status ?? undefined,
    TargetDate:
      value.TargetDate === null
        ? null
        : dateUtils.parseISO(value.TargetDate) ?? undefined,
  };
  return result;
};
