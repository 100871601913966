/**
 *
 * WorkOrderTypePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';

import * as React from 'react';

import { Entity, Identifiable } from 'types/common';
export interface IWorkOrderTypeParamsPicker {
  assets?: IAssetDto[];
  groupIds?: number[];
  downtime?: boolean;
}
export interface WorkOrderTypePickerProps
  extends Omit<AutocompletePickerProps<IWorkOrderTypeDto>, 'loadData'> {
  useSearchOrPredicate?: boolean;
}

const url = '/api/odata/v4/WorkOrderType';
export const initWOTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export const initWOTypeDataParams = (
  assets: IAssetDto[] | undefined,
  groupIds: number[] | undefined,
  downtime: boolean | undefined,
) => {
  if (assets === undefined) {
    return new Promise<IWorkOrderTypeDto[]>((resolve, reject) => {
      resolve([] as IWorkOrderTypeDto[]);
    });
  } else {
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $select?: string;
    } = {
      $orderby: 'Name asc',
      $skip: 0,
      $top: 10,
      $select: 'Id,Name,AssetAlertType',
    };
    const predicates: (
      | string
      | Condition<IWorkOrderTypeDto>
    )[] = filteredWorkOrderTypes(assets, groupIds, downtime);
    params.$filter = new ODataFilterBuilder<IWorkOrderTypeDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: undefined,
      globalServiceGroupFilter: [],
    }).toString();
    return httpClient
      .get(url, params)
      .then(response => response.value as IWorkOrderTypeDto[]);
  }
};
export const filteredWorkOrderTypes = (
  assets: IAssetDto[] | undefined,
  groupIds: number[] | undefined,
  downtime: boolean | undefined,
  calibration?: boolean,
): (string | Condition<IWorkOrderTypeDto>)[] => {
  const predicates: (string | Condition<IWorkOrderTypeDto>)[] = [];
  let isOfflineServiceType = false;
  if (assets !== undefined && assets.length > 0) {
    isOfflineServiceType = assets.some(
      f => f.ServiceTypeId === ServiceType.Offline,
    );
    const grp = assets
      .map(f => f.ServiceGroupId)
      .filter((elem, index, self) => {
        return (
          elem !== null && elem !== undefined && index === self.indexOf(elem)
        );
      })
      .map(f => {
        return { Id: f } as Identifiable<number>;
      });
    const cts = assets
      .map(f => f.AssetCatId)
      .filter((elem, index, self) => {
        return (
          elem !== null && elem !== undefined && index === self.indexOf(elem)
        );
      })
      .map(f => {
        return { Id: f } as Identifiable<number>;
      });
    const byGroups: string[] = [];
    byGroups.push(
      new Condition('Groups', ODataOperators.HasNoAny, 0).toString() || '',
    );
    byGroups.push(
      `(${grp
        .map(
          g =>
            new Condition('Groups', ODataOperators.Any, g.Id).toString() || '',
        )
        .filter(f => f !== '')
        .join(' and ')})`,
    );
    predicates.push(
      '(' + byGroups.filter(f => f !== '' && f !== '()').join(' or ') + ')',
    );
    const byCats: string[] = [];
    byCats.push(
      new Condition('AssetCats', ODataOperators.HasNoAny, 0).toString() || '',
    );
    byCats.push(
      `(${cts
        .map(
          g =>
            new Condition('AssetCats', ODataOperators.Any, g.Id).toString() ||
            '',
        )
        .filter(f => f !== '')
        .join(' and ')})`,
    );
    predicates.push(
      '(' + byCats.filter(f => f !== '' && f !== '()').join(' or ') + ')',
    );
  }
  if (groupIds !== undefined && groupIds.length > 0) {
    predicates.push(
      new Condition(
        'Groups',
        ODataOperators.Includes,
        groupIds.map(g => {
          return { Id: g } as Identifiable<number>;
        }),
      ).toString() || '',
    );
  }
  if (isOfflineServiceType) {
    predicates.push(
      new Condition('DowntimeEnabled', ODataOperators.Equals, false),
    );
  } else if (downtime) {
    predicates.push(
      new Condition('AssetAlertType', ODataOperators.Equals, true),
    );
    predicates.push(
      new Condition('DowntimeEnabled', ODataOperators.Equals, true),
    );
  }
  if (calibration === true) {
    predicates.push(
      new Condition('ShowOnCalibrationHistory', ODataOperators.Equals, true),
    );
  }
  predicates.push(new Condition('Statuses', ODataOperators.Any, true, 'New'));
  return predicates || [];
};
export function withParamsFilter(
  assets: IAssetDto[] | undefined,
  groupIds: number[] | undefined,
  downtime: boolean | undefined,
) {
  return (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };
    const predicates: (
      | string
      | Condition<IWorkOrderTypeDto>
    )[] = filteredWorkOrderTypes(assets, groupIds, downtime);
    params.$filter = new ODataFilterBuilder<IWorkOrderTypeDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm === null ? undefined : searchTerm,
      globalServiceGroupFilter: [],
    }).toString();
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  };
}
const loadData = (
  predicates: (string | Condition<IWorkOrderTypeDto>)[] | undefined,
  queryUrl?: string,
  useSearchOrPredicate?: boolean,
) =>
  getAutoCompleteLoadDataFn<IWorkOrderTypeDto>({
    url: url ?? queryUrl,
    predicates: predicates,
    select: [
      'Id',
      'Name',
      'UserInquiry',
      'AssetAlertType',
      'QuickCreation',
      'ReportSymptoms',
      'UserCreated',
      'NotifySGA',
      'NotifyCreator',
      'NotifyInstrumentAdmin',
      'NotifyLabTech',
      'Active',
      'StaffOnly',
      'AutoCreateEvent',
      'RepairCodesEnabled',
      'AssignedToCoreAdmin',
      'AssignedToInstrumentAdmin',
      'AssignedToLabTech',
      'AssignedToCreator',
      'ShowOnCalibrationHistory',
      'NotifyAdditionalEmails',
      'UserInquiryGuidance',
      'RepeatsOn',
      'DefaultSubject',
      'DefaultCreatedBy',
      'CustomFormId',
      'RepeatsEveryNum',
      'RepeatsEveryType',
      'RepeatsBy',
      'AutoCreateFor',
      'DefaultRemaindBefore',
      'RepetitiveOptions',
      'DowntimeReasonTypeId',
      'DowntimeDuration',
      'CreatedDate',
      'UpdateDate',
      'DefaultEventTime',
      'AssignedUsers',
      'AssetCats',
      'Groups',
      'FormValues',
      'Statuses',
      'NotEditable',
      'TitleEnabled',
      'EventTimeEnabled',
      'DowntimeEnabled',
      'AssigneeEnabled',
    ],
    useSearchOrPredicate: useSearchOrPredicate,
  });
export function WorkOrderTypePicker(props: WorkOrderTypePickerProps) {
  const innerLoadData = (predicates, queryUrl) =>
    loadData(props.predicates, queryUrl, props.useSearchOrPredicate);
  // const loadData = withParamsFilter(
  //   props.assets,
  //   props.groupIds,
  //   props.downtime,
  // );
  return (
    <AutocompletePicker
      size={props.size}
      id={props.id || 'workordertypeId'}
      mini={props.mini ? true : undefined}
      loadData={innerLoadData(props.predicates, url)}
      {...props}
    />
  );
}
export const loadWorkOrderTypes = loadData;
