/**
 *
 * ReservationOrDowntimePickerFilter
 *
 */

import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { ReservationOrDowntimePicker } from 'app/components/pickers/AutocompletePickers/ReservationOrDowntimePicker';
import { ODataOperators } from 'api/odata/ODataFilter';
import { GetEnum, GetRawValue } from '../../types/FilterParam';
import { TFunction } from 'i18next';
import { ReservationOrDowntimeEnum } from 'enums/ReservationOrDowntimeEnum';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';

export function ReservationOrDowntimePickerFilter(
  props: IFilterComponentProps,
) {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <ReservationOrDowntimePicker
      value={props.value}
      isOpen={props.open}
      onChange={handleOnChange}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      id={props.id || 'ReservationOrDowntimePicker'}
    />
  );
}
export function getReservationOrDowntimePickerFilter<TRow>(
  accessor: keyof TRow,
  params: URLSearchParams,
  t: TFunction,
): IFilterSettings<TRow> {
  return {
    id: 'ReservationOrDowntime',
    fieldName: accessor,
    displayNameKey: translations.ReservationOrDowntime,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'ReservationOrDowntime',
    value: GetEnum(
      GetRawValue(params, 'ReservationOrDowntime'),
      ReservationOrDowntimeEnum,
      t,
    ),
    component: ReservationOrDowntimePickerFilter,
  };
}
export default ReservationOrDowntimePickerFilter;
