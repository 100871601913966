import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import {
  FlexRowContent,
  FlexRowDiv,
} from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import React from 'react';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface CustomEmptyTableProps {
  id?: string;
  headTitle?: string;
  title?: string;
  shortView?: boolean;
}

export function CustomEmptyTable(props: CustomEmptyTableProps) {
  const { headTitle, title } = props;
  return (
    <FlexRowDiv>
      <FlexRowContent>
        {props.shortView ? (
          <React.Fragment>
            {headTitle && (
              <BasicTypography variant={'boldM'}>{headTitle}</BasicTypography>
            )}
            {title && (
              <BasicTypography variant={'boldS'}>{title}</BasicTypography>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {headTitle && <H3>{headTitle}</H3>}
            {title && <H4>{title}</H4>}
          </React.Fragment>
        )}
      </FlexRowContent>
    </FlexRowDiv>
  );
}
