import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { ExportImportActions } from 'app/components/PopupImportExport/Actions';
import { AddWorkOrder } from 'app/pages/Actions/GlobalSelectedActions/AddWorkOrder';
//import { AssetRangesExportForImport } from 'app/pages/Actions/GlobalSelectedActions/AssetRangesExportForImport';
import { BringBackUp } from 'app/pages/Actions/GlobalSelectedActions/BringBackUp';
import { CloneEquipmentSettings } from 'app/pages/Actions/GlobalSelectedActions/CloneEquipments';
import { DownWorkOrder } from 'app/pages/Actions/GlobalSelectedActions/DownWorkOrder';
import { OpenCalendar } from 'app/pages/Actions/GlobalSelectedActions/OpenCalendar';
import { OpenWorkOrders } from 'app/pages/Actions/GlobalSelectedActions/OpenWrokOrders';
import { RenderTypes } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/renderTypes';
import { useAssetsPermissions } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/useAssetsPermissionsUtils';
import { AddToLists } from 'app/pages/SavedViewsPage/AddToLists';
import { useAdminPermissions } from 'app/permissions/Asset/assetPermissions';
import { ScreensId } from 'enums/ConfigurableTypes';
import { TFunction } from 'i18next';
import fileDownload from 'js-file-download';
import { translations } from 'locales/translations';
import React from 'react';
import { useMemo } from 'react';
import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { IMyAssetsRow } from '../IMyAssetsRow';
import { openExportLink } from 'utils/url-utils';
import { ImportAdditionalData } from 'app/components/PopupImportExport/Actions/Types';
import { PrintSelectedAssetLabelsButton } from 'app/components/LabelPrint/PrintSelectedAssetLabelsButton';
import { mapToAssetDto } from 'app/pages/AssetPopUp/IAsset';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { ExportQRCodesButton } from 'app/components/QrCodeExport';
import { AssetsApi } from '../Actions/Api';

export interface GetSelectedActionsProps {
  enableSavedLists?: boolean;
  canExportAssetRanges?: boolean;
  isShowAllCores?: boolean;
  authenticatedUser?: AuthenticatedUser;
  useSidePanel?: boolean;
}

export function GetSelectedActions(
  t: TFunction,
  props: GetSelectedActionsProps,
): SelectedRowsActionRenderer<IMyAssetsRow>[] {
  const { allowedToView } = useAssetsPermissions();
  const ap = useAdminPermissions();
  const allowedToImport = React.useCallback(
    (rows: any[]): any[] => {
      return rows.filter(r => ap.allowedToAdd(r) && ap.allowedToEdit(r));
    },
    [ap],
  );
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const enabledByCores = React.useCallback(
    (rows: IMyAssetsRow[]) => {
      return !props.isShowAllCores
        ? true
        : rows.every(f =>
            selectedServiceGroups?.some(g => g.Id === f.ServiceGroupId),
          );
    },
    [props.isShowAllCores, selectedServiceGroups],
  );
  const SelectedActions: SelectedRowsActionRenderer<IMyAssetsRow>[] = useMemo(
    () => [
      rows =>
        props.enableSavedLists &&
        allowedToView(rows.map(mapToAssetDto), RenderTypes.AddToList) &&
        enabledByCores(rows) && (
          <AddToLists
            rows={rows}
            useSidePanel={props.useSidePanel}
            getEquipments={rows =>
              rows.map(item => {
                return {
                  Id: item.ServiceId,
                  Name: item.Name,
                } as Entity<number>;
              })
            }
            getAssets={rows => {
              let ids = rows.map(item => item.Id).join(',');
              return initAssetsData(ids);
            }}
          />
        ),
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.OpenCalendar) &&
        enabledByCores(rows) && (
          <OpenCalendar
            rows={rows}
            getEquipmentIds={rows =>
              rows.map(r => r.ServiceId ?? 0).filter(f => f > 0)
            }
            bookMultiple
          />
        ),
      /* Commented according to BKT-7297 */
      /*       (rows, refresh) =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.OpenTimeline) && (
          <OpenTimeline
            rows={rows}
            getEquipmentIds={rows =>
              rows.map(r => r.ServiceId ?? 0).filter(f => f > 0)
            }
          />
        ), */
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.OpenWorkOrders) &&
        enabledByCores(rows) && (
          <OpenWorkOrders
            rows={rows}
            getAssetIds={r => r.map(a => a.Id) || []}
          />
        ),
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.AddWorkOrder) &&
        enabledByCores(rows) && (
          <AddWorkOrder
            rows={rows}
            getAssets={rows => {
              let ids = rows.map(item => item.Id).join(',');
              return initAssetsData(ids);
            }}
            buttonName={t(translations.AddEvent)}
            useSidePanel={props.useSidePanel}
          />
        ),
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.DownWorkOrder) &&
        enabledByCores(rows) && (
          <DownWorkOrder
            rows={rows}
            getAssets={rows => {
              let ids = rows.map(item => item.Id).join(',');
              return initAssetsData(ids);
            }}
            buttonName={t(translations.TakeDown)}
            useSidePanel={props.useSidePanel}
          />
        ),
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.CloneEquipments) && (
          <CloneEquipmentSettings
            rows={rows}
            getEquipmentIds={rows =>
              rows.map(r => r.ServiceId ?? 0).filter(f => f > 0)
            }
          />
        ),
      (rows, onRefresh) => {
        if (
          allowedToView(rows.map(mapToAssetDto), RenderTypes.ExportForImport)
        ) {
          const exportRows = allowedToImport(rows);
          if (exportRows.length > 0) {
            return (
              <SelectedRowsActionIconButton
                key="export-forimport-assets"
                title={t(translations.ExportForImport)}
                text={t(translations.ExportForImport)}
                confirm={false}
                validConfirm={false}
                rows={rows}
                onRefresh={onRefresh}
                variantButton="button"
                action={() =>
                  new Promise<IResponseType>(async (resolve, reject) => {
                    const selectedRows = exportRows.map(item => item.Id);
                    const res = await ExportImportActions.ExportForImport(
                      ScreensId.MyAssets,
                      selectedRows,
                    );
                    const cd = res.headers['content-disposition'];
                    const filename =
                      cd !== undefined
                        ? cd.split('filename=')[1]
                        : 'ExportForImport_assets.xlsx';
                    fileDownload(res.data, filename);
                    let messages: IResponseType = {
                      SuccessMessages: [],
                      ErrorMessages: [],
                      WarningMessages: [],
                    };
                    resolve(messages);
                  })
                }
              />
            );
          } else {
            return <></>;
          }
        } else {
          return <></>;
        }
      },
      (rows, onRefresh) => {
        if (
          allowedToView(rows.map(mapToAssetDto), RenderTypes.ExportForImport) &&
          props.canExportAssetRanges
        ) {
          const exportRows = allowedToImport(rows);
          if (exportRows.length > 0) {
            return (
              //<AssetRangesExportForImport
              //rows={rows}
              //getAssetIds={rows => rows.map(r => r.Id)}
              ///>
              <SelectedRowsActionIconButton
                key="export-forimport-assetRanges"
                title={t(translations.ExportAssetRangesTooltip)}
                text={t(translations.ExportAssetRanges)}
                confirm={false}
                validConfirm={false}
                rows={rows}
                onRefresh={onRefresh}
                variantButton="button"
                action={() =>
                  new Promise<IResponseType>(async (resolve, reject) => {
                    const selectedRows = exportRows.map(item => item.Id);
                    const res = await ExportImportActions.ExportForImport(
                      ScreensId.MyAssets,
                      selectedRows,
                      ImportAdditionalData.AssetRanges,
                    );
                    const cd = res.headers['content-disposition'];
                    const filename =
                      cd !== undefined
                        ? cd.split('filename=')[1]
                        : 'ExportForImport_assetRanges.xlsx';
                    fileDownload(res.data, filename);
                    let messages: IResponseType = {
                      SuccessMessages: [],
                      ErrorMessages: [],
                      WarningMessages: [],
                    };
                    resolve(messages);
                  })
                }
              />
            );
          } else {
            return <></>;
          }
        } else {
          return <></>;
        }
      },
      (rows, onRefresh) => {
        if (
          allowedToView(rows.map(mapToAssetDto), RenderTypes.DownloadImages)
        ) {
          return (
            <SelectedRowsActionIconButton
              key="download-asset-images"
              title={t(translations.DownloadAssetImages)}
              text={t(translations.DownloadAssetImages)}
              confirm={false}
              validConfirm={false}
              rows={rows}
              onRefresh={onRefresh}
              variantButton="button"
              action={() =>
                new Promise<IResponseType>(async (resolve, reject) => {
                  const rowHasNoImages =
                    rows.filter(f => f.ImageName === null).length > 0;
                  if (rowHasNoImages) {
                    const messages: IResponseType = {
                      SuccessMessages: [],
                      ErrorMessages: ['One or more row has no image file'],
                      WarningMessages: [],
                    };
                    resolve(messages);
                  } else {
                    const downUrl = `api/AssetImages/Selected?selectedIds=${rows
                      .map(r => r.Id)
                      .join(',')}`;
                    await openExportLink(downUrl);
                    const messages: IResponseType = {
                      SuccessMessages: [t(translations.Success)],
                      ErrorMessages: [],
                      WarningMessages: [],
                    };
                    resolve(messages);
                  }
                })
              }
            />
          );
        } else return <></>;
      },
      rows =>
        allowedToView(rows.map(mapToAssetDto), RenderTypes.BringBackUp) &&
        enabledByCores(rows) &&
        rows.some(f => f.StatusId === 2 || f.StatusId === 3) && (
          <BringBackUp
            rows={rows}
            getEquipmentIds={rows =>
              rows.map(r => r.ServiceId ?? 0).filter(f => f > 0)
            }
            getAssetIds={rows => rows.map(r => r.Id ?? 0).filter(f => f > 0)}
            useSidePanel={props.useSidePanel}
          />
        ),
      (rows, onRefresh) => {
        if (
          allowedToView(rows.map(mapToAssetDto), RenderTypes.PrintAssetLabels)
        ) {
          return (
            <PrintSelectedAssetLabelsButton
              key="printlabels"
              rows={rows}
              onRefresh={onRefresh}
              title={t(translations.PrintLabels)}
            />
          );
        }
      },
      (rows, onRefresh, toggleAllRowsSelected) => {
        if (allowedToView(rows.map(mapToAssetDto), RenderTypes.ExportQRCodes)) {
          return (
            <>
              <ExportQRCodesButton
                url="/api/QrCodes/assets"
                rows={rows}
                toggleAllRowsSelected={toggleAllRowsSelected}
              />
            </>
          );
        } else {
        }
      },
      (rows, onRefresh) => (
        <SelectedRowsActionIconButton
          key="startupgrade"
          text={t(translations.UpgradeLocalApp)}
          title={t(translations.UpgradeLocalApp)}
          rows={rows}
          onRefresh={onRefresh}
          variantButton="button"
          action={() =>
            new Promise<IResponseType>((resolve, reject) => {
              const ids = rows.map(r => r.Id);
              return AssetsApi.LocalAppUpgrade(ids)
                .then(response => {
                  const messages: IResponseType = {
                    SuccessMessages: [t(translations.Success) as string],
                    ErrorMessages: [],
                    WarningMessages: [],
                  };
                  resolve(messages);
                })
                .catch(error => {
                  console.log('status error', error);
                  let err = t(translations.Failed) as string;
                  reject(err);
                });
            })
          }
        />
      ),
    ],
    [
      allowedToImport,
      allowedToView,
      enabledByCores,
      props.canExportAssetRanges,
      props.enableSavedLists,
      props.useSidePanel,
      t,
    ],
  );

  return SelectedActions;
}
