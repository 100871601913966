import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { StockRenewalState } from '../Details/slice/types';
import React from 'react';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';

import {
  selectEditCreatableService,
  selectStockRenewalHasErrors,
  selectStockRenewalValueHandler,
} from '../Details/slice/selectors';
import { useOfflineServiceStateSlice } from '../Details/slice';
import { isEmpty } from 'lodash';
import {
  FieldHandler,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { BookQuickServices } from '../Details/components/BookQuickServices';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { dateUtils } from 'utils/date-utils';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { FormCheckbox } from 'app/components/Forms/FormCheckbox';
import { loadOfflineServiceData } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { ErrorsSummury } from 'app/components/Forms/FormsLayout/FormErrorSummury';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';
import { Condition } from 'api/odata/ODataFilter';

export interface StockRenewalFormProps {
  onSubmit: (item: StockRenewalState) => void;
  onAddServiceClick: (services: number[]) => void;
  initialValues: StockRenewalState;
  processing?: boolean;
  bindSubmitForm: any;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<StockRenewalState>>;
  globalSettings: GlobalSettingsType;
  onRenewStockClick?: (service: IOtherServices) => void;
  editCreatable?: boolean;
  onAddBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
}

export const StockRenewalForm = React.memo(function StockRenewalForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  globalSettings,
  innerFormRef,
  onRenewStockClick,
  onAddServiceClick,
  editCreatable,
  onAddBatchClick,
}: StockRenewalFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();

  const fieldHandler = useSelector(selectStockRenewalValueHandler);
  const hasError = useSelector(selectStockRenewalHasErrors);
  const editCreatableService = useSelector(selectEditCreatableService);
  const { newDate } = useSystemDate();
  // const readonly = !isAdmin;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: StockRenewalState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
      validateForms(true);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetStockRenewalValueHandler());
  };

  /// Local State ///
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [recentMostBatchesChanged, setRecentMostBatchesChanged] =
    React.useState(false);
  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [servicesSelected, setServicesSelected] = useAsyncExtendedState<
    IOtherServices[]
  >(initialValues.Services ?? []);
  // const [serviceTypeSelected, setServiceTypeSelected] = useAsyncExtendedState<
  //   IOfflineServiceFilterDto | undefined
  // >(initialValues.ServiceType ?? undefined);
  // const IsAdmin = React.useCallback(
  //   (service: IOtherServices) => {
  //     return user?.IsAllGroupOrLabTechAdmin(
  //       [service.ServiceGroupId ?? 0],
  //       [
  //         {
  //           Id: service.ServiceTypeID,
  //           Name: service.ServiceType,
  //           ServiceTypeId: ServiceType.Offline,
  //         } as IServiceTypeFilterDto,
  //       ],
  //     );
  //   },
  //   [user],
  // );
  // const [errorMessages, setErrorMessages] = React.useState<IndexNode[]>([]);
  // const addErrorMessages = React.useCallback((data: string[]) => {
  //   if (data.length > 0) {
  //     setErrorMessages([]);
  //     messagesHandler(data, 'error', (nodes: IndexNode[]) =>
  //       setErrorMessages(() => nodes),
  //     );
  //   }
  // }, []);
  const handleFormChange = React.useCallback(
    (
      values: StockRenewalState,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (recentMostBatchesChanged) {
        setRecentMostBatchesChanged(false);
      }
      if (serviceChanged) {
        setServiceChanged(false);
      }
    },
    [recentMostBatchesChanged, serviceChanged],
  );
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);

  // useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);

  // React.useEffect(() => {
  //   if (!!serviceChangeState) {
  //     console.log('service settings: ', serviceSettings);
  //     if (!!serviceChangeState) {
  //       if (serviceSettings.budgetVisible) {
  //         dispatch(
  //           connectedFiltersActions.setConnectedValue({
  //             fieldKey: 'Budget',
  //             fieldValue: serviceChangeState.DefaultBudget,
  //           }),
  //         );
  //         if (
  //           serviceSettings.ReservationUserGroupGroupByBudgetUserGroup &&
  //           serviceChangeState.DefaultBudget !== null
  //         ) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'UserGroup',
  //               fieldValue: {
  //                 Id: serviceChangeState.DefaultBudget.UserGroupId,
  //                 Name: serviceChangeState.DefaultBudget.UserGroupName,
  //               },
  //             }),
  //           );
  //         }
  //         if (
  //           serviceSettings.budgetExpirementVisible &&
  //           serviceChangeState.DefaultExperiment !== null
  //         ) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'BudgetExperiment',
  //               fieldValue: serviceChangeState.DefaultExperiment,
  //             }),
  //           );
  //         }
  //       } else {
  //         if (serviceSettings.isUserGroupCoordinator) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'UserGroup',
  //               fieldValue: {
  //                 Id: user?.ActiveUserGroup?.Id,
  //                 Name: user?.ActiveUserGroup?.Name,
  //               },
  //             }),
  //           );
  //         }
  //       }
  //     }
  //     dispatch(actions.resetServiceChangeState());
  //   }
  // }, [
  //   actions,
  //   connectedFiltersActions,
  //   dispatch,
  //   serviceChangeState,
  //   serviceSettings,
  //   user?.ActiveUserGroup?.Id,
  //   user?.ActiveUserGroup?.Name,
  // ]);
  React.useEffect(() => {
    dispatch(
      actions.setStockRenewalValue({
        fieldKey: 'Services',
        fieldValue: servicesSelected,
      }),
    );
  }, [actions, dispatch, servicesSelected]);

  const otherServicesSchema = Yup.array().of(
    Yup.mixed() as Yup.SchemaOf<IOtherServices>,
  );
  const changeOfflineServices = React.useCallback(
    (services: IOtherServices[], operation: 'add' | 'remove' | 'update') => {
      if (operation === 'add') {
        let addedServ = services.filter(
          f => !servicesSelected.some(s => s.ServiceTypeID === f.ServiceTypeID),
        );
        setServicesSelected([...servicesSelected, ...addedServ]);
        setServiceChanged(true);
      }
      if (operation === 'remove') {
        let otherServices = servicesSelected.filter(f => {
          return !services.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServicesSelected(otherServices);
        setServiceChanged(true);
      }
      if (operation === 'update') {
        setServicesSelected(
          servicesSelected.map(f => {
            let service = services.find(
              s => s.ServiceTypeID === f.ServiceTypeID,
            );
            if (!!service && service !== null) {
              return Object.assign({}, f, service);
            } else {
              return f;
            }
          }),
        );
      }
    },
    [servicesSelected, setServicesSelected],
  );
  const stockRenwevalSchema: Yup.SchemaOf<StockRenewalState> = Yup.object({
    Services: otherServicesSchema
      .min(1)
      .label(t(translations.Consumables) as string)
      .required(t(translations.err_OfflineServiceTypeRequired) as string),
    DateReceived: Yup.date()
      .default(newDate())
      .label(t(translations.DateReceived) as string)
      .required(t(translations.err_DateReceived_required) as string),
    AutoGenerateNewBatches: Yup.boolean()
      .nullable()
      .default(true)
      .label(t(translations.AutoGenerateNewBatches) as string),
  });
  const initServices = async (): Promise<IOtherServices[]> => {
    return new Promise(async resolve => {
      resolve(servicesSelected);
    });
  };
  const prepareService = (
    serviceType: IOfflineServiceFilterDto,
    otherData?: StockRenewalState,
  ) => {
    let batch =
      otherData?.AutoGenerateNewBatches === false
        ? serviceType.InventoryBatches.length > 0
          ? serviceType.InventoryBatches.sort((a, b) => b.Id - a.Id)[0]
          : null
        : null;
    let data = {
      Id: 0,
      BookedBy: user?.Id,
      UserDisplayName: user?.Name,
      BudgetID: null,
      Budget: null,
      Quantity: serviceType.DefaultQuantity,
      DefaultQuantity: serviceType.DefaultQuantity,
      IntQuantityOnly: serviceType.IntQuantityOnly,
      DiscountFactor: 1,
      Mandatory: false,
      Remarks: t(translations.StockRenewal) as string,
      ServiceDate: dateUtils.formatISO(
        dateUtils.dateOrStringToDate(otherData?.DateReceived ?? newDate()),
      ),
      TrackInventory: serviceType.TrackInventory,
      ServiceTypeID: serviceType.Id,
      ServiceType: serviceType.Name,
      ServiceGroupId: serviceType.ServiceGroupId,
      Units: serviceType.UnitTypeName,
      UserGroup: user?.ActiveUserGroup?.Id,
      UserGroupName: user?.ActiveUserGroup?.Name,
      AllowToUser: serviceType.AllowToUser,
      BudgetsTurnedOn: serviceType.BudgetsTurnedOn,
      StaffOnly: serviceType.StaffOnly,
      InventoryBatchesEnabled: serviceType.InventoryBatchesEnabled,
      InventoryBatchId: batch === null ? null : batch.Id,
      InventoryBatchName: batch === null ? null : batch.Name,
      InventoryBatchAmount: batch === null ? null : batch.Inventory,
      RenewStockInventory: true,
      AutoGenerateNewBatches: otherData?.AutoGenerateNewBatches ?? true,
    } as IOtherServices;
    return data;
  };
  const createService =
    (values: StockRenewalState) =>
    async (
      serviceType: IOfflineServiceFilterDto,
    ): Promise<IOtherServices | undefined> => {
      return new Promise(async (resolve, reject) => {
        let staticService = prepareService(serviceType, values);
        changeOfflineServices([staticService], 'add');
        resolve(staticService);
      });
    };
  const createServices =
    (values: StockRenewalState) =>
    async (
      serviceTypes: IOfflineServiceFilterDto[],
    ): Promise<IOtherServices[]> => {
      return new Promise(async (resolve, reject) => {
        let staticServices = serviceTypes.map(f => prepareService(f, values));
        changeOfflineServices(staticServices, 'add');
        resolve(staticServices);
      });
    };
  const onRemoveService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'remove');
      resolve(service);
    });
  };
  const onRemoveServices = async (services: IOtherServices[]) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices(services, 'remove');
      resolve(services);
    });
  };
  const onUpdateService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'update');
      resolve(service);
    });
  };
  const onAddAndRemoveServices =
    (values: StockRenewalState) =>
    async (
      serviceTypes: IOfflineServiceFilterDto[],
      removed: IOtherServices[],
    ): Promise<IOtherServices[]> => {
      return new Promise(async (resolve, reject) => {
        let staticServices = serviceTypes.map(f => prepareService(f, values));
        let addedServ = staticServices.filter(
          f => !servicesSelected.some(s => s.ServiceTypeID === f.ServiceTypeID),
        );
        let otherServices = servicesSelected.filter(f => {
          return !removed.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServicesSelected([...otherServices, ...addedServ]);
        setServiceChanged(true);
        resolve([...otherServices, ...addedServ]);
      });
    };
  const loadServiceTypes = async (
    searchTerm: string | null,
    predicates: (string | Condition<IOfflineServiceFilterDto>)[] | undefined,
  ): Promise<IOfflineServiceFilterDto[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await loadOfflineServiceData(
          predicates,
          'base',
          [],
          undefined,
          'InventoryBatches',
        )(searchTerm, undefined);
        resolve(data.value);
      } catch (error) {
        resolve([]);
      }
    });
  };

  React.useEffect(() => {
    if (
      !!editCreatableService &&
      servicesSelected.some(
        f => f.ServiceTypeID === editCreatableService.ServiceTypeID,
      )
    ) {
      changeOfflineServices([editCreatableService], 'update');
      dispatch(actions.setEditCreatableService(undefined));
      dispatch(actions.setRefreshConsumableOfflineServices(true));
    }
  }, [
    actions,
    changeOfflineServices,
    dispatch,
    editCreatableService,
    servicesSelected,
  ]);
  return (
    <>
      <Formik
        validationSchema={stockRenwevalSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
                console.debug('errors', responseErrors);
              } else if (
                values.AutoGenerateNewBatches === false &&
                values.Services.some(
                  f => f.InventoryBatchesEnabled && f.InventoryBatchId === null,
                )
              ) {
                // addErrorMessages([
                //   `No batch associated with ${
                //     values.Services.filter(
                //       f =>
                //         f.InventoryBatchesEnabled &&
                //         f.InventoryBatchId === null,
                //     )[0].ServiceType
                //   }`,
                // ]);
                formikHelpers.setErrors({
                  Services: `No batch associated with ${
                    values.Services.filter(
                      f =>
                        f.InventoryBatchesEnabled &&
                        f.InventoryBatchId === null,
                    )[0].ServiceType
                  }`,
                });
                formikHelpers.setSubmitting(false);
              } else if (values.Services.length < 1) {
                // addErrorMessages([
                //   t(translations.err_OfflineServiceTypeRequired) as string,
                // ]);
                formikHelpers.setErrors({
                  Services: t(
                    translations.err_OfflineServiceTypeRequired,
                  ) as string,
                });
                formikHelpers.setSubmitting(false);
              }
              // else if (errorMessages.length > 0) {
              //   console.debug('errors', errorMessages);
              //   formikHelpers.setSubmitting(false);
              // }
              else {
                console.debug('submitting', values);
                formikHelpers.setSubmitting(true);
                handleSubmit(values);
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Consumables errors', formik.errors);
          }
          console.log('Consumables submitting', formik.isSubmitting);
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['Services']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <ErrorsSummury errors={formik.errors} />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                //errors={errorMessages}
                // successNodes={successMessages}
                >
                  <FormFieldsSection
                    titleSection={
                      t(translations.ReportConsumablesMainInfo) as string
                    }
                  >
                    <FormRow fullRow>
                      <FormBookitDateTimePicker
                        fullWidth
                        id="DateReceivedID"
                        name="DateReceived"
                        // clear={false}
                        // label={t(translations.DateReceived) as string}
                        label={t(translations.ReceivedDateTimeLabel) as string}
                        labelInline={true}
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    <FormRow
                      fullRow={true}
                      hide={
                        formik.values.Services.length > 0 &&
                        !formik.values.Services.some(
                          f => f.InventoryBatchesEnabled,
                        )
                      }
                    >
                      <FormCheckbox
                        name="AutoGenerateNewBatches"
                        info={
                          servicesSelected.length > 1
                            ? t(
                                translations.AutoGenerateNewBatchesDisabled_info,
                              )
                            : t(translations.AutoGenerateNewBatches_info)
                        }
                        label={t(translations.AutoGenerateNewBatches)}
                        disabled={
                          formik.isSubmitting || servicesSelected.length > 0
                        }
                        onChange={() => setRecentMostBatchesChanged(true)}
                      />
                    </FormRow>
                  </FormFieldsSection>
                  <FormFieldsSection
                    titleSection={t(translations.ReportedConsumables) as string}
                  >
                    <BookQuickServices
                      createServiceFromServiceType={createService(
                        formik.values,
                      )}
                      createServicesFromServiceType={createServices(
                        formik.values,
                      )}
                      onAddAndRemoveServices={onAddAndRemoveServices(
                        formik.values,
                      )}
                      initServicesFunc={initServices}
                      onServiceRemove={onRemoveService}
                      onServicesRemove={onRemoveServices}
                      onServiceUpdate={onUpdateService}
                      onServiceClick={onRenewStockClick}
                      isEditMode={false}
                      disabled={false}
                      useAssetSelection={true}
                      onAddClick={onAddServiceClick}
                      userName={user?.Id}
                      createdEditable={editCreatable}
                      loadSeviceTypes={loadServiceTypes}
                      renewStock={true}
                      onAddBatchClick={onAddBatchClick}
                    />
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
