import { CircularProgress } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import { BookitChip } from 'app/components/BasicChip';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { autocompleteStyles } from 'styles/MUIExtend/autocompleteStyles';
import { Entity } from 'types/common';
import clsx from 'clsx';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';

export interface AutocompleteMultiPickerProps<
  TKey,
  TValue extends Entity<TKey> = Entity<TKey>
> extends Omit<
    AutocompleteProps<TValue, true, boolean | undefined, false>,
    | 'multiple'
    | 'loading'
    | 'open'
    | 'options'
    | 'renderInput'
    | 'onChange'
    | 'classes'
    | 'renderTags'
    | 'renderOptionLabel'
    | 'renderOptions'
  > {
  value?: TValue[];
  label?: string;
  loadData: (searchTerm: string | null) => Promise<TValue[]>;
  size?: 'medium' | 'small';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  isOpen?: boolean;
  style?: CSSProperties;
  name?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onChange: (value: TValue[]) => void;
  required?: boolean;
  info?: string;
  id?: string;
  renderTags?: (
    value: TValue[],
    getTagProps: AutocompleteGetTagProps,
  ) => React.ReactNode | undefined;
  renderOptionLabel?: (value: TValue) => string | undefined;
  renderOptions?: (
    option: TValue,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode | undefined;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChipClick?: (chip: TValue) => void;
  ariaLabel?: string;
  /**
   * Controls width of selected option chips. If true, every selected option will occupy full width of the picker, otherwise width will depend on option text content length
   */
  fullWdithOptions?: boolean;
  onPickerOpen?: () => void;
}

export function AutocompleteMultiPicker<
  TKey,
  TValue extends Entity<TKey> = Entity<TKey>
>({
  isOpen,
  size,
  mini,
  onOpen,
  onClose,
  label,
  fullWidth,
  fullWdithOptions,
  variant,
  name,
  placeholder,
  error,
  helperText,
  required,
  renderTags,
  loadData,
  disableClearable,
  onChange,
  onInputChange,
  getOptionSelected,
  getOptionLabel,
  renderOptionLabel,
  renderOptions,
  onBlur,
  value,
  defaultValue,
  info,
  id,
  onChipClick,
  ariaLabel,
  inputValue,
  onPickerOpen,
  ...props
}: AutocompleteMultiPickerProps<TKey, TValue>) {
  const classes = autocompleteStyles();
  let firstOpen = isOpen;
  const [open, setOpen] = React.useState(firstOpen);
  const [options, setOptions] = React.useState<TValue[] | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [hasSelected, setSelected] = React.useState(
    defaultValue && defaultValue.length > 0,
  );
  const [newInputValue, setInputValue] = React.useState(inputValue || '');
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = isMobile || (sidePanelOpen && !sidePanelExpanded);
  const loading = open && options === null;
  useEffectOnMount(() => {
    setSelected((value || []).length > 0);
  });
  React.useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      try {
        const data = await loadData(searchTerm);
        active && setOptions(data as TValue[]);
      } catch {}
    })();

    return () => {
      active = false;
    };
  }, [loading, loadData, searchTerm]);

  React.useEffect(() => {
    if (!open) setOptions(null);
  }, [open]);

  const handleRenderTags = (
    value: TValue[],
    getTagProps: AutocompleteGetTagProps,
  ) => {
    if (renderTags === undefined) {
      return value.map((option: TValue, index: number) => {
        const color = ((option as unknown) as IServiceFilterDto).Color;
        return (
          <BookitChip
            onClick={() => onChipClick?.(option)}
            variant="default"
            size="small"
            label={option.Name}
            custombgcolor={color ?? undefined}
            fullwidth={fullWdithOptions}
            {...getTagProps({ index })}
          />
        );
      });
    } else {
      return renderTags(value, getTagProps);
    }
  };
  const handleRenderOptionLabel = (option: TValue) => {
    return renderOptionLabel === undefined
      ? option.Name
      : (renderOptionLabel(option) as string);
  };
  return (
    <Autocomplete
      id={id || 'base-multi-autocomplete'}
      multiple
      classes={{
        ...classes,
        root: clsx(
          classes.root,
          'multiSelect',
          showShortView ? 'shortView' : undefined,
          !!label ? 'hasLabel' : undefined,
        ),
      }}
      loading={loading}
      options={options ?? []}
      open={open || false}
      onOpen={e => {
        //onOpen && onOpen(e);
        !disableClearable && setOptions(null);
        setSearchTerm(null);
        setOpen(true);
        !!onPickerOpen && onPickerOpen();
      }}
      onClose={(e, r) => {
        //onClose && onClose(e, r);
        setSearchTerm(null);
        setOpen(false);
      }}
      onChange={(e, v, r, d) => {
        setSearchTerm(null);
        setInputValue('');
        setSelected(v.length > 0);
        onChange(v);
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'reset') return;
        setOptions(null);
        setInputValue(newValue);
        if (reason === 'input') setSearchTerm(newValue);
        //onInputChange && onInputChange(event, newValue, reason);
      }}
      getOptionSelected={
        getOptionSelected ?? ((option, value) => option.Id === value.Id)
      }
      getOptionLabel={handleRenderOptionLabel}
      renderTags={handleRenderTags}
      size={size}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      value={value ?? []}
      inputValue={newInputValue}
      popupIcon={<Icon icon="chevron-down" size="xs" />}
      closeIcon={<Icon icon="circle-xmark" size="xs" />}
      selectOnFocus={isMobile ? false : true}
      renderInput={params => (
        <BaseTextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          size={size}
          variant={variant || 'standard'}
          name={name}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          required={required}
          inputProps={{
            ...params.inputProps,
            'aria-label': label
              ? undefined
              : `${ariaLabel ? ariaLabel : 'search input'}`,
          }}
          InputLabelProps={{
            classes: { root: 'multiSelect' },
            ...params.InputLabelProps,
          }}
          InputProps={{
            autoFocus: firstOpen,
            classes: {
              root: clsx(
                'multiSelect',
                showShortView ? 'shortView' : undefined,
                !!label ? 'hasLabel' : undefined,
              ),
            },
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={params.size === 'small' ? 12 : 16}
                    style={{
                      position: 'absolute',
                      right: 32,
                      top: 'calc(50% - 8px)',
                    }}
                  />
                ) : info ? (
                  <Tooltip
                    arrow
                    title={info}
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: 'calc(50% - 10.5px)',
                      right: hasSelected ? 52 : 32,
                    }}
                  >
                    <span>
                      <Icon
                        icon="info-circle"
                        color={error ? 'danger' : 'default'}
                        colorExtend="textHover"
                        style={{
                          width: 12,
                          height: 12,
                        }}
                      />
                    </span>
                  </Tooltip>
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: params.InputProps.startAdornment,
          }}
        />
      )}
      {...props}
    />
  );
}
