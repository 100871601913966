import { Timestamp } from 'types/Timestamp';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { dateUtils } from './date-utils';

/**
 * Converts date/time from a given tz to UTC
 * @param date date (time part is ignored)
 * @param time time of day (date part is ignored)
 * @param timeZone source time zone
 * @returns
 */
export function fromTimZoneToUTC(
  date: Date,
  time: Timestamp,
  timeZone?: string,
): Date {
  if (timeZone === undefined) {
    timeZone = 'UTC';
  }
  if (time.lastIndexOf('Z') === -1) {
    // Convert the ISO date string with offset to UTC 'America/Chicago'
    let utcDate = zonedTimeToUtc(time, timeZone);

    // Convert the UTC time back to the original timezone 'America/Chicago'
    const zonedDate = utcToZonedTime(utcDate, timeZone);
    return zonedDate;
    // // Format the date back to ISO format with the original offset
    // const formattedDate = formatISO(zonedDate, { representation: 'complete' });
  }
  let d = dateUtils.formatISO(date, {
    representation: 'date',
  });
  const t = parseExact(time);
  let result: Date = zonedTimeToUtc(`${d} ${t}`, timeZone, {});
  return result;
}

function parseExact(ts: Timestamp) {
  const regexp = /^\d\d\d\d-\d\d-\d\dT(?<timeOfDay>\d\d:\d\d:\d\d)|Z$/;
  const match = regexp.exec(ts);
  const result = match?.groups?.timeOfDay ?? undefined;
  return result;
}
function parseDateExact(ts: Timestamp) {
  const regexp = /^(?<dateOfDay>\d\d\d\d-\d\d-\d\d)T\d\d:\d\d:\d\d|Z$/;
  const match = regexp.exec(ts);
  const result = match?.groups?.dateOfDay ?? undefined;
  return result;
}

export function toTimeZoneUTCDate(
  datetime: Timestamp,
  timeZone?: string,
): Date {
  if (timeZone === undefined) {
    timeZone = 'UTC';
  }
  if (datetime.lastIndexOf('Z') === -1) {
    // Convert the ISO date string with offset to UTC 'America/Chicago'
    let utcDate = zonedTimeToUtc(datetime, timeZone);

    // Convert the UTC time back to the original timezone 'America/Chicago'
    const zonedDate = utcToZonedTime(utcDate, timeZone);
    return zonedDate;
    // // Format the date back to ISO format with the original offset
    // const formattedDate = formatISO(zonedDate, { representation: 'complete' });
  }
  let d = parseDateExact(datetime);
  let t = parseExact(datetime);
  let result: Date = zonedTimeToUtc(`${d} ${t}`, timeZone, {});
  return result;
}
export function formatToTimeZoneUTCDate(
  date: Date | string | null | undefined,
  timeZone?: string,
): string | null {
  if (date === null || date === undefined) {
    return null;
  }
  return dateUtils.formatISO(
    toTimeZoneUTCDate(
      typeof date === 'string' ? date : dateUtils.formatISO(date),
      timeZone,
    ),
  );
}
