import { StyledStatusLabel } from 'app/components/StyledComponents';

export interface StatusLabelProps {
  variant?: 'success' | 'info' | 'warning' | 'error';
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
}
export function StatusLabel(props: StatusLabelProps) {
  return (
    <StyledStatusLabel
      className="status-label"
      variant={props.variant}
      size={props.size}
    >
      {props.children}
    </StyledStatusLabel>
  );
}
