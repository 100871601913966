import { BasicTable, IBasicTableState } from 'app/components/BasicTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { ScreensId } from 'enums/ConfigurableTypes';
import * as React from 'react';
import { Column, IdType, Row } from 'react-table';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { getColumns } from '../Columns';
import { DefaultFilter } from '../DefaultFilter';
import { IMyAssetsRow } from '../IMyAssetsRow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAppSettings,
  selectglobalSettings,
  selectIsAuthenticated,
  selectShowAllCores,
} from 'app/slice/selectors';
import {
  SelectedRowsActionExtRenderer,
  SelectedRowsActionRenderer,
} from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { useGlobalServiceGroupsFilter } from 'app/hooks/useGlobalServiceGroupsFilter';
import {
  AppSettings,
  IsAssetFieldEnabled,
  IsAssetFieldIsPublic,
  IsModuleEnabled,
} from 'types/AppSettings';
import { Entity } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import { DetectIsMobile } from 'utils/mobileDetect';
import { BookitChipWithInfo } from 'app/components/BasicChip';
import { SavedViewSelected } from 'app/pages/SavedViewsPage/SavedViewPage/slice/types';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { GetFilters } from './Filter';
import { AssetActionType } from './AssetsSelection';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import useState from 'react-usestateref';
import { TableMode } from 'app/components/BasicTable/ControlledTable';
import { CustomEmptyTableProps } from 'app/components/BasicTable/components/CustomEmptyTable';
import { bookitColors } from 'styles/colors/bookitColors';
import { GetSelectedActions } from '../SelectedRowActions/GetSelectedActions';
import { AssetAttributes } from 'enums/AssetAttributes';
import { translations } from 'locales/translations';
import { AllowedSettings } from 'utils/globalSettings';
import { KnownModules } from 'types/KnownModules';
import { getOnlineServiceTypeAssetFilter } from 'app/components/pickers/StaticOptionsPickers/OnlineServiceTypePicker';

export interface AssetsSelectionTableProps {
  useSidePanel?: boolean;
  authenticatedUser?: AuthenticatedUser;
  closeSidePanel: () => void;
  setFilters?: (
    appliedFilters: IFilterSettings<IMyAssetsRow>[] | undefined,
  ) => void;
  allowMultiToggle: boolean;
  primaryAction?: (row: Row<IMyAssetsRow>) => void;
  primaryMultiAction: (rows: IMyAssetsRow[]) => void;
  isRowSelectable: (row: Row<IMyAssetsRow>) => boolean;
  getRequiredFilter?: (
    search: string,
    appSettings?: AppSettings | null,
  ) => string | undefined;
  assets?: Entity<number>[];
  screenName: string;
  multiModeDisabled?: boolean;
  openAsset: (id: number) => void;
  useRecentAssets?: boolean;
  RecentAssets?: React.ReactNode;
  actionType: AssetActionType;
  customPredicates?: (string | Condition<IMyAssetsRow>)[];
  isShortView?: boolean;
  emptyTable?: CustomEmptyTableProps;
  searchTerm?: string;
  serviceGroupId?: number;
}

export function AssetsSelectionTable(props: AssetsSelectionTableProps) {
  const {
    useSidePanel,
    authenticatedUser,
    setFilters,
    isRowSelectable,
    getRequiredFilter,
    assets,
    primaryAction,
    primaryMultiAction,
    screenName,
    multiModeDisabled,
    // openAsset,
    useRecentAssets,
    RecentAssets,
    actionType,
    customPredicates,
    isShortView = false,
    emptyTable,
    searchTerm,
    serviceGroupId,
  } = props;
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const showAllCores = useSelector(selectShowAllCores);
  const isMobile = DetectIsMobile();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const roomsEnabled = settings.GetBoolean(
    AllowedSettings[AllowedSettings.RoomUserRoleEnabled],
  );
  const canExportAssetRanges = IsAssetFieldEnabled(
    appSettings,
    AssetAttributes.Range,
  );
  const assetFieldEnabled = React.useCallback(
    (AssetField: AssetAttributes): boolean => {
      if (isAuthenticated) {
        return IsAssetFieldEnabled(appSettings, AssetField);
      } else {
        return (
          IsAssetFieldEnabled(appSettings, AssetField) &&
          IsAssetFieldIsPublic(appSettings, AssetField)
        );
      }
    },
    [appSettings, isAuthenticated],
  );
  const searchColumns = React.useMemo(() => {
    let result: string[] = [
      'Name',
      'AvailabilityTypeName',
      'DivisionName',
      'ServiceGroupName',
      'InstrumentType',
      //'RoomAreaName',
      //'InstrumentCatName',
    ];
    if (assetFieldEnabled(AssetAttributes.Building)) {
      result.push('Building');
    }
    if (assetFieldEnabled(AssetAttributes.Room)) {
      result.push('Room');
    }
    if (assetFieldEnabled(AssetAttributes.AssetCat)) {
      result.push('AssetCatName');
    }
    if (assetFieldEnabled(AssetAttributes.LocationsList)) {
      result.push('LocationName');
    }
    if (assetFieldEnabled(AssetAttributes.SubLocationsList)) {
      result.push('SubLocationName');
    }
    if (assetFieldEnabled(AssetAttributes.AssetCatGroup)) {
      result.push('AssetCatGroupName');
    }
    if (assetFieldEnabled(AssetAttributes.POnumber)) {
      result.push('POnumber');
    }
    if (assetFieldEnabled(AssetAttributes.AssetAccessories)) {
      result.push('AccessoriesText');
    }
    if (assetFieldEnabled(AssetAttributes.Contacts)) {
      result.push('ContactsText');
    }
    if (assetFieldEnabled(AssetAttributes.Experts)) {
      result.push('ExpertsText');
    }
    if (assetFieldEnabled(AssetAttributes.Suppliers)) {
      result.push('SuppliersText');
    }
    if (assetFieldEnabled(AssetAttributes.OtherNames)) {
      result.push('OtherNames');
    }
    if (assetFieldEnabled(AssetAttributes.Brand)) {
      result.push('Brand');
    }
    if (assetFieldEnabled(AssetAttributes.Description)) {
      result.push('Description');
    }
    if (assetFieldEnabled(AssetAttributes.ShortDescription)) {
      result.push('ShortDescription');
    }

    if (assetFieldEnabled(AssetAttributes.ServiceContract)) {
      result.push('ServiceContract');
    }
    if (assetFieldEnabled(AssetAttributes.Campus)) {
      result.push('Campus');
    }
    if (assetFieldEnabled(AssetAttributes.Tags)) {
      result.push('TagsText');
    }
    if (assetFieldEnabled(AssetAttributes.MainSpecRange)) {
      result.push('MainSpecRange');
    }
    if (assetFieldEnabled(AssetAttributes.AccountingId)) {
      result.push('AccountingId');
    }
    if (assetFieldEnabled(AssetAttributes.ModelNumber)) {
      result.push('ModelNumber');
    }
    if (assetFieldEnabled(AssetAttributes.FunctionalCharacteristics)) {
      result.push('FunctionalCharacteristics');
    }
    if (assetFieldEnabled(AssetAttributes.WebLink)) {
      result.push('WebLink');
    }
    if (assetFieldEnabled(AssetAttributes.Model)) {
      result.push('Model');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL0)) {
      result.push('ExtraDDL0Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL1)) {
      result.push('ExtraDDL1Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL2)) {
      result.push('ExtraDDL2Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL3)) {
      result.push('ExtraDDL3Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL4)) {
      result.push('ExtraDDL4Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL5)) {
      result.push('ExtraDDL5Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL6)) {
      result.push('ExtraDDL6Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL7)) {
      result.push('ExtraDDL7Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL8)) {
      result.push('ExtraDDL8Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL9)) {
      result.push('ExtraDDL9Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL10)) {
      result.push('ExtraDDL10Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL11)) {
      result.push('ExtraDDL11Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL12)) {
      result.push('ExtraDDL12Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL13)) {
      result.push('ExtraDDL13Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL14)) {
      result.push('ExtraDDL14Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL15)) {
      result.push('ExtraDDL15Value');
    }
    return result;
  }, [assetFieldEnabled]);

  const additionalColumns = React.useMemo(() => {
    let result: string[] = [
      'Id',
      'ServiceId',
      'AvailabilityTypeId',
      'ServiceGroupId',
      'ServiceTypeId',
      'AutoMode',
      'AvailabilityTypeName',
      'TrainingSessionsEnabled',
      'HideProjects',
      'AllowToUser',
      'StaffOnly',
      'AssetCatId',
      'AssetCatGroupId',
      'BuildingId',
      'FloorName',
      'RoomAreaId',
      'RemoteControlEnabled',
      'RegionId',
      'RemoteControlEnabled',
    ];
    if (IsModuleEnabled(appSettings, KnownModules.Divisions)) {
      result.push('DivisionAssetId');
      result.push('DivisionId');
      result.push('RegionId');
    }
    if (assetFieldEnabled(AssetAttributes.AssetCat)) {
      result.push('AssetCatId');
    }
    if (assetFieldEnabled(AssetAttributes.AssetCatGroup)) {
      result.push('AssetCatGroupId');
    }

    if (assetFieldEnabled(AssetAttributes.ExtraDDL0)) {
      result.push('ExtraDDL0');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL1)) {
      result.push('ExtraDDL1');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL2)) {
      result.push('ExtraDDL2');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL3)) {
      result.push('ExtraDDL3');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL4)) {
      result.push('ExtraDDL4');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL5)) {
      result.push('ExtraDDL5');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL6)) {
      result.push('ExtraDDL6');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL7)) {
      result.push('ExtraDDL7');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL8)) {
      result.push('ExtraDDL8');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL9)) {
      result.push('ExtraDDL9');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL10)) {
      result.push('ExtraDDL10');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL11)) {
      result.push('ExtraDDL11');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL12)) {
      result.push('ExtraDDL12');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL13)) {
      result.push('ExtraDDL13');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL14)) {
      result.push('ExtraDDL14');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL15)) {
      result.push('ExtraDDL15');
    }
    return result;
  }, [appSettings, assetFieldEnabled]);
  const [multiAssetSelectionMode, setMultiAssetSelectionMode] = React.useState<
    boolean
  >(false);
  const [selectedRowIds, setSelectedRowIds] = React.useState<
    Record<IdType<number>, boolean>
  >({});
  const [, setCurrentMode, currentModeRef] = useState<TableMode>('card');
  const [loacalAssets, setLoacalAssets, localAssetsRef] = useState<
    Entity<number>[]
  >([]);
  React.useEffect(() => {
    let obj = {};
    if (!!assets && assets.length > 0) {
      for (const key of assets) {
        obj[key.Id] = true;
      }
      setMultiAssetSelectionMode(assets.length > 1);
      setLoacalAssets(assets);
    }
    setSelectedRowIds(obj);
  }, [assets, setLoacalAssets]);

  function getMyListFilter(search: string, selectedView: SavedViewSelected) {
    const result = isEmptyOrWhitespace(search)
      ? new URLSearchParams()
      : new URLSearchParams(search);
    result.set('aid', selectedView.assetIds || '');
    const res = result.toString();
    return isEmptyOrWhitespace(res) ? undefined : res;
  }

  const columns: Column<IMyAssetsRow>[] = React.useMemo(
    () => getColumns(t, authenticatedUser, appSettings, settings),
    [t, authenticatedUser, appSettings, settings],
  );
  const initialState: IBasicTableState<IMyAssetsRow> = React.useMemo(
    () => ({
      sortBy:
        actionType === 'AssetSearch'
          ? [{ id: 'Rank', desc: true }]
          : [{ id: 'Name', desc: false }],
      selectedRowIds: selectedRowIds,
      globalFilter: searchTerm,
    }),
    [actionType, searchTerm, selectedRowIds],
  );
  // const selectedRowActions: SelectedRowsActionRenderer<
  //   IMyAssetsRow
  // >[] = GetSelectedActions(t, {
  //   enableSavedLists: false,
  //   canExport: false,
  //   canExportAssetRanges: false,
  // });
  const selectedRowActions: SelectedRowsActionExtRenderer<
    IMyAssetsRow
  >[] = React.useMemo(() => {
    let actions: SelectedRowsActionExtRenderer<IMyAssetsRow>[] =
      loacalAssets.length > 0
        ? loacalAssets.map(
            sg => (selectedRws, rows, refresh, toggleAll, toggleSingle) => (
              <BookitChipWithInfo
                info={
                  rows.filter(f => f.id === sg.Id.toString()).length > 0
                    ? undefined
                    : t(translations.DeleteSelectedCard_info)
                }
                onDelete={() => {
                  onSelectChange(
                    selectedRws.filter(f => f.Id === sg.Id)[0],
                    false,
                  );
                  rows
                    .filter(f => f.id === sg.Id.toString())
                    .forEach(f => {
                      f.toggleRowSelected(false);
                    });
                  //toggleSingle(sg.Id.toString(), false);
                }}
                disabled={
                  !(rows.filter(f => f.id === sg.Id.toString()).length > 0)
                }
                // onClick={() => {
                //   openAsset(sg.Id);
                // }}
                variant="default"
                customsize={isShortView ? 'small' : 'medium'}
                label={sg.Name}
                customwidth={isShortView ? 100 : 180}
                custombgcolor={bookitColors.primary.disabled}
                customcolor={bookitColors.grayscale.blackComponents}
                customiconcolor={bookitColors.grayscale.blackComponents}
                shape="circle"
                key={sg.Id}
              />
            ),
          )
        : [];
    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShortView, loacalAssets]);

  const selectedRowDefaultActions: SelectedRowsActionRenderer<
    IMyAssetsRow
  >[] = GetSelectedActions(t, {
    enableSavedLists: isAuthenticated,
    canExportAssetRanges: canExportAssetRanges,
    isShowAllCores: showAllCores,
    authenticatedUser: authenticatedUser,
    useSidePanel: useSidePanel,
  });
  const handleRowsChange = (rows: Row<IMyAssetsRow>[]) => {
    let selectable = rows.map(f => {
      return {
        Id: f.original.Id,
        Name: f.original.Name,
      } as Entity<number>;
    });
    setLoacalAssets(selectable);
  };
  const toggleAllRows = (rows: Row<IMyAssetsRow>[], checked: boolean) => {
    if (checked) {
      handleRowsChange(rows);
    } else {
      handleRowsChange([]);
    }
  };
  const handleSingleChange = React.useCallback(
    (value: Entity<number>, added: boolean) => {
      if (added) {
        if (
          !!localAssetsRef.current &&
          localAssetsRef.current.length + 1 >
            (currentModeRef.current === 'card' ? 0 : 0)
        ) {
          setMultiAssetSelectionMode(true);
        }
        setLoacalAssets(oldAssets => [
          ...oldAssets,
          {
            Id: value.Id,
            Name: value.Name,
          } as Entity<number>,
        ]);
      } else {
        if (
          !!localAssetsRef.current &&
          localAssetsRef.current.length - 1 <
            (currentModeRef.current === 'card' ? 1 : 1)
        ) {
          setMultiAssetSelectionMode(false);
        }
        setLoacalAssets(oldAssets => {
          let newAsset = oldAssets.filter(ass => ass.Id !== value.Id);
          return newAsset;
        });
      }
    },
    [currentModeRef, localAssetsRef, setLoacalAssets],
  );
  const onSelectChange = React.useCallback(
    (row: IMyAssetsRow, checked: boolean) => {
      handleSingleChange(
        {
          Id: row.Id,
          Name: row.Name,
        } as Entity<number>,
        checked,
      );
    },
    [handleSingleChange],
  );
  const customFilter = React.useMemo(() => {
    let filter: (string | Condition<IMyAssetsRow>)[] = customPredicates ?? [];
    if (actionType === 'StartUsage') {
      filter.push(
        new Condition<IMyAssetsRow>('AutoMode', ODataOperators.Equals, 1),
      );
      filter.push(getOnlineServiceTypeAssetFilter(appSettings, roomsEnabled));
    }
    if (actionType === 'TrainingSession') {
      filter.push(
        new Condition<IMyAssetsRow>(
          'TrainingSessionsEnabled',
          ODataOperators.Equals,
          true,
        ),
      );
    }
    return filter.length > 0 ? filter : undefined;
  }, [actionType, appSettings, customPredicates, roomsEnabled]);
  const useFloatingActions = React.useMemo(() => {
    return multiAssetSelectionMode
      ? loacalAssets.length > 0
      : loacalAssets.length > 1;
  }, [loacalAssets.length, multiAssetSelectionMode]);

  const url = '/api/odata/v4/MyAssets';
  const { serviceGroups } = useGlobalServiceGroupsFilter(!showAllCores);
  const TTable = React.useMemo(
    () =>
      filterHandler<IMyAssetsRow, {}>(BasicTable, GetFilters, {
        isSidePanel: useSidePanel,
        setFilters: setFilters,
        reservedQueryStringParameterNames: ['active'],
        getDefaultFilter: DefaultFilter,
        getRequiredFilter: getRequiredFilter,
        getSelectedViewFilter: getMyListFilter,
        enableSavedLists: true,
        anyDefinition: serviceGroupId !== undefined,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useSidePanel],
  );
  return (
    <React.Fragment>
      <TTable
        screenId={
          actionType === 'AssetSearch'
            ? ScreensId.AssetSearch
            : ScreensId.MyAssets
        }
        api={
          actionType === 'AssetSearch'
            ? '/api/odata/v4/MyAssets/GetMyAssetsSearchDto'
            : url
        }
        columns={columns}
        screenName={screenName}
        screenNameVariant="section"
        additionalColumns={
          actionType === 'AssetSearch'
            ? ['Rank', ...additionalColumns]
            : additionalColumns
        }
        searchColumns={actionType === 'AssetSearch' ? undefined : searchColumns}
        serviceGroups={
          serviceGroupId !== undefined
            ? [{ Id: serviceGroupId, Name: '' }]
            : serviceGroups
        }
        inlineRowActions={undefined}
        // selectedRowsActions={selectedRowActions}
        initialState={initialState}
        isRowSelectable={isRowSelectable}
        hideMenuOnMobile={useSidePanel}
        useExport={actionType === 'AssetSearch'}
        useCustomSearch={actionType === 'AssetSearch'}
        cardSize="small"
        viewCardsCheck={!multiModeDisabled || actionType === 'AssetSearch'}
        // viewCardsCheck={
        //   multiAssetSelectionMode ||
        //   (isShortView && !multiModeDisabled) ||
        //   actionType === 'AssetSearch'
        // }
        onSelectedChange={onSelectChange}
        imageSrcKey="ImageName"
        titleKey="Name"
        allowCards={true}
        compactTable={isShortView}
        onModeChange={mode => setCurrentMode(mode === true ? 'card' : 'table')}
        primaryAction={row => {
          let canSwitch = isRowSelectable(row);
          if (!multiAssetSelectionMode) {
            if (canSwitch) {
              primaryAction?.(row);
            }
          } else {
            if (canSwitch) {
              row.toggleRowSelected(!row.isSelected);
              onSelectChange(row.original, !row.isSelected);
            }
          }
        }}
        savedListSettings={{
          enableSavedLists: isAuthenticated,
          savedListRelatedPickerName: 'Id',
          savedListSerializedKey: 'aid',
          listViewbyAsset: true,
        }}
        useConfigurable={actionType === 'AssetSearch'}
        useCardsByDefault={true}
        selectedRowsActions={
          actionType === 'AssetSearch' ? selectedRowDefaultActions : undefined
        }
        floatingTabSettings={{
          useFloatingActions:
            useFloatingActions &&
            actionType !== 'AssetSearch' &&
            !multiModeDisabled,
          selectedFloatingActions: selectedRowActions,
          buttonFloatingActions: [
            rows => (
              <Button
                size={isShortView ? 'small' : 'medium'}
                onClick={() => {
                  primaryMultiAction(rows);
                }}
              >
                {isShortView ? 'Continue' : 'Continue with Selected'}
              </Button>
            ),
          ],
          useSidePanel: useSidePanel,
        }}
        //pageActions={pageActions}
        useRowSelect={!multiModeDisabled}
        withToolBar={false}
        customEmptyTableSettings={emptyTable}
        tabSectionSettings={{
          useTabsSection: isAuthenticated,
          getMultiMode: value => setMultiAssetSelectionMode(value),
          selectedTabValue: undefined,
          onAllRowsSelected: toggleAllRows,
          disableMultiMode: multiModeDisabled,
          defaultMultiMode: !multiModeDisabled,
          // defaultMultiMode:
          //   multiAssetSelectionMode === true ||
          //   (!!localAssetsRef.current && localAssetsRef.current.length > 0),
        }}
        customToolbarSettings={{
          useCustomToolbar: useRecentAssets,
          CustomToolbar: RecentAssets,
        }}
        predicates={customFilter}
        preventInnerClicks={isMobile}
      />
    </React.Fragment>
  );
}
