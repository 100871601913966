/**
 *
 * BasicDialogTitle
 *
 */
import {
  Box,
  createStyles,
  DialogTitle as MuiDialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Body } from '../Typography';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { H3 } from '../Typography';
const titleStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3, 3, 1, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      background: theme.palette.background.default,
      //borderRadius: '12px 12px 0px 0px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
    },
    titleBody: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px',
    },
    titleSubtitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {},
  }),
);
export interface BasicDialogTitleProps {
  id?: string;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: () => void;
}
export function BasicDialogTitle(props: BasicDialogTitleProps) {
  const { id, children, subtitle, onClose, ...other } = props;
  const classes = titleStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className={classes.titleContainer}>
        <Box className={classes.titleBody}>
          <H3 className={classes.title}>{children}</H3>
        </Box>
        {subtitle && (
          <Box className={classes.titleSubtitle}>
            <Body className={classes.subtitle}>{subtitle}</Body>
          </Box>
        )}

        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Icon icon="xmark" />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
}
