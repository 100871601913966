import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { ScreensId } from 'enums/ConfigurableTypes';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './Columns';
import { useSelector } from 'react-redux';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';

export function ArticlesTable<
  TProps extends Omit<
    BasicTableProps<IArticleDto>,
    'api' | 'additionalColumns' | 'searchColumns' | 'columns' | 'screenName'
  > & {
    useSidePanel?: boolean;
    openPanel?: (state: SidePanelOpenState) => void;
  }
>({
  initialState,
  rowActions,
  screenId,
  serviceGroups,
  predicates,
  useSidePanel,
  openPanel,
  ...props
}: TProps) {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const globalSettings = useSelector(selectglobalSettings);
  const columns = React.useMemo(
    () =>
      getColumns(t, authenticatedUser, globalSettings, useSidePanel, openPanel),
    [authenticatedUser, globalSettings, openPanel, t, useSidePanel],
  );
  // add custom global service grups filter since the Article is connected to the service groups through Assets property and does not have ServiceGroupId property used in the basic table filtering
  const globalServiceGroupsFilter = React.useMemo(
    () =>
      serviceGroups !== undefined && serviceGroups.length > 0
        ? [
            `Assets/any(a: a/Availability/Id eq ${
              AvailabilityTypes.ServiceGroup
            } and a/ServiceGroupId in (${serviceGroups
              .map(f => f.Id)
              .join(',')}))`,
          ]
        : undefined,
    [serviceGroups],
  );
  const newPredicates = React.useMemo(() => {
    if (predicates !== undefined && predicates.length > 0) {
      const externalPredicates = predicates
        .map(p => p.toString())
        .filter(r => r !== undefined) as string[];
      if (globalServiceGroupsFilter !== undefined) {
        globalServiceGroupsFilter.push(...externalPredicates);
        return globalServiceGroupsFilter;
      } else {
        return externalPredicates;
      }
    } else return globalServiceGroupsFilter;
  }, [globalServiceGroupsFilter, predicates]);
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    Reflect.deleteProperty(p, 'columns');
    return p;
  }, [props]);
  const url = '/api/odata/v4/articles';
  return (
    <>
      <BasicTable
        api={url}
        screenId={ScreensId.Articles}
        initialState={initialState}
        columns={columns}
        rowActions={rowActions}
        // fetch all of the columns as the row will be used in displaying article details inside the sidepanel without additional data fetch
        additionalColumns={[
          'Id',
          'Title',
          'Abstract',
          'ExternalId',
          'ExternalIdSource',
          'Year',
          'Authors',
          'Doi',
          'ISSN',
          'Journal',
          'ORCIDs',
          //'ArticleContributionLevelId',
          'Assets',
          'RelatedUser',
          'CreatedByMe',
          'EnteredBy',
        ]}
        searchColumns={[
          'cast(Id,Edm.String)',
          'Title',
          // 'Abstract',
          // 'ExternalId',
          // 'ExternalIdSource',
          // 'cast(Year,Edm.String)',
          s => `Authors/any(a:contains(a, ${s}))`,
          // 'Doi',
          // 'ISSN',
          'Journal',
          //'ORCIDs',
          //'ArticleContributionLevelId',
          //'Assets',
          s => `Assets/any(a:contains(a/Name, ${s}))`,
          'ExternalIdSource',
        ]}
        screenName={t(translations.Publications)}
        serviceGroups={serviceGroups}
        predicates={newPredicates}
        {...(newProps as TProps)}
      />
    </>
  );
}
