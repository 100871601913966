import i18next from 'i18next';
import { assign } from 'lodash';
import { isDefined } from './isDefined';
import {
  ICustomModelError,
  IModelError,
  IModelStateDictionary,
} from 'types/Modelstate';

export function getErrorMessages(
  modelState: IModelStateDictionary,
): Array<string> {
  const result = Object.values(modelState)
    .flatMap(item => item.Errors)
    .map(toErrorMessage)
    .filter(isDefined);
  return result;
}
function toErrorMessage(modelError: IModelError): string | undefined {
  if ((modelError as ICustomModelError).Key !== undefined) {
    return i18next.t(
      (modelError as ICustomModelError).Key,
      (modelError as ICustomModelError).Value,
      modelError.ErrorMessage ?? undefined,
    );
  } else if (modelError.ErrorMessage !== null) {
    return modelError.ErrorMessage;
  } else if (modelError.Exception !== null) {
    return modelError.Exception.Message;
  }
}
export function addModelError(
  modelState: IModelStateDictionary,
  errormessage: string,
) {
  const modelErrors =
    modelState['']?.Errors ??
    (modelState[''] = {
      Value: null,
      Errors: [],
    });
  modelErrors.push({ ErrorMessage: errormessage, Exception: null });
}
export function addModelStateDictionary(
  a: IModelStateDictionary,
  b: IModelStateDictionary,
) {
  // todo: merge per property errors
  assign(a, b);
}
