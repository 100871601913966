import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { bookitColors } from 'styles/colors/bookitColors';

export const Section = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
  sidePanelWidth: number;
}>`
  &.formsection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 32px;
    max-width: 100%;
    width: 100%;
    gap: ${props => props.theme.spacing(17)}px;
    &.request-section {
      background: ${bookitColors.base.white};
      border: 1px solid ${bookitColors.grayscale.grayBorders};
      border-radius: 12px;
      padding: 20px 24px 24px 24px;
    }
    &.noPadding {
      padding: 0;
    }
    &.shortView {
      .baseRootHeight & {
        flex-direction: row;
        padding: 0px 32px;
        width: 100%;
        gap: ${props => props.theme.spacing(17)}px;
      }
      &.noPadding {
        padding: 0;
      }
      flex-direction: column;
      padding: 0px 12px;
      width: ${props => props.sidePanelWidth}px;
      gap: ${props => props.theme.spacing(2)}px;
    }
    @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      &.shortView {
        width: 100%;
      }
    }
  }
`;
export const LeftSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.leftsection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0px;

    width: 100%;
    gap: ${props => props.theme.spacing(6)}px;
    &.shortView {
      gap: ${props => props.theme.spacing(3)}px;
      .baseRootHeight & {
        gap: ${props => props.theme.spacing(6)}px;
      }
    }
  }
`;
export const RightSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.rightsection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0px;
    width: 40%;
    gap: ${props => props.theme.spacing(6)}px;
    &.shortView {
      width: 100%;
      gap: ${props => props.theme.spacing(2)}px;
      padding-bottom: ${props => props.theme.spacing(3)}px;
      .baseRootHeight & {
        width: 40%;
        gap: ${props => props.theme.spacing(6)}px;
        padding-bottom: 0px;
      }
    }
  }
`;

export const RowSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: `${theme.spacing(2)}px`,
  flex: '1 1 49%',
  '&.hideRow': {
    display: 'none',
  },
  '&.shortView': {
    flex: '1 1 100%',
  },
  '&.fullRow': {
    flex: '1 1 100%',
  },
  '&.minRow': {
    flex: '1 1 25%',
    [theme.breakpoints.down('md')]: {
      flex: '1 1 40%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 40%',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '1 1 100%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   flex: '1 1 100%',
    // },
  },
  '.shortView &': {
    flex: '1 1 100%',
    gap: '8px',
  },
  '&.wrap': {
    flexWrap: 'wrap',
  },
  '&.shrink-1': {
    '& > *:first-child': {
      flexShrink: 1,
    },
  },
  '&.shrink-2': {
    '& > *:first-child': {
      flexShrink: 2,
    },
  },
  '&.shrink-3': {
    '& > *:first-child': {
      flexShrink: 3,
    },
  },
}));

export const FormFieldsBox = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.fields-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    gap: ${props => props.theme.spacing(1.5)}px;
    &.request-section {
      background: ${bookitColors.base.white};
      border: 1px solid ${bookitColors.grayscale.grayBorders};
      border-radius: 12px;
      padding: 20px 24px 24px 24px;
    }
    &.maxSectionWidthXs {
      max-width: ${props => props.theme.breakpoints.values.xs}px;
    }
    &.maxSectionWidthSm {
      max-width: ${props => props.theme.breakpoints.values.sm}px;
    }
    &.maxSectionWidthMd {
      max-width: ${props => props.theme.breakpoints.values.md}px;
    }
    &.maxSectionWidthLg {
      max-width: ${props => props.theme.breakpoints.values.lg}px;
    }
    &.maxSectionWidthXl {
      max-width: ${props => props.theme.breakpoints.values.xl}px;
    }
    &.centered {
      align-self: center;
    }
    & .sectiontitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      // padding: ${props => props.theme.spacing(0, 0, 0.5)};
      gap: ${props => props.theme.spacing(2)}px;
    }
    & .fields {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      flex-wrap: wrap;
      box-sizing: border-box;
      width: 100%;
      gap: ${props => props.theme.spacing(1.5)}px;
      // Adjusted styles for spacing using margin
      // & > .row:not(:last-child) {
      //   //margin-right: ${props => props.theme.spacing(1.5)}px;
      //   margin-bottom: ${props => props.theme.spacing(1.5)}px;
      // }
    }
  }
`;
export const CustomDivider = styled.hr`
  &.divider {
    width: 100%;
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.12);
    .leftsection & {
      margin-bottom: -24px;
      margin-top: -24px;
      .shortView & {
        margin-bottom: -8px;
        margin-top: -8px;
        .baseRootHeight & {
          margin-bottom: -24px;
          margin-top: -24px;
        }
      }
    }
    .rightsection & {
      margin-bottom: -24px;
      margin-top: -24px;
      .shortView & {
        margin-bottom: -8px;
        margin-top: -8px;
        .baseRootHeight & {
          margin-bottom: -24px;
          margin-top: -24px;
        }
      }
    }
  }
`;
