import { CircularProgress } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import { BookitChip, BookitChipWithInfo } from 'app/components/BasicChip';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import React, { useEffect } from 'react';
import { CSSProperties } from 'styled-components';
import {
  autocompleteExtendStyles,
  autocompleteStyles,
} from 'styles/MUIExtend/autocompleteStyles';
import { EntityType } from 'types/common';
import clsx from 'clsx';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  ColoredOption,
  extractDataFromResponse,
  IDisableable,
  InfoOption,
  loadDataAndPrepareOptions,
  NonRemoveableOption,
  NonSelectableOption,
  OptionType,
} from '../Utils/autoCompletePickerUtils';
import { useTranslation } from 'react-i18next';
import { Condition } from 'api/odata/ODataFilter';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { undefinedIfIsEmpty } from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';

export interface AutocompleteMultiPickerExProps<TEntity extends EntityType>
  extends Omit<
    AutocompleteProps<OptionType<TEntity>, true, boolean | undefined, false>,
    | 'multiple'
    | 'loading'
    | 'open'
    | 'options'
    | 'renderInput'
    | 'onChange'
    | 'classes'
    | 'renderTags'
    | 'renderOptionLabel'
    | 'renderOptions'
  > {
  value?: TEntity[];
  label?: string;
  loadData: (
    searchTerm: string | null,
    predicates?: (string | Condition<TEntity>)[],
    expandedColumns?: string,
  ) => Promise<TEntity[] | { value: TEntity[]; more: boolean }>;
  size?: 'medium' | 'small';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  isOpen?: boolean;
  style?: CSSProperties;
  name?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (value: TEntity[]) => void;
  required?: boolean;
  info?: string;
  id?: string;
  renderTags?: (
    value: TEntity[],
    getTagProps: AutocompleteGetTagProps,
  ) => React.ReactNode | undefined;
  renderOptionLabel?: (value: TEntity) => string | undefined;
  renderOptions?: (
    option: TEntity,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode | undefined;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChipClick?: (chip: TEntity) => void;
  ariaLabel?: string;
  predicates?: (string | Condition<TEntity>)[];
  additionalItem?: TEntity | null;
  expandedColumns?: string;
  onPickerOpen?: () => void;
}

/**
 * A copy of AutocompleteMultiPicker with addition of limiting the length of options shown
 * TODO: merge with AutocompleteMultiPicker + apply to BaseURLMultiPicker
 */
export function AutocompleteMultiPickerEx<TEntity extends EntityType>({
  isOpen,
  size,
  mini,
  onOpen,
  onClose,
  label,
  fullWidth,
  variant,
  name,
  placeholder,
  error,
  helperText,
  required,
  renderTags,
  loadData,
  disableClearable,
  onChange,
  onInputChange,
  getOptionSelected,
  getOptionLabel,
  renderOptionLabel,
  renderOptions,
  onBlur,
  value,
  defaultValue,
  info,
  id,
  onChipClick,
  ariaLabel,
  predicates,
  additionalItem,
  expandedColumns,
  onPickerOpen,
  ...props
}: AutocompleteMultiPickerExProps<TEntity>) {
  const { t } = useTranslation();
  const classes = autocompleteStyles();
  let firstOpen = isOpen;
  const [open, setOpen] = React.useState(firstOpen);
  const [options, setOptions] = React.useState<OptionType<TEntity>[] | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [hasSelected, setSelected] = React.useState(
    defaultValue && defaultValue.length > 0,
  );
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = isMobile || (sidePanelOpen && !sidePanelExpanded);
  const loading = open && options === null;
  useEffect(() => {
    setSelected((value || []).length > 0);
  }, [value]);
  React.useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      try {
        const rawResponse = await loadData(
          searchTerm,
          predicates,
          expandedColumns,
        );
        const response = extractDataFromResponse(rawResponse);
        const optionsData = loadDataAndPrepareOptions({
          response,
          additionalItem,
          t,
        });
        active && setOptions(optionsData);
      } catch {}
    })();

    return () => {
      active = false;
    };
  }, [
    loading,
    loadData,
    searchTerm,
    t,
    predicates,
    additionalItem,
    expandedColumns,
  ]);

  React.useEffect(() => {
    if (!open) setOptions(null);
  }, [open]);

  const handleRenderTags = (
    value: OptionType<TEntity>[],
    getTagProps: AutocompleteGetTagProps,
  ) => {
    const selectable = value.filter(
      option => (option as NonSelectableOption)?.disabled !== true,
    );
    if (renderTags === undefined) {
      return selectable.map((option: OptionType<TEntity>, index: number) =>
        !!(option as InfoOption).titleInfo ? (
          <BookitChipWithInfo
            onClick={() => onChipClick?.(option as TEntity)}
            variant="default"
            size="small"
            label={option.Name}
            custombgcolor={
              undefinedIfIsEmpty((option as ColoredOption)?.Color) ?? undefined
            }
            info={(option as InfoOption).titleInfo}
            notremoved={(option as NonRemoveableOption)?.noremoved}
            {...getTagProps({ index })}
          />
        ) : (
          <BookitChip
            onClick={() => onChipClick?.(option as TEntity)}
            variant="default"
            size="small"
            label={option.Name}
            custombgcolor={
              undefinedIfIsEmpty((option as ColoredOption)?.Color) ?? undefined
            }
            notremoved={(option as NonRemoveableOption)?.noremoved}
            {...getTagProps({ index })}
          />
        ),
      );
    } else {
      return renderTags(selectable as TEntity[], getTagProps);
    }
  };
  const handleRenderOptionLabel = (option: OptionType<TEntity>) => {
    return renderOptionLabel === undefined
      ? option.Name
      : (renderOptionLabel(option as TEntity) as string);
  };
  const extClasses = autocompleteExtendStyles();
  const getIsOptionSelected_Internal = (
    option: OptionType<TEntity>,
    value: OptionType<TEntity>,
  ) => {
    if ((option as NonSelectableOption).disabled !== undefined) {
      return false;
    }
    var id = (option as TEntity)?.Id;
    var selectable = id !== undefined;

    if (!selectable) {
      return false;
    } else if (getOptionSelected !== undefined) {
      return getOptionSelected(option as TEntity, value as TEntity);
    } else {
      return id === (value as TEntity)?.Id;
    }
  };
  const getOptionDisabled = (option: OptionType<TEntity>) =>
    (option as IDisableable)?.disabled ?? false;

  return (
    <Autocomplete
      id={id || 'base-multi-autocomplete'}
      multiple={true}
      classes={{
        ...classes,
        root: clsx(
          classes.root,
          'multiSelect',
          showShortView ? 'shortView' : undefined,
          !!label ? 'hasLabel' : undefined,
        ),
      }}
      loading={loading}
      options={(options as OptionType<TEntity>[]) ?? []}
      open={open || false}
      onOpen={e => {
        //onOpen && onOpen(e);
        !disableClearable && setOptions(null);
        setSearchTerm(null);
        setOpen(true);
        !!onPickerOpen && onPickerOpen();
      }}
      onClose={(e, r) => {
        //onClose && onClose(e, r);
        setSearchTerm(null);
        setOpen(false);
      }}
      onChange={(e, v, r, d) => {
        setSearchTerm(null);
        setSelected(v.length > 0);
        if (onChange) {
          onChange(v as TEntity[]);
        }
      }}
      onInputChange={(event, newValue, reason) => {
        setOptions(null);
        if (reason === 'input') setSearchTerm(newValue);
        //onInputChange && onInputChange(event, newValue, reason);
      }}
      getOptionSelected={getIsOptionSelected_Internal}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={handleRenderOptionLabel}
      renderTags={handleRenderTags}
      size={size}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      value={value || undefined}
      popupIcon={<Icon icon="chevron-down" size="xs" />}
      closeIcon={<Icon icon="circle-xmark" size="xs" />}
      selectOnFocus={isMobile ? false : true}
      renderInput={params => (
        <BaseTextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          size={size}
          variant={variant || 'standard'}
          name={name}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          required={required}
          inputProps={{
            ...params.inputProps,
            'aria-label': label
              ? undefined
              : `${ariaLabel ? ariaLabel : 'search input'}`,
          }}
          InputLabelProps={{
            classes: { root: 'multiSelect' },
            ...params.InputLabelProps,
          }}
          InputProps={{
            autoFocus: firstOpen,
            classes: {
              root: clsx(
                'multiSelect',
                showShortView ? 'shortView' : undefined,
                !!label ? 'hasLabel' : undefined,
              ),
            },
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={params.size === 'small' ? 12 : 16}
                    style={{
                      position: 'absolute',
                      right: 32,
                      top: 'calc(50% - 8px)',
                    }}
                  />
                ) : info ? (
                  <InfoIcon
                    title={info}
                    className={clsx(extClasses.infoIcon, {
                      [extClasses.infoWidthPlus]:
                        hasSelected && !disableClearable,
                      [extClasses.infoWidthMinus]:
                        !hasSelected || disableClearable,
                    })}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: params.InputProps.startAdornment,
          }}
        />
      )}
      {...props}
    />
  );
}
