import { BudgetFields } from 'enums/BudgetFields';
import { AssetAttributes } from 'enums/AssetAttributes';
import { KnownModules } from './KnownModules';
import { Entity } from './common';
import { ImageSize } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { dateUtils } from 'utils/date-utils';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { UserAttributeEnum } from 'enums/UserAttributesEnum';
import { IUserAttributesDto } from 'api/odata/generated/entities/IUserAttributesDto';
import { UserScreenType } from 'app/pages/UserDetails/Details/typeUtils';

export enum EnumAssetTypes {
  Equipment,
  TrainingProgram,
  LectureRoom,
  ServiceGroup,
  SuperGroup,
  Request,
  OfflineService,
  NotAvailable,
}
export interface AppSettings {
  Globalization: CultureInfoModel;
  Modules: KnownModules[];
  UserInternalIdLength: number | null;
  InternalUserId2FieldLength: number | null;
  WebVersion: string;
  LastCodeUpdate: Date;
  UserGroupsProviderType: number;
  AssetFieldSettings: Record<AssetAttributes, IAssetAttributeSettingDto>;
  UserFieldSettings: Record<UserAttributeEnum, IUserAttributesDto>;
  PublicBaseUrl: string;
  UploadFileSizeLimit: number;
  MinimizedBookitlabLogo: boolean;
  ForbiddenFileExtensions: string[];
  BudgetFieldSettings: {
    BudgetField: BudgetFields;
    Disabled: Boolean;
  }[];
  TimeZone: ITimeZone;
  AssetBarcodeUrlTemplate: string;
  AssetBarcodeRelativeUrlTemplate: string;
  RoomBarcodeUrlTemplate: string;
  RoomBarcodeRelativeUrlTemplate: string;
  LocationBarcodeUrlTemplate: string;
  LocationBarcodeRelativeUrlTemplate: string;
  SubLocationBarcodeUrlTemplate: string;
  SubLocationBarcodeRelativeUrlTemplate: string;
  SamplePlateBarcodeUrlTemplate: string;
  SamplePlateBarcodeRelativeUrlTemplate: string;
  ConsumableTypeBarcodeUrlTemplate: string;
  ConsumableTypeBarcodeRelativeUrlTemplate: string;
  RequiredUserProps: string[];
  HideUserProps: string[];
  DefaultRegistrationGroup: Entity<string> | null;
  UserRegistersToSpecificCores: number;
  TopCustomerLogoLink: string;
  TopCustomerLogo: string;
  FavIconImg: string;
  FavIconTitle: string;
  DTUOrbitWebServiceEnabled: boolean;
  PubMedWebServiceEnabled: boolean;
  EmailFromAddress: string;
  ShowRawErrors: boolean;
  ExistingImages: string[];
  Help_Login: string | null;
  LoginScreenCompanyLogoLink: string;
  LoginScreenCompanyLogo: string;
  LoginScreenCompanyLogoHeight: string;
  ShowForgotPassword: boolean;
  AdvancedAssetPublicSearchFilterAttributes: string;
  BrowseByUnitBy: number;
  noAuthSettings?: SettingModel[] | null;
  LdapEnabled: boolean;
}

type Continent =
  | 'Africa'
  | 'America'
  | 'Antarctica'
  | 'Arctic'
  | 'Asia'
  | 'Atlantic'
  | 'Australia'
  | 'Europe'
  | 'Indian'
  | 'and'
  | 'Pacific';

export interface ITimeZone {
  Id: string;
  StandardName: string;
  TimezoneOffsetMinutes: number;
  DisplayName: string;
  Iana: `${Continent}/${string}`;
}

export function IsBudgetFieldEnabled(
  setting: AppSettings | null,
  BudgetField: BudgetFields,
): boolean {
  if (!!setting && setting.BudgetFieldSettings) {
    var result = setting.BudgetFieldSettings.find(bf => {
      return bf.BudgetField === BudgetField;
    });
    return result !== undefined ? result.Disabled === false : true;
  }
  return false;
}

export interface CultureInfoModel {
  Locale: string;
  SupportedLocales: Array<Entity<string>>;
  DefaultLocaleId: string;
  FirstDayOfWeek: number;
  UseAmPm: boolean;
}

export interface AppSettingsErrorType {
  message: string;
}
export function IsModuleEnabled(
  setting: AppSettings | null,
  module: KnownModules,
): boolean {
  if (!!setting && setting.Modules) {
    return setting?.Modules.includes(module);
  }
  return false;
}
export function IsAssetFieldEnabled(
  setting: AppSettings | null,
  AssetField: AssetAttributes,
  AssetAvailabilityType?: AssetTypes,
): boolean {
  if (!!setting && setting.AssetFieldSettings && AssetField !== undefined) {
    const s = setting.AssetFieldSettings[AssetAttributes[AssetField]];
    if (s !== undefined) {
      if (AssetAvailabilityType !== undefined) {
        const id = EnumAssetTypes[AssetAvailabilityType];
        return s.AssetTypes.includes(id);
      } else {
        return true;
      }
    }
  }
  return false;
}

export function IsAssetFieldRequired(
  setting: AppSettings | null,
  AssetField: AssetAttributes,
  AssetAvailabilityType?: AssetTypes,
): boolean {
  if (!setting?.AssetFieldSettings || AssetField === undefined) return false;
  const s: IAssetAttributeSettingDto =
    setting.AssetFieldSettings[AssetAttributes[AssetField]];
  if (s === undefined) return false;
  if (AssetAvailabilityType !== undefined) {
    return s.RequiredAssetTypes.includes(EnumAssetTypes[AssetAvailabilityType]);
  }
  return false;
}
export function IsAssetFieldIsPublic(
  setting: AppSettings | null,
  AssetField: AssetAttributes,
  AssetAvailabilityType?: AssetTypes,
): boolean {
  if (!!setting && setting.AssetFieldSettings && AssetField !== undefined) {
    const s = setting.AssetFieldSettings[AssetAttributes[AssetField]];
    if (s !== undefined) {
      if (AssetAvailabilityType !== undefined) {
        const id = EnumAssetTypes[AssetAvailabilityType];
        return s.AssetTypes.includes(id) && s.IsPublic === true;
      } else {
        return s.IsPublic === true;
      }
    }
  }
  return false;
}
export function IsAssetImageExist(
  settings: AppSettings | null,
  imageName: string | null,
  imageSize?: ImageSize,
) {
  if (
    imageName === null ||
    settings === null ||
    !settings.ExistingImages ||
    (!!settings.ExistingImages && settings.ExistingImages.length < 1)
  ) {
    return false;
  }
  let result = false;
  result =
    !!settings.ExistingImages &&
    settings.ExistingImages.length > 0 &&
    settings.ExistingImages.some(f => f === imageName);
  // switch (imageSize) {
  //   case 'full':
  //     result =

  //     break;
  //   case 'thumb':
  //     result =
  //       !!settings.ExistingImages.ThumbImages &&
  //       settings.ExistingImages.ThumbImages.length > 0 &&
  //       settings.ExistingImages.ThumbImages.some(f => f === imageName);
  //     break;
  //   case 'vertical':
  //     result =
  //       !!settings.ExistingImages.CardVerticalImages &&
  //       settings.ExistingImages.CardVerticalImages.length > 0 &&
  //       settings.ExistingImages.CardVerticalImages.some(f => f === imageName);
  //     break;
  //   case 'horizontal':
  //     result =
  //       !!settings.ExistingImages.CardHorizontalImages &&
  //       settings.ExistingImages.CardHorizontalImages.length > 0 &&
  //       settings.ExistingImages.CardHorizontalImages.some(f => f === imageName);
  //     break;
  //   default:
  //     result = false;
  // }
  return result;
}
export function IsUserFieldEnabled(
  setting: AppSettings | null,
  UserField: UserAttributeEnum,
  type?: UserScreenType,
): boolean {
  if (!!setting && setting.UserFieldSettings && UserField !== undefined) {
    const s = setting.UserFieldSettings[UserAttributeEnum[UserField]];
    if (s !== undefined) {
      if (type !== undefined) {
        return s[type] === true && s.Disabled !== true;
      }
      return s.Disabled !== true;
    }
  }
  return false;
}

export function IsUserFieldRequired(
  setting: AppSettings | null,
  UserField: UserAttributeEnum,
  type?: UserScreenType,
): boolean {
  if (!!setting && setting.UserFieldSettings && UserField !== undefined) {
    const s = setting.UserFieldSettings[UserAttributeEnum[UserField]];
    if (s !== undefined) {
      if (type !== undefined) {
        return s[type] === true && s.Mandatory === true && s.Disabled !== true;
      }
      return s.Mandatory === true && s.Disabled !== true;
    }
  }
  return false;
}
export function getOffsetDate(
  settings: AppSettings | null,
  date: Date | string | undefined | null,
) {
  const systemOffset = settings?.TimeZone?.TimezoneOffsetMinutes ?? 0;
  let correctDate =
    date === null || date === undefined
      ? dateUtils.dateOrStringToDate(new Date())
      : dateUtils.dateOrStringToDate(date);
  const currentDateOffset = correctDate.getTimezoneOffset();
  const dateOrNow = dateUtils.addMinutes(
    correctDate,
    systemOffset + currentDateOffset,
  );
  return dateOrNow;
}

export function GetAssetTypeFrom(
  availability: AvailabilityTypes,
): AssetTypes | undefined {
  switch (availability) {
    case AvailabilityTypes.NotAvailable:
    case AvailabilityTypes.AvailableNotBookable:
      return 'NotAvailable';
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
    case AvailabilityTypes.LoanableEquipment:
      return 'Equipment';
    case AvailabilityTypes.ServiceRelated:
      return 'OfflineService';
    case AvailabilityTypes.ServiceGroup:
      return 'ServiceGroup';
    case AvailabilityTypes.SuperGroup:
      return 'SuperGroup';
    case AvailabilityTypes.RequestRelated:
      return 'Request';
    case AvailabilityTypes.TrainingProgram:
      return 'TrainingProgram';
    case AvailabilityTypes.LectureRoom:
      return 'LectureRoom';
    default:
      return;
  }
}

export type AssetTypes =
  | 'Equipment'
  | 'TrainingProgram'
  | 'LectureRoom'
  | 'ServiceGroup'
  | 'SuperGroup'
  | 'Request'
  | 'OfflineService'
  | 'NotAvailable';
export interface IAssetAttributeSettingDto {
  Name: string;
  AssetTypes: Array<AssetTypes>;
  RequiredAssetTypes: Array<number>;
  AssetCats: Array<number>;
  IsPublic: boolean;
  Enabled: boolean;
  DisplayName: string;
  Order: number;
}
